<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModal"
      @close="$emit('cerrar', false);
        limpiarCamposnuevoConsolidado();"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Datos del consolidado
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form class="row" @submit.prevent>
          <div
            v-if="adminOpAlmacen"
            :class="['col-12 col-sm-6',
              {'campo': validarValores.tipo
                && validarValores.tipo.length},
              {'valido': validarValores.tipo == 'valido'},
              {'invalido': validarValores.tipo == 'invalido'},
            ]"
          >
            <div class="md-form md-outline outline-select my-2">
              <select
                id="tipo-select"
                v-model="nuevoConsolidado.tipo"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in ConsolidadoTipoEnvio"
                  :key="value"
                  :value="key"
                >
                {{ value }}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="nuevoConsolidado.tipo ? 'label-active' : 'label-inactive'"
              >
                Tipo
              </label>
            </div>
            <p
              v-if="validarValores.tipo == 'invalido'"
              class="mensaje-invalido"
            >
              Selecciona el tipo
            </p>
          </div>
          <div
            v-if="adminOpAlmacen"
            class="col-12 col-sm-6"
          >
            <mdb-input
              v-model.trim="nuevoConsolidado.master"
              type="text"
              label="Guía master (opcional)"
              class="my-2"
              outline
            />
          </div>
          <div
            v-if="esEditar"
            :class="['col-12',
              {'campo': validarValores.estatus
                && validarValores.estatus.length},
              {'valido': validarValores.estatus == 'valido'},
              {'invalido': validarValores.estatus == 'invalido'},
            ]"
          >
            <div class="md-form md-outline outline-select my-2">
              <select
                id="estatus-nuevo-nuevoConsolidado-select"
                v-model="nuevoConsolidado.estatus"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in ConsolidadoEstatus"
                  :key="value"
                  :value="key"
                >
                {{ value }}
                </option>
              </select>
              <label
                for="estatus-nuevo-nuevoConsolidado-select"
                :class="nuevoConsolidado.estatus ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
            <p
              v-if="validarValores.estatus == 'invalido'"
              class="mensaje-invalido"
            >
              Selecciona el estatus
            </p>
          </div>
          <div
            v-if="adminOpAlmacen"
            :class="['col-12',
              {'col-sm-6': esEditar},
              {'campo': validarValores.fechaCarga
                && validarValores.fechaCarga.length},
              {'valido': validarValores.fechaCarga == 'valido'},
              {'invalido': validarValores.fechaCarga == 'invalido'},
            ]"
          >
            <mdb-input
              v-model="nuevoConsolidado.fechaCarga.formatted"
              type="date"
              label="Fecha de carga"
              class="my-2"
              outline
            />
            <p
              v-if="validarValores.fechaCarga == 'invalido'"
              class="mensaje-invalido date"
            >
              Indica una fecha
            </p>
          </div>
          <div v-if="esEditar" class="col-12 col-sm-6">
            <mdb-input
              v-model="nuevoConsolidado.fechaArribo.formatted"
              type="date"
              label="Fecha de arribo (opcional)"
              class="my-2"
              outline
            />
          </div>
          <div v-if="esEditar && adminOpAlmacen" class="col-12 col-sm-6">
            <mdb-input
              v-model.number="nuevoConsolidado.pesoManual"
              type="number"
              label="Peso Manual (opcional)"
              class="my-2"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon user-select-none"
                title="Libra"
              >
                lb
              </span>
            </mdb-input>
          </div>
            <div v-if="esEditar && adminOpAlmacen" class="col-12 col-sm-6">
            <mdb-input
              v-model.number="nuevoConsolidado.volumen"
              type="number"
              label="Volumen (opcional)"
              class="my-2"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon user-select-none pr-1"
                title="Pies cúbicos"
              >
                ft<sup>3</sup>
              </span>
            </mdb-input>
          </div>
          <div class="col-12">
            <mdb-input
              v-model.trim="nuevoConsolidado.observacion"
              type="textarea"
              label="Observación (opcional)"
              class="my-2"
              outline
            />
          </div>
        </form>
        <div class="row justify-content-center">
          <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado"
              class="col-auto mt-4"
              @click="validarNuevoConsolidado(false)"
          >
            {{ botonPrincipal.texto }}
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
      <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="validarNuevoConsolidado(true)"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
  </section>
</template>

<script>
import { ConsolidadoEstatus, ConsolidadoTipoEnvio } from "@/constantes/consolidados.js"
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import { leerRoles } from "@/utils/datosToken.js";
import { esRetrocesoEstatus } from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";

export default {
  name: "FormularioPaquete",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ModalConfirmarPermiso,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    datosConsolidado: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    estatusAnterior: {
      type: String,
      required: false,
      default: function () {
        return '';
      },
    },
  },
  data() {
    return {
      rol: leerRoles(),
      // Confirmar permiso
      mostrarModalConfirmarPermiso: false,
      // Otros
      mostrarModalNuevoCliente: false,
      botonDeshabilitado: false,
      mostrarModalFotos: false,
      ConsolidadoEstatus,
      ConsolidadoTipoEnvio,
      nuevoConsolidado: {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
        fechaArribo: {},
      },
      validarValores: {},
    };
  },
  mounted() {
    this.consolidadoAsignarDatosAnteriores();
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCamposnuevoConsolidado();
        this.botonDeshabilitado = false;
        if(this.esEditar){
          this.consolidadoAsignarDatosAnteriores();
        }
      }
    },
    datosConsolidado: function() {
      if(this.datosConsolidado) {
        this.consolidadoAsignarDatosAnteriores();
      }
    }
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    consolidadoAsignarDatosAnteriores() {
      const datosConsolidado = JSON.parse(JSON.stringify(this.datosConsolidado))
      delete datosConsolidado.piesCubicos
      delete datosConsolidado.pesoVolumetrico
      if (!(datosConsolidado.observacion && datosConsolidado.observacion.length)) {
        datosConsolidado.observacion = ''
      }
      Object.assign(this.nuevoConsolidado, datosConsolidado);
    },
    limpiarCamposnuevoConsolidado() {
      this.nuevoConsolidado = {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
        fechaArribo: {},
      };
      this.validarValores = {};
    },
    advertenciaEstatus(){
      this.botonDeshabilitado = false;
      Object.assign(this.validarValores, {
        estatus: "invalido"
      })
      return this.alertaMensaje('No tienes permitido realizar esta acción','advertencia')
    },
    validarNuevoConsolidado(skip) {
      this.botonDeshabilitado = true;
      this.validarValores = {
        tipo: this.nuevoConsolidado.tipo ? "valido" : "invalido",
        fechaCarga: this.nuevoConsolidado.fechaCarga.formatted ? "valido" : "invalido",
      };
      if(this.esEditar){
        Object.assign(this.validarValores, {
          estatus: this.nuevoConsolidado.estatus ? "valido" : "invalido",
        })
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.$emit("alerta-mensaje", {
          contenido: "Llena los campos requeridos",
          tipo: "error",
        });
        this.botonDeshabilitado = false;
        return;
      }
      // Valida si se está editando o creando un consolidado con un estatus diferente a Cargando
      if(this.esEditar && !this.nuevoConsolidado.contienePaquetes && this.nuevoConsolidado.estatus !== "Cargando"){
        this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            estatus: "invalido",
          })
        return this.alertaMensaje('El consolidado se encuentra sin paquetes dentro.'
          +' La acción de cambiar el estatus no se puede realizar','advertencia')
      }

      if(this.esEditar){
        if(this.rol.includes('Admin') || (this.rol.includes('OpAlmacen') && this.rol.includes('OpPuerto'))){
          // Permiso total a modificar estatus
        }
        else if(this.rol.includes('OpAlmacen')){
          // Valida que se esté modificando un estatus menor a "En Transito"
          if(esRetrocesoEstatus('EnPuertoAeropuerto', this.estatusAnterior)){
            if(esRetrocesoEstatus(this.nuevoConsolidado.estatus, 'EnTransito')){
              return this.advertenciaEstatus()
            }
          } else {
            return this.advertenciaEstatus()
          }
        }
        else if(this.rol.includes('OpPuerto')){
          // Valida que se esté modificando un estatus mayor a "En Transito"
          if(esRetrocesoEstatus(this.estatusAnterior, 'NavieraAerolinea')){
            if(esRetrocesoEstatus('EnTransito', this.nuevoConsolidado.estatus)){
              return this.advertenciaEstatus()
            }
          } else {
            return this.advertenciaEstatus()
          }
        }
      }

      // Valida si se está volviendo a un estatus anterior del consolidado, entonces pide confirmación
      if(this.esEditar && esRetrocesoEstatus(this.estatusAnterior, this.nuevoConsolidado.estatus)
          && !skip){
        this.botonDeshabilitado = false;
        return this.mostrarModalConfirmarPermiso = true;
      }
      // Convertir volumen en "float"
      if(this.nuevoConsolidado.volumen) {
        this.nuevoConsolidado.volumen = parseFloat(this.nuevoConsolidado.volumen);
      }else delete this.nuevoConsolidado.volumen;
      if(this.nuevoConsolidado.pesoManual) {
        this.nuevoConsolidado.pesoManual = Number(this.nuevoConsolidado.pesoManual);
      }else{
        this.nuevoConsolidado.pesoManual = 0
      }
      // Eliminar campos que puedan provenir de la query
      delete this.nuevoConsolidado.__typename;
      delete this.nuevoConsolidado.fechaCarga.__typename;
      delete this.nuevoConsolidado.fechaArribo.__typename;

      if(this.esEditar){
        delete this.nuevoConsolidado.paquetes;
        delete this.nuevoConsolidado.destino;
      }
      this.$emit('consolidado', JSON.parse(JSON.stringify(this.nuevoConsolidado)))
      this.limpiarCamposnuevoConsolidado()
      this.botonDeshabilitado = false;
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'};
    },
    adminOpAlmacen() {
      // Retorna verdadero si el rol es admin o operador de almacen
      return this.rol.includes('Admin') || this.rol.includes('OpAlmacen')
        ? true
        : false
    },
  },
};
</script>

 <style lang="scss" scoped>
 .campo {margin-bottom: 1rem;}
</style>

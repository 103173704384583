// Expresión regular de un email
const emailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

// Expresión regular para validar URL
const urlReg = /^(ftp|http|https):\/\/[^ "]+$/;

// Filtra array de objetos, por la propiedad que se especifique en el filtro
function multipleFiltrado(array, filtro) {
  let filtrarClaves = Object.keys(filtro);
  return array.filter(
    (item) =>
      !filtrarClaves.every((clave) => filtro[clave].indexOf(item[clave]) !== -1)
  );
}

// Elimina espacios innecesarios y capitaliza la primera letra de las palabras
function capitalizar(string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase();
    const textoCapitalize = textotoLowerCase.replace(/(^\w|\s\w)/g, (m) =>
      m.toUpperCase()
    );
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ');
    string = textoSinEspacios;
    return string;
  }
}

// Elimina espacios innecesarios y capitaliza solo la primera letra del texto
function capitalizarPrimeraLetra(string) {
  if (string) {
    const textoSinEspacios = string.trim().replace(/\s+/g, ' ');
    string =
      textoSinEspacios.charAt(0).toUpperCase() + textoSinEspacios.slice(1);
    return string;
  }
}

// Avisa si un elemento a ser ingresado a un array de string ya se encuentra agregado
function evitarElementosRepetidos(elemento, listaDeElementos) {
  // Retorna false si no se encuentra el elemento
  // Retorna true si se encuentra el elemento
  const esRepetido = listaDeElementos.includes(elemento);
  return esRepetido ? false : true;
}

// Evitar teléfonos repetidos
function evitarTelefonosRepetidos(listaDeTelefonos, telefono) {
  let proximosTelefonos = JSON.parse(JSON.stringify(listaDeTelefonos));
  const telefonosCantidad = JSON.parse(
    JSON.stringify(proximosTelefonos.length)
  );
  // Filtrar buscando
  proximosTelefonos = proximosTelefonos.filter(
    (t) => String(t.numero) !== JSON.stringify(telefono)
  );
  // Verifica si se eliminó elementos del array
  if (telefonosCantidad && proximosTelefonos.length !== telefonosCantidad) {
    // Retorna true si se encuentra el elemento
    return true;
  }
  // Retorna false si no se encuentra el elemento
  return false;
}

function mostrarSoloDosDecimales(numero) {
  const numeroAString = numero.toString();
  const regex = /(\d*.\d{0,2})/;
  return numeroAString.match(regex)[0];
}

// Avisa si se está cambiando el estatus a uno anterior
const estatusJerarquicoConsolidado = {
  // Estatus disponibles de los paquetes
  Cargando: 1,
  NavieraAerolinea: 2,
  EnTransito: 3,
  EnPuertoAeropuerto: 4,
  ProcesoAduana: 5,
  EsperaCaracas: 6,
  Descargado: 7,
};
function esRetrocesoEstatus(estatusAnterior, estatusPosterior) {
  const estatusAnteriorPosicion = estatusJerarquicoConsolidado[estatusAnterior];
  const estatusPosteriorPosicion = estatusJerarquicoConsolidado[estatusPosterior];
  if (estatusAnteriorPosicion > estatusPosteriorPosicion) {
    return true;
  }
  return false;
}

const verificarEstatusConsolidado = (estatusAEncontrar) =>
estatusJerarquicoConsolidado[estatusAEncontrar] || 0;

/**
 * @description Objeto con codigos de respuesta del enum 'Respuesta'
 */
const enumRespuestaCodigo = {
  Correcto:"Correcto",
  Fallido:"Fallido",
  ClaveInvalida:"ClaveInvalida",
  UsuarioInvalido:"UsuarioInvalido",
  FaltanDatos:"FaltanDatos",
  UsuarioInactivo:"UsuarioInactivo",
  CambioClave:"CambioClave"
}
// Avisa si se está cambiando el estatus a uno anterior
function esRetrocesoEstatusPaquete(estatusAnterior, estatusPosterior) {
  const estatusJerarquico = {
    // Estatus disponibles de los paquetes
    EsperaMiami: 1,
    Devuelto: 2,
    Reempacado: 3,
    Consolidado: 4,
    NavieraAerolinea: 5,
    TransitoInternacional: 6,
    EnPuertoAeropuerto: 7,
    ProcesoAduana: 8,
    EsperaCaracas: 9,
    ListoDespacho: 10,
    TransitoNacional: 11,
    Entregado: 12,
  };
  const estatusAnteriorPosicion = estatusJerarquico[estatusAnterior];
  const estatusPosteriorPosicion = estatusJerarquico[estatusPosterior];
  if (estatusAnteriorPosicion > estatusPosteriorPosicion) {
    return true;
  }
  return false;
}

// Valida expresión regular de un teléfono, retorna verdadero si es válido y falso si es invalido
function validarTel(telefono) {
  return parseFloat(telefono) && /^\d{7,13}$/.test(telefono) ? true : false;
}

// Valida expresión regular de un email, retorna verdadero si es válido y falso si es invalido
function validarMail(email) {
  return emailReg.test(email) ? true : false;
}

// Obtener marca temporal actual para los números HR
const obtenerMarcaTemporal = () => {
  const d = new Date(),
    ano = d.getFullYear(),
    mes = ('0' + (d.getMonth() + 1)).substr(-2),
    dia = ('0' + d.getDate()).substr(-2),
    hora = ('0' + d.getHours()).substr(-2),
    minuto = ('0' + d.getMinutes()).substr(-2),
    segundo = ('0' + d.getSeconds()).substr(-2);

  return [ano, mes, dia, hora, minuto, segundo].join('');
};

// Elimina propiedades vacias de objetos
function eliminarVacios(objeto) {
  for (var clave in objeto) {
    if (typeof objeto[clave] == 'string') {
      if (objeto[clave] == '') {
        delete objeto[clave];
      }
    } else if (typeof objeto[clave] == 'object') {
      eliminarVacios(objeto[clave]);
    }
  }
  return objeto;
}
// Auto coma y auto "y"
function autoComaYAutoY(array) {
  let mensajeFormateado = '';
  array.map((m, i) => {
    array.length == 1 ? (mensajeFormateado = `${m}`) : '';
    mensajeFormateado +=
      i + 1 > array.length - 1 ? ` y ${m}` : i + 1 == 1 ? `${m}` : `, ${m}`;
  });
  return mensajeFormateado;
}

// Formatea la fecha en un Object con formato {dd-mm, yyyy, dd-mm-yyyy}
function formatearFecha(fecha) {
  const fechaArray = fecha.split('-');
  return {
    ddmm: `${fechaArray[2]}-${fechaArray[1]}`,
    yyyy: fechaArray[0],
    ddmmyyyy: `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`,
  };
}

function diaActual() {
  let fecha = new Date();
  const z = fecha.getTimezoneOffset();
  const v = fecha.valueOf();
  const r = v - z * 1000 * 60;
  fecha = new Date(r);
  return fecha.toISOString().split('T')[0];
}

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}
/**
 * @param {string} url
 * @returns {Boolean}
 * @description chequea si la url es valida usando una expresion regularS
 */
const validarURL = (urlVerificar) => urlReg.test(urlVerificar);

/**
 * @param {string} extension
 * @returns {String}
 * @description Genera un nombre aleatorio basado en la fecha y le agrega la extensión enviada como parametro
 */
function generarNombre(extension) {
  return `${Date.now()}.${extension}`;
}
/**
 *  @param {string} base64
 *  @returns {File}
 * @description Convierte una imagen en base64 a un archivo File en formato jpeg
 * */
function transformarDeBase64AImagen(base64) {
  let binary = atob(base64.split(",")[1]);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new File(
        [new Uint8Array(array)],
        `imagen-${generarNombre('jpeg')}`,
        {
          type: "image/jpeg",
        }
      )
}
export {
  capitalizar,
  diaActual,
  capitalizarPrimeraLetra,
  eliminarVacios,
  emailReg,
  validarTel,
  validarMail,
  multipleFiltrado,
  evitarElementosRepetidos,
  esRetrocesoEstatus,
  esRetrocesoEstatusPaquete,
  evitarTelefonosRepetidos,
  mostrarSoloDosDecimales,
  formatearFecha,
  obtenerMarcaTemporal,
  autoComaYAutoY,
  getBase64ImageFromURL,
  validarURL,
  transformarDeBase64AImagen,
  enumRespuestaCodigo,
  verificarEstatusConsolidado
};

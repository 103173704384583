<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
      @close="cerrarModal"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ titulo }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
       <div v-show="!ocultarCamara">
          <web-cam
          ref="camara"
          :device-id="dispositivoId"
          class="contenedor-camara"
          @started="onStarted"
          @stopped="onStopped"
          @error="onError"
          @cameras="onCameras"
          @camera-change="cambioCamara"
        />
       </div>
       <template v-if="ocultarCamara">
         <p class="font-italic text-muted text-center m-3 p-2">La cámara ha sido detenida, presione el botón Iniciar cámara para continuar.</p>
       </template>

        <div class="row justify-content-around align-items-center mx-0 mt-3">
          <div
            :class="['col-12', ocultarCamara ? 'col-lg' : 'col-lg']"
          >
            <div class="md-form md-outline outline-select my-2">
              <select
                id="dispositivos-select"
                v-model="camera"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="dispositivo in dispositivos"
                  :key="dispositivo.deviceId"
                  :value="dispositivo.deviceId"
                >
                  {{ dispositivo.label }}
                </option>
              </select>
              <label
                for="dispositivos-select"
                :class="camera
                  && camera.length
                    ? 'label-active'
                    : 'label-inactive'"
              >
                Cámaras disponibles
              </label>
            </div>
          </div>
          <div
            v-if="ocultarCamara === false"
            class="col-auto px-0"
          >
            <mdb-btn
              color="primario"
              icon="camera"
              class="px-lg-3"
              @click="onCapture"
            >
              Tomar foto
            </mdb-btn>
          </div>
          <div
            v-if="ocultarCamara === false"
            class="col-auto px-0"
          >
            <mdb-btn
              flat
              dark-waves
              icon="ban"
              class="btn-bordeado px-lg-3"
              @click="
                onStop();
                ocultarCamara = true;
              "
            >
              Detener cámara
            </mdb-btn>
          </div>
          <div
            v-if="ocultarCamara === true"
            class="col-auto mt-3 mt-lg-0"
          >
            <mdb-btn
              color="terciario"
              icon="camera"
              @click="onStart"
            >
              Inciar cámara
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import AlertaMensaje from "./AlertaMensaje.vue"
import { WebCam } from "vue-web-cam";
import { head } from "lodash";
import {transformarDeBase64AImagen} from '../funciones/funciones'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
export default {
  name: "ModalWebCam",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    WebCam,
    AlertaMensaje
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
      default: "Tomar foto usando cámara",
    },
  },
  data() {
    return {
      camera: null,
      dispositivoId: null,
      dispositivos: [],
      ocultarCamara: false,
      alertaMensaje:{
        contenido: '',
        tipo: '',
      }
    };
  },
  watch: {
    camera: function (id) {
      this.dispositivoId = id;
    },
    dispositivos: function () {
      let first = head(this.dispositivos);
      if (first) {
        this.camera = first.deviceId;
        this.dispositivoId = first.deviceId;
      }
    },
  },
  methods: {
    onCapture() {
      const resultado = transformarDeBase64AImagen(this.$refs.camara.capture());
      this.$emit("capturar", resultado);
      this.alerta("Foto tomada correctamente", "correcto");
      this.cerrarModal();
    },
     alerta(contenido, tipo) {
      this.alertaMensaje = {
        contenido,
        tipo,
      };
    },
    cerrarModal() {
      this.$emit("cerrar", false);
      this.img = null;
      this.ocultarCamara = false;
      this.dispositivos = [];
      this.camera = null;
      this.dispositivoId = null;
    },
    onStarted() {
      this.ocultarCamara = false;
    },
    onStopped() {
      this.ocultarCamara = true;
    },
    onStop() {
      this.$refs.camara.stop();
    },
    onStart() {
      this.$refs.camara.start();
    },
    onError(error) {
      this.alerta(`Ocurrió un error con la cámara: ${error} `, "error");
    },
    onCameras(camaras) {
      this.dispositivos = camaras;
    },
    cambioCamara(dispositivoId) {
      this.dispositivoId = dispositivoId;
      this.camera = dispositivoId;
    },
  },
};
</script>
<style lang="scss" scoped>
.contenedor-camara {
  height: auto;
  max-height: 450px;
  width: 100%;
}
</style>

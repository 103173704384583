<template>
  <div class="vista-detalles agencia">
    <div class="container-xl px-0">
      <div
        v-if="!$apolloData.queries.agencia.loading"
        class="row align-items-center justify-content-center mt-3"
      >
        <router-link
          to="/agencias"
          class="col-auto order-md-1 text-reset"
        >
          <mdb-btn
            flat
            dark-waves
            icon="angle-left"
            icon-class="fa-lg"
            class="btn-bordeado px-lg-3"
          >
            Volver atrás
          </mdb-btn>
        </router-link>
        <div
          :class="['col-auto order-md-3', {'d-none d-md-block': !agencia}, 'botonFlotante']"
          style="width: 174.27px"
        >
          <mdb-btn
            color="primario"
            icon="redo-alt"
            :disabled="botonDeshabilitado"
            class="my-sm-0 px-3 text-nowrap"
            @click="paqueteActualizar('filtro')"
          >
            Actualizar
          </mdb-btn>
        </div>
        <h3 class="col-12 col-md order-md-2 mt-2 text-center user-select-none">
          Agencia
          <span v-if="agencia && agencia.nombre">{{ agencia.nombre }}</span>
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.agencia.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.agencia.loading && !agencia"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          La agencia solicitada no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row">
          <!-- Logo y colores de la agencia -->
          <div
            v-if="agencia"
            class="col-12 col-xl-7"
          >
            <div class="row">
              <!-- Logo -->
              <div class="col-12 col-sm mb-3">
                <small class="text-muted user-select-none d-block mb-2">
                  Logo
                </small>
                <!-- Existe logo -->
                <div v-if="agencia.logo">
                  <img
                    alt="logo de la agencia"
                    :src="agencia.logo
                      ? `${endpointPublicBackend}${agencia.logo.url}`
                      : ''"
                    height="100"
                    class="d-block"
                  />
                  <mdb-btn
                    flat
                    dark-waves
                    :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
                    :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                    :disabled="botonDeshabilitado"
                    class="btn-bordeado py-2 px-3 px-md-2"
                    @click="tituloConfirmarEliminar='Se eliminará el logo de la agencia';
                    mostrarModalConfirmarEliminar = true; sustantivoFemenino=false;"
                  >
                    Eliminar logo
                  </mdb-btn>
                </div>
                <!-- No existe logo -->
                <div v-else>
                  <span class="text-muted user-select-none d-block">
                    Sin logo disponible
                  </span>
                  <div class="row align-items-center justify-content-center">
                    <div
                      v-if="esElegirFoto"
                      class="col-12 col-sm col-md-12"
                    >
                      <div
                        class="outline-input-file"
                        data-size="sm"
                      >
                        <label for="input-file-images" class="label-file">
                          <font-awesome-icon icon="upload" class="mr-1" />
                          Elegir Logo
                        </label>
                        <span class="contenido">
                          {{
                            foto
                            ? 'Se ha seleccionado un archivo'
                            : 'No se eligió archivo'
                          }}
                        </span>
                        <input
                          id="input-file-images"
                          type="file"
                          accept="image/*"
                          class="custom-file-input"
                          @change="handleInputChange"
                        />
                        <mdb-btn
                          v-if="foto"
                          flat
                          dark-waves
                          icon="times"
                          class="file-button m-0 p-2"
                          title="Eliminar fotos seleccionadas"
                          @click="foto=null"
                        />
                      </div>
                    </div>
                    <div
                      v-if="esElegirFoto"
                      class="col-auto px-1"
                    >
                      <mdb-btn
                        color="exitoso"
                        :icon="botonDeshabilitado
                          ? 'circle-notch' : 'check'"
                        :icon-class="[{'fa-spin': botonDeshabilitado}]"
                        class="my-2 mx-1 py-2 px-3"
                        :disabled="botonDeshabilitado || foto ? false : true"
                        @click="agregarLogo"
                      >
                        Agregar
                      </mdb-btn>
                    </div>
                    <div class="col-auto px-1">
                      <mdb-btn
                        flat
                        dark-waves
                        :title="esElegirFoto ? 'Cancelar' : 'Seleccionar logo de la agencia'"
                        :icon="esElegirFoto ? 'times' : 'images'"
                        class="btn-bordeado my-2 mx-1 py-2 px-3"
                        @click="esElegirFoto = !esElegirFoto; foto = null"
                      >
                        {{ esElegirFoto ? 'Cancelar' : 'Establecer logo' }}
                      </mdb-btn>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Colores -->
              <div class="col-12 col-sm mb-3">
                <small class="text-muted user-select-none d-block mb-2">
                  Colores empresariales
                </small>
                <div>
                  <span
                    class="color-container"
                    :style="agencia.coloresEmpresariales !== null
                      ? `background-color: ${agencia.coloresEmpresariales.primario}` : ''"
                  />
                  <span
                    class="color-container"
                    :style="agencia.coloresEmpresariales !== null
                      ? `background-color: ${agencia.coloresEmpresariales.terciario}` : ''"
                  />
                  <span
                    class="color-container"
                    :style="agencia.coloresEmpresariales !== null
                      ? `background-color: ${agencia.coloresEmpresariales.fondoBarra}` : ''"
                  />
                  <span
                    class="color-container"
                    :style="agencia.coloresEmpresariales !== null
                      ? `background-color: ${agencia.coloresEmpresariales.textoBarra}` : ''"
                  />
                </div>
                <mdb-btn
                  flat
                  dark-waves
                  :icon="botonDeshabilitado ? 'circle-notch' : 'palette'"
                  :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                  :disabled="botonDeshabilitado"
                  class="btn-bordeado mx-0 py-2 px-3 px-md-2"
                  @click="mostrarModalColoresEmpresariales = true"
                >
                  Establecer colores
                </mdb-btn>
              </div>
            </div>
          </div>
          <!-- Teléfonos -->
          <div :class="['col-12', {'col-xl-5': agencia}, 'mb-3']">
            <header
              class="user-select-none cursor-pointer mt-2"
              title="Agregar nuevo teléfono"
              @click="mostrarNuevoTelefono = !mostrarNuevoTelefono"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Teléfonos
            </header>
            <!-- Formulario nuevo teléfono -->
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-sm">
                <mdb-input
                  v-if="mostrarNuevoTelefono"
                  v-model.number="nuevoTelefono"
                  type="tel"
                  label="Teléfono"
                  placeholder="Ej: 58426573828"
                  minlength="7"
                  maxlength="13"
                  class="my-2 plus-addon"
                  outline
                  @keyup.enter.native="validarTelefono(nuevoTelefono, true)"
                >
                  <span class="input-group-text md-addon" slot="prepend">+</span>
                </mdb-input>
              </div>
              <div class="col-auto">
                <mdb-btn
                  v-if="mostrarNuevoTelefono"
                  color="exitoso"
                  icon="sync"
                  title="Agregar teléfono"
                  class="m-1 py-2 px-3"
                  @click="validarTelefono(nuevoTelefono, true)"
                >
                  Agregar
                </mdb-btn>
              </div>
            </div>
            <!-- Listado de teléfonos -->
            <div v-if="agencia && agencia.telefonos && agencia.telefonos.length">
              <ul class="list-unstyled mb-2 row">
                <li v-for="(tel, i) in agencia.telefonos" :key="`tel${i}`" :class="['col-12', {'col-sm-6 col-xl-12': agencia.telefonos.length > 1}]">
                  <!-- Si se selecciona un teléfono para editar, se sustituirá el número teléfono por un
                  input que permite editarlo  -->
                  <div v-if="editarTelefono">
                    <div
                      v-if="telefonoEditarSeleccionado
                        && telefonoAEditar.telefonoIndice===i"
                    >
                      <mdb-input
                        v-model.number="telefonoAEditar.telefonoNuevo"
                        type="tel"
                        label="Teléfono"
                        minlength="7"
                        maxlength="13"
                        class="my-2 plus-addon"
                        outline
                        @keyup.enter.native="validarTelefono(telefonoAEditar.telefonoNuevo)"
                      >
                        <span class="input-group-text md-addon" slot="prepend">+</span>
                      </mdb-input>
                      <div class="mb-2 text-center">
                        <mdb-btn
                          color="exitoso"
                          icon="sync"
                          title="Actualizar teléfono"
                          class="m-1 py-2 px-3"
                          @click="validarTelefono(telefonoAEditar.telefonoNuevo)"
                        >
                          Actualizar
                        </mdb-btn>
                        <mdb-btn
                          flat
                          dark-waves
                          icon="times"
                          title="Cancelar edición del teléfono"
                          class="m-1 p-2 px-md-3"
                          @click="telefonoEditarSeleccionado = false;"
                        >
                          Cancelar
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else class="mb-2">
                      <span  class="d-block">
                        +{{tel.numero}}
                      </span>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoEditarSeleccionado = true;
                          telefonoAEditar = {
                            telefonoIndice: i,
                            telefonoNuevo: tel.numero,
                            telefonoAnterior: tel.numero
                          };"
                      >
                        Editar
                      </mdb-btn>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="trash"
                        title="Eliminar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoRemover(tel.numero)"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                  <div v-else>
                    <a
                      :href="`tel:+${tel.numero}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{{tel.numero}}
                    </a>
                    <div class="d-inline-block">
                      <a
                        :href="`https://wa.me/${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon :icon="['fab', 'whatsapp']" />
                        WA
                      </a>
                      <a
                        :href="`sms:+${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon icon="sms" />
                        Sms
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Botón para editar teléfonos -->
              <mdb-btn
                block
                flat
                dark-waves
                :icon="botonEditarTelefono.icono"
                :title="botonEditarTelefono.title"
                class="m-0 mr-2 px-3 py-2"
                @click="editarTelefono = !editarTelefono"
              >
                {{ botonEditarTelefono.title }}
              </mdb-btn>
            </div>
            <div
              v-else
              class="text-muted user-select-none"
            >
              Sin teléfonos registrados
            </div>
          </div>
        </div>
        <div
          class="row"
        >
          <div v-if="agencia.codigo" class="col-12 col-sm-6 mb-3">
            <small class="text-muted user-select-none d-block">
              Enlace de registro
            </small>
            <a
              :href="url+agencia.codigo"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{url + agencia.codigo}}
            </a>
            <mdb-btn
              v-clipboard="`${url}${agencia.codigo} `"
              v-clipboard:success="clipboardSuccessHandler"
              flat
              dark-waves
              class="btn-bordeado"
              icon="copy"
            >
              Copiar enlace
            </mdb-btn>
          </div>
          <!-- Prefijo -->
          <div class="col-12 col-md-6 mb-3">
            <small class="text-muted user-select-none d-block">
              Prefijo
            </small>
            <span :class="{'text-muted user-select-none': !(agencia.prefijo)}">
              {{ agencia.prefijo ? agencia.prefijo : 'Sin especificar' }}
            </span>
          </div>
        </div>
        <div
          v-if="agencia && agencia.direccion && agencia.direccion.direccion
            && agencia.direccion.direccion.length"
          class="row justify-content-center mt-3"
        >
          <!-- Dirección -->
          <div class="col-12 col-xl-9">
            <small class="text-muted user-select-none d-block">
              Dirección
            </small>
            {{ agencia.direccion.direccion }}.
            {{ agencia.direccion.ciudad.nombre }},
            {{ agencia.direccion.ciudad.estado.nombre }},
            {{ agencia.direccion.ciudad.estado.pais.nombre }}.
          </div>
          <div class="col-12 col-xl-3">
            <small class="text-muted user-select-none d-block">
              Aliado
            </small>
            <span :class="{'text-muted user-select-none': !(agencia.direccion.aliado && agencia.direccion.aliado.nombre)}">
              {{ agencia.direccion.aliado && agencia.direccion.aliado.nombre ? agencia.direccion.aliado.nombre : 'Sin especificar' }}
            </span>
          </div>
          <!-- Tasas -->
          <div class="col-12 mt-2 mt-xl-0">
            <div class="row">
              <div class="col-6 col-xl mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa aérea
                </small>
                <span :class="{'text-muted user-select-none': !agencia.direccion.tasaAerea}">
                  {{ agencia.direccion.tasaAerea ? agencia.direccion.tasaAerea : 'Sin especificar' }}
                </span>
              </div>
              <div class="col-6 col-xl mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa marítima
                </small>
                <span :class="{'text-muted user-select-none': !agencia.direccion.tasaMaritima}">
                  {{ agencia.direccion.tasaMaritima ? agencia.direccion.tasaMaritima : 'Sin especificar' }}
                </span>
              </div>
              <div class="col-6 col-xl mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa mín. aérea
                </small>
                <span :class="{'text-muted user-select-none': !agencia.direccion.minAerea}">
                  {{ agencia.direccion.minAerea ? agencia.direccion.minAerea : 'Sin especificar' }}
                </span>
              </div>
              <div class="col-6 col-xl mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa mín. marítima
                </small>
                <span :class="{'text-muted user-select-none': !agencia.direccion.minMaritima}">
                  {{ agencia.direccion.minMaritima ? agencia.direccion.minMaritima : 'Sin especificar' }}
                </span>
              </div>
            </div>
          </div>
          <mdb-btn
            flat
            dark-waves
            icon="pen"
            class="col-auto my-sm-0 mt-xl-n1 px-3"
            @click="esEditarDirección=true; direccionSeleccionada = agencia.direccion;
            mostrarModalEditarEnvio=!mostrarModalEditarEnvio;"
          >
            Editar dirección
          </mdb-btn>
          <mdb-btn
            flat
            icon="handshake"
            class="col-auto my-sm-0 mt-xl-n1 px-3"
            @click="mostrarModalSeleccionarAliado=!mostrarModalSeleccionarAliado;
              direccionSeleccionada = agencia.direccion;"
          >
            {{ agencia.direccion.aliado && agencia.direccion.aliado.nombre ? 'Cambiar' : 'Asignar' }} aliado
          </mdb-btn>
          <mdb-btn
            v-if="roles.includes('Admin')"
            flat
            dark-waves
            icon="trash"
            class="col-auto my-sm-0 mt-xl-n1 px-3"
            @click="IdDireccionAEliminar = agencia.direccion.id;
              mostrarModalConfirmarEliminar = true;
              tituloConfirmarEliminar='Se eliminará la dirección en su totalidad'
              sustantivoFemenino= true"
            >
              Remover Dirección
          </mdb-btn>
        </div>
        <div v-else>
          <small class="text-muted user-select-none d-block">
            Dirección
          </small>
          <span class="text-muted user-select-none">
            Sin especificar
          </span>
          <mdb-btn
            flat
            dark-waves
            icon="plus"
            size="sm"
            class="btn-bordeado my-sm-0 py-2 px-3"
            @click="mostrarModalEditarEnvio=!mostrarModalEditarEnvio"
          >
            Agregar dirección
          </mdb-btn>
        </div>

        <!-- Filtros -->
        <div class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0">
          <h4 class="col-12 pl-lg-0 text-center text-md-left user-select-none">
            Paquetes
          </h4>
          <div class="col-auto px-2 pl-lg-0">
            <mdb-btn
              flat
              dark-waves
              icon="list"
              :disabled="botonDeshabilitado"
              class="btn-bordeado ml-lg-0 py-2 px-3"
              @click="limpiarCliente= true; limpiarPaquete = true; buscarPor = {
                estatus : '',
                entregado : false,
                  cliente:{
                    id:''
                  },
                  paqueteId:'',
              }; paqueteActualizar();"
            >
              Todos
            </mdb-btn>
          </div>
          <div class="col-12 col-sm col-xl-auto pl-sm-2 pr-md-2">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="estatus-select"
                v-model="buscarPor.estatus"
                class="custom-select"
                @change="paqueteActualizar('estatus')"
              >
                <option class="d-none" disabled value="">Seleccione</option>
                <option
                  v-for="(value, key) in PaqueteEstatus"
                  :key="key"
                  :value="key"
                >
                  {{ value }}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
          </div>
          <div class="col-12 col-md col-xl-6">
            <ClienteBuscador
              id="clienteBuscador-1"
              class="my-2"
              placeholder="Ingrese nombre o casillero del cliente"
              :limpiarCliente="limpiarCliente"
              @cliente="obtenerClienteBuscador($event)"
              @limpiar="limpiarCliente = $event"
            />
            <label
              for="clienteBuscador-form"
              class="outline-datalist-label top-8"
            >
              Buscar por cliente
            </label>
          </div>
          <div class="col-12 col-xl-6 ml-lg-n3 pr-lg-0">
            <PaqueteBuscador
              id="paqueteBuscador-form"
              class="my-2"
              placeholder="Ingrese el tracking o paquete ID"
              :limpiarPaquete="limpiarPaquete"
              @paquete="obtenerPaqueteBuscador($event)"
              @limpiar="limpiarPaquete = $event"
            />
            <label
              for="paqueteBuscador-form"
              class="outline-datalist-label top-8"
            >
              Buscar por tracking
            </label>
          </div>
          <div
            v-if="agencia.paquetes.length"
            class="col-12 col-sm-auto pr-sm-2 pl-lg-0 pl-xl-3"
          >
            <mdb-btn
              block
              flat
              dark-waves
              class="btn-bordeado my-2 py-2 px-3"
              icon="check-square"
              :icon-class="[{'texto-terciario': paquetesId.length}]"
              @click="seleccionarTodo"
            >
              {{ textoBotonSeleccionar }}
            </mdb-btn>
          </div>
          <div class="col-12 col-sm-auto px-sm-2 pl-lg-1">
            <mdb-btn
              block
              flat
              dark-waves
              icon="file-invoice"
              :disabled="botonDeshabilitado"
              class="btn-bordeado my-2 py-2 px-3"
              @click="listarOrdenesReempaque=true;mostrarOrdenesReempaque=!mostrarOrdenesReempaque"
            >
              Órdenes de reempaque
            </mdb-btn>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="custom-control custom-switch py-2">
            <input
              id="check-entregado"
              v-model="buscarPor.entregado"
              @change="paqueteActualizar('entregado')"
              type="checkbox"
              name="checkbox-entregado"
              class="custom-control-input"
            >
            <label class="custom-control-label" for="check-entregado">
              Mostrar entregados y devueltos
            </label>
          </div>
          </div>
          <div
            v-if="paquetesId.length"
            :class="['col-auto pl-0 pr-2 pr-md-0 botones-opcionales',
              { 'mostrar animated': paquetesId.length }]"
          >
            <div
              :class="['d-inline-block my-1 mr-2 mr-md-1 bg-white', {'cursor-not-allowed': (botonDeshabilitado || !paquetesTipoIndividual)
                  || !paquetesMismoCliente}]"
              title="Solo se permite en paquetes de tipo individual del mismo cliente"
            >
              <mdb-btn
                color="terciario"
                icon="box-open"
                :disabled="(botonDeshabilitado || !paquetesTipoIndividual) || !paquetesMismoCliente"
                class="m-0 px-3"
                @click="verificarPaquetesAReempacar"
              >
                Nueva orden de reempaque
              </mdb-btn>
              <!-- <mdb-btn
                color="terciario"
                icon="box-open"
                :disabled="(botonDeshabilitado || !paquetesTipoIndividual) || !paquetesMismoCliente"
                title="Solo se permite en paquetes de tipo individual del mismo cliente"
                class="px-3"
                @click="paqueteReempacar"
              >
                Reempacar
              </mdb-btn> -->
            </div>
            <mdb-btn
              color="terciario"
              icon="clipboard-check"
              :disabled="botonDeshabilitado"
              class="mr-2 mr-md-1 px-3 d-block d-md-inline-block"
              @click="verificarPaquetesAInstruccionEnvio"
            >
              Asignar instrucción de envío
            </mdb-btn>
            <mdb-btn
              class="my-1 mr-2 mr-md-0 px-3"
              color="terciario"
              icon="file-signature"
              :disabled="paquetesId.length ? false : true"
              @click="validarModalEntregarPaquete"
            >
              Entregar paquete{{
                paquetesId.length > 1 ? 's' : ''
              }}
            </mdb-btn>
          </div>
        </div>
        <!-- Tabla -->
        <ul data-table="sticky" class="table mb-page">
          <li class="encabezado row align-items-end">
            <div class="col-3 col-lg-2 d-none d-md-block px-md-0">
              Fecha recibido
            </div>
            <div class="col pl-4 pl-md-0">
              Detalles
            </div>
            <div class="col-3 d-none d-lg-block">Cliente</div>
            <div class="col d-none d-sm-block">Estatus</div>
          </li>
          <li
            v-for="paquete in agencia.paquetes"
            :key="`paquete-${paquete.id}`"
            class="contenido row align-items-center"
            @click="paquetesId = []; $router.push({ name: 'AgenciaPaquete', params: { paqueteId: paquete.id } })"
          >
            <div class="col-3 col-lg-2 d-none d-md-block">
              <label
                class="opciones-checkbox"
                title="Seleccionar paquete"
                @click.stop
              >
                <input
                  v-model="paquetesId"
                  type="checkbox"
                  name="selecionar-paquete-checkbox"
                  :value="paquete"
                />
                <span class="icono-checkbox" />
                {{ paquete.fecha.formatted }}
              </label>
            </div>
            <div class="col px-sm-0">
              <label
                class="opciones-checkbox py-2 d-block d-md-none"
                title="Seleccionar paquete"
                @click.stop
              >
                <input
                  v-model="paquetesId"
                  type="checkbox"
                  name="selecionar-paquete-checkbox"
                  :value="paquete"
                />
                <span class="icono-checkbox" />
                {{ paquete.fecha.formatted }}
              </label>
              Receptor:
              <span :class="paquete.receptor ? '' : 'text-muted user-select-none'">
                {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
              </span>
              <p class="mb-0">
                Paquete ID:
                <span :class="paquete.numeroHR ? '' : 'text-muted user-select-none'">
                  {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
                </span>
              </p>
              <p class="mb-0">
                Tracking:
                <span :class="paquete.tracking ? '' : 'text-muted user-select-none'">
                  {{ paquete.tracking ? paquete.tracking : "Sin especificar" }}
                </span>
              </p>
              <p class="mb-0">
                <span title="Peso por volumen">
                  vlbs: {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                  }}
                  |
                </span>
                <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span>
                <span title="Pies cúbicos">
                  ft<sup>3</sup>: {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                  }}
                </span>
              </p>
              Tipo: {{ paquete.tipo }}
              <p
                v-if="paquete.observacion && paquete.observacion.length"
                class="mb-0 texto-line-clamp"
              >
                Observación: {{ paquete.observacion }}
              </p>
              <div
                v-if="paquete && paquete.cliente &&
                  paquete.cliente.nombre && paquete.cliente.nombre.length"
                class="d-lg-none pt-2"
              >
                Cliente: {{ paquete.cliente.nombre }}
                <span
                  class="btn btn-flat btn-sm btn-bordeado px-3"
                  @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                    objetoAlConfirmarPermiso = paquete;"
                >
                  Cambiar
                </span>
                <div
                  v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length
                    || paquete.cliente.emails && paquete.cliente.emails.length"
                  class="row ml-n1 mr-0 my-1 mr-sm-3"
                >
                  <template v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length && paquete.cliente.telefonos[0].numero">
                    <div class="col px-0">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`tel:+${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        icon="phone-alt"
                        icon-class="fa-2x"
                        size="sm"
                        class="btn-bordeado m-1 px-1 py-2"
                        title="Llamada"
                        @click.native.stop
                      />
                    </div>
                    <div class="col px-1">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`sms:+${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        icon="sms"
                        icon-class="fa-2x"
                        size="sm"
                        class="btn-bordeado m-1 px-1 py-2"
                        tile="SMS"
                        @click.native.stop
                      />
                    </div>
                    <div class="col px-0">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`https://wa.me/${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        fab
                        icon="whatsapp"
                        icon-class="fa-2x"
                        size="sm"
                        class="btn-bordeado m-1 px-1 py-2"
                        title="WhatsApp"
                        @click.native.stop
                      />
                    </div>
                  </template>
                  <div
                    v-if="paquete.cliente.emails && paquete.cliente.emails.length && paquete.cliente.emails[0].email"
                    class="col col-xl pl-1 pr-0"
                  >
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`mailto:${paquete.cliente.emails[0].email}`"
                      role="button"
                      icon="envelope"
                      icon-class="fa-2x"
                      size="sm"
                      class="btn-bordeado m-1 px-1 py-2"
                      title="E-mail"
                      @click.native.stop
                    />
                  </div>
                </div>
              </div>
              <p
                v-else
                class="d-lg-none my-1"
              >
                Cliente:
                <span
                  class="btn btn-flat ripple-parent btn-sm btn-bordeado m-1 px-3"
                  @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                    objetoAlConfirmarPermiso = paquete;"
                >
                  Sin asignar
                </span>
              </p>
              <p
                v-if="paquete.observacionEnvio"
                class="mb-0 mt-2"
              >
                Nota de entrega: {{paquete.observacionEnvio}}
              </p>
              <div v-if="paquete.estatus==='Entregado'">
                <mdb-btn
                  color="terciario"
                  icon="upload"
                  size="sm"
                  class="px-2"
                  title="Haz clic para elegir las fotos de entrega"
                  rounded
                  @click.native.stop="esFoto = true;
                    mostrarModalEntregarPaquete=!mostrarModalEntregarPaquete;
                    paquetesId=[paquete]; idPaquetesAEntregar=[paquete.id]"
                >
                  Fotos de entrega
                </mdb-btn>
              </div>
              <div class="d-sm-none pt-2">
                <div
                  v-if="paquete && paquete.cliente
                    && paquete.cliente.nombre && paquete.envio && paquete.cliente.nombre.length"
                >
                  Envío:
                  <span :class="paquete.envio ? '' : 'text-muted user-select-none'">
                    {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : "Sin especificar" }}
                  </span>
                </div>
                Estatus:
                <span :class="paquete.estatus ? '' : 'text-muted user-select-none'">
                  {{
                    paquete.estatus
                      ? PaqueteEstatus[paquete.estatus]
                      : "Sin especificar"
                  }}
                </span>
                <div
                  v-if="paquete && paquete.cliente
                    && paquete.cliente.nombre && paquete.agenciaDestino
                    && paquete.cliente.nombre.length
                    && paquete.agenciaDestino.nombre
                    && paquete.agenciaDestino.nombre.length
                    "
                >
                  Agencia destino: {{ paquete.agenciaDestino.nombre }}
                </div>
                <div>
                  Consolidado:
                  <span v-if="paquete.consolidado
                    && paquete.consolidado.master">
                    {{ paquete.consolidado.master }}
                  </span>
                  <span
                    v-else
                    class="text-muted user-select-none"
                  >
                    Sin asignar
                  </span>
                </div>
                <div
                  v-if="paquete && paquete.cliente &&
                  paquete.cliente.nombre"
                >
                  Dirección de envío:
                  <span v-if="paquete && paquete.direccionDestino
                    && paquete.direccionDestino.ciudad
                    && paquete.direccionDestino.direccion
                    && paquete.direccionDestino.ciudad.estado.nombre
                    && paquete.direccionDestino.ciudad.estado.nombre.length"
                  >
                    {{ paquete.direccionDestino.ciudad.estado.nombre }},
                    {{ paquete.direccionDestino.ciudad.nombre }},
                    {{ paquete.direccionDestino.direccion }}
                  </span>
                  <span v-else class="mr-2 text-muted user-select-none">Sin especificar</span>
                </div>
                <mdb-btn
                  v-if="!paquete.pagado"
                  flat
                  dark-waves
                  :disabled="botonDeshabilitado"
                  icon="file-invoice-dollar"
                  size="sm"
                  class="my-1 p-2 btn-bordeado"
                  title="Asignar pago"
                  @click.native.stop="validarModalAsignarPago(paquete)"
                >
                  Asignar pago
                </mdb-btn>
                <span
                  v-else
                  class="font-weight-bold texto-exitoso user-select-none"
                >
                  <font-awesome-icon icon="check-circle" />
                  Pagado
                </span>
              </div>
            </div>
            <div class="col-3 d-none d-lg-block">
              <div
                v-if="paquete && paquete.cliente &&
                  paquete.cliente.nombre && paquete.cliente.nombre.length"
              >
                {{ paquete.cliente.nombre }}
                <span
                  class="btn btn-flat btn-sm btn-bordeado px-3"
                  @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                    objetoAlConfirmarPermiso = paquete;"
                >
                  Cambiar
                </span>
                <div
                  v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length
                    || paquete.cliente.emails && paquete.cliente.emails.length"
                  class="row ml-n1 mr-0 my-1 mr-sm-3"
                >
                  <template v-if="paquete.cliente.telefonos && paquete.cliente.telefonos.length && paquete.cliente.telefonos[0].numero">
                    <div class="col col-xl-auto px-0">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`tel:+${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        icon="phone-alt"
                        icon-class="fa-lg"
                        size="sm"
                        class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                        title="Llamada"
                        @click.native.stop
                      />
                    </div>
                    <div class="col col-xl-auto px-1">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`sms:+${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        icon="sms"
                        icon-class="fa-lg"
                        size="sm"
                        class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                        title="SMS"
                        @click.native.stop
                      />
                    </div>
                    <div class="col col-xl-auto px-0">
                      <mdb-btn
                        block flat dark-waves
                        tag="a"
                        :href="`https://wa.me/${paquete.cliente.telefonos[0].numero}`"
                        role="button"
                        fab
                        icon="whatsapp"
                        icon-class="fa-lg"
                        size="sm"
                        class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                        title="WhatsApp"
                        @click.native.stop
                      />
                    </div>
                  </template>
                  <div
                    v-if="paquete.cliente.emails && paquete.cliente.emails.length
                      && paquete.cliente.emails[0].email"
                    :class="['col col-xl-auto px-0',
                      {'pl-1': paquete.cliente.telefonos && paquete.cliente.telefonos.length}]"
                  >
                    <mdb-btn
                      block flat dark-waves
                      tag="a"
                      :href="`mailto:${paquete.cliente.emails[0].email}`"
                      role="button"
                      icon="envelope"
                      icon-class="fa-lg"
                      size="sm"
                      class="btn-bordeado m-1 px-0 py-3 p-xl-2"
                      title="E-mail"
                      @click.native.stop
                    />
                  </div>
                </div>
              </div>
              <span
                v-else
                class="btn btn-flat ripple-parent btn-sm btn-bordeado m-1 px-3"
                @click.stop="metodoAlConfirmar='asignarCliente'; mostrarModalConfirmarPermiso = true;
                  objetoAlConfirmarPermiso = paquete;"
              >
                Sin asignar
              </span>
            </div>
            <div class="col d-none d-sm-block">
              <div
                v-if="paquete && paquete.cliente
                  && paquete.cliente.nombre && paquete.envio && paquete.cliente.nombre.length"
              >
                Envío:
                <span :class="paquete.envio ? '' : 'text-muted user-select-none'">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : "Sin especificar" }}
                </span>
              </div>
              Estatus:
              <span :class="paquete.estatus ? '' : 'text-muted user-select-none'">
                {{
                  paquete.estatus
                    ? PaqueteEstatus[paquete.estatus]
                    : "Sin especificar"
                }}
              </span>
              <div
                v-if="paquete && paquete.cliente
                  && paquete.cliente.nombre && paquete.agenciaDestino
                  && paquete.cliente.nombre.length
                  && paquete.agenciaDestino.nombre
                  && paquete.agenciaDestino.nombre.length
                  "
              >
                Agencia destino: {{ paquete.agenciaDestino.nombre }}
              </div>
              <div>
                Consolidado:
                <span v-if="paquete.consolidado
                  && paquete.consolidado.master">
                  {{ paquete.consolidado.master }}
                </span>
                <span
                  v-else
                  class="text-muted user-select-none"
                >
                  Sin asignar
                </span>
              </div>
              <div
                v-if="paquete && paquete.cliente &&
                paquete.cliente.nombre"
              >
                Dirección de envío:
                <span v-if="paquete && paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion
                  && paquete.direccionDestino.ciudad.estado.nombre
                  && paquete.direccionDestino.ciudad.estado.nombre.length"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="mr-2 text-muted user-select-none">Sin especificar</span>
                <mdb-btn
                  flat
                  dark-waves
                  icon="plus"
                  size="sm"
                  class="btn-bordeado m-0 p-2"
                  @click.native.stop="mostrarModalSeleccionarDireccion = true;
                    paqueteSeleccionado = paquete;"
                >
                  {{ paquete && paquete.direccionDestino ? 'Cambiar' : 'Asignar'}}
                </mdb-btn>
              </div>
              <mdb-btn
                v-if="!paquete.pagado"
                flat
                dark-waves
                :disabled="botonDeshabilitado"
                icon="file-invoice-dollar"
                size="sm"
                class="my-1 ml-0 p-2 btn-bordeado"
                title="Asignar pago"
                @click.native.stop="validarModalAsignarPago(paquete)"
              >
                Asignar pago
              </mdb-btn>
              <span
                v-else
                class="font-weight-bold texto-exitoso user-select-none"
              >
                <font-awesome-icon icon="check-circle" />
                Pagado
              </span>
            </div>
          </li>
          <li
            v-if="!(agencia.paquetes && agencia.paquetes.length)"
            class="no-items"
          >
            No hay paquetes en la agencia
          </li>
        </ul>
      </div>
    </div>
    <!-- Modal Seleccionar Dirección -->
    <ModalSeleccionarDireccion
      :mostrar-modal="mostrarModalSeleccionarDireccion"
      :paqueteSeleccionado="paqueteSeleccionado"
      :esAgencia="true"
      :variablesQueryAgencia="variablesQueryAgencia"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalSeleccionarDireccion = $event; paqueteSeleccionado = {}"
    />
    <!-- Modal Seleccionar Pago -->
    <ModalAsignarPago
      :mostrar-modal="mostrarModalAsignarPago"
      :esPaquete="true"
      :esAgencia="true"
      :entidad="paquetesId && paquetesId.length ? paquetesId[0] : {}"
      :monto-a-cubrir="costoPaqueteAsignar"
      :cliente="paquetesId && paquetesId.length && paquetesId[0].cliente
        ? paquetesId[0].cliente
        : { id:'' }"
      :variablesQueryAgencia="variablesQueryAgencia"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarPago = $event;"
    />
    <!-- Modal Ordenes de reempaque -->
    <ModalOrdenReempaque
      :mostrar-modal="mostrarOrdenesReempaque"
      :titulo="tituloModalOrdenesReempaque"
      :listarOrdenes="listarOrdenesReempaque"
      :paquetes="paquetesId"
      :clienteId="paquetesId && paquetesId.length
        && paquetesId[0].cliente
          ? paquetesId[0].cliente.id
          : ''"
      :esAgencia="true"
      :agenciaId="agencia && agencia.id ? agencia.id : ''"
      @alertaMensaje="alertaMensaje=$event"
      @paginacionResetear="paqueteActualizar('filtro')"
      @close="mostrarOrdenesReempaque = $event;
        tituloModalOrdenesReempaque='Ordenes de reempaque';
        listarOrdenesReempaque=false;
        paquetesId = [];"
    />
    <!-- Modal entregar paquete -->
    <ModalEntregarPaquete
      :mostrar-modal="mostrarModalEntregarPaquete"
      :paquetesId="idPaquetesAEntregar"
      :paquetes="paquetesAEntregar"
      :es-foto="esFoto"
      :variablesQueryAgencia="variablesQueryAgencia"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalEntregarPaquete = $event; paquetesId = [];
        idPaquetesAEntregar=[]; paquetesAEntregar=[]; esFoto = false; paqueteActualizar('filtro')"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      :mensaje="tituloConfirmarEliminar"
      :sustantivoFemenino="sustantivoFemenino"
      @cerrar="mostrarModalConfirmarEliminar = $event"
      @eliminar="sustantivoFemenino ? direccionRemover() : eliminarLogo()"
    />
    <!-- Modal Editar datos de envio -->
    <ModalDatosEnvio
      :mostrar-modal="mostrarModalEditarEnvio"
      :es-editar="esEditarDirección"
      :es-agencia="true"
      :variablesQueryAgencia="variablesQueryAgencia"
      :clienteEspecificado="agencia ? agencia : {}"
      :datosDireccion="direccionSeleccionada ? direccionSeleccionada : ''"
      @creado="agencia.direccion=$event;"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalEditarEnvio = $event; limpiarDatos();"
    />
    <!-- Modal ver factura -->
    <ModalRecibo
      :mostrar-modal="mostrarModalFactura"
      :esAgencia="true"
      :agenciaLogo="agencia && agencia.logo ? `${endpointPublicBackend}${agencia.logo.url}` : ''"
      :agenciaNombre="agencia && agencia.nombre ? agencia.nombre : ''"
      :clienteEspecificado="paquetesId.length && paquetesId[0].cliente ? paquetesId[0].cliente : {}"
      :idConsolidado="paquetesId.length && paquetesId[0].consolidado ? paquetesId[0].consolidado.id : ''"
      :idDireccion="paquetesId.length && paquetesId[0].direccionDestino ? paquetesId[0].direccionDestino.id : ''"
      @paquetes="paquetesAEntregar = $event"
      @ids="idPaquetesAEntregar = $event"
      @entregar="mostrarModalEntregarPaquete = $event;"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalFactura = $event;"
    />
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="metodoConfirmarPermiso"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <!-- Modal Asignar Cliente -->
    <mdb-modal
      centered
      elegant
      :show="mostrarModalAsignarCliente"
      @close="mostrarModalAsignarCliente = false"
    >
      <mdb-modal-header>
        <mdb-modal-title> Asignar cliente a un paquete </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Cliente</header>
        <ClienteBuscador class="my-2" @cliente="cliente = $event" />
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="paqueteAsignarCliente"
          >
            Asignar cliente
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal Instrucciones de envío -->
    <ModalInstruccionesEnvio
      :mostrar-modal="mostrarInstruccionesEnvio"
      :paquetes="paquetesId"
      :variablesUpdateQuery="variablesQueryAgencia"
      @limpiarSelectPaquetes="paquetesId = []"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarInstruccionesEnvio = $event;
        listarInstruccionesEnvio=false"
    />
    <!-- Modal seleccionar aliado-->
    <ModalSeleccionarAliado
      :mostrar-modal="mostrarModalSeleccionarAliado"
      :aliadoId="direccionSeleccionada && direccionSeleccionada.aliado ? direccionSeleccionada.aliado.id : ''"
      :es-agencia="true"
      :direccionEspecificada="direccionSeleccionada ? direccionSeleccionada : {}"
      :variablesQueryAgencia="variablesQueryAgencia"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalSeleccionarAliado = $event;"
    />
    <!-- Modal de los Colores Empresariales -->
    <ModalColoresEmpresariales
      v-if="!$apolloData.queries.agencia.loading && agencia"
      :mostrarModal="mostrarModalColoresEmpresariales"
      :agenciaId="agencia.id"
      :coloresEmpresariales="agencia.coloresEmpresariales === null ? {} : agencia.coloresEmpresariales"
      @cerrar="mostrarModalColoresEmpresariales = !mostrarModalColoresEmpresariales"
      @alerta-mensaje="alertaMensaje = $event"
      @actualizarColores="$apollo.queries.agencia.refetch()"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view  @actualizar="actualizarPaqueteDesdeRutaHija($event);"/>
    </transition>
  </div>
</template>
<script>
import gql from "graphql-tag";
import agenciaGql from "@/graphql/agencia.gql";
import ModalOrdenReempaque from '@/components/FormularioOrdenReempaque.vue'
import ModalAsignarPago from "@/components/ModalAsignarPago.vue";
import ModalRecibo from "@/components/ModalRecibo.vue";
import ModalDatosEnvio from "../components/ModalDatosEnvio.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import direccionEliminarGql from "@/graphql/direccionEliminar.gql";
import ClienteBuscador from "@/components/ClienteBuscador.vue"
import clienteEditarTelefonoGql from "@/graphql/clienteEditarTelefono.gql";
import clienteCrearTelefonoGql from "@/graphql/clienteCrearTelefono.gql";
import agenciaSubirLogoGql from "@/graphql/agenciaSubirLogo.gql";
import agenciaEliminarLogoGql from "@/graphql/agenciaEliminarLogo.gql";
import ModalSeleccionarAliado from "../components/ModalSeleccionarAliado.vue";
import clienteEliminarTelefonoGql from "@/graphql/clienteEliminarTelefono.gql";
import ModalEntregarPaquete from "@/components/ModalEntregarPaquete.vue";
import {endpointPublicBackend} from "@/constantes/paquetes.js"
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import paqueteAsignarClienteGql from "@/graphql/paqueteAsignarCliente.gql"
import ModalSeleccionarDireccion from "@/components/ModalSeleccionarDireccion.vue";
import ModalInstruccionesEnvio from '@/components/FormularioInstruccionesEnvio.vue';
import ModalColoresEmpresariales from '@/components/ModalColoresEmpresariales.vue';
import { ConsolidadoTipoEnvio } from "@/constantes/consolidados.js"
import { regExpFoto } from "@/constantes/paquetes.js";
import { leerRoles } from "@/utils/datosToken.js";
import { paquteCalcularCosto } from '../funciones/calcularCostos';
import {
  validarTel,
  evitarTelefonosRepetidos,
  mostrarSoloDosDecimales,
  obtenerMarcaTemporal
} from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
import CargandoVista from "@/components/CargandoVista.vue";
import PaqueteBuscador from '../components/PaqueteBuscador.vue';

export default {
  name: "agencia",
  components: {
    mdbBtn,
    mdbInput,
    CargandoVista,
    ModalDatosEnvio,
    ConfirmacionEliminar,
    AlertaMensaje,
    ModalEntregarPaquete,
    ModalOrdenReempaque,
    ModalAsignarPago,
    ModalColoresEmpresariales,
    ModalInstruccionesEnvio,
    ModalSeleccionarAliado,
    ModalRecibo,
    ModalSeleccionarDireccion,
    ModalConfirmarPermiso,
    ClienteBuscador,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    PaqueteBuscador,
  },
  data() {
    return {
      url: 'https://trackers.globaltrackit.com/login/',
      // Asignar cliente
      mostrarModalAsignarCliente: false,
      cliente: {},
      // Confirmar permiso
      mostrarModalConfirmarPermiso: false,
      metodoAlConfirmar : '',
      objetoAlConfirmarPermiso : {},
      objetoSeleccionado: {},
      // Asignar dirección
      mostrarModalSeleccionarDireccion: false,
      paqueteSeleccionado: {},
      tituloConfirmarEliminar: '',
      sustantivoFemenino: true,
      // Logo
      endpointPublicBackend,
      foto: null,
      esElegirFoto: false,
      // Colores empresariales
      mostrarModalColoresEmpresariales: false,
      // Factura
      mostrarModalFactura: false,
      idPaquetesAEntregar: [],
      paquetesAEntregar: [],
      // aliados
      mostrarModalSeleccionarAliado: false,
      // Instrucciones de envio
      mostrarInstruccionesEnvio: false,
      listarInstruccionesEnvio: false,
      // Seleccionar pago
      mostrarModalAsignarPago: false,
      // Ordenes de reempaque
      mostrarOrdenesReempaque: false,
      listarOrdenesReempaque: false,
      tituloModalOrdenesReempaque: '',
      // Funciones
      mostrarSoloDosDecimales,
      roles:leerRoles(),
       // direcciones
      mostrarModalEditarEnvio: false,
      esEditarDirección: false,
      direccionSeleccionada: {},
      IdDireccionAEliminar: '',
      // Confirmación eliminar
      mostrarModalConfirmarEliminar: false,
       // Teléfonos
      mostrarNuevoTelefono: false,
      editarTelefono: false,
      telefonoEditarSeleccionado: false,
      nuevoTelefono: '',
      telefonoAEditar: {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: '',
      },
      // Entregar paquetes
      mostrarModalEntregarPaquete: false,
      esFoto: false,
      // Otros
      alertaMensaje: {contenido: ''},
      paquetesId: [],
      botonDeshabilitado: false,
      limpiarCliente: false,
      limpiarPaquete: false,
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      buscarPor: {
        estatus: "",
        entregado: false,
        cliente: {
          id: ''
        },
        paqueteId:'',
      },
      // Variables query agencia
      variablesQueryAgencia: {
        filter:{
          codigo: this.$route.params.id
        },
        filters: {
          reempacado: false,
          estatus_not_in: ['Entregado', 'Devuelto'],
        }
      },
    };
  },
  computed: {
    textoBotonSeleccionar(){
      const paquetesDisponibles = this.agencia.paquetes.length
      const paquetesSeleccionados = this.paquetesId.length
      return paquetesDisponibles === paquetesSeleccionados
        ? "Deseleccionar todo"
        : "Seleccionar todo";
    },
    paquetesIdFormatted() {
      if (!this.paquetesId) return false;
      const ids = [];
      this.paquetesId.forEach((paquete) => {
        ids.push(paquete.id);
      });
      // retornará todos los id de los paquetes seleccionados
      return ids;
    },
    botonEditarTelefono() {
      return this.editarTelefono
      ? {icono:'times', title:'Cancelar edición'}
      : {icono:'pen', title:'Editar teléfonos'};
    },
    // Funcionalidad de reempaque
     paquetesEsperaMiami() {
      if (!this.paquetesId.length) return false;
      let paqueteEnMiami = true;
      this.paquetesId.forEach((paquete) => {
        if(paquete.estatus !== "EsperaMiami"){
          paqueteEnMiami = false
        }
      });
      if(paqueteEnMiami){
      // retornará verdadero si todos los paquetes seleccionados tienen estatus "EsperaMiami"
        return true
      }
      return false;
    },
    paquetesTipoIndividual() {
      if (!this.paquetesId.length) return false;
      const tipos = [];
      this.paquetesId.forEach((paquete) => {
        tipos.push(paquete.tipo);
      });
      if (Object.values(tipos).includes("Reempaque")) return false;
      // retornará verdadero si todos los paquetes seleccionados son de tipo individual
      return true;
    },
    paquetesMismoCliente() {
      if (!this.paquetesId.length) return false;
      let sinCliente = false;
      const clientes = [];
      this.paquetesId.forEach((paquete) => {
        paquete.cliente && paquete.cliente.nombre
        ? clientes.push(paquete.cliente.nombre)
        : sinCliente = true;
      });
      const clientesSet = [...new Set(clientes)];
      // si la logitud es 0, no hay clientes seleccionados
      if(!clientesSet.length) return  false;
      // Si la longitud es 1, todos los clientes son los mismos
      if(sinCliente || clientesSet.length !== 1) return  false;
      // Retorna true si todos los clientes son los mismos
      return true;
    },
    costoPaqueteAsignar() {
      return this.paquetesId[0] ? Number(paquteCalcularCosto(this.paquetesId[0])) : null;
    }
  },
  methods: {
    paqueteAsignarCliente() {
      if (!this.cliente.id){
        return this.alertaMensaje = {
            contenido:  'Por favor selecciona un cliente',
            tipo: 'advertencia',
          };
      }
      if (!this.cliente.casillero){
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
          tipo: 'advertencia',
        };
      }
      if (this.objetoSeleccionado.estatus === 'Devuelto'){
        return this.alertaMensaje = {
          contenido: "No puedes asignar un cliente a un paquete que ha sido devuelto",
          tipo: 'advertencia',
        };
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarClienteGql}
          `,
          variables: {
            paquetesId: [this.objetoSeleccionado.id],
            personaId: this.cliente.id,
          },
          update: (store, {data: {paqueteAsignarCliente}}) => {
            const msg = paqueteAsignarCliente.codigo;
            switch (msg) {
              case "Correcto":
                break;
              case "Fallido":
                this.alertaMensaje = {
                    contenido:  'Ha ocurrido un error asignando cliente al paquete.'
                    +' Por favor intenta de nuevo',
                    tipo: 'error',
                  };
                this.botonDeshabilitado = false;
                return;
              default:
                this.alertaMensaje = {
                  contenido:  'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  tipo: 'error',
                };
                this.botonDeshabilitado = false;
                return;
            }

            const data = store.readQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia
            });

            data.Agencia[0].paquetes.map((p) => {
              let paquete = p
              if(p.id === this.objetoSeleccionado){
                paquete.cliente = JSON.parse(JSON.stringify(this.cliente))
                // Propiedades que no retorna la query clienteBuscar
                paquete.cliente.emails = []
                paquete.cliente.telefonos = []
                paquete.cliente.identificacion = '';
                // Actualizar número HR
                paquete.numeroHR = paquete.cliente.casillero+obtenerMarcaTemporal()
              }
              return (paquete)
            })

            store.writeQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia,
              data
            });

            this.cliente = {};
            this.objetoSeleccionado = {};
            this.mostrarModalAsignarCliente= false;
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: 'Se ha asignado un cliente al paquete correctamente',
              tipo: 'correcto',
            };
          },
        })
        .catch(()=>{
          this.botonDeshabilitado = false
          return this.alertaMensaje = {
            contenido:  'Ha ocurrido un error asignando cliente al paquete.'
            +' Por favor intenta de nuevo',
            tipo: 'error',
          };
        })
    },
    metodoConfirmarPermiso(){
      if(this.metodoAlConfirmar === 'asignarCliente'){
        this.mostrarModalAsignarCliente = true;
        this.objetoSeleccionado = this.objetoAlConfirmarPermiso;
      }
      this.metodoAlConfirmar = '';
      this.objetoAlConfirmarPermiso = {};
    },
    // Copia del portapapeles exitoso
    clipboardSuccessHandler() {
      this.alertaMensaje = {
        contenido: 'Enlace de registro copiado',
        tipo: 'correcto',
      };
    },
    // Fotos
    handleInputChange({ target }) {
      if (!target.files.length) return;
      target.files.forEach((file) => {
        const result = regExpFoto.test(file.name);
        result
          ? this.foto = file
          : this.alertaMensaje = {
            contenido: "El archivo que intenta subir no es una imagen",
            tipo: "error"
          };
      });
    },
    agregarLogo() {
      if(!this.foto) {
        return this.alertaMensaje = {
            contenido: "Seleccione un foto antes de continuar",
            tipo: "error"
          };
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: agenciaSubirLogoGql,
          variables: {
            agenciaId: this.agencia.id,
            logo: {
              foto: this.foto,
            },
          },
        })
        .then(({data:{agenciaSubirLogo}}) => {
          this.agencia.logo = {
            url: agenciaSubirLogo.url
          };
          this.foto = {};
          this.esElegirFoto = false;
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Se ha subido un logo correctamente",
            tipo: "correcto"
          }
        })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault();
          this.botonDeshabilitado = false;
        });
    },
    eliminarLogo(){
      if(!this.agencia.logo) {
        return this.alertaMensaje = {
          contenido:"No existe un logo en la agencia. Por favor intenta de nuevo",
          tipo:"error",
        }
      }
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation:gql`${agenciaEliminarLogoGql}`,
        variables:{
          agenciaId: this.agencia.id,
          url:this.agencia.logo.url,
        }
      }).then(({data:{agenciaEliminarLogo}})=>{
        switch (agenciaEliminarLogo.codigo) {
          case "Correcto":
            this.agencia.logo = null
            this.alertaMensaje = {
              contenido: "Se ha eliminado el logo de la agencia correctamente",
              tipo: "correcto"
            }
            this.mostrarModalConfirmarEliminar = false;
            this.botonDeshabilitado = false;
            break;
          case "Fallido":
            this.botonDeshabilitado = false
            this.mostrarMensajeEnCasoDefault()
            return;
          default:
            this.botonDeshabilitado = false
            this.mostrarMensajeEnCasoDefault()
            return;
        }
      })
    },
    validarModalAsignarPago(paquete){
      if(paquete.estatus === 'Devuelto'){
        return this.alertaMensaje = {
          contenido: "El paquete seleccionado ya ha sido devuelto",
          tipo: "advertencia"
        }
      }
      this.paquetesId = [paquete]
      if(this.paquetesId.length && this.paquetesId[0].cliente){
        this.mostrarModalAsignarPago = true
      } else {
        this.paquetesId = []
        return (
          this.alertaMensaje = {
            contenido: 'Es requerido que el paquete cuente con un cliente para asignar un pago',
            tipo: 'advertencia'
          }
        )
      }
    },
    verificarPaquetesAReempacar () {
      if (!this.paquetesEsperaMiami) {
        return this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia'
        }
      }
      this.mostrarOrdenesReempaque = !this.mostrarOrdenesReempaque
    },
    verificarPaquetesAInstruccionEnvio () {
      if (!this.paquetesEsperaMiami) {
        this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia'
        }
        return
      }
      this.mostrarInstruccionesEnvio = !this.mostrarInstruccionesEnvio
    },
    seleccionarTodo() {
      const paquetesDisponibles = this.agencia.paquetes.length
      const paquetesSeleccionados = this.paquetesId.length
      paquetesDisponibles === paquetesSeleccionados
        ? this.paquetesId = []
        : this.paquetesId = this.agencia.paquetes
    },
    validarModalEntregarPaquete(){
      let paquetesEntregados = false
      if (this.paquetesId.length != 1) {
        return (this.alertaMensaje = {
          contenido: 'Selecciona solo un paquete para continuar',
          tipo: 'advertencia'
        })
      }
      this.paquetesId.forEach(p => {
        if(p.estatus === 'Entregado')return paquetesEntregados = true;
      });
      if(paquetesEntregados){
        return this.alertaMensaje= {
          contenido: 'Alguno de los paquetes que seleccionaste fueron entregados. Por favor verifica tus datos',
          tipo: 'advertencia',
        }
      }
      // En caso de que ningun paquete seleccionado haya sido entregado se abre el modal de entregar paquete
      // this.mostrarModalEntregarPaquete=!this.mostrarModalEntregarPaquete
      this.mostrarModalFactura = true
    },
    actualizarPaqueteDesdeRutaHija(paquete){
      if(paquete.id){
        this.agencia.paquetes.map((p) => {
          let paqueteConFormato = p
          if(p.id === paquete.id){
            Object.assign(paqueteConFormato, paquete)
          }
          return paqueteConFormato
        })
        this.paquetesId = []
      }
    },
    // Funcionalidad teléfonos
    validarTelefono(telefono, nuevo){
      if(!nuevo && telefono === this.telefonoAEditar.telefonoAnterior) {
        return this.alertaMensaje = {
          contenido:'El teléfono no puede ser idéntico al anterior',
          tipo: 'error'
        };
      }
      if(!telefono || !validarTel(telefono)){
        return this.alertaMensaje= {
          contenido: 'Teléfono no válido',
          tipo: 'error',
        }
      }
      //  Validar teléfono repetido en caso sea de la agencia
      if(evitarTelefonosRepetidos(this.agencia.telefonos, telefono)){
          return (this.alertaMensaje = {
            contenido: 'El teléfono que ingresaste ya se encuentra agregado',
            tipo: 'error'
          })
      }
      if(nuevo) return this.telefonoCrear(telefono)
      this.telefonoEditar(telefono)
    },
    telefonoCrear(tel){
      const telefono = String(tel)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteCrearTelefonoGql,
        variables: {
          personaId: this.$route.params.id,
          numero: telefono
        },
        update: (store, {data: {telefonoCrear}}) => {
          const data = store.readQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia,
          });
          const msg = telefonoCrear.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha creado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error creando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Agencia[0].telefonos.push({numero: telefono, __typename: "Telefono"})
          store.writeQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarNuevoTelefono = false;
            this.nuevoTelefono = '';
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoRemover(num){
      const numero = String(num)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEliminarTelefonoGql,
        variables: {
          numero,
          personaId: this.$route.params.id,
        },
        update: (store, {data: {telefonoEliminar}}) => {
          const msg = telefonoEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          const data = store.readQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia,
          });
          data.Agencia[0].telefonos = data.Agencia[0].telefonos.filter((e) => {
            return e.numero !== numero;
          });
          store.writeQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarTelefono = false;
            this.telefonoEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoEditar(tel){
      const telefono = JSON.stringify(tel)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEditarTelefonoGql,
        variables: {
          numeroAnterior: String(this.telefonoAEditar.telefonoAnterior),
          personaId: this.$route.params.id,
          numeroNuevo: telefono,
        },
        update: (store, {data: {telefonoEditar}}) => {
          const data = store.readQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
          });
          const msg = telefonoEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
            data.Agencia[0].telefonos.forEach(tel => {
              if(this.telefonoAEditar.telefonoAnterior === tel.numero){
                tel.numero = this.telefonoAEditar.telefonoNuevo
              }
            });
          store.writeQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarTelefono = false;
            this.telefonoEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    limpiarDatos() {
      this.mostrarModalEditarEnvio= false;
      this.esEditarDirección= false;
      this.direccionSeleccionada= {};
      this.IdDireccionAEliminar= '';
    },
    obtenerClienteBuscador(cliente){
      if(cliente && cliente.id){
        this.buscarPor.cliente.id = cliente.id
      } else {
        this.buscarPor.cliente.id = ''
      }
      this.paqueteActualizar('filtro')
    },
    obtenerPaqueteBuscador(paquete){
      if(paquete && paquete.id){
        this.buscarPor.paqueteId = paquete.id  
      } else {
        this.buscarPor.paqueteId = ''
      }
      this.paqueteActualizar('filtro')
    },
    paqueteActualizar(filtro) {
      this.paquetesId = [];
      let variableParaQuery = {
        filter:{
          codigo: this.$route.params.id,
        },
        filters: {
          reempacado: false,
          estatus_not_in: ['Entregado', 'Devuelto'],
        }
      }
      // Mostrar entregados y devueltos
      if(filtro) {
        if (this.buscarPor.entregado) {
          delete variableParaQuery.filters.estatus_not_in
        }
        if (this.buscarPor.estatus) {
          // en caso el estatus sea entregado o devuelto se actualiza switch
          if (this.buscarPor.estatus == 'Entregado' || this.buscarPor.estatus == 'Devuelto') {
            this.buscarPor.entregado = true
            delete variableParaQuery.filters.estatus_not_in
          } else {
            this.buscarPor.entregado = false
          }
          Object.assign(variableParaQuery.filters, {
            estatus: this.buscarPor.estatus
          })
        }
       if(this.buscarPor.cliente.id && this.buscarPor.cliente.id.length){
        Object.assign(variableParaQuery.filters, {
          cliente: {
            id: this.buscarPor.cliente.id
          }
        })
      }
      if(this.buscarPor.paqueteId && this.buscarPor.paqueteId.length){
        Object.assign(variableParaQuery.filters, {
          id: this.buscarPor.paqueteId
        })
      }
      }
      this.$apollo
        .watchQuery({
            query: gql`
              ${agenciaGql}
            `,
            variables: variableParaQuery,
            update: (data) => data.Agencia[0],
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Agencia){
                this.agencia = data.Agencia[0];
              }
              this.botonDeshabilitado = false;
            },
            error: () => {
             this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando las agencias. Por favor intenta de nuevo',
                tipo: 'error',
              };
              this.botonDeshabilitado = false;
            }
          })
    },
    // Funcionalidad AlertaMensaje
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
    // Direcciones
    direccionRemover(){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: direccionEliminarGql,
        variables: {
          direccionId: this.IdDireccionAEliminar,
        },
        update: (store, {data: {direccionEliminar}}) => {
          const data = store.readQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia,
          });
          const msg = direccionEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido la dirección exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando la dirección. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Agencia[0].direccion = {
            __typename: 'Direccion',
              id: '',
              direccion: '',
              tasaAerea: '',
              tasaMaritima: '',
              minAerea: '',
              minMaritima: '', 
              aliado: {
                id: '',
                nombre: '',
                __typename:"Aliado"
              },
              ciudad: {
                nombre: '',
                __typename: 'Ciudad',
                estado: {
                  nombre: '',
                __typename: 'Estado',
                  pais: {
                    nombre: '',
                  __typename: 'Pais',
                  }
                }
              }
            };
          store.writeQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarModalConfirmarEliminar = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            direccionEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
  },
  apollo: {
    agencia() {
      return {
        query: gql`
          ${agenciaGql}
        `,
        variables: this.variablesQueryAgencia,
        update: (data) => data.Agencia[0],
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.color-container {
	background-color: gray;
	border: 3px solid #fffefe;
	border-radius: 50%;
	display: inline-block;
	height: 50px;
	margin: 0 .25rem .5rem;
	outline: 1px solid rgb(176, 176, 176);
	width: 50px;
}
.botones-opcionales {
  bottom: 73px;
  overflow: hidden;
  position: fixed;
  right: 0px;
  text-align: right;
  transition: height 0.5s ease;
  z-index: 2;

  @media screen and (min-width: 576px) {
    bottom: 68px;
  }

  @media screen and (min-width: 768px) {
    bottom: 1rem;
    right: calc(50% - 375px);
    text-align: center;
  }
  @media screen and (min-width: 992px) {
    right: calc(50% - 113px - 375px);
  }
  @media screen and (min-width: 1200px) {
    right: calc(50% - 113px - 376.72px);
  }

  &.mostrar {
    animation-duration: .5s;
    animation-name: fadeInRight;

    @media screen and (min-width: 768px) {
      animation-name: fadeInUp;
    }
  }
}
</style>
<template>
 <section>
    <mdb-modal centered elegant :show="mostrarModal" @close="cerrarModal">
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo }} </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <section
        v-if="!mostrarCropper"
        class="row justify-content-center"
      >
        <div class="col-12">
          <div class="outline-input-file" data-size="sm">
            <label for="input-file-images" class="label-file">
              <font-awesome-icon icon="upload" class="mr-1" />
              Elegir {{ archivo }}
            </label>
            <span class="contenido">
              {{
                archivos && archivos.length
                  ? archivos.length > 1
                    ? archivos.length + " archivos"
                    : archivos.length + " archivo"
                  : `No se eligió archivo`
              }}
            </span>
            <input
              id="input-file-images"
              type="file"
              multiple
              :accept="soloImagenes ? 'image/*' : 'application/pdf,image/*'"
              class="custom-file-input"
              @change="handleInputChange"
            />
            <mdb-btn
              v-if="archivos.length > 0"
              flat
              dark-waves
              role="button"
              icon="times"
              class="file-button m-0 p-2"
              :title="
                soloImagenes
                  ? 'Eliminar fotos seleccionadas'
                  : 'Eliminar archivos seleccionados'
              "
              @click="vaciarArchivos"
            />
          </div>
          <span
            v-if="sizesTotal"
            class="text-muted"
          >
            Archivos adjuntados: {{ sizesTotal.toFixed(2) }} / {{ maxSize }}MB
          </span>
        </div>
        <div class="col-auto">
          <mdb-btn
            flat
            dark-waves
            icon="camera"
            class="btn-bordeado mt-4 px-3"
            @click="mostrarModalWebCam=!mostrarModalWebCam"
          >
            Usar cámara
          </mdb-btn>
        </div>
        <div class="col-auto">
          <mdb-btn
            color="primario"
            role="button"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitadoComputed || botonDeshabilitado"
            class="mt-4"
            @click="agregarArchivos"
          >
            Agregar
          </mdb-btn>
        </div>
      </section>
      <div
        v-show="mostrarCropper"
        class="content"
      >
        <section class="cropper-area">
          <div class="img-cropper">
            <vue-cropper
              v-show="mostrarCropper"
              ref="cropper"
              :src="imgSrc"
              :viewMode="2"
              drag-mode="move"
              :zoomable="true"
              :guides="true"
              :view-mode="1"
              :rotatable="true"
              :highlight="true"
            />
          </div>
          <div class="actions">
            <div class="btn-group" role="group" aria-label="First group">
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="search-plus"
                class="btn-bordeado ml-0 p-2"
                title="Acercar"
                @click="zoomImg(0.2)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="search-minus"
                class="btn-bordeado p-2"
                title="Alejar"
                @click="zoomImg(-0.2)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="undo-alt"
                class="btn-bordeado p-2"
                title="Rotar -90°"
                @click="rotarImagen(-90)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="redo-alt"
                class="btn-bordeado p-2"
                title="Rotar 90°"
                @click="rotarImagen(90)"
              />
              <a
                role="button"
                ref="horizontal"
                class="btn-bordeado p-2 btn btn-flat ripple-parent"
                title="Voltear horizontalmente"
                @click.prevent="girarHorizontal"
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z"/></svg>
              </a>
              <a
                role="button"
                ref="vertical"
                class="btn-bordeado mr-0 p-2 btn btn-flat ripple-parent"
                title="Voltear verticalmente"
                @click.prevent="girarVertical"
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529" style="transform: rotate(90deg)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z"/></svg>
              </a>
            </div>
            <mdb-btn
              flat
              dark-waves
              class="btn-bordeado p-2"
              @click="resetearEdicion"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 3c-4.97 0-9 4.03-9 9H1l4 3.99L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"/></svg>
              Resetear imagen
            </mdb-btn>
            <mdb-btn
              color="terciario"
              icon="crop-alt"
              class="btn-bordeado p-2"
              @click="enviarArchivo"
            >
              Cortar
            </mdb-btn>
            <mdb-btn
              flat
              dark-waves
              icon="times"
              class="btn-bordeado p-2"
              @click="cerrarCropper"
            >
              Cancelar
            </mdb-btn>
          </div>
        </section>
      </div>
    </mdb-modal-body>
  </mdb-modal>
  <ConfirmacionEliminar
    mensaje="¿Quieres editar la imagen seleccionada?"
    :mostrar-modal="mostrarModalConfirmarEditarImagen"
    :cancelarMensaje="true"
    @cerrar="mostrarModalConfirmarEditarImagen = $event;agregarArchivo()"
    @eliminar="leerImagenEditar();
      mostrarModalConfirmarEditarImagen=false"
  />
  <ModalWebCam 
    :mostrarModal="mostrarModalWebCam"
    titulo="Tomar fotos usando cámara"
    @capturar="imagenSeleccionada = $event; mostrarModalConfirmarEditarImagen = true;"
    @cerrar="mostrarModalWebCam = $event"
    />
 </section>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import fotoCrearGql from "@/graphql/fotoCrear.gql";
import { regExpFoto } from "@/constantes/paquetes.js";
import { regExpComprobante } from "@/constantes/pagos.js";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import ModalWebCam from "../components/ModalWebCam.vue";

import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
export default {
  name: "ModalSubirArchivo",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    VueCropper,
    ConfirmacionEliminar,
    ModalWebCam
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    grafoId: {
      type: String,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
      default: "Agregar fotos al paquete",
    },
    archivo: {
      type: String,
      required: false,
      default: "archivo",
    },
    soloImagenes: {
      type: Boolean,
      required: false,
      default: true,
    },
    entidadFoto: {
      type: String,
      required: true,
      default: "Paquete",
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      mostrarModalConfirmarEditarImagen: false,
      sizesTotal: 0,
      archivos: [],
      mostrarCropper: false,
      imgSrc: "",
      cropImg: "",
      imagenSeleccionada: null,
      maxSize: 18,
      mostrarModalWebCam: false,
    };
  },
  computed: {
    botonDeshabilitadoComputed() {
      return this.archivos.length ? false : true;
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrar", false);
      this.cerrarCropper();
      this.vaciarArchivos();
    },
    cerrarCropper() {
      this.imgSrc = "";
      this.cropImg = "";
      this.imagenSeleccionada = null;
      this.botonDeshabilitado = false;
      this.mostrarCropper = false;
    },
    alerta(contenido, tipo) {
      this.$emit("alerta", { contenido, tipo });
    },
    agregarArchivo(){
       const sizeFile = Number((this.imagenSeleccionada.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          return this.alerta(
            `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
          );
        }
        if ((this.sizesTotal + sizeFile) > this.maxSize) {
          return this.alerta(
            `No se puede agregar el archivo ya que superaría el límite establecido de 18MB. Actualmente tienes: ${this.sizesTotal.toFixed(
              2
            )} MB`,
            "error"
          );
        }
        this.sizesTotal += Number(sizeFile);
        this.archivos.push(this.imagenSeleccionada);
    },
    leerImagenEditar() {
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
          this.mostrarCropper = true;
        };
        reader.readAsDataURL(this.imagenSeleccionada);
      } else {
        this.alerta(
          "No se puede editar la imagen, actualiza tu navegador",
          "advertencia"
        );
      }
    },
    handleInputChange({ target }) {
      const ext = this.soloImagenes ? regExpFoto : regExpComprobante;
      if (!target.files.length) return;
      if (target.files.length === 1 && regExpFoto.test(target.files[0].name)) {
        this.imagenSeleccionada = target.files[0];
        this.mostrarModalConfirmarEditarImagen = true
        return;
      }
      target.files.forEach((file) => {
        const sizeFile = Number((file.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          return this.alerta(
            `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
          );
        }
        if ((this.sizesTotal + sizeFile) > this.maxSize) {
          return this.alerta(
            `No se puede agregar el archivo ya que superaría el límite establecido de 18MB. Actualmente tienes: ${this.sizesTotal.toFixed(
              2
            )} MB`,
            "error"
          );
        }
        if (!ext.test(file.name)) {
          return this.alerta(
            `El archivo que intenta subir no ${
              this.soloImagenes
                ? "es una imagen"
                : "corresponde a un formato compatible"
            }`,
            "error"
          );
        }
        this.sizesTotal += Number(sizeFile);
        this.archivos.push(file);
      });
    },
    vaciarArchivos() {
      this.archivos = [];
      this.sizesTotal = 0;
    },
    enviarArchivo() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.botonDeshabilitado = true;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const fileImg = this.blobToFile(blob);
        const sizeFile = Number((fileImg.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          this.alerta(
            `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
          );
          this.cerrarCropper();
          return;
        }
        if ((this.sizesTotal + sizeFile) > this.maxSize) {
          this.alerta(
            `La imagen modificada supera el límite de 18MB, no se puede agregar actualmente tienes: ${this.sizesTotal.toFixed(
              2
            )} MB`,
            "error"
          );
          this.cerrarCropper();
          return;
        }
        this.sizesTotal += Number(sizeFile);
        this.archivos.push(fileImg);
        this.cerrarCropper();
      });
      this.botonDeshabilitado = false;
    },
    agregarArchivos() {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: fotoCrearGql,
          variables: {
            grafoId: this.grafoId,
            fotos: {
              foto: this.archivos,
            },
            entidadFoto: this.entidadFoto,
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.cerrarModal();
          this.vaciarArchivos();
          this.alerta(
            `${
              this.soloImagenes ? "Imágenes enviadas" : "Archivos subidos"
            }  correctamente`,
            "correcto"
          );
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.cerrarModal();
          this.vaciarArchivos();
          this.alerta("Ha ocurrido un error subiendo los archivos", "error");
        });
    },
    blobToFile(blob) {
      return new File([blob], this.imagenSeleccionada.name, {
        type: this.imagenSeleccionada.type,
      });
    },
    girarHorizontal() {
      const dom = this.$refs.horizontal;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    girarVertical() {
      const dom = this.$refs.vertical;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    resetearEdicion() {
      this.$refs.cropper.reset();
      this.alerta("La imagen ha sido restablecida", "advertencia");
    },
    rotarImagen(deg) {
      this.$refs.cropper.rotate(deg);
    },
    zoomImg(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  @media screen and (min-width: 992px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
}
.cropper-container.cropper-bg {
  width: 100% !important;
  height: auto !important;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 1rem;

  .btn-group {width: 100%;}
  a {color: #212529 !important;}
}
</style>
<template>
  <div class="vista-detalles orden">
    <div class="container-xl px-0">
      <!-- Encabezado -->
      <div
        v-if="!$apolloData.queries.orden.loading"
        class="row align-items-center justify-content-center justify-content-lg-between mt-3"
      >
        <div
          :class="['col-auto text-reset',
            orden.pagado ? 'order-lg-1' : 'order-xl-1']"
        >
          <mdb-btn
            flat
            dark-waves
            icon="angle-left"
            icon-class="fa-lg"
            class="btn-bordeado px-lg-3"
            @click="$router.go(-1)"
          >
            Volver atrás
          </mdb-btn>
        </div>
        <div
          v-if="orden"
          :class="['col-auto text-center',
            orden.pagado ? 'order-lg-3' : 'order-xl-3']"
        >
          <mdb-btn
            v-if="rol.includes('Admin') && validarEstatusProductos"
            flat
            dark-waves
            icon="trash"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 px-3 text-nowrap"
            title="Eliminar compra"
            @click="modalConfirmarEliminar('orden')"
          >
            Eliminar
          </mdb-btn>
          <mdb-btn
            :color="orden.pagado ? 'primario' : 'terciario'"
            icon="pen"
            :disabled="botonDeshabilitado"
            :class="['mr-4 mr-md-0 px-3 text-nowrap', {'botonFlotante': orden.pagado}]"
            title="Editar compra"
            @click="editarPreparar"
          >
            Editar
          </mdb-btn>
          <mdb-btn
            v-if="!orden.pagado"
            color="primario"
            icon="plus"
            class="mr-4 mr-md-0 px-3 text-nowrap botonFlotante"
            title="Crear pago"
            :disabled="botonDeshabilitado"
            @click="mostrarFormularioPago=true"
          >
            Crear pago
          </mdb-btn>
        </div>
        <h3
          :class="['col-12 mt-2 text-center user-select-none',
            orden.pagado ? 'col-lg order-lg-2' : 'col-xl order-xl-2']"
        >
          Compra
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.orden.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.orden.loading && !orden"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          La compra solicitada no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row mt-md-2">
          <p class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Cliente
            </small>
            <span :class="{'text-muted user-select-none': !(orden.cliente && orden.cliente.nombre)}">
              {{
                orden.cliente && orden.cliente.nombre
                  ? orden.cliente.nombre
                  : 'Sin especificar'
              }}
            </span>
          </p>
          <p class="col-12 col-sm-6 col-lg-5 col-xl-4 mb-2 text-break">
            <small class="text-muted user-select-none d-block">
              Compra ID
            </small>
            <span class="fs-09">{{ orden.compraId }}</span>
          </p>
          <p class="col-6 col-lg-3 col-xl-2 mb-2">
            <small class="text-muted user-select-none d-block">
              Fecha
            </small>
            {{ orden.fecha.formatted }}
          </p>
          <p class="col-6 col-lg-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Monto total
            </small>
            $ {{ orden.monto }}
          </p>
          <p class="col-12 col-lg">
            <small class="text-muted user-select-none d-block">
              Pagado
            </small>
              <font-awesome-icon
                :icon="orden.pagado ? 'check-circle' : 'times-circle'"
                :class="orden.pagado ? 'texto-exitoso' : 'texto-peligro'"
              />
              {{ orden.pagado ? "Sí" : "Falta" }}:
              ${{ !orden.pagado ? (orden.monto - orden.montoCubierto).toFixed(2) : orden.montoCubierto.toFixed(2) }}
          </p>
          <p class="col-12 col-lg">
            <small class="text-muted user-select-none d-block">
              Estatus
            </small>
            {{ OrdenEstatus[orden.estatus] }}
          </p>
        </div>

        <!-- Productos de la compra -->
        <ul
          data-table="sticky"
          class="table fs-08 mb-4"
        >
          <li class="encabezado doble-fila row">
            <div class="fila-titulo col-12">
              <div class="row align-items-center mb-2">
                <h4
                  class="col-12 col-sm  mb-0 ml-sm-5 ml-lg-0 px-2 text-center
                    text-sm-left user-select-none cursor-pointer"
                  title="Agregar nuevo producto a la orden de compra"
                  @click="mostrarFormularioOrdenProducto = true; productoAEditar = {}"
                >
                  <font-awesome-icon
                    icon="plus-circle"
                    size="sm"
                    class="texto-exitoso"
                  />
                  Productos
                </h4>
                <!-- Relaciona paquetes con productos -->
                <mdb-btn
                  flat
                  dark-waves
                  size="sm"
                  icon="plus-circle"
                  icon-class="texto-exitoso"
                  title="Relacionar paquetes con productos"
                  class="col-auto m-0 btn-bordeado py-2 px-3"
                  @click="mostrarModalAsignarProductos  = true"
                >
                  Relacionar paquetes
                </mdb-btn>
              </div>
            </div>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col d-none d-md-block">
                  Nombre
                </div>
                <div class="col d-none d-md-block">
                  Estatus
                </div>
                <div class="col pr-md-0">
                  <span class="d-md-none ml-sm-5 ml-lg-0">Detalles</span>
                  <span class="d-none d-md-block">Url / miniatura</span>
                </div>
                <div class="col-2 pr-0 text-right d-none d-sm-block">Cantidad</div>
                <div class="col-3 col-md-2 text-right d-none d-sm-block">Precio</div>
                <div class="col-4 col-sm-2 col-md-auto btnW-fixed text-right text-sm-left">Acciones</div>
              </div>
            </div>
          </li>
          <template v-if="orden.productos && orden.productos.length">
            <li
              v-for="producto in orden.productos"
              :key="producto.id"
              class="contenido no-hover mx-n3"
            >
              <div class="contenido no-hover normal row align-items-center mx-0">
                <div class="col d-none d-md-block text-break ">
                  <input
                    v-if="producto.paquetes && producto.paquetes.length"
                    :id="`producto-${producto.enlace}`"
                    v-model="mostrarPaquetesProducto"
                    type="checkbox"
                    name="mostrar-productos-paquete"
                    class="d-none"
                    :value="producto.id"
                  >
                  <label
                    :for="`producto-${producto.enlace}`"
                    class="checkbox-plus-label"
                  >
                    <font-awesome-icon
                      v-if="producto.paquetes && producto.paquetes.length"
                      :icon="mostrarPaquetesProducto.includes(producto.id) ? 'minus-circle' : 'plus-circle'" class="texto-terciario"
                    />
                    {{ producto.nombre }}
                  </label>
                </div>
                <div class="col d-none d-md-block">
                  <span class="d-md-none">Estatus:</span>
                  {{ OrdenEstatus[producto.estatus] }}
                </div>
                <div class="col pr-0">
                  <p class="mb-2 d-md-none">
                    <input
                      v-if="producto.paquetes && producto.paquetes.length"
                      :id="`producto-${producto.enlace}`"
                      v-model="mostrarPaquetesProducto"
                      type="checkbox"
                      name="mostrar-productos-paquete"
                      class="d-none"
                      :value="producto.id"
                    >
                    <label
                      :for="`producto-${producto.enlace}`"
                      class="checkbox-plus-label"
                    >
                      <font-awesome-icon
                        v-if="producto.paquetes && producto.paquetes.length"
                        :icon="mostrarPaquetesProducto.includes(producto.id) ? 'minus-circle' : 'plus-circle'" class="texto-terciario"
                      />
                      {{ producto.nombre }}
                    </label>
                  </p>
                  <p class="mb-2 d-md-none">
                    Estatus: {{ OrdenEstatus[producto.estatus] }}
                  </p>
                  <span class="d-md-none">Url / miniatura:</span>
                  <p class="texto-line-clamp text-break mb-0">
                    <a
                      :href="producto.enlace"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="text-break"
                    >
                      {{ producto.enlace }}
                    </a>
                  </p>
                  <img
                    v-if="producto.miniatura && producto.miniatura.length"
                    :src="producto.miniatura"
                    alt="Miniatura del producto"
                    class="miniatura"
                  />
                  <span v-else class="d-block text-muted user-select-none">Sin miniatura asignada</span>
                  <p class="my-2 d-sm-none">
                    Cantidad:
                    <span class="font-weight-bold">{{ producto.cantidad }}</span>
                  </p>
                  <p class="my-2 d-sm-none">
                    Precio:
                    <span class="font-weight-bold">$ {{ producto.precio }}</span>
                  </p>
                </div>
                <div class="col-2 pr-0 text-right d-none d-sm-block">
                  <span class="h6 text-break">{{ producto.cantidad }}</span>
                </div>
                <div class="col-3 col-md-2 text-right d-none d-sm-block">
                  <span class="h6 text-break">$ {{ producto.precio }}</span>
                </div>
                <div class="col-4 col-sm-2 col-md-auto btnW-fixed pr-1 text-right text-sm-left">
                  <mdb-btn
                    flat
                    dark-waves
                    size="sm"
                    class="btn-bordeadoOnHover mx-1 px-2"
                    @click="mostrarFormularioOrdenProducto = true; productoAEditar = producto"
                  >
                    <font-awesome-icon
                      icon="pen"
                      size="lg"
                      class="d-block d-xl-inline mx-auto mb-1 mb-xl-0 mr-xl-1"
                    />
                    Editar
                  </mdb-btn>
                  <mdb-btn
                    v-if="producto.estatus === 'EnEspera' || producto.estatus === 'Identificado' || producto.estatus === 'Cotizado'"
                    flat
                    dark-waves
                    size="sm"
                    class="btn-bordeadoOnHover mx-1 px-2"
                    @click="mostrarModalConfirmarPermiso = true;
                    opcionAEliminar = 'producto';
                    objetoAlConfirmarPermiso = producto;"
                  >
                    <font-awesome-icon
                      icon="trash"
                      size="lg"
                      class="d-block d-xl-inline mx-auto mb-1 mb-xl-0 mr-xl-1 texto-peligro"
                    />
                    Eliminar
                  </mdb-btn>
                </div>
              </div>
              <div
                v-if="mostrarPaquetesProducto.includes(producto.id)"
                class="contenido no-hover normal border mb-3 ml-3 mr-2 pt-0"
              >
                <div class="encabezado border-0 row flex-wrap mx-0 pt-2 pb-0 position-static">
                  <header class="col-12 h6-responsive px-2">
                    Paquetes
                  </header>
                  <div class="col-2 d-none d-sm-block">Fecha</div>
                  <div class="col">Detalles</div>
                  <div class="col d-none d-sm-block">Estatus</div>
                </div>
                <div
                  v-for="paq in producto.paquetes"
                  :key="paq.Paquete.id+'-'+producto.id"
                  class="contenido row align-items-center mx-0"
                  @click="$router.push({ name: 'CompraPaquete', params: {paqueteId: paq.Paquete.id}})"
                >
                  <div class="col-2 d-none d-sm-block">
                    {{ paq.Paquete.fecha.formatted }}
                  </div>
                  <div class="col">
                    <p class="d-sm-none mb-1">
                      Fecha: {{ paq.Paquete.fecha.formatted }}
                    </p>
                    Paquete ID:
                    <span class="text-break">{{ paq.Paquete.numeroHR }}</span>
                    <span class="d-block text-break">Tracking: {{ paq.Paquete.tracking }}</span>
                    <span class="d-block text-break">Cantidad: {{ paq.cantidad }}</span>
                    <p class="d-sm-none mb-1">
                      Estatus: {{ PaqueteEstatus[paq.Paquete.estatus] }}
                    </p>
                  </div>
                  <div class="col d-none d-sm-block">
                    {{ PaqueteEstatus[paq.Paquete.estatus] }}
                  </div>
                </div>
              </div>
            </li>
          </template>
          <li
            v-else
            class="no-items"
          >
            No hay productos
          </li>
        </ul>

        <!-- Pagos relacionados -->
        <ul
          data-table="sticky"
          class="table fs-08 mb-4"
        >
          <li class="encabezado doble-fila row">
            <h4
              v-if="(esPermitido || esAgencia) && !orden.pagado"
              class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none cursor-pointer"
              title="Asignar pago a la orden de compra"
              @click="validarModalAsignarPago"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Pagos asignados
            </h4>
            <h4
              v-else
              class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none"
            >
              Pagos asignados
            </h4>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col-2 d-none d-sm-block">Fecha</div>
                <div class="col">Detalles</div>
                <div class="col d-none d-md-block text-right">Asignado / Monto</div>
                <div class="col d-none d-sm-block">Estatus</div>
                <div
                  class="col-auto btn-eliminar"
                  title="Eliminar relación con el pago"
                >
                  Eliminar
                </div>
              </div>
            </div>
          </li>
          <template v-if="orden.pagos && orden.pagos.length">
            <li
              v-for="pago in orden.pagos"
              :key="pago.Pago.id"
              class="contenido normal row align-items-center"
            >
              <div class="col-2 d-none d-sm-block">
                {{ pago.Pago.fecha.formatted }}
              </div>
              <div class="col">
                <p class="d-sm-none mb-1">
                  Fecha: {{ pago.Pago.fecha.formatted }}
                </p>
                Método: {{ pago.Pago.medio.nombre }}
                <span class="d-block mb-1 mb-md-0">
                  Referencia: {{ pago.Pago.referencia }}
                </span>

                <p class="d-md-none mb-1">
                  Asignado / Monto:
                  <span class="font-weight-bold text-break">
                    ${{ pago.monto }} /
                    ${{ pago.Pago.monto }}
                  </span>
                </p>
                <p class="d-sm-none mb-1">
                  Estatus:
                  <span
                    :class="[
                      'd-block font-weight-bold',
                      EstatusPago[pago.Pago.estatus]
                        ? EstatusPagoIconoColor[pago.Pago.estatus]
                        : 'text-muted',
                    ]"
                  >
                    <font-awesome-icon :icon="EstatusPagoIconos[pago.Pago.estatus]" />
                    {{
                      EstatusPago[pago.Pago.estatus]
                        ? EstatusPago[pago.Pago.estatus]
                        : "Sin especificar"
                    }}
                  </span>
                </p>
              </div>
              <div class="col d-none d-md-block text-right">
                <span class="h6 mb-0 text-break">
                  ${{ pago.monto }} /
                  ${{ pago.Pago.monto }}
                </span>
              </div>
              <div class="col d-none d-sm-block">
                <span
                  :class="[
                    'd-block font-weight-bold',
                    EstatusPago[pago.Pago.estatus]
                      ? EstatusPagoIconoColor[pago.Pago.estatus]
                      : 'text-muted',
                  ]"
                >
                  <font-awesome-icon :icon="EstatusPagoIconos[pago.Pago.estatus]" />
                  {{
                    EstatusPago[pago.Pago.estatus]
                      ? EstatusPago[pago.Pago.estatus]
                      : "Sin especificar"
                  }}
                </span>
              </div>
              <div class="col-auto btn-eliminar">
                <mdb-btn
                  block
                  flat
                  dark-waves
                  icon="trash"
                  iconColor="peligro"
                  class="btn-bordeadoOnHover px-0"
                  title="Eliminar relación con pago"
                  @click="mostrarModalConfirmarPermiso = true;
                  opcionAEliminar = 'pago';
                  objetoAlConfirmarPermiso = pago;
                  "
                />
              </div>
            </li>
          </template>
          <li
            v-else
            class="no-items"
          >
            Sin pagos relacionados
          </li>
        </ul>
      </div>
    </div>

    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="modalConfirmarEliminar(opcionAEliminar)"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <!-- Modal Asignar Productos -->
    <ModalAsignarProductos
      :mostrar-modal="mostrarModalAsignarProductos"
      seleccionar="paquetes"
      :cliente-id="orden && orden.cliente && orden.cliente.id ? orden.cliente.id : ''"
      :orden-id="orden.id"
      :productos="orden.productos"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarModalAsignarProductos = $event;"
    />

    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      :mensaje="datosModalConfirmarEliminar.mensaje"
      :sustantivo-femenino="datosModalConfirmarEliminar.sustantivoFemenino"
      @cerrar="mostrarModalConfirmarEliminar = $event;"
      @eliminar="eliminar"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <FormularioPago
      :mostrarModalPago="mostrarFormularioPago"
      @crear="pagoCrear($event)"
      @cerrar="
        mostrarFormularioPago = false;
      "
      titulo="Asignar pago a la compra"
      @clienteError="errorCliente"
      @alertaMsg="mostrarMensajeAlerta($event)"
      @alerta="alertaMensaje = $event"
      :cliente="orden && orden.cliente && orden.cliente.id ? orden.cliente : {}"
      :esVistaDetalles="true"
      :compra="orden && orden.id ? orden : {}"
    />

    <FormularioOrden
      :mostrarFormularioOrden="mostrarFormularioOrden"
      :es-editar="esEditar"
      :titulo="'Datos de la orden a editar'"
      :ordenAsignar="ordenAsignar"
      @cerrar="mostrarFormularioOrden = $event"
      @orden="ordenCrear($event);"
      @cliente="cliente = $event"
      @editar="esEditar = $event"
      @datosEditar="ordenEditar($event)"
      @alerta-mensaje="alertaMensaje = $event"
    />

    <FormularioOrdenProducto
      :mostrar-modal="mostrarFormularioOrdenProducto"
      :producto-editar="productoAEditar"
      @alerta-mensaje="alertaMensaje = $event"
      @cerrar="mostrarFormularioOrdenProducto = $event"
    />
    <!-- Modal Seleccionar Pago -->
    <ModalAsignarPago
      :mostrar-modal="mostrarModalAsignarPago"
      :esCompra="true"
      :entidad="orden ? orden : {}"
      :monto-a-cubrir="Number(orden.monto) - Number(orden.montoCubierto)"
      :cliente="orden && orden.cliente
        ? orden.cliente
        : { id:'' }"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarPago = $event;"
    />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import ModalConfirmarPermiso from "@/components/ModalConfirmarPermiso.vue";
import ModalAsignarProductos from "@/components/ModalAsignarProductos.vue";

import { EstatusPago, EstatusPagoIconos, EstatusPagoIconoColor } from "@/constantes/pagos.js";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import FormularioPago from "@/components/FormularioPago.vue";
import FormularioOrden from "@/components/FormularioOrden.vue";
import FormularioOrdenProducto from "@/components/FormularioOrdenProducto.vue";
import ModalAsignarPago from "@/components/ModalAsignarPago.vue";
import gql from "graphql-tag";
import ordenCompraGql from "@/graphql/ordenCompra.gql";
import ordenEditarGql from "@/graphql/ordenEditar.gql";
import ordenEliminarGql from "@/graphql/ordenEliminar.gql";
import pagoCrearGql from "@/graphql/pagoCrear.gql";
import ordenProductoEliminarGql from "@/graphql/ordenProductoEliminar.gql";
import pagoDesasignarItemGql from "@/graphql/pagoDesasignarItem.gql"
import { leerRoles } from "@/utils/datosToken.js";
import { mdbBtn } from "mdbvue"
export default {
  name: 'Orden',
  components: {
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    ModalConfirmarPermiso,
    mdbBtn,
    FormularioPago,
    FormularioOrden,
    FormularioOrdenProducto,
    ModalAsignarPago,
    ModalAsignarProductos,
  },
  data () {
    return {
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      datosModalConfirmarEliminar: {
        tipo: '',
        mensaje: '',
        sustantivoFemenino: false
      },
      mostrarModalConfirmarEliminar: false,
      mostrarModalConfirmarPermiso: false,
      mostrarFormularioPago:false,
      mostrarFormularioOrden: false,
      mostrarFormularioOrdenProducto: false,
      mostrarModalAsignarPago: false,
      mostrarModalAsignarProductos: false,
      mostrarPaquetesProducto: [],
      pagoDesasignarId: '',
      esEditar: false,
      opcionAEliminar: '',
      objetoAlConfirmarPermiso: {},
      orden: {},
      ordenAsignar: {
        enlace: "",
        miniatura: "",
        estatus: "",
        fecha: {},
      },
      productoAEditar: {},
      rol: leerRoles(),
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      OrdenEstatus,
      PaqueteEstatus,
    }
  },
  computed: {
    esPermitido(){
      return (this.rol.includes('Admin') || this.rol.includes('OpAlmacen'))
    },
    esAgencia(){
      return (this.rol.includes('Agencia'))
    },
    validarEstatusProductos () {
      if(this.orden.producto && this.orden.producto.length) {
        const flag = this.orden.producto.filter(producto => !(producto.estatus === 'EnEspera' || producto.estatus === 'Identificado' || producto.estatus === 'Cotizado'))
        return !(flag.length)
      } else return true
    }
  },
  methods: {
    mostrarMensajeAlerta({ contenido, tipo }) {
      if (!contenido || !tipo) return;
      this.alertaMensaje = { contenido, tipo };
    },
    errorCliente() {
      return (this.alertaMensaje = {
        contenido: "El cliente es requerido para crear el pago",
        tipo: "error",
      });
    },
     esPalabraPlural(paquetes, compras){
      return paquetes.length > 1 || compras.length > 1
    },
    async pagoCrear(event) {
      this.botonDeshabilitado = true;
      const {  compras, paquetes, pago, medioId, comprobante } = event;
      const { cliente, ...restPago } = pago;
      delete pago.__typename;
      delete pago.fecha.__typename;
      delete cliente.__typename;
      const { id: idMedio } = medioId;
      try {
        this.$apollo.mutate({
          mutation: gql`
            ${pagoCrearGql}
          `,
          variables: {
            pago: restPago,
            medioId: idMedio,
            personaId: cliente.id,
            comprobante: { foto: comprobante },
            paquetes, 
            compras,
          },
          update: (cache, { data: { pagoCrear } }) => {
            const datosPagoACache = JSON.parse(JSON.stringify(restPago));
            datosPagoACache.__typename = "Pago";
            datosPagoACache.fecha.formatted = "Date";
            datosPagoACache.fecha.__typename = "Date";
            const idPago = JSON.parse(JSON.stringify(pagoCrear.id));
            let montoCubierto = 0;
            let pagado = false;
            compras.forEach((compra) =>{
              if (compra.id === this.orden.id) {
                montoCubierto = compra.monto
                pagado = true;
              }
            })
            const objetoPago = {
              monto: montoCubierto,
              Pago: {
                id: idPago,
                ...datosPagoACache,
                medio: {
                  id: idMedio,
                  nombre: "",
                  descripcion: "",
                  __typename: "Medio",
                },
                cliente: {
                  id: cliente.id,
                  nombre: cliente.nombre,
                  telefonos: cliente.telefonos,
                  casillero: cliente.casillero,
                  __typename: "Cliente",
                },
                __typename: "_OrdenPagos",
              },
              __typename: "Pago",
            };
            const data = cache.readQuery({
              query: gql`
                ${ordenCompraGql}
              `,
              variables: {
                id: this.orden.id,
              },
            });
            data.Orden[0].cliente.__typename = "Cliente";
            data.Orden[0].montoCubierto += montoCubierto
            data.Orden[0].pagado = pagado;
            data.Orden[0].pagos.push(objetoPago);
            cache.writeQuery({
              query: gql`
                ${ordenCompraGql}
              `,
              variables: {
                id: this.orden.id,
              },
              data,
            });
          },
        });
        this.mostrarFormularioPago = false;
        this.mostrarMensajeAlerta({
          contenido: "Pago creado correctamente",
          tipo: "correcto",
        });
      } catch (error) {
        this.mostrarMensajeAlerta({
          contenido: "Error al crear el pago",
          tipo: "error",
        });
      } finally {
        this.botonDeshabilitado = false;
      }
    },
    modalConfirmarEliminar(opcion) {
      switch (opcion) {
        case 'orden':
          this.datosModalConfirmarEliminar = {
            tipo: opcion,
            mensaje: 'Se eliminarán las relaciones con sus paquetes y pagos, además se eliminarán los datos de la compra',
            sustantivoFemenino: true
          }
          break
        case 'producto':
          this.datosModalConfirmarEliminar = {
            tipo: opcion,
            mensaje: 'Se eliminará el producto de la orden de compra',
            sustantivoFemenino: false
          }
          break
        case 'pago':
          this.datosModalConfirmarEliminar = {
            tipo: opcion,
            mensaje: 'Se eliminará este pago de la orden de compra',
            sustantivoFemenino: false
          }
          break
      }
      this.mostrarModalConfirmarEliminar = true
    },
    eliminar() {
      switch (this.datosModalConfirmarEliminar.tipo) {
        case 'orden':
          this.ordenEliminar();
          break
        case 'producto':
          this.ordenProductoEliminar();
          break
        case 'pago':
          this.pagoDesasignarItem();
          this.mostrarModalConfirmarEliminar = false
          this.opcionAEliminar = ''
          break
      }
      this.opcionAEliminar = ''
      this.mostrarModalConfirmarEliminar = false
    },
    editarPreparar() {
      this.mostrarFormularioOrden=!this.mostrarFormularioOrden;
      this.esEditar=!this.esEditar;
      this.ordenAsignar = JSON.parse(JSON.stringify(this.orden))
      // this.ordenAsignar = { ...this.orden };
      delete this.ordenAsignar.pagos;
      delete this.ordenAsignar.productos;
    },
    ordenEditar(orden) {
      const ordenEditar = JSON.parse(JSON.stringify(orden));
      delete ordenEditar.pagos;
      delete ordenEditar.productos;
      delete ordenEditar.compraId;
      delete ordenEditar.montoCubierto;
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: ordenEditarGql,
          variables: {
            orden: ordenEditar,
          },
          update: (store) => {
            const data = store.readQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
            });
            Object.assign(data.Orden[0], {...orden})
            data.Orden[0].__typename = 'Orden';
            data.Orden[0].fecha.__typename = '_Neo4jDateTime';
            store.writeQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({ data: { ordenEditar } }) => {
          switch (ordenEditar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Orden actualizada correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo actualizar la orden",
                tipo: "error",
              };
              break;
            default:
              this.alertaMensaje = {
                contenido: "Ocurrió un error actualizando la orden",
                tipo: "error",
              };
              break;
          }
          this.limpiarCamposForm();
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: "Ocurrió un error actualizando la orden",
            tipo: "error",
          };

          this.limpiarCamposForm();
        });
    },
    limpiarCamposForm() {
      this.esEditar = false;
      this.ordenAsignar = {
        enlace: "",
        miniatura: "",
        estatus: "",
        fecha: {},
      };
      this.cliente = {};
      this.botonDeshabilitado = false;
      this.mostrarFormularioOrden = false;
    },
    ordenEliminar() {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: ordenEliminarGql,
          variables: {
            ordenId: this.$route.params.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .then(({data:{ordenEliminar}}) => {
          switch (ordenEliminar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Eliminado correctamente",
                tipo: "correcto",
              };
              this.$emit('eliminar');
              this.$router.go(-1);
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo eliminar la orden",
                tipo: "error",
              };
              break;
            default:
              this.alertaMensaje = {
                contenido: "Ocurrió un error inesperado",
                tipo: "error",
              };
            }
            this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error eliminando la orden",
            tipo: "error",
          };
        });
    },
    validarModalAsignarPago(){
      if(this.orden && this.orden.cliente){
        this.mostrarModalAsignarPago = true
      } else {
        return (
          this.alertaMensaje = {
            contenido: 'Es requerido que la orden cuente con un cliente para asignar un pago',
            tipo: 'advertencia'
          }
        )
      }
    },
    ordenProductoEliminar() {
      const producto = JSON.parse(JSON.stringify(this.objetoAlConfirmarPermiso))
      delete producto.__typename;
      delete producto.paquetes;
      this.$apollo
        .mutate({
          mutation: ordenProductoEliminarGql,
          variables: {
            ordenProducto: producto,
            orden: {id: this.$route.params.id},
          },
          update: (store) => {
            const data = store.readQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
            });
            data.Orden[0].productos = data.Orden[0].productos
              ? data.Orden[0].productos.filter((p) => {
                return (p.id !== producto.id);
              })
              : [];
            store.writeQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenProductoEliminar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({ data: { ordenProductoEliminar } }) => {
          switch (ordenProductoEliminar.codigo) {
            case "Correcto":
              this.$emit("alerta-mensaje", {
                contenido: "Producto eliminando correctamente",
                tipo: "correcto",
              });
              this.$emit("alerta-mensaje", {
                contenido: "Producto eliminando correctamente",
                tipo: "correcto",
              });
              this.$emit('cerrar', false);
              break;
            case "Fallido":
              
              this.$emit("alerta-mensaje", {
                contenido: "No se pudo eliminar el producto",
                tipo: "error",
              });
              break;
            default:
              
              this.$emit("alerta-mensaje", {
                contenido: "Ocurrió un error eliminando el producto",
                tipo: "error",
              });
              break;
          }
          return this.botonDeshabilitado = false;
        })
        .catch((err) => {
          this.$emit("alerta-mensaje", {
            contenido: "Ocurrió un error eliminando el producto"+err,
            tipo: "error",
          });
          return this.botonDeshabilitado = false;
        });
    },
    pagoDesasignarItem() {
      const objetoAlConfirmarPermiso = JSON.parse(JSON.stringify(this.objetoAlConfirmarPermiso)) 
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`${pagoDesasignarItemGql}`,
          variables: {
            itemId: this.$route.params.id,
            pagoId: objetoAlConfirmarPermiso.Pago.id,
          },
          update: (store) => {
            const data = store.readQuery({
              query: gql`${ordenCompraGql}`,
              variables: {
                id: this.$route.params.id,
              },
            });
            data.Orden[0].montoCubierto -= Number(objetoAlConfirmarPermiso.monto)
            data.Orden[0].pagado = false;
            data.Orden[0].pagos = data.Orden[0].pagos
              .filter((p) => p.Pago.id !==objetoAlConfirmarPermiso.Pago.id);
            store.writeQuery({
              query: gql`${ordenCompraGql}`,
              variables: {
                id: this.$route.params.id,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoDesasignarItem: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({data}) => {
          switch (data.pagoDesasignarItem.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Pago desasignado correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo desasignar el pago",
                tipo: "error",
              };
              break;
          
            default:
              break;
          }
          // this.pagoDesasignarId = '';
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: "Ocurrió un error desasignando el pago",
            tipo: "error",
          };
          this.botonDeshabilitado = false;
        });
    },
  },
  apollo: {
    orden() {
      return {
        query: gql`
          ${ordenCompraGql}
        `,
        variables: {
          id: this.$route.params.id
        },
        update: (data) => data.Orden[0],
        fetchPolicy: "cache-and-network",
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.btnW-fixed {
  width: 166px;

  @media screen and (min-width: 1200px) {
    width: 200px;
  }
}

.btn-eliminar {
  width: 80px;
}
</style>
<template>
  <section>
    <mdb-modal
      centered
      elegant
      size="lg"
      scrollable
      :show="mostrarModal"
      @close="cerrarModal()"
    >
      <mdb-modal-header v-if="listarOrdenes">
        <mdb-modal-title>
          <mdb-btn
            v-if="step === 2"
            flat
            dark-waves
            icon="arrow-left"
            class="m-0 mr-1 p-2"
            @click="step--; mostrarBotonEliminarOrden=false"
          >
            Atrás
          </mdb-btn>
          {{ step === 2 ? 'Detalles de la orden' : 'Órdenes de reempaque'}}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-header v-else>
        <mdb-modal-title>Orden de reempaque</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body v-if="listarOrdenes" class="pt-0">
        <!-- Caso sin órdenes -->
        <div
          v-if="!ordenes.length"
          class="text-center text-muted user-select-none mb-4"
        >
          <font-awesome-icon
            icon="box-open"
            class="fa-5x mb-4"
          />
          <p class="h5-responsive text-center mb-0 mx-lg-5 px-lg-5">
            No existen órdenes de reempaque registradas.
            Por favor agrega una orden de reempaque para continuar.
          </p>
        </div>
        <!-- Caso listar órdenes -->
        <div v-else>
          <transition
            mode="out-in"
            name="listas-transicion"
          >
            <section v-if="step === 1" key="listaOrdenes">
              <ul class="table" data-table="sticky">
                <li class="encabezado row">
                  <div class="col-1" title="Número de orden">#</div>
                  <div class="col">Detalles de envío</div>
                  <div class="col d-none d-sm-block">Dirección asignada</div>
                  <div class="col-2 d-none d-lg-block">Receptor</div>
                  <div class="col d-none d-lg-block">Cliente</div>
                </li>
                <li
                  v-for="(orden, iO) in ordenes"
                  :key="`orden${iO}`"
                  class="contenido row"
                  @click="ordenSeleccionada = orden;
                    siguientePagina()"
                >
                  <!-- Nro de orden -->
                  <div class="col-1">{{iO+1}}</div>
                  <!-- Detalles de envío -->
                  <div class="col">
                    <p class="mb-0">
                      Tipo de envío:
                      <span :class="{'text-muted user-select-none': !orden.envio}">
                        {{ orden.envio ? ConsolidadoTipoEnvio[orden.envio] : 'Sin especificar' }}
                      </span>
                    </p>
                    <p class="mb-0">
                      Paquetes: {{ orden.paquetes.length }}
                    </p>
                    <p v-if="orden.observacion" class="mb-0">
                      Observación:
                      <span
                        :class="{'text-muted font-italic user-select-none': !orden.observacion}"
                      >
                        {{ orden.observacion ? orden.observacion : 'Sin especificar' }}
                      </span>
                    </p>
                    <p class="mb-0 mt-1 d-sm-none">
                      Dirección asignada:
                      <span v-if="orden.direccion && orden.direccion.id" class="d-block">
                        {{ orden.direccion.ciudad.nombre }},
                        {{ orden.direccion.ciudad.estado.nombre }},
                        {{ orden.direccion.ciudad.estado.pais.nombre }}.
                      </span>
                      <span v-if="orden.direccion && orden.direccion.id">
                        {{orden.direccion.direccion}}
                      </span>
                      <span v-else class="text-muted font-italic user-select-none">Sin especificar</span>
                    </p>
                    <p class="mb-0 mt-1 d-lg-none">
                      Receptor:
                      <span
                        :class="{'text-muted font-italic user-select-none':
                          !(orden.direccion && orden.direccion.recibe && orden.direccion.recibe.nombre)}"
                      >
                        {{ orden.direccion && orden.direccion.recibe && orden.direccion.recibe.nombre
                            ?  orden.direccion.recibe.nombre
                            : 'Sin especificar'
                        }}
                      </span>
                      <ul
                        v-if="orden.direccion && orden.direccion.recibe && orden.direccion.recibe.telefonos && orden.direccion.recibe.telefonos.length"
                        class="list-unstyled mb-0"
                      >
                        <li
                          v-for="(tel, i) in orden.direccion.recibe.telefonos"
                          :key="`tel${i}`"
                          class="d-inline"
                        >
                          {{i > 0 ? ', ' : ''}}
                          <a
                            :href="`tel:+${tel.numero}`"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +{{tel.numero}}
                          </a>
                        </li>
                      </ul>
                      <span v-else class="text-muted font-italic user-select-none d-block">
                        Sin teléfono de contacto
                      </span>
                    </p>
                    <p class="mb-0 mt-1 d-lg-none">
                      Cliente: {{ orden.cliente.nombre }}
                    </p>
                    <p class="mb-0 d-lg-none">
                      Casillero:
                      <span :class="{'text-muted user-select-none': !(orden.cliente.casillero)}">
                        {{orden.cliente.casillero ? orden.cliente.casillero : 'Sin especificar'}}
                      </span>
                      <a
                        v-if="orden.cliente.telefonos
                          && orden.cliente.telefonos.length"
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="`tel:+${orden.cliente.telefonos[0].numero}`"
                        class="d-block text-break"
                        @click.stop
                      >
                        +{{ orden.cliente.telefonos[0].numero }}
                      </a>
                      <span
                        v-if="orden.cliente.telefonos
                          && orden.cliente.telefonos.length"
                      >
                        <a
                          :href="`https://wa.me/${orden.cliente.telefonos[0].numero}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                          @click.stop
                        >
                          <font-awesome-icon :icon="['fab', 'whatsapp']" />
                          WA
                        </a>
                        <a
                          :href="`sms:+${orden.cliente.telefonos[0].numero}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                          @click.stop
                        >
                          <font-awesome-icon icon="sms" />
                          Sms
                        </a>
                      </span>
                      <a
                        v-if="orden.cliente.emails && orden.cliente.emails.length"
                        :href="`mailto:${orden.cliente.emails[0].email}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="d-block text-break"
                        @click.stop
                      >
                        {{ orden.cliente.emails[0].email }}
                      </a>
                    </p>
                  </div>
                  <div class="col d-none d-sm-block">
                    <span v-if="orden.direccion && orden.direccion.id" class="d-block">
                      {{ orden.direccion.ciudad.nombre }},
                      {{ orden.direccion.ciudad.estado.nombre }},
                      {{ orden.direccion.ciudad.estado.pais.nombre }}.
                    </span>
                    <span v-if="orden.direccion && orden.direccion.id">
                      {{orden.direccion.direccion}}
                    </span>
                    <span v-else class="text-muted font-italic user-select-none">Sin especificar</span>
                  </div>
                  <div class="col-2 d-none d-lg-block">
                    <span
                      :class="{'text-muted font-italic user-select-none':
                        !(orden.direccion && orden.direccion.recibe && orden.direccion.recibe.nombre)}"
                    >
                      {{ orden.direccion && orden.direccion.recibe && orden.direccion.recibe.nombre
                        ?  orden.direccion.recibe.nombre
                        : 'Sin especificar'
                      }}
                    </span>
                    <ul
                      v-if="orden.direccion && orden.direccion.recibe && orden.direccion.recibe.telefonos && orden.direccion.recibe.telefonos.length"
                      class="list-unstyled mb-0"
                    >
                      <li
                        v-for="(tel, i) in orden.direccion.recibe.telefonos"
                        :key="`tel${i}`"
                        class="d-inline"
                      >
                        {{i > 0 ? ', ' : ''}}
                        <a
                          :href="`tel:+${tel.numero}`"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          +{{tel.numero}}
                        </a>
                      </li>
                    </ul>
                    <span v-else class="text-muted font-italic user-select-none d-block">
                      Sin teléfono de contacto
                    </span>
                  </div>
                  <div class="col d-none d-lg-block">
                    {{ orden.cliente.nombre }}
                    <p class="mb-0">
                      Casillero:
                      <span :class="{'text-muted user-select-none': !(orden.cliente.casillero)}">
                        {{orden.cliente.casillero ? orden.cliente.casillero : 'Sin especificar'}}
                      </span>
                      <a
                        v-if="orden.cliente.telefonos
                          && orden.cliente.telefonos.length"
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="`tel:+${orden.cliente.telefonos[0].numero}`"
                        class="d-block text-break"
                        @click.stop
                      >
                        +{{ orden.cliente.telefonos[0].numero }}
                      </a>
                      <span
                        v-if="orden.cliente.telefonos
                          && orden.cliente.telefonos.length"
                      >
                        <a
                          :href="`https://wa.me/${orden.cliente.telefonos[0].numero}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                          @click.stop
                        >
                          <font-awesome-icon :icon="['fab', 'whatsapp']" />
                          WA
                        </a>
                        <a
                          :href="`sms:+${orden.cliente.telefonos[0].numero}`"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                          @click.stop
                        >
                          <font-awesome-icon icon="sms" />
                          Sms
                        </a>
                      </span>
                      <a
                        v-if="orden.cliente.emails && orden.cliente.emails.length"
                        :href="`mailto:${orden.cliente.emails[0].email}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="d-block text-break"
                        @click.stop
                      >
                        {{ orden.cliente.emails[0].email }}
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </section>
            <section v-else-if="step === 2" key="detallesOrden">
              <header class="font-weight-bold">Paquetes seleccionados</header>
              <div class="row">
                <div class="col-12 col-sm">
                  <PaqueteBuscador
                    id="paqueteBuscador-form"
                    class="my-2"
                    placeholder="Ingrese el tracking o paquete ID"
                    :limpiarPaquete="limpiarPaquete"
                    @paquete="paqueteObtenidos($event)"
                    @limpiar="limpiarPaquete = $event"
                  />
                  <label
                    for="paqueteBuscador-form"
                    class="outline-datalist-label top-8"
                  >
                    Buscar paquete en la orden
                  </label>
                </div>
              </div>
              <ul class="table" data-table="sticky">
                <li class="encabezado row">
                  <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
                  <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
                  <div class="col">Detalles</div>
                  <div class="col-4 col-md d-none d-sm-block">Receptor</div>
                </li>
                <li
                  v-for="paquete in ordenSeleccionadaPaquetesSinRempacar"
                  :key="`paquete-${paquete.id}`"
                  class="contenido normal row align-items-center"
                >
                  <!-- Fecha recibido -->
                  <div class="col-3 col-sm-2 col-lg-1 text-center">
                    <label class="opciones-checkbox top w-100">
                      <input
                        v-model="paquetesSeleccionados"
                        type="checkbox"
                        name="seleccionar-paquete-checkbox"
                        :value="paquete"
                      />
                      <span class="icono-checkbox" />
                      <span class="d-block">
                        {{ formatearFecha(paquete.fecha.formatted).ddmm }}
                      </span>
                      {{ formatearFecha(paquete.fecha.formatted).yyyy }}
                    </label>
                  </div>
                  <!-- Estatus -->
                  <div class="col-4 col-md-3 d-none d-lg-block">
                    {{PaqueteEstatus[paquete.estatus]}}
                    <p class="mb-0">
                      Envío:
                      <font-awesome-icon
                        v-if="paquete.envio"
                        :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                        class="texto-terciario ml-1"
                      />
                      <span :class="{'text-muted font-italic': !paquete.envio}">
                        {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                      </span>
                    </p>
                    <p class="mb-0">
                      Tipo:
                      <span :class="{'text-muted font-italic': !paquete.tipo}">
                        {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                      </span>
                    </p>
                  </div>
                  <!-- Detalles -->
                  <div class="col">
                    <!-- Columna estatus -->
                    <div class="d-lg-none mb-1">
                      {{PaqueteEstatus[paquete.estatus]}}
                      <p class="mb-0">
                        Envío:
                        <font-awesome-icon
                          v-if="paquete.envio"
                          :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                          class="texto-terciario ml-1"
                        />
                        <span :class="{'text-muted font-italic': !paquete.envio}">
                          {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                        </span>
                      </p>
                      <p class="mb-0">
                        Tipo:
                        <span :class="{'text-muted font-italic': !paquete.tipo}">
                          {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                        </span>
                      </p>
                    </div>
                    Tracking:
                    <span :class="{'text-muted font-italic': !paquete.tracking}">
                      {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
                    </span>
                    <p class="mb-0">
                      <span title="Peso por volumen">
                        vlbs: {{
                          mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                        }}
                        |
                      </span>
                      <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span>
                      <span title="Pies cúbicos">
                        ft<sup>3</sup>: {{
                          mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                        }}
                      </span>
                    </p>
                    <!-- Columna receptor -->
                    <p class="mb-0 mt-1 d-sm-none">
                      Receptor:
                      <span :class="{'text-muted font-italic': !paquete.receptor}">
                        {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                      </span>
                    </p>
                    <p class="mb-0 d-sm-none">
                      Instrucciones de envío:
                      <!-- Dirección de envío: -->
                      <span
                        v-if="paquete.direccionDestino
                          && paquete.direccionDestino.ciudad
                          && paquete.direccionDestino.direccion"
                      >
                        {{ paquete.direccionDestino.ciudad.estado.nombre }},
                        {{ paquete.direccionDestino.ciudad.nombre }},
                        {{ paquete.direccionDestino.direccion }}
                      </span>
                      <span v-else class="text-muted font-italic">Sin especificar</span>
                    </p>
                    <span v-if="paquete.observacion">
                      Observación: {{ paquete.observacion }}
                    </span>
                  </div>
                  <!-- Receptor -->
                  <div class="col-4 col-md d-none d-sm-block">
                    <span :class="{'text-muted font-italic': !paquete.receptor}">
                      {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                    </span>
                    <p class="mb-0">
                      Instrucciones de envío:
                      <!-- Dirección de envío: -->
                      <span
                        v-if="paquete.direccionDestino
                          && paquete.direccionDestino.ciudad
                          && paquete.direccionDestino.direccion"
                      >
                        {{ paquete.direccionDestino.ciudad.estado.nombre }},
                        {{ paquete.direccionDestino.ciudad.nombre }},
                        {{ paquete.direccionDestino.direccion }}
                      </span>
                      <span v-else class="text-muted font-italic">Sin especificar</span>
                    </p>
                  </div>
                </li>
              </ul>
              <div class="row justify-content-around mt-3">
                <div class="col-12 col-sm-6">
                  <header class="font-weight-bold">Tipo de envío</header>
                  {{ConsolidadoTipoEnvio[ordenSeleccionada.envio]}}
                </div>
                <!-- Persona que recibe-->
                <div class="col-12 col-sm-6 mb-1">
                  <header class="font-weight-bold">Receptor</header>
                  <span
                    :class="{'text-muted user-select-none':
                      !(ordenSeleccionada.direccion && ordenSeleccionada.direccion.recibe
                        && ordenSeleccionada.direccion.recibe.nombre)}"
                  >
                    {{ ordenSeleccionada.direccion && ordenSeleccionada.direccion.recibe && ordenSeleccionada.direccion.recibe.nombre
                      ?  ordenSeleccionada.direccion.recibe.nombre : 'Sin especificar'
                    }}
                  </span>
                </div>
                <div class="col-12 col-lg-6 mb-1">
                  <header class="font-weight-bold">Dirección para el reempaque</header>
                  <span v-if="ordenSeleccionada.direccion">
                    {{ ordenSeleccionada.direccion.ciudad.estado.nombre }},
                    {{ ordenSeleccionada.direccion.ciudad.nombre }}.
                    <!-- Dirección-->
                    {{ ordenSeleccionada.direccion.direccion }}
                  </span>
                  <span v-else class="text-muted user-select-none">Sin especificar</span>
                </div>
                <div class="col-12 col-lg-6 mb-1">
                  <header class="font-weight-bold">Observación</header>
                  <span
                    :class="{'text-muted user-select-none': !(ordenSeleccionada.observacion)}"
                  >
                    {{ordenSeleccionada.observacion ? ordenSeleccionada.observacion : 'Sin especificar'}}
                  </span>
                </div>
                <mdb-btn
                  v-if="mostrarBotonEliminarOrden && !esAgencia"
                  flat
                  dark-waves
                  :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
                  :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                  class="mt-3 px-3 btn-bordeado"
                  :disabled="botonDeshabilitado"
                  @click="verificarEliminarOrden"
                >
                  Eliminar orden
                </mdb-btn>
                <mdb-btn
                  v-if="!esAgencia && paquetesSeleccionados.length"
                  color="primario"
                  :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
                  :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                  class="mt-3 px-3"
                  :disabled="botonDeshabilitado"
                  @click="reempacarPaquetes"
                >
                  Reempacar
                </mdb-btn>
              </div>
            </section>
          </transition>
        </div>
      </mdb-modal-body>
      <mdb-modal-body v-else class="pt-0">
        <div>
          <header class="font-weight-bold">Paquetes seleccionados</header>
          <ul class="table" data-table="sticky">
            <li class="encabezado row">
              <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
              <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
              <div class="col">Detalles</div>
              <div class="col-4 col-md d-none d-sm-block">Receptor</div>
            </li>
            <li
              v-for="paquete in paquetes"
              :key="`paquete-${paquete.id}`"
              class="contenido normal row align-items-center"
            >
            <!-- Fecha recibido -->
              <div class="col-3 col-sm-2 col-lg-1">
                <span class="d-block">
                  {{ formatearFecha(paquete.fecha.formatted).ddmm }}
                </span>
                {{ formatearFecha(paquete.fecha.formatted).yyyy }}
              </div>
              <!-- Estatus -->
              <div class="col-4 col-md-3 d-none d-lg-block">
                {{PaqueteEstatus[paquete.estatus]}}
                <p class="mb-0">
                  Envío:
                  <font-awesome-icon
                    v-if="paquete.envio"
                    :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                    class="texto-terciario ml-1"
                  />
                  <span :class="{'text-muted font-italic': !paquete.envio}">
                    {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <span :class="{'text-muted font-italic': !paquete.tipo}">
                    {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                  </span>
                </p>
              </div>
              <!-- Detalles -->
              <div class="col">
                <!-- Columna estatus -->
                <div class="d-lg-none mb-1">
                  {{PaqueteEstatus[paquete.estatus]}}
                  <p class="mb-0">
                    Envío:
                    <font-awesome-icon
                      v-if="paquete.envio"
                      :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                      class="texto-terciario ml-1"
                    />
                    <span :class="{'text-muted font-italic': !paquete.envio}">
                      {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                    </span>
                  </p>
                  <p class="mb-0">
                    Tipo:
                    <span :class="{'text-muted font-italic': !paquete.tipo}">
                      {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                    </span>
                  </p>
                </div>
                Tracking:
                <span :class="{'text-muted font-italic': !paquete.tracking}">
                  {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
                </span>
                <p class="mb-0">
                  Pies cúbicos:
                  <span title="Pies cúbicos">
                    {{
                      mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                    }} ft<sup>3</sup>
                  </span>
                </p>
                Peso:
                <span title="Libras">
                  {{ mostrarSoloDosDecimales(paquete.peso) }} lb
                </span>
                <p class="mb-0">
                  Peso por volúmen:
                  <span title="Libras">
                    {{
                      mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                    }} lb
                  </span>
                </p>
                <!-- Columna receptor -->
                <p class="mb-0 mt-1 d-sm-none">
                  Receptor:
                  <span :class="{'text-muted font-italic': !paquete.receptor}">
                    {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                  </span>
                </p>
                <p class="mb-0 d-sm-none">
                  Instrucciones de envío:
                  <!-- Dirección de envío: -->
                  <span v-if="paquete.direccionDestino
                    && paquete.direccionDestino.ciudad
                    && paquete.direccionDestino.direccion"
                  >
                    {{ paquete.direccionDestino.ciudad.estado.nombre }},
                    {{ paquete.direccionDestino.ciudad.nombre }},
                    {{ paquete.direccionDestino.direccion }}
                  </span>
                  <span v-else class="text-muted font-italic">Sin especificar</span>
                </p>
                <span v-if="paquete.observacion">
                  Observación: {{ paquete.observacion }}
                </span>
              </div>
              <!-- Receptor -->
              <div class="col-4 col-md d-none d-sm-block">
                Receptor:
                <span :class="{'text-muted font-italic': !paquete.receptor}">
                  {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                </span>
                <p class="mb-0">
                  Instrucciones de envío:
                  <!-- Dirección de envío: -->
                  <span v-if="paquete.direccionDestino
                    && paquete.direccionDestino.ciudad
                    && paquete.direccionDestino.direccion"
                  >
                    {{ paquete.direccionDestino.ciudad.estado.nombre }},
                    {{ paquete.direccionDestino.ciudad.nombre }},
                    {{ paquete.direccionDestino.direccion }}
                  </span>
                  <span v-else class="text-muted font-italic">Sin especificar</span>
                </p>
              </div>
            </li>
          </ul>
          <header class="font-weight-bold mt-2">Asignar el tipo de envío deseado</header>
          <div class="md-form md-outline outline-select my-2">
            <select
              id="tipo-select"
              v-model="paquete.envio"
              class="custom-select"
            >
              <option class="d-none" disabled value="">
                Seleccione
              </option>
              <option
                v-for="(key, value) in ConsolidadoTipoEnvio"
                :key="key"
                :value="value"
              >
                {{ key }}
              </option>
            </select>
          </div>
          <header class="font-weight-bold mt-2">
            Asignar dirección al reempaque <small class="text-muted">(opcional)</small>
          </header>
          <div
            :class="['md-form md-outline outline-select my-2',
              {'cursor-not-allowed': !direccionesDelCliente.length}]"
          >
            <select
              id="direccion-select"
              v-model="paquete.direccion"
              class="custom-select"
              :disabled="!direccionesDelCliente.length"
            >
              <option class="d-none" disabled :value="{}">{{
                direccionesDelCliente.length
                  ? 'Selecciona una dirección'
                  : 'El cliente no cuenta con direcciones'
                }}
              </option>
              <option
                v-for="(direccion, i) in direccionesDelCliente"
                :key="i+direccion.id"
                :value="direccion"
              >
                <!--  -->
                {{ direccion.ciudad.estado.nombre }},
                {{ direccion.ciudad.nombre }}
                <!-- Dirección-->
                - {{ direccion.direccion }}
                <!-- Persona que recibe-->
                - Receptor:
                  {{ direccion.recibe && direccion.recibe.nombre
                    ?  direccion.recibe.nombre : 'Sin especificar'
                  }}
              </option>
            </select>
          </div>
          <header class="font-weight-bold mt-2">Observación <small class="text-muted">(opcional)</small></header>
          <mdb-input
            v-model.trim="paquete.observacion"
            type="textarea"
            placeholder="Ej: Reempacar en sobre"
            class="my-2"
            outline
          />
          <div class="row justify-content-center mt-3">
            <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              class="px-3"
              :disabled="botonDeshabilitado"
              @click="validarForm"
            >
              Agregar
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
     <!-- Modal Nuevo paquete  / reempaque-->
    <FormularioPaquete
      titulo="Datos del nuevo paquete tipo reempaque"
      :esReempaque="true"
      :parametrosOrdenPaquete="parametrosOrdenPaquete"
      :paquetes="ordenSeleccionada && ordenSeleccionada.paquetes
        ? ordenSeleccionada.paquetes
        : []"
      :paquetesId="ordenSeleccionada && ordenSeleccionada.paquetes && paquetesSeleccionados.length
        ? paquetesSeleccionados.map((p) => p.id)
        : []"
      :mostrar-modal="mostrarFormularioPaquete"
      :variablesUpdateQueryViewPaquetes="variablesUpdateQueryViewPaquetes"
      :personaIdReempacar="ordenSeleccionada && ordenSeleccionada.cliente && ordenSeleccionada.cliente.id"
      @alertaMensaje="alertaMensaje($event)"
      @eliminarOrdenReempaque="verificarEliminarOrden()"
      @cerrar="
        mostrarFormularioPaquete = $event;
        cerrarModal();
      "
      @paginacionResetear="$emit('paginacionResetear', true)"
    />
  </section>
</template>

<script>
import gql from 'graphql-tag'
import ordenesReempaqueGql from '@/graphql/ordenesReempaque.gql'
import ordenReempaqueEliminarGql from '@/graphql/ordenReempaqueEliminar.gql'
import { capitalizarPrimeraLetra, formatearFecha, mostrarSoloDosDecimales } from '@/funciones/funciones.js'
import { PaqueteEstatus, PaqueteTipos } from '@/constantes/paquetes.js'
import FormularioPaquete from "@/components/FormularioPaquete.vue";
import ordenReempaqueCrearGql from '@/graphql/ordenReempaqueCrear.gql'
import clienteBuscarDireccionesGql from '@/graphql/clienteBuscarDirecciones.gql'
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from '@/constantes/consolidados.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'
import PaqueteBuscador from './PaqueteBuscador.vue'
export default {
  name: 'FormularioOrdenReempacar',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    FormularioPaquete,
    PaqueteBuscador
},
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    esEditar: {
      type: Boolean,
      required: false
    },
    // Permite saber si se está operando en la view de agencia
    esAgencia: {
      type: Boolean,
      required: false
    },
    paquetes: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    listarOrdenes: {
      type: Boolean,
      required: false
    },
    clienteId: {
      type: String,
      required: false
    },
    agenciaId: {
      type: String,
      required: false
    },
    variablesUpdateQueryViewPaquetes: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  data () {
    return {
      // Reempaque
      mostrarFormularioPaquete: false,
      parametrosOrdenPaquete: {},
      mostrarBotonEliminarOrden: false,
      // Steps
      step: 1,
      ordenSeleccionada: {},
      // Crear solicitud
      direccionesDelCliente: [],
      paquete: {
        direccion: {},
        envio: ''
      },
      // Otros
      ordenes: [],
      validarValores: {},
      botonDeshabilitado: false,
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      mostrarSoloDosDecimales,
      formatearFecha,
      PaqueteTipos,
      limpiarPaquete:false,
      paquetesSeleccionados: [],
      ESTATUS_PAQUETES_A_REEMPAQUE: "EsperaMiami"
    }
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos()
      } else {
        if(!this.listarOrdenes && this.esAgencia){
          // buscar las direcciones del cliente en caso de solicitud reempaque
          this.clienteBuscarDirecciones()
        }
        this.ordenesBuscar()
      }
    },
    ordenSeleccionada(){
    const ordenSeleccionadaCopia = JSON.parse(JSON.stringify(this.ordenSeleccionada))
    if(!Object.values(ordenSeleccionadaCopia).length) return
     if(!this.ordenSeleccionadaPaquetesSinRempacar.length){
        this.eliminarOrdenReempaque('La orden ha sido eliminada ya que sus paquetes no estaban disponibles para reempacar')
     }
    }

  },
  computed:{
    ordenSeleccionadaPaquetesSinRempacar(){
      return this.ordenSeleccionada && this.ordenSeleccionada.paquetes && this.ordenSeleccionada.paquetes.filter((p) => !p.reempacado && p.estatus === this.ESTATUS_PAQUETES_A_REEMPAQUE)
    }
  },
  methods: {
    validarForm () {
      this.botonDeshabilitado = true
      this.validarValores = {
        envio: this.paquete.envio.trim && this.paquete.envio.length ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false
        return (this.alertaMensaje('El tipo de envío es requerido', 'advertencia'))
      }
      // Verificar con Includes si ya existen paquetes en alguna orden activa
      let paqueteContenido = false
      this.paquetes.map((p) => {
        if (!paqueteContenido) {
          this.ordenes.map(orden => {
            const encontrado = orden.paquetes.map(paquete => paquete.id).includes(p.id)
            if (encontrado) {
              paqueteContenido = true
            }
          })
        }
      })
      paqueteContenido
        ? this.alertaMensaje('Algún paquete seleccionado se encuentra dentro de una orden ' +
            'de reempaque activa. Por favor verifica tus datos', 'advertencia')
        : this.ordenReempaqueCrear()
      this.botonDeshabilitado = false
    },
    ordenReempaqueCrear () {
      this.botonDeshabilitado = true
      const envio = this.paquete.envio
      const observacion = capitalizarPrimeraLetra(this.paquete.observacion)
      const direccionId = this.paquete.direccion ? this.paquete.direccion.id : ''
      const paquetesId = this.paquetes.map((p) => (p.id))
      const personaId = this.clienteId
      // Datos para asignar a updateQuery
      const orden = {
        envio,
        paquetes: this.paquetes,
        __typename: 'OrdenReempaque'
      }
      const variablesMutate = {
        personaId,
        ordenReempaque: {
          envio
        },
        paquetesId
      }
      // Observación es opcional
      if (observacion && observacion.length) {
        Object.assign(variablesMutate, {
          ordenReempaque: {
            envio,
            observacion
          }
        })
        // Asignar a updateQuery
        Object.assign(orden, {
          observacion
        })
      }
      // dirección es opcional
      if (direccionId && direccionId.length) {
        Object.assign(variablesMutate, {
          direccionId
        })
        // Asignar a updateQuery
        Object.assign(orden, {
          direccion: this.paquete.direccion
        })
      }
      this.$apollo
        .mutate({
          mutation: ordenReempaqueCrearGql,
          variables: variablesMutate
        })
        .then(({ data: { crearOrdenReempaque } }) => {
          if (crearOrdenReempaque.id) {
            this.alertaMensaje('Orden creada correctamente',
              'correcto')
            this.$emit('limpiarSelectPaquetes', true)
            this.cerrarModal()
          } else {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
              'error')
          }
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false
        })
    },
    clienteBuscarDirecciones () {
      const id = this.clienteId
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: gql`
            ${clienteBuscarDireccionesGql}
          `,
          variables: {
            id
          },
          update: (data) => data.Cliente[0],
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Cliente && data.Cliente[0].direcciones) {
              this.direccionesDelCliente = data.Cliente[0].direcciones
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las direcciones.' +
                  ' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false
          }
        })
    },
    reempacarPaquetes(){
      const parametrosHaciaReempaque = {
        envio: this.ordenSeleccionada.envio,
      }
      this.ordenSeleccionada.direccion && this.ordenSeleccionada.direccion.id
        ? Object.assign(parametrosHaciaReempaque, {
          direccionId: this.ordenSeleccionada.direccion.id
        })
        : ''
      this.ordenSeleccionada.observacion && this.ordenSeleccionada.observacion.length
        ? Object.assign(parametrosHaciaReempaque, {
          observacion: this.ordenSeleccionada.observacion
        })
        : ''
      this.parametrosOrdenPaquete = parametrosHaciaReempaque
      // Verificar si alguno de los paquetes a reempacar se encuentran reempacados
      let advertencia = false
      this.paquetesSeleccionados.forEach(p => {
        if(p.reempacado){
          advertencia = true
        }
      });
      if(advertencia){
        this.mostrarBotonEliminarOrden = true
        return this.alertaMensaje('Alguno de los paquetes ya se encuentran reempacados' +
            ' Esta acción no puede ser realizada', 'advertencia')
      }
      this.mostrarFormularioPaquete = true
    },
    verificarEliminarOrden(){
      const paquetesSinReempacar  = this.ordenSeleccionada.paquetes.filter(p=>!p.reempacado);
      if(this.paquetesSeleccionados.length=== paquetesSinReempacar.length){
        this.eliminarOrdenReempaque()
      }
    },
    eliminarOrdenReempaque(mensajeEliminarOrdenExitoso = ''){
      const id = this.ordenSeleccionada.id
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: gql`${ordenReempaqueEliminarGql}`,
          variables: {
            ordenReempaqueId: id,
          }
        })
        .then(({data: {ordenReempaqueEliminar}}) => {
          const msg = ordenReempaqueEliminar.codigo;
          switch (msg) {
            case "Correcto":
              this.ordenes = this.ordenes.filter(o => {
                return o.id !== id
              })
              this.alertaMensaje(`${mensajeEliminarOrdenExitoso || 'Se ha eliminado la orden con éxito '} `, 'correcto');
              this.limpiarCampos();
              break;
            case "Fallido":
              this.alertaMensaje('Ha ocurrido un error eliminando la orden.'
                  +' Por favor intenta de nuevo', 'error');
              break;
            default:
              this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error');
              break;
          }
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false;
        });
    },
    siguientePagina(){
      if(this.step === 1){
        this.step++
      }
    },
    cerrarModal () {
      this.limpiarCampos()
      this.$emit('close', false)
    },
    alertaMensaje (contenido, tipo) {
      this.$emit('alertaMensaje', { contenido, tipo })
    },
    limpiarCampos () {
      // Reempaque
       this.mostrarFormularioPaquete = false;
       this.parametrosOrdenPaquete = {};
       this.mostrarBotonEliminarOrden = false;
       this.step = 1;
       this.ordenSeleccionada = {};
       this.direccionesDelCliente = [];
       this.paquete = {
         direccion: {},
         envio: ''
       };
       this.ordenes = [];
       this.validarValores = {};
       this.limpiarPaquete = false;
       this.paquetesSeleccionados = [];
       this.botonDeshabilitado = false;
    },
    ordenesBuscar () {
      const id = this.clienteId
      const variablesQuery ={
        id,
      }
      if(this.esAgencia){
        Object.assign(variablesQuery, {
          filter: {
            paquetes:{
              agenciaDestino: {
                id: this.agenciaId
              }
            }
          }
        })
      }
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: gql`
            ${ordenesReempaqueGql}
          `,
          variables: variablesQuery,
          update: (data) => data.OrdenReempaque,
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.OrdenReempaque) {
              this.ordenes = data.OrdenReempaque
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las órdenes de reempaque.' +
                  ' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false
          }
        })
    },
    paqueteObtenidos(event){
     const paqueteObtenido = JSON.parse(JSON.stringify(event));
     if(!Object.values(paqueteObtenido).length) return this.alertaMensaje('Este paquete no existe', 'advertencia');
      const paquetesEnLaOrden =  this.ordenSeleccionada && Object.values(this.ordenSeleccionadaPaquetesSinRempacar).length ?  JSON.parse(JSON.stringify(this.ordenSeleccionada?.paquetes)) : [];
      const paqueteEnLaOrden = paquetesEnLaOrden.find(paquete => paquete?.tracking?.toLowerCase() === paqueteObtenido?.tracking?.toLowerCase() || paquete?.numeroHR?.toLowerCase() === paqueteObtenido?.numeroHR.toLowerCase()) 
      this.limpiarPaquete = true
      if(!paqueteEnLaOrden)  return this.alertaMensaje('El paquete no se encuentra en la orden', 'advertencia')
        if(paqueteEnLaOrden.reempacado){
          this.alertaMensaje('El paquete se encuentra en la orden pero ya ha sido reempacado', 'advertencia')
        }else{
         this.alertaMensaje('El paquete se encuentra en la orden', 'correcto')
          this.paquetesSeleccionados.push(paqueteEnLaOrden)
        }
    }
  },
}
</script>
<style lang="scss" scoped>
// Estilos de transición entre listas
.listas-transicion-enter-active {animation-name: fadeIn;}
.listas-transicion-leave-active {animation-name: fadeOut;}

//Duración de las transiciones de Entrada
.listas-transicion-enter-active {animation-duration: .5s;}

//Duración de las transiciones de Salida
.listas-transicion-leave-active {animation-duration: .4s;}
</style>

<template>
  <transition name="alerta-transition">
    <div
      v-if="alertaMensaje.contenido && alertaMensaje.contenido.length"
      :class="['alerta', tipoAlerta.clase]"
      role="alertdialog"
      @click="cerrarAlerta"
    >
      <p>
        <font-awesome-icon
          :icon="tipoAlerta.icono"
          size="lg"
          class="mr-1"
        />
        {{ alertaMensaje.contenido }}
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AlertaMensaje',
  props: {
    alertaMensaje: {
      type: Object,
      default: function() {
        return {
          contenido: '',
        };
      },
    },
    tiempoAutoOcultar: {
      type: Number,
      default: 4500,
    },
  },
  data() {
    return {
      controlTemporizador: '',
    };
  },
  methods: {
    cerrarAlerta() {
      clearTimeout(this.controlTemporizador);
      this.$emit('cerrar');
    },
  },
  watch: {
    alertaMensaje: function(){
      clearTimeout(this.controlTemporizador);
      this.controlTemporizador = setTimeout(
        () => this.cerrarAlerta(),
        this.tiempoAutoOcultar
      );
    }
  },
  computed: {
    tipoAlerta() {
      switch(this.alertaMensaje.tipo) {
        case 'correcto':
          return {
            clase: 'exitosa',
            icono: 'check-circle'
          };
        case 'error':
          return {
            clase: 'error',
            icono: 'times-circle'
          };
        case 'informativo':
          return {
            clase: 'informativa',
            icono: 'info-circle'
          };
        default:
          return {
            clase: 'advertencia',
            icono: 'exclamation-circle'
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alerta {
  border-radius: .75rem .125rem .75rem .75rem;
  color: $claro;
  cursor: pointer;
  font-weight: 400;
  max-width: 90%;
  padding: 1rem;
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 1051;

  &.advertencia {background-color: $advertencia;}
  &.error {background-color: $peligro;}
  &.exitosa {background-color: $exitoso;}
  &.informativa {background-color: $terciario;}

  p {
    margin-bottom: 0;
    user-select: none;
  }
}

// Estilos de transición
.alerta-transition-enter-active {animation-name: fadeInDown;}
.alerta-transition-leave-active {animation-name: fadeOutUp;}
.alerta-transition-enter-active,
.alerta-transition-leave-active {
  animation-duration: .5s;
}
</style>
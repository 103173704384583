<template>
  <div class="container-xl">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Aliados de envío</h2>
      <div
        v-if="!$apolloData.queries.aliados.loading"
        class="col-auto"
      >
        <mdb-btn
          color="primario"
          icon="plus"
          class="my-sm-0 px-3"
          @click="mostrarModalAliado = true"
        >
          Nuevo aliado
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.aliados.loading"
      clase-adicional="vc-75vh"
    />
    <ul
      v-else
      data-table="sticky"
      class="table mb-page mt-2"
    >
      <li class="encabezado row align-items-end">
        <div
          class="col col-md-3 pl-4 pl-lg-2 filtrable"
          @click="ordenarColumna('nombre')"
        >
          Nombre
          <div
            v-if="orderBy.nombre != null"
            class="sort-iconos animated fadeIn"
          >
            <font-awesome-icon
              icon="sort-up"
              :class="{'activo': orderBy.nombre}"
            />
            <font-awesome-icon
              icon="sort-down"
              :class="{'activo': !orderBy.nombre}"
            />
          </div>
        </div>
        <div class="col-4 d-none d-md-block">
          Estados / ciudades
        </div>
        <div class="col-4 col-md-3 d-none d-sm-block">Contacto</div>
        <div class="col-3 col-md-2">Acción</div>
      </li>
      <li
        v-for="aliado in aliados"
        :key="`aliado-${aliado.id}`"
        class="contenido normal row align-items-center"
      >
        <div class="col col-md-3">
          {{ aliado.nombre }}
          <!-- Estados / ciudades -->
          <div class="d-md-none pt-2">
            <span class="d-block">Estados / ciudades:</span>
            <span
              v-for="(estado, i) in aliado.estados"
              :key="`estado-${i}`"
            >{{ i > 0 ? ', ' : '' }}{{ estado }}</span>
          </div>
          <!-- Contacto -->
          <div class="d-sm-none pt-2">
            <span class="d-block">Contacto:</span>
            {{ aliado.contacto }}
            <a
              v-if="aliado.telefonos && aliado.telefonos.length"
              target="_blank"
              rel="noopener noreferrer"
              :href="`tel:+${aliado.telefonos[0]}`"
              class="d-block text-break"
              @click.stop
            >
              +{{ aliado.telefonos[0] }}
            </a>
            <div v-if="aliado.telefonos && aliado.telefonos.length">
              <a
                :href="`https://wa.me/${aliado.telefonos[0]}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                @click.stop
              >
                <font-awesome-icon :icon="['fab', 'whatsapp']" /> WA
              </a>
              <a
                :href="`sms:+${aliado.telefonos[0]}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                @click.stop
              >
                <font-awesome-icon icon="sms" /> Sms
              </a>
            </div>
          </div>
        </div>
        <div class="col-4 d-none d-md-block">
          <span
            v-for="(estado, i) in aliado.estados"
            :key="`estado-${i}`"
          >{{ i > 0 ? ', ' : '' }}{{ estado }}</span>
        </div>
        <div class="col-4 col-md-3 d-none d-sm-block">
          {{ aliado.contacto }}
          <a
            v-if="aliado.telefonos && aliado.telefonos.length"
            target="_blank"
            rel="noopener noreferrer"
            :href="`tel:+${aliado.telefonos[0]}`"
            class="d-block text-break"
            @click.stop
          >
            +{{ aliado.telefonos[0] }}
          </a>
          <div v-if="aliado.telefonos && aliado.telefonos.length">
            <a
              :href="`https://wa.me/${aliado.telefonos[0]}`"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
              @click.stop
            >
              <font-awesome-icon :icon="['fab', 'whatsapp']" /> WA
            </a>
            <a
              :href="`sms:+${aliado.telefonos[0]}`"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
              @click.stop
            >
              <font-awesome-icon icon="sms" /> Sms
            </a>
          </div>
        </div>
        <div class="col-3 col-md-2">
          <mdb-btn
            flat
            dark-waves
            icon="trash"
            :disabled="botonDeshabilitado"
            icon-class="texto-peligro"
            class="m-0 ml-2 ml-xl-0 py-3 px-2 btn-bordeadoOnHover"
            title="Eliminar aliado"
            @click="mostrarConfirmacionEliminar = true;
              aliadoIdEliminar = aliado.id;"
          />
          <mdb-btn
            flat
            dark-waves
            icon="pen"
            :disabled="botonDeshabilitado"
            class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
            title="Editar aliado"
            @click="asignarDatosEditar(aliado)"
          />
        </div>
      </li>
      <li v-if="!(aliados && aliados.length)" class="no-items">
        No hay aliados registrados
      </li>
    </ul>

    <FormularioAliados
      :mostrarModalAliado="mostrarModalAliado"
      :titulo="esEditar
        ? 'Datos del aliado a editar' : 'Datos del nuevo aliado'"
      :es-editar="esEditar"
      :aliadoAsignar="aliadoAsignar && aliadoAsignar"
      @cerrar="mostrarModalAliado = $event;
        botonDeshabilitado = false;"
      @crear="crearAliado($event)"
      @editar="esEditar = $event"
      @alertaAliado="alertaMensajeAsignar($event)"
      @editado="mostrarModalAliado = false;"
    />
    <AlertaMensaje :alerta-mensaje="alertaMensaje" @cerrar="alertaMensaje.contenido = ''" />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      mensaje="Recibimos la orden de eliminar este aliado, es una acción que no puede revertirse"
      @cerrar="
        mostrarConfirmacionEliminar = $event;
        aliadoIdEliminar = '';
      "
      @eliminar="eliminarAliado"
    />
  </div>
</template>

<script>
import { mdbBtn } from "mdbvue";
import gql from "graphql-tag";
import FormularioAliados from "@/components/FormularioAliados";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar";
import AlertaMensaje from "@/components/AlertaMensaje";
import CargandoVista from "@/components/CargandoVista";
import aliadoCrearGql from "@/graphql/aliadoCrear.gql";
import aliadoEliminarGql from "@/graphql/aliadoEliminar.gql";
import aliadosGql from "@/graphql/aliados.gql";
export default {
  name: "Aliados",
  components: {
    mdbBtn,
    FormularioAliados,
    ConfirmacionEliminar,
    CargandoVista,
    AlertaMensaje,
  },
  data() {
    return {
      mostrarModalAliado: false,
      mostrarConfirmacionEliminar: false,
      botonDeshabilitado: false,
      esEditar: false,
      aliadoAsignar: {
        nombre: "",
        estados: [],
        contacto: {
          nombre: "",
          telefono: "",
        },
      },
      aliadoIdEliminar: "",
      alertaMensaje: { contenido: "" },
      aliados: [],
      // Ordenar asc desc
      orderBy: {
        nombre: null,
      },
      orderByActual: [],
    };
  },
  methods: {
    ordenarColumna(columna){
      if(columna == 'nombre'){
        this.orderBy.nombre === null
          ? this.orderBy.nombre = false  // se ordena descendente
          : this.orderBy.nombre
            ? this.orderBy.nombre = false   
            : this.orderBy.nombre = true  // se ordena ascendente
        if(this.orderBy.nombre) {
          this.orderByActual = ['nombre_asc']
          return this.actualizarAliados()
        } else {
          this.orderByActual = ['nombre_desc']
          return this.actualizarAliados()
        }
      }
    },
    actualizarAliados() {
      const filter = {};
      const variablesQuery = {
        filter,
      }
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery,{ 
            orderBy: this.orderByActual
          })
        : ''
      this.botonDeshabilitado = true;
      this.$apollo
        .watchQuery({
          query: aliadosGql,
          update: (data) => data.Aliado,
          fetchPolicy: "cache-and-network",
          variables: variablesQuery
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Aliado) {
              this.aliados = data.Aliado;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje = {
              contenido: "Ha ocurrido un error actualizando los aliados",
              tipo: "error",
            };
            this.botonDeshabilitado = false;
          },
        });
    },
    asignarDatosEditar(datosAliado) {
      this.botonDeshabilitado = true;
      this.aliadoAsignar = JSON.parse(JSON.stringify(datosAliado));
      this.esEditar = true;
      this.mostrarModalAliado = true;
    },
    crearAliado(aliadoData) {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${aliadoCrearGql}
          `,
          variables: {
            aliado: aliadoData,
          },
          update: (cache, { data: { aliadoCrear } }) => {
            const data = cache.readQuery({ query: aliadosGql });
            const aliadoAgregar = {
              ...aliadoData,
              ...aliadoCrear,
            };
            aliadoAgregar.__typename = "Aliado";
            const Aliado = [...data.Aliado, aliadoAgregar];
            cache.writeQuery({ query: aliadosGql, data: { Aliado } });
          },
          optimisticResponse: {
            __typename: "Mutation",
            aliadoCrear: {
              __typename: "Aliado",
              id: -1,
              nombre: aliadoData.nombre,
              contacto: aliadoData.contacto,
              telefonos: aliadoData.telefonos.map((telefono) => telefono),
              estados: aliadoData.estados.map((estado) => estado),
            },
          },
        })
        .then(() => {
          this.alertaMensaje = {
            contenido: "Aliado agregado correctamente",
            tipo: "correcto",
          };
          this.botonDeshabilitado = false;
          this.mostrarModalAliado = false;
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.mostrarModalAliado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error agregando el aliado",
            tipo: "error",
          };
        });
    },
    eliminarAliado() {
      this.botonDeshabilitado = true;
      const aliadoId = JSON.parse(JSON.stringify(this.aliadoIdEliminar));
      this.$apollo
        .mutate({
          mutation: gql`
            ${aliadoEliminarGql}
          `,
          variables: {
            aliadoId,
          },
          update: (
            cache,
            {
              data: {
                aliadoEliminar: { codigo },
              },
            }
          ) => {
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: "Aliado eliminado correctamente",
                  tipo: "correcto",
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                  contenido: "No se pudo eliminar el aliado",
                  tipo: "error",
                };
                this.botonDeshabilitado = false;
                return;
              default:
                this.alertaMensaje = {
                  contenido: "Ocurrió un error inesperado",
                  tipo: "error",
                };
                this.botonDeshabilitado = false;
                return;
            }
            const { Aliado } = cache.readQuery({ query: aliadosGql });
            const data = { Aliado: [] };
            data.Aliado = Aliado.filter(({ id }) => id !== aliadoId);
            cache.writeQuery({
              query: gql`
                ${aliadosGql}
              `,
              data,
            });
            !this.botonDeshabilitado && (this.mostrarConfirmacionEliminar = false);
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            aliadoEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.mostrarModalAliado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error eliminando el aliado",
            tipo: "error",
          };
        });
    },
    alertaMensajeAsignar({ contenido, tipo }) {
      this.alertaMensaje = {
        contenido,
        tipo,
      };
    },
  },
  apollo: {
    aliados() {
      return {
        query: gql`
          ${aliadosGql}
        `,
        update: (data) => data.Aliado,
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
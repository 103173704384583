<template>
  <!-- Modal confirmar permiso -->
  <mdb-modal
    :show="mostrarModal"
    centered
    elegant
    scrollable
    size="sm"
    @close="limpiarCampos(); $emit('cerrar', false);"
    @shown="$refs.campoClave.focus()"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Se necesita confirmación
      </mdb-modal-title>
    </mdb-modal-header>
    <form @submit.prevent="validarClave">
    <mdb-modal-body>
      <div
        :class="[{'campo': validarValores.clave
            && validarValores.clave.length},
          {'valido': validarValores.clave == 'valido'},
          {'invalido': validarValores.clave == 'invalido'},
        ]"
      >
        <mdb-input
          ref="campoClave"
          v-model.trim="clave"
          :type="mostrarCamposClave.clave ? 'text' : 'password'"
          minlength="8"
          label="Contraseña"
          class="my-2"
          outline
        >
          <mdb-btn
            slot="append"
            group
            flat
            dark-waves
            size="md"
            :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
            :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
            @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
          />
        </mdb-input>
        <p
          v-if="validarValores.clave == 'invalido'"
          class="mensaje-invalido"
        >
          Indica la clave
        </p>
      </div>
      <div class="text-center">
        <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'sync'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="validarClave"
        >
          Confirmar
        </mdb-btn>
      </div>
    </mdb-modal-body>
    </form>
  </mdb-modal>
</template>

<script>
import usuarioConfirmarClaveGql from "@/graphql/usuarioConfirmarClave.gql";
import { leerPersonaId } from "@/utils/datosToken.js"
import {
  mdbBtn,
  mdbModal,
  mdbInput,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: 'ModalConfirmarPermiso',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.usuarioId = leerPersonaId()
  },
  data() {
    return {
      botonDeshabilitado: false,
      validarValores: {},
      clave: '',
      usuarioId: '',
      mostrarCamposClave: {
        clave: ''
      }
    };
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
      }
    },
  },
  methods: {
    validarClave(){
      this.botonDeshabilitado = true;
      if(this.clave && this.clave.length < 8){
        this.botonDeshabilitado = false;
        return this.alertaMensaje('La clave debe contener al menos 8 caracteres',
          'advertencia')
      }
      this.validarValores = {
        clave: this.clave.trim() && this.clave.length >= 8 ? "valido" : "invalido",
      };
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Todos los campos son requeridos', 'error');
        this.botonDeshabilitado = false;
        return;
      }
      this.usuarioCambiarClave();
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    usuarioCambiarClave() {
      this.botonDeshabilitado = true;
      this.$apollo
          .mutate({
            mutation: usuarioConfirmarClaveGql,
            variables: {
              usuarioId: this.usuarioId,
              clave: this.clave
            }
          })
          .then(({data: {usuarioConfirmarClave}}) => {
            const msg = usuarioConfirmarClave.codigo;
            switch (msg) {
              case "Correcto":
                this.alertaMensaje('Se ha verificado la contraseña con éxito.', 'correcto');
                this.limpiarCampos();
                this.botonDeshabilitado = false;
                this.$emit('confirmado', true)
                this.$emit('cerrar', false);
                return;
              case "Fallido":
                this.alertaMensaje('Contraseña incorrecta.'
                    +' Por favor verifica tus datos e intenta de nuevo', 'advertencia');
                this.botonDeshabilitado = false;
                break;
              default:
               this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error');
                this.botonDeshabilitado = false;
                break;
            }
            this.validarValores = {
              clave: "invalido"
            };
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.validarValores = {};
      this.clave = '';
    },
  },
}

</script>

 <style lang="scss" scoped>
.campo.invalido {
  margin-bottom: 1rem;
  & > .mensaje-invalido {top: 40px;}
}
 </style>
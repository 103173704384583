<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
      @close="$emit('cerrar', false);
        limpiarCamposNuevoPaquete();"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          {{ mostrarModalNuevoCliente ? "Nuevo cliente" : titulo }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{'pt-0': esReempaque && masReempaque}">
        <transition
          mode="out-in"
          name="custom-classes-transition"
          :enter-active-class="transicionNuevoCliente.entrada"
          :leave-active-class="transicionNuevoCliente.salida"
        >
          <div v-if="!mostrarModalNuevoCliente">
            <div class="row">
              <div class="col-12 col-lg-6">
                <header class="font-weight-bold user-select-none">
                  {{ esEditar || personaIdReempacar ? 'Fecha' : 'Cliente' }}
                  <small
                    v-if="!esEditar && !personaIdReempacar"
                    class="text-muted"
                  >
                    (opcional)
                  </small>
                </header>
                <div class="row align-items-center justify-content-center mt-1 mt-lg-0">
                  <div
                    v-if="!esEditar && !personaIdReempacar"
                    class="col-12 my-2"
                  >
                    <ClienteBuscador
                      id="clienteBuscador-1"
                      :limpiarCliente="limpiarCliente"
                      @cliente="asignarCliente($event)"
                      @limpiar="limpiarCliente = $event"
                    />
                  </div>
                  <div v-if="!esEditar && !personaIdReempacar" class="col-auto col-sm-6 pr-lg-0 text-center">
                    <mdb-btn
                      v-if="esPermitido || esAgencia"
                      flat
                      dark-waves
                      class="btn-bordeado py-2 px-3"
                      @click="mostrarModalNuevoCliente = true"
                    >
                      ¿Es nuevo cliente?
                    </mdb-btn>
                  </div>
                  <div
                    :class="['col-12', {'col-sm-6': !esEditar && !personaIdReempacar}, 'pl-lg-2',
                      {campo: validarValores.fecha
                        && validarValores.fecha.length},
                      { valido: validarValores.fecha == 'valido' },
                      { invalido: validarValores.fecha == 'invalido' }]"
                  >
                    <mdb-input
                      v-model="nuevoPaquete.fecha.formatted"
                      type="date"
                      label="Fecha"
                      class="my-2"
                      outline
                    />
                    <p
                      v-if="validarValores.fecha == 'invalido'"
                      class="mensaje-invalido date"
                    >
                      Selecciona una fecha
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <header class="font-weight-bold user-select-none mt-2 mt-lg-0">
                  Medidas
                </header>
                <div class="row align-items-center justify-content-center">
                  <div
                    :class="['col-12 col-sm-6',
                      {'cursor-not-allowed': esAgencia && esEditar},
                      {campo: validarValores.largo
                        && validarValores.largo.length},
                      { valido: validarValores.largo == 'valido' },
                      { invalido: validarValores.largo == 'invalido' }]"
                    :title="esAgencia && esEditar ? 'No se pueden editar las medidas' : ''"
                  >
                    <mdb-input
                      v-model.number="nuevoPaquete.largo"
                      type="number"
                      :min="0"
                      label="Largo"
                      class="my-2"
                      outline
                      :read-only="esAgencia && esEditar"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Pulgadas"
                      >
                        in
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.largo == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el largo del paquete
                    </p>
                  </div>
                  <div
                    :class="['col-12 col-sm-6',
                      {'cursor-not-allowed': esAgencia && esEditar},
                      {campo: validarValores.ancho
                        && validarValores.ancho.length},
                      { valido: validarValores.ancho == 'valido' },
                      { invalido: validarValores.ancho == 'invalido' }]"
                    :title="esAgencia && esEditar ? 'No se pueden editar las medidas' : ''"
                  >
                    <mdb-input
                      v-model.number="nuevoPaquete.ancho"
                      type="number"
                      :min="0"
                      label="Ancho"
                      class="my-2"
                      outline
                      :read-only="esAgencia && esEditar"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Pulgadas"
                      >
                        in
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.ancho == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el ancho del paquete
                    </p>
                  </div>
                  <div
                    :class="['col-12 col-sm-6',
                      {'cursor-not-allowed': esAgencia && esEditar},
                      {campo: validarValores.alto
                        && validarValores.alto.length},
                      { valido: validarValores.alto == 'valido' },
                      { invalido: validarValores.alto == 'invalido' }]"
                    :title="esAgencia && esEditar ? 'No se pueden editar las medidas' : ''"
                  >
                    <mdb-input
                      v-model.number="nuevoPaquete.alto"
                      type="number"
                      :min="0"
                      label="Alto"
                      class="my-2"
                      outline
                      :read-only="esAgencia && esEditar"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Pulgadas"
                      >
                        in
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.alto == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el alto del paquete
                    </p>
                  </div>
                  <div
                    :class="['col-12 col-sm-6',
                      {'cursor-not-allowed': esAgencia && esEditar},
                      {campo: validarValores.peso
                        && validarValores.peso.length},
                      { valido: validarValores.peso == 'valido' },
                      { invalido: validarValores.peso == 'invalido' }]"
                    :title="esAgencia && esEditar ? 'No se pueden editar las medidas' : ''"
                  >
                    <mdb-input
                      v-model.number="nuevoPaquete.peso"
                      type="number"
                      :min="0"
                      label="Peso"
                      class="my-2"
                      outline
                      :read-only="esAgencia && esEditar"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Libras"
                      >
                        lb
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.peso == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el peso del paquete
                    </p>
                  </div>
                </div>
              </div>
              <div
                :class="['col-12', esReempaque ? 'col-lg-6' : 'col-lg-5',
                  {'mt-lg-n4': parametrosOrdenPaquete && parametrosOrdenPaquete.envio}]"
              >
                <header class="font-weight-bold user-select-none mt-2">
                  Paquete
                </header>
                <!-- Tipo de envío -->
                <p v-if="parametrosOrdenPaquete && parametrosOrdenPaquete.envio">
                  Tipo de envío: {{ ConsolidadoTipoEnvio[nuevoPaquete.envio] }}
                </p>
                <transition
                  name="cta-transition"
                  enter-active-class="animated zoomIn"
                  leave-active-class="animated zoomOut"
                >
                  <blockquote
                    v-if="esPermitido && nuevoPaquete.estatus == 'Devuelto'"
                    id="mensaje-devolucion"
                    class="blockquote bq-terciario mr-n1 pl-2 pr-0 d-none d-lg-block"
                  >
                    <p class="bq-title pt-0 pb-2">¿Por qué se devolvió el paquete?</p>
                    <div>
                      <mdb-btn
                        flat
                        dark-waves
                        class="btn-bordeado my-1 my-sm-0 ml-0 mr-sm-1 p-2"
                        @click="agregarObs('Artículo errado')"
                      >
                        Artículo errado
                      </mdb-btn>
                      <mdb-btn
                        flat
                        dark-waves
                        class="btn-bordeado my-1 p-2"
                        @click="agregarObs('Artículo dañado')"
                      >
                        Dañado
                      </mdb-btn>
                      <mdb-btn
                        flat
                        dark-waves
                        class="btn-bordeado my-1 my-sm-0 mx-0 ml-sm-1 p-2"
                        @click="agregarObs('Artículo devuelto por: ')"
                      >
                        Otro
                      </mdb-btn>
                    </div>
                  </blockquote>
                </transition>
                <mdb-input
                  v-if="esPermitido"
                  v-model.trim="nuevoPaquete.observacion"
                  type="textarea"
                  label="Notas adicionales"
                  class="mt-3 mb-2"
                  :rows="5"
                  outline
                />
              </div>
              <div :class="['col-12', esReempaque ? 'col-lg-6' : 'col-lg-7', {'col-lg-12': esAgencia && esEditar}]">
                <div class="row justify-content-center">
                  <div
                    v-if="!esReempaque && (esPermitido || esAgencia)"
                    class="col-auto pl-lg-2 pr-lg-0 my-2 text-center"
                  >
                    <mdb-btn
                      v-if="!esEditar"
                      flat
                      dark-waves
                      icon="camera"
                      class="btn-bordeado my-0 py-2 px-3"
                      @click="mostrarModalWebCam=!mostrarModalWebCam"
                    >
                      Usar cámara
                    </mdb-btn>
                    <mdb-btn
                      flat
                      dark-waves
                      icon="plus"
                      class="btn-bordeado my-0 py-2 px-3"
                      @click="esEditar
                        ? (mostrarModalFotos = !mostrarModalFotos)
                        : (mostrarModalFile = !mostrarModalFile)"
                    >
                      Fotos
                    </mdb-btn>
                  </div>
                  <div v-if="mostrarModalFile" class="col-12 col-sm my-2">
                    <div class="outline-input-file" data-size="sm">
                      <label for="input-file-images" class="label-file">
                        <font-awesome-icon icon="upload" class="mr-1" />
                        Elegir fotos
                      </label>
                      <span class="contenido">
                        {{
                          fotos && fotos.length
                          ? fotos.length > 1
                            ?  fotos.length + ' archivos'
                            : fotos.length + ' archivo'
                          : 'No se eligió archivo'
                        }}
                      </span>
                      <input
                        id="input-file-images"
                        type="file"
                        multiple
                        accept="image/*"
                        class="custom-file-input"
                        @change="handleInputChange"
                      />
                      <mdb-btn
                        v-if="fotos.length > 0"
                        flat
                        dark-waves
                        icon="times"
                        class="file-button m-0 p-2"
                        title="Eliminar fotos seleccionadas"
                        @click="vaciarFotos"
                      />
                    </div>
                  </div>
                  <div
                    v-if="size"
                    class="col-12 text-center text-muted"
                  >
                    Archivos adjuntados: {{ size.toFixed(2) }} / {{ maxSize }}Mb
                  </div>
                  <div v-if="(esIndividual || !esEditar) && esPermitido" class="w-100"></div>
                  <div
                    v-if="(esIndividual || !esEditar) && esPermitido"
                    :class="['col-12 col-sm-6',
                      {'col-lg-4': esAgencia && esEditar},
                      {campo: validarValores.tracking
                        && validarValores.tracking.length},
                      { valido: validarValores.tracking == 'valido' },
                      { invalido: validarValores.tracking == 'invalido' }]"
                  >
                    <mdb-input
                      v-model.trim="nuevoPaquete.tracking"
                      type="text"
                      label="Tracking"
                      class="my-2"
                      outline
                    />
                    <p
                      v-if="validarValores.tracking == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el número de tracking
                    </p>
                  </div>
                  <div
                    v-if="(esIndividual || !esEditar) && esPermitido"
                    :class="['col-12',
                      esIndividual || !esEditar ? 'col-sm-6' : 'col-sm',
                      {'col-lg-4': esAgencia && esEditar},
                      {campo: validarValores.empaque
                        && validarValores.empaque.length},
                      { valido: validarValores.empaque == 'valido' },
                      { invalido: validarValores.empaque == 'invalido' }]"
                  >
                    <div class="md-form md-outline outline-select my-2">
                      <select
                        id="empaque-select"
                        v-model="nuevoPaquete.empaque"
                        class="custom-select"
                      >
                        <option class="d-none" disabled value="">
                          Seleccione
                        </option>
                        <option
                          v-for="value in PaqueteTipoEmpaque"
                          :key="value"
                          :value="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <label
                        for="empaque-select"
                        :class="nuevoPaquete.empaque
                          && nuevoPaquete.empaque.length
                            ? 'label-active'
                            : 'label-inactive'"
                      >
                        Empaque
                      </label>
                    </div>
                    <p
                      v-if="validarValores.empaque == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Selecciona un empaque
                    </p>
                  </div>
                  <div
                    v-if="(esIndividual || !esEditar) && esPermitido"
                    :class="['col-12',
                      {'col-lg-4': esAgencia && esEditar},
                      {campo: validarValores.receptor
                        && validarValores.receptor.length},
                      { valido: validarValores.receptor == 'valido' },
                      { invalido: validarValores.receptor == 'invalido' }]"
                  >
                    <mdb-input
                      v-model.trim="nuevoPaquete.receptor"
                      type="text"
                      label="Recibe"
                      class="my-2"
                      outline
                    />
                    <p
                      v-if="validarValores.receptor == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el nombre y apellido del receptor
                    </p>
                  </div>
                  <div class="col-12">
                    <div v-if="esPermitido" class="custom-control custom-switch py-2 mt-1">
                      <input
                        id="check-defectuoso"
                        v-model="nuevoPaquete.defectuoso"
                        type="checkbox"
                        name="checkbox-defectuoso"
                        class="custom-control-input"
                      >
                      <label class="custom-control-label" for="check-defectuoso">
                        {{ nuevoPaquete.defectuoso ? 'Si' : 'No' }} se encuentra defectuoso
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="esPermitido || esAgencia" class="custom-control custom-switch py-2">
                      <input
                        id="check-asegurado"
                        v-model="nuevoPaquete.asegurado"
                        type="checkbox"
                        name="checkbox-asegurado"
                        class="custom-control-input"
                      >
                      <label class="custom-control-label" for="check-asegurado">
                        {{ nuevoPaquete.asegurado ? 'Si' : 'No' }} se encuentra asegurado
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="esPermitido && !esEditar && !esReempaque && Object.keys(cliente).length" class="custom-control custom-switch py-2">
                      <input
                        id="check-correo"
                        v-model="enviarCorreo"
                        type="checkbox"
                        name="checkbox-correo"
                        class="custom-control-input"
                      >
                      <label class="custom-control-label" for="check-correo">
                        {{ enviarCorreo ? 'Si' : 'No' }} se envía un correo
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="esEditar && (esPermitido || esAgencia)"
                    class="col-12"
                  >
                    <div class="custom-control custom-switch py-2">
                      <input
                        id="check-pagado"
                        v-model="nuevoPaquete.pagado"
                        type="checkbox"
                        name="checkbox-pagado"
                        class="custom-control-input"
                      >
                      <label class="custom-control-label" for="check-pagado">
                        {{ nuevoPaquete.pagado ? 'Si' : 'No' }} se encuentra pagado
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="esReempaque" class="col-12">
                <div class="custom-control custom-switch py-2">
                  <input
                    id="check-masReempaque"
                    v-model="masReempaque"
                    type="checkbox"
                    name="checkbox-masReempaque"
                    class="custom-control-input"
                  >
                  <label class="custom-control-label" for="check-masReempaque">
                    {{ masReempaque ? 'Si,' : 'No' }}
                    quedarán paquetes para volver a reempacar
                  </label>
                </div>
                <!-- Listado de paquetes -->
                <section v-if="masReempaque" class="animated fadeIn">
                  <header class="font-weight-bold">Seleccionar paquetes para futuros reempaques</header>
                  <ul class="table" data-table="sticky">
                    <li class="encabezado row">
                      <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
                      <div class="col-4 col-md-3 d-none d-lg-block">ID</div>
                      <div class="col">Detalles</div>
                    </li>
                    <li
                      v-for="paquete in paquetes"
                      :key="`paquete-${paquete.id}`"
                      class="contenido normal row align-items-center"
                    >
                    <!-- Fecha recibido -->
                      <div class="col-3 col-sm-2 col-lg-1 px-0">
                        <label
                          class="opciones-checkbox py-3"
                          title="Seleccionar paquete"
                          @click.stop
                        >
                          <input
                            v-model="paquetesListado"
                            type="checkbox"
                            name="selecionar-paquete-checkbox"
                            :value="paquete.id"
                          />
                          <span class="icono-checkbox" />
                          {{ formatearFecha(paquete.fecha.formatted).ddmm }}
                        </label>
                      </div>
                      <!-- ID -->
                      <div class="col-4 col-md-3 d-none d-lg-block">
                        Paquete ID:
                        <span :class="paquete.numeroHR ? '' : 'text-muted user-select-none'">
                          {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
                        </span>
                      </div>
                      <!-- Detalles -->
                      <div class="col">
                        <!-- Columna ID -->
                        <div class="d-lg-none mb-1">
                          Paquete ID:
                          <span :class="paquete.numeroHR ? '' : 'text-muted user-select-none'">
                            {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
                          </span>
                        </div>
                        Tracking:
                        <span :class="{'text-muted font-italic': !paquete.tracking}">
                          {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
                        </span>
                        <p class="mb-0">
                          <span title="Peso por volumen">
                            vlbs: {{
                              mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                            }}
                            |
                          </span>
                          <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span>
                          <span title="Pies cúbicos">
                            ft<sup>3</sup>: {{
                              mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                            }}
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </section>
                </div>
              <div v-if="esEditar" class="col-12">
                <div class="row mt-2">
                  <div
                    :class="['col-12 col-lg-5',
                      {campo: validarValores.estatus
                        && validarValores.estatus.length},
                      { valido: validarValores.estatus == 'valido' },
                      { invalido: validarValores.estatus == 'invalido' }]"
                  >
                    <div v-if="esPermitido || esAgencia" class="md-form md-outline outline-select my-2">
                      <select
                        v-if="estatusAnterior !== 'Devuelto'"
                        id="estatus-select"
                        v-model="nuevoPaquete.estatus"
                        class="custom-select"
                      >
                        <option class="d-none" disabled value="">
                          Seleccione
                        </option>
                        <option
                          v-for="(value, key) in PaqueteEstatus"
                          :key="key"
                          :value="key"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <span v-else>Estatus: {{nuevoPaquete.estatus}}</span>
                      <label
                        for="estatus-select"
                        :class="nuevoPaquete.estatus
                          && nuevoPaquete.estatus.length
                            ? 'label-active'
                            : 'label-inactive'"
                      >
                        Estatus
                      </label>
                    </div>
                    <p
                      v-if="validarValores.estatus == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Selecciona el estatus del paquete
                    </p>
                  </div>
                  <transition
                    name="cta-transition"
                    enter-active-class="animated zoomIn"
                    leave-active-class="animated zoomOut"
                  >
                    <div
                      v-if="esPermitido && nuevoPaquete.estatus == 'Devuelto'"
                      class="col-12 my-2 d-lg-none"
                    >
                      <blockquote class="blockquote bq-terciario">
                        <p class="bq-title pt-0 pb-2">¿Por qué se devolvió el paquete?</p>
                        <div>
                          <mdb-btn
                            flat
                            dark-waves
                            class="btn-bordeado my-1 my-sm-0 ml-0 mr-sm-1 p-2"
                            @click="agregarObs('Artículo errado')"
                          >
                            Artículo errado
                          </mdb-btn>
                          <mdb-btn
                            flat
                            dark-waves
                            class="btn-bordeado my-1 p-2"
                            @click="agregarObs('Artículo dañado')"
                          >
                            Dañado
                          </mdb-btn>
                          <mdb-btn
                            flat
                            dark-waves
                            class="btn-bordeado my-1 my-sm-0 mx-0 ml-sm-1 p-2"
                            @click="agregarObs('Artículo devuelto por: ')"
                          >
                            Otro
                          </mdb-btn>
                        </div>
                      </blockquote>
                    </div>
                  </transition>
                  <div
                    v-if="esPermitido"
                    :class="['col-12 col-md-6 col-lg-3',
                      {campo: validarValores.tipo
                        && validarValores.tipo.length},
                      { valido: validarValores.tipo == 'valido' },
                      { invalido: validarValores.tipo == 'invalido' }]"
                  >
                    <div class="md-form md-outline outline-select my-2">
                      <select
                        id="tipo-select"
                        v-model="nuevoPaquete.tipo"
                        class="custom-select"
                      >
                        <option class="d-none" disabled value="">
                          Seleccione
                        </option>
                        <option
                          v-for="value in PaqueteTipos"
                          :key="value"
                          :value="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                      <label
                        for="tipo-select"
                        :class="nuevoPaquete.tipo
                          && nuevoPaquete.tipo.length
                            ? 'label-active'
                            : 'label-inactive'"
                      >
                        Tipo
                      </label>
                    </div>
                    <p
                      v-if="validarValores.tipo == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el tipo del paquete
                    </p>
                  </div>
                  <div
                    v-if="esPermitido"
                    :class="['col-12 col-md-6 col-lg-4',
                      {campo: validarValores.fechaArribo
                        && validarValores.fechaArribo.length},
                      { valido: validarValores.fechaArribo == 'valido' },
                      { invalido: validarValores.fechaArribo == 'invalido' }]"
                  >
                    <mdb-input
                      v-model="nuevoPaquete.fechaArribo.formatted"
                      type="date"
                      label="Fecha de arribo"
                      class="my-2"
                      outline
                    />
                    <p
                      v-if="validarValores.fechaArribo == 'invalido'"
                      class="mensaje-invalido date"
                    >
                      Selecciona una fecha de arribo
                    </p>
                  </div>
                  <div
                    v-if="esPermitido || esAgencia"
                    :class="['col-12 col-md-6 col-lg-4',
                      {campo: validarValores.valorDeclarado
                        && validarValores.valorDeclarado.length},
                      { valido: validarValores.valorDeclarado == 'valido' },
                      { invalido: validarValores.valorDeclarado == 'invalido' }]"
                  >
                    <mdb-input
                      v-model="nuevoPaquete.valorDeclarado"
                      @input="actualizarCosto"
                      type="number"
                      :min="0"
                      label="Valor declarado"
                      class="my-2"
                      outline
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Dólares"
                      >
                        $
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.valorDeclarado == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el valor declarado del paquete
                    </p>
                  </div>
                  <div
                    :class="['col-12 col-sm-6',
                      esAgencia ? 'col-lg-3' : 'col-lg-4',
                      {campo: validarValores.valorDeclarado
                        && validarValores.valorDeclarado.length},
                      { valido: validarValores.valorDeclarado == 'valido' },
                      { invalido: validarValores.valorDeclarado == 'invalido' }]"
                  >
                    <mdb-input
                      v-model="nuevoPaquete.costo"
                      @input="actualizarCosto"
                      type="number"
                      label="Costos adicionales"
                      class="my-2"
                      outline
                      placeholder="Ej: +10 o -5"
                      :read-only="!(esPermitido || esAgencia)"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Dólares"
                      >
                        $
                      </span>
                    </mdb-input>
                    <p
                      v-if="validarValores.valorDeclarado == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Indica el costo adicional del paquete
                    </p>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4">
                    <mdb-input
                      v-model.trim="costo.seguro"
                      type="text"
                      label="Seguro"
                      class="my-2"
                      outline
                      read-only
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Dólares"
                      >
                        $
                      </span>
                    </mdb-input>
                  </div>
                  <div class="col-12 col-md-6 col-lg">
                    <mdb-input
                      v-model.trim="costo.total"
                      type="text"
                      label="Costo total"
                      class="my-2"
                      outline
                      read-only
                      :small="costo.mensaje ? `Tip: ${costo.mensaje}` : ''"
                    >
                      <span
                        class="input-group-text md-addon user-select-none"
                        slot="append"
                        title="Dólares"
                      >
                        $
                      </span>
                    </mdb-input>
                  </div>
                  <p class="col-12 col-md-4 mb-2">
                    <small class="text-muted user-select-none d-block">
                      Envío
                    </small>
                    <font-awesome-icon
                      v-if="nuevoPaquete.envio"
                      :icon="ConsolidadoTipoEnvioIconos[nuevoPaquete.envio]"
                      class="texto-terciario"
                    />
                    <span :class="nuevoPaquete.envio ? '' : 'text-muted user-select-none'">
                      {{ nuevoPaquete.envio ? ConsolidadoTipoEnvio[nuevoPaquete.envio] : 'Sin especificar' }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="esPermitido" class="col-12 col-lg-6 mt-2">
                <header class="font-weight-bold user-select-none">
                  Agencia
                </header>
                <div class="md-form md-outline outline-select my-2">
                  <select
                    id="agencia-select"
                    v-model="nuevoPaquete.agenciaId"
                    class="custom-select"
                  >
                    <option class="d-none" disabled value="">
                      Seleccione
                    </option>
                    <option
                      v-for="(value, key) in agencias"
                      :key="key+'agencia'"
                      :value="value.id"
                    >
                    {{ value.nombre }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                v-if="esPermitido"
                class="col-12 col-lg-6 mt-2"
                :class="['col-12 col-lg-6 mt-2',
                  {campo: validarValores.almacen
                    && validarValores.almacen.length},
                  { valido: validarValores.almacen == 'valido' },
                  { invalido: validarValores.almacen == 'invalido' }]"
              >
                <header class="font-weight-bold user-select-none">
                  Almacén
                </header>
                <div class="md-form md-outline outline-select my-2">
                  <select v-model="almacenId" id="almacen-select" class="custom-select">
                    <option value="">Seleccione</option>
                    <option
                      v-for="almacen in almacenes"
                      :key="almacen.id"
                      :value="almacen.id">{{almacen.nombre}}</option>
                    <!-- <option value="hrbintel">Global Trackit Miami</option> -->
                  </select>
                </div>
                <p
                  v-if="validarValores.almacen == 'invalido'"
                  class="mensaje-invalido mt-4"
                >
                  Seleccione el almacén del paquete
                </p>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <mdb-btn
                color="primario"
                :icon="botonDeshabilitado ? 'circle-notch' : iconoBotonPrincipal"
                :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                class="px-3"
                :disabled="botonDeshabilitado"
                @click="validarNuevoPaquete"
              >
                {{ esEditar ? 'Actualizar' : 'Agregar' }}
              </mdb-btn>
            </div>
          </div>
          <NuevoCliente
            v-else
            :mostrarFormulario="mostrarModalNuevoCliente"
            @cerrar="mostrarModalNuevoCliente = false"
            @creado="cliente = $event"
            @alertaMensaje="alertaMensaje('', '', $event)"
          />
        </transition>
      </mdb-modal-body>
    </mdb-modal>
    <ModalSubirArchivo
      :mostrar-modal="mostrarModalFotos"
      :grafoId="paqueteId"
      :soloImagenes="true"
      entidadFoto="Paquete"
      titulo="Agregar fotos al paquete a editar"
      archivo="fotos"
      @cerrar="mostrarModalFotos = false"
      @alerta="alertaMensaje('', '', $event)"
    />
    <ModalEditarImagen
      titulo="Editar imagen del paquete"
      :mostrar-modal="mostrarModalEditarFoto"
      :imagen="imagenSeleccionada"
      :imgSrc="imgSrc"
      @alerta="alertaMensaje('', '', $event)"
      @imagenModificada="imagenAgregar($event)"
      @cerrar="mostrarModalEditarFoto = $event; imagenSeleccionada=null; imgSrc=''"
    />
    <ModalWebCam
      :mostrarModal="mostrarModalWebCam"
      titulo="Tomar fotos usando cámara"
      @alerta="alertaMensaje('', '', $event)"
      @capturar="imagenSeleccionada = $event; mostrarModalConfirmarEditarImagen = true;"
      @cerrar="mostrarModalWebCam = $event"
    />
    <ConfirmacionEliminar 
      :mostrar-modal="mostrarModalConfirmarEditarImagen"
      :cancelarMensaje="true"
      mensaje="¿Quieres editar la imagen seleccionada?"
      @cerrar="mostrarModalConfirmarEditarImagen = $event;imagenAgregar(imagenSeleccionada)"
      @eliminar="leerImagenEditar(imagenSeleccionada);
        mostrarModalConfirmarEditarImagen=false"
    />
  </section>
</template>

<script>
import ClienteBuscador from "../components/ClienteBuscador.vue";
import {
  PaqueteEstatus,
  PaqueteTipoEmpaque,
  PaqueteTipos,
} from "@/constantes/paquetes.js";
import NuevoCliente from "../components/NuevoCliente.vue";
import ModalSubirArchivo from "../components/ModalSubirArchivo.vue";
import ModalEditarImagen from "../components/ModalEditarImagen.vue";
import ModalWebCam from "../components/ModalWebCam.vue";
import ConfirmacionEliminar from "../components/ConfirmacionEliminar.vue";
import gql from "graphql-tag";
import paqueteCrearGql from "@/graphql/paqueteCrear.gql";
import paquetesGql from "@/graphql/paquetes.gql";
import paqueteGql from "@/graphql/paquete.gql";
import paqueteEditarGql from "@/graphql/paqueteEditar.gql";
import paqueteReempacarGql from "@/graphql/paqueteReempacar.gql";
import clienteGql from "@/graphql/cliente.gql";
import { regExpFoto } from "@/constantes/paquetes.js";
import agenciasGql from "@/graphql/agencias.gql";
import almacenesGql from "@/graphql/almacenes.gql";
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from "@/constantes/consolidados.js"
import {
  multipleFiltrado, 
  obtenerMarcaTemporal,
  mostrarSoloDosDecimales,
  formatearFecha,
  esRetrocesoEstatusPaquete
} from "@/funciones/funciones.js"
import paquetesSinClienteGql from "@/graphql/paquetesSinCliente.gql";
import { leerPersonaNombre } from "@/utils/datosToken.js"
import { leerRoles } from "@/utils/datosToken.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";

export default {
  name: "FormularioPaquete",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    NuevoCliente,
    ClienteBuscador,
    ModalSubirArchivo,
    ModalEditarImagen,
    ConfirmacionEliminar,
    ModalWebCam
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esReempaque: {
      type: Boolean,
      required: false,
      default: false,
    },
    estatusAnterior: {
      type: String,
      required: false,
      default: function () {
        return '';
      },
    },
    parametrosOrdenPaquete: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // Permite saber si se está operando en la view de cliente
    esCliente: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Permite saber si se está operando en la view de resumen
    esResumen: {
      type: Boolean,
      required: false,
      default: false,
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    esIndividual: {
      type: Boolean,
      required: false,
      default: false,
    },
    paquetesId: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    personaIdReempacar: {
      type: String,
      required: false,
      default: function () {
        return '';
      },
    },
    paquetes: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    datosPaquete: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    variablesUpdateQueryViewPaquetes: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    titulo: {
      type: String,
      required: false,
      default: "Datos del paquete",
    },
  },
  data() {
    return {
      // Funcionalidad reempacar luego
      paquetesListado: [],
      // Otros
      roles:leerRoles(),
      mostrarSoloDosDecimales,
      formatearFecha,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      mostrarModalNuevoCliente: false,
      botonDeshabilitado: false,
      mostrarModalFotos: false,
      mostrarModalFile: false,
      mostrarModalWebCam: false,
      mostrarModalConfirmarEditarImagen:false,
      fotos: [],
      paqueteId:this.$route.params.paqueteId,
      PaqueteEstatus,
      PaqueteTipoEmpaque,
      PaqueteTipos,
      costo: {
        seguro: '',
        total: '',
        mensaje: ''
      },
      almacenId: "",
      nuevoPaquete: {
        agenciaId: "",
        empaque: "",
        fecha: {
          formatted: "",
        },
        fechaArribo: {
          formatted: "",
        },
        estatus: "",
        tipo: "",
        receptor: "",
        pagado: false,
        asegurado: false,
        defectuoso: false,
        observacion: "",
      },
      enviarCorreo: false,
      masReempaque: false,
      validarValores: {},
      cliente: {},
      mostrarModalEditarFoto:false,
      imagenSeleccionada:null,
      imgSrc:"",
      maxSize:18,
      size:0,
      limpiarCliente: false,
      almacenes: [],
    };
  },
  methods: {
    agregarObs(obs) {
      const observacion = JSON.parse(JSON.stringify(this.nuevoPaquete.observacion));
      if(!observacion.length){
        this.nuevoPaquete.observacion = `${obs} \n`
      }else{
        this.nuevoPaquete.observacion = `${obs} \n ${observacion}`
      }
      this.alertaMensaje("Observación agregada a las notas adicionales", "correcto");
      this.$forceUpdate();
    },
    calcularMontoPeso(direccion, tipoEnvio, medida){
      let costoTotal = null;
      if(tipoEnvio === "Aereo"){
        if(medida < direccion.minAerea){
          // Si el peso es menor que la tasa minima aerea se usa la tasa minima aerea
          costoTotal += (direccion.minAerea * direccion.tasaAerea)
        } else {
          // En caso contrario se usa el peso * tasa aerea
          costoTotal += (direccion.tasaAerea * medida)
        }
      }
      if(tipoEnvio === "Maritimo"){
        if(medida < direccion.minMaritima){
          // Si el peso es menor que la tasa minima Maritima se usa la tasa minima Maritima
          costoTotal += (direccion.minMaritima * direccion.tasaMaritima)
        } else {
          // En caso contrario se usan los piesCubicos * tasa Maritima
          costoTotal += (direccion.tasaMaritima * medida)
        }
      }
      return costoTotal
    },
    actualizarCosto(){
      const costo = Number(this.nuevoPaquete.costo)
      const valor = Number(this.nuevoPaquete.valorDeclarado)
      let seguro = 0;
      let costoTotal = 0;

      if(valor){
        seguro = valor * 0.05
        costoTotal = costo + seguro
        // Actualizar seguro y costo total
        this.costo.seguro = mostrarSoloDosDecimales(seguro)
      } else {
        // Si se establece manualmente en "0" el valor, se reinician los valores
        seguro = 0
        costoTotal = costo
        this.costo.seguro = mostrarSoloDosDecimales(seguro)
      }
      // Verificar tasas
      const direccion = this.nuevoPaquete.direccionDestino
      const esAereo = this.nuevoPaquete.envio && this.nuevoPaquete.envio == "Aereo"
        ? true
        : false
      const esMaritimo = this.nuevoPaquete.envio && this.nuevoPaquete.envio == "Maritimo"
        ? true
        : false
      const pesoVolumetrico = (this.nuevoPaquete.ancho * this.nuevoPaquete.alto * this.nuevoPaquete.largo) / 166
      const piesCubicos = (this.nuevoPaquete.ancho * this.nuevoPaquete.alto * this.nuevoPaquete.largo) / 1728
      const peso = this.nuevoPaquete.peso >  pesoVolumetrico
        ? this.nuevoPaquete.peso
        : pesoVolumetrico
      if(!esAereo && !esMaritimo) return (
        this.costo.mensaje = 'Es necesario asignar un consolidado o definir el tipo de envío al paquete para obtener el costo total'
      )
      if(!direccion) return (
        this.costo.mensaje = 'Es necesario asignar una dirección al paquete para obtener el costo total'
      )
      if(esAereo){
        if(direccion.tasaAerea && direccion.minAerea){
          if(peso < direccion.minAerea){
            // Si el peso es menor que la tasa minima aerea se usa la tasa minima aerea
            costoTotal += (direccion.minAerea * direccion.tasaAerea)
          } else {
            // En caso contrario se usa el peso * tasa aerea
            costoTotal += (direccion.tasaAerea * peso)
          }
        }
        else{
          return (
            this.costo.mensaje = 'Es necesario asignar todas las tasas aéreas en dirección para obtener el costo total'
          )
        }
      }

      if(esMaritimo){
        if(direccion.tasaMaritima && direccion.minMaritima){
             if(piesCubicos < direccion.minMaritima){
            // Si los piesCubicos es menor que la tasa minima Maritima se usa la tasa minima Maritima
            costoTotal += (direccion.minMaritima * direccion.tasaMaritima)
          } else {
            // En caso contrario se usan los piesCubicos * tasa Maritima
            costoTotal += (direccion.tasaMaritima * piesCubicos)
          }
    }else{
          return (
              this.costo.mensaje = 'Es necesario asignar tasas maritimas en dirección para obtener el costo total'
            )
        }
      }
      this.costo.total = mostrarSoloDosDecimales(
          costoTotal
        )
      this.costo.mensaje = ''
    },
    obtenerAgenciaDestino(idAgencia) {
      // Obtener datos de la agenciaDestino
      const agencia = this.agencias.filter((a) => a.id === idAgencia)
      const agenciaConFormato = agencia[0] ? agencia [0] : {}
      // Se elimina la propiedad dirección para evitar problemas de cache con las querys
      agenciaConFormato && agenciaConFormato.direccion
        ? delete agenciaConFormato.direccion
        : ''
      return agenciaConFormato
    },
    alertaMensaje(contenido, tipo, emit) {
      // La propiedad emit, existe cuando otro componente hijo emite alertaMensaje
      if(emit && emit.contenido && emit.tipo){
        return this.$emit('alertaMensaje', {
          contenido: emit.contenido,
          tipo: emit.tipo,
        });
      }
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    fechaActual() {
      this.nuevoPaquete.fecha.formatted = new Date().toISOString().slice(0,10);
    },
    PaqueteAsignarDatosAnteriores() {
      Object.assign(this.nuevoPaquete, this.datosPaquete);
      this.almacenId = this.datosPaquete.almacen.id
      if (this.nuevoPaquete.observacion === null) {
        this.nuevoPaquete.observacion = "";
      }
      if (this.nuevoPaquete.empaque === null) {
        this.nuevoPaquete.empaque = "";
      }
      if (this.nuevoPaquete.tracking === null) {
        this.nuevoPaquete.tracking = "";
      }
      if (this.nuevoPaquete.receptor === null) {
        this.nuevoPaquete.receptor = "";
      }
      if (this.nuevoPaquete.valorDeclarado === null) {
        this.nuevoPaquete.valorDeclarado = "";
      }
      // Rellenar datos del select "Agencia"
      if(this.nuevoPaquete.agenciaDestino && this.nuevoPaquete.agenciaDestino.id){
        this.nuevoPaquete.agenciaId = this.nuevoPaquete.agenciaDestino.id
      }
      if(this.nuevoPaquete.valorDeclarado){
        this.actualizarCosto()
      }
    },
    leerImagenEditar(imagenSeleccionada){
       if (typeof FileReader === "function") {
         const reader = new FileReader();
         reader.onload = (event) => {
           this.imgSrc = event.target.result;
           this.mostrarModalEditarFoto = true;
         };
         reader.readAsDataURL(imagenSeleccionada);
       } else {
         this.alertaMensaje("No se puede editar la imagen, actualiza tu navegador", "advertencia");
       }
    },
    imagenAgregar(archivo){
      const sizeFile = Number((archivo.size / 1024 / 1024).toFixed(2));
       if (sizeFile > this.maxSize) {
          return this.alertaMensaje(
            `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
       )}
      if ((this.size + sizeFile) > this.maxSize) {
          return this.alertaMensaje(
            `La imagen modificada supera el límite de 18MB, no se puede agregar. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
            "error"
          );
        }
        this.size += Number(sizeFile);
        this.fotos.push(archivo)
    },
    handleInputChange({ target }) {
      if (!target.files.length) return;
      if (target.files.length === 1 && regExpFoto.test(target.files[0].name)) {
        this.imagenSeleccionada = target.files[0];
        this.mostrarModalConfirmarEditarImagen = true
        return;
      }
      target.files.forEach((file) => {
        const sizeFile = Number((file.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          return this.alertaMensaje(
            `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
          );
        }
        if ((this.size + sizeFile) > this.maxSize) {
          return this.alertaMensaje(
          `No se puede agregar el archivo ya que superaría el límite establecido de 18MB. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
            "error"
          );
        }
        if(!regExpFoto.test(file.name)){
          return this.alertaMensaje("El archivo no es una imagen", "advertencia");
        }
        this.size += Number(sizeFile);
        this.fotos.push(file)
      });
    },
    vaciarFotos(){
      this.fotos = []
      this.size = 0;
    },
    asignarCliente (cliente) {
      if(cliente.id && !cliente.casillero) {
        this.limpiarCliente = true;
        this.botonDeshabilitado = false;
        return this.alertaMensaje("El cliente seleccionado no cuenta con casillero, "+
        "debes dirigirte a los detalles del cliente y asignar una agencia para continuar", "advertencia");
      } else {
        this.cliente = cliente
      }
    },
    limpiarCamposNuevoPaquete() {
      this.nuevoPaquete = {
        agenciaId: "",
        empaque: "",
        fecha: {
          formatted: "",
        },
        fechaArribo: {
          formatted: "",
        },
        estatus: "",
        tipo: "",
      };
      this.almacenId = ''
      this.validarValores = {};
      this.cliente = {};
      this.fotos = [];
      this.size = 0;
      this.mostrarModalFile = false;
      this.paquetesListado = []
      this.masReempaque = false;
    },
    validarNuevoPaquete() {
      this.botonDeshabilitado = true;
      if(this.masReempaque && !this.paquetesListado.length){
        this.alertaMensaje("Se especificó reempacar más paquetes"+
        " es necesario seleccionar mínimo un paquete para continuar", "advertencia");
        this.botonDeshabilitado = false;
        return;
      }
      this.validarValores = {
        fecha: this.nuevoPaquete.fecha.formatted ? "valido" : "invalido",
        largo: parseFloat(this.nuevoPaquete.largo) ? "valido" : "invalido",
        ancho: parseFloat(this.nuevoPaquete.ancho) ? "valido" : "invalido",
        alto: parseFloat(this.nuevoPaquete.alto) ? "valido" : "invalido",
        peso: parseFloat(this.nuevoPaquete.peso) ? "valido" : "invalido",
        almacen: this.almacenId.length ? "valido" : "invalido",
      };

      if(this.esEditar){
        if(this.esPermitido){
          // Permiso a modificar estatus  a excepción de "devuelto" (solo el admin puede cuando esté en miami)
          if(this.nuevoPaquete.estatus == 'Devuelto' && !this.roles.includes('Admin')) {
            this.botonDeshabilitado = false;
            this.nuevoPaquete.estatus = this.estatusAnterior
            return this.alertaMensaje('No tienes permitido devolver si no eres administrador','advertencia')
          }
          if(this.estatusAnterior == "EsperaMiami") {
            // Si el paquete es reempacado no se puede devolver
            if(this.nuevoPaquete.estatus == 'Devuelto' && !this.esIndividual) {
              this.botonDeshabilitado = false;
              this.nuevoPaquete.estatus = this.estatusAnterior
              return this.alertaMensaje('Solo se pueden devolver paquetes individuales, el paquete se encuentra reempacado, revisa tus datos','advertencia')
            }
          } else {
            if(this.nuevoPaquete.estatus == 'Devuelto') {
              this.botonDeshabilitado = false;
              this.nuevoPaquete.estatus = this.estatusAnterior
              return this.alertaMensaje('No tienes permitido devolver el paquete si no se encuentra en Miami','advertencia')
            }
          }
        }
        else if (this.estatusAnterior !== this.nuevoPaquete.estatus){
          // No es permitido asignar cómo devuelto un paquete
          if(this.nuevoPaquete.estatus == 'Devuelto') {
            this.botonDeshabilitado = false;
            this.nuevoPaquete.estatus = this.estatusAnterior
            return this.alertaMensaje('No tienes permitido realizar esta acción','advertencia')
          }
          // Valida que se esté modificando un estatus menor a "En Transito Nacional"
          if(esRetrocesoEstatusPaquete(this.estatusAnterior, 'ListoDespacho')){
            if(esRetrocesoEstatusPaquete('TransitoNacional', this.nuevoPaquete.estatus)){
              this.botonDeshabilitado = false;
              this.nuevoPaquete.estatus = this.estatusAnterior
              return this.alertaMensaje('No tienes permitido realizar esta acción','advertencia')
              }
            } else {
              this.botonDeshabilitado = false;
              this.nuevoPaquete.estatus = this.estatusAnterior
              return this.alertaMensaje('No tienes permitido realizar esta acción','advertencia')
            }
        }
      }

      if(this.nuevoPaquete.estatus == 'Devuelto' && !this.nuevoPaquete.observacion){
        this.botonDeshabilitado = false;
        return this.alertaMensaje('Es necesario especificar en la observación el motivo por el cual se devolvió el paquete','advertencia')
      }

      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Llena los campos requeridos', 'error', false);
        this.botonDeshabilitado = false;
        return;
      }

      // Aplica formato Capitalize al nombre del Receptor
      if (this.nuevoPaquete.receptor) {
        const textoCapitalize = this.nuevoPaquete.receptor.replace(
          /(^\w|\s\w)/g,
          (m) => m.toUpperCase()
        );
        const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, " ");
        this.nuevoPaquete.receptor = textoSinEspacios;
      }

      this.nuevoPaquete.largo = parseFloat(this.nuevoPaquete.largo);
      this.nuevoPaquete.ancho = parseFloat(this.nuevoPaquete.ancho);
      this.nuevoPaquete.alto = parseFloat(this.nuevoPaquete.alto);
      this.nuevoPaquete.peso = parseFloat(this.nuevoPaquete.peso);

      // Eliminando propiedad cliente y agenciaDestino proveniente del prop datosPaquete
      delete this.nuevoPaquete.cliente;
      if(this.nuevoPaquete.agenciaDestino){
        delete this.nuevoPaquete.agenciaDestino;
      }
      if(this.nuevoPaquete.almacen){
        delete this.nuevoPaquete.almacen;
      }
      this.nuevoPaquete.empaque ? '' : delete this.nuevoPaquete.empaque

      if (this.esReempaque) {
        // Se elimina agenciaId para enviarse a la mutate cómo propiedad
        const agenciaId = this.nuevoPaquete.agenciaId
        delete this.nuevoPaquete.agenciaId
        delete this.nuevoPaquete.fechaArribo;
        delete this.nuevoPaquete.estatus;
        this.nuevoPaquete.tipo = "Reempaque";
        this.nuevoPaquete.estatus = "EsperaMiami";
        let personaId = this.cliente && this.cliente.id
          ? this.cliente.id : '';
        // filtrar ids de paquetes con reempacado = false
        let paquetesIdFormatted = []
        // En caso de reempacar cuando ya se tiene el id
        // del cliente se asigna del prop
        if(this.personaIdReempacar){
          personaId = this.personaIdReempacar;
          if(this.paquetes && this.paquetes[0] && this.paquetes[0].cliente){
            this.cliente = this.paquetes[0].cliente
          }
        }
        let variablesMutate = {
          paquete: JSON.parse(JSON.stringify(this.nuevoPaquete)),
          paquetes: this.paquetesId,
          almacenId: this.almacenId,
        }
        if(this.paquetesListado.length){
          Object.assign(variablesMutate, { paquetesListado: this.paquetesListado })
          // Limpiar ids
          this.paquetesListado.forEach(id => {
            paquetesIdFormatted.length 
              ? paquetesIdFormatted = paquetesIdFormatted.filter(i => i != id)
              : paquetesIdFormatted = this.paquetesId.filter(i => i != id)
          })
        }
        if(agenciaId){
          Object.assign(variablesMutate, { agenciaId: agenciaId })
        }
        if(personaId){
          Object.assign(variablesMutate, { personaId: personaId })
        }
        if(this.parametrosOrdenPaquete && this.parametrosOrdenPaquete.direccionId){
          Object.assign(variablesMutate,
            { direccionId: this.parametrosOrdenPaquete.direccionId })
        }
        // Dar formato al paquete
        Object.assign(this.nuevoPaquete, {
          __typename: "Paquete",
          cliente: this.cliente
            ? JSON.parse(JSON.stringify(this.cliente))
            : {},
          contiene: this.paquetes,
          observacion: this.nuevoPaquete.observacion
             ? this.nuevoPaquete.observacion
             : '',
          estatus: this.nuevoPaquete.estatus
             ? this.nuevoPaquete.estatus
             : '',
          receptor: this.nuevoPaquete.receptor
             ? this.nuevoPaquete.receptor
             : '',
          envio: this.nuevoPaquete.envio
             ? this.nuevoPaquete.envio
             : '',
          numeroHR: this.cliente.casillero+obtenerMarcaTemporal(),
          tracking: this.nuevoPaquete.tracking
              ? this.nuevoPaquete.tracking
              : '',
          pagado: false,
          agenciaDestino: this.nuevoPaquete.agenciaDestino
             ? this.nuevoPaquete.agenciaDestino
             : {
                __typename: "Agencia",
                id: '',
                nombre: '',
                logo: null,
                enlace: '',
              },
          consolidado: {
            __typename: "Consolidado",
            id: '',
            master: '',
          },
          direccionDestino: this.nuevoPaquete.direccionDestino
             ? this.nuevoPaquete.direccionDestino
             : {
                __typename: 'Direccion',
                id: '',
                direccion: '',
                ciudad: {
                  nombre: '',
                  __typename: 'Ciudad',
                  estado: {
                    nombre: '',
                  __typename: 'Estado',
                  }
                },
              }
        });
        Object.assign(this.nuevoPaquete.fecha, {
          __typename: "_Neo4jDateTime",
        })
        if(agenciaId){
          this.nuevoPaquete.agenciaDestino = this.obtenerAgenciaDestino(agenciaId)
        }
        // Reempacar paquete
        this.$apollo
          .mutate({
            mutation: gql`
              ${paqueteReempacarGql}
            `,
            variables: variablesMutate,
            update: (store, {data: {paqueteReempacar}}) => {
              if(!this.esCliente){
                // cunado se opera desde la view de paquetes
                const data = store.readQuery({
                    query: paquetesGql,
                    variables: this.variablesUpdateQueryViewPaquetes,
                });
                const paqueteId = paqueteReempacar.id;
                Object.assign(this.nuevoPaquete, {
                  id: paqueteId,
                })
                const nombreAlmacen = this.almacenes.filter((a) => a.id == this.almacenId)
                this.nuevoPaquete.almacen = {
                  id: this.almacenId,
                  nombre: nombreAlmacen[0].nombre,
                  __typename: 'Almacen'
                };

                data.Paquete.unshift(this.nuevoPaquete)

                store.writeQuery({
                  query: paquetesGql,
                  variables: this.variablesUpdateQueryViewPaquetes,
                  data
                });
              }else{
                // Cuando se opera desde la view de cliente
                const data = store.readQuery({
                    query: clienteGql,
                    variables: {
                      filter:{
                        id: this.$route.params.id,
                      }
                    },
                });
                const paqueteId = paqueteReempacar.id;
                Object.assign(this.nuevoPaquete, {
                  id: paqueteId,
                })

                data.Cliente[0].paquetes.unshift(this.nuevoPaquete)
                // Limpiar paquetes reempacados

                if(paquetesIdFormatted.length){
                  data.Cliente[0].paquetes  = multipleFiltrado(data.Cliente[0].paquetes,
                    { id: paquetesIdFormatted });
                } else {
                  data.Cliente[0].paquetes  = multipleFiltrado(data.Cliente[0].paquetes,
                    { id: this.paquetesId });
                }

                store.writeQuery({
                  query: clienteGql,
                  variables: {
                    filter:{
                      id: this.$route.params.id,
                    }
                  },
                  data
                });
              }

              if(this.parametrosOrdenPaquete){
                this.$emit('eliminarOrdenReempaque', true)
              }
              this.alertaMensaje("Reempaque exitoso",
                "correcto");
              this.limpiarCamposNuevoPaquete();
              this.$emit("paginacionResetear", true);
              this.$emit("cerrar", false);
              this.botonDeshabilitado = false;
            },
          })
          .catch(()=>{
            this.alertaMensaje("Ha ocurrido un error reempacando el paquete",
              "error");
            this.nuevoPaquete.agenciaId = agenciaId
            this.botonDeshabilitado = false;
          })
      } else if (this.esEditar) {
        const paqueteAEditar = { ...this.nuevoPaquete };
        // No se pueda cambiar el estatus a entregado si este no se ha paqueteEntregarDestino
        if(paqueteAEditar.estatus === 'Entregado'
          && !paqueteAEditar.pagado) {
            this.botonDeshabilitado = false;
            Object.assign(this.validarValores, {
              estatus: 'invalido'
            })
            return this.alertaMensaje('Estatus inválido, el paquete debe estar pagado para'+
              ' marcarlo como entregado', 'advertencia');
        } else {
          this.validarValores.estatus ? delete this.validarValores.estatus : ''
        }

        // Obtener datos cómo fotos, direcciones... para asignarlos al cache
        const datosPaqueteHaciaCache = {
          agenciaDestino: paqueteAEditar.agenciaDestino,
          consolidado: paqueteAEditar.consolidado,
          direccionDestino: paqueteAEditar.direccionDestino,
          envio: paqueteAEditar.envio,
          observacionEnvio: paqueteAEditar.observacionEnvio,
          contiene: paqueteAEditar.contiene,
          personaRecibe: paqueteAEditar.personaRecibe,
          fotos: paqueteAEditar.fotos,
          cambios: paqueteAEditar.cambios,
        }

        // Eliminar datos no necesarios en la mutate
        delete paqueteAEditar.agenciaDestino;
        delete paqueteAEditar.consolidado;
        delete paqueteAEditar.direccionDestino;
        delete paqueteAEditar.envio;
        delete paqueteAEditar.observacionEnvio;
        delete paqueteAEditar.contiene;
        delete paqueteAEditar.personaRecibe;
        delete paqueteAEditar.fotos;
        delete paqueteAEditar.cambios;
        delete paqueteAEditar.pagos;
        delete paqueteAEditar.ordenProductos;
        delete paqueteAEditar.fecha.__typename;
        delete paqueteAEditar.fechaArribo.__typename;
        delete paqueteAEditar.__typename;
        // Se elimina agenciaId para enviarse a la mutate cómo propiedad
        const agenciaId = this.nuevoPaquete.agenciaId
        delete paqueteAEditar.agenciaId
        delete paqueteAEditar.almacen
        let variablesMutate = {
          paquete: paqueteAEditar,
          almacenId: this.almacenId,
        };
        if(agenciaId){
          Object.assign(variablesMutate, { agenciaId: agenciaId })
        }

        if (this.cliente.id) {
          variablesMutate.personaId = this.cliente.id;
        }

        this.$apollo
          .mutate({
            mutation: gql`
              ${paqueteEditarGql}
            `,
            variables: variablesMutate,
            update: (store, {data: {paqueteEditar}}) => {
              const msg = paqueteEditar.codigo;
              switch (msg) {
                case "Correcto":
                  break;
                case "Fallido":
                  this.botonDeshabilitado = false;
                  this.alertaMensaje("Ha ocurrido un error editando el paquete", "error");
                  return;
                default:
                  this.botonDeshabilitado = false;
                  this.alertaMensaje("Ha ocurrido un error inesperado. Por favor intenta de nuevo", "error");
                  return;
              }

              const data = store.readQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
              });

              const paquete = JSON.parse(JSON.stringify(paqueteAEditar))

              Object.assign(data.Paquete[0], paquete)
              Object.assign(data.Paquete[0], datosPaqueteHaciaCache)

              data.Paquete[0].cambios.unshift({
                autor: leerPersonaNombre(),
                descripcion: "Paquete editado",
                cliente: this.datosPaquete.cliente
                  ? this.datosPaquete.cliente.nombre
                  : '',
                defectuoso: this.nuevoPaquete.defectuoso,
                nuevoEstatus: this.nuevoPaquete.estatus,
                pagado: this.nuevoPaquete.pagado,
                fechaHora: {
                  formatted: new Date().toISOString(),
                __typename: "_Neo4jDateTime"
                },
                __typename: "Cambio"
              })

              data.Paquete[0].__typename = 'Paquete';
              data.Paquete[0].fechaArribo.__typename = '_Neo4jDate';
              data.Paquete[0].fecha.__typename = '_Neo4jDateTime';
              const nombreAlmacen = this.almacenes.filter((a) => a.id == this.almacenId)
              data.Paquete[0].almacen = {
                id: this.almacenId,
                nombre: nombreAlmacen[0].nombre,
                __typename: 'Almacen'
              };

              if (agenciaId) {
                 data.Paquete[0].agenciaDestino = this.obtenerAgenciaDestino(agenciaId)
              } else {
                data.Paquete[0].agenciaDestino = {
                    __typename: "Agencia",
                    id: '',
                    nombre: '',
                    telefonos: [],
                    logo: null,
                    enlace: '',
                  };
              }

              store.writeQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
                data
              });

              this.alertaMensaje("Se ha modificado un paquete correctamente", "correcto");
              this.limpiarCamposNuevoPaquete();
              this.$emit("cerrar", false);
              this.botonDeshabilitado = false;
            },
          })
          .catch(()=>{
            this.alertaMensaje("Ha ocurrido un error al editar el paquete", "error");
            this.botonDeshabilitado = false;
          })
      } else if(!this.esEditar && !this.esReempaque) {
        delete this.nuevoPaquete.fechaArribo;
        // Se elimina agenciaId para enviarse a la mutate cómo propiedad
        const agenciaId = this.nuevoPaquete.agenciaId
        delete this.nuevoPaquete.agenciaId
        this.nuevoPaquete.estatus = "EsperaMiami";
        this.nuevoPaquete.tipo = "Individual";
        const variablesMutate = {
          paquete: JSON.parse(JSON.stringify(this.nuevoPaquete)),
          fotos: { foto: this.fotos },
          almacenId: this.almacenId,
          enviarCorreo: this.enviarCorreo,
        };
        if(agenciaId){
          Object.assign(variablesMutate, { agenciaId: agenciaId })
        }
        if (this.cliente.id) {
          variablesMutate.personaId = this.cliente.id;
        }

        // Dar formato al paquete
        Object.assign(this.nuevoPaquete, {
          __typename: "Paquete",
          cliente: this.cliente && this.cliente.id
            ? JSON.parse(JSON.stringify(this.cliente))
            : {
              __typename: 'Cliente',
              id: '',
              nombre: '',
              credito: '',
              casillero: '',
              emails: [],
              telefonos: [],
            },
          contiene: [],
          observacion: this.nuevoPaquete.observacion
             ? this.nuevoPaquete.observacion
             : '',
          estatus: this.nuevoPaquete.estatus
             ? this.nuevoPaquete.estatus
             : '',
          receptor: this.nuevoPaquete.receptor
             ? this.nuevoPaquete.receptor
             : '',
          envio: this.nuevoPaquete.envio
             ? this.nuevoPaquete.envio
             : '',
          numeroHR:`${this.cliente.casillero || ''}${obtenerMarcaTemporal()}`,
              tracking: this.nuevoPaquete.tracking
              ? this.nuevoPaquete.tracking
              : '',
          pagado: false,
          fotos: { url: this.fotos, __typename: "Foto" },
          agenciaDestino: this.nuevoPaquete.agenciaDestino
             ? this.nuevoPaquete.agenciaDestino
             : {
                __typename: "Agencia",
                id: '',
                nombre: '',
                logo: null,
                enlace: '',
              },
          consolidado: {
            __typename: "Consolidado",
            id: '',
            master: '',
          },
          direccionDestino: this.nuevoPaquete.direccionDestino
             ? this.nuevoPaquete.direccionDestino
             : {
                __typename: 'Direccion',
                id: '',
                direccion: '',
                ciudad: {
                  nombre: '',
                  __typename: 'Ciudad',
                  estado: {
                    nombre: '',
                  __typename: 'Estado',
                  }
                },
              }
        });

        // Verificar si existen propiedades de teléfonos o emails en el cliente del paquete
        if(this.nuevoPaquete.cliente && !this.nuevoPaquete.cliente.emails){
          this.nuevoPaquete.cliente.emails = []
        }
        if(this.nuevoPaquete.cliente && !this.nuevoPaquete.cliente.telefonos){
          this.nuevoPaquete.cliente.telefonos = []
        }

        Object.assign(this.nuevoPaquete.fecha, {
          __typename: "_Neo4jDateTime",
        })
        if(agenciaId){
          this.nuevoPaquete.agenciaDestino = this.obtenerAgenciaDestino(agenciaId)
        }
        // Crear paquete
        this.$apollo
          .mutate({
            mutation: gql`
              ${paqueteCrearGql}
            `,
            variables: variablesMutate,
            update: (store, {data: {paqueteCrear}}) => {
              let data
              if(this.esResumen){
                data = store.readQuery({ 
                  query: paquetesSinClienteGql,
                });
              }
              else{
                data = store.readQuery({ 
                  query: paquetesGql,
                  variables: this.variablesUpdateQueryViewPaquetes,
              });
              }
              const paqueteId = paqueteCrear.id;
              Object.assign(this.nuevoPaquete, {
                id: paqueteId,
              })
              const nombreAlmacen = this.almacenes.filter((a) => a.id == this.almacenId)
              this.nuevoPaquete.almacen = {
                id: this.almacenId,
                nombre: nombreAlmacen[0].nombre,
                __typename: 'Almacen'
              };

              if((!this.esResumen )|| (this.esResumen && !this.cliente.id)){
                data.Paquete.unshift(this.nuevoPaquete)
              }

              if(this.esResumen){
                 store.writeQuery({
                  query: paquetesSinClienteGql,
                  data
                });
              } else {
                store.writeQuery({
                  query: paquetesGql,
                  variables: this.variablesUpdateQueryViewPaquetes,
                  data
                });
              }

              this.alertaMensaje("Se ha agregado un nuevo paquete", "correcto");
              this.limpiarCamposNuevoPaquete();
              this.$emit("paginacionResetear", true);
              this.$emit("cerrar", false);
              this.botonDeshabilitado = false;
            },
          })
          .catch((e)=>{
            this.alertaMensaje("Ha ocurrido un error al crear el paquete"+": "+e, "error");
            this.nuevoPaquete.agenciaId = agenciaId
            this.botonDeshabilitado = false;
          })
      }else return this.botonDeshabilitado = false;
    },
  },
  computed: {
    esPermitido(){
      return this.roles.includes('Admin') || this.roles.includes('OpAlmacen')
        ? true
        : false
    },
    esAgencia(){
      return this.roles.includes('Agencia')
        ? true
        : false
    },
    iconoBotonPrincipal() {
      return this.esEditar ? 'sync' : 'check';
    },
    transicionNuevoCliente() {
      return this.mostrarModalNuevoCliente
        ? { entrada: "animated fadeInRight", salida: "animated fadeOutLeft" }
        : { entrada: "animated fadeInLeft", salida: "animated fadeOutRight" };
    },
  },
  watch: {
    mostrarModal: function() {
      if(this.mostrarModal){
        this.esEditar
          ? this.PaqueteAsignarDatosAnteriores()
          : this.fechaActual();
      }
    },
    parametrosOrdenPaquete: function() {
      if(this.parametrosOrdenPaquete){
        if(this.parametrosOrdenPaquete.envio){
          this.nuevoPaquete.envio = this.parametrosOrdenPaquete.envio
        }
        if(this.parametrosOrdenPaquete.observacion){
          this.nuevoPaquete.observacion = this.parametrosOrdenPaquete.observacion
        }

      }
    },
    agenciaSeleccionada: function() {
      if(this.agenciaSeleccionada){
          this.nuevoPaquete.agenciaId = this.agenciaSeleccionada
        }
    },
  },
  apollo:{
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        fetchPolicy: "cache-and-network",
      };
    },
    almacenes() {
      return {
        query: almacenesGql,
        update: (data) => data.Almacen,
        fetchPolicy: "cache-and-network",
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.campo {margin-bottom: 1rem;}
.mt-lg-custom-reempaque {
  @media screen and (min-width: 992px) {margin-top: 64px;}
}
.opciones-checkbox {padding-left: 20px;}
</style>

<template>
  <div class="vista-detalles consolidado">
    <div class="container-xl px-0">
      <!-- Encabezado -->
      <div
        v-if="!$apolloData.queries.consolidado.loading"
        class="row align-items-center justify-content-center mt-3"
      >
        <router-link
          to="/consolidados"
          class="col-auto order-md-1 text-reset"
        >
          <mdb-btn
            flat
            dark-waves
            icon="angle-left"
            icon-class="fa-lg"
            class="btn-bordeado px-lg-3"
          >
            Volver atrás
          </mdb-btn>
        </router-link>
        <div
          :class="['col-auto order-md-3', {'d-none d-md-block': !consolidado}]"
        >
          <mdb-btn
            v-if="roles.includes('Admin')"
            flat
            dark-waves
            icon="trash"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-md-0 px-3"
            @click="mostrarModalConfirmarPermiso = true"
          >
            Eliminar
          </mdb-btn>
          <mdb-btn
            v-if="permitirEditarConsolidado"
            color="primario"
            icon="pen"
            :disabled="botonDeshabilitado"
            class="my-0 mx-4 mx-md-1 px-3 text-nowrap botonFlotante"
            @click="mostrarFormularioConsolidado = true;"
          >
            Editar
          </mdb-btn>
        </div>
        <h3 class="col-12 col-md order-md-2 mt-2 text-center user-select-none">
          Consolidado
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.consolidado.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.consolidado.loading && !consolidado"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          El consolidado solicitado no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row mt-md-2">
          <p class="col-12 col-sm-6 col-md-4 col-xl-3 mb-2 pr-md-0">
            <small class="text-muted user-select-none d-block">
              Estatus
            </small>
            {{ ConsolidadoEstatus[consolidado.estatus]}}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Guía máster
            </small>
            {{ consolidado.master ? consolidado.master : 'Sin especificar'}}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Tipo
            </small>
            <font-awesome-icon
              v-if="consolidado && consolidado.tipo && consolidado.tipo.length"
              :icon="ConsolidadoTipoEnvioIconos[consolidado.tipo]"
              class="texto-terciario"
            />
            {{ConsolidadoTipoEnvio[consolidado.tipo]}}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Fecha de carga
            </small>
            <font-awesome-icon
              icon="calendar-day"
              class="texto-terciario"
            />
            <span
              v-if="consolidado && consolidado.fechaCarga
                && consolidado.fechaCarga.formatted"
            >
              {{consolidado.fechaCarga.formatted}}
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Fecha de arribo
            </small>
            <font-awesome-icon
              icon="calendar-day"
              class="texto-terciario"
            />
            <span v-if="consolidado && consolidado.fechaArribo && consolidado.fechaArribo.formatted">
              {{consolidado.fechaArribo.formatted}}
            </span>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Volumen
            </small>
            <span
              v-if="consolidado && consolidado.volumen"
              title="Pies cúbicos"
            >
              {{consolidado.volumen}} ft<sup>3</sup>
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso
            </small>
            <span
              v-if="consolidado && consolidado.peso "
              title="Libras"
            >
              {{consolidado.peso}} lb
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin paquetes en el consolidado
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso Manual
            </small>
            <span
              v-if="consolidado && consolidado.pesoManual "
              title="Libras"
            >
              {{consolidado.pesoManual}} lb
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Pies cúbicos
            </small>
            {{ consolidado.piesCubicos }} ft<sup>3</sup>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso por volúmen
            </small>
            {{ consolidado.pesoVolumetrico }} vlbs
          </p>
          <p class="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Cantidad de paquetes
            </small>
            <span v-if="consolidado && consolidado.cantidad">
              {{consolidado.cantidad}}
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
          </p>
        </div>
        <h4 class="user-select-none mt-2">Instrucciones de envío</h4>
        <div class="row">
          <div
            :class="['col-12 col-lg-6',
              {'col-xl-7': consolidado && consolidado.destino && consolidado.destino.direccion},
              'mb-2']"
          >
            <small class="text-muted user-select-none d-block">
              Dirección
            </small>
            <div
              v-if="consolidado && consolidado.destino && consolidado.destino.direccion
                && consolidado.destino.direccion.length"
              class="row justify-content-center"
            >
              <div class="col-12 mb-2">
                {{ consolidado.destino.direccion }}.
                {{ consolidado.destino.ciudad.nombre }},
                {{ consolidado.destino.ciudad.estado.nombre }},
                {{ consolidado.destino.ciudad.estado.pais.nombre }}.
              </div>
              <div class="col-12">
                <div class="row">
                  <p class="col-6 col-md-3 col-lg-6 col-xl-3 mb-2 pr-xl-2">
                    <small class="text-muted user-select-none d-block">
                      Tasa aérea
                    </small>
                    <span :class="{'text-muted user-select-none': !consolidado.destino.tasaAerea}">
                      {{ consolidado.destino.tasaAerea ? consolidado.destino.tasaAerea : 'Sin especificar' }}
                    </span>
                  </p>
                  <p class="col-6 col-md-3 col-lg-6 col-xl-3 mb-2 px-xl-2">
                    <small class="text-muted user-select-none d-block">
                      Tasa marítima
                    </small>
                    <span :class="{'text-muted user-select-none': !consolidado.destino.tasaMaritima}">
                      {{ consolidado.destino.tasaMaritima ? consolidado.destino.tasaMaritima : 'Sin especificar' }}
                    </span>
                  </p>
                  <p class="col-6 col-md-3 col-lg-6 col-xl-3 mb-2 px-xl-2">
                    <small class="text-muted user-select-none d-block">
                      Tasa mín. aérea
                    </small>
                    <span :class="{'text-muted user-select-none': !consolidado.destino.minAerea}">
                      {{ consolidado.destino.minAerea ? consolidado.destino.minAerea : 'Sin especificar' }}
                    </span>
                  </p>
                  <p class="col-6 col-md-3 col-lg-6 col-xl-3 mb-2 px-xl-2">
                    <small class="text-muted user-select-none d-block">
                      Tasa mín. marítima
                    </small>
                    <span :class="{'text-muted user-select-none': !consolidado.destino.minMaritima}">
                      {{ consolidado.destino.minMaritima ? consolidado.destino.minMaritima : 'Sin especificar' }}
                    </span>
                  </p>
                </div>
              </div>
              <mdb-btn
                v-if="permitirEditarParametrosAdicionales"
                flat
                dark-waves
                icon="pen"
                size="sm"
                class="col-auto my-0 px-3"
                @click="esEditarDirección=true; direccionSeleccionada = consolidado.destino;
                mostrarModalEditarEnvio=!mostrarModalEditarEnvio;"
              >
                Editar dirección
              </mdb-btn>
              <mdb-btn
                v-if="roles.includes('Admin')"
                flat
                dark-waves
                icon="trash"
                size="sm"
                class="col-auto my-0 px-3"
                @click="IdDireccionAEliminar = consolidado.destino.id;
                  mostrarConfirmacionEliminar = true"
              >
                Remover Dirección
              </mdb-btn>
            </div>
            <div v-else>
              <span class="text-muted user-select-none">
                Sin especificar
              </span>
              <mdb-btn
                v-if="permitirEditarParametrosAdicionales"
                flat
                dark-waves
                icon="plus"
                size="sm"
                class="btn-bordeado my-sm-0 py-2 px-3"
                @click="mostrarModalEditarEnvio=!mostrarModalEditarEnvio"
              >
                Agregar dirección
              </mdb-btn>
            </div>
          </div>
          <p
            :class="['col-12 col-lg-6',
              {'col-xl-5': consolidado && consolidado.destino && consolidado.destino.direccion},
              'mb-2']"
          >
            <small class="text-muted user-select-none d-block">
              Observación
            </small>
            <span v-if="consolidado && consolidado.observacion">
              {{consolidado.observacion}}
            </span>
            <span
              v-else
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
          </p>
        </div>
        <!-- tabla -->
        <ul
          class="table p-3"
        >
          <li class="encabezado doble-fila row">
            <div class="fila-titulo col-12">
              <div class="row align-items-center my-1">
                <h4
                  class="col mb-0 ml-lg-0 px-2 text-center text-sm-left
                    user-select-none"
                >
                  Paquetes contenidos
                </h4>
                <div
                  v-if="permitirEditarParametrosAdicionales
                    || permitirImprimirRecibo"
                  :class="['col-auto pr-0 text-right btn-eliminar-flotante', paquetesId.length ? 'mostrar' : 'ocultar']"
                >
                  <mdb-btn
                    v-if="permitirEditarParametrosAdicionales"
                    class="px-3 py-md-2"
                    color="peligroso"
                    icon="trash"
                    @click="consolidadoRemoverPaquete"
                  >
                    Remover Paquete{{
                      paquetesId.length > 1 ? 's' : ''
                    }}
                  </mdb-btn>
                  <div class="d-inline-block bg-white m-1">
                    <mdb-btn
                      v-if="permitirImprimirRecibo"
                      flat
                      dark-waves
                      class="btn-bordeado m-0 px-3 py-md-2"
                      icon="file-invoice-dollar"
                      @click="validarModalRecibos"
                    >
                      Ver recibo
                    </mdb-btn>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            class="encabezado doble-fila row"
            v-for="(cliente, idCli) in paquetePorCliente"
            :key="idCli"
          >
            <div class="fila-titulo col-12">
              <div class="row align-items-center my-1 border-top border-top-3 pt-2">
                <div class="w-100">
                  <h5
                    class="row col"
                  >
                    <p v-if="cliente.cliente.nombre" class="col-6 col-sm-3 mb-1">
                      <small class="d-block text-muted user-select-none">Cliente</small>
                      {{ cliente.cliente.nombre }}
                    </p>
                    <p v-if="cliente.cliente.casillero" class="col-6 col-sm-3 mb-1">
                      <small class="d-block text-muted user-select-none">Casillero</small>
                      {{ cliente.cliente.casillero }}
                    </p>
                    <p class="col-12 col-sm-3 text-center text-sm-left mb-1">
                      <small class="d-block text-muted user-select-none">Costo</small>
                      <span
                        v-if="paquetesCalcularCosto(cliente.paquetes).alerta"
                        :title="paquetesCalcularCosto(cliente.paquetes).alerta"
                      >
                        Faltan datos
                      </span>
                      <span v-else>
                        {{ paquetesCalcularCosto(cliente.paquetes).monto }}
                      </span>
                    </p>
                  </h5>
                </div>
                <ul
                  data-table="sticky"
                  class="table"
                >
                  <li class="encabezado doble-fila row">
                    <div class="fila-encabezado-tabla col-12 user-select-none">
                      <div class="row">
                        <div class="col col-sm-3 pl-5 pl-lg-2 d-none d-sm-block">Fecha</div>
                        <div class="col pl-5 pl-sm-2">Detalles</div>
                        <div class="col d-none d-sm-block">
                          Cliente
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    v-for="paquete in cliente.paquetes"
                    :key="`paquete-${paquete.id}`"
                    class="contenido row align-items-center"
                    @click="permitirAccederAPaquete && $router.push({ name: 'ConsolidadoPaquete', params: { paqueteId: paquete.id } })"
                  >
                    <div
                      v-if="permitirImprimirRecibo"
                      class="col col-sm-3 d-none d-sm-block"
                    >
                      <label
                        class="opciones-checkbox"
                        title="Seleccionar paquete"
                        @click.stop
                      >
                        <input
                          v-model="paquetesId"
                          type="checkbox"
                          name="selecionar-paquete-checkbox"
                          :value="paquete"
                        />
                        <span class="icono-checkbox" />
                        {{ paquete.fecha && paquete.fecha.formatted
                          ? paquete.fecha.formatted : 'Sin especificar'}}
                      </label>
                    </div>
                    <div v-else class="col col-sm-3 d-none d-sm-block">
                      {{ paquete.fecha && paquete.fecha.formatted
                        ? paquete.fecha.formatted : 'Sin especificar'}}
                    </div>
                    <div class="col">
                      <label
                        v-if="permitirImprimirRecibo"
                        class="opciones-checkbox py-2 d-block d-sm-none"
                        title="Seleccionar paquete"
                        @click.stop
                      >
                        <input
                          v-model="paquetesId"
                          type="checkbox"
                          name="selecionar-paquete-checkbox"
                          :value="paquete"
                        />
                        <span class="icono-checkbox" />
                        {{ paquete.fecha && paquete.fecha.formatted
                          ? paquete.fecha.formatted : 'Sin especificar'}}
                      </label>
                      <span v-else>
                        {{ paquete.fecha && paquete.fecha.formatted
                          ? paquete.fecha.formatted : 'Sin especificar'}}
                      </span>
                      Receptor: {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                      <span class="d-block">
                        Paquete ID: {{ paquete.numeroHR ? paquete.numeroHR : 'Sin especificar' }}
                      </span>
                      <span v-if="paquete.observacion">
                        Observación: {{ paquete.observacion }}
                      </span>
                      <div class="d-sm-none mt-1">
                        <span class="d-block">
                          Cliente:
                          {{ paquete.cliente && paquete.cliente.nombre
                              ? paquete.cliente.nombre : 'Sin especificar cliente' }}
                        </span>
                        Casillero: {{ paquete.cliente && paquete.cliente.casillero
                          ? paquete.cliente.casillero : 'Sin especificar' }}
                      </div>
                    </div>
                    <div class="col d-none d-sm-block">
                        <span class="d-block">
                          {{ paquete.cliente && paquete.cliente.nombre
                              ? paquete.cliente.nombre : 'Sin especificar cliente' }}
                        </span>
                        Casillero: {{ paquete.cliente && paquete.cliente.casillero
                          ? paquete.cliente.casillero : 'Sin especificar' }}
                    </div>
                  </li>
                  <li
                    v-if="consolidado.paquetes
                      && !consolidado.paquetes.length"
                    class="no-items"
                  >
                    No hay paquetes
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="mostrarConfirmacionEliminar=true"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <FormularioConsolidado
      :esEditar="true"
      :estatusAnterior="consolidado ? consolidado.estatus : ''"
      :datosConsolidado="consolidado ? consolidado : {}"
      :mostrar-modal="mostrarFormularioConsolidado"
      @cerrar="mostrarFormularioConsolidado = $event;"
      @consolidado="consolidadoEditar($event)"
      @alertaMensaje="alertaMensaje=$event"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      :mensaje="IdDireccionAEliminar
        ? 'Se eliminará la dirección en su totalidad'
        : 'Recibimos la orden de eliminar este consolidado, es una acción que no puede revertirse'"
      :sustantivoFemenino="IdDireccionAEliminar ? true : false"
      @cerrar="mostrarConfirmacionEliminar = $event; IdDireccionAEliminar=''"
      @eliminar="IdDireccionAEliminar ? direccionRemover() : consolidadoEliminar()"
    />
    <!-- Modal ver recibo -->
    <ModalRecibo
      :mostrar-modal="mostrarModalRecibo"
      :clienteEspecificado="paquetesId.length && paquetesId[0].cliente ? paquetesId[0].cliente : {}"
      :idConsolidado="consolidado ? consolidado.id : ''"
      :idDireccion="paquetesId.length && paquetesId[0].direccionDestino ? paquetesId[0].direccionDestino.id : ''"
      :agenciaLogo="paquetesId.length && paquetesId[0].agenciaDestino  && paquetesId[0].agenciaDestino && paquetesId[0].agenciaDestino.logo && paquetesId[0].agenciaDestino.logo.url  ? `${endpointPublicBackend}${ paquetesId[0].agenciaDestino.logo.url}` : ''"
      :agenciaNombre="paquetesId.length && paquetesId[0].agenciaDestino?  paquetesId[0].agenciaDestino.nombre : ''"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalRecibo = $event;"
    />
     <!-- Modal Editar datos de envio -->
    <ModalDatosEnvio
      :mostrar-modal="mostrarModalEditarEnvio"
      :es-editar="esEditarDirección"
      :es-consolidado="true"
      :clienteEspecificado="consolidado ? consolidado : {}"
      :datosDireccion="direccionSeleccionada ? direccionSeleccionada : ''"
      @creado="consolidado.destino=$event;"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalEditarEnvio = $event; limpiarDatos();"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import {endpointPublicBackend} from "@/constantes/paquetes.js"
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import { multipleFiltrado } from "@/funciones/funciones.js"
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import FormularioConsolidado from "../components/FormularioConsolidado.vue";
import ModalRecibo from "@/components/ModalRecibo.vue";
import consolidadoGql from "@/graphql/consolidado.gql";
import gql from "graphql-tag";
import consolidadosGql from "@/graphql/consolidados.gql";
import consolidadoEditarGql from "@/graphql/consolidadoEditar.gql"
import consolidadoEliminarGql from "@/graphql/consolidadoEliminar.gql"
import consolidadoEliminarPaquetesGql from "@/graphql/consolidadoEliminarPaquetes.gql"
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import direccionEliminarGql from "@/graphql/direccionEliminar.gql";
import ModalDatosEnvio from "../components/ModalDatosEnvio.vue";
import { ConsolidadoEstatus, ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos,
  } from "@/constantes/consolidados.js"
import { mostrarSoloDosDecimales, esRetrocesoEstatus } from "@/funciones/funciones.js"
import { paquetesCalcularCosto } from "@/funciones/calcularCostos.js"
import { leerRoles } from "@/utils/datosToken.js";
import {
  mdbBtn,
} from "mdbvue";

export  default {
  name: "Consolidado",
  components: {
    mdbBtn,
    FormularioConsolidado,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    ModalDatosEnvio,
    ModalConfirmarPermiso,
    ModalRecibo
  },
  data() {
    return {
      // Recibos
      mostrarModalRecibo: false,
      roles:leerRoles(),
      mostrarSoloDosDecimales,
      // Confirmar permiso
      mostrarModalConfirmarPermiso: false,
       // direcciones
      mostrarModalEditarEnvio: false,
      esEditarDirección: false,
      direccionSeleccionada: {},
      IdDireccionAEliminar: '',
      // Otros
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      mostrarFormularioConsolidado: false,
      mostrarConfirmacionEliminar: false,
      paquetesId: [],
      consolidado: {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
        paquetes: [],
      },
      consolidadoTemp: {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
      },
      validarValores: {},
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      ConsolidadoEstatus,
      endpointPublicBackend,
      paquetesCalcularCosto
    };
  },
  computed: {
    variablesConsolidados() {
        const variables = { 
          offset: 0,
          first: 20,
          orderBy:["fechaCarga_desc", "master_desc"]

        }
        return variables
      },
    paquetesIdFormatted() {
      return this.paquetesId.map(p => p.id)
    },
    permitirAccederAPaquete(){
     if(this.roles.includes('Admin') 
     || 
     (this.roles.includes('OpAlmacen') && this.roles.includes('OpPuerto')) 
     || this.roles.includes('OpAlmacen') 
     ||this.roles.includes('Agencia') ){
       return true
     }else{
       return false
     }

    },
    permitirEditarConsolidado(){
      if(this.consolidado.id){
        if(this.roles.includes('Admin')){
          return true
        } else if(this.roles.includes('OpPuerto')){
          return true
        } else if (this.roles.includes('OpAlmacen')) {
          if(esRetrocesoEstatus(this.consolidado.estatus, 'EnTransito')){
            // El estatus es mayor a EnTransito, retorna false
            return false
          } else {
            return true
          }
        }
      } return false
    },
    permitirImprimirRecibo(){
     if(this.roles.includes('Admin') || (this.roles.includes('OpAlmacen') && this.roles.includes('OpPuerto'))){
        return true
      } else if(this.roles.includes('Agencia')){
        return false
      } else if(this.roles.includes('OpPuerto')){
        return true
      } else {
        if(esRetrocesoEstatus(this.consolidado.estatus, 'EnTransito')){
          return false
        } return true
      }
    },
    permitirEditarParametrosAdicionales(){
      if(this.roles.includes('Admin') || (this.roles.includes('OpAlmacen') && this.roles.includes('OpPuerto'))){
        return true
      } else if(this.roles.includes('Agencia')){
        return false
      } else if(this.roles.includes('OpPuerto')){
        // OpPuerto solo podrá editar paquete, retorna false
        return false
      } else {
        // Es rol OpAlmacen
        if(esRetrocesoEstatus(this.consolidado.estatus, 'EnTransito')){
          // El estatus es mayor a EnTransito, retorna false
          return false
        } return true
      }
    },
    paquetePorCliente(){
      const data = {}
      if(Object.keys(this.consolidado).length && this.consolidado.paquetes.length) {
        this.consolidado.paquetes.forEach(paquete => {
          if (!data[paquete.cliente.id]) {
            data[paquete.cliente.id] = {
              cliente: paquete.cliente,
              paquetes: []
            }
          }
          data[paquete.cliente.id].paquetes.push(paquete)
        })
      }
      return data
    },
  },
  methods: {
    limpiarDatos() {
      this.mostrarModalEditarEnvio= false;
      this.esEditarDirección= false;
      this.direccionSeleccionada= {};
      this.IdDireccionAEliminar= '';
    },
    validarModalRecibos() {
      if (this.paquetesId.length != 1) {
        return (this.alertaMensaje = {
          contenido: 'Selecciona solo un paquete para continuar',
          tipo: 'advertencia'
        })
      }
      this.mostrarModalRecibo = true;
    },
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
    async consolidadoRemoverPaquete() {
      if(!this.paquetesId.length) return
      this.$apollo
        .mutate({
          mutation: gql`
          ${consolidadoEliminarPaquetesGql}
        `,
          variables: {
            paquetesId: this.paquetesIdFormatted,
            consolidadoId: this.consolidado.id,
            estatus: this.consolidado.paquetes.length === this.paquetesId.length ? false : true
          },
        })
        .then(({data}) => {
          const msg = data.consolidadoEliminarPaquetes.codigo;
          const filtro = {
            id: this.paquetesIdFormatted
          };
          switch (msg) {
            case 'Correcto':
              this.consolidado.paquetes = multipleFiltrado(this.consolidado.paquetes, filtro);
              this.paquetesId = [];
              this.alertaMensaje = {
                contenido:  'Se ha removido el paquete del consolidado correctamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.alertaMensaje = {
                contenido:  'Ha ocurrido un error removiendo el paquete Por favor intenta de nuevo',
                tipo: 'error',
              };
              break;
            default:
              this.mostrarMensajeEnCasoDefault();
              break;
          }
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault();
          this.botonDeshabilitado = false;
        });
    },
    consolidadoEditar(consolidado) {
      const consolidadoEnviar = JSON.parse(JSON.stringify(consolidado))
      delete consolidadoEnviar.cantidad
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: consolidadoEditarGql,
        variables: {
          consolidado: consolidadoEnviar,
        },
        update: (store) => {
          const data = store.readQuery({
            query: consolidadoGql,
            variables: {
              id: this.$route.params.id,
            },
          });
          data.Consolidado[0] = JSON.parse(JSON.stringify(consolidado))
          data.Consolidado[0].paquetes = this.consolidado.paquetes;
          data.Consolidado[0].destino = this.consolidado.destino;
          data.Consolidado[0].observacion = consolidado.observacion;
          data.Consolidado[0].cantidad = consolidado.cantidad;
          data.Consolidado[0].pesoVolumetrico = this.consolidado.pesoVolumetrico
            ? this.consolidado.pesoVolumetrico
            : 0;
          data.Consolidado[0].piesCubicos = this.consolidado.piesCubicos
            ? this.consolidado.piesCubicos
            : 0;
          consolidado.peso
            ? data.Consolidado[0].peso = consolidado.peso
            : data.Consolidado[0].peso = this.consolidado.peso
          consolidado.pesoManual
            ? data.Consolidado[0].pesoManual = consolidado.pesoManual
            : data.Consolidado[0].pesoManual = this.consolidado.pesoManual
          consolidado.volumen
            ? data.Consolidado[0].volumen = consolidado.volumen
            : data.Consolidado[0].volumen = this.consolidado.volumen
          data.Consolidado[0].__typename = 'Consolidado';
          data.Consolidado[0].fechaCarga.__typename = '_Neo4jDateTime';
          data.Consolidado[0].fechaArribo.__typename = '_Neo4jDateTime';

          store.writeQuery({
            query: consolidadoGql,
            variables: {
              id: this.$route.params.id,
            },
            data,
          });
          this.mostrarFormularioConsolidado = false;
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido:  'Se ha actualizado el consolidado exitosamente',
            tipo: 'correcto',
          };
        },
        optimisticResponse: {
          __typename: 'Mutation',
            consolidadoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    consolidadoEliminar() {
      // Validaciones
      delete this.consolidado.__typename;
      delete this.consolidado.fechaCarga.__typename;
      this.$apollo.mutate({

        mutation: consolidadoEliminarGql,
        variables: {
          id: this.$route.params.id,
        },
        update: (store) => {
          const data = store.readQuery({
            query: consolidadosGql,
            variables: this.variablesConsolidados
          });
          const id = this.consolidado.id
          data.Consolidado = data.Consolidado.filter((e) => {
            return !id.includes(e.id);
          });

          store.writeQuery({
            query: consolidadosGql,
            variables: this.variablesConsolidados,
            data,
          });
          this.alertaMensaje = {
            contenido:  'Se ha removido el consolidado exitosamente',
            tipo: 'correcto',
          };
          this.mostrarConfirmacionEliminar = false;
          this.botonDeshabilitado = false;
          // Se verifica la ruta, el optimisticResponse se vuelve a llamar cuando se cambia
          // de ruta y ocasionaba error
          if(this.$route.name === 'Consolidados') return
          this.$router.push('/consolidados')
        },
        optimisticResponse: {
          __typename: 'Mutation',
            consolidadoEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
      })
    },
    // Direcciones
    direccionRemover(){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: direccionEliminarGql,
        variables: {
          direccionId: this.IdDireccionAEliminar,
        },
        update: (store, {data: {direccionEliminar}}) => {
          const data = store.readQuery({
              query: consolidadoGql,
              variables: {
                id: this.$route.params.id
              },
          });
          const msg = direccionEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido la dirección exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando la dirección. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Consolidado[0].destino = {
            __typename: 'Direccion',
              id: '',
              direccion: '',
              tasaAerea: '',
              tasaMaritima: '',
              minAerea: '',
              piesCubicos:'',
              pesoVolumetrico:'',
              minMaritima: '',
              ciudad: {
                nombre: '',
                __typename: 'Ciudad',
                estado: {
                  nombre: '',
                __typename: 'Estado',
                  pais: {
                    nombre: '',
                  __typename: 'Pais',
                  }
                }
              }
            };
          store.writeQuery({
            query: consolidadoGql,
              variables: {
                id: this.$route.params.id
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarConfirmacionEliminar = false;
            this.limpiarDatos()
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            direccionEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
  },
  apollo: {
    consolidado() {
      return {
        query: consolidadoGql,
        variables: {
          id: this.$route.params.id,
        },
        update: (data) => data.Consolidado[0],
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.vista-detalles.consolidado {
  .btn-eliminar-flotante{
    bottom: 4.25rem;
    position: fixed;
    right: 1rem;
    transition: transform .5s ease, opacity .5s ease;
    width: 228px;
    z-index: 2;

    @media screen and (min-width: 576px) {
      bottom: .7rem;
      right: 8.35rem;
      width: inherit;
    }
    @media screen and (min-width: 768px) {position: static;}

    &.ocultar {
      opacity: 0;
      transform: translateX(calc(100% + 2rem));
      @media screen and (min-width: 576px) {transform: translateY(calc(100% + 1.5rem));}
      @media screen and (min-width: 768px) {transform: translateX(100%);}
    }
    &.mostrar {
      opacity: 1;
      transform: translateX(0);
      @media screen and (min-width: 576px) {transform: translateY(0);}
      @media screen and (min-width: 768px) {transform: translateX(0);}
    }
  }
  [data-table="sticky"] {max-width: 900px;}
}
</style>
<template>
  <div v-if="mostrarFormulario">
    <div class="row justify-content-center">
      <div
        :class="['col-12 col-lg-5',
          {'campo': validarValores.nombre
            && validarValores.nombre.length},
          {'valido': validarValores.nombre == 'valido'},
          {'invalido': validarValores.nombre == 'invalido'},
        ]"
      >
        <mdb-input
          v-model.trim="cliente.persona.nombre"
          type="text"
          label="Nombre"
          class="my-2"
          outline
        />
        <p
          v-if="validarValores.nombre == 'invalido'"
          class="mensaje-invalido"
        >
          Indica el nombre
        </p>
      </div>
      <div :class="['col-12 col-sm-6 col-lg-4',
          {'campo': validarValores.identificacion
            && validarValores.identificacion.length},
          {'valido': validarValores.identificacion == 'valido'},
          {'invalido': validarValores.identificacion == 'invalido'},
        ]"
      >
        <mdb-input
          v-model.trim="cliente.persona.identificacion"
          label="Número de ID"
          class="my-2"
          outline
        />
          <p
          v-if="validarValores.identificacion == 'invalido'"
          class="mensaje-invalido"
        >
          Indica el número de identificación
        </p>
      </div>
      <div
        :class="['col-12 col-sm-6 col-lg-3',
          {'campo': validarValores.genero
            && validarValores.genero.length},
          {'valido': validarValores.genero == 'valido'},
          {'invalido': validarValores.genero == 'invalido'},
        ]"
      >
        <div class="md-form md-outline outline-select my-2">
          <select
            id="genero-select"
            v-model="cliente.persona.genero"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(value, key) in ClienteGeneros"
              :key="value+'genero'"
              :value="key"
            >
            {{ value }}
            </option>
          </select>
          <label
            for="genero-select"
            :class="cliente.tipo ? 'label-active' : 'label-inactive'"
          >
            Género
          </label>
        </div>
        <p
          v-if="validarValores.genero == 'invalido'"
          class="mensaje-invalido"
        >
          Selecciona el género
        </p>
      </div>
      <template v-if="!esEditar">
        <!-- CLAVE -->
        <div
          :class="['col-12 col-sm-6',
            {'campo': validarValores.claveTemporal
              && validarValores.claveTemporal.length},
            {'valido': validarValores.claveTemporal == 'valido'},
            {'invalido': validarValores.claveTemporal == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="cliente.persona.claveTemporal"
            :type="mostrarClave ? 'text' : 'password'"
            minlength="8"
            label="Clave"
            class="my-2"
            outline
          >
            <mdb-btn
              slot="append"
              group
              flat
              dark-waves
              size="md"
              :icon="mostrarClave ? 'eye-slash' : 'eye'"
              :title="mostrarClave ? 'Ocultar clave' : 'Mostrar clave'"
              @click="mostrarClave = !mostrarClave"
            />
          </mdb-input>
          <p
            v-if="validarValores.claveTemporal == 'invalido'"
            class="mensaje-invalido"
          >
            La clave es requerida
          </p>
        </div>
        <!-- Repetir clave -->
        <div
          :class="['col-12 col-sm-6',
            {'campo': validarValores.confirmarClave
              && validarValores.confirmarClave.length},
            {'valido': validarValores.confirmarClave == 'valido'},
            {'invalido': validarValores.confirmarClave == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="cliente.persona.confirmarClave"
            :type="mostrarClave ? 'text' : 'password'"
            minlength="8"
            label="Repetir clave"
            class="my-2"
            outline
          >
            <mdb-btn
              slot="append"
              group
              flat
              dark-waves
              size="md"
              :icon="mostrarClave ? 'eye-slash' : 'eye'"
              :title="mostrarClave ? 'Ocultar clave' : 'Mostrar clave'"
              @click="mostrarClave = !mostrarClave"
            />
          </mdb-input>
          <p
            v-if="validarValores.confirmarClave == 'invalido'"
            class="mensaje-invalido"
          >
            La confirmación de la clave es requerida
          </p>
        </div>
      </template>
      <!-- Agencia -->
      <div
        :class="['col-12 col-sm-6 col-lg-4',
          {'campo': validarValores.agencia
            && validarValores.agencia.length},
          {'valido': validarValores.agencia == 'valido'},
          {'invalido': validarValores.agencia == 'invalido'},
        ]"
      >
        <div class="md-form md-outline outline-select my-2">
          <select
            id="agencia-select"
            v-model="agenciaSeleccionada"
            class="custom-select"
          >
            <option class="d-none" disabled :value="{}">
              Seleccione
            </option>
            <option
              v-for="(value, key) in agencias"
              :key="key+'agencia'"
              :value="value"
            >
            {{ value.nombre }}
            </option>
          </select>
          <label
            for="agencia-select"
            :class="cliente.agenciaId && cliente.agenciaId.length
              ? 'label-active' : 'label-inactive'"
          >
            Agencia
          </label>
        </div>
        <p
          v-if="validarValores.agencia == 'invalido'"
          class="mensaje-invalido"
        >
          Selecciona la agencia
        </p>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <mdb-input
          v-model.trim="cliente.persona.casillero"
          type="text"
          label="Casillero"
          class="my-2"
          outline
          read-only
        />
      </div>
      <div class="col-12 col-lg-4 text-center">
        <label class="opciones-checkbox right py-3">
          <input
            v-model="cliente.persona.credito"
            type="checkbox"
            name="checkbox-credito"
          />
          <span class="icono-checkbox" />
          ¿Crédito admitido?
        </label>
      </div>
    </div>
    <div v-if="!esEditar">
      <header class="mt-2 font-weight-bold">
        Datos de contacto
      </header>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.telefono
                  && validarValores.telefono.length},
                {'valido': validarValores.telefono == 'valido'},
                {'invalido': validarValores.telefono == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.number="telefono"
                id="input-telefono"
                type="tel"
                label="Teléfono"
                placeholder="Ej: 58426573828"
                minlength="7"
                maxlength="13"
                class="my-2 plus-addon"
                outline
                @keyup.enter.native="validarTelefono(telefono)"
              >
                <span class="input-group-text md-addon" slot="prepend">+</span>
              </mdb-input>
              <p
                v-if="validarValores.telefono == 'invalido'"
                class="mensaje-invalido prepend"
              >
                Indica el teléfono
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-campo-btn mr-3 px-2"
              title="Agregar teléfono"
              @click="validarTelefono(telefono)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-for="(tel, i) in cliente.persona.telefonos"
            :key="'Teléfono'+i"
            class="chip delete-btn"
          >
            +{{tel}}
            <mdb-btn
              class="m-0 ml-2 p-1 pb-2"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar teléfono"
              @click="telefonoRemover(i)"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.email
                  && validarValores.email.length},
                {'valido': validarValores.email == 'valido'},
                {'invalido': validarValores.email == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.trim="email"
                id="input-email"
                type="email"
                label="Email"
                placeholder="Ej: ejemplo@mail.com"
                class="my-2"
                outline
                @keyup.enter.native="validarEmail(email)"
              />
              <p
                v-if="validarValores.email == 'invalido'"
                class="mensaje-invalido"
              >
                Indica el email
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-campo-btn mr-3 px-2"
              title="Agregar email"
              @click="validarEmail(email)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-for="(email, i) in cliente.persona.emails"
            :key="'email'+i"
            class="chip delete-btn"
          >
            {{email}}
            <mdb-btn
              class="m-0 ml-2 p-1 pb-2"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar email"
              @click="emailRemover(i)"
            />
          </div>
        </div>
      </div>
      <header class="mt-2 font-weight-bold">Datos de dirección</header>
      <div class="row">
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.estado
              && validarValores.estado.length},
            {'valido': validarValores.estado == 'valido'},
            {'invalido': validarValores.estado == 'invalido'},
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              v-model="cliente.direccion.estado"
              id="estado-select"
              class="custom-select"
            >
            <option class="d-none" disabled value="">
                Seleccione
              </option>
              <option
                v-for="(value, key) in estados"
                :key="key+'estado'"
                :value="value.nombre"
              >
              {{ value.nombre }}
              </option>
            </select>
            <label
              for="estado-select"
              :class="cliente.direccion.estado ? 'label-active' : 'label-inactive'"
            >
              Estado
            </label>
          </div>
          <p
            v-if="validarValores.estado == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona el estado
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.ciudad
              && validarValores.ciudad.length},
            {'valido': validarValores.ciudad == 'valido'},
            {'invalido': validarValores.ciudad == 'invalido'},
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              v-model="cliente.direccion.ciudad"
              id="ciudad-select"
              class="custom-select"
            >
              <option class="d-none" disabled value="">
                Seleccione
              </option>
              <option
                v-for="(value, key) in ciudades"
                :key="key+'ciudad'"
                :value="value.nombre"
              >
              {{ value.nombre }}
              </option>
            </select>
            <label
              for="ciudad-select"
              :class="cliente.direccion.ciudad ? 'label-active' : 'label-inactive'"
            >
              Ciudad
            </label>
          </div>
          <p
            v-if="validarValores.ciudad == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona la ciudad
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.direccion
              && validarValores.direccion.length},
            {'valido': validarValores.direccion == 'valido'},
            {'invalido': validarValores.direccion == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="cliente.direccion.direccion"
            type="textarea"
            label="Dirección"
            :rows="2"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.direccion == 'invalido'"
            class="mensaje-invalido textarea"
          >
            Indica la dirección
          </p>
        </div>
        <div class="col-12 col-md-6">
          <mdb-input
            v-model.trim="cliente.direccion.observacion"
            type="textarea"
            label="Observación de envío"
            :rows="2"
            class="my-2"
            outline
          />
        </div>
      </div>
      <header class="mt-2 font-weight-bold">Recibe</header>
      <div class="row">
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.rnombre
              && validarValores.rnombre.length},
            {'valido': validarValores.rnombre == 'valido'},
            {'invalido': validarValores.rnombre == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="cliente.direccion.recibe.nombre"
            type="text"
            label="Nombre"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.rnombre == 'invalido'"
            class="mensaje-invalido"
          >
            Indica el nombre
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.ridentificacion
              && validarValores.ridentificacion.length},
            {'valido': validarValores.ridentificacion == 'valido'},
            {'invalido': validarValores.ridentificacion == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="cliente.direccion.recibe.identificacion"
            label="Número de ID"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.ridentificacion == 'invalido'"
            class="mensaje-invalido"
          >
            Indica la identificación
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.rtelefono
                  && validarValores.rtelefono.length},
                {'valido': validarValores.rtelefono == 'valido'},
                {'invalido': validarValores.rtelefono == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.number="rtelefono"
                id="input-rtelefono"
                type="tel"
                label="Teléfono"
                placeholder="Ej: 58426573828"
                minlength="7"
                maxlength="13"
                class="my-2 plus-addon"
                outline
                @keyup.enter.native="validarTelefono(rtelefono, true)"
              >
                <span class="input-group-text md-addon" slot="prepend">+</span>
              </mdb-input>
              <p
                v-if="validarValores.rtelefono == 'invalido'"
                class="mensaje-invalido prepend"
              >
                Indica el teléfono
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-campo-btn mr-3 px-2"
              title="Agregar teléfono"
              @click="validarTelefono(rtelefono, true)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-for="(tel, i) in cliente.direccion.recibe.telefonos"
            :key="'Rteléfono'+i"
            class="chip delete-btn"
          >
            +{{tel}}
            <mdb-btn
              class="m-0 ml-2 p-1 pb-2"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar teléfono"
              @click="rtelefonoRemover(i)"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.remail
                  && validarValores.remail.length},
                {'valido': validarValores.remail == 'valido'},
                {'invalido': validarValores.remail == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.trim="remail"
                id="input-remail"
                type="email"
                label="Email"
                placeholder="Ej: ejemplo@mail.com"
                class="my-2"
                outline
                @keyup.enter.native="validarEmail(remail, true)"
              />
              <p
                v-if="validarValores.remail == 'invalido'"
                class="mensaje-invalido"
              >
                Indica el email
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-campo-btn mr-3 px-2"
              title="Agregar email"
              @click="validarEmail(remail, true)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-for="(email, i) in cliente.direccion.recibe.emails"
            :key="'Remail'+i"
            class="chip delete-btn"
          >
            {{email}}
            <mdb-btn
              class="m-0 ml-2 p-1 pb-2"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar email"
              @click="remailRemover(i)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-around">
      <mdb-btn
        flat
        dark-waves
        icon="times"
        class="col-auto btn-bordeado mt-3 px-3"
        @click="$emit('cerrar', false)"
      >
        Cancelar
      </mdb-btn>
      <mdb-btn
        color="primario"
        :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        class="col-auto mt-3 px-3"
        :disabled="botonDeshabilitado"
        @click="validarCliente"
      >
        {{ botonPrincipal.texto }}
      </mdb-btn>
    </div>
  </div>
</template>
<script>
import {
  mdbBtn,
  mdbInput,
} from "mdbvue";
import gql from "graphql-tag";
import clienteGql from "@/graphql/cliente.gql";
import clienteCrearGql from "@/graphql/clienteCrear.gql";
import clienteEditarGql from "@/graphql/clienteEditar.gql";
import agenciasGql from "@/graphql/agencias.gql";
import estadosGql from "@/graphql/estados.gql";
import ciudadesGql from "@/graphql/ciudades.gql";
import clientesGql from "@/graphql/clientes.gql";
import existeUsuarioGql from "@/graphql/existeUsuario.gql"
import { ClienteGeneros } from "@/constantes/clientes.js"
import { leerRoles } from "@/utils/datosToken.js";
import {
  capitalizar,
  eliminarVacios,
  validarTel,
  validarMail,
  evitarElementosRepetidos,
} from "@/funciones/funciones.js"

export default {
  name:'NuevoCliente',
  components: {
    mdbBtn,
    mdbInput,
  },
  props: {
    mostrarFormulario: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    datosCliente: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  data(){
    return {
      roles:leerRoles(),
      botonDeshabilitado: false,
      mostrarClave:false,
      telefono: '',
      email: '',
      rtelefono: '',
      remail: '',
      ClienteGeneros,
      validarValores: {},
      paises: [],
      estados: [],
      ciudades: [],
      clienteId: '',
      agenciaSeleccionada: {},
      agenciaOriginal: {},
      casilleroOriginal: '',
      cliente: {
        persona: {
          nombre: '',
          identificacion: '',
          genero: '',
          casillero: '',
          telefonos: [],
          emails: [],
          credito: false,
        },
        agenciaId: '',
        direccion: {
          direccion: '',
          observacion: '',
          ciudad: '',
          estado: '',
          recibe:{
            nombre: '',
            identificacion: '',
            telefonos: [],
            emails: [],
          },
        },
        telefonos: [],
        emails: [],
      },
    }
  },
  mounted(){
    if(this.datosCliente && this.esEditar) {
        this.clienteAsignarDatosAnteriores();
      }
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Registrar'};
    },
    // Ordenar por fecha descendente los paquetes del cliente
    clientesAscendente: function() {
      if(this.clientes){
        const clientes = JSON.parse(JSON.stringify(this.clientes))
        return clientes.sort((a, b) => new Date(b.creadoEl.formatted) - new Date(a.creadoEl.formatted))
      } return []
    },
  },
  watch: {
    mostrarFormulario: function() {
      if(!this.mostrarFormulario) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
        if(this.esEditar){
          this.clienteAsignarDatosAnteriores();
        }
      }
    },
    agenciaSeleccionada: function() {
      if(this.agenciaSeleccionada && this.agenciaSeleccionada.id) {
        // Validar que al editar un cliente y seleccionar la agencia original, no se cambie el casillero
        if(this.esEditar && this.agenciaSeleccionada.id === this.agenciaOriginal.id) {
          this.cliente.agenciaId = this.agenciaSeleccionada.id
          return this.cliente.persona.casillero = this.casilleroOriginal
        }
        this.alertaMensaje(
          "Se ha seleccionado una nueva agencia, el casillero del cliente se actualizará",
          "advertencia"
        );
        this.cliente.agenciaId = this.agenciaSeleccionada.id
        // Asignación automática de casillero
        // const casilleroPrefijo = this.agenciaSeleccionada.codigo
        const casilleroPrefijo = this.agenciaSeleccionada.prefijo
        let casilleroAnterior
        // Fix: skip a ultimos clientes registrados desde repo "client" sin casillero

        this.clientesAscendente.forEach(c => {
          if(c && c.casillero && !casilleroAnterior){
            casilleroAnterior = c.casillero
            return
          }
        });
        if(casilleroAnterior && casilleroAnterior.length){
          const casilleroNumeroAnterior = casilleroAnterior.replace(/[^\d]/g, '')
          if(casilleroNumeroAnterior && casilleroNumeroAnterior.length){
            let casilleroNumeroNuevo = Number(casilleroNumeroAnterior) + 1
            // Validar si se encuentra rapetido el casillero y si es así se suma uno
            this.clientes.forEach((c) => {
              if(c.casillero && c.casillero.includes(String(casilleroNumeroNuevo))){
                casilleroNumeroNuevo+= 1
              }
            })
            this.cliente.persona.casillero = casilleroPrefijo+String(casilleroNumeroNuevo)
          }
        }
      }
    },
  },
  methods: {
    clienteAsignarDatosAnteriores() {
      // Aplicar formato al cliente a editar
      const cliente = JSON.parse(JSON.stringify(this.datosCliente));
      const clienteConFormato = {};
      this.clienteId = this.datosCliente.id;

      clienteConFormato.persona = {
        nombre: cliente.nombre,
        identificacion: cliente.identificacion,
        credito: cliente.credito,
        genero: cliente.genero,
        casillero: cliente.casillero,
        telefonos: cliente.telefonos,
        emails: cliente.telefonos,
      };
      if (cliente.agencia) {
        this.agenciaSeleccionada = cliente.agencia
        this.agenciaOriginal = JSON.parse(JSON.stringify(cliente.agencia))
        this.casilleroOriginal = JSON.parse(JSON.stringify(cliente.casillero))
      }

      Object.assign(this.cliente, clienteConFormato);
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    telefonoRemover(index){
      this.cliente.persona.telefonos.splice(index, 1)
    },
    emailRemover(index){
      this.cliente.persona.emails.splice(index, 1)
    },
    rtelefonoRemover(index){
      this.cliente.direccion.recibe.telefonos.splice(index, 1)
    },
    remailRemover(index){
      this.cliente.direccion.recibe.emails.splice(index, 1)
    },

    validarTelefono(telefono, recibe, skip){
      if(!telefono || !validarTel(telefono)) {
        this.alertaMensaje('Teléfono no válido',
          'error')
        return false
      }else {
         if(skip){
           return true
         }
        if(recibe){
          evitarElementosRepetidos(`${telefono}`, this.cliente.direccion.recibe.telefonos)
            ? this.cliente.direccion.recibe.telefonos.push(`${telefono}`)
            : this.alertaMensaje(
                "Este teléfono ya se encuentra agregado",
                "advertencia"
              );
          return this.rtelefono = '';
        }
        evitarElementosRepetidos(`${telefono}`, this.cliente.persona.telefonos)
          ? this.cliente.persona.telefonos.push(`${telefono}`)
          : this.alertaMensaje(
              "Este teléfono ya se encuentra agregado",
              "advertencia"
            );
        return this.telefono = '';
      }
    },
    async validarEmail(email, recibe, skip){
      try{
        this.botonDeshabilitado = true;
        // Retorna true si se encuentra ya registrado anteriormente el email
        const res = await this.$apollo
          .query({
              query: gql`
                ${existeUsuarioGql}
              `,
              variables: {
                usuario: email
              },
              update: (data) => data.existeUsuario,
              fetchPolicy: "no-cache",
            })
          const codigo = res.data.existeUsuario.codigo;
          this.botonDeshabilitado = false;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje('El email especificado ya se encuentra asignado a otro usuario.'+
                ' Por favor comprueba tus datos', 'advertencia');
              return false
            case 'Fallido':
              this.validarEmailFormat(email, recibe, skip)
              return true
            default:
              this.alertaMensaje('Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
                'error')
              return false
          }
      } catch {
          this.botonDeshabilitado = false;
          this.alertaMensaje('Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
            'error')
          return false
      }
    },
    validarEmailFormat(email, recibe, skip){
       if(!email || !validarMail(email)){
        this.alertaMensaje('Email no válido',
          'error')
        return false
       }else {
         if(skip){
           return true
         }
        if(recibe){
          this.cliente.direccion.recibe.emails.push(email)
          return this.remail = '';
        }
        this.cliente.persona.emails.push(email)
        return this.email = '';
      }
    },
    async validarCliente(){
      let sinReceptor = false;
      this.botonDeshabilitado = true;
      this.validarValores = {
        // Datos de la persona
        nombre: this.cliente.persona.nombre ? "valido" : "invalido",
        genero: this.cliente.persona.genero ? "valido" : "invalido",
        casillero: this.cliente.persona.casillero ? "valido" : "invalido",
        agencia: this.cliente.agenciaId ? "valido" : "invalido",
        identificacion: this.cliente.persona.identificacion ? "valido" : "invalido",
      };

      if(!this.esEditar){
        Object.assign(this.validarValores, {
          telefono: parseFloat(this.telefono)
            || this.cliente.persona.telefonos.length ? "valido" : "invalido",
          email:  this.email
            || this.cliente.persona.emails.length ? "valido" : "invalido",
        claveTemporal: this.cliente.persona.claveTemporal ? "valido" : "invalido",
        confirmarClave: this.cliente.persona.confirmarClave ? "valido" : "invalido",
        });
        }

      if(this.telefono){
        if(!this.validarTelefono(this.telefono, '', true)){
          this.botonDeshabilitado = false;
          return Object.assign(this.validarValores, {
             telefono: "invalido",
          })
        }
        if(evitarElementosRepetidos(`${this.telefono}`, this.cliente.persona.telefonos)){
          this.cliente.persona.telefonos.push(`${this.telefono}`)
        } else {
          this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            telefono: "invalido",
          })
          return this.alertaMensaje(
            "Este teléfono ya se encuentra agregado",
            "advertencia"
          );
        }
        this.telefono = "";
      }

      if(this.email){
        const resultValidation = await this.validarEmail(this.email, '', true)
        if(!resultValidation){
          this.botonDeshabilitado = false;
          return Object.assign(this.validarValores, {
             email: "invalido",
          })
        }
        this.cliente.persona.emails.push(this.email)
        this.email = "";
      }
      if(this.cliente.persona.claveTemporal){
        if(this.cliente.persona.claveTemporal.length <= 5){
          this.validarValores.claveTemporal = 'invalido';
          this.validarValores.confirmarClave = 'invalido';
          this.alertaMensaje('La longitud de la clave debe ser minimo de 6 caracteres', 'error');
          return this.botonDeshabilitado = false;
        }
        if(this.cliente.persona.claveTemporal !== this.cliente.persona.confirmarClave){
          this.validarValores.confirmarClave = 'invalido';
          this.alertaMensaje('Las claves deben ser iguales', 'error');
          return this.botonDeshabilitado = false;
        }
      }
      // Si se ingresa un dato de un receptor, se verifica que se introduzca la dirección de envío y los datos del receptor
      if(this.cliente.direccion.recibe.nombre || this.cliente.direccion.recibe.genero
        || this.cliente.direccion.recibe.identificacion || this.cliente.direccion.recibe.telefonos.length
        || this.cliente.direccion.recibe.emails.length) {
          Object.assign(this.validarValores, {
            // Datos de dirección
            estado: this.cliente.direccion.estado ? "valido" : "invalido",
            ciudad: this.cliente.direccion.ciudad ? "valido" : "invalido",
            direccion: this.cliente.direccion.direccion ? "valido" : "invalido",
            // Datos del receptor
            rnombre: this.cliente.direccion.recibe.nombre ? "valido" : "invalido",
            ridentificacion: parseFloat(this.cliente.direccion.recibe.identificacion) ? "valido" : "invalido",
            rtelefono: parseFloat(this.rtelefono)
              || this.cliente.direccion.recibe.telefonos.length ? "valido" : "invalido",
            remail: this.remail
              || this.cliente.direccion.recibe.emails.length ? "valido" : "invalido",
          })

          if(this.rtelefono){
            if(!this.validarTelefono(this.rtelefono, '', true)){
              this.botonDeshabilitado = false;
              return Object.assign(this.validarValores, {
                rtelefono: "invalido",
              })
            }
            if(evitarElementosRepetidos(`${this.rtelefono}`, this.cliente.direccion.recibe.telefonos)){
              this.cliente.direccion.recibe.telefonos.push(`${this.rtelefono}`)
            } else {
              this.botonDeshabilitado = false;
              Object.assign(this.validarValores, {
                rtelefono: "invalido",
              })
              return this.alertaMensaje(
                "Este teléfono ya se encuentra agregado",
                "advertencia"
              );
            }
            this.rtelefono = "";
          }

          if(this.remail){
            const resultValidation = await this.validarEmail(this.remail, '', true)
            if(!resultValidation){
              this.botonDeshabilitado = false;
              return Object.assign(this.validarValores, {
                remail: "invalido",
              })
            }
            this.cliente.direccion.recibe.emails.push(this.remail)
            this.remail = "";
          }
      } else {
          sinReceptor = true;
      }

      if(this.cliente.direccion && (this.cliente.direccion.estado || this.cliente.direccion.ciudad
        || this.cliente.direccion.direccion || this.cliente.direccion.observacion)){
        Object.assign(this.validarValores, {
          estado: this.cliente.direccion.estado ? "valido" : "invalido",
          ciudad: this.cliente.direccion.ciudad ? "valido" : "invalido",
          direccion: this.cliente.direccion.direccion ? "valido" : "invalido",
        })
      }
       // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Llena los campos requeridos', 'error');
        return this.botonDeshabilitado = false;
      }

      // Aplica formato Capitalize al nombre del cliente y nombre del receptor
      this.cliente.persona.nombre = capitalizar(this.cliente.persona.nombre);
      this.cliente.direccion.recibe.nombre ?
        this.cliente.direccion.recibe.nombre = capitalizar(this.cliente.direccion.recibe.nombre)
        : ''

      // Los emails de cliente serán los mismo que los de la persona (provisionalmente)
      this.cliente.emails = this.cliente.persona.emails;
      this.cliente.telefonos = this.cliente.persona.telefonos;
       
      const cliente = JSON.parse(JSON.stringify(this.cliente));
      delete cliente.direccion.estado

      // Eliminar campos vacios
      const clienteSinCamposVacios = eliminarVacios(cliente)
      if(sinReceptor){
        delete clienteSinCamposVacios.direccion.recibe
        if(clienteSinCamposVacios.direccion && !Object.entries(clienteSinCamposVacios.direccion).length){
          delete clienteSinCamposVacios.direccion
        }
      }
      // Evita duplicado de datos (compatibilidad con API)
      delete clienteSinCamposVacios.persona.telefonos
      delete clienteSinCamposVacios.persona.emails
      if(this.esEditar){
        return this.clienteEditar(clienteSinCamposVacios)
      }
      delete clienteSinCamposVacios.persona.confirmarClave
      this.clienteCrear(clienteSinCamposVacios);
    },
    clienteCrear(cliente){
      if(!(this.roles.includes('Admin') || this.roles.includes('Agencia'))){
        this.alertaMensaje('No estás autorizado para crear clientes, esta acción está permitida solo para Administradores y Agencias',
        'error')
        this.botonDeshabilitado = false;
        return
      }
      cliente.persona.acceso = true;
      this.$apollo
          .mutate({
            mutation: gql`${clienteCrearGql}`,
            variables: cliente
          })
          .then(({data: {clienteCrear}}) => {
            // Aplicar formato al cliente a enviar
            const clienteConFormato = {}
            // Dar formato a los teléfonos y emails
            cliente.persona.telefonos = cliente.telefonos.map((x => ({numero: x})));
            if(cliente.direccion && cliente.direccion.recibe) {
              cliente.direccion.recibe.telefonos =
                cliente.direccion.recibe.telefonos.map((x => ({numero: x})))
              cliente.direccion.recibe.emails =
                cliente.direccion.recibe.emails.map((x => ({email: x})))
            }

            clienteConFormato.id = clienteCrear.id;
            clienteConFormato.creadoEl = {
              formatted: 'Hoy',
              __typename: '_Neo4jDateTime',
            }
            Object.assign(clienteConFormato, cliente.persona)
            cliente.direccion
              ? clienteConFormato.direccion = cliente.direccion
              : ''

            this.limpiarCampos();
            this.$emit('creado', JSON.parse(JSON.stringify(clienteConFormato)))
            this.alertaMensaje('Cliente creado correctamente',
             'correcto')
            this.$emit('cerrar', false);
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    clienteEditar(cliente){
      const clienteAEditar = {persona:{}};
      // Se adapta cliente al input schema de la mutate clienteEditar
      clienteAEditar.persona.id =  this.clienteId;
      clienteAEditar.persona.nombre = cliente.persona.nombre;
      clienteAEditar.persona.identificacion = cliente.persona.identificacion;
      clienteAEditar.persona.casillero = cliente.persona.casillero;
      clienteAEditar.persona.genero = cliente.persona.genero;
      cliente.persona.credito
        ? clienteAEditar.persona.credito = cliente.persona.credito
        : '';
      clienteAEditar.agenciaId = cliente.agenciaId
      this.$apollo.mutate({
        mutation: clienteEditarGql,
        variables: clienteAEditar,
        update: (store, {data: {clienteEditar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = clienteEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje('Se ha modificado el cliente con exito.', 'correcto');
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
                'error');
            default:
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error');
          }
          // Aplicar formato al cliente a enviar
          Object.assign(data.Cliente[0], cliente.persona)
          data.Cliente[0].agencia = this.agenciaSeleccionada

          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.alertaMensaje('Se ha modificado el cliente con exito.', 'correcto');
            this.limpiarCampos();
            this.$emit('editado', JSON.parse(JSON.stringify(data.Cliente[0])))
            this.$emit('cerrar', false);
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            clienteEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
      this.alertaMensaje('Ha ocurrido un error inesperado', 'error');
        this.botonDeshabilitado = false
      })
    },
    limpiarCampos() {
      this.cliente = {
        nombre:'',
        identificacion:'',
        casillero: '',
        persona: {
          nombre: '',
          identificacion: '',
          genero: '',
          casillero: '',
          telefonos: [],
          emails: [],
          credito: false,
        },
        agenciaId: '',
        direccion: {
          direccion: '',
          observacion: '',
          ciudad: '',
          estado: '',
          recibe:{
            nombre: '',
            identificacion: '',
            telefonos: [],
            emails: [],
          },
        },
        telefonos: [],
        emails: [],
      },
      this.validarValores = {};
      this.clienteId = '';
      this.telefono = '';
      this.email = '';
      this.rtelefono = '';
      this.remail = '';
    },
  },
  apollo: {
    clientes() {
      return {
        query: clientesGql,
        update: (data) => data.Cliente,
        fetchPolicy: "cache-and-network",
      };
    },
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        fetchPolicy: "cache-and-network",
      };
    },
    estados() {
      return {
        query: estadosGql,
        update: (data) => data.Estado,
        fetchPolicy: "cache-and-network",
      };
    },
    ciudades() {
      return {
        query: ciudadesGql,
        variables: () => {
          return {
            filter: {
              estado: {
                nombre: this.cliente.direccion.estado,
              },
            }
          }
        },
        skip() { return this.cliente.direccion && this.cliente.direccion.estado ? false : true},
        update: (data) => data.Ciudad,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1rem;

  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
.mt-campo-btn {margin-top: 10px;}
</style>
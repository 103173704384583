var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","size":"lg","show":_vm.mostrarModal},on:{"close":function($event){_vm.$emit('cerrar', false);
      _vm.limpiarCampos();}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.tituloModal)+" ")])],1),_c('mdb-modal-body',[(!_vm.esCambioDeClave)?_c('section',[_c('div',{staticClass:"row align-items-center"},[_c('div',{class:['col-12 col-md-6',
              {'campo': _vm.validarValores.nombre
                && _vm.validarValores.nombre.length},
              {'valido': _vm.validarValores.nombre == 'valido'},
              {'invalido': _vm.validarValores.nombre == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.usuario.persona.nombre),callback:function ($$v) {_vm.$set(_vm.usuario.persona, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"usuario.persona.nombre"}}),(_vm.validarValores.nombre == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el nombre ")]):_vm._e()],1),_c('div',{class:['col-12 col-md-6',
              {'campo': _vm.validarValores.identificacion
                && _vm.validarValores.identificacion.length},
              {'valido': _vm.validarValores.identificacion == 'valido'},
              {'invalido': _vm.validarValores.identificacion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Identificación","outline":""},model:{value:(_vm.usuario.persona.identificacion),callback:function ($$v) {_vm.$set(_vm.usuario.persona, "identificacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"usuario.persona.identificacion"}}),(_vm.validarValores.identificacion == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el identificación ")]):_vm._e()],1),_c('div',{class:['col-12 col-md-6',
              {'campo': _vm.validarValores.genero
                && _vm.validarValores.genero.length},
              {'valido': _vm.validarValores.genero == 'valido'},
              {'invalido': _vm.validarValores.genero == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.usuario.persona.genero),expression:"usuario.persona.genero"}],staticClass:"custom-select",attrs:{"id":"genero-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.usuario.persona, "genero", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.UsuarioGeneros),function(value,key){return _c('option',{key:value+'genero',domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('label',{class:_vm.usuario.genero ? 'label-active' : 'label-inactive',attrs:{"for":"genero-select"}},[_vm._v(" Género ")])]),(_vm.validarValores.genero == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el género ")]):_vm._e()]),_c('div',{staticClass:"col-12 col-md-6"},[_c('small',{staticClass:"text-muted user-select-none d-block"},[_vm._v(" Usuario ")]),(_vm.esEditar)?_c('span',{class:{'font-italic text-muted user-select-none': !(_vm.usuario.persona.usuario
                && _vm.usuario.persona.usuario.length)}},[_vm._v(" "+_vm._s(_vm.usuario.persona.usuario && _vm.usuario.persona.usuario.length ? _vm.usuario.persona.usuario : 'No hay un email registrado para asignar el usuario')+" ")]):_c('span',{class:{'font-italic text-muted user-select-none': !(_vm.usuario.persona.emails[0]
                && _vm.usuario.persona.emails[0].length)}},[_vm._v(" "+_vm._s(_vm.usuario.persona.emails[0] && _vm.usuario.persona.emails[0].length ? _vm.usuario.persona.emails[0] : 'Agrega un email para asignar el usuario')+" ")])]),_c('div',{staticClass:"col-12 col-md-6 py-2"},[_c('label',{staticClass:"opciones-checkbox right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usuario.persona.credito),expression:"usuario.persona.credito"}],attrs:{"type":"checkbox","name":"checkbox-credito"},domProps:{"checked":Array.isArray(_vm.usuario.persona.credito)?_vm._i(_vm.usuario.persona.credito,null)>-1:(_vm.usuario.persona.credito)},on:{"change":function($event){var $$a=_vm.usuario.persona.credito,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usuario.persona, "credito", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usuario.persona, "credito", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usuario.persona, "credito", $$c)}}}}),_c('span',{staticClass:"icono-checkbox"}),_vm._v(" ¿Crédito admitido? ")])])]),_c('div',{staticClass:"row mt-2"},[(!_vm.esEditar)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
                  {'campo': _vm.validarValores.telefono
                    && _vm.validarValores.telefono.length},
                  {'valido': _vm.validarValores.telefono == 'valido'},
                  {'invalido': _vm.validarValores.telefono == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2 plus-addon",attrs:{"id":"input-telefono","type":"tel","label":"Teléfono","placeholder":"Ej: 58426573828","minlength":"7","maxlength":"13","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarTelefono(_vm.telefono)}},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=_vm._n($$v)},expression:"telefono"}},[_c('span',{staticClass:"input-group-text md-addon",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("+")])]),(_vm.validarValores.telefono == 'invalido')?_c('p',{staticClass:"mensaje-invalido prepend"},[_vm._v(" Indica el teléfono ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-2 mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar teléfono"},on:{"click":function($event){return _vm.validarTelefono(_vm.telefono)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.usuario.persona.telefonos),function(tel,i){return _c('div',{key:'teléfono'+i,staticClass:"chip delete-btn"},[_vm._v(" +"+_vm._s(tel)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar teléfono"},on:{"click":function($event){return _vm.telefonoRemover(i)}}})],1)})],2):_vm._e(),(!_vm.esEditar)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
                  {'campo': _vm.validarValores.email
                    && _vm.validarValores.email.length},
                  {'valido': _vm.validarValores.email == 'valido'},
                  {'invalido': _vm.validarValores.email == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"id":"input-email","type":"email","label":"Email","placeholder":"Ej: ejemplo@mail.com","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarEmail(_vm.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),(_vm.validarValores.email == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el email ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar email"},on:{"click":function($event){return _vm.validarEmail(_vm.email)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.usuario.persona.emails),function(email,i){return _c('div',{key:'email'+i,staticClass:"chip delete-btn"},[_vm._v(" "+_vm._s(email)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar email"},on:{"click":function($event){return _vm.emailRemover(i)}}})],1)})],2):_vm._e(),_c('transition',{attrs:{"mode":"out-in","name":"cambio-input-transicion"}},[(_vm.mostrarCampoAgencia)?_c('div',{key:"mostrarCampoAgencia",staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3 pr-md-1 pr-lg-3',
                    {'campo': _vm.validarValores.agencia
                      && _vm.validarValores.agencia.length},
                    {'valido': _vm.validarValores.agencia == 'valido'},
                    {'invalido': _vm.validarValores.agencia == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.agencia),expression:"agencia"}],staticClass:"custom-select",attrs:{"id":"agencia-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.agencia=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.agencias),function(value,key){return _c('option',{key:key+'agencia',domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.usuario.agenciaId && _vm.usuario.agenciaId.length
                        ? 'label-active' : 'label-inactive',attrs:{"for":"agencia-select"}},[_vm._v(" Agencia ")])]),(_vm.validarValores.agencia == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona la agencia ")]):_vm._e()]),(_vm.rol ==='Agencia' || _vm.usuario.roles.includes('Agencia') || _vm.mostrarCampoAgencia )?_c('mdb-btn',{staticClass:"col-auto mt-2 mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Añadir agencia"},on:{"click":function($event){return _vm.agregarAgencia(_vm.agencia)}}},[_vm._v(" Añadir ")]):_vm._e()],1),(_vm.usuario.agenciaId && _vm.usuario.agenciaId.length)?_c('div',{class:['pt-1', {'animated fadeIn': _vm.usuario.agenciaId.length}]},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(_vm.usuario.agenciaId.length > 1 ? 'Agencias seleccionadas' : 'Agencia seleccionada')+" :")]),_vm._l((_vm.usuario.agenciaId),function(Agencia,i){return _c('div',{key:'agencias'+i,staticClass:"chip delete-btn color-terciario"},[_vm._v(" "+_vm._s(_vm.mostrarAgenciaNombre(Agencia))+" "),_c('mdb-btn',{staticClass:"m-0 ml-1 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar agencia del usuario"},on:{"click":function($event){_vm.esEditar && _vm.usuario.agenciaId.length === 1
                      ? _vm.usuario.roles.splice(_vm.usuario.roles.indexOf('Agencia'), 1)
                      : _vm.usuario.agenciaId.splice(i, 1)}}})],1)})],2):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3 pr-md-1 pr-lg-3',
                  {'campo': _vm.validarValores.roles
                    && _vm.validarValores.roles.length},
                  {'valido': _vm.validarValores.roles == 'valido'},
                  {'invalido': _vm.validarValores.roles == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rol),expression:"rol"}],staticClass:"custom-select",attrs:{"id":"roles-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.rol=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.rolesFormateados),function(value,key){return _c('option',{key:value+'rol',domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('label',{class:_vm.usuario.rol ? 'label-active' : 'label-inactive',attrs:{"for":"roles-select"}},[_vm._v(" Roles ")])]),(_vm.validarValores.roles == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el rol ")]):_vm._e()]),_c('mdb-btn',{staticClass:"col-auto mt-2 mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar rol"},on:{"click":function($event){return _vm.agregarRol(_vm.rol)}}},[_vm._v(" Añadir ")])],1),(_vm.usuario.roles && _vm.usuario.roles.length)?_c('div',{class:['pt-1', {'animated fadeIn': _vm.usuario.roles.length}]},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Roles seleccionados:")]),_vm._l((_vm.usuario.roles),function(Rol,i){return _c('div',{key:'roles'+i,staticClass:"chip delete-btn color-terciario"},[_vm._v(" "+_vm._s(_vm.esAgencia ? Rol : _vm.UsuarioRoles[Rol])+" "),_c('mdb-btn',{staticClass:"m-0 ml-1 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar rol del usuario"},on:{"click":function($event){_vm.esEditar && _vm.usuario.roles.length === 1 && _vm.rol !='Agencia'
                    ? _vm.mostrarConfirmacionEliminar = true
                    : _vm.usuario.roles.splice(i, 1)}}})],1)})],2):_vm._e()])],1)]):_vm._e(),(!_vm.esEditar)?_c('section',{staticClass:"row"},[_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.clave
              && _vm.validarValores.clave.length},
            {'valido': _vm.validarValores.clave == 'valido'},
            {'invalido': _vm.validarValores.clave == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":_vm.mostrarCamposClave.clave ? 'text' : 'password',"minlength":"8","label":"Clave","outline":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"clave"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarCamposClave.clave ? 'eye-slash' : 'eye',"title":_vm.mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'},on:{"click":function($event){_vm.mostrarCamposClave.clave = !_vm.mostrarCamposClave.clave}},slot:"append"})],1),(_vm.validarValores.clave == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica la clave ")]):_vm._e()],1),_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.confirmacionClave
              && _vm.validarValores.confirmacionClave.length},
            {'valido': _vm.validarValores.confirmacionClave == 'valido'},
            {'invalido': _vm.validarValores.confirmacionClave == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":_vm.mostrarCamposClave.confirmacionClave ? 'text' : 'password',"minlength":"8","label":"Confirmar clave","outline":""},model:{value:(_vm.confirmacionClave),callback:function ($$v) {_vm.confirmacionClave=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmacionClave"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye',"title":_vm.mostrarCamposClave.confirmacionClave
                  ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'},on:{"click":function($event){_vm.mostrarCamposClave.confirmacionClave
                  = !_vm.mostrarCamposClave.confirmacionClave}},slot:"append"})],1),(_vm.validarValores.confirmacionClave == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Confirma la clave ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row justify-content-around"},[_c('mdb-btn',{staticClass:"col-auto mt-3 px-3",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":function($event){_vm.esCambioDeClave ? _vm.validarClave() : _vm.validarUsuario()}}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],1)])],1),_c('ConfirmacionEliminar',{attrs:{"mostrar-modal":_vm.mostrarConfirmacionEliminar,"mensaje":"Si remueves todos los accesos, se eliminará el usuario en su totalidad"},on:{"cerrar":function($event){_vm.mostrarConfirmacionEliminar = $event;},"eliminar":_vm.usuarioEliminar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
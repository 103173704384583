import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '../src/assets/scss/app.scss'
import 'vue-select/dist/vue-select.css';
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createProvider } from './vue-apollo'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import './fa-icons'
import vSelect from 'vue-select'
import Clipboard from 'v-clipboard'

Vue.use(VueLazyLoad)
Vue.use(Clipboard)

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

new Vue({
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

<template>
  <!-- Modal Asignar Compras / Paquetes y Productos -->
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Asignar productos
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body  class="pt-0">
      <form @submit.prevent>
        <fieldset v-if="seleccionar === 'paquetes'" class="mb-4">
          <legend class="h5 mb-1">Lista de paquetes</legend>
          <CargandoVista
            v-if="consultandoDatos"
            clase-adicional="vc-50px"
          />
          <template v-else>
            <p class="text-muted mb-1">
              {{
                paquetesDisponibles.length
                  ? 'Selecciona un paquete para asignar sus productos'
                  : 'Sin paquetes disponibles.'
              }}
            </p>
            <ul
              v-if="paquetesDisponibles.length"
              class="table"
              data-table="sticky"
            >
              <li class="encabezado row">
                <div class="col-4 col-lg-2">Fecha</div>
                <div class="col">Detalles</div>
                <div class="col d-none d-sm-block">Estatus</div>
              </li>
              <li
                v-for="paquete in paquetesDisponibles"
                :key="paquete.id"
                class="contenido normal row align-items-center"
                @click="paqueteSeleccionado = paquete"
              >
                <div class="col-4 col-lg-2">
                  <label
                    class="opciones-radio ml-0"
                    title="Seleccionar paquete"
                  >
                    <input
                      v-model="paqueteSeleccionado"
                      type="radio"
                      name="radio-paquetes"
                      :value="paquete"
                    >
                    <span class="icono-radio" />
                    {{ paquete.fecha.formatted }}
                  </label>
                </div>
                <div class="col">
                  Tracking: {{ paquete.tracking }}
                  <span class="text-break d-block">
                    Paquete ID: {{ paquete.numeroHR }}
                  </span>
                  <p class="d-sm-none mb-0">Estatus: {{ PaqueteEstatus[paquete.estatus] }}</p>
                </div>
                <div class="col d-none d-sm-block">{{ PaqueteEstatus[paquete.estatus] }}</div>
              </li>
            </ul>
          </template>
        </fieldset>
        <fieldset v-if="seleccionar === 'ordenes'" class="mb-4">
          <legend class="h5 mb-1">Lista de órdenes</legend>
          <CargandoVista
            v-if="consultandoDatos"
            clase-adicional="vc-50px"
          />
          <template v-else>
            <p class="text-muted mb-1">
              {{
                ordenesDisponibles.length
                  ? 'Selecciona una orden para mostrar sus productos'
                  : 'Sin órdenes disponibles.'
              }}
            </p>
            <ul
              v-if="ordenesDisponibles.length"
              class="table"
              data-table="sticky"
            >
              <li class="encabezado row">
                <div class="col-4 col-lg-2">Fecha</div>
                <div class="col">Compra ID</div>
                <div class="col d-none d-sm-block">Estatus</div>
              </li>
              <li
                v-for="orden in ordenesDisponibles"
                :key="orden.id"
                class="contenido normal row align-items-center"
                @click="ordenSeleccionada = orden; productosDisponibles = orden.productos"
              >
                <div class="col-4 col-lg-2">
                  <label
                    class="opciones-radio ml-0"
                    title="Seleccionar orden"
                  >
                    <input
                      v-model="ordenSeleccionada"
                      type="radio"
                      name="radio-ordenes"
                      :value="orden"
                    >
                    <span class="icono-radio" />
                    {{ orden.fecha.formatted }}
                  </label>
                </div>
                <div class="col">
                  <span class="d-sm-none">
                    Compra ID:
                  </span>
                  <span class="text-break">
                    {{ orden.compraId }}
                  </span>
                  <p class="d-sm-none mb-0">Estatus: {{ OrdenEstatus[orden.estatus] }}</p>
                </div>
                <div class="col d-none d-sm-block">{{ OrdenEstatus[orden.estatus] }}</div>
              </li>
            </ul>
          </template>
        </fieldset>
        <fieldset>
          <legend class="h5 mb-1">Lista de productos</legend>
          <ul
            v-if="productosDisponibles.length"
            class="table"
            data-table="sticky"
          >
            <li class="encabezado row">
              <div class="col">
                Nombre
                <span class="d-lg-none">/ Estatus</span>
              </div>
              <div class="col d-none d-lg-block">Estatus</div>
              <div class="col-5 col-sm-4 col-lg-3">Cantidad</div>
            </li>
            <li
              v-for="producto in productosDisponibles"
              :key="`producto-${producto.id}`"
              class="contenido normal row align-items-center"
            >
              <div class="col">
                <span
                  class="custom-control custom-switch ml-1"
                  title="Seleccionar este producto"
                >
                  <input
                    :id="`check-seleccionado-${producto.id}`"
                    v-model="productosSeleccionados"
                    type="checkbox"
                    name="seleccionar-producto"
                    class="custom-control-input"
                    :value="producto"
                    :selected="producto.cantidadRelacionada"
                  >
                  <label
                    :for="`check-seleccionado-${producto.id}`"
                    class="custom-control-label pr-4 pb-2"
                  >
                    {{ producto.nombre }}
                  </label>
                </span>
                <small class="d-block d-lg-none">
                  {{ OrdenEstatus[producto.estatus] }}
                </small>
              </div>
              <div class="col d-none d-lg-block">{{ OrdenEstatus[producto.estatus] }}</div>
              <div class="col-5 col-sm-4 col-lg-3">
                <mdb-input
                  v-model.number="producto.cantidadRelacionada"
                  type="number"
                  placeholder="0"
                  class="my-2 input-text-right"
                  size="sm"
                  :min="0"
                  :max="producto.cantidad"
                  outline
                >
                  <span
                    class="input-group-text md-addon"
                    slot="append"
                  >
                    / {{ producto.cantidad }}
                  </span>
                </mdb-input>
              </div>
            </li>
          </ul>
          <p
            v-else
            class="text-muted"
          >
            {{
              seleccionar === 'ordenes' && !(Object.keys(ordenSeleccionada).length)
                ? 'Debes seleccionar una orden para listar sus productos.'
                : 'Sin productos disponibles.'
            }}
          </p>
        </fieldset>
      </form>
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-center">
      <mdb-btn
        color="primario"
        icon="check"
        @click="asignarProductos()"
      >
        Asignar productos
      </mdb-btn>

    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import CargandoVista from "@/components/CargandoVista.vue";

import { OrdenEstatus } from "@/constantes/ordenes.js"
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import ordenesPorEstatusDeClienteGql from "@/graphql/ordenesPorEstatusDeCliente.gql";
import paquetesPorEstatusDeClienteGql from "@/graphql/paquetesPorEstatusDeCliente.gql";
import ordenProductosRelPaqueteGql from "@/graphql/ordenProductosRelPaquete.gql";
import paqueteGql from "@/graphql/paquete.gql";
import ordenCompraGql from "@/graphql/ordenCompra.gql";



import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
} from "mdbvue";

export default {
  name: 'ModalAsignarProductos',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
    CargandoVista,
  },
  props: {
    clienteId: {
      type: String,
      required: true,
    },
    paqueteId: {
      type: String,
      default: '',
      required: false,
    },
    ordenId: {
      type: String,
      default: '',
      required: false,
    },
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    seleccionar: {
      required: true,
      validator: function (valor) {
        return ["ordenes", "paquetes"].includes(valor);
      }
    },
    productos: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      OrdenEstatus,
      PaqueteEstatus,
      ordenesDisponibles: [],
      paquetesDisponibles: [],
      productosDisponibles: [],
      ordenSeleccionada: {},
      paqueteSeleccionado: {},
      productosSeleccionados: [],
      consultandoDatos: false,
    };
  },
  watch: {
    mostrarModal: function() {
      if (this.mostrarModal) {
        this.consultandoDatos = true;
        // Consultar datos de la lista correspondiente
        switch (this.seleccionar) {
          case 'paquetes':
            this.productosDisponibles = this.productos;
            this.consultarPaquetes();
            break;
          case 'ordenes':
            this.consultarOrdenes();
            break;
        }
      } else {
        this.limpiarCampos();
      }
    },
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    asignarProductos () {
      switch (this.seleccionar) {
        case 'paquetes':
          if (!(Object.keys(this.paqueteSeleccionado).length)) {
            return this.alertaMensaje('Debes seleccionar un paquete', 'advertencia')
          }
          if (!(this.productosSeleccionados.length)) {
            return this.alertaMensaje('Debes seleccionar algún producto', 'advertencia')
          }
          break;
        case 'ordenes':
          if (!(Object.keys(this.ordenSeleccionada).length)) {
            return this.alertaMensaje('Debes seleccionar una orden', 'advertencia')
          }
          if (!(this.productosSeleccionados.length)) {
            return this.alertaMensaje('Debes seleccionar algún producto', 'advertencia')
          }
          break;
      }

      this.botonDeshabilitado = true;
      const paqueteId = this.paqueteId || this.paqueteSeleccionado.id;
      const ordenId = this.ordenId || this.ordenSeleccionada.id;
      const ordenProductos = this.productosSeleccionados.map((p)=>{
        const pr = {
          id: p.id,
          nombre: p.nombre,
          cantidad: p.cantidadRelacionada,
        }
        return pr;
      })
      this.$apollo
        .mutate({
          mutation: ordenProductosRelPaqueteGql,
          variables: {
            paqueteId,
            ordenProductos,
          },
          // refetchQueries: ['orden'],
          update: (store) => {
            if (this.ordenSeleccionada.id) {
              // Para cuando se está relacionando desde PaqueteID
              try {
                const data = store.readQuery({
                  query: paqueteGql,
                  variables: {
                    id: paqueteId,
                  },
                });
                this.productosSeleccionados.forEach((p) =>{
                  data.Paquete[0].ordenProductos.push({
                    cantidad: p.cantidad,
                    OrdenProducto: {
                      nombre: p.nombre,
                      id: p.id,
                      cantidad: p.cantidadRelacionada,
                      estatus: p.estatus,
                      orden: {
                        id: ordenId,
                        monto: this.ordenSeleccionada.monto || '?',
                        estatus: this.ordenSeleccionada.estatus || '?',
                        compraId: this.ordenSeleccionada.compraId || '?',
                        __typename: 'Orden',
                      },
                      __typename: 'OrdenProducto',
                    },
                    __typename: '_PaqueteOrdenProductos'

                  })
                })
                store.writeQuery({
                  query: paqueteGql,
                  variables: {
                    id: paqueteId,
                  },
                  data,
                });
              } catch(e) {
                // Error
                // console.log(e)
              }
            } else{
              // Para cuando se está relacionando desde Orden
              try {
                const data = store.readQuery({
                  query: ordenCompraGql,
                  variables: {
                    id: ordenId,
                  },
                });
                this.productosSeleccionados.forEach((p) =>{ 
                  let ind = null;
                  data.Orden[0].productos.some((dpro, i) => {
                    if (dpro.id === p.id) ind = i;
                    return dpro.id === p.id
                  })
                  data.Orden[0].productos[ind].paquetes
                  .push({
                    cantidad: p.cantidadRelacionada,
                    Paquete: {
                      id: paqueteId,
                      pagado: this.paqueteSeleccionado.pagado || '?',
                      estatus: this.paqueteSeleccionado.estatus || '?',
                      tracking: this.paqueteSeleccionado.tracking || '?',
                      numeroHR: this.paqueteSeleccionado.numeroHR || '?',
                      fecha: this.paqueteSeleccionado.fecha || '?',
                      __typename: 'Paquete',
                    },
                    __typename: '_PaqueteOrdenProductos'

                  })
                })
                store.writeQuery({
                  query: ordenCompraGql,
                  variables: {
                    id: ordenId,
                  },
                  data,
                });
              } catch(e) {
                // Error
                // console.log(e)
              }
            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenProductosRelPaquete: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(()=>{
          this.alertaMensaje('Productos relacionados al paquete', 'correcto')
          this.$emit('cerrar', false)
          this.botonDeshabilitado = false;
          this.limpiarCampos();
        })
        .catch(()=>{
          this.alertaMensaje('Productos relacionados al paquete', 'error')
          this.botonDeshabilitado = false;
        })
    },
    consultarOrdenes () {
      this.botonDeshabilitado = true;
       this.$apollo
        .watchQuery({
          query: ordenesPorEstatusDeClienteGql,
          variables: {
            clienteId: this.clienteId,
            estatus: ['Identificado', 'Cotizado', 'EnEspera'],
          },
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.ordenesPorEstatusDeCliente){
              this.ordenesDisponibles = data.ordenesPorEstatusDeCliente;
            }
            this.botonDeshabilitado = false;
            this.consultandoDatos = false;
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo los pagos del cliente.'
                  +' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false;
            this.consultandoDatos = false;
          }
        })
    },
    consultarPaquetes () {
      this.botonDeshabilitado = true;
       this.$apollo
        .watchQuery({
          query: paquetesPorEstatusDeClienteGql,
          variables: {
            clienteId: this.clienteId,
            estatus: ['EsperaMiami', 'Reempacado', 'Consolidado'],
          },
          // update: (data) => data.paquetesPorEstatusDeCliente,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.paquetesPorEstatusDeCliente){
              this.paquetesDisponibles = data.paquetesPorEstatusDeCliente;
            }
            this.botonDeshabilitado = false;
            this.consultandoDatos = false;
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo los pagos del cliente.'
                  +' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false;
            this.consultandoDatos = false;
          }
        })
    },
    limpiarCampos() {
      this.ordenesDisponibles = [];
      this.paquetesDisponibles = [];
      this.productosDisponibles = [];
      this.ordenSeleccionada = {};
      this.paqueteSeleccionado = {};
      this.productosSeleccionados = [];
    },
  },
}
</script>

<style>

</style>
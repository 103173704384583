<template>
  <!-- Modal Seleccionar Pago -->
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="limpiarCampos(); $emit('close', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Asignar pagos a {{ esPaquete ? 'paquete ' : 'compra'}}
        {{montoACubrir ? `de $ ${montoACubrir.toFixed(2)}` : ''}}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div
        v-if="(esCompra || esAgencia) && entidad && entidad.pagos && entidad.pagos.length"
        class="mb-3"
      >
        <header
          v-if="entidad.pagos && entidad.pagos.length"
          class="font-weight-bold mt-n3"
        >
          Pagos asignados
        </header>
        <template v-for="(pago, ipago) in entidad.pagos">
          <div
            :key="pago.Pago.id"
            :class="{'pt-2': ipago}"
            class="row"
          >
            <p class="col-6 col-lg-2 mb-2">
              <small class="d-block text-muted user-select-none">
                Fecha
              </small>
              {{ pago.Pago.fecha.formatted }}
            </p>
            <p class="col-6 col-lg-2 px-lg-0 mb-2">
              <small class="d-block text-muted user-select-none">
                Estatus
              </small>
              <span
                :class="{'text-muted': !EstatusPago[pago.Pago.estatus]}"
              >
                <font-awesome-icon
                  :icon="EstatusPagoIconos[pago.Pago.estatus]"
                  :class="EstatusPagoIconoColor[pago.Pago.estatus]"
                />
                {{
                  EstatusPago[pago.Pago.estatus]
                    ? EstatusPago[pago.Pago.estatus]
                    : "Sin especificar"
                }}
              </span>
            </p>
            <p class="col-6 col-lg-2 mb-2">
              <small class="text-muted user-select-none d-block">
                Monto
              </small>
              <span :class="pago.Pago.monto ? 'font-weight-bold' : 'text-muted user-select-none'">
                {{ pago.monto ? '$'+pago.monto : 'Sin especificar' }}
              </span>
            </p>
            <p class="col-12 col-sm-6 col-lg-3 px-lg-0 mb-2">
              <small class="text-muted user-select-none d-block">
                Medio / Referencia
              </small>
              <span :class="pago.Pago.medio.nombre ? '' : 'text-muted user-select-none'">
                {{ pago.Pago.medio.nombre ? pago.Pago.medio.nombre : 'Sin especificar' }}
              </span>
              / Ref.
              <span :class="{'text-muted user-select-none': !pago.Pago.referencia}">
                {{ pago.Pago.referencia ? pago.Pago.referencia : 'Sin especificar' }}
              </span>
            </p>
            <p
              class="col-12 col-lg mb-2"
            >
              <small class="text-muted user-select-none d-block">
                Observación
              </small>
              <span :class="{'text-muted user-select-none': !pago.Pago.observacion}">
                {{ pago.Pago.observacion ? pago.Pago.observacion : 'Sin especificar' }}
              </span>
            </p>
            <hr>
          </div>
        </template>
      </div>
      <div v-if="pagos && pagos.length">
        <header class="font-weight-bold">Lista de pagos</header>
        <ul class="list-unstyled mb-0">
          <li
            v-for="(pago, iP) in pagos"
            :key="`pago${iP}`"
            class="row my-1"
          >
            <hr v-if="iP > 0" class="col-11">
            <p class="col-12 col-sm-6 col-lg-3 mb-2">
              <small class="d-block text-muted user-select-none">
                Seleccionar
              </small>
              <span
                :class="['custom-control custom-switch d-inline-block ml-1']"
                :title="'Seleccionar este paquete'"
              >
                <input
                  :id="`check-seleccionado-${pago.id}`"
                  v-model="pagosSeleccionados"
                  type="checkbox"
                  name="seleccionar-paquete"
                  class="custom-control-input"
                  :value="pago"
                  :disabled="(pagosSeleccionados.includes(pago) ? false: true) && sumaSaldosRestantes >= montoACubrir"
                >
                <label
                  :for="`check-seleccionado-${pago.id}`"
                  class="custom-control-label pr-4 pb-2"
                >
                  {{ pagosSeleccionados.includes(pago) ? 'Sí' : 'No'}} -
                  Pago {{iP+1}}
                </label>
              </span>
            </p>
            <p class="col-6 col-lg-3 mb-2">
              <small class="d-block text-muted user-select-none">
                Fecha
              </small>
              {{ pago.fecha.formatted }}
            </p>
            <p class="col-6 col-lg-3 mb-2">
              <small class="d-block text-muted user-select-none">
                Estatus
              </small>
              <font-awesome-icon
                v-if="pago.estatus"
                :icon="EstatusPagoIconos[pago.estatus]"
                :class="EstatusPagoIconoColor[pago.estatus]"
              />
              <span :class="{'text-muted user-select-none': !pago.estatus}">
                {{
                EstatusPago[pago.estatus]
                  ? EstatusPago[pago.estatus]
                  : "Sin especificar"
              }}
              </span>
            </p>
            <p class="col-6 col-lg-3 order-lg-1 mb-2">
              <small class="d-block text-muted user-select-none">
                Monto / Disponible
              </small>
              ${{ pago.monto }} / ${{ pago.restante }}
            </p>
            <p class="col-6 col-lg-3 mb-2">
              <small class="d-block text-muted user-select-none">
                Referencia
              </small>
              {{ pago.referencia }}
            </p>
            <p class="col-12 col-sm-6 col-lg-3 mb-2">
              <small class="d-block text-muted user-select-none">
                Medio de pago
              </small>
              <span :class="{'text-muted user-select-none': !(pago.medio && pago.medio.nombre.length)}">
                {{
                  pago.medio && pago.medio.nombre.length
                    ? pago.medio.nombre
                    : "Sin especificar"
                }}
              </span>
            </p>
            <p class="col-12 col-lg order-lg-2 mb-2">
              <small class="d-block text-muted user-select-none">
                Observación
              </small>
              <span :class="{'text-muted': !(pago.observacion && pago.observacion.length)}">
                {{
                  pago.observacion && pago.observacion.length
                    ? pago.observacion
                    : "Sin especificar"
                }}
              </span>
            </p>
          </li>
        </ul>
      <div class="text-center">
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="mt-4"
          @click="verificarPago"
        >
          Asignar pago
        </mdb-btn>
      </div>
    </div>
    <div
      v-else
      class="text-center text-muted user-select-none mb-4"
    >
      <font-awesome-icon
        :icon="['fas', 'exclamation-triangle']"
        class="fa-4x mb-4"
      />
      <p class="h5-responsive text-center mb-0 mx-lg-5 px-lg-5">
        El cliente no cuenta con pagos confirmados y saldo disponible para asignar.
        <span class="d-block">
          Por favor agrega uno para continuar.
        </span>
      </p>
    </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { leerPersonaNombre } from "@/utils/datosToken.js"
import agenciaGql from "@/graphql/agencia.gql";
import pagosGql from "@/graphql/pagos.gql"
import ordenCompraGql from "@/graphql/ordenCompra.gql";
import paqueteGql from "@/graphql/paquete.gql";
import pagoAsignarPaqueteGql from "@/graphql/pagoAsignarPaquete.gql";
import pagoAsignarCompraGql from "@/graphql/pagoAsignarCompra.gql";
import {
  EstatusPago,
  EstatusPagoIconos,
  EstatusPagoIconoColor,
} from "@/constantes/pagos.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: 'ModalAsignarPago',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esPaquete: {
      type: Boolean,
      required: false,
    },
    esCompra: {
      type: Boolean,
      required: false,
    },
    // permite saber si se está operando desde la view de agencia
    esAgencia: {
      type: Boolean,
      required: false,
    },
    // Objeto (pago o compra)
    entidad: {
      type: Object,
      required: true,
    },
    variablesQueryAgencia: {
      type: Object,
      required: false,
    },
    cliente: {
      type: Object,
      required: true,
    },
    montoACubrir: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      // Seleccionar pago
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      pagoSeleccionado: {},
      pagosSeleccionados: [],
      pagos: {},
      // Otros
      botonDeshabilitado: false,
      validarValores: {},
      FECHA_LIMITE_PAGO:"2024-09-01",
    };
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
      } else {
        this.obtenerPagosCliente()
      }
    },
  },
  computed: {
    sumaSaldosRestantes() {
      const suma = this.pagosSeleccionados.reduce(
        (anteriorValor, actualValor) => {
          return Number(anteriorValor) + Number(actualValor.restante)
        },
        0)
      return suma;
    },
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    // Pagos
    obtenerPagosCliente() {
      this.botonDeshabilitado = true;
       this.$apollo
        .watchQuery({
          query: pagosGql,
          variables: {
            filter: {
              estatus: 'Confirmado',
              cliente: {
                id: this.cliente.id,
              },
              fecha_gte: {
                formatted: this.FECHA_LIMITE_PAGO
              },
              restante_gt: 0,
            }
          },
          update: (data) => data.Pago,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.Pago){
              this.pagos = data.Pago;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo los pagos del cliente.'
                  +' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false;
          }
        })
    },
    verificarPago(){
       if (!this.pagosSeleccionados.length) {
        return this.alertaMensaje('Selecciona un pago para continuar','advertencia')
      }
       if (!this.montoACubrir) {
        return this.alertaMensaje('Faltan datos para determinar el costo del paquete','advertencia')
      }
      let restante = this.montoACubrir;
      this.pagosSeleccionados = this.pagosSeleccionados.map((pago)=>{
        const dif = Number((pago.restante - restante).toFixed(2))
        if (dif >= 0) {
          pago.restante = dif
          pago.asignado = restante;
        } else {
          restante = Math.abs(dif);
          pago.asignado = pago.restante
          pago.restante = 0;
        }
        return pago;
      })
      this.esPaquete
        ? this.paqueteAsignarPago()
        : this.compraAsignarPago()
    },
    compraAsignarPago(){
      const ordenId = this.entidad.id
      this.botonDeshabilitado = true;
      const pagos = this.pagosSeleccionados.map((p)=>{
        return {
          id: p.id,
          restante: p.restante,
          monto: p.asignado,
        }
      })
      this.$apollo
          .mutate({
            mutation: pagoAsignarCompraGql,
            variables: {
              pagos,
              ordenId,
            },
            update: (store) => {
              let abonado = 0;
              const pagoAgregar = (JSON.parse(JSON.stringify(this.pagosSeleccionados)))
                .map((p) =>{
                  const monto = p.asignado
                  abonado += monto;
                  delete p.asignado
                  return {
                    monto,
                    Pago: {
                      ...p
                    },
                    __typename: '_OrdenPagos',
                  }
                })
              const variables = { 
                id: ordenId,
              }
              const data = store.readQuery({
                query: ordenCompraGql,
                variables,
              });

              data.Orden[0].pagos = data.Orden[0].pagos.concat(pagoAgregar);
              data.Orden[0].montoCubierto += Number(abonado.toFixed(2));
              data.Orden[0].pagado = this.montoACubrir > abonado
                ? false
                : true;

              store.writeQuery({
                query: ordenCompraGql,
                variables,
                data,
              });
              this.alertaMensaje('Se ha asignado un pago a la compra correctamente','correcto')
              this.limpiarCampos()
              this.$emit('close', false);
            },
          })
          .catch(()=>{
            this.mostrarMensajeEnCasoDefault()
            this.botonDeshabilitado = false
          })
    },
     mostrarMensajeEnCasoDefault(){
      this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo', 'error')
    },
    paqueteAsignarPago(){
      const id = this.$route.params.paqueteId;
      this.botonDeshabilitado = true;
      const pagos = this.pagosSeleccionados.map((p)=>{
        return {
          id: p.id,
          restante: p.restante,
          monto: p.asignado,
        }
      })
      this.$apollo
          .mutate({
            mutation: pagoAsignarPaqueteGql,
            variables: {
              pagos,
              paqueteId: this.entidad.id,
              costoPaquete: this.montoACubrir,
            },
            update: (store) => {
              let data = {}
              let abonado = 0;
              const pagoAgregar = (JSON.parse(JSON.stringify(this.pagosSeleccionados)))
                .map((p) =>{
                  const monto = p.asignado
                  delete p.asignado
                  abonado += monto;
                  return {
                    monto,
                    Pago: {
                      ...p
                    },
                    __typename: '_PaquetePagos',
                  }
                })
              if(!this.esAgencia){
                // Se opera desde la view de paquete
                data = store.readQuery({
                  query: paqueteGql,
                  variables: {
                    id,
                  },
                });

                data.Paquete[0].pagos = data.Paquete[0].pagos.concat(pagoAgregar);
                data.Paquete[0].montoCubierto += Number(abonado);
                data.Paquete[0].pagado = this.montoACubrir > abonado
                  ? false
                  : true;

                data.Paquete[0].cambios.unshift({
                  autor: leerPersonaNombre(),
                  descripcion: "Pago asignado al paquete",
                  cliente: this.entidad.cliente
                    ? this.entidad.cliente.nombre
                    : '',
                  defectuoso: this.entidad.defectuoso,
                  nuevoEstatus: this.entidad.estatus,
                  pagado: true,
                  fechaHora: {
                    formatted: new Date().toISOString(),
                  __typename: "_Neo4jDateTime"
                  },
                  __typename: "Cambio"
                })
                store.writeQuery({
                  query: paqueteGql,
                  variables: {
                    id,
                  },
                  data,
                });
              } else  {
                // se opera desde la view de agencia
                data = store.readQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                });

                data.Agencia[0].paquetes = data.Agencia[0].paquetes.map(p => {
                  const paquete = p
                  if(paquete.id == this.entidad.id){
                    paquete.pagos.push(pagoAgregar); 
                    paquete.pagado = true;
                  }
                  return paquete
                })
                store.writeQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                  data,
                });
              }
              this.alertaMensaje('Se ha asignado un pago al paquete correctamente','correcto')
              this.limpiarCampos()
              this.$emit('close', false);
            },
          })
          .catch(()=>{
            this.mostrarMensajeEnCasoDefault()
            this.botonDeshabilitado = false
          })
    },
    limpiarCampos() {
      this.pagosSeleccionados = [];
      this.pagos = [];
      this.botonDeshabilitado = false;
    },
  },
}

</script>

 <style lang="scss" scoped>
.campo.invalido {
  margin-bottom: 1rem;
  & > .mensaje-invalido {top: 40px;}
}
 </style>
<template>
  <div class="container-xl">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Medios de pagos</h2>
      <div v-if="!cargandoMedios" class="col-auto">
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          class="btn-bordeado my-sm-0 px-3"
          :disabled="botonDeshabilitado"
          @click="medioActualizar()"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          color="primario"
          icon="plus"
          class="my-sm-0 px-3"
          :disabled="botonDeshabilitado"
          @click="mostrarModalMedios = true"
        >
          Nuevo medio de pago
        </mdb-btn>
      </div>
    </div>
    <CargandoVista v-if="cargandoMedios" clase-adicional="vc-75vh" />
    <div v-else class="mb-page">
      <ul data-table="sticky" class="table mt-2">
        <li class="encabezado row align-items-end">
          <div
          class="col d-none d-sm-block filtrable"
          @click="ordenarColumna('nombre')"
          >
            Nombre
            <div
              v-if="orderBy.nombre != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.nombre}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.nombre}"
              />
            </div>
          </div>
          <div class="col">Descripción</div>
          <div class="col-3 col-md-2">Acción</div>
        </li>
        <li
          v-for="medio in medios"
          :key="`medios-${medio.id}`"
          class="contenido normal row align-items-center"
        >
          <div class="col d-none d-sm-block">
            {{ medio.nombre }}
          </div>
          <div class="col">
            <p class="font-weight-bold mb-2 d-sm-none">
              {{ medio.nombre }}
            </p>
            {{ medio.descripcion }}
          </div>
          <div class="col-3 col-md-2">
            <mdb-btn
              flat
              dark-waves
              icon="trash"
              :disabled="botonDeshabilitado"
              icon-class="texto-peligro"
              class="m-0 ml-2 ml-sm-0 py-3 px-2 btn-bordeadoOnHover"
              title="Eliminar medio de pago"
              @click="
                mostrarConfirmacionEliminar = true;
                medioIdEliminar = medio.id;
              "
            />
            <mdb-btn
              flat
              dark-waves
              icon="pen"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Editar medio de pago"
              @click="medioAEditar(medio)"
            />
          </div>
        </li>
        <li v-if="!medios.length" class="no-items">
          No hay medios de pagos registrados
        </li>
      </ul>
    </div>
    <FormularioMedios
      :mostrarModalMedios="mostrarModalMedios"
      :titulo="esEditar
        ? 'Datos del medio de pago a editar'
        : 'Datos del nuevo medio de pago'"
      :es-editar="esEditar"
      :medioAsignar="medioAsignar && medioAsignar"
      @cerrar="mostrarModalMedios = $event;
        botonDeshabilitado = $event;"
      @editar="esEditar = $event"
      @alerta="mensajeAlerta($event)"
      @crear="medioCrear($event)"
      @editarMedio="medioEditar($event)"
      @editado="mostrarModalMedios=false"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      mensaje="Recibimos la orden de eliminar este medio de pago"
      @cerrar="mostrarConfirmacionEliminar = $event;
        medioIdEliminar = '';"
      @eliminar="eliminarMedio"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>

<script>
import mediosGql from "@/graphql/medios.gql";
import medioEliminarGql from "@/graphql/medioEliminar.gql";
import medioCrarGql from "@/graphql/medioCrear.gql";
import gql from "graphql-tag";
import FormularioMedios from "@/components/FormularioMedios.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import { mdbBtn } from "mdbvue";
export default {
  name: "Medios",
  components: {
    FormularioMedios,
    mdbBtn,
    CargandoVista,
    ConfirmacionEliminar,
    AlertaMensaje,
  },
  data() {
    return {
      medios: [],
      mostrarModalMedios: false,
      botonDeshabilitado: false,
      esEditar: false,
      mostrarConfirmacionEliminar: false,
      medioAsignar: {
        nombre: "",
        descripcion: "",
      },
      medioIdEliminar: "",
      alertaMensaje: { contenido: "" },
      // Ordenar asc desc
      orderBy: {
        nombre: null,
      },
      orderByActual: [],
    };
  },
  computed: {
    cargandoMedios() {
      return this.$apolloData.queries.medios.loading || this.actualizandoMedios
        ? true
        : false;
    },
  },
  methods: {
    ordenarColumna(columna){
      if(columna == 'nombre'){
        this.orderBy.nombre === null
          ? this.orderBy.nombre = false  // se ordena descendente
          : this.orderBy.nombre
            ? this.orderBy.nombre = false   
            : this.orderBy.nombre = true  // se ordena ascendente
        if(this.orderBy.nombre) {
          this.orderByActual = ['nombre_asc']
          return this.medioActualizar()
        } else {
          this.orderByActual = ['nombre_desc']
          return this.medioActualizar()
        }
      }
    },
    medioAEditar(medioSeleccionado) {
      this.botonDeshabilitado = true;
      this.medioAsignar = JSON.parse(JSON.stringify(medioSeleccionado));
      this.esEditar = true;
      this.mostrarModalMedios = true;
    },
    medioActualizar() {
      this.actualizandoMedios = true;
      this.botonDeshabilitado = true;
      const filter = {};
      const variablesQuery = {
        filter,
      }
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery,{ 
            orderBy: this.orderByActual
          })
        : ''
      this.$apollo
        .watchQuery({
          query: gql`
            ${mediosGql}
          `,
          variables: variablesQuery,
          update: (data) => data.Medio,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Medio) {
              this.medios = data.Medio;
            }
            this.botonDeshabilitado = false;
            this.actualizandoMedios = true;
          },
          error: () => {
            this.alertaMensaje = {
              contenido:
                "Ha ocurrido un error actualizando los medios de pago. Por favor intenta de nuevo",
              tipo: "error",
            };
            this.botonDeshabilitado = false;
            this.actualizandoMedios = true;
          },
        });
    },
    medioCrear(medioEvent) {
      const medio = { ...medioEvent };
      delete medio.__typename;
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${medioCrarGql}
          `,
          variables: {
            medio,
          },
          update: (cache, { data: { medioCrear } }) => {
            const data = cache.readQuery({ query: mediosGql });
            const medioAgregar = {
              ...medio,
              ...medioCrear,
            };
            medioAgregar.__typename = "Medio";
            const Medio = [...data.Medio, medioAgregar];
            cache.writeQuery({ query: mediosGql, data: { Medio } });
          },
          optimisticResponse: {
            __typename: "Mutation",
            medioCrear: {
              __typename: "Medio",
              id: -1,
              nombre: medio.nombre,
              descripcion: medio.descripcion,
            },
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Medio de pago agregado correctamente",
            tipo: "correcto",
          };
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error agregando el medio de pago",
            tipo: "error",
          };
        });
    },
    limpiarCamposForm() {
      this.botonDeshabilitado = false;
      this.medioIdEliminar = "";
      this.medioAsignar = { nombre: "", descripcion: "" };
    },
    eliminarMedio() {
      const medioId = JSON.parse(JSON.stringify(this.medioIdEliminar));
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${medioEliminarGql}
          `,
          variables: {
            medioId,
          },
          update: (
            cache,
            {
              data: {
                medioEliminar: { codigo },
              },
            }
          ) => {
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: "Medio de pago eliminado correctamente",
                  tipo: "correcto",
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                  contenido: "No se pudo eliminar el medio de pago",
                  tipo: "error",
                };
                return;
              default:
                this.alertaMensaje = {
                  contenido: "Ocurrió un error inesperado",
                  tipo: "error",
                };
                return;
            }
            const data = cache.readQuery({
              query: gql`
                ${mediosGql}
              `,
            });

            const Medio = data.Medio.filter(({ id }) => id !== medioId);
            cache.writeQuery({
              query: gql`
                ${mediosGql}
              `,
              data: {
                Medio,
              },
            });
            !this.botonDeshabilitado &&
              (this.mostrarConfirmacionEliminar = false);
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            medioEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .catch(() => {
          this.establecerValoresEliminar();
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error eliminando el medio de pago",
            tipo: "error",
          };
        });
    },
    mensajeAlerta({ contenido, tipo }) {
      this.alertaMensaje = {
        contenido,
        tipo,
      };
    },
    establecerValoresEliminar() {
      this.botonDeshabilitado = false;
      this.mostrarConfirmacionEliminar = false;
      this.medioIdEliminar = "";
      this.mostrarModalMedios = false;
    },
  },
  apollo: {
    medios() {
      return {
        query: gql`
          ${mediosGql}
        `,
        update: (data) => data.Medio,
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

import {library} from '@fortawesome/fontawesome-svg-core';
import { faBars, faCamera, faBoxes, faShip, faUsers, faFileInvoiceDollar, faMoneyCheckAlt,
  faMapMarkedAlt, faUserFriends, faHandshake, faCogs, faSignOutAlt, faLock,
  faSms, faEdit, faAngleLeft, faFolderOpen, faCheckCircle, faTimesCircle,
  faCalendarDay, faPlaneDeparture, faEnvelope, faBox, faSignInAlt, faImages,
  faExclamationCircle, faInfoCircle, faMinusCircle, faUpload, faCircleNotch,
  faExclamationTriangle, faPlusCircle, faImage, faMapSigns, faHandHoldingUsd,
  faPeopleArrows, faPhoneAlt, faClock, faUserClock, faBoxOpen, faSortUp,
  faSortDown, faExternalLinkAlt, faAngleRight,faFileExcel, faPen, faTrash,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faTrello, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  // Solid
  faBars,faCamera, faBoxes, faShip, faUsers, faFileInvoiceDollar, faMoneyCheckAlt,
  faMapMarkedAlt, faUserFriends, faHandshake, faCogs, faSignOutAlt, faLock,
  faSms, faEdit, faAngleLeft, faFolderOpen, faCheckCircle, faTimesCircle,
  faCalendarDay, faPlaneDeparture, faEnvelope, faBox, faSignInAlt, faImages,
  faExclamationCircle, faInfoCircle, faMinusCircle, faUpload, faCircleNotch,
  faExclamationTriangle, faPlusCircle, faImage, faMapSigns, faHandHoldingUsd,
  faPeopleArrows, faPhoneAlt, faClock, faUserClock, faBoxOpen, faSortUp,
  faSortDown, faExternalLinkAlt, faAngleRight,faFileExcel, faPen, faTrash,
  faShoppingBasket,
  // Regular
  faThumbsUp,
  faTrello, faWhatsapp,
);
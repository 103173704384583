// eslint-disable
import Vue from "vue";
import VueApollo from "vue-apollo";
import {
  createApolloClient,
  restartWebsockets,
} from "vue-cli-plugin-apollo/graphql-client";
import { onError } from "apollo-link-error";
import { fromPromise, ApolloLink } from "apollo-link";
import { leerPersonaId, leerEmpresa, leerRoles} from '@/utils/datosToken.js'
import router from '@/router/index'
import renovarTokenGql from '@/graphql/renovarToken.gql'
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";
const ERROR_TOKEN = {
  tokenExpired: "Your token is expired",
  autenticacionRequerida: "autenticacion_requerida"
}
// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || "http://0.0.0.0:4001/graphql";

const getNewToken = async () => {
  const personaId = leerPersonaId()
  const empresa = leerEmpresa()
  const {data} =  await apolloClient.query({ query: renovarTokenGql, variables:{personaId, empresa} })
  return data?.renovarToken?.token;
};
const errorLink = onError(
  ({ graphQLErrors,/*  networkError,  */operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case ERROR_TOKEN.tokenExpired:
          case ERROR_TOKEN.autenticacionRequerida:
            return fromPromise(
              getNewToken().catch((error) => {
                //clean token of LS and redirect to login
                const nombreEmpresa = leerEmpresa();
                localStorage.removeItem(AUTH_TOKEN);
                router.push({
                  name: "Login",
                  params: { empresa: nombreEmpresa ?? null },
                });
                console.log(error);
                return;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                localStorage.setItem(AUTH_TOKEN, accessToken);
                // modify authorization header with refreshToken
                operation.setContext({
                  headers: {
                    authorization: `Bearer ${accessToken}`,
                  },
                });

                // retry the request, returning the new observable
                return forward(operation);
              });
        }
      }
    }
  }
);
// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: ApolloLink.from([
    errorLink,
    // httpLink,
  ]),

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Create apollo client
const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
});
// Call this in the Vue app file
export function createProvider() {
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
export async function isAuthenticated(to, from, next) {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(AUTH_TOKEN) ? next() : next(`/login/`);
  }
}
export async function haveAccess(to, from, next) {
  const roles = leerRoles()
  const empresa = leerEmpresa()
  if(roles && roles.length && empresa && empresa.length){
    if(roles.includes('Admin')){
      return next()
    }
    if(roles.includes('Agencia')){
      if(to.name == 'Agencias'){
        return next(`/agencias/${empresa}`)
      }
      if(to.name == 'Resumen' || to.name == 'Paquetes' || to.name == 'Paquete'
          || to.name == 'Consolidado' || to.name == 'Consolidados'  || to.name == 'ConsolidadoPaquete'
          || to.name == 'Clientes'  || to.name == 'Cliente' || to.name == 'ClientePaquete'
          || to.name == 'Agencia' || to.name == 'AgenciaPaquete'
          || to.name == 'Pagos' || to.name == 'Compras'){
        return next()
      }
    }
    if(roles.includes('OpAlmacen') || (roles.includes('OpAlmacen') && roles.includes('OpPuerto'))){
      if(to.name == 'Resumen' || to.name == 'Paquetes' || to.name == 'Paquete'
        || to.name == 'Consolidado' || to.name == 'Consolidados'  || to.name == 'ConsolidadoPaquete'
        || to.name == 'Clientes' || to.name == 'Cliente' || to.name == 'ClientePaquete'){
        return next()
      }
    }
    if(roles.includes('OpPuerto')){
      if(to.name == 'Resumen' || to.name == 'Consolidados' || to.name == 'Consolidado'
        || to.name == 'Salir' || to.name == 'Clientes' || to.name == 'Cliente'){
        return next()
      }
    }
    return next('/')
  }
  return next('/login')
}
export async function isNotAuthenticated(to, from, next) {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(AUTH_TOKEN) ? next("/") : next();
  }
}

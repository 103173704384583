<template>
  <section>
    <div :class="[
      'container-xl',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaOrdenes },
    ]">
      <div class="encabezado-pagina">
        <h2 class="col user-select-none">Compras</h2>
        <div
          class="col-auto"
        >
          <mdb-btn
            flat
            dark-waves
            icon="redo-alt"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 px-3"
            @click="actualizarOrdenes('estatusOrden')"
          >
            Actualizar
          </mdb-btn>
          <mdb-btn
            color="primario"
            icon="plus"
            :disabled="botonDeshabilitado"
            class="my-sm-0 px-3"
            @click="mostrarFormularioOrden = true"
          >
            Nueva Orden
          </mdb-btn>
        </div>
      </div>
      <div class="mb-page">
        <div
          class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0"
        >
          <mdb-btn
            flat
            dark-waves
            icon="list"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 py-2 px-3"
            @click="buscarPor.estatus = '';
              actualizarOrdenes();"
          >
            Todos
          </mdb-btn>
          <div class="col-12 col-sm col-md-auto px-2">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="estatus-select"
                v-model="buscarPor.estatus"
                class="custom-select"
                @change="actualizarOrdenes('estatusOrden')"
              >
                <option class="d-none" disabled value="">Seleccione</option>
                <option
                  v-for="(value, key) in OrdenEstatus"
                  :key="`ordenEstatus-${key}`"
                  :value="key"
                >
                  {{ value }}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
          </div>
        </div>
        <ul data-table="sticky" class="table">
          <li class="encabezado row">
            <div
              class="col col-sm-2 d-none d-sm-block filtrable"
              @click="ordenarColumna('fecha')"
            >
              Fecha
              <div
                v-if="orderBy.fecha != null"
                class="sort-iconos animated fadeIn"
              >
                <font-awesome-icon
                  icon="sort-up"
                  :class="{'activo': orderBy.fecha}"
                />
                <font-awesome-icon
                  icon="sort-down"
                  :class="{'activo': !orderBy.fecha}"
                />
              </div>
            </div>
            <div class="col col-md-3">
              <span class="d-md-none">Detalles</span>
              <span class="d-none d-md-block">Cliente / Compra ID</span>
            </div>
            <div class="col d-none d-md-block">
              Estatus
            </div>
            <div
              class="col-3 col-md-2 d-none d-sm-block text-right filtrable"
              @click="ordenarColumna('monto')"
            >
              Monto total
              <div
                v-if="orderBy.monto != null"
                class="sort-iconos animated fadeIn"
              >
                <font-awesome-icon
                  icon="sort-up"
                  :class="{'activo': orderBy.monto}"
                />
                <font-awesome-icon
                  icon="sort-down"
                  :class="{'activo': !orderBy.monto}"
                />
              </div>
            </div>
          </li>
          <li
            v-for="orden in ordenes"
            :key="`orden-${orden.id}`"
            class="contenido row align-items-center py-1"
            @click="$router.push({ name: 'Compra', params: { id: orden.id } })"
          >
            <div class="col col-sm-2 pr-sm-0 d-none d-sm-block">
              {{ orden.fecha.formatted }}
            </div>
            <div class="col col-md-3">
              <p class="font-weight-bold mb-1 d-sm-none">{{ orden.fecha.formatted }}</p>
              <span class="d-md-none">Cliente:</span>
              {{ orden.cliente ? orden.cliente.nombre : 'Sin especificar' }}
              <p class="mb-1">
                <span class="d-md-none">Compra ID:</span>
                <small :class="{'text-muted user-select-none': !(orden.compraId && orden.compraId.length)}">
                  {{
                    orden.compraId && orden.compraId.length
                      ? orden.compraId
                      : 'Sin especificar'
                  }}
                </small>
              </p>
              <div class="mb-2 mb-md-0" @click.stop>
                <mdb-btn
                  flat
                  dark-waves
                  size="sm"
                  :disabled="botonDeshabilitado"
                  icon="users-cog"
                  class="btn-bordeado mx-0 px-md-3 px-xl-4"
                  title="Cambiar cliente"
                  @click="abrirModalAsignarCliente(orden)"
                >
                  Cambiar cliente
                </mdb-btn>
              </div>
              <p class="mb-1 d-md-none">
                Estatus: {{
                  OrdenEstatus[orden.estatus]
                    ? OrdenEstatus[orden.estatus]
                    : "Sin especificar"
                }}
              </p>
              <p class="mb-1 d-sm-none">
                Monto total:
                <span class="font-weight-bold">${{ orden.monto }}</span>
              </p>
            </div>
            <div class="col d-none d-md-block">
              {{
                OrdenEstatus[orden.estatus]
                  ? OrdenEstatus[orden.estatus]
                  : "Sin especificar"
              }}
            </div>
            <div class="col-3 col-md-2 d-none d-sm-block text-right">
              <span class="h5 text-break">${{ orden.monto }}</span>
            </div>
          </li>
          <li v-if="!(ordenes && ordenes.length)" class="no-items">
            No hay órdenes de compra
          </li>
          <li v-if="$apolloData.queries.ordenes.loading">
            <CargandoVista clase-adicional="vc-50px" />
          </li>
        </ul>
      </div>
    </div>

    <mdb-modal
      centered
      elegant
      :show="mostrarModalAsignarCliente"
      @close="mostrarModalAsignarCliente = false; cliente = {}; ordenSeleccionada = '' "
    >
      <mdb-modal-header>
        <mdb-modal-title> Cambiar cliente asignado a la orden </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Cliente</header>
        <ClienteBuscador class="my-2" @cliente="cliente = $event" />
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado || !cliente.id"
            class="mt-4"
            @click="ordenAsignarCliente"
          >
            Asignar cliente
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />

    <!-- Modal Seleccionar Pago -->
    <!--
    <ModalAsignarPago
      :mostrar-modal="mostrarModalAsignarPago"
      :esCompra="true"
      :entidad="ordenAsignar"
      :cliente="ordenAsignar && ordenAsignar.cliente
        ? ordenAsignar.cliente
        : { id:'' }"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarPago = $event;"
    />  -->

    <FormularioOrden
      :mostrarFormularioOrden="mostrarFormularioOrden"
      :es-editar="esEditar"
      :titulo="
        esEditar ? 'Datos de la orden a editar' : 'Datos de la nueva orden'
      "
      :ordenAsignar="ordenAsignar"
      @cerrar="mostrarFormularioOrden = $event"
      @orden="ordenCrear($event);"
      @cliente="cliente = $event"
      @editar="esEditar = $event"
      @alerta-mensaje="alertaMensaje = $event"
    />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view 
        @eliminar="actualizarOrdenes('estatusOrden')"
      />
    </transition>
  </section>
</template>

<script>
import ordenesCompraGql from "@/graphql/ordenesCompra.gql";
import ordenCambiarClienteGql from "@/graphql/ordenCambiarCliente.gql";
import ordenCrearGql from "@/graphql/ordenCrear.gql";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import ClienteBuscador from "@/components/ClienteBuscador.vue"
import CargandoVista from "@/components/CargandoVista.vue";
// import ModalAsignarPago from "@/components/ModalAsignarPago.vue";
import FormularioOrden from "@/components/FormularioOrden.vue";
import { leerRoles } from "@/utils/datosToken.js";
import {obtenerMarcaTemporal} from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle, } from "mdbvue";
export default {
  name: "Ordenes",
  components: {
    mdbBtn,
    AlertaMensaje,
    CargandoVista,
    FormularioOrden,
    // ModalAsignarPago,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    ClienteBuscador
  },
  data() {
    return {
      roles:leerRoles(),
      // Pagos
      // mostrarModalAsignarPago: false,
      // Otros
      buscarPor: { estatus: "" },
      botonDeshabilitado: false,
      mostrarFormularioOrden: false,
      mostrarModalAsignarCliente: false,
      esEditar: false,
      ordenes: [],
      ordenSeleccionada: {},
      orden: {
        enlace: "",
        miniatura: "",
        estatus: "",
        fecha: {},
      },
      ordenAsignar: {
        enlace: "",
        miniatura: "",
        estatus: "",
        fecha: {},
      },
      cliente: {},
      personaId: "",
      alertaMensaje: { contenido: "" },
      OrdenEstatus,
      // Ordenar asc desc
      orderBy: {
        fecha: null,
        monto: null,
      },
      orderByActual: [],
      mostrarMas:true,
      pagina:1
    };
  },
  mounted(){
    this.onScrollChange()
  },
  computed:{
    ocultarOverflowEnVistaOrdenes() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
    variablesOrden() {
        const variables = {
          offset: 0,
          first: 20,
          orderBy:['fecha_desc', 'compraId_desc']
        }
        return variables
      },
  },
  methods: {
    ordenarColumna(columna){
      if(columna == 'fecha'){
        this.orderBy.monto = null;
        this.orderBy.fecha === null
          ? this.orderBy.fecha = false  // se ordena descendente
          : this.orderBy.fecha
            ? this.orderBy.fecha = false   
            : this.orderBy.fecha = true  // se ordena ascendente
        if(this.orderBy.fecha) {
          this.orderByActual = ['fecha_asc']
          return this.actualizarOrdenes('filtro')
        } else {
          this.orderByActual = ['fecha_desc']
          return this.actualizarOrdenes('filtro')
        }
      }
       if(columna == 'monto'){
        this.orderBy.fecha = null
        this.orderBy.monto === null
          ? this.orderBy.monto = false  // se ordena descendente
          : this.orderBy.monto
            ? this.orderBy.monto = false   
            : this.orderBy.monto = true  // se ordena ascendente
        if(this.orderBy.monto) {
          this.orderByActual = ['monto_asc']
          return this.actualizarOrdenes('filtro')
        } else {
          this.orderByActual = ['monto_desc']
          return this.actualizarOrdenes('filtro')
        }
      }
    },
    abrirModalAsignarCliente(ordenEnviada){
      this.ordenSeleccionada = ordenEnviada; 
      this.mostrarModalAsignarCliente = true; 
    },
    onScrollChange() {
      window.onscroll = () => {
        const finalPagina = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
        if (finalPagina && this.mostrarMas) {
          this.ordenesObtenerMas()
        }
      };
    },
    ordenAsignarCliente() {
      if (this.cliente?.id === this.ordenSeleccionada?.cliente?.id){
        return this.alertaMensaje = {
          contenido:  'Este cliente ya se encuentra asignado a la orden',
          tipo: 'advertencia',
        };
      }
      if (!this.cliente.casillero) {
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
          tipo: 'advertencia',
        }
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: 
            ordenCambiarClienteGql,
          variables: {
            ordenId: this.ordenSeleccionada.id,
            clienteNuevoId: this.cliente.id,
          },
          update: (store, {data: {ordenCambiarCliente}}) => {
            const msg = ordenCambiarCliente.codigo;
            switch (msg) {
              case "Correcto":
                break;
              case "Fallido":
                this.alertaMensaje = {
                    contenido:  'Ha ocurrido un error asignando cliente a la orden.'
                    +' Por favor intenta de nuevo',
                    tipo: 'error',
                  };
                this.botonDeshabilitado = false;
                return;
              case "FaltanDatos":
                this.alertaMensaje = {
                    contenido:  'El cliente seleccionado no cuenta con casillero.'
                    +' Asigna una agencia en los detalles del cliente para continuar.',
                    tipo: 'advertencia',
                  };
                this.botonDeshabilitado = false;
                return;
              default:
                this.alertaMensaje = {
                  contenido:  'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  tipo: 'error',
                };
                this.botonDeshabilitado = false;
                return;
            }
            const data = store.readQuery({
              query: ordenesCompraGql,
              variables:this.variablesOrden
            });
           data.Orden.filter(
              (orden) => orden.id === this.ordenSeleccionada.id
            ).map((orden) => {
              const cliente = JSON.parse(JSON.stringify(this.cliente));
              orden.cliente.id = cliente.id;
              orden.cliente.nombre = cliente.nombre;
              orden.cliente.casillero = cliente.casillero;
              orden.compraId = cliente.casillero + "C" + obtenerMarcaTemporal();
            });
            store.writeQuery({
              query: ordenesCompraGql,
              variables:this.variablesOrden,
              data
            });
            this.cliente = {};
            this.ordenSeleccionada = {}
            this.mostrarModalAsignarCliente= false;
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: 'Se ha asignado el cliente a la orden correctamente',
              tipo: 'correcto',
            };
          },
        })
        .catch(()=>{
          this.botonDeshabilitado = false
          return this.alertaMensaje = {
            contenido:  'Ha ocurrido un error asignando cliente a la orden.'
            +' Por favor intenta de nuevo',
            tipo: 'error',
          };
        })
    },
    ordenCrear(orden) {
      delete orden.__typename;
      delete orden.fecha.__typename;
      this.botonDeshabilitado = true;
      this.paginacionResetear()
      this.$apollo
        .mutate({
          mutation: ordenCrearGql,
          variables: {
            orden: orden,
            personaId: this.cliente.id,
          },
          update: (store, { data: { ordenCrear } }) => {
            const data = store.readQuery({
              query: ordenesCompraGql,
              variables:this.variablesOrden
            });
            const ordenAgregar = {
              ...orden,
              ...ordenCrear,
              cliente: {},
            };
            const clienteDatos = JSON.parse(JSON.stringify(this.cliente));
            ordenAgregar.cliente.nombre = clienteDatos?.nombre;
            ordenAgregar.cliente.id = clienteDatos?.id;
            ordenAgregar.cliente.casillero = clienteDatos?.casillero;
            ordenAgregar.compraId = clienteDatos?.casillero ? clienteDatos?.casillero+ 'C'+obtenerMarcaTemporal() : 'Sin especificar';
            ordenAgregar.cliente.__typename = "Cliente";
            ordenAgregar.fecha.__typename = "_Neo4jDateTime";
            ordenAgregar.pago = [];
            ordenAgregar.pagado = true;
            data.Orden.unshift(ordenAgregar);
            store.writeQuery({
              query: ordenesCompraGql,
              variables:this.variablesOrden,
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenCrear: {
              __typename: "Orden",
              id: -1,
              compraId:this.cliente?.casillero ? this.cliente?.casillero+'C'+obtenerMarcaTemporal() : 'Sin especificar',
              estatus: orden.estatus,
              pagado: false,
              cliente: {
                id:this.cliente.id,
                nombre: this.cliente.nombre,
                casillero: this.cliente.casillero,
                __typename: "Cliente",
              },
              pago: [
                {
                  __typename: "Pago",
                  id: '',
                  monto: '',
                  medio: {
                    __typename: "Medio",
                    id: '',
                    nombre: '',
                    descripcion: '',
                  },
                  observacion: '',
                  referencia: '',
                  estatus: '',
                  fecha: {
                  __typename: "_Neo4jDateTime",
                    formatted: '',
                  },
                },
              ],
              fecha: {
                formatted: orden.fecha.formatted,
                __typename: "_Neo4jDateTime",
              },
            },
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.mostrarFormularioOrden = false;
          this.actualizarOrdenes('filtro')
          this.limpiarCamposForm();
          this.alertaMensaje = {
            contenido: "Agregada orden correctamente",
            tipo: "correcto",
          };
        })
        .catch((e) => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: `Ha ocurrido un error agregando la orden. ${e.message.length ? e.message.replace('GraphQL error: ', '') : ''} `,
            tipo: "error",
          };
        });
    },
    abrirModalEditar(ordenSeleccionada) {
      this.orden = ordenSeleccionada;
      this.ordenAsignar = { ...this.orden };
      this.esEditar = true;
      this.mostrarFormularioOrden = true;
    },
    paginacionResetear() {
      this.pagina = 1;
      this.mostrarMas = true;
    },
    ordenesObtenerMas() {
      if(this.botonDeshabilitado) return
      this.botonDeshabilitado = true;
      const ordenesActuales = JSON.parse(JSON.stringify(this.ordenes))
      const filter = {};
      if(this.buscarPor.estatus !== ''  && this.buscarPor.estatus.length){
        Object.assign(filter,{
          estatus:this.buscarPor.estatus
        })
      }
      this.$apollo.queries.ordenes.fetchMore({
        variables: {
          filter,
          offset: this.pagina * this.variablesOrden.first,
          first: this.variablesOrden.first,
          orderBy: this.orderByActual && this.orderByActual.length
            ? this.orderByActual
            : this.variablesOrden.orderBy
        },
        updateQuery: (previusResult, {fetchMoreResult}) => {
          const nuevasOrdenes = JSON.parse(JSON.stringify(fetchMoreResult.Orden));
          this.mostrarMas = nuevasOrdenes.length >= this.variablesOrden.first
          const typename = previusResult?.Orden?.length
            ? previusResult?.Orden[0]?.__typename
            : ''
          if(this.mostrarMas){
            this.pagina++;
          }
        if(!nuevasOrdenes.length){
          this.pagina = 1;
          this.mostrarMas = false;
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Orden:ordenesActuales,
          };  
        }
        if(nuevasOrdenes.length){
          let ordenesObtenidas = [...ordenesActuales, ...nuevasOrdenes];
          if (this.buscarPor.estatus) {
            ordenesObtenidas = ordenesObtenidas.filter(o => o.estatus === this.buscarPor.estatus)
          }
          // Si el filtro de orderByActual esta activo, aplica filtrado de ordenes para evitar warning de duplicados
          if(this.orderByActual.length){
          let ordenes = {};
           ordenesObtenidas = ordenesObtenidas
            .filter(orden => ordenes[orden.id] ? false : ordenes[orden.id] = true);
          }
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Orden: ordenesObtenidas,
          };
        }
        }
      });
    },
    actualizarOrdenes(estatus) {
      if(this.botonDeshabilitado) return
      this.paginacionResetear();
      this.botonDeshabilitado = true;
      const filter = {};
      estatus && this.buscarPor.estatus !== ""
        ? (filter.estatus = this.buscarPor.estatus)
        : "";
      if(!estatus && !this.buscarPor.estatus.length){
        this.orderByActual = [];
        this.orderBy =  {
        fecha: null,
        monto: null,
      }
      }
      const variablesQuery = {
        filter: {},
        offset: 0,
        first: this.variablesOrden.first,
        orderBy: this.orderByActual && this.orderByActual.length
          ? this.orderByActual
          : this.variablesOrden.orderBy
      }
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery,{ 
            orderBy: this.orderByActual
          })
        : Object.assign(variablesQuery,{ 
            orderBy: this.variablesOrden.orderBy
          })
      Object.assign(variablesQuery,{
        offset:0,
        first: this.variablesOrden.first
      })
      if(estatus && this.buscarPor.estatus !== ""){
        variablesQuery.filter.estatus = this.buscarPor.estatus;
      }
      this.$apollo
        .watchQuery({
          query: ordenesCompraGql,
          variables: variablesQuery,
          update: (data) => data.Orden,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Orden) {
              this.ordenes = JSON.parse(JSON.stringify(data.Orden));
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje = {
              contenido: "Ha ocurrido un error actualizando las órdenes",
              tipo: "error",
            };
            this.botonDeshabilitado = false;
          },
        });
    },
    limpiarCamposForm() {
      this.orden = {
        enlace: "",
        miniatura: "",
        estatus: "",
        fecha: {},
      };
      this.cliente = {};
      this.botonDeshabilitado = false;
      this.mostrarFormularioOrden = false;
    },
  },
  apollo: {
    ordenes() {
      return {
        query: ordenesCompraGql,
        update: (data) => data.Orden,
        variables:()=>{
          return this.variablesOrden
        },
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
// Diccionarios
const ConsolidadoTipoEnvio= {
  Maritimo: 'Marítimo',
  Aereo: 'Aéreo',
};

const ConsolidadoTipoEnvioIconos= {
  Maritimo: 'ship',
  Aereo: 'plane-departure',
};

const ConsolidadoEstatus= {
  Cargando: 'Cargando',
  NavieraAerolinea: 'Con Naviera / Aerolínea',
  EnTransito: 'En tránsito',
  EnPuertoAeropuerto: 'En puerto / Aeropuerto',
  ProcesoAduana: 'En proceso de aduana',
  EsperaCaracas: 'Recibido en almacén de Caracas',
  Descargado: 'Listo para despacho de entrega',
};

export {
  ConsolidadoTipoEnvio,
  ConsolidadoTipoEnvioIconos,
  ConsolidadoEstatus,
};
<template>
  <div
    :class="[
      'container-xl',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistausuarios },
    ]"
  >
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Usuarios</h2>
      <div
        v-if="!$apolloData.queries.usuarios.loading"
        class="col-auto"
      >
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3"
          @click="usuariosActualizar"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-sm-0 px-3"
          @click="mostrarModalCrearUsuario = true;"
        >
          Nuevo usuario
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.usuarios.loading"
      clase-adicional="vc-75vh"
    />
    <!-- tabla -->
    <ul
      v-else
      data-table="sticky"
      class="table mt-2 mb-page"
    >
      <li class="encabezado row align-items-end">
        <div
          class="col-9 col-sm-5 col-md-3 filtrable"
          @click="ordenarColumna('nombre')"
        >
          Nombre
          <div
            v-if="orderBy.nombre != null"
            class="sort-iconos animated fadeIn"
          >
            <font-awesome-icon
              icon="sort-up"
              :class="{'activo': orderBy.nombre}"
            />
            <font-awesome-icon
              icon="sort-down"
              :class="{'activo': !orderBy.nombre}"
            />
          </div>
        </div>
        <div class="col-3 d-none d-md-block">
          Tipo / acceso
        </div>
        <div class="col-5 col-md-3 d-none d-sm-block">Contacto</div>
        <div class="col-3 col-sm-2 col-md-3">Acción</div>
      </li>
      <li
        v-for="usuario in usuarios"
        :key="`usuario-${usuario.id}`"
        class="contenido normal row align-items-center"
      >
        <!-- NOMBRE -->
        <div class="col-9 col-sm-5 col-md-3">
          {{ usuario.nombre ? usuario.nombre : 'Sin especificar'}}
          <!-- Agencias relacionadas al usuarios -->
          <div v-if="usuario.roles && usuario.roles.includes('Agencia')" class="pt-2">
            <span class="d-block">
              Agencia{{ usuario.agencias.length > 1 ? 's' : ''}}:
            </span>
            <span
              v-for="(agencia, i) in usuario.agencias"
              :key="`agencia-${i}`"
            >{{i > 0 ? ', ' : ''}}{{agencia.nombre}}</span>
          </div>

          <!-- TIPO / ACCESSO -->
          <div class="d-md-none pt-2">
            Tipo / acceso:
            <div v-if="usuario.roles && usuario.roles.length">
              <span
                v-for="(rol, i) in usuario.roles"
                :key="`rol-${i}`"
              >{{i > 0 ? ', ' : ''}}{{
                UsuarioRoles[rol]
              }}</span>
            </div>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
          </div>

          <!-- CONTACTO -->
          <div class="d-sm-none pt-2">
            <span class="d-block">Usuario:</span>
            {{ usuario.usuario }}
            <span class="d-block">
              Contacto:
            </span>
            <div v-if="usuario.telefonos && usuario.telefonos.length">
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="`tel:+${usuario.telefonos[0].numero}`"
                class="d-block text-break"
                @click.stop
              >
                +{{ usuario.telefonos[0].numero }}
              </a>
              <a
                  :href="`https://wa.me/${usuario.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                >
                  <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  WA
                </a>
                <a
                  :href="`sms:+${usuario.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                >
                  <font-awesome-icon icon="sms" />
                  Sms
                </a>
            </div>
            <span v-else class="text-muted d-block user-select-none">
              Sin teléfonos de contacto
            </span>
            <a
              v-if="usuario.emails
                && usuario.emails.length"
              target="_blank"
              :href="`mailto:${usuario.emails[0].email}`"
              class="d-block text-break"
            >
              {{ usuario.emails[0].email }}
            </a>
            <span
              v-else
              class="text-muted d-block user-select-none"
            >
              Sin email de contacto
            </span>
          </div>
        </div>
        <!-- TIPO / ACCESSO -->
        <div class="col-3 d-none d-md-block">
            <div v-if="usuario.roles && usuario.roles.length">
              <span
                v-for="(rol, i) in usuario.roles"
                :key="`rol-${i}`"
              >{{i > 0 ? ', ' : ''}}{{
                UsuarioRoles[rol]
              }}</span>
            </div>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
        </div>
        <!-- CONTACTO -->
        <div class="col-5 col-md-3 d-none d-sm-block">
          <span class="d-block">Usuario:</span>
          {{ usuario.usuario }}
          <div v-if="usuario.telefonos && usuario.telefonos.length">
            <a
              target="_blank"
              rel="noopener noreferrer"
              :href="`tel:+${usuario.telefonos[0].numero}`"
              class="d-block text-break"
              @click.stop
            >
              +{{ usuario.telefonos[0].numero }}
            </a>
            <a
                :href="`https://wa.me/${usuario.telefonos[0].numero}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
              >
                <font-awesome-icon :icon="['fab', 'whatsapp']" />
                WA
              </a>
              <a
                :href="`sms:+${usuario.telefonos[0].numero}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
              >
                <font-awesome-icon icon="sms" />
                Sms
              </a>
          </div>
          <span v-else class="text-muted d-block user-select-none">
            Sin teléfonos de contacto
          </span>
          <a
            v-if="usuario.emails
              && usuario.emails.length"
            target="_blank"
            :href="`mailto:${usuario.emails[0].email}`"
            class="d-block text-break"
          >
            {{ usuario.emails[0].email }}
          </a>
          <span
            v-else
            class="text-muted d-block user-select-none"
          >
            Sin email de contacto
          </span>
        </div>
        <!-- ACCION -->
        <div class="col-3 col-sm-2 col-md-3 pr-md-0">
          <mdb-btn
             v-if="roles.includes('Admin') || roles.includes('Agencia')"
            flat
            dark-waves
            icon="trash"
            :disabled="botonDeshabilitado"
            icon-class="texto-peligro"
            class="m-0 ml-2 ml-xl-0 py-3 px-2 btn-bordeadoOnHover"
            title="Eliminar usuario"
            @click="IdUsuarioAEliminar = usuario.id;
              mostrarConfirmacionEliminar=true;"
          />
          <mdb-btn
            flat
            dark-waves
            icon="pen"
            :disabled="botonDeshabilitado"
            class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
            title="Editar usuario"
            @click="usuarioSeleccionado=usuario;
              mostrarModalCrearUsuario=true; esEditar = true;"
          />
          <template v-if="roles.includes('Admin')">
          <mdb-btn
            flat
            dark-waves
            icon="lock"
            :disabled="botonDeshabilitado"
            class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
            title="Cambiar contraseña del usuario"
            @click="usuarioSeleccionado = usuario;
              mostrarModalCrearUsuario=true; esCambioDeClave = true;"
          />
          </template>
        </div>
      </li>
      <li v-if="!(usuarios && usuarios.length)" class="no-items">
        No hay usuarios
      </li>
    </ul>
    <!-- Modal Crear Usuario -->
    <FormularioUsuarios
      :usuarioId="usuarioSeleccionado ? usuarioSeleccionado.id : ''"
      :mostrarModal="mostrarModalCrearUsuario"
      :datosUsuario="usuarioSeleccionado && mostrarModalCrearUsuario && esEditar ? usuarioSeleccionado : {}"
      :esEditar="esEditar"
      :esCambioDeClave="esCambioDeClave"
      @cerrar="mostrarModalCrearUsuario = false; esEditar = false; esCambioDeClave = false;"
      @editado="editarUsuario($event)"
      @creado="usuarios.push($event); mostrarModalCrearUsuario = false"
      @alertaMensaje="alertaMensajeAsignar($event)"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      mensaje="Recibimos la orden de eliminar este usuario, es una acción que no puede revertirse"
      @cerrar="mostrarConfirmacionEliminar = $event;"
      @eliminar="usuarioEliminar"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import FormularioUsuarios from "../components/FormularioUsuarios.vue";
import gql from "graphql-tag";
import usuariosGql from "@/graphql/usuarios.gql";
import { UsuarioRoles } from "@/constantes/usuarios.js"
import usuarioEliminarGql from "@/graphql/usuarioEliminar.gql";
import { leerRoles } from "@/utils/datosToken.js";
import {
  mdbBtn,
} from "mdbvue";

export  default {
  name: "usuarios",
  components: {
    mdbBtn,
    FormularioUsuarios,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
  },
  data() {
    return {
      roles:leerRoles(),
      UsuarioRoles,
      alertaMensaje: {contenido: ''},
      mostrarConfirmacionEliminar: false,
      mostrarModalCrearUsuario: false,
      botonDeshabilitado: false,
      mostrarFormulariousuario: false,
      usuarios: [],
      IdUsuarioAEliminar: '',
      usuarioSeleccionado: {},
      esEditar: false,
      esCambioDeClave: false,
      // Ordenar asc desc
      orderBy: {
        tipo: null,
      },
      orderByActual: [],
    };
  },
  computed: {
    ocultarOverflowEnVistausuarios() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
  },
  methods: {
    ordenarColumna(columna){
       if(columna == 'nombre'){
        this.orderBy.nombre === null
          ? this.orderBy.nombre = false  // se ordena descendente
          : this.orderBy.nombre
            ? this.orderBy.nombre = false   
            : this.orderBy.nombre = true  // se ordena ascendente
        if(this.orderBy.nombre) {
          this.orderByActual = ['nombre_asc']
          return this.usuariosActualizar()
        } else {
          this.orderByActual = ['nombre_desc']
          return this.usuariosActualizar()
        }
      }
    },
    editarUsuario(usuarioConFormato){
      this.usuarios.map(usuario => {
          if(usuario.id === this.usuarioSeleccionado.id){
            Object.assign(usuario, usuarioConFormato)
          }
      });
    },
    alertaMensajeAsignar(contenido) {
      if(contenido){
        return this.alertaMensaje = {
          contenido: contenido.contenido,
          tipo: contenido.tipo,
        };
      }
    },
    usuariosActualizar() {
      this.botonDeshabilitado = true;
      const variablesQuery = {};
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery,{ 
            orderBy: this.orderByActual
          })
        : ''
      // Se utiliza apollo.watchQuery, debido a cache-and-network
      this.$apollo
        .watchQuery({
            query: gql`
              ${usuariosGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Usuario,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Usuario){
                this.usuarios = data.Usuario;
              }
              this.botonDeshabilitado = false;
            },
            error: () => {
              this.alertaMensaje = {
                contenido:"Ha ocurrido un error actualizando los usuarios. Por favor intenta de nuevo",
                tipo:"error"
              };
              this.botonDeshabilitado = false;
            }
          })
    },
    usuarioEliminar() {
      const id = this.IdUsuarioAEliminar
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: gql`${usuarioEliminarGql}`,
        variables: {
          personaId: id,
        },
        update: (store, {data: {usuarioEliminar}}) => {
          const codigo = usuarioEliminar.codigo;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha eliminado el usuario satisfactoriamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando el usuario. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
            default:
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
          }
          const data = store.readQuery({
            query: usuariosGql,
          });
          // Filtrar los usuarios, para eliminar el seleccionado
          data.Usuario = data.Usuario.filter((e) => {
            return !id.includes(e.id);
          });
          store.writeQuery({
            query: usuariosGql,
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarConfirmacionEliminar = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            usuarioEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(() => {
        this.alertaMensaje = {
          contenido: 'Ha ocurrido un error eliminando el usuario. Por favor intenta de nuevo',
          tipo: 'error',
        }
      })
    }
  },
  apollo: {
    usuarios() {
      return {
        query: usuariosGql,
        update: (data) => data.Usuario,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
      @close="$emit('cerrar', false);
        limpiarCampos();"
    >
     <mdb-modal-header>
        <mdb-modal-title>
          {{ tituloModal }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!-- <section v-if="esCambioDeClave" class="row">
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.clave
                && validarValores.clave.length},
              {'valido': validarValores.clave == 'valido'},
              {'invalido': validarValores.clave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="clave"
              :type="mostrarCamposClave.clave ? 'text' : 'password'"
              minlength="8"
              label="Clave"
              class="my-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
                @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
              />
            </mdb-input>
            <p
              v-if="validarValores.clave == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la clave
            </p>
          </div>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.confirmacionClave
                && validarValores.confirmacionClave.length},
              {'valido': validarValores.confirmacionClave == 'valido'},
              {'invalido': validarValores.confirmacionClave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="confirmacionClave"
              :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
              minlength="8"
              label="Confirmar clave"
              class="my-2"
              outline
              >
                <mdb-btn
                  slot="append"
                  group
                  flat
                  dark-waves
                  size="md"
                  :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                  :title="mostrarCamposClave.confirmacionClave
                    ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                  @click="mostrarCamposClave.confirmacionClave
                    = !mostrarCamposClave.confirmacionClave"
                />
              </mdb-input>
            <p
              v-if="validarValores.confirmacionClave == 'invalido'"
              class="mensaje-invalido"
            >
              Confirma la clave
            </p>
          </div>
        </section> -->
        <section v-if="!esCambioDeClave">
          <div class="row align-items-center">
            <div
              :class="['col-12 col-md-6',
                {'campo': validarValores.nombre
                  && validarValores.nombre.length},
                {'valido': validarValores.nombre == 'valido'},
                {'invalido': validarValores.nombre == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.trim="usuario.persona.nombre"
                type="text"
                label="Nombre"
                class="my-2"
                outline
              />
              <p
                v-if="validarValores.nombre == 'invalido'"
                class="mensaje-invalido"
              >
                Indica el nombre
              </p>
            </div>
            <div
              :class="['col-12 col-md-6',
                {'campo': validarValores.identificacion
                  && validarValores.identificacion.length},
                {'valido': validarValores.identificacion == 'valido'},
                {'invalido': validarValores.identificacion == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.trim="usuario.persona.identificacion"
                type="text"
                label="Identificación"
                class="my-2"
                outline
              />
              <p
                v-if="validarValores.identificacion == 'invalido'"
                class="mensaje-invalido"
              >
                Indica el identificación
              </p>
            </div>
            <div
              :class="['col-12 col-md-6',
                {'campo': validarValores.genero
                  && validarValores.genero.length},
                {'valido': validarValores.genero == 'valido'},
                {'invalido': validarValores.genero == 'invalido'},
              ]"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="genero-select"
                  v-model="usuario.persona.genero"
                  class="custom-select"
                >
                  <option class="d-none" disabled value="">
                    Seleccione
                  </option>
                  <option
                    v-for="(value, key) in UsuarioGeneros"
                    :key="value+'genero'"
                    :value="key"
                  >
                  {{ value }}
                  </option>
                </select>
                <label
                  for="genero-select"
                  :class="usuario.genero ? 'label-active' : 'label-inactive'"
                >
                  Género
                </label>
              </div>
              <p
                v-if="validarValores.genero == 'invalido'"
                class="mensaje-invalido"
              >
                Selecciona el género
              </p>
            </div>
            <div class="col-12 col-md-6">
              <small class="text-muted user-select-none d-block">
                Usuario
              </small>
              <span
                v-if="esEditar"
                :class="{'font-italic text-muted user-select-none': !(usuario.persona.usuario
                  && usuario.persona.usuario.length)}"
              >
                {{
                  usuario.persona.usuario && usuario.persona.usuario.length
                    ? usuario.persona.usuario
                    : 'No hay un email registrado para asignar el usuario'
                }}
              </span>
              <span
                v-else
                :class="{'font-italic text-muted user-select-none': !(usuario.persona.emails[0]
                  && usuario.persona.emails[0].length)}"
              >
                {{
                  usuario.persona.emails[0] && usuario.persona.emails[0].length
                    ? usuario.persona.emails[0]
                    : 'Agrega un email para asignar el usuario'
                }}
              </span>
            </div>
            <div class="col-12 col-md-6 py-2">
              <label class="opciones-checkbox right">
                <input
                  v-model="usuario.persona.credito"
                  type="checkbox"
                  name="checkbox-credito"
                />
                <span class="icono-checkbox" />
                ¿Crédito admitido?
              </label>
            </div>
          </div>
          <div class="row mt-2">
            <div v-if="!esEditar"  class="col-12 col-lg-6">
              <div class="row align-items-start">
                <div
                  :class="['col pr-1 pr-sm-3',
                    {'campo': validarValores.telefono
                      && validarValores.telefono.length},
                    {'valido': validarValores.telefono == 'valido'},
                    {'invalido': validarValores.telefono == 'invalido'},
                  ]"
                >
                  <mdb-input
                    v-model.number="telefono"
                    id="input-telefono"
                    type="tel"
                    label="Teléfono"
                    placeholder="Ej: 58426573828"
                    minlength="7"
                    maxlength="13"
                    class="my-2 plus-addon"
                    outline
                    @keyup.enter.native="validarTelefono(telefono)"
                  >
                    <span class="input-group-text md-addon" slot="prepend">+</span>
                  </mdb-input>
                  <p
                    v-if="validarValores.telefono == 'invalido'"
                    class="mensaje-invalido prepend"
                  >
                    Indica el teléfono
                  </p>
                </div>
                <mdb-btn
                  color="exitoso"
                  icon="plus"
                  size="sm"
                  class="col-auto mt-2 mr-3 px-2"
                  title="Agregar teléfono"
                  @click="validarTelefono(telefono)"
                >
                  Añadir
                </mdb-btn>
              </div>
              <div
                v-for="(tel, i) in usuario.persona.telefonos"
                :key="'teléfono'+i"
                class="chip delete-btn"
              >
                +{{tel}}
                <mdb-btn
                  class="m-0 ml-2 p-1 pb-2"
                  dark-waves
                  flat
                  icon="trash"
                  size="sm"
                  title="Eliminar teléfono"
                  @click="telefonoRemover(i)"
                />
              </div>
            </div>
            <div v-if="!esEditar"  class="col-12 col-lg-6">
              <div class="row align-items-start">
                <div
                  :class="['col pr-1 pr-sm-3',
                    {'campo': validarValores.email
                      && validarValores.email.length},
                    {'valido': validarValores.email == 'valido'},
                    {'invalido': validarValores.email == 'invalido'},
                  ]"
                >
                  <mdb-input
                    v-model.trim="email"
                    id="input-email"
                    type="email"
                    label="Email"
                    placeholder="Ej: ejemplo@mail.com"
                    class="my-2"
                    outline
                    @keyup.enter.native="validarEmail(email)"
                  />
                  <p
                    v-if="validarValores.email == 'invalido'"
                    class="mensaje-invalido"
                  >
                    Indica el email
                  </p>
                </div>
                <mdb-btn
                  color="exitoso"
                  icon="plus"
                  size="sm"
                  class="col-auto mt-campo-btn mr-3 px-2"
                  title="Agregar email"
                  @click="validarEmail(email)"
                >
                  Añadir
                </mdb-btn>
              </div>
              <div
                v-for="(email, i) in usuario.persona.emails"
                :key="'email'+i"
                class="chip delete-btn"
              >
                {{email}}
                <mdb-btn
                  class="m-0 ml-2 p-1 pb-2"
                  dark-waves
                  flat
                  icon="trash"
                  size="sm"
                  title="Eliminar email"
                  @click="emailRemover(i)"
                />
              </div>
            </div>
            <transition
              mode="out-in"
              name="cambio-input-transicion"
            >
              <div
                v-if="mostrarCampoAgencia"
                key="mostrarCampoAgencia"
                class="col-12 col-lg-6"
              >
                <div class="row align-items-start">
                  <div
                    :class="['col pr-1 pr-sm-3 pr-md-1 pr-lg-3',
                      {'campo': validarValores.agencia
                        && validarValores.agencia.length},
                      {'valido': validarValores.agencia == 'valido'},
                      {'invalido': validarValores.agencia == 'invalido'},
                    ]"
                  >
                    <div class="md-form md-outline outline-select my-2">
                      <select
                        id="agencia-select"
                        v-model="agencia"
                        class="custom-select"
                      >
                        <option class="d-none" disabled value="">
                          Seleccione
                        </option>
                        <option
                          v-for="(value, key) in agencias"
                          :key="key+'agencia'"
                          :value="value.id"
                        >
                        {{ value.nombre }}
                        </option>
                      </select>
                      <label
                        for="agencia-select"
                        :class="usuario.agenciaId && usuario.agenciaId.length
                          ? 'label-active' : 'label-inactive'"
                      >
                        Agencia
                      </label>
                    </div>
                    <p
                      v-if="validarValores.agencia == 'invalido'"
                      class="mensaje-invalido"
                    >
                      Selecciona la agencia
                    </p>
                  </div>
                  <mdb-btn
                    v-if="rol ==='Agencia' || usuario.roles.includes('Agencia') || mostrarCampoAgencia "
                    color="exitoso"
                    icon="plus"
                    size="sm"
                    class="col-auto mt-2 mr-3 px-2"
                    title="Añadir agencia"
                    @click="agregarAgencia(agencia)"
                  >
                    Añadir
                  </mdb-btn>
                </div>
                <div
                  v-if="usuario.agenciaId && usuario.agenciaId.length"
                  :class="['pt-1', {'animated fadeIn': usuario.agenciaId.length}]"
                >
                  <span class="font-weight-bold mr-2">{{usuario.agenciaId.length > 1 ? 'Agencias seleccionadas' : 'Agencia seleccionada'}} :</span>
                  <div
                    v-for="(Agencia, i) in usuario.agenciaId"
                    :key="'agencias'+i"
                    class="chip delete-btn color-terciario"
                  >
                    {{ mostrarAgenciaNombre(Agencia) }}
                    <mdb-btn
                      class="m-0 ml-1 p-1 pb-2"
                      dark-waves
                      flat
                      icon="trash"
                      size="sm"
                      title="Eliminar agencia del usuario"
                      @click="esEditar && usuario.agenciaId.length === 1
                        ? usuario.roles.splice(usuario.roles.indexOf('Agencia'), 1)
                        : usuario.agenciaId.splice(i, 1)"
                    />
                  </div>
                </div>
              </div>
            </transition>
            <div class="col-12 col-lg-6">
              <div class="row align-items-start">
                <div
                  :class="['col pr-1 pr-sm-3 pr-md-1 pr-lg-3',
                    {'campo': validarValores.roles
                      && validarValores.roles.length},
                    {'valido': validarValores.roles == 'valido'},
                    {'invalido': validarValores.roles == 'invalido'},
                  ]"
                >
                  <div class="md-form md-outline outline-select my-2">
                    <select
                      id="roles-select"
                      v-model="rol"
                      class="custom-select"
                    >
                      <option class="d-none" disabled value="">
                        Seleccione
                      </option>
                      <option
                        v-for="(value, key) in rolesFormateados"
                        :key="value+'rol'"
                        :value="key"
                      >
                      {{ value }}
                      </option>
                    </select>
                    <label
                      for="roles-select"
                      :class="usuario.rol ? 'label-active' : 'label-inactive'"
                    >
                      Roles
                    </label>
                  </div>
                  <p
                    v-if="validarValores.roles == 'invalido'"
                    class="mensaje-invalido"
                  >
                    Selecciona el rol
                  </p>
                </div>
                <mdb-btn
                  color="exitoso"
                  icon="plus"
                  size="sm"
                  class="col-auto mt-2 mr-3 px-2"
                  title="Agregar rol"
                  @click="agregarRol(rol)"
                >
                  Añadir
                </mdb-btn>
              </div>
              <div
                v-if="usuario.roles && usuario.roles.length"
                :class="['pt-1', {'animated fadeIn': usuario.roles.length}]"
              >
                <span class="font-weight-bold mr-2">Roles seleccionados:</span>
                <div
                  v-for="(Rol, i) in usuario.roles"
                  :key="'roles'+i"
                  class="chip delete-btn color-terciario"
                >
                  {{ esAgencia ? Rol : UsuarioRoles[Rol]}}
                  <mdb-btn
                    class="m-0 ml-1 p-1 pb-2"
                    dark-waves
                    flat
                    icon="trash"
                    size="sm"
                    title="Eliminar rol del usuario"
                    @click="esEditar && usuario.roles.length === 1 && rol !='Agencia'
                      ? mostrarConfirmacionEliminar = true
                      : usuario.roles.splice(i, 1)"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="!esEditar" class="row">
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.clave
                && validarValores.clave.length},
              {'valido': validarValores.clave == 'valido'},
              {'invalido': validarValores.clave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="clave"
              :type="mostrarCamposClave.clave ? 'text' : 'password'"
              minlength="8"
              label="Clave"
              class="my-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
                @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
              />
            </mdb-input>
            <p
              v-if="validarValores.clave == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la clave
            </p>
          </div>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.confirmacionClave
                && validarValores.confirmacionClave.length},
              {'valido': validarValores.confirmacionClave == 'valido'},
              {'invalido': validarValores.confirmacionClave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="confirmacionClave"
              :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
              minlength="8"
              label="Confirmar clave"
              class="my-2"
              outline
              >
                <mdb-btn
                  slot="append"
                  group
                  flat
                  dark-waves
                  size="md"
                  :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                  :title="mostrarCamposClave.confirmacionClave
                    ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                  @click="mostrarCamposClave.confirmacionClave
                    = !mostrarCamposClave.confirmacionClave"
                />
              </mdb-input>
            <p
              v-if="validarValores.confirmacionClave == 'invalido'"
              class="mensaje-invalido"
            >
              Confirma la clave
            </p>
          </div>
        </section>
        <div class="row justify-content-around">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="col-auto mt-3 px-3"
            :disabled="botonDeshabilitado"
            @click="esCambioDeClave ? validarClave() : validarUsuario()"
          >
          {{ botonPrincipal.texto }}
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      mensaje="Si remueves todos los accesos, se eliminará el usuario en su totalidad"
      @cerrar="mostrarConfirmacionEliminar = $event;"
      @eliminar="usuarioEliminar"
    />
  </section>
</template>
<script>
import usuarioCrearGql from "@/graphql/usuarioCrear.gql";
import usuarioEditarGql from "@/graphql/usuarioEditar.gql";
import usuarioCambiarClaveGql from "@/graphql/usuarioCambiarClave.gql";
import usuariosGql from "@/graphql/usuarios.gql";
import agenciasGql from "@/graphql/agencias.gql";
import empresasGql from "@/graphql/empresas.gql";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import gql from "graphql-tag";
import usuarioEliminarGql from "@/graphql/usuarioEliminar.gql";
import { UsuarioRoles } from "@/constantes/usuarios.js"
import { UsuarioGeneros } from "@/constantes/usuarios.js"
import { evitarElementosRepetidos } from "@/funciones/funciones.js"
import existeUsuarioGql from "@/graphql/existeUsuario.gql"
import {
  capitalizar,
  eliminarVacios,
  validarTel,
  validarMail,
} from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";

export default {
  name:'FomularioUsuarios',
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    usuarioId: {
      type: String,
      required: false,
      default: '',
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    esCambioDeClave: {
      type: Boolean,
      required: false,
      default: false,
    },
    datosUsuario: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ConfirmacionEliminar,
  },
  data(){
    return {
      // Validar si el usuario está relacionado con una agencia o empresa
      esAgencia: false,
      esEmpresa: false,
      // propiedad empresa asignada al editar un usuario
      empresa: '',
      // Modal confirmación eliminar
      mostrarConfirmacionEliminar: false,
      // Otros
      botonDeshabilitado: false,
      telefono: '',
      email: '',
      rol: '',
      UsuarioGeneros,
      UsuarioRoles,
      validarValores: {},
      clave: '',
      confirmacionClave: '',
      mostrarCamposClave: {
        clave: false,
        confirmacionClave: false,
      },
      usuario: {
        persona: {
          nombre: '',
          genero: '',
          credito: '',
          telefonos: [],
          emails: [],
        },
        roles: [],
        telefonos: [],
        emails: [],
        agenciaId: [],
      },
      agencia : ''
    }
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
        if(this.esEditar){
          this.usuarioAsignarDatosAnteriores();
        }
      }
    },
    datosUsuario: function() {
      if(this.datosUsuario) {
        this.usuarioAsignarDatosAnteriores();
      }
    }
  },
  methods: {
    mostrarAgenciaNombre(agenciaId){
      return this.agencias.filter(({id})=>id===agenciaId)[0]?.nombre ?? agenciaId
    },
    agregarAgencia(agenciaId) {
      if(!agenciaId) {
        return this.alertaMensaje('Debe seleccionar una agencia', 'advertencia');
      }
      if(this.usuario.agenciaId.indexOf(agenciaId) === -1) {
        this.usuario.agenciaId.push(agenciaId);
      }else{
        this.alertaMensaje('La agencia ya se encuentra agregada', 'advertencia');
      }
      this.agencia = '';
    },
    agregarRol(rol, skip){
      if(!rol){
          return this.alertaMensaje('Selecciona un rol para continuar',
            'advertencia');}
      if(skip){
        return evitarElementosRepetidos(rol, this.usuario.roles)
          ?  true
          :  false
      }
      evitarElementosRepetidos(rol, this.usuario.roles)
        ? this.usuario.roles.push(rol)
        : this.alertaMensaje('El usuario ya cuenta con el rol que intentas agregar',
          'advertencia');
      this.rol = ''
    },
    usuarioAsignarDatosAnteriores() {
      // Aplicar formato al usuario a enviar
      const usuario = JSON.parse(JSON.stringify(this.datosUsuario));
      const usuarioConFormato = JSON.parse(JSON.stringify(this.usuario));

      if(usuario && Object.entries(usuario).length  && usuarioConFormato){
        usuarioConFormato.persona = {
          nombre: usuario.nombre,
          usuario: usuario.usuario,
          genero: usuario.genero,
          credito: usuario.credito,
          identificacion: usuario.identificacion,
        };
        if(usuario.agencias && usuario.agencias.length){
          this.empresa = usuario.agencias[0].id
        }
        if(usuario.roles && usuario.roles.length){
          this.usuario.roles = usuario.roles
          if(Object.values(this.usuario.roles).includes("Agencia")){
            this.esAgencia = true;
            this.esEmpresa = false;
            Object.assign(this.usuario, {
              agenciaId: usuario.agencias.map( a => a.id),
            });
          }else{
            this.esAgencia = false;
           this.esEmpresa = true;
          }
            
        }
      }
    
      Object.assign(this.usuario.persona, usuarioConFormato.persona);
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    telefonoRemover(index){
      this.usuario.persona.telefonos.splice(index, 1)
    },
    validarTelefono(telefono, skip){
      if(!telefono || !validarTel(telefono)) {
        this.alertaMensaje('Teléfono no válido',
          'error')
        return false
      }else {
         if(skip){
           return true
         }
        evitarElementosRepetidos(`${telefono}`, this.usuario.persona.telefonos)
          ? this.usuario.persona.telefonos.push(`${telefono}`)
          : this.alertaMensaje(
              "Este teléfono ya se encuentra agregado",
              "advertencia"
            );
        return this.telefono = '';
      }
    },
    emailRemover(index){
      this.usuario.persona.emails.splice(index, 1)
    },
    async validarEmail(email, skip){
      try{
        this.botonDeshabilitado = true;
        // Retorna true si se encuentra ya registrado anteriormente el email
        const res = await this.$apollo
          .query({
              query: gql`
                ${existeUsuarioGql}
              `,
              variables: {
                usuario: email
              },
              update: (data) => data.existeUsuario,
              fetchPolicy: "no-cache",
            })
          const codigo = res.data.existeUsuario.codigo;
          this.botonDeshabilitado = false;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje('El email especificado ya se encuentra asignado a otro usuario.'+
                ' Por favor comprueba tus datos', 'advertencia');
              return false
            case 'Fallido':
              this.validarEmailFormat(email, skip)
              return true
            default:
              this.alertaMensaje('Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
                'error')
              return false
          }
      } catch {
          this.botonDeshabilitado = false;
          this.alertaMensaje('Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
            'error')
          return false
      }
    },
    validarEmailFormat(email, skip){
       if(!email || !validarMail(email)){
        this.alertaMensaje('Email no válido',
          'error')
        return false
       }else {
         if(skip){
           return true
         }
        this.usuario.persona.emails.push(email)
        return this.email = '';
      }
    },
    existeRolConEmpresa(roles){
      return roles.some(role=>!role.includes('Agencia')) ? 'operadores' : ''
    },
    validarClave() {
      this.validarValores = {
        clave: this.clave.trim() ? "valido" : "invalido",
        confirmacionClave: this.confirmacionClave.trim() ? "valido" : "invalido",
      };
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Todos los campos son requeridos', 'error');
        this.botonDeshabilitado = false;
        return;
      }
      if(this.clave.length < 8){
        return this.alertaMensaje('La clave debe contener al menos 8 caracteres',
          'advertencia')
      }
      if(this.clave !== this.confirmacionClave){
        this.validarValores.confirmacionClave = "invalido";
        return this.alertaMensaje('La confirmación de la clave no coincide. Por favor verifica tus datos',
          'advertencia')
      }
      this.usuarioCambiarClave()
    },
    async validarUsuario() {
      this.botonDeshabilitado = true;
      this.validarValores = {
        // Datos del usuario
        nombre: this.usuario.persona.nombre ? "valido" : "invalido",
        genero: this.usuario.persona.genero ? "valido" : "invalido",
        roles: this.rol
          || this.usuario.roles.length ? "valido" : "invalido",
        identificacion: this.usuario.persona.identificacion ? "valido" : "invalido",
      };

      if(!this.esEditar){
        if(this.mostrarCampoAgencia){
          Object.assign(this.validarValores, {
            agencia: this.usuario.agenciaId  && this.usuario.agenciaId.length ? "valido" : "invalido",
          })
        }else {
          this.usuario.agenciaId = [];
        }
        Object.assign(this.validarValores, {
          telefono: parseFloat(this.telefono)
            || this.usuario.persona.telefonos.length ? "valido" : "invalido",
          email:  this.email
            || this.usuario.persona.emails.length ? "valido" : "invalido",
        })
      }

      if(this.telefono){
        if(!this.validarTelefono(this.telefono, true)){
          this.botonDeshabilitado = false;
          return Object.assign(this.validarValores, {
            telefono: "invalido",
          })
        }
        if(evitarElementosRepetidos(`${this.telefono}`, this.usuario.persona.telefonos)){
          this.usuario.persona.telefonos.push(`${this.telefono}`)
        } else {
          this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            telefono: "invalido",
          })
          return this.alertaMensaje(
            "Este teléfono ya se encuentra agregado",
            "advertencia"
          );
        }
        this.telefono = "";
      }

      if(this.email){
        const resultValidation = await this.validarEmail(this.email, true)
        if(!resultValidation){
          this.botonDeshabilitado = false;
          return Object.assign(this.validarValores, {
             email: "invalido",
          })
        }
        this.usuario.persona.emails.push(this.email)
        this.email = "";
      }
      if(this.agencia){
      if(this.usuario.agenciaId.indexOf(this.agencia) === -1) {
        this.usuario.agenciaId.push(this.agencia);
      }else{
        this.alertaMensaje('La agencia ya se encuentra agregada', 'advertencia');
        this.botonDeshabilitado = false;
        this.agencia = '';
        return
      }
      this.agencia = '';
      }
      if(this.rol){
         if(!this.agregarRol(this.rol, true)){
          this.botonDeshabilitado = false;
          return this.alertaMensaje('El usuario ya cuenta con el rol que intentas agregar',
          'advertencia');
        }
        this.usuario.roles.push(this.rol);
        this.rol = '';
      }
      if(!this.esEditar){
        // Validar clave
        Object.assign(this.validarValores, {
          clave: this.clave.trim() ? "valido" : "invalido",
          confirmacionClave: this.confirmacionClave.trim() ? "valido" : "invalido",
        });
  
        if(this.clave.length < 8){
          this.botonDeshabilitado = false;
          return this.alertaMensaje('La clave debe contener al menos 8 caracteres',
            'advertencia')
        }
        if(this.clave !== this.confirmacionClave){
          this.validarValores.confirmacionClave = "invalido";
          this.botonDeshabilitado = false;
          return this.alertaMensaje('La confirmación de la clave no coincide. Por favor verifica tus datos',
            'advertencia')
        }
      }

       // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Todos los campos son requeridos', 'error');
        this.botonDeshabilitado = false;
        return;
      }

      // Asignar clave al usuario
      this.usuario.persona.clave = this.clave

      // Los emails de usuario serán los mismo que los de la persona
      this.usuario.emails = this.usuario.persona.emails;
      this.usuario.telefonos = this.usuario.persona.telefonos

      // Aplica formato Capitalize al nombre de usuario
      this.usuario.persona.nombre = capitalizar(this.usuario.persona.nombre);

      const usuario = JSON.parse(JSON.stringify(this.usuario));

      // Eliminar campos vacios
      const usuarioSinCamposVacios = eliminarVacios(usuario)
      if(!Object.entries(usuarioSinCamposVacios.emails).length){
        delete usuarioSinCamposVacios.emails
      }
      if(!Object.entries(usuarioSinCamposVacios.telefonos).length){
        delete usuarioSinCamposVacios.telefonos
      }
      if(!Object.entries(usuarioSinCamposVacios.roles).length){
        delete usuarioSinCamposVacios.roles
      }
      // Prevenir duplicado de datos (Compatibilidad con API)
      delete usuarioSinCamposVacios.persona.telefonos
      delete usuarioSinCamposVacios.persona.emails

      if(this.esEditar){
        usuarioSinCamposVacios.persona.id = this.datosUsuario.id
        return this.usuarioEditar(usuarioSinCamposVacios)
      }
      this.usuarioCrear(usuarioSinCamposVacios);
    },
    usuarioEliminar() {
      const id = this.datosUsuario.id
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: gql`${usuarioEliminarGql}`,
        variables: {
          personaId: id,
        },
        update: (store, {data: {usuarioEliminar}}) => {
          const codigo = usuarioEliminar.codigo;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje('Se ha eliminado el usuario satisfactoriamente',
                'correcto')
              break;
            case 'Fallido':
              this.alertaMensaje('Ha ocurrido un error eliminando el usuario. Por favor intenta de nuevo',
                'error')
              return;
            default:
              this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error')
              return;
          }
          const data = store.readQuery({
            query: usuariosGql,
          });
          // Filtrar los usuarios, para eliminar el seleccionado
          data.Usuario = data.Usuario.filter((e) => {
            return !id.includes(e.id);
          });
          store.writeQuery({
            query: usuariosGql,
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarConfirmacionEliminar = false;
            this.limpiarCampos();
            this.$emit('cerrar', false);
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            usuarioEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(() => {
        this.alertaMensaje('Ha ocurrido un error eliminando el usuario. Por favor intenta de nuevo',
          'error')
      })
    },
    usuarioCrear(usuario) {
      this.botonDeshabilitado = true;
      usuario.persona.acceso = true
      const usuarioCopia = JSON.parse(JSON.stringify(usuario))
      //Verificar si el usuario tiene roles de empresas para agregar campo EmpresaId
      usuario.empresaId = this.existeRolConEmpresa(usuarioCopia.roles);
      this.$apollo
          .mutate({
            mutation: usuarioCrearGql,
            variables: usuario,
            update: (store, { data: { usuarioCrear } }) => {
              const data = store.readQuery({
                query: usuariosGql,
              });
              const usuarioConFormato = {}
              usuarioConFormato.agencias = []
              // Dar formato a los teléfonos y emails
              usuarioConFormato.__typename = "Usuario";
              usuarioConFormato.id = usuarioCrear.id;
              usuarioConFormato.roles = usuarioCopia.roles;
              usuario.persona.credito = usuarioCopia.persona.credito ? true  : false;
              usuario.persona.identificacion = usuarioCopia.persona.identificacion;
              usuario.persona.usuario = usuarioCopia.emails[0]
              usuario.persona.telefonos = usuarioCopia.telefonos.map((x => ({numero: x, __typename:"Telefono"})));
              usuario.persona.emails = usuarioCopia.emails.map((x => ({email: x, __typename:"Email"})));

              Object.assign(usuarioConFormato, usuario.persona)
              if(usuario.roles.includes('Agencia')){
                  usuarioConFormato.agencias  = this.agencias.filter(agencia => usuario.agenciaId.includes(agencia.id))
                }
              data.Usuario.push(usuarioConFormato)
              store.writeQuery({
                query: usuariosGql,
                data,
              });
              this.limpiarCampos();
              this.alertaMensaje('Se ha creado el usuario con exito.', 'correcto');
              this.$emit('cerrar', false);
              this.botonDeshabilitado = false;
            },
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    usuarioCambiarClave() {
      this.$apollo
          .mutate({
            mutation: usuarioCambiarClaveGql,
            variables: {
              personaId: this.usuarioId,
              clave: this.clave
            }
          })
          .then(({data: {usuarioCambiarClave}}) => {
            const msg = usuarioCambiarClave.codigo;
            const usuarioConFormato = {}
            switch (msg) {
              case "Correcto":
                this.alertaMensaje('Se ha modificado la contraseña con exito.', 'correcto');
                this.limpiarCampos();
                this.botonDeshabilitado = false;
                this.$emit('editado', JSON.parse(JSON.stringify(usuarioConFormato)))
                this.$emit('cerrar', false);
                break;
              case "Fallido":
                this.alertaMensaje('Ha ocurrido un error modificando la contraseña.'
                    +' Por favor intenta de nuevo', 'error');
                this.botonDeshabilitado = false;
                break;
              default:
               this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error');
                this.botonDeshabilitado = false;
                break;
            }
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    usuarioEditar(usuario){
      this.$apollo
          .mutate({
            mutation: usuarioEditarGql,
            variables: {
              ...usuario,
              empresa: this.existeRolConEmpresa(this.usuario.roles),
              agenciaId: this.usuario?.agenciaId || [],
            }
          })
          .then(({data: {usuarioEditar}}) => {
            const msg = usuarioEditar.codigo;
            const usuarioConFormato = {}
            switch (msg) {
              case "Correcto":
                // Aplicar formato al usuario a enviar
                Object.assign(usuarioConFormato, usuario.persona)
                usuarioConFormato.roles = usuario.roles;
                if(usuario.roles.includes('Agencia')){
                  usuarioConFormato.agencias  = this.agencias.filter(agencia => usuario.agenciaId.includes(agencia.id))
                }
                this.alertaMensaje('Se ha modificado el usuario con exito.', 'correcto');
                this.limpiarCampos();
                this.botonDeshabilitado = false;
                this.$emit('editado', JSON.parse(JSON.stringify(usuarioConFormato)))
                this.$emit('cerrar', false);
                break;
              case "Fallido":
                this.alertaMensaje('Ha ocurrido un error modificando los datos del usuario.'
                    +' Por favor intenta de nuevo', 'error');
                this.botonDeshabilitado = false;
                break;
              default:
               this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error');
                this.botonDeshabilitado = false;
                break;
            }
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    limpiarCampos() {
      this.usuario = {
        persona: {
          nombre: '',
          genero: '',
          credito: '',
          telefonos: [],
          emails: [],
        },
        roles: [],
        telefonos: [],
        emails: [],
        agenciaId: [],
      },
      this.telefono = '';
      this.email = '';
      this.rol = '';
      this.clave = '';
      this.confirmacionClave = '';
      this.validarValores = {};
      this.esAgencia = false;
      this.esEmpresa = false;
      this.empresa = '';
      this.mostrarCamposClave = {
        clave: false,
        confirmacionClave: false,
      };
    },
  },
  computed: {
    rolesFormateados(){
      return this.UsuarioRoles
    },
    botonPrincipal() {
      return this.esCambioDeClave
      ? {icono: 'sync', texto: 'Cambiar'}
      : this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'};
    },
    tituloModal() {
      return this.esCambioDeClave
        ? 'Cambio de clave'
        : this.esEditar
          ? 'Datos del usuario a editar'
          : 'Datos del nuevo usuario';
    },
    mostrarCampoAgencia() {
      return Object.values(this.usuario.roles).includes("Agencia")
        || this.rol === 'Agencia'
          ? true
          : false
    }
  },
  apollo: {
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        fetchPolicy: "cache-and-network",
      };
    },
    empresas() {
      return {
        query: empresasGql,
        update: (data) => data.Empresa,
        fetchPolicy: "cache-and-network",
      };
    },
    usuarios() {
      return {
        query: usuariosGql,
        update: (data) => data.usuario,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
.cambio-input-transicion-enter-active {animation-name: fadeIn;}
.cambio-input-transicion-leave-active {animation-name: fadeOut;}
.cambio-input-transicion-enter-active,
.cambio-input-transicion-leave-active {animation-duration: .35s;}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","size":"lg","show":_vm.mostrarModal},on:{"close":function($event){return _vm.$emit('cerrar', false);}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.titulo)+" producto ")])],1),_c('mdb-modal-body',[_c('div',{staticClass:"row"},[_c('div',{class:['col-12 col-lg-5',
          {campo: _vm.validarValores.nombre && _vm.validarValores.nombre.length},
          {valido: _vm.validarValores.nombre === 'valido'},
          {invalido: _vm.validarValores.nombre === 'invalido'}
        ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Nombre","outline":""},model:{value:(_vm.producto.nombre),callback:function ($$v) {_vm.$set(_vm.producto, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"producto.nombre"}})],1),_c('div',{class:['col-6 col-lg-3',
          {campo: _vm.validarValores.cantidad && _vm.validarValores.cantidad.length},
          {valido: _vm.validarValores.cantidad === 'valido'},
          {invalido: _vm.validarValores.cantidad === 'invalido'}
        ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"number","label":"Cantidad","min":1,"outline":""},model:{value:(_vm.producto.cantidad),callback:function ($$v) {_vm.$set(_vm.producto, "cantidad", _vm._n($$v))},expression:"producto.cantidad"}})],1),_c('div',{class:['col-6 col-lg-4',
          {campo: _vm.validarValores.precio && _vm.validarValores.precio.length},
          {valido: _vm.validarValores.precio === 'valido'},
          {invalido: _vm.validarValores.precio === 'invalido'}
        ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"number","min":1,"label":"Precio","outline":""},model:{value:(_vm.producto.precio),callback:function ($$v) {_vm.$set(_vm.producto, "precio", _vm._n($$v))},expression:"producto.precio"}},[_c('span',{staticClass:"input-group-text md-addon user-select-none",attrs:{"slot":"append","title":"Dólares"},slot:"append"},[_vm._v(" $ ")])])],1),_c('div',{class:[
          'col-12 col-lg-9',
          { campo: _vm.validarValores.enlace && _vm.validarValores.enlace.length },
          { valido: _vm.validarValores.enlace === 'valido' },
          { invalido: _vm.validarValores.enlace === 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"url","label":"Enlace","outline":"","pattern":"https://.*"},on:{"blur":_vm.cargarImagen},model:{value:(_vm.producto.enlace),callback:function ($$v) {_vm.$set(_vm.producto, "enlace", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"producto.enlace"}}),(_vm.validarValores.enlace == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el enlace web del producto ")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-3 text-center"},[(_vm.cargandoMiniatura)?_c('CargandoVista',{attrs:{"clase-adicional":"vc-50px"}}):(!_vm.cargandoMiniatura && _vm.producto.miniatura !== undefined && !_vm.producto.miniatura.length)?_c('div',{staticClass:"contenedor-miniatura",on:{"click":_vm.cargarImagen}},[_c('font-awesome-icon',{staticClass:"icono-miniatura",attrs:{"icon":"image"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Haz clic aquí para cargar la miniatura ")])],1):_c('img',{staticClass:"cursor-pointer",attrs:{"src":_vm.producto.miniatura,"alt":"Miniatura del Producto","width":"50","height":"50","title":"Haz click aquí para actualizar la miniatura"},on:{"click":_vm.cargarImagen}})],1),_c('div',{class:[
          'col-12 col-lg-auto',
            { campo: _vm.validarValores.estatus && _vm.validarValores.estatus.length },
            { valido: _vm.validarValores.estatus == 'valido' },
            { invalido: _vm.validarValores.estatus == 'invalido' } ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.producto.estatus),expression:"producto.estatus"}],staticClass:"custom-select",attrs:{"id":"estatus-select-form"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.producto, "estatus", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.OrdenEstatus),function(value,key){return _c('option',{key:("producto-" + key),domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('label',{class:_vm.producto.estatus ? 'label-active' : 'label-inactive',attrs:{"for":"estatus-select-form"}},[_vm._v(" Estatus ")])]),(_vm.validarValores.estatus == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el estatus ")]):_vm._e()]),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col"})])]),_c('mdb-modal-footer',{staticClass:"justify-content-center"},[_c('mdb-btn',{attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.btnConfirmar.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":function($event){return _vm.validarProducto()}}},[_vm._v(" "+_vm._s(_vm.btnConfirmar.texto)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <nav :class="['menu-contenedor', {'expandido': mostarMenu }]">
      <div class="menu-encabezado">
        <header @click="cerrarMenu(enlaces[0].ruta)">
          Global Trackit
          <span class="fs-inherit d-block">System</span>
        </header>
        <button
          :class="[mostarMenu ? 'btn-flat texto-claro' : 'sale-btn btn-primario',
            'btn ripple-parent m-1 px-3 py-3 d-lg-none']"
          @click="mostarMenu = !mostarMenu"
        >
          <font-awesome-icon icon="bars" size="lg" />
        </button>
      </div>
      <ul :class="['menu-lista', {'reducida': tieneAccesoModalEscaner}]">
        <li
          v-for="enlace in enlacesPorRol"
          :key="enlace.nombre"
          :class="['menu-enlace',
            {'activo': verificarVistaActiva(enlace.ruta)},
            {'disabled': enlace.deshabilitar}]"
          @click="cerrarMenu(enlace.ruta)"
        >
          <font-awesome-icon
            :icon="[enlace.icono == 'trello' ? 'fab' : 'fas', enlace.icono]"
            class="mx-2"
          />
          {{ enlace.nombre }}
        </li>
      </ul>
      <div class="py-2 text-center text-muted user-select-none">
        <mdb-btn
          v-if="tieneAccesoModalEscaner"
          flat
          dark-waves
          icon="camera"
          icon-class="fa-lg"
          class="btn-bordeado"
          @click="mostrarModalEscanear = !mostrarModalEscanear"
        >
          Escanear
        </mdb-btn>
        <span class="nombre-usuario">
          <i class="fas fa-user-circle"></i>
          {{ leerPersonaNombre() }}
          <small
            v-if="usuario && usuario.usuario"
            class="d-block"
          >
            {{ usuario.usuario }}
          </small>
        </span>
        <small class="d-block">
          ©
          <span class="font-weight-bold fs-inherit letter-spacing-1">
            Global Trackit System
          </span>
        </small>
        <small class="d-block mt-n1">
          Versión
          {{ fechaCompilacion() }}
        </small>
      </div>
    </nav>
    <!-- Modal Escaner -->
    <ModalEscaner
      :mostrar-modal="mostrarModalEscanear"
      :esNavbar="true"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalEscanear = $event;"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import { mdbBtn } from "mdbvue";
import { onLogout } from "../vue-apollo";
import { leerRoles } from "@/utils/datosToken.js";
import ModalEscaner from "@/components/ModalEscaner.vue";
import usuarioGql from "@/graphql/usuario.gql";
import { leerEmpresa, leerPersonaNombre, leerPersonaId } from '@/utils/datosToken.js'
export default {
  name: 'Navbar',
  components: {
    mdbBtn,
    ModalEscaner,
    AlertaMensaje
  },
  data() {
    return {
      // Escaner
      mostrarModalEscanear: false,
      // Usuario
      leerPersonaNombre,
      alertaMensaje: {contenido: ''},
      usuarioId: '',
      rol: leerRoles(),
      // Enlaces del menú
      enlaces: [
        {
          nombre: 'Resumen',
          ruta: '/',
          icono: 'trello',
          roles: ['Admin', 'Agencia', 'OpAlmacen', 'OpPuerto'],
          deshabilitar: false,
        },
        {
          nombre: 'Paquetes',
          ruta: '/paquetes',
          icono: 'boxes',
          roles: ['Admin', 'Agencia', 'OpAlmacen'],
          deshabilitar: false,
        },
        {
          nombre: 'Consolidados',
          ruta: '/consolidados',
          icono: 'ship',
          roles: ['Admin', 'Agencia', 'OpAlmacen', 'OpPuerto'],
          deshabilitar: false,
        },
        {
          nombre: 'Clientes',
          ruta: '/clientes',
          icono: 'users',
          roles: ['Admin', 'Agencia', 'OpAlmacen', 'OpPuerto'],
          deshabilitar: false,
        },
        {
          nombre: 'Pagos',
          ruta: '/pagos',
          icono: 'file-invoice-dollar',
          roles: ['Admin', 'Agencia'],
          deshabilitar: false,
        },
        {
          nombre: 'Compras',
          ruta: '/compras',
          icono: 'money-check-alt',
          roles: ['Admin', 'Agencia'],
          deshabilitar: false,
        },
        {
          nombre: 'Agencias',
          ruta: '/agencias',
          icono: 'map-marked-alt',
          roles: ['Admin', 'Agencia'],
          deshabilitar: false,
        },
        {
          nombre: 'Usuarios',
          ruta: '/usuarios',
          icono: 'user-friends',
          roles: ['Admin'],
          deshabilitar: false,
        },
        {
          nombre: 'Aliados de envío',
          ruta: '/aliados',
          icono: 'handshake',
          roles: ['Admin'],
          deshabilitar: false,
        },
        {
          nombre: 'Medios de pago',
          ruta: '/medios',
          icono: 'hand-holding-usd',
          roles: ['Admin'],
          deshabilitar: false,
        },
        {
          nombre: 'Auditoría',
          ruta: '/auditoria',
          icono: 'user-clock',
          roles: ['Admin'],
          deshabilitar: false,
        },
        {
          nombre: 'Configuración',
          ruta: '/configuracion',
          icono: 'cogs',
          roles: ["Admin"],
          deshabilitar: false,
        },
        {
          nombre: 'Exportar',
          ruta: '/exportar',
          icono: 'file-excel',
          roles: ["Admin"],
          deshabilitar: false,
        },
        {
          nombre: 'Salir',
          ruta: '/login',
          icono: 'sign-out-alt',
          roles: ['Admin', 'Agencia','OpAlmacen', 'OpPuerto'],
          deshabilitar: false,
        },
      ],
      mostarMenu: false,
    };
  },
  mounted(){
    this.usuarioId = leerPersonaId()
  },
  computed: {
    tieneAccesoModalEscaner(){
      if (this.rol &&
        (
          this.rol.includes("Admin") ||
          this.rol.includes("OpAlmacen") ||
          (this.rol.includes("OpPuerto") && this.rol.includes("OpAlmacen")) ||
          this.rol.includes("Agencia")
        )
      ) {
        return true;
      }
      return false
    },
    enlacesPorRol(){
      if (!this.rol) return [];
      const enlaces = [];
      this.enlaces.forEach(e => {
        this.rol.forEach(r => {
          if (e.roles.includes(r)) {
            if (!enlaces.includes(e)) {
              enlaces.push(e);
            }
          }
        });
      })
      return enlaces;
    }
  },
  methods: {
    cerrarMenu(ruta) {
      if (this.mostarMenu) this.mostarMenu = false;
      if (ruta == '/login') this.logout();
      else if (this.$route.path != ruta) this.$router.push(ruta);
    },

    fechaCompilacion() {
      const metaArray = document.head.querySelector('[name=updated]').content
          .split(' ');
      let mes;
      switch (metaArray[1]) {
        case 'Jan':
          mes = '01';
          break;
        case 'Feb':
          mes = '02';
          break;
        case 'Mar':
          mes = '03';
          break;
        case 'Apr':
          mes = '04';
          break;
        case 'May':
          mes = '05';
          break;
        case 'Jun':
          mes = '06';
          break;
        case 'Jul':
          mes = '07';
          break;
        case 'Aug':
          mes = '08';
          break;
        case 'Sep':
          mes = '09';
          break;
        case 'Oct':
          mes = '10';
          break;
        case 'Nov':
          mes = '11';
          break;
        default:
          mes = '12';
          break;
      }
      return [metaArray[2], mes, metaArray[3]].join('/')
        +' '+metaArray[4].substring(0, 5);
    },

    logout() {
      const nombreEmpresa = leerEmpresa();
      onLogout(this.$apolloProvider.defaultClient);
      this.$router.push({
        name: "Login",
        params: { empresa: nombreEmpresa ?? null },
      });
    },

    verificarVistaActiva(ruta) {
      if (this.$route.path == ruta) return true; // Si es la misma ruta
      else if ((this.$route.path.slice(1).split('/')[0] == ruta.slice(1).split('/')[0])
        && (this.$route.path.slice(1).split('/')[0] != '')) return true; // Si comparten ruta y es distinta de '/'
      else return false;
    },
  },
  apollo: {
    usuario() {
      return {
        query: usuarioGql,
        variables() {
          return {
            filter:{
              id: leerPersonaId(),
            }
          }
        },
        update: (data) => data.Usuario[0],
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu-contenedor {
    background-color: #F6F6F9; /* $terciario + blanco */
    box-shadow: 0 0 5px rgb(4, 4, 13, 0.3); /* $oscuro + opacidad */
    position: fixed;
    transition: transform .5s ease;
    width: 230px;
    z-index: 10;

    @media screen and (max-width: 991px) {transform: translateX(-100%);}

    &.expandido {
      @media screen and (max-width: 991px) {transform: translateX(0);}
    }

    .menu-encabezado {
      align-items: center;
      background-color: $terciario;
      border-radius: 0 0 7px 0;
      color: $claro;
      display: flex;
      flex-flow: row nowrap;
      padding: .75rem 0;
      position: relative;

      &::before {
        background: $terciario;
        border-radius: 0 0 0 50px;
        bottom: -16px;
        content: '';
        height: 34px;
        left: -1px;
        position: absolute;
        transform: rotate(-8deg);
        width: 97%;
        z-index: 0;
      }

      header {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        text-align: center;
        user-select: none;
        width: 100%;
        z-index: 1;
      }

      button {
        position: absolute;
        right: -4px;
        transition: right .5s ease;
        z-index: 2;
      }
      button.sale-btn {right: -62px;}
    }

    .menu-lista { // menú general
      height: calc(100vh - 120px - 42px); /* - encabezado - (fechaCompilacion / 2) */
      list-style-type: none;
      margin-bottom: 0;
      overflow-y: auto;
      padding: 1.75rem 0 1rem;
    }

    .menu-lista.reducida { // Cambia altura del menú cuando tienen permitido escanear QR o Barras
      height: calc(100vh - 120px - 104px); /* - encabezado - (fechaCompilacion / 2) */
    }

    .menu-lista .menu-enlace {
      color: $oscuro;
      cursor: pointer;
      margin: .25rem .75rem;
      padding: .4rem .5rem;
      transition: color .5s ease, font-weight .5s ease;
      user-select: none;

      &.activo {
        color: $primario;
        font-weight: 700;
      }

      &.disabled {color: #757575;}

      svg {width: 20px !important;}
    }

    .nombre-usuario {
      display: block;
      font-size: .8rem;
      line-height: 1.3;
      margin-bottom: .25rem;
    }
  }
</style>

<template>
  <div
    :class="[
      'container-xl',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaclientes },
    ]"
  >
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Clientes</h2>
      <!-- Aplicar estilo para que no desaparezca forzadamente los botones -->
      <div class="col-auto" v-if="$route.name === 'Clientes'">
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3"
          @click="buscarClientes"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          v-if="roles.includes('Admin') || roles.includes('Agencia')"
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-sm-0 px-3"
          @click="mostrarFormularioCliente = true"
        >
          Nuevo cliente
        </mdb-btn>
      </div>
    </div>
    <div class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0">
      <div class="col-auto pl-sm-2">
        <mdb-btn
          flat
          dark-waves
          icon="list"
          :disabled="botonDeshabilitado"
          class="btn-bordeado mx-0 my-sm-0 py-2 px-3"
          @click="
            agenciaSeleccionada = '';
            buscarClientes();
            buscarPor.nombre = '';
          "
        >
          Todos
        </mdb-btn>
      </div>
      <div
        :class="['col-12', !idAgenciasToken.length || !roles.includes('Agencia')
          ? 'col-md-6 col-xl order-sm-2 order-md-1 px-md-2'
          : 'col-sm']"
      >
        <mdb-input
          v-model.trim="buscarPor.nombre"
          type="text"
          label="Buscar"
          class="my-2"
          outline
          placeholder="Ingrese el nombre o casillero del cliente"
          title="Ingrese el nombre o casillero del cliente"
          @input="!buscarPor.nombre.trim() ? buscarClientes() : ''"
        />
      </div>
      <div
        v-if="!idAgenciasToken.length || !roles.includes('Agencia')"
        class="col-12 col-sm order-sm-1 order-md-2"
      >
        <div class="md-form md-outline outline-select my-2">
          <select
            id="agencia-select"
            v-model="agenciaSeleccionada"
            class="custom-select"
            @change="buscarClientes()"
          >
            <option class="d-none" disabled value="">Seleccione</option>
            <option
              v-for="agencia in agencias"
              :key="agencia.id"
              :value="agencia.id"
            >
              {{ agencia.nombre }}
            </option>
          </select>
          <label
            for="agencia-select"
            :class="agenciaSeleccionada ? 'label-active' : 'label-inactive'"
          >
            Agencia
          </label>
        </div>
      </div>
    </div>

    <!-- tabla -->
    <ul data-table="sticky" class="table mb-page">
      <li class="encabezado row">
        <div class="col col-lg-3 filtrable" @click="ordenarColumna('cliente')">
          Cliente
          <div
            v-if="orderBy.cliente != null"
            class="sort-iconos animated fadeIn"
          >
            <font-awesome-icon
              icon="sort-up"
              :class="{ 'activo': orderBy.cliente }"
            />
            <font-awesome-icon
              icon="sort-down"
              :class="{ 'activo': !orderBy.cliente }"
            />
          </div>
        </div>
        <div
          class="col col-sm-3 d-none d-sm-block filtrable"
          @click="ordenarColumna('casillero')"
        >
          Casillero
          <div
            v-if="orderBy.casillero != null"
            class="sort-iconos animated fadeIn"
          >
            <font-awesome-icon
              icon="sort-up"
              :class="{ 'activo': orderBy.casillero }"
            />
            <font-awesome-icon
              icon="sort-down"
              :class="{ 'activo': !orderBy.casillero }"
            />
          </div>
        </div>
        <div
          class="col col-md-2 d-none d-md-block filtrable"
          @click="ordenarColumna('desde')"
        >
          Cliente desde
          <div v-if="orderBy.desde != null" class="sort-iconos animated fadeIn">
            <font-awesome-icon
              icon="sort-up"
              :class="{ 'activo': orderBy.desde }"
            />
            <font-awesome-icon
              icon="sort-down"
              :class="{ 'activo': !orderBy.desde }"
            />
          </div>
        </div>
        <div class="col-5 col-sm">Contactos</div>
      </li>
      <CargandoVista
        v-if="$apolloData.queries.clientes.loading"
        clase-adicional="vc-160px"
      />
      <li
        v-else
        v-for="cliente in clientes"
        :key="`cliente-${cliente.id}`"
        class="contenido row align-items-center"
        @click="$router.push({ name: 'Cliente', params: { id: cliente.id } })"
      >
        <div class="col col-lg-3 pl-0 pl-sm-2">
          {{ cliente.nombre }}
          <span
            v-if="cliente.creadoEl && cliente.creadoEl.formatted"
            class="d-block d-md-none"
          >
            Desde: {{ cliente.creadoEl.formatted.substring(0, 10) }}
          </span>
          <p class="mb-0 d-sm-none">
            Casillero:
            <span
              :class="{ 'text-muted user-select-none': !cliente.casillero }"
            >
              {{ cliente.casillero ? cliente.casillero : "Sin especificar" }}
            </span>
          </p>
          <span class="d-block" v-if="cliente.credito">Crédito: Sí</span>
        </div>
        <div
          :class="[
            'col col-sm-3 d-none d-sm-block',
            { 'text-muted user-select-none': !cliente.casillero },
          ]"
        >
          {{ cliente.casillero ? cliente.casillero : "Sin especificar" }}
        </div>
        <div
          :class="[
            'col col-md-2 d-none d-md-block',
            {
              'text-muted user-select-none': !(
                cliente.creadoEl && cliente.creadoEl.formatted
              ),
            },
          ]"
        >
          {{
            cliente.creadoEl && cliente.creadoEl.formatted
              ? cliente.creadoEl.formatted.substring(0, 10)
              : "Sin especificar"
          }}
        </div>
        <div class="col-5 col-sm">
          <div
            v-if="
              (cliente.telefonos &&
                cliente.telefonos.length &&
                cliente.telefonos[0].numero) ||
              (cliente.emails &&
                cliente.emails.length &&
                cliente.emails[0].email)
            "
            class="row mx-n2 mr-lg-0"
          >
            <template
              v-if="
                cliente.telefonos &&
                cliente.telefonos.length &&
                cliente.telefonos[0].numero
              "
            >
              <div class="col-6 col-sm pl-0 pr-1 pr-sm-0">
                <mdb-btn
                  block
                  flat
                  dark-waves
                  tag="a"
                  :href="`tel:+${cliente.telefonos[0].numero}`"
                  role="button"
                  icon="phone-alt"
                  icon-class="fa-lg"
                  size="sm"
                  class="btn-bordeado m-1 py-2 px-1"
                  title="Llamada"
                  @click.native.stop
                />
              </div>
              <div class="col-6 col-sm pl-1 pr-0 pr-sm-1">
                <mdb-btn
                  block
                  flat
                  dark-waves
                  tag="a"
                  :href="`sms:+${cliente.telefonos[0].numero}`"
                  role="button"
                  icon="sms"
                  icon-class="fa-lg"
                  size="sm"
                  class="btn-bordeado m-1 py-2 px-1"
                  title="SMS"
                  @click.native.stop
                />
              </div>
              <div class="col-6 col-sm pl-0 pr-1 pr-sm-0">
                <mdb-btn
                  block
                  flat
                  dark-waves
                  tag="a"
                  :href="`https://wa.me/${cliente.telefonos[0].numero}`"
                  role="button"
                  fab
                  icon="whatsapp"
                  icon-class="fa-lg"
                  size="sm"
                  class="btn-bordeado m-1 py-2 px-1"
                  title="WhatsApp"
                  @click.native.stop
                />
              </div>
            </template>
            <div
              v-if="
                cliente.emails &&
                cliente.emails.length &&
                cliente.emails[0].email
              "
              :class="[
                'col-6 col-sm px-0',
                { 'pl-1': cliente.telefonos && cliente.telefonos.length },
              ]"
            >
              <mdb-btn
                block
                flat
                dark-waves
                tag="a"
                :href="`mailto:${cliente.emails[0].email}`"
                role="button"
                icon="envelope"
                icon-class="fa-lg"
                size="sm"
                class="btn-bordeado m-1 py-2 px-1"
                title="E-mail"
                @click.native.stop
              />
            </div>
          </div>
          <span v-else class="text-muted user-select-none">
            Sin especificar
          </span>
        </div>
      </li>
      <li v-if="!(clientes && clientes.length)" class="no-items">
        No hay clientes
      </li>
    </ul>

    <!-- Modal Crear Cliente -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarFormularioCliente"
      @close="mostrarFormularioCliente = false"
    >
      <mdb-modal-header>
        <mdb-modal-title> Datos del cliente </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <NuevoCliente
          :mostrarFormulario="mostrarFormularioCliente"
          @cerrar="mostrarFormularioCliente = false"
          @creado="
            buscarClientes();
            mostrarFormularioCliente = false;
          "
          @alertaMensaje="alertarMensaje($event)"
        />
      </mdb-modal-body>
    </mdb-modal>

    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import NuevoCliente from "@/components/NuevoCliente.vue";
import gql from "graphql-tag";
import clientesGql from "@/graphql/clientes.gql";
import clienteBuscadorFormGql from "@/graphql/clienteBuscadorForm.gql";
import agenciasGql from "@/graphql/agencias.gql";
import { leerRoles, leerAgencias } from "@/utils/datosToken.js";
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: "clientes",
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    NuevoCliente,
    AlertaMensaje,
    CargandoVista,
  },
  data() {
    return {
      roles: leerRoles(),
      idAgenciasToken: leerAgencias(),
      alertaMensaje: { contenido: "" },
      botonDeshabilitado: false,
      mostrarFormularioCliente: false,
      clientes: [],
      validarValores: {},
      buscarPor: {
        nombre: "",
      },
      // Ordenar asc desc
      orderBy: {
        cliente: null,
        casillero: null,
        desde: null,
      },
      orderByActual: [],
      agenciaSeleccionada: "",
    };
  },
  mounted() {
    this.buscarClientes();
  },
  computed: {
    ocultarOverflowEnVistaclientes() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
  },
  methods: {
    ordenarColumna(columna) {
      if (columna == "cliente") {
        this.orderBy.casillero = null;
        this.orderBy.desde = null;
        this.orderBy.cliente === null
          ? (this.orderBy.cliente = false) // se ordena descendente
          : this.orderBy.cliente
          ? (this.orderBy.cliente = false)
          : (this.orderBy.cliente = true); // se ordena ascendente
        if (this.orderBy.cliente) {
          this.orderByActual = ["nombre_asc"];
          return this.buscarClientes();
        } else {
          this.orderByActual = ["nombre_desc"];
          return this.buscarClientes();
        }
      }
      if (columna == "casillero") {
        this.orderBy.desde = null;
        this.orderBy.cliente = null;
        this.orderBy.casillero === null
          ? (this.orderBy.casillero = false) // se ordena descendente
          : this.orderBy.casillero
          ? (this.orderBy.casillero = false)
          : (this.orderBy.casillero = true); // se ordena ascendente
        if (this.orderBy.casillero) {
          this.orderByActual = ["casillero_asc"];
          return this.buscarClientes();
        } else {
          this.orderByActual = ["casillero_desc"];
          return this.buscarClientes();
        }
      }
      if (columna == "desde") {
        this.orderBy.cliente = null;
        this.orderBy.casillero = null;
        this.orderBy.desde === null
          ? (this.orderBy.desde = false) // se ordena descendente
          : this.orderBy.desde
          ? (this.orderBy.desde = false)
          : (this.orderBy.desde = true); // se ordena ascendente
        if (this.orderBy.desde) {
          this.orderByActual = ["creadoEl_asc"];
          return this.buscarClientes();
        } else {
          this.orderByActual = ["creadoEl_desc"];
          return this.buscarClientes();
        }
      }
    },
    alertarMensaje(mensaje) {
      if (mensaje && mensaje.contenido && mensaje.tipo) {
        return (this.alertaMensaje = {
          contenido: mensaje.contenido,
          tipo: mensaje.tipo,
        });
      }
    },
    buscarClientes() {
      // Se utiliza apollo.watchQuery, debido a cache-and-network
      const variablesQuery = {};
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery, {
            orderBy: this.orderByActual,
          })
        : "";
      if (this.roles.includes("Agencia") && this.idAgenciasToken.length) {
        Object.assign(variablesQuery, {
          filter: {
            agencia_in: this.idAgenciasToken.map((id) => ({ id })),
          },
        });
      }
      this.agenciaSeleccionada && this.agenciaSeleccionada.length
        ? Object.assign(variablesQuery, {
            filter: {
              agencia_in: [{ id: this.agenciaSeleccionada }],
            },
          })
        : "";
      this.$apollo
        .watchQuery({
          query: gql`
            ${clientesGql}
          `,
          variables: variablesQuery,
          update: (data) => data.Cliente,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Cliente) {
              this.clientes = data.Cliente;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.botonDeshabilitado = false;
            return (this.alertaMensaje = {
              contenido: "Ha ocurrido un error obteniendo los clientes",
              tipo: "error",
            });
          },
        });
    },
  },
  apollo: {
    clientes() {
      return {
        query: gql`
          ${clienteBuscadorFormGql}
        `,
        variables() {
          return {
            nombre: this.buscarPor.nombre,
            agenciaId:
              this.roles.includes("Agencia") && this.idAgenciasToken.length
                ? this.idAgenciasToken
                : this.agenciaSeleccionada && this.agenciaSeleccionada.length
                ? [this.agenciaSeleccionada]
                : null,
          };
        },
        skip: () =>
          this.buscarPor.nombre.trim() && this.buscarPor.nombre.length > 2
            ? false
            : true,
        debounce: "1000",
        update: (data) => data.clienteBuscadorForm,
        fetchPolicy: "cache-and-network",
      };
    },
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
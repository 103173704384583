<template>
  <div class="container">
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Auditoría</h2>
      <div class="col-auto">
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3"
          @click="cambiosActualizar()"
        >
          Actualizar
        </mdb-btn>
      </div>
    </div>
      <!-- Tabla -->
      <ul data-table="sticky" class="table">
        <li class="encabezado row align-items-end">
          <div
            class="col-2 d-none d-lg-block filtrable"
            @click="ordenarColumna('fecha')"
          >
            Fecha
            <div
              v-if="orderBy.fecha != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.fecha}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.fecha}"
              />
            </div>
          </div>
          <div class="col">Descripción</div>
          <div
            class="col col-lg-3 d-none d-sm-block filtrable"
            @click="ordenarColumna('autor')"
          >
            Autor
            <div
              v-if="orderBy.autor != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.autor}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.autor}"
              />
            </div>
          </div>
          <div
            class="col col-lg-3 d-none d-md-block"
          >
            Cliente
          </div>
        </li>
        <li v-if="cambios && !cambios.length" class="no-items">
          No hay cambios
        </li>
        <li
          v-else
          v-for="(cambio, i) in cambios"
          :key="`cambio-${i}`"
          class="contenido normal row align-items-center"
        >
          <div class="col-2 d-none d-lg-block">
            {{ fechaYHoraConFormato(cambio.fechaHora.formatted) }}
          </div>
          <div class="col">
            <p class="mb-2 d-lg-none">
              Fecha: {{ fechaYHoraConFormato(cambio.fechaHora.formatted) }}
            </p>
            Descripción:
            <pre v-if="cambio.descripcion" class="ff-roboto mb-0">{{cambio.descripcion}}</pre>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
            <p class="mb-0" v-if="cambio.defectuoso">
              Defectuoso:
              <span>
                {{ cambio.defectuoso ? 'Si' : '' }}
              </span>
            </p>
            <p class="mb-0" v-if="cambio.pagado">
              Pagado:
              <span>
                {{ cambio.pagado ? 'Si' : '' }}
              </span>
            </p>
            <p
              v-if="(cambio.nuevoEstatus && cambio.entidad) && estatusAuditoria(cambio) !== 'Sin especificar'"
              class="mb-2"
            >
              Estatus: {{ estatusAuditoria(cambio) }}
            </p>
            <p
              v-if="cambio.entidad==='Paquete' && cambio.tracking && cambio.tracking.length"
              class="mb-2"
            >
              Tracking: {{cambio.tracking}}
            </p>
            <p class="mb-2 d-sm-none">
              Autor:
              <span :class="{'text-muted user-select-none': !cambio.autor}">
                {{ cambio.autor ? cambio.autor : 'Sin especificar' }}
              </span>
            </p>
            <p class="mb-0 d-md-none">
              Cliente:
              <span :class="{'text-muted user-select-none': !cambio.autor}">
                {{ cambio.cliente ? cambio.cliente : 'Sin especificar' }}
              </span>
            </p>
            <p
              v-if="cambio.idPaquete && cambio.idPaquete.length && cambio.entidad==='Paquete'"
              class="mb-2"
            >
              <router-link :to="'/paquetes/'+cambio.idPaquete">
                Ver detalles del paquete
                <font-awesome-icon icon="angle-right" />
              </router-link>
            </p>
            <p
              v-if="cambio.descripcion && cambio.descripcion.length && cambio.descripcion.includes('ConsolidadoID') && !cambio.descripcion.includes('Consolidado eliminado')"
              class="mb-2"
            >
              <router-link :to="'/consolidados/'+obtenerIdConsolidadoDescripcion(cambio.descripcion)">
                Ver detalles del consolidado
                <font-awesome-icon icon="angle-right" />
              </router-link>
            </p>
          </div>
          <div :class="['col col-lg-3 d-none d-sm-block', {'text-muted user-select-none': !cambio.autor}]">
            {{ cambio.autor ? cambio.autor : 'Sin especificar' }}
          </div>
          <div :class="['col col-lg-3 d-none d-md-block', {'text-muted user-select-none': !cambio.cliente}]">
            {{ cambio.cliente ? cambio.cliente : 'Sin especificar' }}
          </div>
        </li>
        <li v-if="$apolloData.queries.cambios.loading">
          <CargandoVista clase-adicional="vc-50px" />
        </li>
      </ul>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import cambiosGql from "@/graphql/cambios.gql";
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import { EstatusPago } from "@/constantes/pagos.js"
import { OrdenEstatus } from "@/constantes/ordenes.js"
import { ConsolidadoEstatus } from "@/constantes/consolidados.js"
import {
  mdbBtn,
} from "mdbvue";

export default {
  name: "Auditoría",
  components: {
    mdbBtn,
    CargandoVista,
    AlertaMensaje
  },
  data() {
    return {
      // Paginación
      // Logintud de página recomendada: '10'
      paginaLongitud: 20,
      pagina: 1,
      mostrarMas: true,
      // Otros
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      cambios: [],
      PaqueteEstatus,
      // Ordenar columnas
      orderBy: {
        fecha: false,
        autor: null,
      },
      orderByActual: [],
      EstatusPago,
      OrdenEstatus,
      ConsolidadoEstatus,
    };
  },
  mounted(){
    this.scroll();
    this.cambiosActualizar()
  },
  computed:{
    variablesUpdateQueryViewCambios() {
      return  {
        offset: 0,
        first: this.paginaLongitud,
        orderBy: ['fechaHora_desc']
      }
    },
  },
  methods: {
    ordenarColumna(columna){
      if(columna == 'fecha'){
        this.orderBy.autor = null;
        this.orderBy.fecha === null
          ? this.orderBy.fecha = false  // se ordena descendente
          : this.orderBy.fecha
            ? this.orderBy.fecha = false   
            : this.orderBy.fecha = true  // se ordena ascendente
        if(this.orderBy.fecha) {
          this.orderByActual = ['fechaHora_asc']
          this.cambiosActualizar()
        } else {
          this.orderByActual = ['fechaHora_desc']
          this.cambiosActualizar()
        }
      }
       if(columna == 'autor'){
        this.orderBy.fecha = null;
        this.orderBy.cliente = null;
        this.orderBy.autor === null
          ? this.orderBy.autor = false  // se ordena descendente
          : this.orderBy.autor
            ? this.orderBy.autor = false   
            : this.orderBy.autor = true  // se ordena ascendente
        if(this.orderBy.autor) {
          this.orderByActual = ['autor_asc']
          this.cambiosActualizar()
        } else {
          this.orderByActual = ['autor_desc']
          this.cambiosActualizar()
        }
      }
    },
    // Formatear fecha y hora
    fechaYHoraConFormato(fecha){
      const fechaFormato = new Date(fecha)
      return fechaFormato.toLocaleString()
    },
    // Scroll infinito
    scroll() {
      window.onscroll = () => {
        let finalDeLaPagina = document.documentElement.scrollTop
          + window.innerHeight === document.documentElement.offsetHeight;
        if (finalDeLaPagina && this.mostrarMas) {
          this.cambiosBuscarMas()
        }
      };
    },
    estatusAuditoria(auditoriaInfo) {
      const entidadNoEncontrada = 'Sin especificar';
      const ENTIDADES_AUDITORIA = {
        Paquete: this.PaqueteEstatus[auditoriaInfo.nuevoEstatus],
        Pago: this.EstatusPago[auditoriaInfo.nuevoEstatus],
        Orden: this.OrdenEstatus[auditoriaInfo.nuevoEstatus],
        Consolidado: this.ConsolidadoEstatus[auditoriaInfo.nuevoEstatus],
      };
      return ENTIDADES_AUDITORIA[auditoriaInfo.entidad] || entidadNoEncontrada;
    },
    cambiosBuscarMas() {
      this.$apollo.queries.cambios.fetchMore({
        variables: {
          offset: this.pagina * this.paginaLongitud,
          first: this.paginaLongitud,
          orderBy: this.orderByActual && this.orderByActual.length
            ? this.orderByActual
            : this.variablesUpdateQueryViewCambios.orderBy
        },
        updateQuery: (previusResult, {fetchMoreResult}) => {
          const nuevosCambios = fetchMoreResult.Cambio;
          const restoCambios = fetchMoreResult.Cambio.length >= this.paginaLongitud
            ? true
            : false
          this.mostrarMas = restoCambios;
          if(restoCambios){
            this.pagina++;
          }
          const cambiosConcatenados = [...previusResult.Cambio, ...nuevosCambios];
          let hash = {};
          const cambiosSinDuplicados = cambiosConcatenados
            .filter(p => hash[p._id] ? false : hash[p._id] = true);
          return {
            __typename: previusResult.Cambio.__typename,
            Cambio: cambiosSinDuplicados,
            hasMore: this.mostrarMas,
          };
        }
      });
    },
    paginacionResetear() {
      this.pagina = 1;
      this.mostrarMas = true;
    },
    cambiosActualizar() {
      const variablesQuery = {
        offset: 0,
        first: this.variablesUpdateQueryViewCambios.first,
        orderBy: this.orderByActual && this.orderByActual.length
          ? this.orderByActual
          : this.variablesUpdateQueryViewCambios.orderBy
      }
      this.paginacionResetear()
      this.botonDeshabilitado = true;
      this.$apollo
        .watchQuery({
          query: gql`
            ${cambiosGql}
          `,
          variables: variablesQuery,
          update: (data) => data.Cambio,
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.Cambio){
              this.cambios = data.Cambio;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error actualizando las auditorías. Por favor intenta de nuevo',
              tipo: 'error',
            };
            this.botonDeshabilitado = false;
          }
        })
    },
    obtenerIdConsolidadoDescripcion(descripcion){
      const indiceConsolidadoId = descripcion.search("ConsolidadoID");
      const stringConsolidado = descripcion.slice(indiceConsolidadoId);
      const idString = stringConsolidado.split(" ").at(1);
      return idString.replace(/\./g, '');
    }
  },
  apollo: {
    cambios() {
      return {
        query() {
          return gql`${cambiosGql}`;
        },
        variables:() => {
          return this.variablesUpdateQueryViewCambios
        },
        debounce: 1000,
        update: (data) => data.Cambio,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ff-roboto {
  font-size: .85rem;
  white-space: break-spaces;
}
</style>

<template>
  <div :class="['text-center text-muted vertical-centered', claseAdicional]">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115 115"
      class="loader fa-spin"
    >
      <circle cx="57.5" cy="57.5" r="51" stroke="url(#paint0_linear_52:7)" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="57.5" cy="57.5" r="51" stroke="url(#paint1_linear_52:7)" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M88.6509 25.6614L98.2108 16.8919L100.773 19.6855C103.195 22.3253 103.018 26.4285 100.378 28.8501V28.8501C97.7383 31.2718 93.6351 31.0948 91.2135 28.4549L88.6509 25.6614Z" fill="#FCC626"/>
      <defs>
        <linearGradient id="paint0_linear_52:7" x1="60.375" y1="59.8" x2="60.95" y2="60.375" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FCC626"/>
          <stop offset="1" stop-color="#FCC626" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_52:7" x1="32.2" y1="106.95" x2="75.9" y2="80.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#071A6A"/>
          <stop offset="0.65625" stop-color="#071A6A" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
    <small class="d-block">
      CARGANDO
    </small>
  </div>
</template>

<script>
export default {
  name: 'CargandoVista',
  props: {
    claseAdicional: {
      type: [String, Array],
      default: '',
    }
  }
}
</script>
<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModalAliado"
    @close="cerrarModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row justify-content-center" @submit.prevent>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.nombre
              && validarValores.nombre.length},
            {'valido': validarValores.nombre == 'valido'},
            {'invalido': validarValores.nombre == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="aliado.nombre"
            type="text"
            label="Nombre del aliado"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.nombre === 'invalido'"
            class="mensaje-invalido"
          >
            Indica el nombre del aliado
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.contacto
              && validarValores.contacto.length},
            {'valido': validarValores.contacto == 'valido'},
            {'invalido': validarValores.contacto == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="aliado.contacto"
            type="text"
            label="Contacto"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.contacto === 'invalido'"
            class="mensaje-invalido"
          >
            Indica el nombre del contacto
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.telefono
                  && validarValores.telefono.length},
                {'valido': validarValores.telefono == 'valido'},
                {'invalido': validarValores.telefono == 'invalido'},
              ]"
            >
              <mdb-input
                v-model.number="telefono"
                id="input-telefono"
                type="tel"
                label="Teléfono del contacto"
                placeholder="Ej: 58426573828"
                minlength="7"
                maxlength="13"
                class="my-2 plus-addon"
                outline
                @keyup.enter.native="agregarTelefono(telefono)"
              >
                <span class="input-group-text md-addon" slot="prepend">+</span>
              </mdb-input>
              <p
                v-if="validarValores.telefono == 'invalido'"
                class="mensaje-invalido prepend"
              >
                Indica el teléfono del contacto
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-2 mr-3 px-2"
              title="Agregar teléfono"
              @click="agregarTelefono(telefono)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-if="aliado.telefonos && aliado.telefonos.length"
            :class="{'animated fadeIn': aliado.telefonos.length}"
          >
            <span class="font-weight-bold mb-2 d-block">Teléfono(s):</span>
            <div
              v-for="(tel, i) in aliado.telefonos"
              :key="`teléfono-${i}`"
              class="chip delete-btn"
            >
              +{{tel}}
              <mdb-btn
                class="m-0 ml-2 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar teléfono"
                @click="telefonoEliminar(tel)"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-start">
            <div
              :class="['col pr-1 pr-sm-3',
                {'campo': validarValores.estados
                  && validarValores.estados.length},
                {'valido': validarValores.estados == 'valido'},
                {'invalido': validarValores.estados == 'invalido'},
              ]"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="estado-select"
                  v-model="estado"
                  class="custom-select"
                >
                  <option class="d-none" disabled value="">Seleccione</option>
                  <option
                    v-for="(value, key) in estados"
                    :key="key"
                    :value="value.nombre"
                  >
                    {{ value.nombre }}
                  </option>
                </select>
                <label
                  for="estado-select"
                  :class="estado ? 'label-active' : 'label-inactive'"
                >
                  Estados
                </label>
              </div>
              <p
                v-if="validarValores.estados == 'invalido'"
                class="mensaje-invalido"
              >
                Selecciona el estado
              </p>
            </div>
            <mdb-btn
              color="exitoso"
              icon="plus"
              size="sm"
              class="col-auto mt-2 mr-3 px-2"
              title="Agregar estado"
              @click="AgregarEstado(estado)"
            >
              Añadir
            </mdb-btn>
          </div>
          <div
            v-if="aliado.estados && aliado.estados.length"
            :class="{'animated fadeIn': aliado.estados.length}"
          >
            <span class="font-weight-bold mb-2 d-block">Estados seleccionados:</span>
            <div
              v-for="(aliado, a) in aliado.estados"
              :key="`aliadoEstados-${a}`"
              class="chip delete-btn color-terciario"
            >
              {{ aliado }}
              <mdb-btn
                class="m-0 ml-2 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar estado seleccionado"
                @click="removerEstado(aliado)"
              />
            </div>
          </div>
        </div>
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="mt-4"
          @click="submitAliado"
        >
          {{ botonPrincipal.texto }}
        </mdb-btn>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import estadosGql from "@/graphql/estados.gql";
import aliadoEditarGql from "@/graphql/aliadoEditar.gql";
import aliadosGql from "@/graphql/aliados.gql";
import gql from "graphql-tag";

import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
import { evitarElementosRepetidos, validarTel, capitalizar } from "@/funciones/funciones.js";

export default {
  name: "FormularioAliado",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
  },
  props: {
    mostrarModalAliado: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    titulo: {
      type: String,
      required: true,
    },
    aliadoAsignar: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      estado: "",
      telefono: "",
      estados: [],
      aliado: {
        nombre: "",
        estados: [],
        contacto: "",
        telefonos: [],
      },
      validarValores: {},
    };
  },
  watch: {
    esEditar: function () {
      this.esEditar &&
        Object.assign(
          this.aliado,
          JSON.parse(JSON.stringify(this.aliadoAsignar))
        );
    },
  },
  methods: {
    submitAliado() {
      if (this.telefono) {
        const esCorrecto = this.agregarTelefono(this.telefono, true);
        if (!esCorrecto) {
          this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            telefono: "invalido",
          });
          return;
        }
        this.aliado.telefonos.push(`${this.telefono}`);
        this.telefono = "";
      }
      if (this.estado) {
        const esCorrecto = this.AgregarEstado(this.estado, true);
        if (!esCorrecto) {
          this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            estado: "invalido",
          });
          return;
        }
        this.aliado.estados.push(this.estado);
        this.estado = "";
      }
      this.validarValores = {
        nombre: this.aliado.nombre ? "valido" : "invalido",
        contacto: this.aliado.contacto ? "valido" : "invalido",
        telefono:
          this.telefono ||
          (this.aliado.telefonos && this.aliado.telefonos.length)
            ? "valido"
            : "invalido",
        estados:
          this.aliado.estados && this.aliado.estados.length
            ? "valido"
            : "invalido",
      };
      if (Object.values(this.validarValores).includes("invalido")) {
        this.botonDeshabilitado = false;
        this.alertaMensaje("Todos los campos son requeridos", "error");
        return;
      }
      this.aliado.nombre = capitalizar(this.aliado.nombre)
      this.aliado.contacto = capitalizar(this.aliado.contacto)
      const aliadoData = JSON.parse(JSON.stringify(this.aliado));
      delete aliadoData.__typename;
      if(this.esEditar){
          return this.aliadoEditar(aliadoData)
      }
         this.$emit("crear", aliadoData);
         this.limpiarCampos()
    },
    AgregarEstado(estadoSeleccionado, skip) {
      if (!estadoSeleccionado) {
        return this.alertaMensaje(
          "Debes seleccionar al menos un estado",
          "error"
        );
      }
      if (skip) {
        return evitarElementosRepetidos(estadoSeleccionado, this.aliado.estados)
          ? true
          : false;
      }
      evitarElementosRepetidos(estadoSeleccionado, this.aliado.estados)
        ? this.aliado.estados.push(estadoSeleccionado)
        : this.alertaMensaje(
            "Este estado ya se encuentra seleccionado",
            "advertencia"
          );
      this.estado = "";
    },
    removerEstado(indice) {
      this.aliado.estados.splice(this.aliado.estados.indexOf(indice), 1);
    },
    telefonoEliminar(indice) {
      this.aliado.telefonos.splice(this.aliado.telefonos.indexOf(indice), 1);
    },
    agregarTelefono(telefono, skip) {
      const esValido = validarTel(telefono);
      if (!telefono || !esValido) {
        return this.alertaMensaje(
          "El teléfono que ingresaste no es valido",
          "error"
        );
      }
      if (skip) {
        return evitarElementosRepetidos(telefono, this.aliado.telefonos)
          ? true
          : false;
      }
      evitarElementosRepetidos(`${telefono}`, this.aliado.telefonos)
        ? this.aliado.telefonos.push(`${telefono}`)
        : this.alertaMensaje(
            "Este teléfono ya se encuentra agregado",
            "error"
          );
      this.telefono = "";
    },
    aliadoEditar(aliadoData) {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${aliadoEditarGql}
          `,
          variables: {
            aliado: aliadoData,
          },
          update: (
            cache,
            {
              data: {
                aliadoEditar: { codigo },
              },
            }
          ) => {
            const data = cache.readQuery({ query: aliadosGql });

            switch (codigo) {
              case "Correcto":
                this.alertaMensaje(
                  "Aliado actualizado correctamente",
                  "correcto"
                );
                break;
              case "Fallido":
                this.botonDeshabilitado = false;
                return this.alertaMensaje(
                  "No se pudo actualizar el aliado",
                  "error"
                );
              default:
                this.botonDeshabilitado = false;
                return this.alertaMensaje(
                  "Ocurrió un error inesperado",
                  "error"
                );
            }
            data.Aliado.filter(({ id }) => id === aliadoData.id).map(
              (aliadoEditar) => {
                aliadoEditar.nombre = aliadoData.nombre;
                aliadoEditar.contacto = aliadoData.contacto;
                aliadoEditar.telefonos = aliadoData.telefonos;
                aliadoEditar.estados = aliadoData.estados;
              }
            );
            cache.writeQuery({
              query: aliadosGql,
              data,
            });
            if (!this.botonDeshabilitado) {
              this.alertaMensaje(
                "Aliado actualizado correctamente",
                "correcto"
              );
              this.limpiarCampos();
              this.$emit("editado");
              this.$emit("cerrar", false);
              this.cerrarModal()
            }
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            aliadoEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje(
            "Ha ocurrido un error actualizando el aliado",
            "error"
          );
        });
    },
    cerrarModal() {
      this.$emit("cerrar", false);
      this.$emit("editar", false);
      this.limpiarCampos();
    },
    limpiarCampos() {
      this.telefono = "";
      this.aliado = {
        nombre: "",
        estados: [],
        contacto: "",
        telefonos: [],
      };
      this.validarValores = {};
      this.botonDeshabilitado = false;
    },
    alertaMensaje(contenido, tipo) {
      this.$emit("alertaAliado", {
        contenido,
        tipo,
      });
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'}
    },
  },
  apollo: {
    estados() {
      return {
        query: estadosGql,
        update: (data) => data.Estado,
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
</style>

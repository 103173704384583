import Vue from "vue";
import VueRouter from "vue-router";
import { isAuthenticated, isNotAuthenticated, haveAccess } from "../vue-apollo";
import Consolidados from "@/views/Consolidados.vue";
import Consolidado from "@/views/Consolidado.vue";
import Ordenes from "@/views/Ordenes.vue";
import Orden from "@/views/Orden.vue";
import Clientes from "@/views/Clientes.vue";
import Cliente from "@/views/Cliente.vue";
import Agencias from "@/views/Agencias.vue";
import Agencia from "@/views/Agencia.vue";
import Usuarios from "@/views/Usuarios.vue";
import Pagos from "@/views/Pagos.vue";
import Auditoria from "@/views/Auditoria.vue";
import Aliados from "@/views/Aliados.vue";
import Medios from "@/views/Medios.vue";

Vue.use(VueRouter);
const pushPrototype = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return pushPrototype.call(this, location).catch((e) => e);
};
const routes = [
  {
    path: "/",
    name: "Resumen",
    component: () => import("../views/Resumen.vue"),
    beforeEnter: isAuthenticated && haveAccess,
    meta: { title: 'Resumen | Global Trackit' },
  },
  {
    path: "/login/:empresa?",
    name: "Login",
    component: () => import("../views/Login.vue"),
    beforeEnter: isNotAuthenticated,
    meta: { title: 'Login | Global Trackit System' },
  },
  {
    path: '/recuperar-clave/:usuario',
    name: 'Recuperar',
    component: () => import('../views/Recuperar.vue'),
    beforeEnter: isNotAuthenticated,
    meta: { title: 'Recuperar clave | Global Trackit System' }
  },
  {
    path: "/paquetes",
    name: "Paquetes",
    component: () => import("../views/Paquete.vue"),
    meta: { title: 'Paquetes | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
    children: [
      {
        path: ":paqueteId",
        name: "Paquete",
        component: () => import("../views/PaqueteId.vue"),
        meta: { title: 'Paquete | Global Trackit' },
      },
    ],
  },
  {
    path: "/consolidados",
    name: "Consolidados",
    component: Consolidados,
    meta: { title: 'Consolidados | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
    children: [
      {
        path: ":id",
        name: "Consolidado",
        component: Consolidado,
        meta: { title: 'Consolidado | Global Trackit' },
        children: [
          {
            path: ":paqueteId",
            name: "ConsolidadoPaquete",
            component: () => import("../views/PaqueteId.vue"),
            meta: { title: 'Paquete del consolidado | Global Trackit' },
          },
        ],
      },
    ],
  },
  {
    path: "/pagos",
    name: "Pagos",
    component: Pagos,
    meta: { title: 'Pagos | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/auditoria",
    name: "Auditoria",
    component: Auditoria,
    meta: { title: 'Auditoría | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/compras",
    name: "Compras",
    component: Ordenes,
    meta: { title: 'Compras | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
    children: [
      {
        path: ":id",
        name: "Compra",
        component: Orden,
        meta: { title: 'Compra | Global Trackit' },
        children: [
          {
            path: ":paqueteId",
            name: "CompraPaquete",
            component: () => import("../views/PaqueteId.vue"),
            meta: { title: 'Paquete de la compra | Global Trackit' },
          },
        ],
      },
    ],
  },
  {
    path: "/aliados",
    name: "Aliados",
    component: Aliados,
    meta: { title: 'Aliados de envío | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: { title: 'Clientes | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
    children: [
      {
        path: ":id",
        name: "Cliente",
        component: Cliente,
        meta: { title: 'Cliente | Global Trackit' },
        children: [
          {
            path: ":paqueteId",
            name: "ClientePaquete",
            component: () => import("../views/PaqueteId.vue"),
            meta: { title: 'Paquete del cliente | Global Trackit' },
          },
        ],
      },
    ],
  },
  {
    path: "/agencias",
    name: "Agencias",
    component: Agencias,
    meta: { title: 'Agencias | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
    children: [
      {
        path: ":id",
        name: "Agencia",
        component: Agencia,
        meta: { title: 'Agencia | Global Trackit' },
        children: [
          {
            path: ":paqueteId",
            name: "AgenciaPaquete",
            component: () => import("../views/PaqueteId.vue"),
            meta: { title: 'Paquete de la agencia | Global Trackit' },
          },
        ],
      },
    ],
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: { title: 'Usuarios | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/medios",
    name: "Medios",
    component: Medios,
    meta: { title: 'Medios de pago | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/configuracion",
    name: "Configuracion",
    component:  () => import("../views/Configuracion.vue"),
    meta: { title: 'Configuración | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "/exportar",
    name: "Exportar",
    component: () => import("../views/Exportar.vue"),
    meta: { title: 'Exportar | Global Trackit' },
    beforeEnter: isAuthenticated && haveAccess,
  },
  {
    path: "*",
    redirect:'/'
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const defaultTitle = 'Global Trackit System'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle
  })
});

export default router;

<template>
  <section>
    <div v-if="!cargandoApp" id="app">
      <Navbar v-if="!ocultarNavbar" />
      <div id="contenido" :class="{ login: ocultarNavbar }">
        <transition
          mode="out-in"
          :name="
            ocultarNavbar ? 'login-app-transicion' : 'vistas-app-transicion'
          "
        >
          <router-view />
        </transition>
      </div>
    </div>
    <AlertaSnackBar
      :alerta-snack-bar="{
        color: 'informativo',
        contenido: 'Nueva versión disponible',
      }"
      :btn-accion="{ icono: 'sync', texto: 'Clic para actualizar'}"
      :mostrar-snack-bar="existeActualizacion"
      @btn-accion="actualizarApp"
    />
  </section>
</template>

<script>
import AlertaSnackBar from '@/components/AlertaSnackBar.vue'
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'App',
  components: { AlertaSnackBar, Navbar },
  data() {
    return {
      cargandoApp: false,
      detalles: null,
      estaActualizando: false,
      existeActualizacion: false,
    };
  },
  computed: {
    ocultarNavbar () {
      const ruta = this.$route.path.slice(1).split('/')[0]
      return ruta === 'login' || ruta === 'recuperar-clave'
    }
  },
  methods:{
    mostrarActualizacion (e) {
      this.detalles = e.detail;
      this.existeActualizacion = true;
    },
    actualizarApp () {
      this.existeActualizacion = false;
      if (!this.detalles || !this.detalles.waiting) return;
      this.detalles.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
  created() {
    this.cargandoApp = true;
    setTimeout(()=> this.cargandoApp = false, 250);
    document.addEventListener('actualizacionServiceWorker', this.mostrarActualizacion, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.estaActualizando) return;
        this.estaActualizando = true;
        window.location.reload();
      });
    }
  },
}
</script>

<style lang="scss" scoped>
  #contenido:not(.login) {
    padding: 1.25rem 1rem 1rem;

    @media screen and (min-width: 992px) {
      margin-left: 230px;
      width: calc(100% - 230px); /* - ancho del navbar */
    }
  }

  // Estilos de transición entre vistas
  .vistas-app-transicion-enter-active {animation-name: fadeIn;}
  .vistas-app-transicion-leave-active {animation-name: fadeOut;}

  // Estilos de transición del login
  .login-app-transicion-enter-active {animation-name: fadeIn;}
  .login-app-transicion-leave-active {animation-name: fadeOutDown;}

  //Duración de las transiciones de Entrada
  .vistas-app-transicion-enter-active,
  .login-app-transicion-enter-active {animation-duration: .5s;}

  //Duración de las transiciones de Salida
  .vistas-app-transicion-leave-active,
  .login-app-transicion-leave-active {animation-duration: .25s;}
</style>
<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarFormularioOrden"
    @close="cerrarModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title> {{ titulo }} </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row" @submit.prevent>
        <div
          v-if="!esEditar && !clienteEspecificado"
          :class="[
            'col-12',
            { campo: validarCliente && validarCliente.length },
            { valido: validarCliente === 'valido' },
            { invalido: validarCliente === 'invalido' },
          ]"
        >
          <ClienteBuscador
            id="clienteBuscador-form"
            class="my-2"
            @cliente="enviarCliente($event)"
          />
          <label
            for="clienteBuscador-form"
            class="outline-datalist-label top-8"
          >
            Nombre del cliente
          </label>
          <p
            v-if="validarCliente === 'invalido'"
            class="mensaje-invalido datalist-title"
          >
            Indica el cliente
          </p>
        </div>
        <div
          :class="[
            'col-12 col-lg-3',
            { campo: validarValores.fecha && validarValores.fecha.length },
            { valido: validarValores.fecha == 'valido' },
            { invalido: validarValores.fecha == 'invalido' },
          ]"
        >
          <mdb-input
            v-model="orden.fecha.formatted"
            type="date"
            label="Fecha orden"
            outline
            class="my-2"
          />
          <p
            v-if="validarValores.fecha === 'invalido'"
            class="mensaje-invalido date"
          >
            Indica una fecha
          </p>
        </div>
        <div
          :class="[
            'col-12 col-lg',
            { campo: validarValores.estatus && validarValores.estatus.length },
            { valido: validarValores.estatus == 'valido' },
            { invalido: validarValores.estatus == 'invalido' },
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select-form"
              v-model="orden.estatus"
              class="custom-select"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in OrdenEstatus"
                :key="`ordenEstatus-${key}`"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select-form"
              :class="orden.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
          <p
            v-if="validarValores.estatus == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona el estatus
          </p>
        </div>
        <div
          :class="[
            'col-12 col-lg-3',
            { campo: validarValores.monto },
            { valido: validarValores.monto == 'valido' },
            { invalido: validarValores.monto == 'invalido' },
          ]"
        >
          <mdb-input
            v-model.number="orden.monto"
            type="number"
            label="Monto"
            class="my-2 dollar-addon"
            outline
          >
            <span
              slot="prepend"
              class="input-group-text md-addon user-select-none"
              title="Dólares"
            >
              $
            </span>
          </mdb-input>
        </div>
        <div class="col-12 text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="enviarOrden"
          >
            {{ botonPrincipal.texto }}
          </mdb-btn>
        </div>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
import ClienteBuscador from "../components/ClienteBuscador.vue";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import { obtenerMarcaTemporal } from "@/funciones/funciones.js";
export default {
  name: "FormularioOrden",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ClienteBuscador,
  },
  props: {
    mostrarFormularioOrden: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    titulo: {
      type: String,
      required: true,
    },
    clienteEspecificado: {
      type: Object,
      required: false,
    },
    ordenAsignar: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      OrdenEstatus,
      cliente: {},
      validarValores: {},
      validarCliente: "",
      botonDeshabilitado: false,
      orden: {
        estatus: "",
        fecha: {},
      },
    };
  },
  watch: {
    esEditar: function () {
      this.esEditar
        ? Object.assign(
            this.orden,
            JSON.parse(JSON.stringify(this.ordenAsignar))
          )
        : {};
    },
  },

  methods: {
    cerrarModal() {
      this.$emit("editar", false);
      this.$emit("cerrar", false);
      this.limpiarCamposForm();
    },
    enviarOrden() {
      this.botonDeshabilitado = true;
      this.validarValores = {
        estatus: this.orden.estatus ? "valido" : "invalido",
        fecha: this.orden.fecha.formatted ? "valido" : "invalido",
        monto: this.orden.monto ? "valido" : "invalido",
      };
      this.validarCliente = Object.keys(this.cliente).length
        ? "valido"
        : "invalido";
      if (Object.values(this.validarValores).includes("invalido")) {
        this.$emit("alerta-mensaje", {
          contenido: "Todos los campos son requeridos",
          tipo: "error",
        });
        this.botonDeshabilitado = false;
        return;
      }
      if (this.esEditar) {
        const clienteCasillero = this.orden.cliente && this.orden.cliente.casillero
        delete this.orden.cliente;
        delete this.orden.fecha.__typename;
        const { ...rest } = this.orden;
        const ordenEditar = {
          ...rest,
        };
        delete ordenEditar.__typename;
        ordenEditar.monto = Math.abs(ordenEditar.monto)
        ordenEditar.compraId = ordenEditar.compraId
          ? ordenEditar.compraId
          : clienteCasillero 
            ? clienteCasillero+ 'C'+obtenerMarcaTemporal()
            : 'Sin especificar';
        this.$emit("datosEditar", ordenEditar);
        this.limpiarCamposForm();
        this.cerrarModal();
        return;
      }
      if (!this.clienteEspecificado && !Object.keys(this.cliente).length) {
        this.validarCliente = "invalido";
        this.botonDeshabilitado = false;
        this.$emit("alerta-mensaje", {
          contenido: "El cliente es requerido",
          tipo: "error",
        });
        return;
      }
      if (!(this.cliente && this.cliente.casillero) && !this.clienteEspecificado) {
        this.validarCliente = "invalido";
        this.botonDeshabilitado = false;
          return this.$emit("alerta-mensaje", {
            contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
            tipo: "advertencia",
        });
      }
      this.botonDeshabilitado = false;
      this.orden.monto = Math.abs(this.orden.monto )
      this.$emit("orden", this.orden);
      this.limpiarCamposForm();
      this.cerrarModal();
    },
    limpiarCamposForm() {
      this.orden = {
        estatus: "",
        fecha: {},
      };
      this.cliente = {};
      this.validarValores = {};
      this.validarCliente = "";
      this.botonDeshabilitado = false;
    },
    enviarCliente(cliente) {
      this.$emit("cliente", cliente);
      this.cliente = cliente
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? { icono: "sync", texto: "Actualizar" }
        : { icono: "check", texto: "Agregar" };
    },
  },
};
</script>

<style lang="scss" scoped>
.campo {margin-bottom: 1rem;}
.campo.invalido > .mensaje-invalido.prepend {left: 47px;}
</style>


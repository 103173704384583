var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","size":"lg","show":_vm.mostrarFormularioOrden},on:{"close":function($event){return _vm.cerrarModal()}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.titulo)+" ")])],1),_c('mdb-modal-body',[_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();}}},[(!_vm.esEditar && !_vm.clienteEspecificado)?_c('div',{class:[
          'col-12',
          { campo: _vm.validarCliente && _vm.validarCliente.length },
          { valido: _vm.validarCliente === 'valido' },
          { invalido: _vm.validarCliente === 'invalido' } ]},[_c('ClienteBuscador',{staticClass:"my-2",attrs:{"id":"clienteBuscador-form"},on:{"cliente":function($event){return _vm.enviarCliente($event)}}}),_c('label',{staticClass:"outline-datalist-label top-8",attrs:{"for":"clienteBuscador-form"}},[_vm._v(" Nombre del cliente ")]),(_vm.validarCliente === 'invalido')?_c('p',{staticClass:"mensaje-invalido datalist-title"},[_vm._v(" Indica el cliente ")]):_vm._e()],1):_vm._e(),_c('div',{class:[
          'col-12 col-lg-3',
          { campo: _vm.validarValores.fecha && _vm.validarValores.fecha.length },
          { valido: _vm.validarValores.fecha == 'valido' },
          { invalido: _vm.validarValores.fecha == 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"date","label":"Fecha orden","outline":""},model:{value:(_vm.orden.fecha.formatted),callback:function ($$v) {_vm.$set(_vm.orden.fecha, "formatted", $$v)},expression:"orden.fecha.formatted"}}),(_vm.validarValores.fecha === 'invalido')?_c('p',{staticClass:"mensaje-invalido date"},[_vm._v(" Indica una fecha ")]):_vm._e()],1),_c('div',{class:[
          'col-12 col-lg',
          { campo: _vm.validarValores.estatus && _vm.validarValores.estatus.length },
          { valido: _vm.validarValores.estatus == 'valido' },
          { invalido: _vm.validarValores.estatus == 'invalido' } ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.orden.estatus),expression:"orden.estatus"}],staticClass:"custom-select",attrs:{"id":"estatus-select-form"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.orden, "estatus", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.OrdenEstatus),function(value,key){return _c('option',{key:("ordenEstatus-" + key),domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('label',{class:_vm.orden.estatus ? 'label-active' : 'label-inactive',attrs:{"for":"estatus-select-form"}},[_vm._v(" Estatus ")])]),(_vm.validarValores.estatus == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el estatus ")]):_vm._e()]),_c('div',{class:[
          'col-12 col-lg-3',
          { campo: _vm.validarValores.monto },
          { valido: _vm.validarValores.monto == 'valido' },
          { invalido: _vm.validarValores.monto == 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2 dollar-addon",attrs:{"type":"number","label":"Monto","outline":""},model:{value:(_vm.orden.monto),callback:function ($$v) {_vm.$set(_vm.orden, "monto", _vm._n($$v))},expression:"orden.monto"}},[_c('span',{staticClass:"input-group-text md-addon user-select-none",attrs:{"slot":"prepend","title":"Dólares"},slot:"prepend"},[_vm._v(" $ ")])])],1),_c('div',{staticClass:"col-12 text-center"},[_c('mdb-btn',{staticClass:"mt-4",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.enviarOrden}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
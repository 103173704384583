var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","size":"lg","show":_vm.mostrarModal},on:{"close":function($event){_vm.$emit('cerrar', false);
    _vm.limpiarCampos();}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.tituloModal)+" ")])],1),_c('mdb-modal-body',[_c('form',{staticClass:"row justify-content-around",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{class:['col-12',
          {'col-md-6': !_vm.esEditar},
          {'campo': _vm.validarValores.nombre
            && _vm.validarValores.nombre.length},
          {'valido': _vm.validarValores.nombre == 'valido'},
          {'invalido': _vm.validarValores.nombre == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.agencia.agencia.nombre),callback:function ($$v) {_vm.$set(_vm.agencia.agencia, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"agencia.agencia.nombre"}}),(_vm.validarValores.nombre == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el nombre ")]):_vm._e()],1),_c('div',{class:['col-12',
          {'col-md-6': !_vm.esEditar},
          {'campo': _vm.validarValores.codigo
            && _vm.validarValores.codigo.length},
          {'valido': _vm.validarValores.codigo == 'valido'},
          {'invalido': _vm.validarValores.codigo == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Código de registro","minlength":"2","maxlength":"10","outline":"","placeholder":"Ej: hrbintel"},on:{"blur":function($event){_vm.agencia.agencia.codigo ? _vm.agencia.agencia.codigo = _vm.agencia.agencia.codigo.toLowerCase() : ''}},model:{value:(_vm.agencia.agencia.codigo),callback:function ($$v) {_vm.$set(_vm.agencia.agencia, "codigo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"agencia.agencia.codigo"}}),(_vm.validarValores.codigo == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el código de registro de tu agencia ")]):_vm._e()],1),_c('div',{class:['col-12',
          {'col-md-6': !_vm.esEditar},
          {'campo': _vm.validarValores.prefijo
            && _vm.validarValores.prefijo.length},
          {'valido': _vm.validarValores.prefijo == 'valido'},
          {'invalido': _vm.validarValores.prefijo == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Prefijo del casillero","minlength":"2","maxlength":"4","outline":"","placeholder":"Ej: MYA"},on:{"blur":function($event){_vm.agencia.agencia.prefijo ? _vm.agencia.agencia.prefijo = _vm.agencia.agencia.prefijo.toUpperCase() : ''}},model:{value:(_vm.agencia.agencia.prefijo),callback:function ($$v) {_vm.$set(_vm.agencia.agencia, "prefijo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"agencia.agencia.prefijo"}}),(_vm.validarValores.prefijo == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el prefijo de los casilleros de tu agencia ")]):_vm._e()],1),(!_vm.esEditar)?[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.agencia.empresaId),expression:"agencia.empresaId"}],staticClass:"custom-select",attrs:{"id":"empresa-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.agencia, "empresaId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v("Otra")]),_vm._l((_vm.empresas),function(value,key){return _c('option',{key:key+'empresas',domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.agencia.empresaId ? 'label-active' : 'label-inactive',attrs:{"for":"empresa-select"}},[_vm._v(" Empresa ")])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
                {'campo': _vm.validarValores.telefono
                  && _vm.validarValores.telefono.length},
                {'valido': _vm.validarValores.telefono == 'valido'},
                {'invalido': _vm.validarValores.telefono == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2 plus-addon",attrs:{"type":"tel","label":"Teléfono","placeholder":"Ej: 58426573828","minlength":"7","maxlength":"13","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarTelefono(_vm.telefono)}},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=_vm._n($$v)},expression:"telefono"}},[_c('span',{staticClass:"input-group-text md-addon",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("+")])]),(_vm.validarValores.telefono == 'invalido')?_c('p',{staticClass:"mensaje-invalido prepend"},[_vm._v(" Indica el teléfono ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar teléfono"},on:{"click":function($event){return _vm.validarTelefono(_vm.telefono)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.agencia.telefonos),function(tel,i){return _c('div',{key:'Teléfono'+i,staticClass:"chip delete-btn"},[_vm._v(" +"+_vm._s(tel)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar teléfono"},on:{"click":function($event){return _vm.telefonoRemover(i)}}})],1)})],2),_c('header',{staticClass:"col-12 mt-2 font-weight-bold user-select-none"},[_vm._v(" Datos de dirección ")]),_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.estado
              && _vm.validarValores.estado.length},
            {'valido': _vm.validarValores.estado == 'valido'},
            {'invalido': _vm.validarValores.estado == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.agencia.direccion.estado),expression:"agencia.direccion.estado"}],staticClass:"custom-select",attrs:{"id":"estado-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.agencia.direccion, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.estados),function(value,key){return _c('option',{key:key,domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.agencia.direccion.estado ? 'label-active' : 'label-inactive',attrs:{"for":"estado-select"}},[_vm._v(" Estado ")])]),(_vm.validarValores.estado == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el estado ")]):_vm._e()]),_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.ciudad
              && _vm.validarValores.ciudad.length},
            {'valido': _vm.validarValores.ciudad == 'valido'},
            {'invalido': _vm.validarValores.ciudad == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.agencia.direccion.ciudad),expression:"agencia.direccion.ciudad"}],staticClass:"custom-select",attrs:{"id":"ciudad-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.agencia.direccion, "ciudad", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.ciudades),function(value,key){return _c('option',{key:key,domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.agencia.direccion.ciudad ? 'label-active' : 'label-inactive',attrs:{"for":"ciudad-select"}},[_vm._v(" Ciudad ")])]),(_vm.validarValores.ciudad == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona la ciudad ")]):_vm._e()]),_c('div',{class:['col-12',
            {'campo': _vm.validarValores.direccion
              && _vm.validarValores.direccion.length},
            {'valido': _vm.validarValores.direccion == 'valido'},
            {'invalido': _vm.validarValores.direccion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","rows":2,"label":"Dirección","outline":""},model:{value:(_vm.agencia.direccion.direccion),callback:function ($$v) {_vm.$set(_vm.agencia.direccion, "direccion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"agencia.direccion.direccion"}}),(_vm.validarValores.direccion == 'invalido')?_c('p',{staticClass:"mensaje-invalido textarea"},[_vm._v(" Indica la dirección ")]):_vm._e()],1),_c('header',{staticClass:"col-12 mt-2 font-weight-bold user-select-none"},[_vm._v(" Políticas de la agencia ")])]:_vm._e(),_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"url","label":"Enlace a las políticas de la agencia","outline":"","placeholder":"Ej: http://www.hrbintel.com/ve/politicas"},model:{value:(_vm.agencia.agencia.enlace),callback:function ($$v) {_vm.$set(_vm.agencia.agencia, "enlace", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"agencia.agencia.enlace"}})],1),_c('mdb-btn',{staticClass:"col-auto btn-bordeado mt-3 px-3",attrs:{"flat":"","dark-waves":"","icon":"times"},on:{"click":function($event){return _vm.$emit('cerrar', false)}}},[_vm._v(" Cancelar ")]),_c('mdb-btn',{staticClass:"col-auto mt-3 px-3",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.validarAgencia}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false); limpiarCampos();"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ tituloModal }}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <!-- Datos de dirección, no se muestran si se creará un receptor a dirección existente -->
      <div class="row" v-if="!esCrearReceptor">
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.estado
              && validarValores.estado.length},
            {'valido': validarValores.estado == 'valido'},
            {'invalido': validarValores.estado == 'invalido'},
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              v-model="direccion.estado"
              id="estado-select"
              class="custom-select"
              @change="direccion.ciudad = ''"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in estados"
                :key="key+'estado'"
                :value="value.nombre"
              >
                {{ value.nombre }}
              </option>
            </select>
            <label
              for="estado-select"
              :class="direccion.estado ? 'label-active' : 'label-inactive'"
            >
              Estado
            </label>
          </div>
          <p
            v-if="validarValores.estado == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona el estado
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.ciudad
              && validarValores.ciudad.length},
            {'valido': validarValores.ciudad == 'valido'},
            {'invalido': validarValores.ciudad == 'invalido'},
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              v-model="direccion.ciudad"
              id="ciudad-select"
              class="custom-select"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in ciudades"
                :key="key+'ciudad'"
                :value="value.nombre"
              >
                {{ value.nombre }}
              </option>
            </select>
            <label
              for="ciudad-select"
              :class="direccion.ciudad ? 'label-active' : 'label-inactive'"
            >
              Ciudad
            </label>
          </div>
          <p
            v-if="validarValores.ciudad == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona la ciudad
          </p>
        </div>
        <div
          :class="['col-12',
            {'col-md-6': !esAgencia && !esConsolidado},
            {'campo': validarValores.direccion
              && validarValores.direccion.length},
            {'valido': validarValores.direccion == 'valido'},
            {'invalido': validarValores.direccion == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="direccion.direccion"
            type="textarea"
            label="Dirección"
            :rows="2"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.direccion == 'invalido'"
            class="mensaje-invalido textarea"
          >
            Indica la dirección
          </p>
        </div>
        <div
          v-if="!esAgencia && !esConsolidado"
          class="col-12 col-md-6"
        >
          <mdb-input
            v-model.trim="direccion.observacion"
            type="textarea"
            label="Observación de envío"
            :rows="2"
            class="my-2"
            outline
          />
        </div>
      </div>
      <template v-if="!esCrearReceptor && !esAgencia && !esConsolidado">
        <header class="mt-2 font-weight-bold">Tarifas</header>
        <div class="row">
          <div class="col-12 col-md-6">
            <mdb-input
              v-model.number="direccion.tasaAerea"
              type="number"
              label="Tasa Aérea"
              class="my-2"
              outline
            />
          </div>
          <div class="col-12 col-md-6">
            <mdb-input
              v-model.number="direccion.tasaMaritima"
              type="number"
              label="Tasa Marítima"
              class="my-2"
              outline
            />
          </div>
          <div class="col-12 col-md-6"
          >
            <mdb-input
              v-model.number="direccion.minAerea"
              type="number"
              label="Min. Aérea"
              class="my-2"
              outline
            />
          </div>
          <div class="col-12 col-md-6">
            <mdb-input
              v-model.number="direccion.minMaritima"
              type="number"
              label="Mín. Marítima"
              class="my-2"
              outline
            />
          </div>
        </div>
      </template>
      <!-- Datos del Receptor -->
      <div v-if="(receptor && esEditar) || (!esAgencia && !esConsolidado && !esEditar)
          || (esCrearReceptor)">
        <header class="mt-2 font-weight-bold">Recibe</header>
        <div class="row">
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.rnombre
                && validarValores.rnombre.length},
              {'valido': validarValores.rnombre == 'valido'},
              {'invalido': validarValores.rnombre == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="direccion.recibe.nombre"
              type="text"
              label="Nombre"
              class="my-2"
              outline
            />
            <p
              v-if="validarValores.rnombre == 'invalido'"
              class="mensaje-invalido"
            >
              Indica el nombre
            </p>
          </div>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.ridentificacion
                && validarValores.ridentificacion.length},
              {'valido': validarValores.ridentificacion == 'valido'},
              {'invalido': validarValores.ridentificacion == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="direccion.recibe.identificacion"
              label="Número de ID"
              class="my-2"
              outline
            />
            <p
              v-if="validarValores.ridentificacion == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la identificación
            </p>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row align-items-center">
              <div
                :class="['col pr-1 pr-sm-3',
                  {'campo': validarValores.rtelefono
                    && validarValores.rtelefono.length},
                  {'valido': validarValores.rtelefono == 'valido'},
                  {'invalido': validarValores.rtelefono == 'invalido'},
                ]"
              >
                <mdb-input
                  v-model.number="rtelefono"
                  id="input-rtelefono"
                  type="tel"
                  label="Teléfono"
                  placeholder="Ej: 58426573828"
                  minlength="7"
                  maxlength="13"
                  class="my-2 plus-addon"
                  outline
                  @keyup.enter.native="validarTelefono(rtelefono)"
                >
                  <span class="input-group-text md-addon" slot="prepend">+</span>
                </mdb-input>
                <p
                  v-if="validarValores.rtelefono == 'invalido'"
                  class="mensaje-invalido prepend"
                >
                  Indica el teléfono
                </p>
              </div>
              <mdb-btn
                color="exitoso"
                icon="plus"
                size="sm"
                class="col-auto mr-3 px-2"
                title="Agregar teléfono"
                @click="validarTelefono(rtelefono)"
              >
                Añadir
              </mdb-btn>
            </div>
            <div
              v-for="(tel, i) in direccion.recibe.telefonos"
              :key="'Rteléfono'+i"
              class="chip delete-btn"
            >
              +{{tel}}
              <mdb-btn
                class="m-0 ml-2 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar teléfono"
                @click="rtelefonoRemover(i)"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row align-items-center">
              <div
                :class="['col pr-1 pr-sm-3',
                  {'campo': validarValores.remail
                    && validarValores.remail.length},
                  {'valido': validarValores.remail == 'valido'},
                  {'invalido': validarValores.remail == 'invalido'},
                ]"
              >
                <mdb-input
                  v-model.trim="remail"
                  id="input-remail"
                  type="email"
                  label="Email"
                  placeholder="Ej: ejemplo@mail.com"
                  class="my-2"
                  outline
                  @keyup.enter.native="validarEmail(remail)"
                />
                <p
                  v-if="validarValores.remail == 'invalido'"
                  class="mensaje-invalido"
                >
                  Indica el email
                </p>
              </div>
              <mdb-btn
                color="exitoso"
                icon="plus"
                size="sm"
                class="col-auto mr-3 px-2"
                title="Agregar email"
                @click="validarEmail(remail)"
              >
                Añadir
              </mdb-btn>
            </div>
            <div
              v-for="(email, i) in direccion.recibe.emails"
              :key="'Remail'+i"
              class="chip delete-btn"
            >
              {{email}}
              <mdb-btn
                class="m-0 ml-2 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar email"
                @click="remailRemover(i)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-around">
        <mdb-btn
          flat
          dark-waves
          icon="times"
          class="col-auto btn-bordeado mt-3 px-3"
          @click="$emit('cerrar', false)"
        >
          Cancelar
        </mdb-btn>
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          class="col-auto mt-3 px-3"
          :disabled="botonDeshabilitado"
          @click="validarDireccion"
        >
          {{ botonPrincipal.texto }}
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import clienteGql from "@/graphql/cliente.gql";
import agenciaGql from "@/graphql/agencia.gql";
import consolidadoGql from "@/graphql/consolidado.gql";
import direccionCrearGql from "@/graphql/direccionCrear.gql";
import consolidadoDireccionCrearGql from "@/graphql/consolidadoDireccionCrear.gql";
import direccionEditarGql from "@/graphql/direccionEditar.gql";
import estadosGql from "@/graphql/estados.gql";
import receptorCrearGql from '@/graphql/receptorCrear.gql'
import ciudadesGql from "@/graphql/ciudades.gql";
import {
  capitalizar,
  eliminarVacios,
  validarTel,
  validarMail,
  evitarElementosRepetidos
} from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";

export default {
  name: 'ModaldatosEnvio',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    variablesQueryAgencia: {
      type: Object,
      required: false,
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    esAgencia: {
      type: Boolean,
      required: false,
    },
    esCrearReceptor: {
      type: Boolean,
      required: false
    },
    esConsolidado: {
      type: Boolean,
      required: false,
    },
    clienteEspecificado: {
      type: Object,
      required: false,
    },
    datosDireccion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      rtelefono: '',
      remail: '',
      receptorId: '',
      receptor: false,
      estadoSeleccionado: {},
      validarValores: {},
      direccion: {
        direccion: '',
        observacion: '',
        tasaAerea:'',
        tasaMaritima:'',
        minAerea:'',
        minMaritima:'',
        ciudad: '',
        estado: '',
        recibe:{
          nombre: '',
          identificacion: '',
          telefonos: [],
          emails: [],
        },
      },
    };
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
      }
    },
    esEditar: function() {
      if(this.esEditar && this.datosDireccion
        && Object.entries(this.datosDireccion).length){
          this.direccionAsignarDatosAnteriores();
      }
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'};
    },
    tituloModal() {
      if (this.esEditar) {
        return 'Datos de la dirección a editar'
      }
      if (this.esCrearReceptor) {
        return 'Datos del nuevo receptor'
      }
      return 'Datos de la nueva dirección'
    }
  },
  methods: {
    obtenerIdReceptor(idDireccion) {
      this.$apollo
        .query({
            query: gql`
              ${clienteGql}
            `,
            variables: {
              filter:{
                id: this.$route.params.id,
              }
            },
            update: (data) => data.Cliente[0],
            fetchPolicy: "no-cache",
          })
          .then(({data}) => {
              if(data && data.Cliente){
                const direccionEspecificada = data.Cliente[0].direcciones
                  .filter((d) => d.id===idDireccion)
                this.$emit('creado', direccionEspecificada[0])
                this.$emit('cerrar', false);
              }
              this.botonDeshabilitado = false;
            },
          )
          .catch(() => {
              this.botonDeshabilitado = false;
              this.alertaMensaje('Ha ocurrido un error obteniendo los clientes',
               'error');
          })
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    rtelefonoRemover(index){
      this.direccion.recibe.telefonos.splice(index, 1)
    },
    remailRemover(index){
      this.direccion.recibe.emails.splice(index, 1)
    },
    validarTelefono(telefono, skip){
      if(!telefono || !validarTel(telefono)) {
        this.alertaMensaje('Teléfono no válido',
          'error')
        return false
      }else {
         if(skip){
           return true
         }
        evitarElementosRepetidos(`${telefono}`, this.direccion.recibe.telefonos)
          ? this.direccion.recibe.telefonos.push(`${telefono}`)
          : this.alertaMensaje(
              "Este teléfono ya se encuentra agregado",
              "advertencia"
            );
        return this.rtelefono = '';
      }
    },
    validarEmail(email, skip){
       if(!email || !validarMail(email)){
        this.alertaMensaje('Email no válido',
          'error')
        return false
       }else {
         if(skip){
           return true
         }
        this.direccion.recibe.emails.push(email)
        return this.remail = '';
      }
    },
    direccionAsignarDatosAnteriores() {
      // Aplicar formato de la dirección a editar
      const direccion = JSON.parse(JSON.stringify(this.datosDireccion));
      const direccionConFormato = {};
      direccionConFormato.id = direccion.id;
      Object.assign(direccionConFormato, {
        direccion: direccion.direccion,
        ciudad: direccion.ciudad.nombre,
        estado: direccion.ciudad.estado.nombre,
        tasaAerea:direccion.tasaAerea ? direccion.tasaAerea : '',
        tasaMaritima:direccion.tasaMaritima ? direccion.tasaMaritima : '',
        minMaritima:direccion.minMaritima ? direccion.minMaritima : '',
        minAerea:direccion.minAerea ? direccion.minAerea : '',
      })

      // Si existe observación o receptor se asgignan
      direccion.observacion
        ? direccionConFormato.observacion = direccion.observacion
        : "";
      if(direccion.recibe && Object.entries(direccion.recibe).length) {
        this.receptor = true;
        this.receptorId = direccion.recibe.id
        direccionConFormato.recibe = {}
        Object.assign(direccionConFormato.recibe, {
          nombre: direccion.recibe.nombre,
          identificacion: direccion.recibe.identificacion
        })
      }

      Object.assign(this.direccion, direccionConFormato);
    },
    validarDireccion(){
      let sinReceptor = false;
      this.botonDeshabilitado = true;
      // Si se ingresa un dato de un receptor, se verifica que se introduzca la dirección
      // de envío y los datos del receptor
      if((!this.esEditar && (this.direccion.recibe.nombre || this.direccion.recibe.genero
        || this.direccion.recibe.identificacion || this.direccion.recibe.telefonos.length
        || this.direccion.recibe.emails.length)) || this.esCrearReceptor) {
        if (this.esCrearReceptor) {
          Object.assign(this.validarValores, {
            // Datos del receptor
            rnombre: this.direccion.recibe.nombre ? 'valido' : 'invalido',
            ridentificacion: parseFloat(this.direccion.recibe.identificacion) ? 'valido' : 'invalido'
          })
        } else {
          Object.assign(this.validarValores, {
            // Datos de dirección
            estado: this.direccion.estado ? 'valido' : 'invalido',
            ciudad: this.direccion.ciudad ? 'valido' : 'invalido',
            direccion: this.direccion.direccion ? 'valido' : 'invalido',
            // Datos del receptor
            rnombre: this.direccion.recibe.nombre ? 'valido' : 'invalido',
            ridentificacion: parseFloat(this.direccion.recibe.identificacion) ? 'valido' : 'invalido'
          })
        }
        if(!this.esEditar) {
          Object.assign(this.validarValores, {
            rtelefono: parseFloat(this.rtelefono)
              || this.direccion.recibe.telefonos.length ? "valido" : "invalido",
            remail: this.remail
              || this.direccion.recibe.emails.length ? "valido" : "invalido",
          })
        }

        if(this.rtelefono){
          if(!this.validarTelefono(this.rtelefono, true)){
            this.botonDeshabilitado = false;
            return Object.assign(this.validarValores, {
              rtelefono: "invalido",
            })
          }
          if(evitarElementosRepetidos(`${this.rtelefono}`, this.direccion.recibe.telefonos)){
            this.direccion.recibe.telefonos.push(`${this.rtelefono}`)
          } else {
            this.botonDeshabilitado = false;
            Object.assign(this.validarValores, {
              rtelefono: "invalido",
            })
            return this.alertaMensaje(
              "Este teléfono ya se encuentra agregado",
              "advertencia"
            );
          }
          this.rtelefono = "";
        }

        if(this.remail){
          if(!this.validarEmail(this.remail, true)){
            this.botonDeshabilitado = false;
            return Object.assign(this.validarValores, {
              remail: "invalido",
            })
          }
          this.direccion.recibe.emails.push(this.remail)
          this.remail = "";
        }
      } else {
          sinReceptor = true;
      }

      if(this.esEditar && this.receptor) {
        Object.assign(this.validarValores, {
          // Datos del receptor
          rnombre: this.direccion.recibe.nombre ? "valido" : "invalido",
          ridentificacion: parseFloat(this.direccion.recibe.identificacion) ? "valido" : "invalido",
        })
      }
      // Si se crea un receptor, no hace falta validar estado, ciudad y dirección
      if (!this.esCrearReceptor) {
        Object.assign(this.validarValores, {
          estado: this.direccion.estado ? "valido" : "invalido",
          ciudad: this.direccion.ciudad ? "valido" : "invalido",
          direccion: this.direccion.direccion ? "valido" : "invalido",
        })
      }
       // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        return this.botonDeshabilitado = false;
      }

      // Aplica formato Capitalize al nombre del receptor
      this.direccion.recibe.nombre ?
        this.direccion.recibe.nombre = capitalizar(this.direccion.recibe.nombre)
        : ''

      const direccion = JSON.parse(JSON.stringify(this.direccion));
      this.estadoSeleccionado = direccion.estado;
      delete direccion.estado

      // Eliminar campos vacios
      const direccionSinCamposVacios = eliminarVacios(direccion)
      if(!this.esEditar && sinReceptor){
        delete direccionSinCamposVacios.recibe
      }

      if(this.esEditar){
        return this.direccionEditar(direccionSinCamposVacios,
          this.esAgencia ? true : false,
          this.esConsolidado ? true : false
        )
      }
      if (this.esCrearReceptor) {
        return this.receptorCrear(direccionSinCamposVacios)
      }
      this.direccionCrear(direccionSinCamposVacios,
        this.esAgencia ? true : false,
        this.esConsolidado ? true : false
      );
    },
    direccionCrear(direccion, agencia, consolidado){
      const personaId = this.clienteEspecificado
        && this.clienteEspecificado.id
          ? this.clienteEspecificado.id
          : '';
      if(!agencia && !consolidado){
        this.$apollo
          .mutate({
            mutation: gql`${direccionCrearGql}`,
            variables: {
              direccion,
              personaId
            }
          })
          .then(({data: {direccionCrear}}) => {
              this.alertaMensaje('Dirección creada correctamente',
              'correcto')
            this.limpiarCampos();
              this.obtenerIdReceptor(
                direccionCrear.id,
              )
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
              'error')
            this.botonDeshabilitado = false;
          });
      }else {
        this.$apollo
          .mutate({
            mutation: gql`${consolidado ? consolidadoDireccionCrearGql : direccionCrearGql}`,
            variables: {
              direccion,
              personaId
            },
            update: (store, {data}) => {
            // Aplicar formato al direccion a enviar
            const direccionConFormato = {}
            // Buscar el nombre del pais
              const pais = this.estados.filter(e => e.nombre === this.estadoSeleccionado)
              direccionConFormato.ciudad = {
                nombre: direccion.ciudad,
                __typename: 'Ciudad',
                estado: {
                  nombre: this.estadoSeleccionado,
                __typename: 'Estado',
                  pais: {
                    nombre: pais[0].pais.nombre,
                  __typename: 'Pais',
                  }
                }
              }
              if(agencia){
                const datas = store.readQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                });
                direccionConFormato.id = data.direccionCrear.id
                direccionConFormato.direccion = direccion.direccion
                direccionConFormato.tasaAerea = direccion.tasaAerea ? direccion.tasaAerea : ''
                direccionConFormato.tasaMaritima = direccion.tasaMaritima ? direccion.tasaMaritima : ''
                direccionConFormato.minAerea = direccion.minAerea ? direccion.minAerea : ''
                direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
                direccionConFormato.aliado = {
                  id: '',
                  nombre: '',
                  __typename:"Aliado"
                }
                direccionConFormato.__typename = 'Direccion';
                datas.Agencia[0].direccion = direccionConFormato

                store.writeQuery({
                  query: agenciaGql,
                    variables: this.variablesQueryAgencia,
                  data: datas,
                });
              }
              if(consolidado){
                const datas = store.readQuery({
                  query: consolidadoGql,
                  variables: {
                      id: this.$route.params.id
                  },
                });

                direccionConFormato.id = data.direccionAsignarConsolidado.id
                direccionConFormato.direccion = direccion.direccion
                  direccionConFormato.tasaAerea = direccion.tasaAerea ? direccion.tasaAerea : ''
                direccionConFormato.tasaMaritima = direccion.tasaMaritima ? direccion.tasaMaritima : ''
                direccionConFormato.minAerea = direccion.minAerea ? direccion.minAerea : ''
                direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
                direccionConFormato.__typename = 'Direccion';
                datas.Consolidado[0].destino = direccionConFormato;
                datas.Consolidado[0].pesoVolumetrico = '';
                datas.Consolidado[0].piesCubicos = '';
                datas.Consolidado[0].fechaCarga.__typename = '_Neo4jDateTime'
                datas.Consolidado[0].fechaArribo.__typename = '_Neo4jDate'
                store.writeQuery({
                  query: consolidadoGql,
                    variables: {
                        id: this.$route.params.id
                    },
                  data: datas,
                });
              }

          this.limpiarCampos();
          this.$emit('cerrar', false);
          this.botonDeshabilitado = false;
           this.alertaMensaje('Dirección creada correctamente',
              'correcto')
        },
      })
      .catch(()=>{
        this.botonDeshabilitado = false;
        return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
          'error');
      })
      }
    },
    receptorCrear (direccion) {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: gql`${receptorCrearGql}`,
          variables: {
            direccionId: this.datosDireccion.id,
            persona: direccion.recibe
          },
          update: (store, { data: { direccionAsignarReceptor } }) => {
            const id = direccionAsignarReceptor.id
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter: {
                  id: this.$route.params.id
                }
              }
            })

            const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
            const receptorConFormato = direccion.recibe
            receptorConFormato.id = id

            receptorConFormato.__typename = 'Persona'
            receptorConFormato.telefonos = receptorConFormato.telefonos.map(x => ({ numero: x, __typename: 'Telefono' }))
            receptorConFormato.emails = receptorConFormato.emails.map(x => ({ email: x, __typename: 'Email' }))

            const direccionesActualizadas = []
            direcciones.forEach((d) => {
              if (d.id === this.datosDireccion.id) {
                Object.assign(d, {
                  recibe: receptorConFormato
                })
              }
              direccionesActualizadas.push(d)
            })
            data.Cliente[0].direcciones = direccionesActualizadas

            store.writeQuery({
              query: clienteGql,
              variables: {
                filter: {
                  id: this.$route.params.id
                }
              },
              data
            })

            this.limpiarCampos()
            this.$emit('cerrar', false)
            this.botonDeshabilitado = false
          }
        })
        .catch(() => {
          this.botonDeshabilitado = false
          return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
            'error')
        })
    },
    direccionEditar(direccion, agencia, consolidado){
      this.$apollo
        .mutate({
          mutation: gql`${direccionEditarGql}`,
          variables: {
            direccion,
          },
          update: (store, {data: {direccionEditar}}) => {
          const msg = direccionEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje('Se ha editado la dirección con exito.', 'correcto');
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
                'error');
            default:
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error');
            }
            // Aplicar formato al direccion a enviar
            const direccionConFormato = {}
            // En caso de editar la dirección de un cliente
            // Buscar el nombre del pais
              const pais = this.estados.filter(e => e.nombre === this.estadoSeleccionado)
              direccionConFormato.ciudad = {
                nombre: direccion.ciudad,
                __typename: 'Ciudad',
                estado: {
                  nombre: this.estadoSeleccionado,
                __typename: 'Estado',
                  pais: {
                    nombre: pais[0].pais.nombre ? pais[0].pais.nombre : '',
                  __typename: 'Pais',
                  }
                }
              }
            if(!agencia && !consolidado){
              const data = store.readQuery({
                query: clienteGql,
                variables: {
                  filter:{
                    id: this.$route.params.id
                  }
                },
              });

              direccionConFormato.direccion = direccion.direccion
              direccionConFormato.tasaAerea = direccion.tasaAerea ? direccion.tasaAerea : '';
              direccionConFormato.tasaMaritima = direccion.tasaMaritima ? direccion.tasaMaritima : '';
              direccionConFormato.minAerea = direccion.minAerea ? direccion.minAerea : '';
              direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
              direccionConFormato.__typename = 'Direccion';
              direccionConFormato.observacion =  direccion.observacion
               ? direccion.observacion
                : '';
              if(this.receptor && direccion && direccion.recibe) {
                direccionConFormato.recibe = {
                  id: this.receptorId,
                  __typename: 'Persona',
                };
                // Evitar problemas con typename
                direccionConFormato.recibe.telefonos = this.datosDireccion.recibe.telefonos
                Object.assign(direccionConFormato.recibe, direccion.recibe)
              }

              const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
              const direccionesActualizadas = []
              direcciones.forEach((d) => {
                if(d.id === direccion.id) {
                  Object.assign(d, direccionConFormato)
                }
                direccionesActualizadas.push(d)
              });
              data.Cliente[0].direcciones = direccionesActualizadas

              store.writeQuery({
                query: clienteGql,
                  variables: {
                    filter:{
                      id: this.$route.params.id
                    }
                  },
                data,
              });
            // en caso de editar la dirección de una agencia
            }else {
              if(agencia){
                const data = store.readQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                });

                direccionConFormato.id = direccion.id
                direccionConFormato.direccion = direccion.direccion
                direccionConFormato.tasaAerea = direccion.tasaAerea ? direccion.tasaAerea : '';
                direccionConFormato.tasaMaritima = direccion.tasaMaritima ? direccion.tasaMaritima : '';
                direccionConFormato.minAerea = direccion.minAerea ? direccion.minAerea : '';
                direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
                direccionConFormato.__typename = 'Direccion';
                data.Agencia[0].direccion = direccionConFormato

                 store.writeQuery({
                  query: agenciaGql,
                    variables: this.variablesQueryAgencia,
                  data,
                });
              }

            // en caso de editar la dirección de un consolidado
              if(consolidado){
                const data = store.readQuery({
                  query: consolidadoGql,
                  variables: {
                    id: this.$route.params.id
                  },
                });

                direccionConFormato.id = direccion.id
                direccionConFormato.direccion = direccion.direccion
                direccionConFormato.tasaAerea = direccion.tasaAerea ? direccion.tasaAerea : ''
                direccionConFormato.tasaMaritima = direccion.tasaMaritima ? direccion.tasaMaritima : ''
                direccionConFormato.minAerea = direccion.minAerea ? direccion.minAerea : ''
                direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
                direccionConFormato.minMaritima = direccion.minMaritima ? direccion.minMaritima : ''
                direccionConFormato.__typename = 'Direccion';
                data.Consolidado[0].destino = direccionConFormato
                data.Consolidado[0].pesoVolumetrico = '';
                data.Consolidado[0].piesCubicos = '';
                 store.writeQuery({
                  query: consolidadoGql,
                    variables: {
                      id: this.$route.params.id
                    },
                  data,
                });
              }
            }

          if(!this.botonDeshabilitado){
            this.limpiarCampos();
            this.$emit('cerrar', false);
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            direccionEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.botonDeshabilitado = false;
        return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
          'error');
      })
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.receptor = false;
      this.rtelefono = '';
      this.remail = '';
      this.validarValores = {};
      this.estadoSeleccionado = {};
      this.direccion = {
        direccion: '',
        observacion: '',
        ciudad: '',
        estado: '',
        tasaAerea:'',
        tasaMaritima:'',
        minAerea:'',
        minMaritima:'',
        recibe:{
          nombre: '',
          identificacion: '',
          telefonos: [],
          emails: [],
        },
      };
    },
  },
  apollo: {
    estados() {
      return {
        query: estadosGql,
        update: (data) => data.Estado,
        fetchPolicy: "cache-and-network",
      };
    },
    ciudades() {
      return {
        query: ciudadesGql,
        variables: () => {
          return {
            filter: {
              estado: {
                nombre: this.direccion.estado,
              },
            }
          }
        },
        skip() { return this.direccion && this.direccion.estado ? false : true},
        update: (data) => data.Ciudad,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>

<style lang="scss" scoped>
  .campo {
    margin-bottom: 1.1rem;
    &.invalido > .mensaje-invalido.prepend  {left: 47px;}
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","show":_vm.mostrarModalMedios},on:{"close":function($event){return _vm.cerrarModal()}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(_vm._s(_vm.titulo))])],1),_c('mdb-modal-body',[_c('form',{staticClass:"row justify-content-center",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{class:[
          'col-12',
          { campo: _vm.validarValores.nombre && _vm.validarValores.nombre.length },
          { valido: _vm.validarValores.nombre === 'valido' },
          { invalido: _vm.validarValores.nombre === 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.medio.nombre),callback:function ($$v) {_vm.$set(_vm.medio, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"medio.nombre"}}),(_vm.validarValores.nombre === 'invalido')?_c('p',{staticClass:"mensaje-invalido date"},[_vm._v(" Indica el nombre del medio ")]):_vm._e()],1),_c('div',{class:[
          'col-12',
          {
            campo:
              _vm.validarValores.descripcion && _vm.validarValores.descripcion.length,
          },
          { valido: _vm.validarValores.descripcion === 'valido' },
          { invalido: _vm.validarValores.descripcion === 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","rows":3,"label":"Descripción","outline":""},model:{value:(_vm.medio.descripcion),callback:function ($$v) {_vm.$set(_vm.medio, "descripcion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"medio.descripcion"}}),(_vm.validarValores.descripcion === 'invalido')?_c('p',{staticClass:"mensaje-invalido textarea"},[_vm._v(" Indica la descripcion ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-4",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.submitPago}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-select
    v-model="cliente"
    :options="clientes"
    :filter="fuseSearch"
    :getOptionLabel="option => option.nombre"
    class="outline-datalist"
    :placeholder="placeholder"
    @input="$emit('cliente', cliente && cliente.nombre && cliente.id ? cliente : {})"
    @search="query=> query != '' ? clienteNombre = query : ''"
  >
     <template #option="{ nombre, casillero }">
      {{ nombre }} - <cite>{{ casillero ? casillero : 'Sin especificar' }}</cite>
    </template>
    <template #no-options v-if="clienteNombre.length < 3">
      Por favor ingresa un nombre para empezar a buscar
    </template>
    <template #no-options v-else-if="$apolloData.queries.clientes.loading">
      Cargando
    </template>
    <template #no-options v-else>
      No se encontraron resultados para la búsqueda
    </template>
  </v-select>
</template>

<script>
import clienteBuscarGql from "@/graphql/clienteBuscar.gql";
import gql from "graphql-tag";
import Fuse from "fuse.js";

export default {
  name: "ClienteBuscador",
  props: {
    placeholder: {
      type: String,
      default: 'Escribe el nombre para buscar'
    },
    limpiarCliente: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      cliente: '',
      clienteNombre: '',
    };
  },
  watch: {
    limpiarCliente(){
      if(this.limpiarCliente){
        this.cliente = ''
        this.clienteNombre = ''
        this.$emit('limpiar', false)
      }
    }
  },
  methods: {
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["nombre", "casillero"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    }
  },
  apollo: {
    clientes() {
      return {
        query: gql`
          ${clienteBuscarGql}
        `,
        variables() {
          return {
            nombre: this.clienteNombre,
          };
        },
        skip: () => this.clienteNombre.trim() && this.clienteNombre.length > 2 ? false : true,
        debounce: '1000',
        update: (data) => data.clienteBuscar,
        fetchPolicy: "no-cache",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title>Detalles del pago</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="row">
        <p class="col-6 col-lg-3 mb-2">
          <small class="subtitulo">Estatus</small>
          <span
            v-if="EstatusPago[pago.estatus]"
            :class="['font-weight-bold pt-1', EstatusPagoIconoColor[pago.estatus]]"
          >
            <font-awesome-icon :icon="EstatusPagoIconos[pago.estatus]" />
            {{
              EstatusPago[pago.estatus]
                ? EstatusPago[pago.estatus]
                : "Sin especificar"
            }}
          </span>
        </p>
        <p
          v-if="pago && pago.fecha && pago.fecha.formatted"
          class="col-6 col-lg-3 mb-2"
        >
          <small class="subtitulo">Fecha</small>
          {{ pago.fecha.formatted }}
        </p>
        <p class="col-6 col-lg-3 mb-2">
          <small class="subtitulo">Monto</small>
          $ {{ pago.monto }}
        </p>
        <p class="col-6 col-lg-3 mb-2">
          <small class="subtitulo">Restante</small>
          $ {{ pago.restante }}
        </p>
        <p
          v-if="pago.medio && pago.medio.nombre"
          class="col-12 col-lg-6 mb-2"
        >
          <small class="subtitulo">Medio de pago</small>
          {{ pago.medio.nombre }}
          <small
            v-if="pago.medio.descripcion"
            class="d-block text-break"
          >
            {{ pago.medio.descripcion }}
          </small>
        </p>
        <p
          v-if="pago.observacion && pago.observacion.length"
          class="col-6 col-lg-6 mb-2"
        >
          <small class="subtitulo">Observación</small>
          {{ pago.observacion }}
        </p>
        <template v-if="pago.cliente">
          <div class="w-100" />
          <p
            v-if="pago.cliente.nombre"
            class="col-12 col-lg-3 mb-2"
          >
            <small class="subtitulo">Cliente</small>
            {{ pago.cliente.nombre }}
          </p>
          <p
            v-if="pago.cliente.casillero"
            class="col-12 col-sm-6 col-lg-3 mb-2"
          >
            <small class="subtitulo">Casillero</small>
            {{ pago.cliente.casillero }}
          </p>
          <p
            v-if="pago.cliente.telefonos"
            class="col-12 col-lg-6 mb-2"
          >
            <small class="subtitulo">Teléfonos</small>
            <template v-for="(telefono, iTel) in pago.cliente.telefonos">
              <span v-if="iTel > 0" :key="`coma-${iTel}`">, </span>
              <a
                :key="iTel"
                :href="`tel:+${telefono.numero}`"
                class="text-break"
              >+{{ telefono.numero }}</a>
            </template>
          </p>
        </template>
      </div>

      <!-- Paquetes relacionados -->
      <ul
        data-table="sticky"
        class="table fs-08 mb-4"
      >
        <li class="encabezado doble-fila row">
          <h4
            class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
              text-sm-left user-select-none"
          >
            Paquetes relacionados
          </h4>
          <div class="fila-encabezado-tabla col-12 user-select-none">
            <div class="row">
              <div class="col-2 d-none d-sm-block">
                Fecha
              </div>
              <div class="col">
                Detalles
              </div>
              <div class="col-3 d-none d-sm-block">
                Estatus
              </div>
            </div>
          </div>
        </li>
        <template v-if="paquetes && paquetes.length">
          <li
            v-for="paquete in paquetes"
            :key="paquete.id"
            class="contenido row align-items-center"
            @click="$router.push({ name: 'Paquete', params: {paqueteId: paquete.id}})"
          >
            <div class="col-2 d-none d-sm-block">
              {{ paquete.fecha.formatted }}
            </div>
            <div class="col">
              <p class="d-sm-none mb-1">
                Fecha: {{ paquete.fecha.formatted }}
              </p>
              Paquete ID:
              <span class="text-break">{{ paquete.numeroHR }}</span>
              <span
                v-if="paquete.tracking"
                class="d-block text-break">Tracking: {{ paquete.tracking }}</span>
              <p class="d-sm-none mb-1">
                Estatus: {{ PaqueteEstatus[paquete.estatus] }}
              </p>
            </div>
            <div class="col-3 d-none d-sm-block">
              {{ PaqueteEstatus[paquete.estatus] }}
            </div>
          </li>
        </template>
        <li
          v-else
          class="no-items"
        >
          Sin paquetes relacionados
        </li>
      </ul>

      <!-- Compras relacionadas -->
      <ul
        data-table="sticky"
        class="table fs-08 mb-4"
      >
        <li class="encabezado doble-fila row">
          <h4
            class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
              text-sm-left user-select-none"
          >
            Compras relacionados
          </h4>
          <div class="fila-encabezado-tabla col-12 user-select-none">
            <div class="row">
              <div class="col-2 d-none d-sm-block">
                Fecha
              </div>
              <div class="col">
                Detalles
              </div>
              <div class="col-3 d-none d-sm-block">
                Estatus
              </div>
            </div>
          </div>
        </li>
        <template v-if="ordenes && ordenes.length">
          <li
            v-for="orden in ordenes"
            :key="orden.id"
            class="contenido row align-items-center"
            @click="$router.push({ name: 'Compra', params: {id: orden.id}})"
          >
            <div class="col-2 d-none d-sm-block">
              {{ orden.fecha.formatted }}
            </div>
            <div class="col">
              <p class="d-sm-none mb-1">
                Fecha: {{ orden.fecha.formatted }}
              </p>
              Compra ID:
              <span class="text-break">{{ orden.compraId }}</span>
              <span class="d-block text-break">Monto: $ {{ orden.monto }}</span>
              <p class="d-sm-none mb-1">
                Estatus: {{ OrdenEstatus[orden.estatus] }}
              </p>
            </div>
            <div class="col-3 d-none d-sm-block">
              {{ OrdenEstatus[orden.estatus] }}
            </div>
          </li>
        </template>
        <li
          v-else
          class="no-items"
        >
          Sin paquetes relacionados
        </li>
      </ul>

      <div class="row justify-content-around mt-3">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="btn-bordeado"
            @click="$emit('cerrar', false)"
          >
            Cerrar
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { EstatusPago, EstatusPagoIconos,EstatusPagoIconoColor } from "@/constantes/pagos.js";
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import { OrdenEstatus } from "@/constantes/ordenes.js";
import pagoGql from "@/graphql/pago.gql";

import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: 'ModalPagoDetalles',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      default: false,
    },
    pago: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      PaqueteEstatus,
      OrdenEstatus,
      pagoItems: {
        compra: [],
        paquete: [],
      }
    }
  },
  watch: {
    mostrarModal: function() {
      if(this.mostrarModal) {
        this.obtenerItems();
      }
    },
  },
  computed: {
    ordenes() {
      let ordenes = []
      ordenes = this.pagoItems.compra
      return ordenes;
    },
    paquetes() {
      let paquetes = []
      paquetes = this.pagoItems.paquete
      return paquetes;
    },
  },
  methods: {
    obtenerItems() {
      this.botonDeshabilitado = true;
       this.$apollo
        .watchQuery({
          query: pagoGql,
          variables: {
            id: this.pago.id,
          },
          update: (data) => data.Pago[0],
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.Pago && data.Pago[0]){
              this.pagoItems = data.Pago[0];
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.botonDeshabilitado = false;
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.subtitulo {
  color: $gris-label;
  display: block;
  user-select: none;
}

</style>
<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ tituloModal }}
        </mdb-modal-title>
        <a class="close" @click="cerrarModal()">
          x
        </a>
      </mdb-modal-header>
      <mdb-modal-body v-if="step === 1 || step === 2" class="pt-0">
        <!-- Sección: Seleccionar método para escanear -->
        <section
          v-if="step === 1"
          class="row justify-content-center mx-0"
        >
          <p class="col-12 text-center px-4 px-sm-5">
            <span class="d-block mt-2 font-weight-bold" >
              Escanea el código de barras ó QR {{ esNavbar ? 'del paquete' : 'de los paquetes a reempacar' }}
            </span>
          </p>
          <!-- BarCode || QR -->
          <p
            v-if="error"
            class="error col-12"
          >
            {{ error }}
          </p>

          <p
            v-if="resultado"
            class="decode-result text-center col-12"
          >
            Paquete ID ó Tracking:
            <span class="font-weight-bold">{{ resultado }}</span>
          </p>
          <span
            v-if="botonDeshabilitado"
            class="col-12 text-center"
          >
            Cargando, por favor espera
          </span>

          <StreamBarcodeReader
            v-if="!botonDeshabilitado"
            @decode="decodificar"
            @loaded="administrarCamara"
          >
          </StreamBarcodeReader>

        <section class="col-12 mt-3 px-0 px-lg-5">
          <header class="font-weight-bold">
            Ingresar los datos manualmente  {{ esNavbar ? 'del paquete' : 'de los paquetes a reempacar' }}
          </header>
          <PaqueteBuscador
            class="mt-2 mb-large"
            placeholder="Ingrese el tracking o paquete ID"
            :limpiarPaquete="limpiarPaquete"
            @paquete="obtenerPaqueteBuscador($event)"
            @limpiar="limpiarPaquete = $event"
            :habilitarAutoFocus="true"
            :autoSeleccinar="true"
          />
          <div class="text-center">
            <mdb-btn
              v-if="paquetes.length"
              flat
              dark-waves
              icon="list"
              :disabled="botonDeshabilitado"
              class="btn-bordeado px-3 bg-white"
              @click="step = 2"
            >
              Listado de paquetes
            </mdb-btn>
          </div>
        </section>
        </section>

        <!-- Listado de paquetes en caso se escané más de un paquete -->
        <section
          v-if="paquetes.length && paquetes[0].id && step === 2"
          class="animated fadeIn"
        >
          <ul class="table" data-table="sticky">
            <li class="encabezado row">
              <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
              <div class="col-3 d-none d-lg-block">Estatus</div>
              <div class="col pl-0">Detalles</div>
              <div class="col-4 col-md d-none d-sm-block">Receptor</div>
              <div class="col-auto d-none d-lg-block accion">Acción</div>
            </li>
            <li
              v-for="paquete in paquetes"
              :key="`paquete-${paquete.id}`"
              class="contenido normal row align-items-center"
            >
              <!-- Fecha recibido -->
              <div class="col-3 col-sm-2 col-lg-1">
                <span class="d-block">
                  {{ formatearFecha(paquete.fecha.formatted).ddmm }}
                </span>
                {{ formatearFecha(paquete.fecha.formatted).yyyy }}
              </div>
              <!-- Estatus -->
              <div class="col-3 d-none d-lg-block">
                {{ PaqueteEstatus[paquete.estatus] }}
                <p class="mb-0">
                  Envío:
                  <span :class="{ 'text-muted font-italic': !paquete.envio }">
                    {{
                      paquete.envio
                        ? ConsolidadoTipoEnvio[paquete.envio]
                        : "Sin especificar"
                    }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                    {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                  </span>
                </p>
              </div>
              <!-- Detalles -->
              <div class="col pl-0">
                <!-- Columna estatus -->
                <div class="d-lg-none mb-1">
                  {{ PaqueteEstatus[paquete.estatus] }}
                  <p class="mb-0">
                    Envío:
                    <span :class="{ 'text-muted font-italic': !paquete.envio }">
                      {{
                        paquete.envio
                          ? ConsolidadoTipoEnvio[paquete.envio]
                          : "Sin especificar"
                      }}
                    </span>
                  </p>
                  <p class="mb-0">
                    Tipo:
                    <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                      {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                    </span>
                  </p>
                </div>
                Tracking:
                <span :class="{ 'text-muted font-italic': !paquete.tracking }">
                  {{ paquete.tracking ? paquete.tracking : "Sin especificar" }}
                </span>
                <p class="mb-0">
                  Cliente:
                <span :class="{ 'text-muted font-italic': !paquete.cliente }">
                  {{ paquete.cliente ? paquete.cliente.nombre : "Sin cliente" }}
                </span>
                </p>
                <p class="mb-0">
                  <span title="Peso por volumen">
                    vlbs:
                    {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166) }}
                    |
                  </span>
                  <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span
                  >
                  <span title="Pies cúbicos">
                    ft<sup>3</sup>:
                    {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728) }}
                  </span>
                </p>
                <!-- Columna receptor -->
                <p class="mb-0 mt-1 d-sm-none">
                  Receptor:
                  <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                    {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
                  </span>
                </p>
                <p class="mb-0 d-sm-none">
                  Instrucciones de envío:
                  <!-- Dirección de envío: -->
                  <span
                    v-if="
                      paquete.direccionDestino &&
                      paquete.direccionDestino.ciudad &&
                      paquete.direccionDestino.direccion
                    "
                  >
                    {{ paquete.direccionDestino.ciudad.estado.nombre }},
                    {{ paquete.direccionDestino.ciudad.nombre }},
                    {{ paquete.direccionDestino.direccion }}
                  </span>
                  <span v-else class="text-muted font-italic"
                    >Sin especificar</span
                  >
                </p>
                <span v-if="paquete.observacion">
                  Observación: {{ paquete.observacion }}
                </span>
                <mdb-btn
                  flat
                  dark-waves
                  icon="trash"
                  :disabled="botonDeshabilitado"
                  class="btn-bordeado mx-0 my-2 px-3 bg-white d-lg-none"
                  title="Remover paquete"
                  @click="removerPaquete(paquete.id, paquete.tracking)"
                >
                  Remover
                </mdb-btn>
              </div>
              <!-- Receptor -->
              <div class="col-4 col-md d-none d-sm-block">
                Receptor:
                <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                  {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
                </span>
                <p class="mb-0">
                  Instrucciones de envío:
                  <!-- Dirección de envío: -->
                  <span
                    v-if="
                      paquete.direccionDestino &&
                      paquete.direccionDestino.ciudad &&
                      paquete.direccionDestino.direccion
                    "
                  >
                    {{ paquete.direccionDestino.ciudad.estado.nombre }},
                    {{ paquete.direccionDestino.ciudad.nombre }},
                    {{ paquete.direccionDestino.direccion }}
                  </span>
                  <span v-else class="text-muted font-italic">Sin especificar</span>
                </p>
              </div>
              <div class="col-auto d-none d-lg-block accion">
                <mdb-btn
                  flat
                  dark-waves
                  icon="trash"
                  :disabled="botonDeshabilitado"
                  class="btn-bordeado m-0 px-3"
                  title="Remover paquete"
                  @click="removerPaquete(paquete.id, paquete.tracking)"
                >
                  <span class="d-lg-none">
                    Remover
                  </span>
                </mdb-btn>
              </div>
            </li>
          </ul>
        </section>
      </mdb-modal-body>
      <!-- Funcionalidad reempacar paquetes -->
      <mdb-modal-footer
        v-if="paquetes.length && step === 2"
        class="justify-content-around"
      >
        <mdb-btn
          flat
          dark-waves
          icon="camera"
          :disabled="botonDeshabilitado"
          class="btn-bordeado px-3"
          @click="step = 1"
        >
          Escanear más paquetes
        </mdb-btn>
        <mdb-btn
          :color="esNavbar ? 'terciario' : 'primario'"
          :icon="botonDeshabilitado ? 'circle-notch' : 'box-open'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          class="px-3"
          :disabled="botonDeshabilitado"
          @click="reempacarPaquetes"
        >
          Reempacar {{ paquetes.length > 1 ? 'paquetes' : 'paquete' }}
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </section>
</template>

<script>
import gql from "graphql-tag";
import paquetesEscaneadosGql from "@/graphql/paquetesEscaneados.gql";
import paquetesEscaneadosIdGql from "@/graphql/paquetesEscaneadosId.gql";
import PaqueteBuscador from "@/components/PaqueteBuscador.vue"
import { PaqueteEstatus, PaqueteTipos } from "@/constantes/paquetes.js";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { ConsolidadoTipoEnvio } from "@/constantes/consolidados.js";
import {
  mostrarSoloDosDecimales,
  formatearFecha,
} from "@/funciones/funciones.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
} from "mdbvue";

export default {
  name: "ModalEscaner",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
    StreamBarcodeReader,
    PaqueteBuscador
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esNavbar: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      PaqueteTipos,
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      mostrarSoloDosDecimales,
      formatearFecha,
      botonDeshabilitado: false,
      paquetes: [],
      tracking: [],
      step: 1,
      // QR
      error: '',
      resultado: '',
      limpiarPaquete: false
    };
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.cerrarModal();
      }
    },
  },
  computed: {
    tituloModal() {
      return this.esNavbar
        ? "Escanear para obtener detalles del paquete"
        : "Escanear para crear paquetes tipo reempaque";
    },
  },
  methods: {
    // Método QR
    decodificar (resultado) {
      this.botonDeshabilitado = true;
      if(this.tracking.includes(resultado)){
        this.botonDeshabilitado = false
        return this.alertaMensaje(
          "El paquete que intentas agregar ya se encuentra agregado",
          "error"
        );
      }
      if(this.esNavbar){
        return this.obtenerPaquete(resultado, true)
      }
      this.resultado = resultado
      this.obtenerPaquete(resultado)
    },
    async administrarCamara (promise) {
      try {
        await promise
      } catch (error) {
        this.error = `Ha ocurrido un error inesperado con la cámara, por favor intenta de nuevo`;
      }
    },
    // Fin métodos QR
    obtenerPaquete(string, esRedireccion) {
      this.botonDeshabilitado = true
      const filter = {
        tracking: string,
        reempacado: false,
      };
      const variablesQuery = {
        filter,
      };
      if(!esRedireccion){
        this.$apollo
          .query({
            query: gql`
              ${paquetesEscaneadosGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Paquete[0],
            fetchPolicy: "no-cache",
          })
          .then(({data}) => {
            if(data && data.Paquete && data.Paquete.length){
              if (data.Paquete[0].reempacado) {
                this.alertaMensaje(
                  "El paquete que intentas agregar es de tipo reempaque",
                  "error"
                );
                this.botonDeshabilitado = false
                return;
              }
              this.tracking.push(string)
              const paqueteAgregar = JSON.parse(JSON.stringify(data.Paquete[0]))
              this.paquetes.push(paqueteAgregar);
              const idsPaquete = this.paquetes.map(p => p.id)
              this.paquetes = this.paquetes.filter(({id}, index) => !idsPaquete.includes(id, index + 1))
              this.step = 1;
              this.limpiarCampos();
              this.alertaMensaje(
                "Paquete agregado correctamente",
                "correcto"
              );
            } else {
              this.alertaMensaje(
                "El paquete no existe o ya se encuentra dentro de un reempaque",
                "advertencia"
              );
              this.limpiarPaquete = true
            setTimeout(()=> {
              this.botonDeshabilitado = false;
              this.resultado = '';
              this.step = 1
            }, 1000);
            }
          })
          .catch(() => {
            this.alertaMensaje(
              "El paquete no existe o ya se encuentra dentro de un reempaque",
              "advertencia"
            );
            setTimeout(()=> this.botonDeshabilitado = false, 2500);
          })
      } else {
        // Solo se obtendra el id del paquete para redirigir ruta a los detalles del paquete
        this.$apollo
          .watchQuery({
            query: gql`
              ${paquetesEscaneadosIdGql}
            `,
            variables: {
             filter: {
                OR: [
                  {
                    numeroHR: string,
                  },
                  {
                    tracking: string,
                  },
                ],
              },
            },
            update: (data) => data.Paquete[0],
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Paquete){
                if(!data?.Paquete[0]){
                  this.alertaMensaje(
                    "El paquete que intentas acceder no existe",
                    "advertencia"
                  );
                  setTimeout(()=> this.botonDeshabilitado = false, 2500);
                  return;
                }
                const id = data?.Paquete[0]?.id
                this.$router.push({ name: 'Paquete', params: { paqueteId: id } })
                this.cerrarModal()
              }
            },
            error: () => {
              this.alertaMensaje(
                "Ha ocurrido un error obteniendo el paquete. Por favor intenta de nuevo",
                "error"
              );
              this.botonDeshabilitado = false;
            }
          })
      }
    },
    removerPaquete(id, tracking){
      this.paquetes = this.paquetes.filter(p => p.id !== id)
      this.tracking = this.tracking.filter(n => n !== tracking)
      if(!this.paquetes.length){
        this.step = 1
      }
      this.limpiarCampos();
    },
    reempacarPaquetes(){
      // Valida que todos los paquetes sean del mismo cliente
      const cliente = this.paquetes[0]?.cliente
      const paquetes = this.paquetes.filter(p => p.cliente?.id === cliente?.id)
      if(paquetes.length !== this.paquetes.length){
        this.alertaMensaje(
          "Los paquetes deben pertenecer al mismo cliente",
          "advertencia"
        );
        return;
      }
      this.botonDeshabilitado = true
      this.$emit('paquetes', JSON.parse(JSON.stringify(this.paquetes)))
      this.limpiarCampos();
    },
    alertaMensaje(contenido, tipo) {
      this.$emit("alertaMensaje", {
        contenido,
        tipo,
      });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.resultado = '';
      this.error = '';
    },
    cerrarModal() {
      this.limpiarCampos();
      this.step = 1;
      this.paquetes = [];
      this.tracking = [];
      this.$emit('cerrar', false);
    },
    obtenerPaqueteBuscador(evento){
      this.obtenerPaquete(evento.tracking, this.esNavbar)
    }
  },
};
</script>

<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
}
.accion {
  width: 86px;
}

.mb-large {
  margin-bottom: 12rem;
}
</style>

/**
 * Formatos de impresión.
 * Ordenados alfabéticamente.
 * @returns {Array} Vista de impresión
 */

/**
 * FORMATO DE ETIQUETAS PARA LOS PAQUETES
 * Componente: ModalEtiquetas.vue
 * @param {Array} paquetes Array con los paquetes
 * @param {Boolean} esEtiquetaIndividual Booleano para indicar si se imprime una etiqueta desde PaqueteId.vue
 */
function generarEtiquetas (paquetes, esEtiquetaIndividual = false) {
  const paquetesConFormato = []
  const cantidadPaquetes = paquetes.length

  if (esEtiquetaIndividual) {
    const paquete = paquetes[0]

    paquetesConFormato.push({
      stack: [
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
        {
          columns: [
            {
              width: 'auto',
              image: paquete.agenciaDestino.urlLogo,
              fit: [30, 30]
            },
            {
              width: '*',
              text: paquete.agenciaDestino.nombre,
              style: 'header4',
              alignment: 'center',
            },
          ]
        },
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
        {
          text: paquete.numeroHR,
          alignment: 'center',
          bold: true,
          fontSize: 12,
          margin: [0, 2]
        },
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
        {
          columns: [
            {
              text: paquete.cliente && paquete.cliente.casillero
              ? paquete.cliente.casillero
              : 'Sin especificar',
              bold: true,
              margin: [ 0, 10, 0, 0 ]
            },
            {
              text: [
                paquete.numeroCaja === 'Sin especificar'
                  ? paquete.numeroCaja
                  : {
                      text: [
                        {
                          text: paquete.numeroCaja[0],
                          bold: true,
                        },
                        paquete.numeroCaja.slice(1)
                      ],
                    },
              ],
              alignment: 'center',
              fontSize: paquete.numeroCaja === 'Sin especificar'
                ? 9
                : 14,
              margin: paquete.numeroCaja === 'Sin especificar'
                ? [ 0, 10, 0, 0 ]
                : [ 0, 7, 0, 0 ]
            },
            {
              stack: [
                {
                  text: `${paquete.peso} lbs`,
                  alignment: 'right',
                },
                {
                  text: `${paquete.dimensiones} vlbs`,
                  alignment: 'right',
                },
                {
                  text: `${paquete.piesCubicos} ft3`,
                  alignment: 'right',
                }
              ]
            },
          ]
        },
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
        {
          columns: [
            paquete.envioSimbolo === 'Sin especificar'
            ? {
                text: 'Tipo de envío sin especificar',
                margin: [ 0, 16, 0, 0 ]
              }
            : {
                image: paquete.envioSimbolo,
                fit: [63, 63],
              },
            {
              width: 'auto',
              qr: paquete.numeroHR,
              fit: '80',
            },
          ],
          margin: [ 0, 0, 0, 4 ]
        },
        {
          columns: [
            {
              width: '*',
              text: paquete.politicaEnvio
            },
            {
              width: '30%',
              text: paquete.fechaEntradaConsolidado,
              alignment: 'right',

            }
          ]
        },
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
        {
          image: paquete.codigoBarra,
          alignment: 'center',
          width: 250,
          height: 50
        },
        {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 516, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      ]
    })
  } else if (cantidadPaquetes) {
    for (let index = 0; index < cantidadPaquetes; index+=2) {
      const indexSiguiente = index + 1
      const columnas = []
      columnas.push(estructuraVariasEtiquetas(paquetes[index]))

      if (paquetes[indexSiguiente]) {
        columnas.push(estructuraVariasEtiquetas(paquetes[indexSiguiente]))
      }

      paquetesConFormato.push({
        style: 'etiquetaFont1',
        columns: columnas
      })
    }
  }

  const documento = paquetesConFormato
  return documento
}

/** ESTRUCTURA PARA VARIAS ETIQUETAS
 * Estructura de varias etiquetas para evitar el código duplicado
 * en la función generarEtiquetas
 * Archivo: formatosImpresion.js
 * @param {Object} paquete Objeto con los datos del paquete a estructurar
 * @returns {Object} Objeto en formato de etiqueta
 */
function estructuraVariasEtiquetas (paquete) {
  return {
    width: '50%',
    stack: [
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      {
        columns: [
          {
            width: 'auto',
            image: paquete.agenciaDestino.urlLogo,
            fit: [30, 30]
          },
          {
            width: '*',
            text: paquete.agenciaDestino.nombre,
            style: 'header4',
            alignment: 'center',
          },
        ]
      },
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      {
        text: paquete.numeroHR,
        alignment: 'center',
        bold: true,
        fontSize: 12,
        margin: [0, 2]
      },
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      {
        columns: [
          {
            text: paquete.cliente && paquete.cliente.casillero
            ? paquete.cliente.casillero
            : 'Sin especificar',
            bold: true,
            margin: [ 0, 10, 0, 0 ]
          },
          {
            text: [
              paquete.numeroCaja === 'Sin especificar'
                ? paquete.numeroCaja
                : {
                    text: [
                      {
                        text: paquete.numeroCaja[0],
                        bold: true,
                      },
                      paquete.numeroCaja.slice(1)
                    ],
                  },
            ],
            alignment: 'center',
            fontSize: paquete.numeroCaja === 'Sin especificar'
              ? 9
              : 14,
            margin: paquete.numeroCaja === 'Sin especificar'
              ? [ 0, 10, 0, 0 ]
              : [ 0, 7, 0, 0 ]
          },
          {
            stack: [
              {
                text: `${paquete.peso} lbs`,
                alignment: 'right',
              },
              {
                text: `${paquete.dimensiones} vlbs`,
                alignment: 'right',
              },
              {
                text: `${paquete.piesCubicos} ft3`,
                alignment: 'right',
              }
            ]
          },
        ]
      },
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      {
        columns: [
          paquete.envioSimbolo === 'Sin especificar'
          ? {
              text: 'Tipo de envío sin especificar',
              margin: [ 0, 16, 0, 0 ]
            }
          : {
              image: paquete.envioSimbolo,
              fit: [43, 43],
            },
          {
            width: 'auto',
            qr: paquete.numeroHR,
            fit: '60',
          },
        ],
        margin: [ 0, 0, 0, 4 ]
      },
      {
        columns: [
          {
            width: '*',
            text: paquete.politicaEnvio
          },
          {
            width: '30%',
            text: paquete.fechaEntradaConsolidado,
            alignment: 'right',

          }
        ]
      },
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
      {
        image: paquete.codigoBarra,
        alignment: 'center',
        width: 250,
        height: 50
      },
      {canvas: [ { type: 'line', x1: 0, y1: 0, x2: 258, y2: 0, lineWidth: 1} ],  margin: [0,5] },
    ]
  }
}


/**
 * RECIBO DE PAQUETES POR CONSOLIDADO / CLIENTE
 * Componente: ModalRecibo.vue
 * @param {Object} remitente Objeto con datos del cliente, en formato {nombre, identificacion, casillero, telefono, direccionDestino}
 * @param {Object} destinatario Objeto de la persona que recibe, en formato {nombre, identificacion}
 * @param {Object} consolidado Objeto con datos del consolidado, en formato {guiaMaster}
 * @param {Object} paquetes Objeto de paquetes recibidos, en formato {headerRows as int, widths as array, body as array}
 * @param {Object} informacionPaquetes Objetos con información sobre los paquetes, en formato {cantidadPaquetes, asegurados, aliado}
 * @param {String} logoHR Contiene el logo HR
 * @param {String} nombreHR Contiene el nombre de la Agencia
 */

function reciboPaquetesConsolidadoCliente (remitente, destinatario, consolidado, paquetes, informacionPaquetes, logoHR, nombreHR) {
  const documento = [
    // Encabezado de la página: Logo, Casillero cliente (Código cliente) y Consolidado
    {
      columns: [
        {
          width: 30,
          image: logoHR, // Logo HR
          fit: [30, 30]
        },
        {
          width: '*',
          text: nombreHR,
          style: 'header1'
        },
        {
          width: 'auto',
          columns: [
            // Detalles del consolidado
            {
              stack: [
                {
                  text: 'Guía consolidado',
                  style: 'header5'
                },
                consolidado.guiaMaster
              ]
            },
            // Código cliente | Casillero cliente
            {
              width: 'auto',
              stack: [
                {
                  text: 'Código cliente',
                  style: 'header5'
                },
                remitente.casillero
              ]
            }
          ]
        }
      ],
      margin: [0, 0, 0, 10],
    },
    // Detalles del envío: Destinatario, Remitente, Consolidado.
    {
      columns: [
        // Destinatario
        {
          width: '*',
          stack: [
            {
              text: 'Destinatario',
              style: 'header6'
            },
            destinatario.nombre,
            destinatario.identificacion
              ? {text: [{text: 'Cédula: ', bold: true}, remitente.identificacion]}
              : '',
            remitente.direccionDestino
          ],
        },
        // Remitente
        {
          width: '30%',
          stack: [
            {
              text: 'Remitente',
              style: 'header6'
            },
            remitente.nombre,
            {
              text: [
                {text: 'Cédula: ', bold: true},
                remitente.identificacion
              ]
            },
            {
              text: [
                {text: 'Teléfono: ', bold: true},
                remitente.telefono
              ]
            }
          ],
        },
        // Detalles del envío: informacionPaquetes y Aliado
        {
          width: '*',
          stack: [
            {
              text: 'Detalles del envío',
              style: 'header6'
            },
            {
              text: [
                {text: 'Aliado: ', bold: true},
                informacionPaquetes.aliado ? informacionPaquetes.aliado : ''
              ]
            },
            {
              text: [
                {text: 'Cant. total de paquetes: ', bold: true},
                informacionPaquetes.cantidadPaquetes
              ]
            },
            {
              text: [
                {text: 'Asegurados: ', bold: true},
                informacionPaquetes.asegurados
              ]
            }
          ],
        }
      ],
      margin: [0, 5]
    },
    // Tabla de paquetes en el consolidado
    {
      layout: 'lightHorizontalLines', // Estilos de los bordes
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: paquetes.headerRows,
        widths: paquetes.widths,
        body: paquetes.body
      },
      margin: [0, 5]
    },
    // Datos de recepción
    {
      text: 'Recibido por',
      margin: [0, 13, 0, 7],
      style: 'header5'
    },
    {
      layout: 'lightHorizontalLines', // Estilos de los bordes
      table: {
        headerRows: 1,
        widths: [ '*', '20%', '20%', '25%' ],
        body: [
          [
            {text: 'NOMBRE Y APELLIDO', style: 'tableHeader'},
            {text: 'N° DE CÉDULA', style: 'tableHeader'},
            {text: 'FECHA / HORA', style: 'tableHeader'},
            {text: 'FIRMA', style: 'tableHeader'},
          ],
          [{text: '', margin: [0, 15]}, '', '', ''],
          [{text: 'Observación: ', style: 'header6', margin: [0, 5]}, '', '', ''],
        ]
      },
    }
  ]
  return documento
}

export {
  generarEtiquetas,
  reciboPaquetesConsolidadoCliente
}

<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false);
      limpiarCampos();"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ tituloModal }}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row justify-content-around" @submit.prevent>
        <div class="col-12 col-lg-6">
          <header class="font-weight-bold user-select-none">
            Datos de dirección
          </header>
          <p v-if="direccionEspecificada.ciudad && direccionEspecificada.direccion">
            {{ direccionEspecificada.ciudad.nombre }},
            {{ direccionEspecificada.ciudad.estado.nombre }}.
            {{direccionEspecificada.direccion}}
          </p>
          <p v-else class="text-muted user-select-none">
            Sin especificar
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <header class="mt-2 mt-md-0 font-weight-bold user-select-none">
            Aliados
          </header>
          <div
            :class="[{'cursor-not-allowed': !(aliados && aliados.length)},
              {'campo': validarValores.aliado
                && validarValores.aliado.length},
              {'valido': validarValores.aliado == 'valido'},
              {'invalido': validarValores.aliado == 'invalido'},
            ]"
          >
            <!-- nombre: {{nombreAliado}} -->
            <div
              :class="['md-form md-outline outline-select my-2',
                {'no-pointer-events': !(aliados && aliados.length)}]"
            >
              <select
                v-model="aliadoSeleccionado"
                id="aliado-select"
                class="custom-select"
              >
                <template v-if="aliados.length">
                  <option class="d-none" disabled value="">
                    Seleccione
                  </option>
                  <option
                    v-for="(value, key) in aliados"
                    :key="key"
                    :value="value.id"
                    @click="nombreAliado = value.nombre"
                  >
                    {{ value.nombre }} -
                    <span
                    v-for="(estado, i) in value.estados"
                    :key="estado+i"
                    >
                      {{estado}}{{i == value.estados.length - 1 ? '' : ', '}}
                    </span>
                  </option>
                </template>
                <option v-else disabled value="">
                  No hay aliados disponibles
                </option>
              </select>
              <small
                v-if="!(aliados && aliados.length)"
                class="form-text text-muted user-select-none"
              >
                <font-awesome-icon
                  icon="exclamation-triangle"
                  class="text-warning"
                />
                Debes registrar al menos 1 aliado
              </small>
              <label
                for="aliado-select"
                :class="aliadoSeleccionado ? 'label-active' : 'label-inactive'"
              >
                Aliado
              </label>
            </div>
            <p
              v-if="validarValores.aliado === 'invalido' && (aliados && aliados.length)"
              class="mensaje-invalido"
            >
              Selecciona el aliado
            </p>
          </div>
        </div>
        <div
          :class="['col-auto mt-3 px-0', {'cursor-not-allowed': !(aliados && aliados.length)}]"
          :title="aliados && aliados.length ? '' : 'Esta acción solo se permite con aliados registrados'"
        >
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="col-auto m-0 px-3"
            :disabled="botonDeshabilitado || !(aliados && aliados.length)"
            @click="validarAliado"
          >
            {{ botonPrincipal.texto }}
          </mdb-btn>
        </div>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import gql from "graphql-tag";
import clienteGql from "@/graphql/cliente.gql";
import agenciaGql from "@/graphql/agencia.gql";
import direccionAsignarAliadoGql from "@/graphql/direccionAsignarAliado.gql";
import aliadosGql from "@/graphql/aliados.gql";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name:'ModalSeleccionarAliado',
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esAgencia: {
      type: Boolean,
      required: false,
    },
    esCliente: {
      type: Boolean,
      required: false,
    },
    aliadoId: {
      type: String,
      required: false,
    },
    variablesQueryAgencia: {
      type: Object,
      required: false,
    },
    direccionEspecificada: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
  },
  data(){
    return {
      botonDeshabilitado: false,
      validarValores: {},
      aliadoSeleccionado: '',
      nombreAliado: '',
      aliados: [],
     }
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
      } else {
        this.aliadoId
          ? this.aliadoSeleccionado = JSON.parse(JSON.stringify(this.aliadoId))
          : ''
      }
    },
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    validarAliado() {
      this.botonDeshabilitado = true;
      this.validarValores = {
        aliado: this.aliadoSeleccionado ? "valido" : "invalido",
      };

       // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.botonDeshabilitado = false;
        this.alertaMensaje('El aliado es requerido', 'error');
        return;
      }
      if(!this.direccionEspecificada.id){
        this.alertaMensaje('Ha ocurrido un error. Por favor intenta más tarde', 'error');
      }

      this.direccionAsignarAliado();
    },
    direccionAsignarAliado(){
      this.botonDeshabilitado = true;
      if(this.aliadoId.includes(this.aliadoSeleccionado)){
        this.botonDeshabilitado = false;
        return this.alertaMensaje('El aliado ya se encuentra asignado a la dirección. Por favor verifica tus datos', 'advertencia');
      }
      const aliadoSeleccionado = this.aliados.filter(a => a.id == this.aliadoSeleccionado)
      const aliado = {
        id: this.aliadoSeleccionado,
        nombre: aliadoSeleccionado[0].nombre,
        __typename:"Aliado"
      }
      this.$apollo
        .mutate({
          mutation: gql`${direccionAsignarAliadoGql}`,
          variables: {
            direccionId: this.direccionEspecificada.id,
            aliadoId: this.aliadoSeleccionado,
          },
          update: (store, {data : {direccionAsignarAliado}}) => {
            const msg = direccionAsignarAliado.codigo;
          switch (msg) {
            case "Correcto":
              this.alertaMensaje('Se ha asignado un aliado a la dirección con éxito.', 'correcto');
              break;
            case "Fallido":
              this.alertaMensaje('Ha ocurrido un error asignando el aliado.'
                  +' Por favor intenta de nuevo', 'error');
              this.botonDeshabilitado = false;
              return
            default:
              this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error');
              this.botonDeshabilitado = false;
              return
          }
            if(this.esAgencia){
              // View de agencia
              const datas = store.readQuery({
                query: agenciaGql,
                variables: this.variablesQueryAgencia,
              });

              datas.Agencia[0].direccion.aliado = aliado

              store.writeQuery({
                query: agenciaGql,
                variables: this.variablesQueryAgencia,
                data: datas,
              });
            } else {
              // View de cliente
              const datas = store.readQuery({
                query: clienteGql,
                variables: {
                  filter:{
                    id: this.$route.params.id
                  }
                },
              })

              const direcciones = JSON.parse(JSON.stringify(datas.Cliente[0].direcciones))
              const direccionesActualizadas = []
              direcciones.forEach((d) => {
                if(d.id === this.direccionEspecificada.id) {
                  Object.assign(d, {aliado})
                }
                direccionesActualizadas.push(d)
              });
              datas.Cliente[0].direcciones = direccionesActualizadas

              store.writeQuery({
                query: clienteGql,
                variables: {
                  filter:{
                    id: this.$route.params.id
                  }
                },
                data: datas,
              });
            }

            this.limpiarCampos();
            this.$emit('cerrar', false);
          },
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false;
        });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.validarValores = {};
      this.aliadoSeleccionado = '';
      this.nombreAliado = '';
    },
  },
  computed: {
    botonPrincipal() {
      return this.aliadoId
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'};
    },
    tituloModal() {
      return 'Asignar aliado a la dirección'
    },
  },
  apollo: {
    aliados() {
      return {
        query: aliadosGql,
        update: (data) => data.Aliado,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1rem;

  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
.mt-campo-btn {margin-top: 10px;}
</style>
<template>
  <!-- Modal Seleccionar Dirección -->
  <mdb-modal
    :show="mostrarModal"
    centered
    elegant
    scrollable
    size="lg"
    @close="limpiarCampos(); $emit('close', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>Asignar dirección al paquete</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div v-if="direccionesDelCliente && direccionesDelCliente.length">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="md-form md-outline outline-select mt-n3">
              <select
                id="tipo-envio-select"
                v-model="tipoEnvioPaquete"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione envío
                </option>
                <option
                  v-for="(key, value) in ConsolidadoTipoEnvio"
                  :key="key"
                  :value="value"
                >
                  {{ key }}
                </option>
              </select>
              <label
                for="tipo-envio-select"
                :class="tipoEnvioPaquete ? 'label-active' : 'label-inactive'"
              >
                Tipo de envío
              </label>
            </div>
          </div>
        </div>
        <h6>
          Direcciones del cliente
          <span v-if="paqueteSeleccionado
            && paqueteSeleccionado.cliente">{{ paqueteSeleccionado.cliente.nombre }}:
          </span>
        </h6>
        <ul class="list-unstyled mb-0">
          <li
            v-for="(direccion, iD) in direccionesDelCliente"
            :key="`direccion${iD}`"
          >
            <label
              v-if="direccionesDelCliente.length > 1"
              class="opciones-radio ml-0"
              title="Seleccionar dirección"
            >
              <input
                v-model="DireccionSeleccionada"
                type="radio"
                name="radio-direcciones"
                :value="direccion"
              >
              <span class="icono-radio" />
              Dirección {{iD+1}}:
            </label>
            <div class="row my-2">
              <p class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Dirección de envío
                </small>
                {{ direccion.direccion }}.
                <span class="d-block">
                  {{ direccion.ciudad.nombre }},
                  {{ direccion.ciudad.estado.nombre }},
                  {{ direccion.ciudad.estado.pais.nombre }}.
                </span>
              </p>
              <p class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Observación
                </small>
                <span
                  :class="{'text-muted user-select-none': !direccion.observacion}"
                >
                  {{ direccion.observacion ? direccion.observacion : 'Sin especificar' }}
                </span>
              </p>
              <div class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Persona que recibe
                </small>
                <span :class="{'text-muted user-select-none': !(direccion.recibe && direccion.recibe.nombre)}">
                  {{ direccion.recibe && direccion.recibe.nombre ?  direccion.recibe.nombre : 'Sin especificar'}}
                </span>
              </div>
              <div
                v-if="direccion.recibe"
                class="col-12 col-lg-6 mb-2"
              >
                <small class="text-muted user-select-none d-block">
                  Teléfonos
                </small>
                <ul
                  v-if="direccion.recibe.telefonos && direccion.recibe.telefonos.length"
                  class="list-unstyled mb-0"
                >
                  <li
                    v-for="(tel, i) in direccion.recibe.telefonos"
                    :key="`tel${i}`"
                    class="d-inline"
                  >
                    {{i > 0 ? ', ' : ''}}
                    <a
                      :href="`tel:+${tel.numero}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{{tel.numero}}
                    </a>
                  </li>
                </ul>
                <span v-else class="text-muted user-select-none">
                  Sin especificar
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="text-center text-muted user-select-none mb-4"
      >
        <font-awesome-icon
          :icon="['fas', 'map-signs']"
          class="fa-5x mb-4"
        />
        <p class="h3-responsive text-center mb-0 mx-lg-5 px-lg-5">
          El cliente no tiene direcciones registradas.
          Por favor agrega una dirección para continuar.
        </p>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer
      v-if="direccionesDelCliente && direccionesDelCliente.length"
      class="justify-content-center"
    >
      <mdb-btn
        color="primario"
        :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        :disabled="botonDeshabilitado"
        @click="paqueteAsignarDirección()"
      >
        Asignar instrucción de envío
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import agenciaGql from "@/graphql/agencia.gql";
import clienteBuscarDireccionesGql from "@/graphql/clienteBuscarDirecciones.gql";
import paqueteAsignarDestinoGql from "@/graphql/paqueteAsignarDestino.gql"
import paquetesGql from "@/graphql/paquetes.gql";
import paqueteGql from "@/graphql/paquete.gql";
import { leerPersonaNombre } from "@/utils/datosToken.js"
import paqueteModificarEnvioGql from "@/graphql/paqueteModificarEnvio.gql";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
} from "mdbvue";
import { ConsolidadoTipoEnvio } from "../constantes/consolidados";

export default {
  name: 'ModalSeleccionarDireccion',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esRutaHija: {
      type: Boolean,
      required: false,
    },
    esAgencia: {
      type: Boolean,
      required: false,
    },
    variablesUpdateQueryViewPaquetes: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    variablesQueryAgencia: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    paqueteSeleccionado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      DireccionSeleccionada: '',
      direccionesDelCliente: [],
      tipoEnvioPaquete:'',
      ConsolidadoTipoEnvio
    };
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
      }else {
        this.clienteBuscarDirecciones()
      }
    },
  },
  methods: {
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    asignarTipoEnvioPaquete(){
      this.botonDeshabilitado = true
      const variablesMutate = {
        envio:JSON.parse(JSON.stringify(this.tipoEnvioPaquete)),
        paquetesId: [this.paqueteSeleccionado.id]
      }
      this.$apollo
        .mutate({
          mutation: paqueteModificarEnvioGql,
          variables: variablesMutate,
          update: (store, { data: { paqueteModificarEnvio } }) => {
            const msg = paqueteModificarEnvio.codigo
            switch (msg) {
              case 'Correcto':
                this.alertaMensaje('Instrucción de envío asignada correctamente.', 'correcto')
                break
              case 'Fallido':
                this.botonDeshabilitado = false
                return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
                  'error')
              default:
                this.botonDeshabilitado = false
                return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error')
            }
            if(this.esRutaHija){
              const data = store.readQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
              });

              data.Paquete[0].envio = JSON.parse(JSON.stringify(this.tipoEnvioPaquete))

              store.writeQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
                data,
              });
            }
            this.$emit('close', false);
            this.botonDeshabilitado = false
          }
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false
        }).finally(()=>{
          this.tipoEnvioPaquete = ''
        })
    },  
    paqueteAsignarDirección() {
      if(this.tipoEnvioPaquete.length){
          this.asignarTipoEnvioPaquete()
      }
      if(this.paqueteSeleccionado.estatus === 'Devuelto'){
        this.alertaMensaje('No puedes asignar una dirección a un paquete devuelto', 'advertencia');
        return;
      }
      if(this.direccionesDelCliente.length!==1 && !this.DireccionSeleccionada){
        return this.alertaMensaje('Por favor selecciona una dirección antes de continuar',
          'advertencia')
      }
      if(this.direccionesDelCliente.length==1 && this.paqueteSeleccionado.direccionDestino
        && this.direccionesDelCliente[0].direccion === this.paqueteSeleccionado.direccionDestino.direccion){
          return this.alertaMensaje('La dirección ya se encuentra asignada al paquete',
          'advertencia')
        }
      const direccion = this.DireccionSeleccionada
        ? this.DireccionSeleccionada
        : this.direccionesDelCliente[0]
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${paqueteAsignarDestinoGql}
          `,
          variables: {
            paqueteId: this.paqueteSeleccionado.id,
            direccionId: direccion.id,
          },
          update: (store, {data: {paqueteAsignarDestino}}) => {
             const msg = paqueteAsignarDestino.codigo;
            switch (msg) {
              case "Correcto":
                break;
              default:
                this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo', 'error')
                this.botonDeshabilitado = false;
                return;
            }
            if(this.esAgencia){
                // se opera desde la view de agencia
                const data = store.readQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                });

                data.Agencia[0].paquetes = data.Agencia[0].paquetes.map(p => {
                  const paquete = p
                  if(p.id === this.paqueteSeleccionado.id){
                    paquete.direccionDestino = JSON.parse(JSON.stringify(direccion))
                  }
                  return paquete
                })

                store.writeQuery({
                  query: agenciaGql,
                  variables: this.variablesQueryAgencia,
                  data,
                });
            }
            // Si se esta en ruta hija se actualiza una query diferente
            if(this.esRutaHija){
              const data = store.readQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
              });

              data.Paquete[0].direccionDestino = JSON.parse(JSON.stringify(direccion))

              // Agregar al caché los cambios
              data.Paquete[0].cambios.unshift({
                autor: leerPersonaNombre(),
                descripcion: "Paquete asignado a destino",
                cliente: this.paqueteSeleccionado.cliente
                  ? this.paqueteSeleccionado.cliente.nombre
                  : '',
                defectuoso: this.paqueteSeleccionado.defectuoso,
                nuevoEstatus: this.paqueteSeleccionado.estatus,
                pagado: this.paqueteSeleccionado.pagado,
                fechaHora: {
                  formatted: new Date().toISOString(),
                __typename: "_Neo4jDateTime"
                },
                __typename: "Cambio"
            })

              store.writeQuery({
                query: paqueteGql,
                variables: {
                  id: this.$route.params.paqueteId
                },
                data,
              });
            }
            if(!this.esRutaHija && !this.esAgencia){
                // cuando se opera desde la view de paquetes
                const data = store.readQuery({
                    query: paquetesGql,
                    variables: this.variablesUpdateQueryViewPaquetes,
                });

                data.Paquete.map((p) => {
                  let paquete = p
                  if(p.id === this.paqueteSeleccionado.id){
                    paquete.direccionDestino = JSON.parse(JSON.stringify(direccion))
                  }
                  return (paquete)
                })

                store.writeQuery({
                  query: paquetesGql,
                  variables: this.variablesUpdateQueryViewPaquetes,
                  data,
                });
            }

            this.direccionesDelCliente = [];
            this.DireccionSeleccionada = '';
            this.botonDeshabilitado = false;
            this.alertaMensaje('Dirección asignada al paquete exitosamente','correcto')
            this.$emit('close', false);
          },
        })
        .catch(()=>{
          this.alertaMensaje('Ha ocurrido un error inesperado.'
            +' Por favor intenta de nuevo', 'error')
          this.botonDeshabilitado = false
        })
    },
    clienteBuscarDirecciones() {
      let id = ''
      if (this.paqueteSeleccionado.cliente) {
        id = this.paqueteSeleccionado.cliente.id
      } else return
      this.botonDeshabilitado = true;
      this.$apollo
        .watchQuery({
          query: gql`
            ${clienteBuscarDireccionesGql}
          `,
          variables: {
            id,
          },
          update: (data) => data.Cliente[0],
          fetchPolicy: "cache-and-network",
        })
        .subscribe({
          next: ({data}) => {
            if(data && data.Cliente && data.Cliente[0].direcciones){
              this.direccionesDelCliente = data.Cliente[0].direcciones;
            }
            this.botonDeshabilitado = false;
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las direcciones del cliente.'
                  +' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false;
          }
        })
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.DireccionSeleccionada = '';
      this.direccionesDelCliente = [];
    },
  },
}

</script>

 <style lang="scss" scoped>

 </style>
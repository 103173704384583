<template>
  <mdb-modal
    centered
    elegant
    :show="mostrarModal"
    @close="cerrarModal"
  >
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="content">
        <section class="cropper-area mb-3">
          <div class="img-cropper">
            <vue-cropper
              ref="cropper"
              :src="imgSrc"
              :viewMode="2"
              drag-mode="move"
              :zoomable="true"
              :guides="true"
              :view-mode="1"
              :rotatable="true"
              :highlight="true"
            />
          </div>
          <div class="actions">
            <div class="btn-group" role="group" aria-label="First group">
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="search-plus"
                class="btn-bordeado ml-0 p-2"
                title="Acercar"
                @click="zoomImg(0.2)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="search-minus"
                class="btn-bordeado p-2"
                title="Alejar"
                @click="zoomImg(-0.2)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="undo-alt"
                class="btn-bordeado p-2"
                title="Rotar -90°"
                @click="rotarImagen(-90)"
              />
              <mdb-btn
                flat
                dark-waves
                iconRight
                icon="redo-alt"
                class="btn-bordeado p-2"
                title="Rotar 90°"
                @click="rotarImagen(90)"
              />
              <a
                role="button"
                ref="horizontal"
                class="btn-bordeado p-2 btn btn-flat ripple-parent"
                title="Voltear horizontalmente"
                @click.prevent="girarHorizontal"
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z"/></svg>
              </a>
              <a
                role="button"
                ref="vertical"
                class="btn-bordeado mr-0 p-2 btn btn-flat ripple-parent"
                title="Voltear verticalmente"
                @click.prevent="girarVertical"
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529" style="transform: rotate(90deg)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z"/></svg>
              </a>
            </div>
            <mdb-btn
              flat
              dark-waves
              class="btn-bordeado p-2"
              @click="resetearEdicion"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#212529"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 3c-4.97 0-9 4.03-9 9H1l4 3.99L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"/></svg>
              Resetear imagen
            </mdb-btn>
            <mdb-btn
              color="terciario"
              icon="crop-alt"
              class="btn-bordeado p-2"
              @click="enviarArchivo"
            >
              Cortar
            </mdb-btn>
            <mdb-btn
              flat
              dark-waves
              icon="times"
              class="btn-bordeado p-2"
              @click="cerrarModal"
            >
              Cancelar
            </mdb-btn>
          </div>
        </section>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { regExpFoto } from "@/constantes/paquetes.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
export default {
  name: "ModalEditarImagen",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    VueCropper,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
      default: "Editar foto",
    },
    imagen: {
      type: File,
      required: false,
    },
    imgSrc: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      regExpFoto,
      cargando: false,
      cropImg: "",
    };
  },
  created() {
    if (this.imgSrc) {
      this.$refs.cropper.replace(this.imgSrc);
    }
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrar", false);
      this.cerrarCropper();
    },
    enviarArchivo() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.cargando = true;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const fileImg = this.blobToFile(blob);
        this.$emit("imagenModificada", fileImg);
        this.cerrarModal();
      });
      this.cargando = false;
    },
    cerrarCropper() {
      this.mostrarCropper = false;
      this.cropImg = "";
    },
    alerta(contenido, tipo) {
      this.$emit("alerta", { contenido, tipo });
    },
    blobToFile(blob) {
      return new File([blob], this.imagen?.name ?? `imagen-${Date.now()}.jpeg}` , {
        type: this.imagen?.type ?? "image/jpeg",
      });
    },
    girarHorizontal() {
      const dom = this.$refs.horizontal;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    girarVertical() {
      const dom = this.$refs.vertical;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    resetearEdicion() {
      this.$refs.cropper.reset();
      this.alerta("La imagen ha sido restablecida", "advertencia");
    },
    rotarImagen(deg) {
      this.$refs.cropper.rotate(deg);
    },
    zoomImg(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper-container.cropper-bg {
  width: 100% !important;
  height: auto !important;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 1rem;

  .btn-group {width: 100%;}
  a {color: #212529 !important;}
}
</style>
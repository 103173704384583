<template>
  <div class="container-xl">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Historial de pagos</h2>
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          class="btn-bordeado my-sm-0 px-3"
          :disabled="botonDeshabilitado"
          @click="pagoActualizar('actualizar')"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          v-if="roles.includes('Admin') || roles.includes('Agencia')"
          color="primario"
          icon="plus"
          class="my-sm-0 px-3"
          :disabled="botonDeshabilitado"
          @click="mostrarModalPago = true"
        >
          Nuevo pago
        </mdb-btn>
    </div>
    <div  class="mb-page">
      <!-- Filtros -->
      <div
        class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0"
      >
        <mdb-btn
          flat
          dark-waves
          icon="list"
          class="btn-bordeado my-sm-0 py-2 px-3"
          @click="
            buscarPor.estatus = '';
            pagoActualizar();
          "
        >
          Todos
        </mdb-btn>
        <div class="col-auto pr-0">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select-pago"
              v-model="buscarPor.estatus"
              class="custom-select"
              @change="pagoActualizar('estatus')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in EstatusPago"
                :key="`pagoEstatus-${key}`"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select-pago"
              :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
      </div>
      <!-- Tabla -->
      <ul data-table="sticky" class="table">
        <li class="encabezado row align-items-end">
          <div
            class="col-auto fecha pr-0 d-none d-md-block filtrable"
            @click="ordenarColumna('fecha')"
          >
            Fecha / Estatus
            <div
              v-if="orderBy.fecha != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.fecha}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.fecha}"
              />
            </div>
          </div>
          <div class="col">
            <span class="d-md-none">Detalles</span>
            <span
              class="d-none d-md-block filtrable"
              @click="ordenarColumna('monto')"
            >
              Medio / Referencia / Monto
              <div
                v-if="orderBy.monto != null"
                class="sort-iconos animated fadeIn"
              >
                <font-awesome-icon
                  icon="sort-up"
                  :class="{'activo': orderBy.monto}"
                />
                <font-awesome-icon
                  icon="sort-down"
                  :class="{'activo': !orderBy.monto}"
                />
              </div>
            </span>
          </div>
          <div class="col d-none d-xl-block">Observación</div>
          <div class="col col-md-3 d-none d-sm-block">
            Cliente
          </div>
          <div class="col-auto pr-0 acciones">Acción</div>
        </li>
        <li
          v-for="pago in pagos"
          :key="`pagos-${pago.id}`"
          class="contenido row align-items-center"
          @click="mostrarModalPagoDetalles = true; pagoSeleccionado = pago"
        >
          <div class="col-auto fecha pr-0 d-none d-md-block">
            {{ pago.fecha.formatted }}
            <small
              :class="[
                'd-block font-weight-bold pt-1',
                EstatusPago[pago.estatus]
                  ? EstatusPagoIconoColor[pago.estatus]
                  : 'text-muted',
              ]"
            >
              <font-awesome-icon :icon="EstatusPagoIconos[pago.estatus]" />
              {{
                EstatusPago[pago.estatus]
                  ? EstatusPago[pago.estatus]
                  : "Sin especificar"
              }}
            </small>
          </div>
          <div class="col">
            <div class="py-2 d-md-none">
              {{ pago.fecha.formatted }}
              <p class="my-1">
                Estatus:
                <span
                  :class="[
                    'font-weight-bold',
                    EstatusPago[pago.estatus]
                      ? EstatusPagoIconoColor[pago.estatus]
                      : 'text-muted',
                  ]"
                >
                  <font-awesome-icon :icon="EstatusPagoIconos[pago.estatus]" />
                  {{
                    EstatusPago[pago.estatus]
                      ? EstatusPago[pago.estatus]
                      : "Sin especificar"
                  }}
                </span>
              </p>
            </div>
            Medio de pago:
            <span
              :class="{
                'text-muted': !(pago.medio && pago.medio.nombre.length),
              }"
            >
              {{
                pago.medio && pago.medio.nombre.length
                  ? pago.medio.nombre
                  : "Sin especificar"
              }}
            </span>
            <div title="Referencia">Ref. {{ pago.referencia }}</div>
            <div class="font-weight-bold" title="Monto">${{ pago.monto }}</div>
            <div class="py-2 d-xl-none">
              Observación:
              <span
                :class="!(pago.observacion && pago.observacion.length)
                  ? 'text-muted'
                  : 'texto-line-clamp'"
              >
                {{
                  pago.observacion && pago.observacion.length
                    ? pago.observacion
                    : "Sin especificar"
                }}
              </span>
            </div>
            <div class="d-sm-none">
              Cliente: {{ pago.cliente ? pago.cliente.nombre : 'Sin especificar' }}
              <div
                v-if="pago.cliente && pago.cliente.telefonos && pago.cliente.telefonos.length"
              >
                <a
                  target="_blank"
                  :href="`tel:+${pago.cliente.telefonos[0].numero}`"
                  class="d-block text-break"
                  @click.stop
                >
                  +{{ pago.cliente.telefonos[0].numero }}
                </a>
                <div>
                  <a
                    :href="`https://wa.me/${pago.cliente.telefonos[0].numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                    @click.stop
                  >
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                    WA
                  </a>
                  <a
                    :href="`sms:+${pago.cliente.telefonos[0].numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                    @click.stop
                  >
                    <font-awesome-icon icon="sms" />
                    Sms
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              'col d-none d-xl-block',
              { 'text-muted': !(pago.observacion && pago.observacion.length) },
            ]"
          >
            {{
              pago.observacion && pago.observacion.length
                ? pago.observacion
                : "Sin especificar"
            }}
          </div>
          <div class="col col-md-3 d-none d-sm-block">
            {{ pago.cliente ? pago.cliente.nombre : 'Sin especificar' }}
            <div v-if="pago.cliente && pago.cliente.telefonos && pago.cliente.telefonos.length">
              <a
                target="_blank"
                :href="`tel:+${pago.cliente.telefonos[0].numero}`"
                class="d-block text-break"
                @click.stop
              >
                +{{ pago.cliente.telefonos[0].numero }}
              </a>
              <div>
                <a
                  :href="`https://wa.me/${pago.cliente.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                  @click.stop
                >
                  <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  WA
                </a>
                <a
                  :href="`sms:+${pago.cliente.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                  @click.stop
                >
                  <font-awesome-icon icon="sms" />
                  Sms
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-auto pr-0 acciones"
            @click.stop
          >
            <mdb-btn
              v-if="roles.includes('Admin')"
              flat
              dark-waves
              icon="trash"
              :disabled="botonDeshabilitado"
              icon-class="texto-peligro"
              class="m-0 ml-2 ml-md-0 py-3 px-2 btn-bordeadoOnHover"
              title="Eliminar pago"
              @click="
                mostrarModalConfirmarPermiso = true;
                pagoIdEliminar = pago.id;
                obtenerComprobantesEliminar();
              "
            />
            <mdb-btn
              v-if="roles.includes('Admin') || roles.includes('Agencia')"
              flat
              dark-waves
              icon="pen"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Editar pago"
              @click="pagoAEditar(pago)"
            />
            <mdb-btn
              flat
              dark-waves
              icon="file-invoice-dollar"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Ver comprobantes"
              @click="obtenerComprobantes(pago.id)"
            />
          </div>
        </li>
        <li v-if="!(pagos && pagos.length)" class="no-items">
          No hay pagos registrados
        </li>
        <li v-if="$apolloData.queries.pagos.loading">
            <CargandoVista clase-adicional="vc-50px" />
          </li>
      </ul>
    </div>
    <FormularioPago
      :mostrarModalPago="mostrarModalPago"
      :es-editar="esEditar"
      :titulo="esEditar ? 'Datos del pago a editar' : 'Datos del nuevo pago'"
      :pagoAsignar="pagoAsignar && pagoAsignar"
      @crear="pagoCrear($event)"
      @cerrar="
        mostrarModalPago = false;
        botonDeshabilitado = false;
      "
      @editar="esEditar = $event"
      @editarPago="pagoEditar($event)"
      @clienteError="errorCliente"
      @alertaMsg="comprobanteError($event)"
      @alerta="alertaMensaje = $event"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <!-- Comprobantes del Pago -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalComprobante"
      @close="cerrarModalComprobante"
    >
      <mdb-modal-header>
        <mdb-modal-title> Comprobantes de pago </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <h5 class="mb-3">Fotos del comprobante</h5>
        <div v-if="cargandoComprobantes">
          <CargandoVista
            v-if="cargandoComprobantes"
            clase-adicional="vc-50vh"
          />
        </div>
        <div v-else>
          <ul
            v-if="comprobante.fotos && comprobante.fotos.length"
            class="listado-fotos"
          >
            <li
              v-for="(foto, iFoto) in comprobante.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto}`"
                  :alt="`Comprobante del pago: Imagen nro ${iFoto + 1}`"
                  @click="abrirGaleriaEn(iFoto)"
                />
              </div>
              <label
                v-if="roles.includes('Admin')"
                class="boton-eliminar checkbox-grande"
                title="Seleccionar las imágenes del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="fotos-eliminar"
                  v-model="comprobantesEliminar"
                  :value="foto"
                />
                <span class="checkbox" />
              </label>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay fotos para mostrar
          </span>
          <h5 class="my-3">Documentos PDF</h5>
          <ul
            v-if="comprobante.pdf && comprobante.pdf.length"
            class="lista-archivos"
          >
            <li
              v-for="(urlArchivo, pdfIndex) in comprobante.pdf"
              :key="pdfIndex"
              class="archivo-contenedor"
            >
              <a
                :href="`${endpointPublicBackend}${urlArchivo}`"
                target="_blank"
                rel="noopener noreferrer"
                class="archivo"
              >
                <pdf :src="`${endpointPublicBackend}${urlArchivo}`"> </pdf>
              </a>
              <label
                v-if="roles.includes('Admin')"
                class="boton-eliminar checkbox-grande"
                title="Seleccionar archivos del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="pdf-checkboxes"
                  v-model="comprobantesEliminar"
                  :value="urlArchivo"
                />
                <span class="checkbox" />
              </label>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay archivos en fomato pdf para mostrar
          </span>
          <div
            class="row justify-content-center align-items-center my-3"
            v-if="comprobante.pdf.length || comprobante.fotos.length
              && (roles.includes('Admin'))"
          >
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'check-square'"
              :icon-class="[
                { 'texto-terciario': comprobantesEliminar.length },
                { 'fa-spin': botonDeshabilitado },
              ]"
              :disabled="botonDeshabilitado"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="seleccionarTodo"
            >
              {{ textoBotonSeleccionar }}
            </mdb-btn>
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
              :icon-class="[{ 'fa-spin': botonDeshabilitado }]"
              :disabled="botonDeshabilitado || !comprobantesEliminar.length"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="
                mostrarConfirmacionEliminar = true;
                esEliminarComprobantes = true;
              "
            >
              Eliminar comprobantes
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="mostrarConfirmacionEliminar=true"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <ModalPagoDetalles
      :mostrar-modal="mostrarModalPagoDetalles"
      :pago="pagoSeleccionado"
      @cerrar="mostrarModalPagoDetalles = $event; pagoSeleccionado = {};"
    />
    <LightBox
      v-if="galeriaFotosComprobante && galeriaFotosComprobante.length"
      ref="lightbox"
      :media="galeriaFotosComprobante"
      :show-light-box="false"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      :mensaje="msgAlerta"
      :cancelarMensaje="cancelarMensajeAlerta"
      @cerrar="
        mostrarConfirmacionEliminar = $event;
        pagoIdEliminar = '';
        tieneComprobantes = false;
      "
      @eliminar="metodoEliminar"
    />
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";
import ModalPagoDetalles from "@/components/ModalPagoDetalles.vue";
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import FormularioPago from "@/components/FormularioPago.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import mediosGql from "@/graphql/medios.gql";
import pagoCrearGql from "@/graphql/pagoCrear.gql";
import pagoEditarGql from "@/graphql/pagoEditar.gql";
import pagoEliminarGql from "@/graphql/pagoEliminar.gql";
import obtenerFotosGql from "@/graphql/obtenerFotos.gql";
import pagosFilterGql from "@/graphql/pagoFilter.gql";
import fotoEliminarGql from "@/graphql/fotoEliminar.gql";
import gql from "graphql-tag";
import {
  regExpPdf,
  EstatusPago,
  EstatusPagoIconos,
  EstatusPagoIconoColor,
} from "@/constantes/pagos.js";
import { endpointPublicBackend } from "@/constantes/paquetes.js";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import LightBox from "vue-image-lightbox";
import pdf from "vue-pdf";
import { leerRoles } from "@/utils/datosToken.js";
export default {
  name: "Pagos",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    FormularioPago,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    ModalPagoDetalles,
    LightBox,
    ModalConfirmarPermiso,
    pdf,
  },
  data() {
    return {
      pagos: [],
      botonDeshabilitado: false,
      mostrarModalPago: false,
      mostrarModalPagoDetalles: false,
      pagoSeleccionado: {},
      mostrarConfirmacionEliminar: false,
      mostrarModalComprobante: false,
      comprobantesEliminar: [],
      esEditar: false,
      cargandoPagos: false,
      cargandoComprobantes: false,
      tieneComprobantes: false,
      esEliminarComprobantes: false,
      pagoIdComprobante: "",
      buscarPor: { estatus: "" },
      alertaMensaje: { contenido: "" },
      pagoIdEliminar: "",
      comprobante: {
        fotos: [],
        pdf: [],
      },
      galeriaFotosComprobante: [],
      pagoAsignar: {
        medio: "",
        fecha: {},
        monto: "",
        referencia: "",
        estatus: "",
      },
      endpointPublicBackend,
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      // Confirmar permiso
      mostrarModalConfirmarPermiso: false,
      // Ordenar asc desc
      orderBy: {
        fecha: null,
        monto: null,
      },
      orderByActual: [],
      roles:leerRoles(),
      mostrarMas:true,
      pagina:1,
      variablesPagos:{
          offset: 0,
          first: 20,
          orderBy:['fecha_desc', 'referencia_desc']
        }
    };
  },
   mounted(){
    this.onScrollChange()
  },
  computed: {
    cargandoPagosTemplate() {
      return this.$apolloData.queries.pagos.loading || this.cargandoPagos
        ? true
        : false;
    },
    textoBotonSeleccionar() {
      const pdfDisponibles = this.comprobante.pdf.length;
      const fotosDisponibles = this.comprobante.fotos.length;
      const totalComprobantes = pdfDisponibles + fotosDisponibles;
      return this.comprobantesEliminar.length === totalComprobantes
        ? "Deseleccionar todo"
        : "Seleccionar todo";
    },
    msgAlerta() {
      return this.esEliminarComprobantes
        ? `¿Estas seguro en eliminar ${
            this.comprobantesEliminar.length > 1
              ? "los comprobantes seleccionados?"
              : "el comprobante seleccionado?"
          }`
        : "Recibimos la orden de eliminar este pago, es una acción que no puede revertirse";
    },
    cancelarMensajeAlerta() {
      return this.esEliminarComprobantes ? true : false;
    },
  },
  methods: {
    onScrollChange() {
      window.onscroll = () => {
        const finalPagina = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
        if (finalPagina && this.mostrarMas) {
          this.pagosObtenerMas()
        }
      };
    },
    pagosObtenerMas(){
    if(this.botonDeshabilitado) return
      this.botonDeshabilitado = true;
      const pagosActuales = JSON.parse(JSON.stringify(this.pagos))
      const filter = {};
      if(this.buscarPor.estatus !== ''  && this.buscarPor.estatus.length){
        Object.assign(filter,{
          estatus:this.buscarPor.estatus
        })
      }
      this.$apollo.queries.pagos.fetchMore({
        variables: {
          filter,
          offset: this.pagina * this.variablesPagos.first,
          first: this.variablesPagos.first,
          orderBy: this.orderByActual && this.orderByActual.length
            ? this.orderByActual
            : this.variablesPagos.orderBy
        },
        updateQuery: (previusResult, {fetchMoreResult}) => {
          const nuevosPagos = JSON.parse(JSON.stringify(fetchMoreResult.Pago));
          this.mostrarMas = nuevosPagos.length >= this.variablesPagos.first
          const typename = previusResult?.Pago?.length
            ? previusResult?.Pago[0]?.__typename
            : ''
          if(this.mostrarMas){
            this.pagina++;
          }
        if(!nuevosPagos.length){
          this.pagina = 1;
          this.mostrarMas = false;
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Pago:pagosActuales,
          };  
        }
        if(nuevosPagos.length){
          let pagosObtenidos = [...pagosActuales, ...nuevosPagos];
          if (this.buscarPor.estatus) {
            pagosObtenidos = pagosObtenidos.filter(p => p.estatus === this.buscarPor.estatus)
          }
          let pagos = {};
           pagosObtenidos = pagosObtenidos
            .filter(pago => pagos[pago.id] ? false : pagos[pago.id] = true);
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Pago: pagosObtenidos,
          };
        }
        }
      });
    },
    ordenarColumna(columna){
      if(columna == 'fecha'){
        this.orderBy.monto = null;
        this.orderBy.cliente = null;
        this.orderBy.fecha === null
          ? this.orderBy.fecha = false  // se ordena descendente
          : this.orderBy.fecha
            ? this.orderBy.fecha = false   
            : this.orderBy.fecha = true  // se ordena ascendente
        if(this.orderBy.fecha) {
          this.orderByActual = ['fecha_asc']
          return this.pagoActualizar('filtro')
        } else {
          this.orderByActual = ['fecha_desc']
          return this.pagoActualizar('filtro')
        }
      }
       if(columna == 'monto'){
        this.orderBy.cliente = null;
        this.orderBy.fecha = null
        this.orderBy.monto === null
          ? this.orderBy.monto = false  // se ordena descendente
          : this.orderBy.monto
            ? this.orderBy.monto = false   
            : this.orderBy.monto = true  // se ordena ascendente
        if(this.orderBy.monto) {
          this.orderByActual = ['monto_asc']
          return this.pagoActualizar('filtro')
        } else {
          this.orderByActual = ['monto_desc']
          return this.pagoActualizar('filtro')
        }
      }
    },
    abrirGaleriaEn(foto) {
      this.$refs.lightbox.showImage(foto);
    },
    seleccionarTodo() {
      const pdfCantidad = this.comprobante.pdf.length;
      const fotosCantidad = this.comprobante.fotos.length;
      const todosLosElementosSeleccionados =
        this.comprobantesEliminar.length === pdfCantidad + fotosCantidad;
      if (todosLosElementosSeleccionados) {
        this.comprobantesEliminar = [];
        return;
      }
      const pdfAEliminar = this.comprobante.pdf.map((pdf) => pdf);
      const fotosAEliminar = this.comprobante.fotos.map((foto) => foto);
      this.comprobantesEliminar = [...pdfAEliminar, ...fotosAEliminar];
    },
    pagoCrear({ pago, comprobante, medioId, paquetes, compras}) {
      this.botonDeshabilitado = true;
      const { cliente, ...restPago } = pago;
      delete restPago.__typename;
      delete restPago.fecha.__typename;
      delete cliente.__typename;
      const { id: idMedio, nombre: nombreDelMedio, descripcion } = medioId;
      this.paginacionResetear();
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoCrearGql}
          `,
          variables: {
            pago: restPago,
            medioId: idMedio,
            personaId: cliente.id,
            comprobante: { foto: comprobante },
            paquetes,
            compras,
          },
          update: (store, { data: { pagoCrear } }) => {
            const data = store.readQuery({ query: pagosFilterGql, variables:this.variablesPagos });
            const pagoAgregar = {
              ...restPago,
              ...pagoCrear,
              medio: {},
              cliente: {},
              paquetes:[]
            };
            const { nombre, casillero } = cliente;
            pagoAgregar.__typename = "Pago";
            pagoAgregar.cliente.__typename = "Cliente";
            pagoAgregar.cliente.nombre = nombre;
            pagoAgregar.cliente.casillero = casillero;
            pagoAgregar.cliente.telefonos = [];
            pagoAgregar.medio.nombre = nombreDelMedio;
            pagoAgregar.medio.id = idMedio;
            pagoAgregar.medio.descripcion = descripcion;
            pagoAgregar.medio.__typename = "Medio";
            pagoAgregar.fecha.__typename = "_Neo4jDateTime";
            data.Pago.unshift(pagoAgregar)
            store.writeQuery({ query: pagosFilterGql, variables:this.variablesPagos, data});
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoCrear: {
              __typename: "Pago",
              id: -1,
              monto: restPago.monto,
              restante: restPago.restante,
              estatus: restPago.estatus,
              medio: {
                nombre: nombreDelMedio,
                __typename: "Medio",
              },
              paquetes:[],
              referencia: restPago.referencia,
              observacion: restPago.observacion.length
                ? restPago.observacion
                : "",
              fecha: {
                formatted: restPago.fecha.formatted,
                __typename: "_Neo4jDateTime",
              },
              cliente: {
                nombre: cliente.nombre,
                casillero:cliente.casillero,
                telefonos: [],
                __typename: "Cliente",
              },
            },
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.mostrarModalPago = false;
          this.pagoActualizar('filtro')
          this.alertaMensaje = {
            contenido: "Pago agregado correctamente",
            tipo: "correcto",
          };
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error agregando el pago",
            tipo: "error",
          };
        });
    },
    comprobanteError({ contenido, tipo }) {
      if (!contenido || !tipo) return;
      this.alertaMensaje = { contenido, tipo };
    },
    metodoEliminar() {
      this.esEliminarComprobantes
        ? this.eliminarComprobantes()
        : this.pagoEliminar();
    },
    cerrarModalComprobante() {
      this.mostrarModalComprobante = false;
      this.comprobantesEliminar = [];
      this.comprobante.pdf = [];
      this.comprobante.fotos = [];
      this.galeriaFotosComprobante = [];
      this.pagoIdComprobante = "";
      this.esEliminarComprobantes = false;
    },
    eliminarComprobantes() {
      if (!this.comprobantesEliminar.length || !this.pagoIdComprobante) {
        return (this.alertaMensaje = {
          contenido: "Debes seleccionar al menos un comprobante",
          tipo: "advertencia",
        });
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${fotoEliminarGql}
          `,
          variables: {
            grafoId: this.pagoIdComprobante,
            url: this.comprobantesEliminar,
            entidadEliminar:"Pago"
          },
        })
        .then(({ data: { fotoEliminar } }) => {
          switch (fotoEliminar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: `${
                  this.comprobantesEliminar.length > 1
                    ? "Comprobantes eliminados"
                    : "Comprobante eliminado"
                } correctamente`,
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo eliminar los comprobantes",
                tipo: "error",
              };
              break;
            default:
              this.alertaMensaje = {
                contenido: "Ha ocurrido un error eliminando los comprobantes",
                tipo: "error",
              };
              break;
          }
          this.establecerValoresEliminar();
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido:
              "Ha ocurrido un error inesperado eliminando los comprobantes",
            tipo: "error",
          };
          this.establecerValoresEliminar();
        });
    },
    obtenerComprobantesEliminar() {
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId: this.pagoIdEliminar,
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: "network-only",
        })
        .then(({ data: { obtenerFotos } }) => {
          this.tieneComprobantes = obtenerFotos.length > 0 ? true : false;
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error consultando los comprobantes",
            tipo: "error",
          };
        });
    },
    obtenerComprobantes(grafoId) {
      this.cargandoComprobantes = true;
      this.mostrarModalComprobante = true;
      this.pagoIdComprobante = grafoId;
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId,
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: "network-only",
        })
        .then(({ data: { obtenerFotos } }) => {
          const archivosUrl = obtenerFotos.map((archivo) => archivo.url);
          this.comprobante.pdf = archivosUrl.filter((url) =>
            regExpPdf.test(url)
          );
          this.comprobante.fotos = archivosUrl.filter(
            (url) => !regExpPdf.test(url)
          );
          if (this.comprobante.fotos.length) {
            const endpoint = this.endpointPublicBackend;
            this.galeriaFotosComprobante = this.comprobante.fotos.map(
              (foto) => ({
                thumb: `${endpoint}${foto}`,
                src: `${endpoint}${foto}`,
              })
            );
          } else this.galeriaFotosComprobante = [];
          this.cargandoComprobantes = false;
        })
        .catch(() => {
          this.cargandoComprobantes = false;
          this.mostrarModalComprobante = false;
        });
    },
    pagoEliminar() {
      if (this.tieneComprobantes) {
        return (this.alertaMensaje = {
          contenido:
            "Para eliminar el pago debes remover los comprobantes adjuntos",
          tipo: "Advertencia",
        });
      }
      this.botonDeshabilitado = true;
      this.paginacionResetear()
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoEliminarGql}
          `,
          variables: {
            pagoId: this.pagoIdEliminar,
          },
          update: (
            store,
            {
              data: {
                pagoEliminar: { codigo },
              },
            }
          ) => {
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: "Pago eliminado correctamente",
                  tipo: "correcto",
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                  contenido: "No se pudo eliminar el pago",
                  tipo: "error",
                };
                this.establecerValoresEliminar();
                return;
              default:
                this.alertaMensaje = {
                  contenido: "Ocurrió un error inesperado",
                  tipo: "error",
                };
                this.establecerValoresEliminar();
                return;
            }
            const { Pago } = store.readQuery({
              query: gql`
                ${pagosFilterGql}
              `,
              variables:this.variablesPagos,
            });
            const data = { Pago: [] };
            data.Pago = Pago.filter(({ id }) => id !== this.pagoIdEliminar);
            store.writeQuery({
              query: gql`
                ${pagosFilterGql}
              `,
              data,
              variables:this.variablesPagos,
            });
            this.establecerValoresEliminar();
            this.pagoActualizar('filtro')
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .catch(() => {
          this.establecerValoresEliminar();
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error eliminando el pago",
            tipo: "error",
          };
        });
    },
    pagoAEditar(pagoSeleccionado) {
      this.botonDeshabilitado = true;
      this.pagoAsignar = JSON.parse(JSON.stringify(pagoSeleccionado));
      this.esEditar = true;
      this.mostrarModalPago = true;
    },
    pagoEditar({ pago, medioId }) {
      this.botonDeshabilitado = true;
      pago.monto = Number(pago.monto);
      const pagoVariable = JSON.parse(JSON.stringify(pago));
      const { id: idMedio, nombre: nombreDelMedio, descripcion } = medioId;
      this.paginacionResetear();
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoEditarGql}
          `,
          variables: {
            pago: pagoVariable,
            medioId: idMedio,
          },
          update: (store) => {
            const data = store.readQuery({ query: pagosFilterGql, variables:this.variablesPagos });
            data.Pago.filter(({ id }) => id === pagoVariable.id).map(
              (pagoEditar) => {
                if (!pagoEditar.medio) {
                  pagoEditar.medio = {};
                  pagoEditar.medio.__typename = "Medio";
                }
                pagoEditar.monto = pagoVariable.monto;
                pagoEditar.estatus = pagoVariable.estatus;
                pagoEditar.medio.nombre = nombreDelMedio;
                pagoEditar.medio.id = idMedio;
                pagoEditar.medio.descripcion = descripcion;
                pagoEditar.observacion = pagoVariable.observacion?.length
                  ? pagoVariable.observacion
                  : "";
                pagoEditar.referencia = pagoVariable.referencia;
                pagoEditar.fecha.formatted = pagoVariable.fecha.formatted;
              }
            );
            store.writeQuery({
              query: pagosFilterGql,
              variables:this.variablesPagos,
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(
          ({
            data: {
              pagoEditar: { codigo },
            },
          }) => {
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: "Pago actualizado correctamente",
                  tipo: "correcto",
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                  contenido: "No se pudo actualizar el pago",
                  tipo: "error",
                };
                break;
              default:
                this.alertaMensaje = {
                  contenido: "Ocurrió un error inesperado",
                  tipo: "error",
                };
                break;
            }
            this.botonDeshabilitado = false;
            this.limpiarCamposForm();
            this.pagoActualizar('filtro')
            return;
          }
        )
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error editando el pago",
            tipo: "error",
          };
        });
    },
    limpiarCamposForm() {
      this.botonDeshabilitado = false;
      this.pagoIdEliminar = "";
      this.pagoAsignar = { medio: "", fecha: {}, cliente: {} };
      this.comprobante.pdf = [];
      this.comprobante.fotos = [];
      this.galeriaFotosComprobante = [];
      this.pagoIdComprobante = "";
    },
    errorCliente() {
      return (this.alertaMensaje = {
        contenido: "El cliente es requerido para crear el pago",
        tipo: "error",
      });
    },
    paginacionResetear() {
      this.pagina = 1;
      this.mostrarMas = true;
    },
    establecerValoresEliminar() {
      this.botonDeshabilitado = false;
      this.mostrarConfirmacionEliminar = false;
      this.pagoId = "";
      this.comprobante.pdf = [];
      this.comprobante.fotos = [];
      this.comprobantesEliminar = [];
      this.mostrarModalComprobante = false;
      this.galeriaFotosComprobante = [];
      this.pagoIdComprobante = "";
      this.esEliminarComprobantes = false;
      this.tieneComprobantes = false;
    },
    pagoActualizar(estatus) {
      if(this.botonDeshabilitado) return
      this.paginacionResetear()
      this.cargandoPagos = true;
      this.botonDeshabilitado = true;
      let filter = {};
      estatus && this.buscarPor.estatus !== "" && (filter.estatus = this.buscarPor.estatus)
      if(!estatus && !this.buscarPor.estatus.length){
        this.orderByActual = [];
        this.orderBy =  {
        fecha: null,
        monto: null,
      }
      }
      const variablesQuery = {
        filter,
        offset: 0,
        first: this.variablesPagos.first,
        orderBy: this.orderByActual && this.orderByActual.length
          ? this.orderByActual
          : this.variablesPagos.orderBy
      }
      this.$apollo
        .watchQuery({
          query: gql`
            ${pagosFilterGql}
          `,
          update: (data) => data.Pago,
          fetchPolicy: "cache-and-network",
          variables: variablesQuery,
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Pago) {
              this.pagos = JSON.parse(JSON.stringify(data.Pago));
            }
            this.botonDeshabilitado = false;
            this.cargandoPagos = false;
          },
          error: () => {
            this.alertaMensaje = {
              contenido: "Ha ocurrido un error actualizando los pagos",
              tipo: "error",
            };
            this.botonDeshabilitado = false;
            this.cargandoPagos = false;
          },
        });
    },
  },
  apollo: {
    pagos() {
      return {
        query: gql`
          ${pagosFilterGql}
        `,
        update: (data) => data.Pago,
        variables:() =>{
          return this.variablesPagos
        },
        fetchPolicy: "cache-and-network",
      };
    },
    medios() {
      return {
        query: mediosGql,
        update: (data) => data.Medio,
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.col-auto {
  &.fecha {
    width: 126px;
  }
  &.acciones {
    width: 64px;
    @media screen and (min-width: 768px) {
      width: 136px;
    }
  }
}
</style>
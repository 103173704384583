var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mostrarFormulario)?_c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{class:['col-12 col-lg-5',
        {'campo': _vm.validarValores.nombre
          && _vm.validarValores.nombre.length},
        {'valido': _vm.validarValores.nombre == 'valido'},
        {'invalido': _vm.validarValores.nombre == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.cliente.persona.nombre),callback:function ($$v) {_vm.$set(_vm.cliente.persona, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.persona.nombre"}}),(_vm.validarValores.nombre == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el nombre ")]):_vm._e()],1),_c('div',{class:['col-12 col-sm-6 col-lg-4',
        {'campo': _vm.validarValores.identificacion
          && _vm.validarValores.identificacion.length},
        {'valido': _vm.validarValores.identificacion == 'valido'},
        {'invalido': _vm.validarValores.identificacion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Número de ID","outline":""},model:{value:(_vm.cliente.persona.identificacion),callback:function ($$v) {_vm.$set(_vm.cliente.persona, "identificacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.persona.identificacion"}}),(_vm.validarValores.identificacion == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el número de identificación ")]):_vm._e()],1),_c('div',{class:['col-12 col-sm-6 col-lg-3',
        {'campo': _vm.validarValores.genero
          && _vm.validarValores.genero.length},
        {'valido': _vm.validarValores.genero == 'valido'},
        {'invalido': _vm.validarValores.genero == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.cliente.persona.genero),expression:"cliente.persona.genero"}],staticClass:"custom-select",attrs:{"id":"genero-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.cliente.persona, "genero", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.ClienteGeneros),function(value,key){return _c('option',{key:value+'genero',domProps:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('label',{class:_vm.cliente.tipo ? 'label-active' : 'label-inactive',attrs:{"for":"genero-select"}},[_vm._v(" Género ")])]),(_vm.validarValores.genero == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el género ")]):_vm._e()]),(!_vm.esEditar)?[_c('div',{class:['col-12 col-sm-6',
          {'campo': _vm.validarValores.claveTemporal
            && _vm.validarValores.claveTemporal.length},
          {'valido': _vm.validarValores.claveTemporal == 'valido'},
          {'invalido': _vm.validarValores.claveTemporal == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":_vm.mostrarClave ? 'text' : 'password',"minlength":"8","label":"Clave","outline":""},model:{value:(_vm.cliente.persona.claveTemporal),callback:function ($$v) {_vm.$set(_vm.cliente.persona, "claveTemporal", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.persona.claveTemporal"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarClave ? 'eye-slash' : 'eye',"title":_vm.mostrarClave ? 'Ocultar clave' : 'Mostrar clave'},on:{"click":function($event){_vm.mostrarClave = !_vm.mostrarClave}},slot:"append"})],1),(_vm.validarValores.claveTemporal == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" La clave es requerida ")]):_vm._e()],1),_c('div',{class:['col-12 col-sm-6',
          {'campo': _vm.validarValores.confirmarClave
            && _vm.validarValores.confirmarClave.length},
          {'valido': _vm.validarValores.confirmarClave == 'valido'},
          {'invalido': _vm.validarValores.confirmarClave == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":_vm.mostrarClave ? 'text' : 'password',"minlength":"8","label":"Repetir clave","outline":""},model:{value:(_vm.cliente.persona.confirmarClave),callback:function ($$v) {_vm.$set(_vm.cliente.persona, "confirmarClave", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.persona.confirmarClave"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarClave ? 'eye-slash' : 'eye',"title":_vm.mostrarClave ? 'Ocultar clave' : 'Mostrar clave'},on:{"click":function($event){_vm.mostrarClave = !_vm.mostrarClave}},slot:"append"})],1),(_vm.validarValores.confirmarClave == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" La confirmación de la clave es requerida ")]):_vm._e()],1)]:_vm._e(),_c('div',{class:['col-12 col-sm-6 col-lg-4',
        {'campo': _vm.validarValores.agencia
          && _vm.validarValores.agencia.length},
        {'valido': _vm.validarValores.agencia == 'valido'},
        {'invalido': _vm.validarValores.agencia == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.agenciaSeleccionada),expression:"agenciaSeleccionada"}],staticClass:"custom-select",attrs:{"id":"agencia-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.agenciaSeleccionada=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":""},domProps:{"value":{}}},[_vm._v(" Seleccione ")]),_vm._l((_vm.agencias),function(value,key){return _c('option',{key:key+'agencia',domProps:{"value":value}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.cliente.agenciaId && _vm.cliente.agenciaId.length
            ? 'label-active' : 'label-inactive',attrs:{"for":"agencia-select"}},[_vm._v(" Agencia ")])]),(_vm.validarValores.agencia == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona la agencia ")]):_vm._e()]),_c('div',{staticClass:"col-12 col-sm-6 col-lg-4"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Casillero","outline":"","read-only":""},model:{value:(_vm.cliente.persona.casillero),callback:function ($$v) {_vm.$set(_vm.cliente.persona, "casillero", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.persona.casillero"}})],1),_c('div',{staticClass:"col-12 col-lg-4 text-center"},[_c('label',{staticClass:"opciones-checkbox right py-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cliente.persona.credito),expression:"cliente.persona.credito"}],attrs:{"type":"checkbox","name":"checkbox-credito"},domProps:{"checked":Array.isArray(_vm.cliente.persona.credito)?_vm._i(_vm.cliente.persona.credito,null)>-1:(_vm.cliente.persona.credito)},on:{"change":function($event){var $$a=_vm.cliente.persona.credito,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.cliente.persona, "credito", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.cliente.persona, "credito", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.cliente.persona, "credito", $$c)}}}}),_c('span',{staticClass:"icono-checkbox"}),_vm._v(" ¿Crédito admitido? ")])])],2),(!_vm.esEditar)?_c('div',[_c('header',{staticClass:"mt-2 font-weight-bold"},[_vm._v(" Datos de contacto ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
              {'campo': _vm.validarValores.telefono
                && _vm.validarValores.telefono.length},
              {'valido': _vm.validarValores.telefono == 'valido'},
              {'invalido': _vm.validarValores.telefono == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2 plus-addon",attrs:{"id":"input-telefono","type":"tel","label":"Teléfono","placeholder":"Ej: 58426573828","minlength":"7","maxlength":"13","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarTelefono(_vm.telefono)}},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=_vm._n($$v)},expression:"telefono"}},[_c('span',{staticClass:"input-group-text md-addon",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("+")])]),(_vm.validarValores.telefono == 'invalido')?_c('p',{staticClass:"mensaje-invalido prepend"},[_vm._v(" Indica el teléfono ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar teléfono"},on:{"click":function($event){return _vm.validarTelefono(_vm.telefono)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.cliente.persona.telefonos),function(tel,i){return _c('div',{key:'Teléfono'+i,staticClass:"chip delete-btn"},[_vm._v(" +"+_vm._s(tel)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar teléfono"},on:{"click":function($event){return _vm.telefonoRemover(i)}}})],1)})],2),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
              {'campo': _vm.validarValores.email
                && _vm.validarValores.email.length},
              {'valido': _vm.validarValores.email == 'valido'},
              {'invalido': _vm.validarValores.email == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"id":"input-email","type":"email","label":"Email","placeholder":"Ej: ejemplo@mail.com","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarEmail(_vm.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),(_vm.validarValores.email == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el email ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar email"},on:{"click":function($event){return _vm.validarEmail(_vm.email)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.cliente.persona.emails),function(email,i){return _c('div',{key:'email'+i,staticClass:"chip delete-btn"},[_vm._v(" "+_vm._s(email)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar email"},on:{"click":function($event){return _vm.emailRemover(i)}}})],1)})],2)]),_c('header',{staticClass:"mt-2 font-weight-bold"},[_vm._v("Datos de dirección")]),_c('div',{staticClass:"row"},[_c('div',{class:['col-12 col-md-6',
          {'campo': _vm.validarValores.estado
            && _vm.validarValores.estado.length},
          {'valido': _vm.validarValores.estado == 'valido'},
          {'invalido': _vm.validarValores.estado == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.cliente.direccion.estado),expression:"cliente.direccion.estado"}],staticClass:"custom-select",attrs:{"id":"estado-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.cliente.direccion, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.estados),function(value,key){return _c('option',{key:key+'estado',domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.cliente.direccion.estado ? 'label-active' : 'label-inactive',attrs:{"for":"estado-select"}},[_vm._v(" Estado ")])]),(_vm.validarValores.estado == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el estado ")]):_vm._e()]),_c('div',{class:['col-12 col-md-6',
          {'campo': _vm.validarValores.ciudad
            && _vm.validarValores.ciudad.length},
          {'valido': _vm.validarValores.ciudad == 'valido'},
          {'invalido': _vm.validarValores.ciudad == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.cliente.direccion.ciudad),expression:"cliente.direccion.ciudad"}],staticClass:"custom-select",attrs:{"id":"ciudad-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.cliente.direccion, "ciudad", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v(" Seleccione ")]),_vm._l((_vm.ciudades),function(value,key){return _c('option',{key:key+'ciudad',domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.cliente.direccion.ciudad ? 'label-active' : 'label-inactive',attrs:{"for":"ciudad-select"}},[_vm._v(" Ciudad ")])]),(_vm.validarValores.ciudad == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona la ciudad ")]):_vm._e()]),_c('div',{class:['col-12 col-md-6',
          {'campo': _vm.validarValores.direccion
            && _vm.validarValores.direccion.length},
          {'valido': _vm.validarValores.direccion == 'valido'},
          {'invalido': _vm.validarValores.direccion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","label":"Dirección","rows":2,"outline":""},model:{value:(_vm.cliente.direccion.direccion),callback:function ($$v) {_vm.$set(_vm.cliente.direccion, "direccion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.direccion.direccion"}}),(_vm.validarValores.direccion == 'invalido')?_c('p',{staticClass:"mensaje-invalido textarea"},[_vm._v(" Indica la dirección ")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","label":"Observación de envío","rows":2,"outline":""},model:{value:(_vm.cliente.direccion.observacion),callback:function ($$v) {_vm.$set(_vm.cliente.direccion, "observacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.direccion.observacion"}})],1)]),_c('header',{staticClass:"mt-2 font-weight-bold"},[_vm._v("Recibe")]),_c('div',{staticClass:"row"},[_c('div',{class:['col-12 col-md-6',
          {'campo': _vm.validarValores.rnombre
            && _vm.validarValores.rnombre.length},
          {'valido': _vm.validarValores.rnombre == 'valido'},
          {'invalido': _vm.validarValores.rnombre == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.cliente.direccion.recibe.nombre),callback:function ($$v) {_vm.$set(_vm.cliente.direccion.recibe, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.direccion.recibe.nombre"}}),(_vm.validarValores.rnombre == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el nombre ")]):_vm._e()],1),_c('div',{class:['col-12 col-md-6',
          {'campo': _vm.validarValores.ridentificacion
            && _vm.validarValores.ridentificacion.length},
          {'valido': _vm.validarValores.ridentificacion == 'valido'},
          {'invalido': _vm.validarValores.ridentificacion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Número de ID","outline":""},model:{value:(_vm.cliente.direccion.recibe.identificacion),callback:function ($$v) {_vm.$set(_vm.cliente.direccion.recibe, "identificacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"cliente.direccion.recibe.identificacion"}}),(_vm.validarValores.ridentificacion == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica la identificación ")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
              {'campo': _vm.validarValores.rtelefono
                && _vm.validarValores.rtelefono.length},
              {'valido': _vm.validarValores.rtelefono == 'valido'},
              {'invalido': _vm.validarValores.rtelefono == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2 plus-addon",attrs:{"id":"input-rtelefono","type":"tel","label":"Teléfono","placeholder":"Ej: 58426573828","minlength":"7","maxlength":"13","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarTelefono(_vm.rtelefono, true)}},model:{value:(_vm.rtelefono),callback:function ($$v) {_vm.rtelefono=_vm._n($$v)},expression:"rtelefono"}},[_c('span',{staticClass:"input-group-text md-addon",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("+")])]),(_vm.validarValores.rtelefono == 'invalido')?_c('p',{staticClass:"mensaje-invalido prepend"},[_vm._v(" Indica el teléfono ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar teléfono"},on:{"click":function($event){return _vm.validarTelefono(_vm.rtelefono, true)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.cliente.direccion.recibe.telefonos),function(tel,i){return _c('div',{key:'Rteléfono'+i,staticClass:"chip delete-btn"},[_vm._v(" +"+_vm._s(tel)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar teléfono"},on:{"click":function($event){return _vm.rtelefonoRemover(i)}}})],1)})],2),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{class:['col pr-1 pr-sm-3',
              {'campo': _vm.validarValores.remail
                && _vm.validarValores.remail.length},
              {'valido': _vm.validarValores.remail == 'valido'},
              {'invalido': _vm.validarValores.remail == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"id":"input-remail","type":"email","label":"Email","placeholder":"Ej: ejemplo@mail.com","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarEmail(_vm.remail, true)}},model:{value:(_vm.remail),callback:function ($$v) {_vm.remail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"remail"}}),(_vm.validarValores.remail == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el email ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mt-campo-btn mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar email"},on:{"click":function($event){return _vm.validarEmail(_vm.remail, true)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.cliente.direccion.recibe.emails),function(email,i){return _c('div',{key:'Remail'+i,staticClass:"chip delete-btn"},[_vm._v(" "+_vm._s(email)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar email"},on:{"click":function($event){return _vm.remailRemover(i)}}})],1)})],2)])]):_vm._e(),_c('div',{staticClass:"row justify-content-around"},[_c('mdb-btn',{staticClass:"col-auto btn-bordeado mt-3 px-3",attrs:{"flat":"","dark-waves":"","icon":"times"},on:{"click":function($event){return _vm.$emit('cerrar', false)}}},[_vm._v(" Cancelar ")]),_c('mdb-btn',{staticClass:"col-auto mt-3 px-3",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.validarCliente}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
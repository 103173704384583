<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false);"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ titulo }} producto
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="row">
        <div
          :class="['col-12 col-lg-5',
            {campo: validarValores.nombre && validarValores.nombre.length},
            {valido: validarValores.nombre === 'valido'},
            {invalido: validarValores.nombre === 'invalido'}
          ]"
        >
          <mdb-input
            v-model.trim="producto.nombre"
            label="Nombre"
            class="my-2"
            outline
          />
        </div>
        <div
          :class="['col-6 col-lg-3',
            {campo: validarValores.cantidad && validarValores.cantidad.length},
            {valido: validarValores.cantidad === 'valido'},
            {invalido: validarValores.cantidad === 'invalido'}
          ]"
        >
          <mdb-input
            v-model.number="producto.cantidad"
            type="number"
            label="Cantidad"
            class="my-2"
            :min="1"
            outline
          />
        </div>
        <div
          :class="['col-6 col-lg-4',
            {campo: validarValores.precio && validarValores.precio.length},
            {valido: validarValores.precio === 'valido'},
            {invalido: validarValores.precio === 'invalido'}
          ]"
        >
          <mdb-input
            v-model.number="producto.precio"
            type="number"
            :min="1"
            label="Precio"
            class="my-2"
            outline
          >
            <span
              class="input-group-text md-addon user-select-none"
              slot="append"
              title="Dólares"
            >
              $
            </span>
          </mdb-input>
        </div>
        <div
          :class="[
            'col-12 col-lg-9',
            { campo: validarValores.enlace && validarValores.enlace.length },
            { valido: validarValores.enlace === 'valido' },
            { invalido: validarValores.enlace === 'invalido' },
          ]"
        >
          <mdb-input
            v-model.trim="producto.enlace"
            type="url"
            label="Enlace"
            class="my-2"
            outline
            pattern="https://.*"
            @blur="cargarImagen"
          />
          <p
            v-if="validarValores.enlace == 'invalido'"
            class="mensaje-invalido"
          >
            Indica el enlace web del producto
          </p>
        </div>
        <div class="col-12 col-lg-3 text-center">
          <CargandoVista v-if="cargandoMiniatura" clase-adicional="vc-50px" />
          <div
            v-else-if="!cargandoMiniatura && producto.miniatura !== undefined && !producto.miniatura.length"
            class="contenedor-miniatura"
            @click="cargarImagen"
          >
            <font-awesome-icon
              icon="image"
              class="icono-miniatura"
            />
            <small class="text-muted">
              Haz clic aquí para cargar la miniatura
            </small>
          </div>
          <img
            v-else
            :src="producto.miniatura"
            alt="Miniatura del Producto"
            width="50"
            height="50"
            class="cursor-pointer"
            title="Haz click aquí para actualizar la miniatura"
            @click="cargarImagen"
          />
        </div>
        <div
          :class="[
            'col-12 col-lg-auto',
              { campo: validarValores.estatus && validarValores.estatus.length },
              { valido: validarValores.estatus == 'valido' },
              { invalido: validarValores.estatus == 'invalido' },
          ]"
        >
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select-form"
              v-model="producto.estatus"
              class="custom-select"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in OrdenEstatus"
                :key="`producto-${key}`"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select-form"
              :class="producto.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
          <p
            v-if="validarValores.estatus == 'invalido'"
            class="mensaje-invalido"
          >
            Selecciona el estatus
          </p>
        </div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-center">
      <mdb-btn
        color="primario"
        :icon="botonDeshabilitado ? 'circle-notch' : btnConfirmar.icono"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        :disabled="botonDeshabilitado"
        @click="validarProducto()"
      >
        {{ btnConfirmar.texto }}
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import CargandoVista from "@/components/CargandoVista.vue";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import { capitalizarPrimeraLetra, validarURL } from "@/funciones/funciones.js";
import ordenMiniaturaGql from "@/graphql/ordenObtenerMiniatura.gql";
import ordenProductoEditarGql from "@/graphql/ordenProductoEditar.gql";
import ordenProductoCrearGql from "@/graphql/ordenProductoCrear.gql";
import ordenCompraGql from "@/graphql/ordenCompra.gql";


import {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
  } from 'mdbvue';
export default {
  name: 'FormularioOrdenProducto',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
    CargandoVista,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    productoEditar: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      OrdenEstatus,
      botonDeshabilitado: false,
      btnConfirmar: {
        icono: 'check',
        texto: 'Agregar',
      },
      cargandoMiniatura: false,
      producto: {
        nombre: '',
        cantidad: '',
        precio: '',
        enlace: '',
        estatus: '',
        miniatura: '',
      },
      titulo: 'Agregar',
      validarValores: {}
    }
  },
  watch: {
    mostrarModal() {
      // Si es editar
      if (this.mostrarModal && (Object.keys(this.productoEditar)).length) {
        Object.assign(this.producto, JSON.parse(JSON.stringify(this.productoEditar)));
        delete this.producto.paquetes;
        delete this.producto.__typename;
        this.titulo = 'Editar';
        this.btnConfirmar = {
          icono: 'sync',
          texto: 'Actualizar',
        };
      } else { // Si es crear
        this.btnConfirmar = {
          icono: 'check',
          texto: 'Agregar',
        },
        this.producto = {
          nombre: '',
          cantidad: '',
          precio: '',
          enlace: '',
          estatus: '',
          miniatura: '',
        }
        this.titulo = 'Agregar';
        this.validarValores = {}
      }
    }
  },
  methods: {
    validarProducto() {
      this.botonDeshabilitado = true;

      this.validarValores = {
        nombre: this.producto.nombre.length ? 'valido' : 'invalido',
        cantidad: this.producto.cantidad ? 'valido' : 'invalido',
        precio: this.producto.precio ? 'valido' : 'invalido',
        // enlace: validarURL(this.producto.enlace),
        estatus: this.producto.estatus ? 'valido' : 'invalido',
      };

      if (Object.values(this.validarValores).includes("invalido")) {
        this.$emit("alerta-mensaje", {
          contenido: "Todos los campos son requeridos",
          tipo: "error",
        });
        return this.botonDeshabilitado = false;
      }

      // Capitaliza la primera letra del nombre
      this.producto.nombre = capitalizarPrimeraLetra(this.producto.nombre);

      // Si se edita un producto, se actualiza
      if (Object.keys(this.productoEditar).length) {
        // actualizarProducto
        this.ordenProductoEditar();
        // Actualización correcta
      } else {
        // agregarProducto
        this.ordenProductoCrear();
        // Producto agregado correctamente
      }
    },
    ordenProductoCrear() {
      const producto = JSON.parse(JSON.stringify(this.producto))
      // const producto = {...this.producto}
      this.$apollo
        .mutate({
          mutation: ordenProductoCrearGql,
          variables: {
            ordenProducto: producto,
            ordenId: this.$route.params.id
          },
          update: (store, {data: { ordenProductoCrear }}) => {
            const data = store.readQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
            });
            producto.id = ordenProductoCrear.id;
            data.Orden[0].productos.push({
              ...producto,
              paquetes: [],
              __typename: "OrdenProducto",
              })
            store.writeQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenProductoCrear: {
              __typename: "OrdenProducto",
              id: -1,
              ...producto,
              paquetes: [],
            },
          },
        })
        .then(({ data: { ordenProductoCrear } }) => {
          if (ordenProductoCrear.id.length) {
            this.$emit("alerta-mensaje", {
              contenido: "Producto creado correctamente",
              tipo: "correcto",
            });
            this.$emit('cerrar', false);
          } else {
            this.$emit("alerta-mensaje", {
              contenido: "No se pudo crear el producto",
              tipo: "error",
            });
          }
          return this.botonDeshabilitado = false;
        })
        .catch((err) => {
          this.$emit("alerta-mensaje", {
            contenido: "Ocurrió un error creando el producto"+err,
            tipo: "error",
          });
          return this.botonDeshabilitado = false;
        });
    },
    ordenProductoEditar() {
      const producto = JSON.parse(JSON.stringify(this.producto))
      this.$apollo
        .mutate({
          mutation: ordenProductoEditarGql,
          variables: {
            ordenProducto: producto,
            orden: {id: this.$route.params.id},
          },
          update: (store) => {
            const data = store.readQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
            });
            data.Orden[0].productos = data.Orden[0].productos
              ? data.Orden[0].productos.map((p) => {
                if (p.id === producto.id) {
                  p = {
                    ...producto,
                    __typename: 'OrdenProducto',
                    paquetes: []
                  }
                }
                return p
              })
              : [];
            store.writeQuery({
              query: ordenCompraGql,
              variables: {
                id: this.$route.params.id,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenProductoEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({ data: { ordenProductoEditar } }) => {
          switch (ordenProductoEditar.codigo) {
            case "Correcto":
              this.$emit("alerta-mensaje", {
                contenido: "Producto editado correctamente",
                tipo: "correcto",
              });
              this.$emit("alerta-mensaje", {
                contenido: "Producto editado correctamente",
                tipo: "correcto",
              });
              this.$emit('cerrar', false);
              break;
            case "Fallido":
              
              this.$emit("alerta-mensaje", {
                contenido: "No se pudo editar el producto",
                tipo: "error",
              });
              break;
            default:
              
              this.$emit("alerta-mensaje", {
                contenido: "Ocurrió un error editando el producto",
                tipo: "error",
              });
              break;
          }
          return this.botonDeshabilitado = false;
        })
        .catch((err) => {
          this.$emit("alerta-mensaje", {
            contenido: "Ocurrió un error editando el producto"+err,
            tipo: "error",
          });
          return this.botonDeshabilitado = false;
        });
    },
    cargarImagen() {
      if (
        !this.producto.enlace.length ||
        this.producto.enlace.length <= 5 ||
        this.producto.enlace === ""
      ) {
        return this.$emit("alerta-mensaje", {
          contenido: "Debes completar el campo del enlace",
          tipo: "advertencia",
        });
      }else if(!validarURL(this.producto.enlace)){
        return this.$emit("alerta-mensaje", {
          contenido: "Enlace inválido",
          tipo: "advertencia",
        });
      }
      this.cargandoMiniatura = true;
      this.botonDeshabilitado = true;
      this.$apollo
        .watchQuery({
          query: ordenMiniaturaGql,
          variables: {
            enlace: this.producto.enlace,
          },
          update: (data) => data.ordenObtenerMiniatura,
          fetchPolicy: "cache-first",
        })
        .subscribe({
          next: ({ data, loading  }) => {
            if (data?.ordenObtenerMiniatura?.miniatura) {
              this.producto.miniatura = data.ordenObtenerMiniatura.miniatura;
              this.botonDeshabilitado = loading;
              this.cargandoMiniatura = loading;
              return;
            }
            this.producto.miniatura = "";
            this.botonDeshabilitado = false;
            this.cargandoMiniatura = false;
            this.$emit("alerta-mensaje", {
              contenido: "No se pudo obtener la miniatura",
              tipo: "advertencia",
            });
          },
          error: () => {
            this.$emit("alerta-mensaje", {
              contenido:
                "Ha ocurrido un error obteniendo la miniatura, intenta nuevamente",
              tipo: "error",
            });
            this.cargandoMiniatura = false;
            this.botonDeshabilitado = false;
          },
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.campo {margin-bottom: 1rem;}
.campo.invalido > .mensaje-invalido.prepend {left: 47px;}

.contenedor-miniatura {
  text-align: center;

  @media screen and (min-width: 992px) {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    text-align: left;
  }

  .icono-miniatura {
    color: $gris-thead-borde;
    cursor: pointer;
    height: 50px;
    width: 50px;
  }
  small {
    cursor: pointer;
    display: block;
    font-style: italic;
    user-select: none;

    @media screen and (min-width: 992px){padding-left: .5rem;}
  }
}
</style>
/**
 * leerPayload devuelve el payload del token en json
 * @return {Object} payload en JSON
 */
function leerPayload() {
  const token = localStorage.getItem('apollo-token');
  const payload = token ? token.split('.')[1] : null;
  return payload ? JSON.parse(atob(payload)) : {};
}

/**
 * leerPayload devuelve el id del token en json
 * @return {String} el identificador de la persona loggueada
 */
function leerPersonaId() {
  return leerPayload().id;
}

/**
 * leerPayload devuelve el nombre del token en json
 * @return {String} el nombre completo de la persona loggueada
 */
function leerPersonaNombre() {
  return leerPayload().nombre;
}

/**
 * leerPayload devuelve los roles del token en json
 * @return {String} el rol de la persona loggueada
 */
function leerRoles() {
  return leerPayload().roles;
}

/**
 * leerPayload devuelve el tiempo de expiración del token en json
 * @return {String} el fecha de expiracion de la persona loggueada
 */
function leerExpiracion() {
  return leerPayload().exp;
}
/**
 * leerPayload devuelve la empresa del usuario que ha hecho login
 * @return {String} con la empresa que accedió
 */
function leerEmpresa() {
  return leerPayload().empresa;
}
/**
 * leerAgencias devuelve arreglo id de las agencias
 * @return {Array} de id de las agencia que tiene acceso
 */
function leerAgencias(){
  return leerPayload().agencias;
}

export {
  leerPersonaId,
  leerPersonaNombre,
  leerRoles,
  leerExpiracion,
  leerEmpresa,
  leerAgencias
};

<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false);
      limpiarCampos();"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ tituloModal }}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row justify-content-around" @submit.prevent>
        <div
          :class="['col-12',
            {'col-md-6': !esEditar},
            {'campo': validarValores.nombre
              && validarValores.nombre.length},
            {'valido': validarValores.nombre == 'valido'},
            {'invalido': validarValores.nombre == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="agencia.agencia.nombre"
            type="text"
            label="Nombre"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.nombre == 'invalido'"
            class="mensaje-invalido"
          >
            Indica el nombre
          </p>
        </div>
        <div
          :class="['col-12',
            {'col-md-6': !esEditar},
            {'campo': validarValores.codigo
              && validarValores.codigo.length},
            {'valido': validarValores.codigo == 'valido'},
            {'invalido': validarValores.codigo == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="agencia.agencia.codigo"
            label="Código de registro"
            minlength="2"
            maxlength="10"
            class="my-2"
            outline
            placeholder="Ej: hrbintel"
            @blur="agencia.agencia.codigo ? agencia.agencia.codigo = agencia.agencia.codigo.toLowerCase() : ''"
          />
          <p
            v-if="validarValores.codigo == 'invalido'"
            class="mensaje-invalido"
          >
            Indica el código de registro de tu agencia
          </p>
        </div>
        <div
          :class="['col-12',
            {'col-md-6': !esEditar},
            {'campo': validarValores.prefijo
              && validarValores.prefijo.length},
            {'valido': validarValores.prefijo == 'valido'},
            {'invalido': validarValores.prefijo == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="agencia.agencia.prefijo"
            label="Prefijo del casillero"
            minlength="2"
            maxlength="4"
            class="my-2"
            outline
            placeholder="Ej: MYA"
            @blur="agencia.agencia.prefijo ? agencia.agencia.prefijo = agencia.agencia.prefijo.toUpperCase() : ''"
          />
          <p
            v-if="validarValores.prefijo == 'invalido'"
            class="mensaje-invalido"
          >
            Indica el prefijo de los casilleros de tu agencia
          </p>
        </div>
        <template v-if="!esEditar">
          <div class="col-12 col-md-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="agencia.empresaId"
                id="empresa-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">Otra</option>
                  <option
                      v-for="(value, key) in empresas"
                      :key="key+'empresas'"
                      :value="value.id"
                    >
                    {{ value.nombre }}
                  </option>
              </select>
              <label
                for="empresa-select"
                :class="agencia.empresaId ? 'label-active' : 'label-inactive'"
              >
                Empresa
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="row align-items-start">
              <div
                :class="['col pr-1 pr-sm-3',
                  {'campo': validarValores.telefono
                    && validarValores.telefono.length},
                  {'valido': validarValores.telefono == 'valido'},
                  {'invalido': validarValores.telefono == 'invalido'},
                ]"
              >
                <mdb-input
                  v-model.number="telefono"
                  type="tel"
                  label="Teléfono"
                  placeholder="Ej: 58426573828"
                  minlength="7"
                  maxlength="13"
                  class="my-2 plus-addon"
                  outline
                  @keyup.enter.native="validarTelefono(telefono)"
                >
                  <span class="input-group-text md-addon" slot="prepend">+</span>
                </mdb-input>
                <p
                  v-if="validarValores.telefono == 'invalido'"
                  class="mensaje-invalido prepend"
                >
                  Indica el teléfono
                </p>
              </div>
              <mdb-btn
                color="exitoso"
                icon="plus"
                size="sm"
                class="col-auto mt-campo-btn mr-3 px-2"
                title="Agregar teléfono"
                @click="validarTelefono(telefono)"
              >
                Añadir
              </mdb-btn>
            </div>
            <div
              v-for="(tel, i) in agencia.telefonos"
              :key="'Teléfono'+i"
              class="chip delete-btn"
            >
              +{{tel}}
              <mdb-btn
                class="m-0 ml-2 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar teléfono"
                @click="telefonoRemover(i)"
              />
            </div>
          </div>
          <header class="col-12 mt-2 font-weight-bold user-select-none">
            Datos de dirección
          </header>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.estado
                && validarValores.estado.length},
              {'valido': validarValores.estado == 'valido'},
              {'invalido': validarValores.estado == 'invalido'},
            ]"
          >
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="agencia.direccion.estado"
                id="estado-select"
                class="custom-select"
              >
              <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in estados"
                  :key="key"
                  :value="value.nombre"
                >
                {{ value.nombre }}
                </option>
              </select>
              <label
                for="estado-select"
                :class="agencia.direccion.estado ? 'label-active' : 'label-inactive'"
              >
                Estado
              </label>
            </div>
            <p
              v-if="validarValores.estado == 'invalido'"
              class="mensaje-invalido"
            >
              Selecciona el estado
            </p>
          </div>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.ciudad
                && validarValores.ciudad.length},
              {'valido': validarValores.ciudad == 'valido'},
              {'invalido': validarValores.ciudad == 'invalido'},
            ]"
          >
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="agencia.direccion.ciudad"
                id="ciudad-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in ciudades"
                  :key="key"
                  :value="value.nombre"
                >
                {{ value.nombre }}
                </option>
              </select>
              <label
                for="ciudad-select"
                :class="agencia.direccion.ciudad ? 'label-active' : 'label-inactive'"
              >
                Ciudad
              </label>
            </div>
            <p
              v-if="validarValores.ciudad == 'invalido'"
              class="mensaje-invalido"
            >
              Selecciona la ciudad
            </p>
          </div>
          <div
            :class="['col-12',
              {'campo': validarValores.direccion
                && validarValores.direccion.length},
              {'valido': validarValores.direccion == 'valido'},
              {'invalido': validarValores.direccion == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="agencia.direccion.direccion"
              type="textarea"
              :rows="2"
              label="Dirección"
              class="my-2"
              outline
            />
            <p
              v-if="validarValores.direccion == 'invalido'"
              class="mensaje-invalido textarea"
            >
              Indica la dirección
            </p>
          </div>
          <header class="col-12 mt-2 font-weight-bold user-select-none">
            Políticas de la agencia
          </header>
        </template>
        <div class="col-12">
          <mdb-input
            v-model.trim="agencia.agencia.enlace"
            type="url"
            label="Enlace a las políticas de la agencia"
            class="my-2"
            outline
            placeholder="Ej: http://www.hrbintel.com/ve/politicas"
          />
        </div>
        <mdb-btn
          flat
          dark-waves
          icon="times"
          class="col-auto btn-bordeado mt-3 px-3"
          @click="$emit('cerrar', false)"
        >
          Cancelar
        </mdb-btn>
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          class="col-auto mt-3 px-3"
          :disabled="botonDeshabilitado"
          @click="validarAgencia"
        >
          {{ botonPrincipal.texto }}
        </mdb-btn>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import gql from "graphql-tag";
import agenciaCrearGql from "@/graphql/agenciaCrear.gql";
import agenciaEditarGql from "@/graphql/agenciaEditar.gql";
import agenciasGql from "@/graphql/agencias.gql";
import estadosGql from "@/graphql/estados.gql";
import ciudadesGql from "@/graphql/ciudades.gql";
import existeAgenciaPorPrefijoCodigoGql from "@/graphql/existeAgenciaPorPrefijoCodigo.gql";
import empresasGql from "@/graphql/empresas.gql";
import { ClienteGeneros } from "@/constantes/clientes.js"
import {
  capitalizar,
  eliminarVacios,
  validarTel,
  evitarElementosRepetidos,
} from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";

export default {
  name:'NuevoCliente',
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    nombresDeLasAgencias: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    datosAgencia: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
  },
  data(){
    return {
      agenciaCodigoSinModificar: '',
      agenciaPrefijoSinModificar: '',
      agenciaNombreSinModificar: '',
      botonDeshabilitado: false,
      estadoSeleccionado: '',
      telefono: '',
      email: '',
      rtelefono: '',
      remail: '',
      ClienteGeneros,
      validarValores: {},
      paises: [],
      estados: [],
      ciudades: [],
      agenciaId: '',
      agencia: {
        telefonos: [],
        agencia: {
          nombre: '',
        },
        direccion: {
          direccion: '',
            ciudad: '',
            estado: '',
        },
        empresaId: '',
      },
    }
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
        if(this.esEditar){
          this.agenciaAsignarDatosAnteriores();
        }
      }
    },
    datosAgencia: function() {
      if(this.datosAgencia && Object.entries(this.datosAgencia).length) {
        this.agenciaAsignarDatosAnteriores();
      }
    }
  },
  methods: {
    agenciaAsignarDatosAnteriores() {
      // Aplicar formato a la agencia a enviar
      const agencia = JSON.parse(JSON.stringify(this.datosAgencia));
      const agenciaConFormato = JSON.parse(JSON.stringify(this.agencia));
      this.agenciaId = this.datosAgencia.id;
      this.agenciaNombreSinModificar = agencia.nombre;
      this.agenciaPrefijoSinModificar = agencia.prefijo;
      this.agenciaCodigoSinModificar = agencia.codigo;

      // Dar formato a los teléfonos y emails
      agenciaConFormato.agencia = {
        nombre: agencia.nombre,
        enlace: agencia.enlace,
        codigo: agencia.codigo,
        prefijo: agencia.prefijo,
      };

      Object.assign(this.agencia, agenciaConFormato);
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    telefonoRemover(index){
      this.agencia.telefonos.splice(index, 1)
    },
    validarTelefono(telefono, skip){
      if(!telefono || !validarTel(telefono)) {
        this.alertaMensaje('Teléfono no válido',
          'error')
        return false
      } else {
        if(skip) {
          return true
        }
        evitarElementosRepetidos(`${telefono}`, this.agencia.telefonos)
          ? this.agencia.telefonos.push(`${telefono}`)
          : this.alertaMensaje(
              "Este teléfono ya se encuentra agregado",
              "advertencia"
            );
        return this.telefono = '';
      }
    },
    async validarAgencia() {
      this.botonDeshabilitado = true;
      this.validarValores = {
        // Datos de la agencia
        nombre: this.agencia.agencia.nombre ? "valido" : "invalido",
        codigo: this.agencia.agencia.codigo && this.agencia.agencia.codigo.length > 1 && this.agencia.agencia.codigo.length < 11 ? "valido" : "invalido",
        prefijo: this.agencia.agencia.prefijo && this.agencia.agencia.prefijo.length > 1 && this.agencia.agencia.prefijo.length < 11 ? "valido" : "invalido",
      };

      if(!this.esEditar){
        Object.assign(this.validarValores, {
          telefono: parseFloat(this.telefono)
            || this.agencia.telefonos.length ? "valido" : "invalido",
        })
      }

      if(this.telefono){
        if(!this.validarTelefono(this.telefono, true)){
          this.botonDeshabilitado = false;
          return Object.assign(this.validarValores, {
            telefono: "invalido",
          })
        }
        if(evitarElementosRepetidos(`${this.telefono}`, this.agencia.telefonos)){
          this.agencia.telefonos.push(`${this.telefono}`)
        } else {
          this.botonDeshabilitado = false;
          Object.assign(this.validarValores, {
            telefono: "invalido",
          })
          return this.alertaMensaje(
            "Este teléfono ya se encuentra agregado",
            "advertencia"
          );
        }
        this.telefono = "";
      }

      // Validar qué el código y el prefijo no exista (Preguntar si estos datos se podran editar)
      if(this.agencia.direccion && (this.agencia.direccion.estado || this.agencia.direccion.ciudad
        || this.agencia.direccion.direccion)){
        Object.assign(this.validarValores, {
          estado: this.agencia.direccion.estado ? "valido" : "invalido",
          ciudad: this.agencia.direccion.ciudad ? "valido" : "invalido",
          direccion: this.agencia.direccion.direccion ? "valido" : "invalido",
        })
      }
       // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje('Llena los campos requeridos', 'error');
        this.botonDeshabilitado = false;
        return;
      }

      if((this.agencia.agencia.codigo && this.agencia.agencia.prefijo) && !this.esEditar) {
        // Valida que el código o el prefijo de la agencia no se repita
        const resultado = await this.validarCodigoYPrefijo(this.agencia.agencia.codigo, this.agencia.agencia.prefijo)
        if(!resultado) {
          return this.botonDeshabilitado = false;
        }
      }

      if(this.agencia.agencia.enlace && !this.agencia.agencia.enlace.includes("http")){
        return this.alertaMensaje('Enlace inválido. Debe ser http o https','advertencia')
      }

      // Aplica formato Capitalize al nombre de la agencia
      this.agencia.agencia.nombre = capitalizar(this.agencia.agencia.nombre);

      const agencia = JSON.parse(JSON.stringify(this.agencia));
      this.estadoSeleccionado = agencia.direccion.estado
      delete agencia.direccion.estado

      // Eliminar campos vacios
      const agenciaSinCamposVacios = eliminarVacios(agencia)
      if(agenciaSinCamposVacios.direccion && !Object.entries(agenciaSinCamposVacios.direccion).length){
        delete agenciaSinCamposVacios.direccion
      }

      if(this.esEditar){
        // Se cambió el código o el prefijo
        if(agenciaSinCamposVacios.agencia.codigo !== this.agenciaCodigoSinModificar || agenciaSinCamposVacios.agencia.prefijo !== this.agenciaPrefijoSinModificar){
          // Valida que el código o el prefijo de la agencia no se repita
          const resultado = await this.validarCodigoYPrefijo(agenciaSinCamposVacios.agencia.codigo !== this.agenciaCodigoSinModificar ? this.agencia.agencia.codigo : '', agenciaSinCamposVacios.agencia.prefijo !== this.agenciaPrefijoSinModificar ? this.agencia.agencia.prefijo : '')
          if(!resultado) return this.botonDeshabilitado = false;
        }
        if(agenciaSinCamposVacios.agencia.nombre !== this.agenciaNombreSinModificar && Object.values(this.nombresDeLasAgencias).includes(agenciaSinCamposVacios.agencia.nombre)){
        this.botonDeshabilitado = false;
        return this.alertaMensaje("Esta agencia ya existe, verifica tus datos","advertencia")
      }
        return this.agenciaEditar(agenciaSinCamposVacios)
      }
      if(Object.values(this.nombresDeLasAgencias).includes(agenciaSinCamposVacios.agencia.nombre)){
        this.botonDeshabilitado = false;
        return this.alertaMensaje("Agencia existente. Por favor verifica tus datos",
              "advertencia")
      }
      this.agenciaCrear(agenciaSinCamposVacios);
    },
    async validarCodigoYPrefijo(codigoA, prefijoA) {
      this.botonDeshabilitado = true;
      try {
        // Retorna true si se encuentra ya registrado anteriormente el codigo o el prefijo
        const res = await this.$apollo
          .query({
              query: gql`
                ${existeAgenciaPorPrefijoCodigoGql}
              `,
              variables: {
                codigo: codigoA,
                prefijo: prefijoA,
              },
              update: (data) => data.existeAgenciaPorPrefijoCodigo,
              fetchPolicy: "no-cache",
            })
          const codigo = res.data.existeAgenciaPorPrefijoCodigo.codigo;
          this.botonDeshabilitado = false;
          switch (codigo) {
            case 'CodigoRegistrado':
                this.alertaMensaje('El código especificado ya se encuentra asignado a otra agencia.'+
                  ' Por favor comprueba tus datos', 'advertencia');
                Object.assign(this.validarValores, {
                  codigo: "invalido",
                })
                return false
            case 'PrefijoRegistrado':
                this.alertaMensaje('El prefijo especificado ya se encuentra asignado a otra agencia.'+
                  ' Por favor comprueba tus datos', 'advertencia');
                Object.assign(this.validarValores, {
                  prefijo: "invalido",
                })
                return false
            case 'DatosRegistrados':
                  this.alertaMensaje('El prefijo y código ya se encuentra asignado a otra agencia.' + ' Por favor comprueba tus datos',
                        'advertencia');
                Object.assign(this.validarValores, {
                  prefijo: "invalido",
                  codigo: "invalido",
                })
                  return false
            case 'DatosNoEnviados':
                  this.alertaMensaje('El prefijo y código son requeridos',
                        'advertencia');
                  Object.assign(this.validarValores, {
                  prefijo: "invalido",
                  codigo: "invalido",
                })
                  return false
            case 'Disponible':
              return true
            default:
              this.alertaMensaje('Ha ocurrido un error validando el prefijo y el código de la agencia. Por favor intenta de nuevo',
                'error')
              return false
          }
      } catch {
          this.botonDeshabilitado = false;
          this.alertaMensaje('Ha ocurrido un error validando los datos de prefijo y código. Por favor intenta de nuevo',
            'error')
          return false
      }
    },
    obtenerAgenciaCreada(id) {
      this.botonDeshabilitado = true;
      this.$apollo
        .query({
            query: gql`
              ${agenciasGql}
            `,
            update: (data) => data.Agencia,
            fetchPolicy: "no-cache",
          })
          .then(({data}) => {
              if(data && data.Agencia){
                const agencia = data.Agencia
                  .filter((d) => d.id===id)
                this.$emit('creado', agencia[0])
                this.$emit('cerrar', false);
                this.botonDeshabilitado = true;
              }
            },
          )
          .catch(() => {
              this.botonDeshabilitado = false;
              this.alertaMensaje('Ha ocurrido un error obteniendo los clientes',
               'error');
          })
    },
    agenciaCrear(agencia) {
      const agenciaVariable = { ...agencia };
      this.$apollo
        .mutate({
          mutation: gql`
            ${agenciaCrearGql}
          `,
          variables: agenciaVariable,
        })
        .then(({data: {agenciaCrear}}) => {
          this.limpiarCampos();
          // Se hace uso de método para obtener la agencia para evitar problemas con la cache
          this.obtenerAgenciaCreada(agenciaCrear.id)
          this.alertaMensaje(
            "Agencia creada correctamente",
            "correcto")
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false;
        });
    },
    agenciaEditar(agenciaConFormato){
      const agencia = {};
      // Se adapta agencia al input schema de la mutate agenciaEditar
      agencia.id =  this.agenciaId;
      agencia.nombre = agenciaConFormato.agencia.nombre;
      agencia.enlace = agenciaConFormato.agencia.enlace;
      agencia.codigo = agenciaConFormato.agencia.codigo;
      agencia.prefijo = agenciaConFormato.agencia.prefijo;
      this.$apollo
          .mutate({
            mutation: gql`${agenciaEditarGql}`,
            variables: {
              agencia,
            }
          })
          .then(({data: {agenciaEditar}}) => {
            const msg = agenciaEditar.codigo;
            switch (msg) {
              case "Correcto":
                this.alertaMensaje('Se ha modificado la agencia con exito.', 'correcto');
                this.limpiarCampos();
                this.botonDeshabilitado = false;
                this.$emit('editado', JSON.parse(JSON.stringify(agencia)))
                this.$emit('cerrar', false);
                break;
              case "Fallido":
                this.alertaMensaje('Ha ocurrido un error modificando los datos de la agencia.'
                    +' Por favor intenta de nuevo', 'error');
                this.botonDeshabilitado = false;
                break;
              default:
               this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error');
                this.botonDeshabilitado = false;
                break;
            }
          })
          .catch(() => {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
             'error')
            this.botonDeshabilitado = false;
          });
    },
    limpiarCampos() {
      this.agencia=  {
        telefonos: [],
        agencia: {
          nombre: '',
        },
        direccion: {
          direccion: '',
          ciudad: '',
          estado: '',
        },
        empresaId: '',
      },
      this.agenciaNombreSinModificar = '';
      this.estadoSeleccionado = '';
      this.agenciaId = '';
      this.telefono = '',
      this.validarValores = {};
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? {icono: 'sync', texto: 'Actualizar'}
        : {icono: 'check', texto: 'Agregar'};
    },
    tituloModal() {
      return this.esEditar
        ? 'Datos de la agencia a editar'
        : 'Datos de la nueva agencia';
    },
  },
  apollo: {
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        fetchPolicy: "cache-and-network",
      };
    },
     empresas() {
      return {
        query: empresasGql,
        update: (data) => data.Empresa,
        fetchPolicy: "cache-and-network",
      };
    },
    estados() {
      return {
        query: estadosGql,
        update: (data) => data.Estado,
        fetchPolicy: "cache-and-network",
      };
    },
    ciudades() {
      return {
        query: ciudadesGql,
        variables: () => {
          return {
            filter: {
              estado: {
                nombre: this.agencia.direccion.estado,
              },
            }
          }
        },
        skip() { return this.agencia.direccion && this.agencia.direccion.estado ? false : true},
        update: (data) => data.Ciudad,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1rem;

  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
.mt-campo-btn {margin-top: 10px;}
</style>
<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModalPago"
      @close="cerrarModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ titulo }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent>
          <div class="row">
            <div
              :class="['col-12 col-sm-5',
                { 'campo': validarValores.fecha
                  && validarValores.fecha.length },
                { 'valido': validarValores.fecha === 'valido' },
                { 'invalido': validarValores.fecha === 'invalido' },
              ]"
            >
              <mdb-input
                v-model="pago.fecha.formatted"
                type="date"
                label="Fecha pago"
                outline
                class="my-2"
              />
              <p
                v-if="validarValores.fecha === 'invalido'"
                class="mensaje-invalido date"
              >
                Indica la fecha
              </p>
            </div>
            <div
              :class="[
                'col-12 col-sm-7 pl-sm-0',
                { campo: validarValores.medio && validarValores.medio.length },
                { valido: validarValores.medio === 'valido' },
                { invalido: validarValores.medio === 'invalido' },
                {'cursor-not-allowed campo invalido mb-0': !(medios && medios.length)}
              ]"
              title="Debes agregar al menos 1 método de pago"
            >
              <div
                :class="['md-form md-outline outline-select my-2',
                  {'no-pointer-events': !(medios && medios.length)}
                ]"
              >
                <select
                  id="medio-select"
                  v-model="medio"
                  class="custom-select"
                >
                  <option class="d-none" disabled :value="{}">Seleccione</option>
                  <option
                    v-for="(value, key) in medios"
                    :key="`medioPago-${key}`"
                    :value="value"
                  >
                    {{ value.nombre }}
                  </option>
                </select>
                <small
                  v-if="!(medios && medios.length)"
                  class="form-text text-muted user-select-none"
                >
                  <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-warning"
                  />
                  Debes registrar al menos 1 medio de pago
                </small>
                <label
                  for="medio-select"
                  :class="Object.entries(medio).length ? 'label-active' : 'label-inactive'"
                >
                  Medio de pago
                </label>
              </div>
              <p
                v-if="validarValores.medio === 'invalido' && (medios && medios.length)"
                class="mensaje-invalido"
              >
                Selecciona el medio de pago
              </p>
            </div>
            <div
              :class="[
                'col-12',
                { campo: validarValores.monto && validarValores.monto.length },
                { valido: validarValores.monto === 'valido' },
                { invalido: validarValores.monto === 'invalido' },
              ]"
            >
              <mdb-input
                v-model.number="pago.monto"
                type="number"
                label="Monto"
                class="my-2 dollar-addon"
                outline
              >
                <span
                  slot="prepend"
                  class="input-group-text md-addon user-select-none"
                  title="Dólares"
                >
                  $
                </span>
              </mdb-input>
              <p
                v-if="validarValores.monto === 'invalido'"
                class="mensaje-invalido prepend"
              >
                Indica el monto
              </p>
            </div>
            <div
              v-if="!esEditar && !clienteEspecificado && !esVistaDetalles"
              :class="[
                'col-12 mt-2',
                { campo: validarValores.cliente && validarValores.cliente.length },
                { valido: validarValores.cliente === 'valido' },
                { invalido: validarValores.cliente === 'invalido' },
              ]"
            >
              <header class="d-inline font-weight-bold user-select-none my-1">
                Cliente
              </header>
              <ClienteBuscador @cliente="asignarCliente" />
              <p
                v-if="validarValores.cliente === 'invalido'"
                class="mensaje-invalido datalist-title"
              >
                Debes indicar el cliente
              </p>
            </div>
            <div
              :class="['col-12 col-sm-6 mt-2',
                { campo: validarValores.referencia && validarValores.referencia.length },
                { valido: validarValores.referencia === 'valido' },
                { invalido: validarValores.referencia === 'invalido' },
              ]"
            >
              <mdb-input
                v-model.trim="pago.referencia"
                type="text"
                label="Referencial del pago"
                class="my-2"
                outline
              />
                <p
                v-if="validarValores.referencia === 'invalido'"
                class="mensaje-invalido"
              >
                Indica la referencia
              </p>
            </div>
            <div class="col-12 col-sm-6 mt-sm-2 pl-sm-0">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="estatusPago-select"
                  v-model="pago.estatus"
                  class="custom-select"
                >
                  <option class="d-none" disabled value="">Seleccione</option>
                  <option
                    v-for="(value, key) in EstatusPago"
                    :key="`estatusPago-${key}`"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
                <label
                  for="estatusPago-select"
                  :class="pago.estatus ? 'label-active' : 'label-inactive'"
                >
                  Estatus del pago (opcional)a
                </label>
              </div>
            </div>
            <div class="col-12">
              <mdb-input
                v-model.trim="pago.observacion"
                type="textarea"
                label="Observacion del pago (opcional)"
                :rows="2"
                class="my-2"
                outline
              />
            </div>
            <div class="col-12 my-2" v-if="!esEditar">
              <div class="outline-input-file" data-size="sm">
                <label for="input-file-images" class="label-file">
                  <font-awesome-icon icon="upload" class="mr-1" />
                  Elegir comprobante
                </label>
                <span class="contenido">
                  {{
                    comprobante && comprobante.length
                      ? comprobante.length > 1
                        ? comprobante.length + " archivos"
                        : comprobante.length + " archivo"
                      : "No se eligió archivo"
                  }}
                </span>
                <input
                  id="input-file-images"
                  type="file"
                  multiple
                  accept="application/pdf,image/*"
                  class="custom-file-input"
                  @change="comprobantePago"
                />
                <mdb-btn
                  v-if="comprobantePago.length > 0"
                  flat
                  dark-waves
                  icon="times"
                  class="file-button m-0 p-2"
                  title="Eliminar archivos seleccionadas"
                  @click="comprobante = []; size=0;"
                />
              </div>
            </div>
            <small
              v-if="size"
              class="col-12 text-muted text-center"
            >
              Archivos adjuntados: {{ size.toFixed(2) }} / {{ maxSize }}MB
            </small>
            <div v-if="esEditar" class="col-12 text-center mt-2">
              <mdb-btn
                flat
                dark-waves
                icon="plus"
                class="btn-bordeado my-0 py-2 px-3"
                @click="modalEditarArchivo=true"
              >
                Fotos
              </mdb-btn>
            </div>
          </div>
          <hr>
          <ul
            v-if="paquetesSinPago.length || ordenesSinPago.length"
            class="nav nav-pills justify-content-around align-items-center pb-sm-0"
          >
            <li
              v-if="paquetesSinPago && paquetesSinPago.length"
              :class="['nav-item nav-link', {'active': mostrarLista === 'paquetes'}]"
              @click="mostrarLista = 'paquetes'"
            >
              Paquetes
              <mdb-badge color="danger" class="ml-2">{{paquetesSinPago.length}}</mdb-badge>
            </li>
            <li
              v-if="ordenesSinPago && ordenesSinPago.length"
              :class="['nav-item nav-link', {'active': mostrarLista === 'ordenes'}]"
              @click="mostrarLista = 'ordenes'"
            >
              Compras
              <mdb-badge color="danger" class="ml-2">{{ordenesSinPago.length}}</mdb-badge>
            </li>
            <li class="nav-item mb-2 px-2">
              Restante
              <span :class="['font-weight-bold', colorMonto]">{{ calcularMontoRestante }} $</span>
            </li>
          </ul>
          <div v-if="paquetesSinPago && paquetesSinPago.length && mostrarLista === 'paquetes'">
            <h5 class="mt-2 mr-n2">
              Seleccionar paquetes para asignar el pago
              <small class="text-muted">(opcional)</small>
            </h5>
            <ul class="lista-seleccionable">
              <li
                v-for="paquete in paquetesSinPagoOrdenados"
                :key="paquete.id"
                :class="{'seleccionado': paquetesSeleccionados.some(e => e.id === paquete.id)}"
              >
                <!-- Crear checkbox para seleccionar id del paquete -->
                <p class="mb-1">
                  <span class="lista-titulo">
                    Paquete ID
                  </span>
                  <span :class="{'text-muted user-select-none': !(paquete.numeroHR && paquete.numeroHR.length)}">
                    {{ paquete.numeroHR && paquete.numeroHR.length ? paquete.numeroHR : 'Paquete ID no disponible' }}
                  </span>
                </p>
                <div class="row">
                  <p class="col-12 col-sm-6 mb-1">
                    <span class="lista-titulo">
                      Estatus
                    </span>
                    <span :class="{'text-muted user-select-none': !(paquete.estatus && paquete.estatus.length)}">
                      {{ paquete.estatus && paquete.estatus.length
                        ? PaqueteEstatus[paquete.estatus] : 'Estatus no disponible' }}
                    </span>
                  </p>
                  <p class="col-12 col-sm-6 mb-1">
                    <span class="lista-titulo">
                      Tipo
                    </span>
                    <span :class="{'text-muted user-select-none': !(paquete.tipo && paquete.tipo.length)}">
                      {{ paquete.tipo && paquete.tipo.length
                        ? paquete.tipo : 'Tipo no disponible' }}
                    </span>
                  </p>
                  <p class="col-12 col-sm-6 mb-1">
                    <span class="lista-titulo">
                      Costo total
                    </span>
                    <span v-if="paquete.monto" title="Dólares">
                      $ {{ paquete.monto }}
                    </span>
                    <span v-else class="text-muted user-select-none">
                      Sin especificar
                    </span>
                  </p>
                  <p
                    v-if="paquete.envio && paquete.envio.length && paquete.consolidado && paquete.consolidado.master && paquete.consolidado.master.length"
                    class="col-12 col-sm-6 mb-1"
                  >
                    <font-awesome-icon :icon="ConsolidadoTipoEnvioIconos[paquete.envio]" class="texto-terciario" />
                    <span :class="{'text-muted user-select-none': !(paquete.consolidado.master && paquete.consolidado.master.length)}">
                      {{ paquete.consolidado.master && paquete.consolidado.master.length
                        ? paquete.consolidado.master : 'Guía master no disponible' }}
                    </span>
                  </p>
                </div>
                <p class="mb-1">
                  <span class="lista-titulo">
                    Seleccionar
                  </span>
                  <span
                    :class="['custom-control custom-switch d-inline-block ml-1 pb-2', {'cursor-not-allowed': !paquete.monto}]"
                    :title="!paquete.monto ? 'No se puede seleccionar un paquete sin costo total' : 'Seleccionar este paquete'"
                  >
                    <input
                      :id="`check-seleccionado-${paquete.id}`"
                      v-model="paquetesSeleccionados"
                      type="checkbox"
                      name="seleccionar-paquete"
                      class="custom-control-input"
                      :value="paquete"
                      :disabled="!paquete.monto || !Number(pago.monto) || Number(pago.monto) <= 0"
                    >
                    <label
                      :for="`check-seleccionado-${paquete.id}`"
                      class="custom-control-label pr-4"
                    >
                      {{ paquetesSeleccionados.some(e => e.id === paquete.id) ? 'Sí' : 'No'}}
                    </label>
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div v-if="ordenesSinPago && ordenesSinPago.length && mostrarLista === 'ordenes'">
            <h5 class="mr-n2">
              Seleccionar órdenes para asignar el pago
              <small class="text-muted">(opcional)</small>
            </h5>
            <ul class="lista-seleccionable">
              <li
                v-for="orden in ordenesSinPagoOrdenados"
                :key="orden.id"
                :class="{'seleccionado': ordenesSeleccionadas.some(e => e.id === orden.id)}"
              >
                <!-- Crear checkbox para seleccionar id del paquete -->
                <p class="mb-1">
                  <span class="lista-titulo">
                    Compra ID
                  </span>
                  <span :class="{'text-muted user-select-none': !(orden.compraId && orden.compraId.length)}">
                    {{ orden.compraId && orden.compraId.length ? orden.compraId : 'Compra ID no disponible' }}
                  </span>
                </p>
                <div class="row">
                  <p class="col-12 col-sm-6 mb-1">
                    <span class="lista-titulo">
                      Estatus
                    </span>
                    <span :class="{'text-muted user-select-none': !(orden.estatus && orden.estatus.length)}">
                      {{ orden.estatus && orden.estatus.length
                        ? OrdenEstatus[orden.estatus] : 'Estatus no disponible' }}
                    </span>
                  </p>
                  <p class="col-12 col-sm-6 mb-1">
                    <span class="lista-titulo">
                      Monto
                    </span>
                    <span :class="{'text-muted user-select-none': !orden.monto}">
                      <strong>$</strong>{{ orden.monto && orden.monto > 0
                        ? orden.monto : 'Monto no disponible' }}
                    </span>
                  </p>
                </div>
                <p class="mb-1">
                  <span class="lista-titulo">
                    Seleccionar
                  </span>
                  <span class="custom-control custom-switch d-inline-block ml-1 pb-2">
                    <input
                      :id="`check-seleccionado-${orden.id}`"
                      v-model="ordenesSeleccionadas"
                      type="checkbox"
                      name="seleccionar-paquete"
                      class="custom-control-input"
                      :value="orden.id"
                      :disabled="!orden.monto || !Number(pago.monto) || Number(pago.monto) <= 0"
                    >
                    <label
                      :for="`check-seleccionado-${orden.id}`"
                      class="custom-control-label pr-4"
                    >
                      {{ ordenesSeleccionadas.some(e => e.id === orden.id) ? 'Sí' : 'No'}}
                    </label>
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div class="text-center">
            <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado || verificarMontoPago()"
              class="mt-3"
              @click="submitPago"
            >
              {{ botonPrincipal.texto }}
            </mdb-btn>
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <ModalSubirArchivo
      :mostrar-modal="modalEditarArchivo"
      :grafoId="pago.id"
      :soloImagenes="false"
      entidadFoto="Pago"
      titulo="Subir comprobantes"
      archivo="comprobantes"
      @cerrar="modalEditarArchivo = false"
      @alerta="mensajeAlerta($event)"
    />
    <ModalEditarImagen
      titulo="Editar comprobante de pago"
      :mostrar-modal="mostrarModalEditarFoto"
      :imagen="imagenSeleccionada"
      :imgSrc="imgSrc"
      @alerta="mensajeAlerta($event)"
      @imagenModificada="imagenAgregar($event)"
      @cerrar="mostrarModalEditarFoto = $event; imagenSeleccionada=null; imgSrc=''"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEditarImagen"
      :cancelarMensaje="true"
      mensaje="¿Quieres editar la imagen seleccionada?"
      @cerrar="mostrarModalConfirmarEditarImagen = $event;imagenAgregar(imagenSeleccionada)"
      @eliminar="leerImagenEditar(imagenSeleccionada);
        mostrarModalConfirmarEditarImagen=false"
    />
  </section>
</template>

<script>
import ClienteBuscador from "@/components/ClienteBuscador.vue";
import mediosGql from "@/graphql/medios.gql";
import paquetesSinPagoPorClienteGql from "@/graphql/paquetesSinPagoPorCliente.gql";
import ordenesSinPagoPorClienteGql from "@/graphql/ordenesSinPagoPorCliente.gql";
import ModalSubirArchivo from "@/components/ModalSubirArchivo.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import ModalEditarImagen from "@/components/ModalEditarImagen.vue";
import { ConsolidadoTipoEnvioIconos } from "@/constantes/consolidados.js";
import { regExpComprobante, EstatusPago } from "@/constantes/pagos.js";
import { capitalizarPrimeraLetra } from "@/funciones/funciones.js";
import { regExpFoto, PaqueteEstatus } from "@/constantes/paquetes.js";
import { OrdenEstatus } from "@/constantes/ordenes.js";
import { paquteCalcularCosto } from '../funciones/calcularCostos';
import {
  mdbBadge,
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
export default {
  name: "FormularioPago",
  components: {
    mdbBadge,
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ClienteBuscador,
    ModalSubirArchivo,
    ModalEditarImagen,
    ConfirmacionEliminar
  },
  props: {
    mostrarModalPago: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    esVistaDetalles: {
      type: Boolean,
      required: false,
      default: false
    },
    titulo: {
      type: String,
      required: true,
    },
    clienteEspecificado: {
      type: Object,
      required: false,
      default: () => {},
    },
    pagoAsignar: {
      type: Object,
      required: false,
      default: () => {},
    },
    cliente: {
      type: Object,
      required: false,
      default: () => {},
    },
    paquete: {
      type: Object,
      required: false,
      default: () => {},
    },
    compra:{
      type: Object,
      required: false,
      default: () => {},
    }
  },
  data() {
    return {
      botonDeshabilitado: false,
      ConsolidadoTipoEnvioIconos,
      mostrarModalConfirmarEditarImagen: false,
      pago: {
        referencia:"",
        observacion:"",
        estatus:"",
        fecha: {},
        cliente: {},
      },
      medio:{},
      validarValores: {},
      comprobante: [],
      modalEditarArchivo: false,
      EstatusPago,
      PaqueteEstatus,
      mostrarModalEditarFoto:false,
      imagenSeleccionada:null,
      imgSrc:"",
      maxSize:18,
      size:0,
      mostrarLista: 'paquetes',
      paquetesSinPago:[],
      paquetesSeleccionados:[],
      ordenesSinPago: [],
      ordenesSeleccionadas: [],
      OrdenEstatus
    };
  },
  watch: {
    esEditar: function () {
      this.esEditar &&
        Object.assign(this.pago, JSON.parse(JSON.stringify(this.pagoAsignar))) &&
        Object.assign(this.medio, JSON.parse(JSON.stringify(this.pagoAsignar.medio)));
    },
    mostrarModalPago: function () {
      if(this.cliente && Object.keys(this.cliente).length){
      this.asignarCliente(this.cliente);
      }
      if(this.paquete && this.paquete.id){
        this.paquetesSeleccionados.push(this.paquete);
      }
      if(this.compra && this.compra.id){
        this.ordenesSeleccionadas.push(this.compra);
      }
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("editar", false);
      this.$emit("cerrar", false);
      this.limpiarCamposForm();
    },
    mensajeAlerta(mensaje){
      this.$emit("alertaMsg", mensaje)
      this.$emit("alerta", mensaje)
    },
    limpiarCamposForm() {
      this.botonDeshabilitado = false;
      this.pago = {referencia:"", observacion:"", estatus:"", fecha: {}, cliente: {} };
      this.medio = {}
      this.validarValores = {};
      this.comprobante = [];
      this.size = 0;
      this.paquetesSinPago = [];
      this.ordenesSeleccionadas = [];
      this.ordenesSinPago = []
      this.paquetesSeleccionados = []
    },
    imagenAgregar(archivo){
      const sizeFile = Number((archivo.size / 1024 / 1024).toFixed(2));
      if (sizeFile > this.maxSize) {
          return this.$emit("alerta",
            {contenido:`El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            tipo:"error"}
      )}
      if ((this.size + sizeFile) > this.maxSize) {
          return this.$emit("alerta",
            {contenido:`La imagen modificada supera el límite de 18MB, no se puede agregar. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
            tipo:"error"}
          );
        }
        this.size += Number(sizeFile);
        this.comprobante.push(archivo)
    },
    leerImagenEditar(imagenSeleccionada){
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.mostrarModalEditarFoto = true;
        };
        reader.readAsDataURL(imagenSeleccionada);
      } else {
        this.$emit("alerta", {
        contenido: "No se puede editar la imagen, actualiza tu navegador",
        tipo: "advertencia",
      });
      }
    },
    sumatoriaOrdenesSinPagoSeleccionados(){
      let sumatoria = 0;
      this.ordenesSinPago.forEach(orden => {
        if (this.ordenesSeleccionadas.some(e => e.id === orden.id)) {
          sumatoria += Number(orden.monto) - Number(orden.montoCubierto);
        }
      });
      return sumatoria
    },
    sumatoriaPaquetesSinPagoSeleccionados(){
      let sumatoria = 0;
      this.paquetesSinPagoOrdenados.forEach(paquete => {
        if (this.paquetesSeleccionados.some(e => e.id === paquete.id)) {
          sumatoria += Number(paquete.monto) - Number(paquete.montoCubierto);
        }
      });
      // this.paquetesSeleccionados.forEach(paquete => {
      //   sumatoria += Number(paquete.monto) - Number(paquete.montoCubierto);
      // });
      return sumatoria
    },
    verificarMontoPago(){
      const itemsSeleccionados = this.sumatoriaOrdenesSinPagoSeleccionados() + this.sumatoriaPaquetesSinPagoSeleccionados()
      if(this.paquetesSeleccionados || this.ordenesSeleccionadas){
        if(itemsSeleccionados > this.pago.monto){
           this.$emit("alerta", {
            contenido: "El monto de los items seleccionados supera al monto establecido para el pago",
            tipo: "advertencia",
          });
          return true
        } else {
           this.$emit("alerta", {
            contenido: "",
            tipo: "correcto",
          });
        }
      return false
      }
    },
    comprobantePago({ target }) {
      if (!target.files.length) return;
      if (target.files.length === 1 && regExpFoto.test(target.files[0].name)) {
        this.imagenSeleccionada = target.files[0];
        this.mostrarModalConfirmarEditarImagen = true
        return;
      }
      target.files.forEach((file) => {
        const sizeFile = Number((file.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          return this.$emit("alerta",
            {contenido:`El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            tipo:"error"}
          );
        }
        if ((this.size + sizeFile) > this.maxSize) {
          return this.$emit("alerta",
          {contenido:`No se puede agregar el archivo ya que superaría el límite establecido de 18MB. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
            tipo:"error"}
          );
        }
        if(!regExpComprobante.test(file.name)){
          return this.$emit("alertaMsg", {
            contenido: "El archivo no es de tipo / formato imagen o PDF",
              tipo: "advertencia",
            });
        }
        this.size += Number(sizeFile);
        this.comprobante.push(file)
      });
    },
    asignarCliente(clienteObtenido){
      if(!clienteObtenido.casillero){
        return this.$emit("alerta", {
          contenido: "El cliente no tiene casillero asignado",
          tipo: "advertencia",
        });
      }
      this.paquetesSinPago= [];
      this.ordenesSinPago = []
      this.paquetesSeleccionados = [];
      this.ordenesSeleccionadas = [];
      this.pago.cliente = clienteObtenido;
      this.paquetesSinPagoDelCliente()
      this.ordenesSinPagoDelCliente()
    },
    async ordenesSinPagoDelCliente(){
      if(this.esEditar) return
        try {
          const {data:{ordenesPendientePagoPorCliente}} = await this.$apollo.query({
          query: ordenesSinPagoPorClienteGql,
          variables: {
            clienteId: this.pago.cliente.id,
          },
          fetchPolicy: "network-only"
        });
        if(ordenesPendientePagoPorCliente.length){
          this.ordenesSinPago = ordenesPendientePagoPorCliente
        }
        } catch (error) {
          console.log(error, 'error')
            this.$emit("alerta", {
              contenido: "Error al obtener órdenes del cliente sin pago",
              tipo: "error",
            });
        }
    },
    async paquetesSinPagoDelCliente(){
      if(this.esEditar) return
        try {
          const {data:{paquetesSinPagoPorCliente}} = await this.$apollo.query({
          query: paquetesSinPagoPorClienteGql,
          variables: {
            clienteId: this.pago.cliente.id,
          },
          fetchPolicy: "network-only"
        });
        if(paquetesSinPagoPorCliente.length){
          this.paquetesSinPago = paquetesSinPagoPorCliente
        }
        } catch (error) {
            this.$emit("alerta", {
              contenido: "Error al obtener paquetes sin pago",
              tipo: "error",
            });
        }
    },
    submitPago() {
      if (this.clienteEspecificado) {
        this.pago.cliente = this.clienteEspecificado;
      }
      if (this.pago.observacion && this.pago.observacion.length) {
        this.pago.observacion = capitalizarPrimeraLetra(this.pago.observacion);
      }
      if(!this.pago.cliente.casillero){
        this.$emit("alerta", {
          contenido: "El cliente no cuenta con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia para continuar",
          tipo: "advertencia",
        });
        this.botonDeshabilitado = false;
        this.validarValores = {
          cliente: 'invalido'
        }
        return
      }
      if (this.pago.estatus !== this.EstatusPago.Confirmado && (this.paquetesSeleccionados.length || this.ordenesSeleccionadas.length)) {
        this.$emit("alerta", {
          contenido: "Solo pagos Confirmados pueden ser asignados a Paquetes o Compras",
          tipo: "advertencia",
        });
        this.botonDeshabilitado = false;
        this.validarValores = {
          estatus: 'invalido'
        }
        return
      }
      this.validarValores = {
        fecha: this.pago.fecha.formatted ? "valido" : "invalido",
        monto: this.pago.monto ? "valido" : "invalido",
        referencia: this.pago.referencia ? "valido" : "invalido",
        cliente: this.pago.cliente?.nombre?.length && this.pago.cliente?.casillero?.length ? "valido" : "invalido",
        estatus: this.pago.estatus !== this.EstatusPago.Confirmado && (this.paquetesSeleccionados.length || this.ordenesSeleccionadas.length) ? "invalido" : "valido",
        medio:this.medio?.id?.length ? "valido" : "invalido"
      };
      if (Object.values(this.validarValores).includes("invalido")) {
        this.$emit("alerta", {
          contenido: "Todos los campos son requeridos",
          tipo: "error",
        });
        this.botonDeshabilitado = false;
        return;
      }
      this.pago.estatus = this.pago.estatus.length ? this.pago.estatus : Object.keys(this.EstatusPago)[0]
      if (this.esEditar) {
        if (this.pago.monto < this.pago.restante) {
          this.$emit("alerta", {
            contenido: "El nuevo monto debe ser igual o menor al saldo restante: "
            + this.pago.restante,
            tipo: "error",
          });
          this.botonDeshabilitado = false;
          return;
        } else {
          this.pago.restante = this.pago.monto - this.pago.restante;
        }
        delete this.pago.cliente;
        delete this.pago.fecha.__typename;
        const { ...rest } = this.pago;
        delete rest.medio
        delete rest.__typename
        const pagoEditar = {
          pago:rest,
          medioId:this.medio
        };
        delete pagoEditar.__typename;
        pagoEditar.pago.monto = Math.abs(pagoEditar.pago.monto)
        this.$emit("editarPago", pagoEditar);
        this.limpiarCamposForm();
        this.cerrarModal();
        return;
      } else {
        this.pago.restante = Number(this.calcularMontoRestante);
      }
      if (!this.clienteEspecificado && !Object.keys(this.pago.cliente).length) {
        this.$emit("clienteError");
        if(!this.clienteEspecificado) {
          Object.assign(this.validarValores, {
            cliente: this.pago.cliente.nombre
              && this.pago.cliente.nombre.length ? "valido" : "invalido",
          })
        }
        this.botonDeshabilitado = false;
        return;
      }
      const paquetesSeleccionados = JSON.parse(JSON.stringify(this.paquetesSeleccionados))
      const paquetes = paquetesSeleccionados.map((e)=>{
        e.monto -= e.montoCubierto
        for (const key in e) {
          if (key !== 'id' && key !== 'monto') {
            delete e[key];
          }
        }
        return e;
      })

      const ordenesSeleccionadas = JSON.parse(JSON.stringify(this.ordenesSeleccionadas))
      const compras = ordenesSeleccionadas.map((e)=>{
        e.monto -= e.montoCubierto
        for (const key in e) {
          if (key !== 'id' && key !== 'monto') {
            delete e[key];
          }
        }
        return e;
      })
      this.pago.monto = Math.abs(this.pago.monto )
      const pagoCrear = {
        pago: this.pago,
        medioId:this.medio,
        comprobante: this.comprobante,
        paquetes,
        compras
      };
      this.$emit("crear", pagoCrear);
      this.limpiarCamposForm();
      // this.$emit("cerrar", false);
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? { icono: "sync", texto: "Actualizar" }
        : { icono: "check", texto: "Agregar" };
    },
    calcularMontoRestante() {
      let monto = Number(this.pago.monto) - Number(this.sumatoriaOrdenesSinPagoSeleccionados()) - Number(this.sumatoriaPaquetesSinPagoSeleccionados())
      // Aplica .toFixed(2) en números decimales
      if (!(Number.isNaN(monto)) && !(Number.isInteger(monto))) {
        monto = monto.toFixed(2)
      }
      return monto || 0
    },
    colorMonto() {
      if (this.calcularMontoRestante < 1) return 'texto-peligro'
      else return 'texto-exitoso'
    },
    ordenesSinPagoOrdenados(){
      const pagosListadosActuales = [
        ...this.ordenesSinPago
      ];
    return pagosListadosActuales.sort((a, b) => {
        if (a.fecha.formatted < b.fecha.formatted) {
          return -1;
        }
        if (a.fecha.formatted > b.fecha.formatted) {
          return 1;
        }
        return 0;
      });
    },
    paquetesSinPagoOrdenados() {
      const paquetesSinPago = [...this.paquetesSinPago];
      if(!paquetesSinPago.length) return  [] 
      const paquetesSinPagoNoReempacados = paquetesSinPago.filter((paquete)=>!paquete.reempacado)
      paquetesSinPagoNoReempacados.forEach((paquete)=>{
        paquete.monto  = paquteCalcularCosto(paquete);
        return paquete
      })
      return paquetesSinPagoNoReempacados.sort((a, b) => {
        if (a.fecha.formatted < b.fecha.formatted) {
          return -1;
        }
        if (a.fecha.formatted > b.fecha.formatted) {
          return 1;
        }
        return 0;
      });
    },
  },
  apollo:{
    medios(){
      return{
        query: mediosGql,
        update: (data)=>data.Medio,
        fetchPolicy: "cache-and-network",
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1rem;

  &.invalido > .mensaje-invalido {
    &.prepend {
      left: 47px;
    }
    &.datalist-title {
      top: 64px;
    }
  }
}

.nav-pills {
  background-color: $claro;
  padding-bottom: .5rem;
  position: sticky;
  top: -1rem;
  z-index: 1;

  .nav-item.nav-link {
    color: $terciario;
    cursor: pointer;
    margin-bottom: .5rem;
    transition: background-color .2s ease, color .2s ease;

    &.active {
      background-color: $terciario;
      color: $claro;
    }
  }
}

.lista-seleccionable {
  list-style-type: none;
  padding-left: 0;
  font-size: .86rem;

  li {
    border: 1px solid transparent;
    padding: .4rem 0 0 1.5rem;
    position: relative;
    transition: background-color .25s ease;

    &.seleccionado {
      background-color: $exitoso;
      background-color: lighten($exitoso, 67.5%);
      border-color: #b0ddca;
      border-radius: .25rem;

      &::before {
        animation-duration: .25s;
        animation-fill-mode: both;
        animation-name: zoomIn;
        color: $exitoso;
        content: '\f058';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        left: .3rem;
        position: absolute;
        top: .4rem;
      }
    }

    & + li {
      margin-top: .5rem;
    }
  }


  .lista-titulo {
    font-weight: 600;
    margin-right: .15rem;
  }
}
</style>
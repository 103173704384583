<template>
  <mdb-modal
    centered
    elegant
    :show="mostrarModal"
    @close="$emit('cerrar', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Se requiere confirmación
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <p class="text-center px-4 px-sm-5">
        <font-awesome-icon
          icon="exclamation-circle"
          size="5x"
          class="d-block mx-auto mb-4 texto-peligro animated rotateIn"
        />
        {{ mensaje }}.
        <span class="d-block mt-2 font-weight-bold" v-if="!cancelarMensaje">
          ¿Estás seguro que deseas eliminarl{{ sustantivoFemenino ? 'a' : 'o' }}?
        </span>
      </p>
      <div class="row justify-content-around">
        <mdb-btn
          flat
          waves
          :icon="botonDeshabilitado ? 'circle-notch' : 'times'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto btn-bordeado px-4"
          @click="$emit('cerrar', false);"
        >
          No
        </mdb-btn>
        <mdb-btn
          color="peligroso"
          :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto px-4"
          @click="$emit('eliminar', true);"
        >
          Si
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: "ConfirmacionEliminar",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    sustantivoFemenino: {
      type: Boolean,
      required: false,
      default: false,
    },
    mensaje: {
      type: String,
      required: true,
    },
    cancelarMensaje:{
      type:Boolean,
      required:false,
      default:false
    }
  },
  data() {
    return {
      botonDeshabilitado: false,
    };
  },
}
</script>

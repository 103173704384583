<template>
  <v-select
    ref="paqueteBuscadorInput"
    v-model="paquete"
    :options="paquetes"
    :filter="fuseSearch"
    :getOptionLabel="option => option.numeroHR"
    class="outline-datalist"
    :placeholder="placeholder"
    @input="$emit('paquete', paquete && paquete.id ? paquete : {}); paqueteTracking = ''; paquete = ''; paquetes = []; focus()"
    @search="query=> query != '' ? paqueteTracking = query : paquetes = []"
  >
    <template #option="{ tracking, numeroHR }">
      <span class="d-block">
        Tracking: {{ tracking ? tracking : 'Sin especificar' }}
      </span>
      <cite>Paquete ID: {{ numeroHR }}</cite>
    </template>
    <template #no-options v-if="paqueteTracking.length < 3">
      Por favor ingresa un tracking o paquete ID para empezar a buscar
    </template>
    <template #no-options v-else-if="$apolloData.queries.paquetes.loading">
      Cargando
    </template>
    <template #no-options v-else>
      No se encontraron resultados para la búsqueda
    </template>
  </v-select>
</template>

<script>
import paqueteBuscadorGql from "@/graphql/paqueteBuscador.gql";
import gql from "graphql-tag";
import Fuse from "fuse.js";

export default {
  name: "PaqueteBuscador",
  props: {
    placeholder: {
      type: String,
      default: 'Ingrese el tracking o paquete ID para buscar'
    },
    limpiarPaquete: {
      type: Boolean,
      required: false
    },
    habilitarAutoFocus: {
      type: Boolean,
      required: false
    },
    autoSeleccinar: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      paquete: '',
      paqueteTracking: '',
    };
  },
  mounted () {
      setTimeout(() => {
        this.focus();
      }, 100);
  },
  watch: {
    limpiarPaquete(){
      if(this.limpiarPaquete){
        this.paquete = ''
        this.paqueteTracking = ''
        this.$emit('limpiar', false)
      }
    },
    paqueteTracking(){
        this.focus()
      },
  },
  methods: {
    focus() {
      if (this.habilitarAutoFocus) {
        this.$refs.paqueteBuscadorInput.$el.querySelector('input').focus()    
      }
    },
    fuseSearch(options, search) {
      if(this.autoSeleccinar && this.paqueteTracking.length > 2 && options.length){
        const opcionesSinReactividad = JSON.parse(JSON.stringify(options));
        const opciones = opcionesSinReactividad.filter(opcion => opcion?.tracking?.toLowerCase() === this.paqueteTracking?.toLowerCase() || opcion?.numeroHR?.toLowerCase() === this.paqueteTracking?.toLowerCase() ) 
        if (opciones.length) {
            this.paquete = ''
            this.paqueteTracking = ''
            this.$refs.paqueteBuscadorInput.$el.querySelector('input').blur()      
            this.$refs.paqueteBuscadorInput.$el.querySelector('input').focus() 
            this.$emit('paquete', opciones[0]);
        }
      }
      const fuse = new Fuse(options, {
        keys: ["tracking", "numeroHR"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    }
  },
  apollo: {
    paquetes() {
      return {
        query: gql`
          ${paqueteBuscadorGql}
        `,
        variables() {
          return {
            parametro: this.paqueteTracking,
          };
        },
        skip: () => this.paqueteTracking.trim() && this.paqueteTracking.length > 2 ? false : true,
        debounce: '1000',
        update: (data) => data.paqueteBuscador,
        fetchPolicy: "no-cache",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

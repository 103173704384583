<template>
  <div
    :class="[
      'container-xl',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaconsolidados },
    ]"
  >
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Consolidados</h2>
      <mdb-btn
        flat
        dark-waves
        icon="redo-alt"
        :disabled="botonDeshabilitado"
        class="btn-bordeado my-sm-0 px-3"
        @click="consolidadoActualizar('estatus')"
      >
        Actualizar
      </mdb-btn>
      <mdb-btn
        v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
        color="primario"
        icon="plus"
        :disabled="botonDeshabilitado"
        class="my-sm-0 px-3"
        @click="validarCrearConsolidado"
      >
        Nuevo consolidado
      </mdb-btn>
    </div>
    <div class="mb-page">
      <!-- Filtros -->
      <div
        class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0"
      >
        <div class="col-auto">
          <mdb-btn
            flat
            dark-waves
            icon="list"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 py-2 px-3"
            @click="consolidadoActualizar();
              buscarPor.estatus = '';"
          >
            Todos
          </mdb-btn>
        </div>
        <div class="col-auto px-0">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select"
              v-model="buscarPor.estatus"
              class="custom-select"
              @change="consolidadoActualizar('estatus')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in ConsolidadoEstatus"
                :key="value"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select"
              :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
      </div>
      <!-- tabla -->
      <ul
        data-table="sticky"
        class="table mb-page"
      >
        <li class="encabezado row align-items-end">
          <div
            @click="ordenarColumna('tipo')"
            class="col-4 col-sm-2 pl-4 pl-lg-2 filtrable"
          >
            Tipo
            <div
              v-if="orderBy.tipo != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.tipo}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.tipo}"
              />
            </div>
          </div>
          <div
            @click="ordenarColumna('estatus')"
            class="col filtrable"
          >
            Detalles
            <div
              v-if="orderBy.estatus != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.estatus}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.estatus}"
              />
            </div>
          </div>
          <div
            @click="ordenarColumna('guia')"
            class="col-3 d-none d-sm-block filtrable"
          >
            Guía máster
            <div
              v-if="orderBy.guia != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.guia}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.guia}"
              />
            </div>
          </div>
          <div
            @click="ordenarColumna('fecha')"
            class="col-2 px-0 d-none d-md-block filtrable"
          >
            Fecha de carga
            <div
              v-if="orderBy.fecha != null"
              class="sort-iconos animated fadeIn"
            >
              <font-awesome-icon
                icon="sort-up"
                :class="{'activo': orderBy.fecha}"
              />
              <font-awesome-icon
                icon="sort-down"
                :class="{'activo': !orderBy.fecha}"
              />
            </div>
          </div>
        </li>
        <li
          v-for="consolidado in consolidados"
          :key="`consolidado-${consolidado.id + Date.now()}`"
          class="contenido row align-items-center"
          @click="$router.push({ name: 'Consolidado', params: { id: consolidado.id } })"
        >
          <div class="col-4 col-sm-2">
            {{ ConsolidadoTipoEnvio[consolidado.tipo] }}
          </div>
          <!-- Detalles -->
          <div class="col">
            Estatus:
            <span class="d-block d-sm-inline-block mr-2">
              {{ ConsolidadoEstatus[consolidado.estatus] }}
            </span>
            <span
              v-if="consolidado.estatus != 'Devuelto'
                && (rol.includes('Admin') || rol.includes('OpPuerto') || rol.includes('OpAlmacen'))"
              class="btn btn-flat ripple-parent btn-sm btn-bordeado m-0 my-2 my-md-0 ml-md-2 p-2"
              @click.stop="mostrarModalEditarEstatus = true;
                consolidadoSeleccionado = JSON.parse(JSON.stringify(consolidado));
                estatusAnterior = consolidadoSeleccionado.estatus">
              <font-awesome-icon icon="edit" class="mx-1" />
              Editar
            </span>
            <p class="mb-0">
              <span title="Peso por volumen">
                vlbs: {{ consolidado.pesoVolumetrico }}
                |
              </span>
              <span title="Peso">lbs: {{ mostrarSoloDosDecimales(consolidado.peso) }} |</span>
              <span title="Pies cúbicos">
                ft<sup>3</sup>: {{ consolidado.piesCubicos }}
              </span>
            </p>
            <span v-if="consolidado.observacion">
              Observación: {{ consolidado.observacion }}
            </span>
            <p
              v-if="consolidado.cantidad"
              class="mb-0"
            >
              Cantidad de paquetes:
              <span>
                {{ consolidado.cantidad }}
              </span>
            </p>
            <p class="my-1 d-sm-none">
              Guía master:
              <span :class="{'text-muted user-select-none': !consolidado.master}">
                {{ consolidado.master ? consolidado.master : 'Sin especificar' }}
              </span>
            </p>
            <p class="mt-1 mb-0 d-md-none">
              Fecha de carga: {{ consolidado.fechaCarga.formatted }}
            </p>
          </div>
          <div :class="['col-3 d-none d-sm-block', {'text-muted user-select-none': !consolidado.master}]">
            {{ consolidado.master ? consolidado.master : 'Sin especificar' }}
          </div>
          <div class="col-2 pl-0 d-none d-md-block">
            {{ consolidado.fechaCarga.formatted }}
          </div>
        </li>
        <li
          v-if="!consolidados.length"
          class="no-items"
        >
          No hay consolidados
        </li>
        <li v-if="$apolloData.queries.consolidados.loading">
          <CargandoVista clase-adicional="vc-50px" />
        </li>
      </ul>
    </div>
    <FormularioConsolidado
      :mostrar-modal="mostrarFormularioConsolidado"
      @cerrar="mostrarFormularioConsolidado = $event;"
      @consolidado="consolidadoCrear($event)"
      @alertaMensaje="alertaMensaje=$event"
    />
    <mdb-modal
      centered
      elegant
      :show="mostrarModalEditarEstatus"
      @close="mostrarModalEditarEstatus = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Estatus del consolidado
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Estatus</header>
        <div class="md-form md-outline outline-select my-2">
          <select
            v-model="consolidadoSeleccionado.estatus"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(value, key) in ConsolidadoEstatus"
              :key="value"
              :value="key"
            >
            {{ value }}
            </option>
          </select>
        </div>
        <div class="text-center">
          <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : 'sync'"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado"
              class="mt-4"
              @click="consolidadoEditarEstatus(false)"
          >
            Actualizar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="consolidadoEditarEstatus(true)"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>

<script>
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import FormularioConsolidado from "../components/FormularioConsolidado.vue";
import gql from "graphql-tag";
import consolidadosGql from "@/graphql/consolidados.gql";
import consolidadoCrearGql from "@/graphql/consolidadoCrear.gql"
import consolidadoEditarGql from "@/graphql/consolidadoEditar.gql"
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import { leerRoles } from "@/utils/datosToken.js";
import { ConsolidadoEstatus, ConsolidadoTipoEnvio } from "@/constantes/consolidados.js"
import { mostrarSoloDosDecimales, esRetrocesoEstatus, verificarEstatusConsolidado } from "@/funciones/funciones.js"
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export  default {
  name: "Consolidados",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    FormularioConsolidado,
    AlertaMensaje,
    CargandoVista,
    ModalConfirmarPermiso,
  },
  data() {
    return {
      rol: leerRoles(),
      mostrarSoloDosDecimales,
      // Confirmar permiso
      metodoAlConfirmar: '',
      objetoAlConfirmarPermiso: {},
      mostrarModalConfirmarPermiso: false,
      estatusAnterior: '',
      // Otros
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      mostrarModalEditarEstatus: false,
      mostrarFormularioConsolidado: false,
      consolidados: [],
      consolidado: {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
      },
      buscarPor: {
        estatus: "",
      },
      // Ordenar asc desc
      orderBy: {
        fecha: null,
        tipo: null,
        estatus: null,
        guia: null,
      },
      orderByActual: [],
      // Constantes
      ConsolidadoTipoEnvio,
      ConsolidadoEstatus,
      consolidadoSeleccionado: {
        master: '',
        estatus: '',
        tipo: '',
        fechaCarga: {},
      },
      mostrarMas:true,
      pagina:1
    };
  },
    mounted(){
    this.onScrollChange()
  },
  computed: {
    ocultarOverflowEnVistaconsolidados() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
    variablesConsolidados() {
        const variables = { 
          offset: 0,
          first: 20,
          orderBy:["fechaCarga_desc", "master_desc"]
        }
        return variables
      },
  },
  methods: {
    validarCrearConsolidado() {
      this.mostrarFormularioConsolidado = true;
    },
    ordenarColumna(columna){
      if(columna == 'fecha'){
        this.orderBy.guia = null;
        this.orderBy.tipo = null;
        this.orderBy.estatus = null;
        this.orderBy.fecha === null
          ? this.orderBy.fecha = false  // se ordena descendente
          : this.orderBy.fecha
            ? this.orderBy.fecha = false   
            : this.orderBy.fecha = true  // se ordena ascendente
        if(this.orderBy.fecha) {
          this.orderByActual = ['fechaCarga_asc']
          return this.consolidadoActualizar('filtro')
        } else {
          this.orderByActual = ['fechaCarga_desc']
          return this.consolidadoActualizar('filtro')
        }
      }
       if(columna == 'estatus'){
        this.orderBy.guia = null;
        this.orderBy.tipo = null;
        this.orderBy.fecha = null
        this.orderBy.estatus === null
          ? this.orderBy.estatus = false  // se ordena descendente
          : this.orderBy.estatus
            ? this.orderBy.estatus = false   
            : this.orderBy.estatus = true  // se ordena ascendente
        if(this.orderBy.estatus) {
          this.orderByActual = ['estatus_asc']
          return this.consolidadoActualizar('filtro')
        } else {
          this.orderByActual = ['estatus_desc']
          return this.consolidadoActualizar('filtro')
        }
      }
       if(columna == 'guia'){
        this.orderBy.estatus = null;
        this.orderBy.tipo = null;
        this.orderBy.fecha = null
        this.orderBy.guia === null
          ? this.orderBy.guia = false  // se ordena descendente
          : this.orderBy.guia
            ? this.orderBy.guia = false   
            : this.orderBy.guia = true  // se ordena ascendente
        if(this.orderBy.guia) {
          this.orderByActual = ['master_asc']
          return this.consolidadoActualizar('filtro')
        } else {
          this.orderByActual = ['master_desc']
          return this.consolidadoActualizar('filtro')
        }
      }
       if(columna == 'tipo'){
        this.orderBy.estatus = null;
        this.orderBy.guia = null;
        this.orderBy.fecha = null
        this.orderBy.tipo === null
          ? this.orderBy.tipo = false  // se ordena descendente
          : this.orderBy.tipo
            ? this.orderBy.tipo = false   
            : this.orderBy.tipo = true  // se ordena ascendente
        if(this.orderBy.tipo) {
          this.orderByActual = ['tipo_asc']
          return this.consolidadoActualizar('filtro')
        } else {
          this.orderByActual = ['tipo_desc']
          return this.consolidadoActualizar('filtro')
        }
      }
    },
     onScrollChange() {
      window.onscroll = () => {
        const finalPagina = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
        if (finalPagina && this.mostrarMas) {
          this.consolidadoSolicitarMas()
        }
      };
    },
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
    consolidadoSolicitarMas() {
      if(this.botonDeshabilitado) return
      this.botonDeshabilitado = true
       const consolidadosActuales = JSON.parse(JSON.stringify(this.consolidados))
      const filter = {};
      if(this.buscarPor.estatus !== ''  && this.buscarPor.estatus.length){
        Object.assign(filter,{
          estatus:this.buscarPor.estatus
        })
      }
      const variables = {
        filter,
        offset:this.pagina * this.variablesConsolidados.first,
        first:this.variablesConsolidados.first,
        orderBy:this.orderByActual && this.orderByActual.length ? this.orderByActual :  this.variablesConsolidados.orderBy
      }
      this.$apollo.queries.consolidados.fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
        const nuevosConsolidados = JSON.parse(JSON.stringify(fetchMoreResult.Consolidado))
        this.mostrarMas =  nuevosConsolidados.length >=  this.variablesConsolidados.first
        const typename = previousResult?.Consolidado?.length
            ? previousResult?.Consolidado[0]?.__typename
            : ''
         if(this.mostrarMas){
            this.pagina++;
          }  
          if(!nuevosConsolidados.length){
          this.pagina = 1;
          this.mostrarMas = false;
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Consolidado:consolidadosActuales,
          };  
        }
        if(nuevosConsolidados.length){
          let consolidadosObtenidos = [...consolidadosActuales, ...nuevosConsolidados];
          if (this.buscarPor.estatus) {
            consolidadosObtenidos = consolidadosObtenidos.filter(c => c.estatus === this.buscarPor.estatus)
          }
          if(this.orderByActual.length){
          let consolidados = {};
           consolidadosObtenidos = consolidadosObtenidos
            .filter(consolidado => consolidados[consolidado.id] ? false : consolidados[consolidado.id] = true);
          }
          this.botonDeshabilitado = false;
          return {
            __typename: typename,
            Consolidado: consolidadosObtenidos,
          }
        }
        },
      })
    },
    paginacionResetear(){
      this.pagina = 1;
      this.mostrarMas = true;
    },
    consolidadoActualizar(filtro) {
      if(this.botonDeshabilitado) return
      this.botonDeshabilitado = true;
      this.paginacionResetear();
      if(!filtro){
        this.orderByActual = [];
        this.orderBy = {
        fecha: null,
        tipo: null,
        estatus: null,
        guia: null,
      }
      }
      let filtros = {};
      filtro && this.buscarPor.estatus != ""
        ? (filtros.estatus = this.buscarPor.estatus)
        : "";
      const variablesQuery = {
        filter: filtros,
      }
      this.orderByActual && this.orderByActual.length
          ? Object.assign(variablesQuery,{ 
              orderBy: this.orderByActual
            })
          : Object.assign(variablesQuery,{ 
              orderBy: this.variablesConsolidados.orderBy
            });
           Object.assign(variablesQuery,{ 
              offset: this.variablesConsolidados.offset,
              first: this.variablesConsolidados.first,
            })
      // Se utiliza apollo.watchQuery, debido a cache-and-network
      this.$apollo
        .watchQuery({
            query: gql`
              ${consolidadosGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Consolidado,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Consolidado){
                this.consolidados = data.Consolidado;
              }
              this.botonDeshabilitado = false;
            },
            error: () => {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando los consolidados',
                tipo: 'error',
              };
              this.botonDeshabilitado = false;
            }
          })
    },
    consolidadoCrear(consolidadoAEnviar) {
      const consolidado = JSON.parse(JSON.stringify(consolidadoAEnviar))
      consolidado.estatus = "Cargando"
      this.botonDeshabilitado = true;
      this.paginacionResetear()
      this.$apollo.mutate({
        mutation: gql`${consolidadoCrearGql}`,
        variables: {
          consolidado
        },
        update: (store, {data: {consolidadoCrear}}) => {
          const data = store.readQuery({
            query: consolidadosGql,
            variables:this.variablesConsolidados
          });

          Object.assign(consolidadoCrear, JSON.parse(JSON.stringify(consolidado)))
          consolidadoCrear.fechaCarga.__typename = '_Neo4jDateTime';
          consolidadoCrear.contienePaquetes = false;
          consolidadoCrear.cantidad = 0;
          consolidadoCrear.observacion = consolidadoCrear.observacion
            ? consolidadoCrear.observacion
            : '';
          consolidadoCrear.piesCubicos = 0;
          consolidadoCrear.pesoVolumetrico = 0;
          consolidadoCrear.peso = 0;
          data.Consolidado.unshift(consolidadoCrear);

          store.writeQuery({
            query: consolidadosGql,
            variables: this.variablesConsolidados,
            data,
          });
          this.consolidadoActualizar('filtro')
          this.mostrarFormularioConsolidado = false;
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: 'Se ha registrado el consolidado exitosamente',
            tipo: 'correcto',
          };
        },
        optimisticResponse: {
          __typename: 'Mutation',
            consolidadoCrear: {
            __typename: 'Consolidado',
            id: -1,
            master: consolidado.master,
            tipo: consolidado.tipo,
            estatus: consolidado.estatus,
            fechaCarga: {
              formatted: consolidado.fechaCarga.formatted,
              __typename: '_Neo4jDateTime',
            },
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    advertenciaEstatus(){
      this.botonDeshabilitado = false;
      return this.alertaMensaje = {
        contenido: 'No tienes permitido realizar esta acción',
        tipo: 'advertencia',
      };
    },
    consolidadoEditarEstatus(skip) {
      this.botonDeshabilitado = true;
      if(!this.consolidadoSeleccionado.contienePaquetes){
        this.botonDeshabilitado = false;
        return this.alertaMensaje = {
          contenido: 'El consolidado se encuentra sin paquetes dentro. Esta acción no se puede realizar',
          tipo: 'advertencia',
        };
      }
      if(this.rol.includes('Admin') || (this.rol.includes('OpAlmacen') && this.rol.includes('OpPuerto'))){
        // Permiso total a modificar estatus
      }
      else if(this.rol.includes('OpAlmacen')){
        const estatusActualConsolidado = verificarEstatusConsolidado(this.estatusAnterior)
        const estatusRestriccion = verificarEstatusConsolidado('EnTransito')
        // Valida que se esté modificando un estatus menor a "En Transito"
        if(estatusActualConsolidado < estatusRestriccion){
          const estatuSeleccionado = verificarEstatusConsolidado(this.consolidadoSeleccionado.estatus)
          // Si el estatus seleccionado es mayor a "En Transito" no se puede modificar.
          if(estatuSeleccionado > estatusRestriccion){
            return this.advertenciaEstatus()
          }
        } else {
          return this.advertenciaEstatus()
        }
      }
      else if(this.rol.includes('OpPuerto')){
        // Valida que se esté modificando un estatus mayor a "En Transito"
        if(esRetrocesoEstatus(this.estatusAnterior, 'NavieraAerolinea')){
          if(esRetrocesoEstatus('EnTransito', this.consolidadoSeleccionado.estatus)){
            return this.advertenciaEstatus()
          }
        } else {
          return this.advertenciaEstatus()
        }
      }
      // Valida si se está volviendo a un estatus anterior del consolidado, entonces pide confirmación
      if(esRetrocesoEstatus(this.estatusAnterior, this.consolidadoSeleccionado.estatus)
          && !skip){
        this.botonDeshabilitado = false;
        return this.mostrarModalConfirmarPermiso = true;
      }
      // Seleccionados datos requeridos en consolidadoEntrada + estatus modificado
      const consolidado = {
            id: this.consolidadoSeleccionado.id,
            estatus: this.consolidadoSeleccionado.estatus,
            tipo: this.consolidadoSeleccionado.tipo,
            master: this.consolidadoSeleccionado.master
          };
      delete this.consolidado.__typename;
      delete this.consolidado.fechaCarga.__typename;
      this.paginacionResetear()
      this.$apollo.mutate({
        mutation: gql`${consolidadoEditarGql}`,
        variables: {
          consolidado,
        },
        update: (store, {data: {consolidadoEditar}}) => {
          const codigo = consolidadoEditar.codigo;
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'El estatus ha sido actualizado satisfactoriamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando el estatus. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
            default:
              this.mostrarMensajeEnCasoDefault();
              return;
          }
          const data = store.readQuery({
            query: consolidadosGql,
            variables: this.variablesConsolidados,
          });
          // filtrar por id el consolidado a editar
          data.Consolidado.forEach(c => {
            if (c.id === this.consolidadoSeleccionado.id){
              Object.assign(c, consolidado)
            }
          });
          store.writeQuery({
            query: consolidadosGql,
            variables: this.variablesConsolidados,
            data,
          });
          this.mostrarModalEditarEstatus = false;
          this.botonDeshabilitado = false;
          this.consolidadoActualizar('filtro')
          this.alertaMensaje = {
            contenido: 'Se ha actualizado el estatus exitosamente',
            tipo: 'correcto',
          };
        },
        optimisticResponse: {
          __typename: 'Mutation',
            consolidadoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
      })
    },
  },
  apollo: {
    consolidados() {
      return {
        query: consolidadosGql,
        update: (data) => data.Consolidado,
        variables:this.variablesConsolidados,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div
    :class="[
      'container-xl',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaAgencias },
    ]"
  >
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Agencias</h2>
      <div
        v-if="!$apolloData.queries.agencias.loading"
        class="col-auto"
      >
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3"
          @click="agenciasActualizar()"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          v-if="roles.includes('Admin')"
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-sm-0 px-3"
          @click="mostrarModalCrearAgencia = true;"
        >
          Nueva agencia
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.agencias.loading"
      clase-adicional="vc-75vh"
    />
    <div v-else class="mb-page">
      <!-- Tabla -->
      <ul
        data-table="sticky"
        class="table mt-3"
      >
        <li class="encabezado row">
          <div class="col-9 col-sm-4 col-md-3 pl-4 pl-lg-2">
            <span class="d-sm-none">Detalles</span>
            <span
              class="d-none d-sm-block filtrable"
              @click="ordenarColumna('nombre')"
            >
              Nombre
              <div
                v-if="orderBy.nombre != null"
                class="sort-iconos animated fadeIn"
              >
                <font-awesome-icon
                  icon="sort-up"
                  :class="{'activo': orderBy.nombre}"
                />
                <font-awesome-icon
                  icon="sort-down"
                  :class="{'activo': !orderBy.nombre}"
                />
              </div>
            </span>
          </div>
          <div class="col-5 col-md-4 d-none d-sm-block">
            Dirección
          </div>
          <div class="col-3 d-none d-md-block">
            Contacto
          </div>
          <div class="col-3 col-md-2">
            Acción
          </div>
        </li>
        <li
          v-for="agencia in agencias"
          :key="`agencia-${agencia.id}`"
          class="contenido row align-items-center"
          @click="$router.push({ name: 'Agencia', params: { id: agencia.codigo } })"
        >
          <!-- NOMBRE -->
          <div class="col-9 col-sm-4 col-md-3">
            {{ agencia.nombre }}
            <div class="d-sm-none pt-2">
              <span class="d-block">Dirección:</span>
              <span
                v-if="agencia.direccion && agencia.direccion.direccion
                  && agencia.direccion.direccion.length"
              >
                {{ agencia.direccion.direccion }}
                {{ agencia.direccion.ciudad.nombre }},
                {{ agencia.direccion.ciudad.estado.nombre }},
                {{ agencia.direccion.ciudad.estado.pais.nombre }}.
              </span>
              <span v-else class="text-muted user-select-none d-block">
                Sin especificar
              </span>
            </div>
            <div class="d-md-none pt-2">
              Contacto:
              <div v-if="agencia.telefonos && agencia.telefonos.length">
                <a
                  :href="`tel:+${agencia.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="d-block text-break"
                  @click.stop
                >
                  +{{ agencia.telefonos[0].numero }}
                </a>
                <div>
                  <a
                    :href="`https://wa.me/${agencia.telefonos[0].numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                    @click.stop
                  >
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                    WA
                  </a>
                  <a
                    :href="`sms:+${agencia.telefonos[0].numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                    @click.stop
                  >
                    <font-awesome-icon icon="sms" />
                    Sms
                  </a>
                </div>
              </div>
              <span v-else class="text-muted user-select-none d-block">
                Sin teléfonos de contacto
              </span>
            </div>
            <div class="d-md-none pt-2">
              Políticas:
              <span v-if="agencia.enlace">
                <a
                  :href="`${agencia.enlace}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="d-block text-break"
                  @click.stop
                >
                  {{ agencia.enlace }}
                </a>
              </span>
              <span v-else class="text-muted user-select-none d-block">
                Sin especificar
              </span>
            </div>
          </div>
          <!-- DIRECCIÓN -->
          <div class="col-5 col-md-4 d-none d-sm-block">
             <span v-if="agencia.direccion && agencia.direccion.direccion
              && agencia.direccion.direccion.length">
              {{ agencia.direccion.direccion }}
              {{ agencia.direccion.ciudad.nombre }},
              {{ agencia.direccion.ciudad.estado.nombre }},
              {{ agencia.direccion.ciudad.estado.pais.nombre }}.
            </span>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
          </div>
          <!-- CONTACTO -->
          <div class="col-3 d-none d-md-block">
            <div v-if="agencia.telefonos && agencia.telefonos.length">
              <a
                :href="`tel:+${agencia.telefonos[0].numero}`"
                target="_blank"
                rel="noopener noreferrer"
                class="d-block text-break"
                @click.stop
              >
                +{{ agencia.telefonos[0].numero }}
              </a>
              <div>
                <a
                  :href="`https://wa.me/${agencia.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                  @click.stop
                >
                  <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  WA
                </a>
                <a
                  :href="`sms:+${agencia.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-1 p-2"
                  @click.stop
                >
                  <font-awesome-icon icon="sms" />
                  Sms
                </a>
              </div>
            </div>
            <span v-else class="text-muted user-select-none d-block">
              Sin teléfonos de contacto
            </span>
          </div>
          <!-- ACCION -->
          <div class="col-3 col-md-2">
            <mdb-btn
              v-if="roles.includes('Admin')"
              flat
              dark-waves
              icon="trash"
              :disabled="botonDeshabilitado"
              icon-class="texto-peligro"
              class="m-0 ml-2 ml-sm-0 py-3 px-2 btn-bordeadoOnHover"
              title="Eliminar agencia"
              @click.stop.native="IdAgenciaAEliminar = agencia.id;
                mostrarConfirmacionEliminar=true;"
            />
            <mdb-btn
              v-if="roles.includes('Admin')"
              flat
              dark-waves
              icon="pen"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Editar agencia"
              @click.stop.native="agenciaSeleccionada=agencia;
                mostrarModalCrearAgencia=true; esEditar = true;"
            />
          </div>
        </li>
        <li
          v-if="!(agencias && agencias.length)"
          class="no-items"
        >
          No hay agencias registradas
        </li>
      </ul>
    </div>

    <FormularioAgencias
      :nombresDeLasAgencias="nombresDeLasAgencias"
      :mostrarModal="mostrarModalCrearAgencia"
      :datosAgencia="agenciaSeleccionada && esEditar ? agenciaSeleccionada : {}"
      :esEditar="esEditar"
      @cerrar="mostrarModalCrearAgencia = false; esEditar = false;"
      @editado="editarAgencia($event)"
      @creado="agencias.push($event)"
      @alertaMensaje="alertaMensajeAsignar($event)"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      :sustantivo-femenino="true"
      mensaje="Recibimos la orden de eliminar esta agencia, es una acción que no puede revertirse"
      @cerrar="mostrarConfirmacionEliminar = $event;"
      @eliminar="agenciaEliminar"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import FormularioAgencias from "../components/FormularioAgencias.vue";
import gql from "graphql-tag";
import agenciasGql from "@/graphql/agencias.gql";
import agenciaEliminarGql from "@/graphql/agenciaEliminar.gql";
import { leerRoles, leerAgencias } from "@/utils/datosToken.js";
import {
  mdbBtn,
} from "mdbvue";

export  default {
  name: "Agencias",
  components: {
    mdbBtn,
    FormularioAgencias,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
  },
  data() {
    return {
      alertaMensaje: {contenido: ''},
      mostrarConfirmacionEliminar: false,
      mostrarModalCrearAgencia: false,
      botonDeshabilitado: false,
      mostrarFormularioAgencia: false,
      buscarPor: {
        nombre: "",
      },
      agencias: [],
      IdAgenciaAEliminar: '',
      agenciaSeleccionada: {},
      esEditar: false,
      // Ordenar asc desc
      orderBy: {
        nombre: null,
      },
      orderByActual: [],
      roles: leerRoles(),
      agenciasIdToken:leerAgencias()
    };
  },
  computed: {
    ocultarOverflowEnVistaAgencias() {
      const ruta = this.$route.path.slice(1).split("/");
      return ruta[1] && ruta[1].length ? true : false; // Si existe ruta hija return true
    },
    nombresDeLasAgencias() {
      return this.agencias
        ? this.agencias.map((a) => (a.nombre))
        : ''
    },
    agenciasIdPorToken(){
      if(this.roles.includes('Agencia') && this.agenciasIdToken.length){
        return {
          filter:{
            id_in: this.agenciasIdToken
          }
        }
      }
      return {}
    }
  },
  methods: {
    ordenarColumna(columna){
      if(columna == 'nombre'){
        this.orderBy.nombre === null
          ? this.orderBy.nombre = false  // se ordena descendente
          : this.orderBy.nombre
            ? this.orderBy.nombre = false   
            : this.orderBy.nombre = true  // se ordena ascendente
        if(this.orderBy.nombre) {
          this.orderByActual = ['nombre_asc']
          return this.agenciasActualizar()
        } else {
          this.orderByActual = ['nombre_desc']
          return this.agenciasActualizar()
        }
      }
    },
    editarAgencia(agenciaConFormato){
      this.agencias.map(agencia => {
          if(agencia.id === this.agenciaSeleccionada.id){
            Object.assign(agencia, agenciaConFormato)
          }
      });
    },
    alertaMensajeAsignar(contenido) {
      if(contenido){
        return this.alertaMensaje = {
          contenido: contenido.contenido,
          tipo: contenido.tipo,
        };
      }
    },
    agenciasActualizar() {
      const variablesQuery = {};
      this.orderByActual && this.orderByActual.length
        ? Object.assign(variablesQuery,{ 
            orderBy: this.orderByActual
          })
        : ''
        if(this.roles.includes('Agencia') && this.agenciasIdToken.length){
          Object.assign(variablesQuery, {
            filter:{
              id_in: this.agenciasIdToken
            }
          }) 
        }
      // Se utiliza apollo.watchQuery, debido a cache-and-network
      this.$apollo
        .watchQuery({
            query: gql`
              ${agenciasGql}
            `,
            variables: variablesQuery,
            update: (data) => data.Agencia,
            fetchPolicy: "cache-and-network",
          })
          .subscribe({
            next: ({data}) => {
              if(data && data.Agencia){
                this.agencias = data.Agencia;
              }
              this.botonDeshabilitado = false;
            },
            error: () => {
             this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando las agencias. Por favor intenta de nuevo',
                tipo: 'error',
              };
              this.botonDeshabilitado = false;
            }
          })
    },
    agenciaEliminar() {
      const idEliminar = this.IdAgenciaAEliminar
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: gql`${agenciaEliminarGql}`,
        variables: {
          agenciaId: idEliminar,
        },
        update: (store,{data:{agenciaEliminar:{codigo}}}) => {
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha eliminado la agencia satisfactoriamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando la agencia. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
            default:
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                tipo: 'error',
              };
              return;
          }
          const {Agencia:AgenciaStore} = store.readQuery({
            query: agenciasGql,
            variables:{}
          });
          const Agencia = AgenciaStore.filter(({id}) => id !== idEliminar);
          store.writeQuery({
            query: agenciasGql,
            variables: {},
            data:{
              Agencia,
            }
          });
          if(!this.botonDeshabilitado){
            this.mostrarConfirmacionEliminar = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            agenciaEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(() => {
        this.alertaMensaje = {
          contenido: 'Ha ocurrido un error eliminando la agencia. Por favor intenta de nuevo',
          tipo: 'error',
        }
        this.botonDeshabilitado = false;
      })
    }
  },
  apollo: {
    agencias() {
      return {
        query: agenciasGql,
        update: (data) => data.Agencia,
        variables:this.agenciasIdPorToken,
        fetchPolicy: "cache-and-network",
      };
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <mdb-modal centered elegant :show="mostrarModalMedios" @close="cerrarModal()">
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row justify-content-center" @submit.prevent>
        <div
          :class="[
            'col-12',
            { campo: validarValores.nombre && validarValores.nombre.length },
            { valido: validarValores.nombre === 'valido' },
            { invalido: validarValores.nombre === 'invalido' },
          ]"
        >
          <mdb-input
            v-model.trim="medio.nombre"
            type="text"
            label="Nombre"
            outline
            class="my-2"
          />
          <p
            v-if="validarValores.nombre === 'invalido'"
            class="mensaje-invalido date"
          >
            Indica el nombre del medio
          </p>
        </div>
        <div
          :class="[
            'col-12',
            {
              campo:
                validarValores.descripcion && validarValores.descripcion.length,
            },
            { valido: validarValores.descripcion === 'valido' },
            { invalido: validarValores.descripcion === 'invalido' },
          ]"
        >
          <mdb-input
            v-model.trim="medio.descripcion"
            type="textarea"
            :rows="3"
            label="Descripción"
            class="my-2"
            outline
          />
          <p
            v-if="validarValores.descripcion === 'invalido'"
            class="mensaje-invalido textarea"
          >
            Indica la descripcion
          </p>
        </div>
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto mt-4"
          @click="submitPago"
        >
          {{ botonPrincipal.texto }}
        </mdb-btn>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { capitalizar } from "@/funciones/funciones.js";
import medioEditarGql from "@/graphql/medioEditar.gql";
import mediosGql from "@/graphql/medios.gql";
import gql from "graphql-tag";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
export default {
  name: "FormularioMedios",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
  },
  props: {
    mostrarModalMedios: {
      type: Boolean,
      required: true,
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    titulo: {
      type: String,
      required: true,
    },
    medioAsignar: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      medio: {
        nombre: "",
        descripcion: "",
      },
      validarValores: {},
    };
  },
  watch: {
    esEditar: function () {
      this.esEditar &&
        Object.assign(
          this.medio,
          JSON.parse(JSON.stringify(this.medioAsignar))
        );
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("editar", false);
      this.$emit("cerrar", false);
      this.limpiarCamposForm();
    },
    limpiarCamposForm() {
      this.botonDeshabilitado = false;
      (this.medio = {
        nombre: "",
        descripcion: "",
      }),
        (this.validarValores = {});
    },
    submitPago() {
      this.validarValores = {
        nombre: this.medio.nombre ? "valido" : "invalido",
        descripcion: this.medio.descripcion ? "valido" : "invalido",
      };
      if (Object.values(this.validarValores).includes("invalido")) {
        this.$emit("alerta", {
          contenido: "Todos los campos son requeridos",
          tipo: "error",
        });
        this.botonDeshabilitado = false;
        return;
      }
      this.medio.nombre = capitalizar(this.medio.nombre);
      if (this.esEditar) {
        const medioEditar = { ...this.medio };
        delete medioEditar.__typename;
        return this.medioEditar(medioEditar);
      }
      this.$emit("crear", this.medio);
      this.limpiarCamposForm();
      this.cerrarModal();
    },
    medioEditar(medio) {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${medioEditarGql}
          `,
          variables: {
            medio,
          },
          update: (
            cache,
            {
              data: {
                medioEditar: { codigo },
              },
            }
          ) => {
            const data = cache.readQuery({ query: mediosGql });
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje(
                  "Medio de pago actualizado correctamente",
                  "correcto"
                );
                break;
              case "Fallido":
                this.botonDeshabilitado = false;
                return this.alertaMensaje(
                  "No se pudo actualizar el medio de pago",
                  "error"
                );
              default:
                this.botonDeshabilitado = false;
                return this.alertaMensaje(
                  "Ocurrió un error inesperado",
                  "error"
                );
            }
            data.Medio.filter(({ id }) => id === medio.id).map(
              (medioEditar) => {
                medioEditar.nombre = medio.nombre;
                medioEditar.descripcion = medio.descripcion;
              }
            );
            cache.writeQuery({
              query: mediosGql,
              data,
            });
            if (!this.botonDeshabilitado) {
              this.limpiarCamposForm();
              this.$emit("editado");
              this.$emit("cerrar", false);
              this.cerrarModal();
            }
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            medioEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje(
            "Ha ocurrido un error actualizando el medio",
            "error"
          );
        });
    },
    alertaMensaje(contenido, tipo) {
      this.$emit("alerta", {
        contenido,
        tipo,
      });
    },
  },
  computed: {
    botonPrincipal() {
      return this.esEditar
        ? { icono: "sync", texto: "Actualizar" }
        : { icono: "check", texto: "Agregar" };
    },
  },
};
</script>
// Exporta los roles del usuario
const UsuarioRoles = {
  Admin: 'Administrador',
  Agencia: 'Agencia',
  OpAlmacen: 'Operador de almacén',
  OpPuerto: 'Operador de puerto',
};

// Exporta los generos de los usuarios
const UsuarioGeneros = {
  Masculino: 'Masculino',
  Femenino: 'Femenino',
  Otro: 'Otro',
};

export {
  UsuarioRoles,
  UsuarioGeneros,
};
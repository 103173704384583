<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar')"
  >
    <mdb-modal-header>
      <mdb-modal-title>Colores empresariales</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row mx-0" @submit.prevent>
        <div class="col-3 pl-0 d-none d-lg-block">
          <small class="text-muted user-select-none">
            Vista previa del menú
          </small>

          <div
            class="container-preview encabezado"
            :style="`--fondo-barra: ${colores.fondoBarra};`"
          >
            <label
              for="btnTerciarioFondo"
              class="bg-preview"
              :style="`--terciario: ${colores.terciario};`"
            />
            <label
              for="textoMenuEncabezado"
              class="text-preview mt-3"
              :style="`--texto-encabezado-barra: ${colores.textoEncabezadoBarra};`"
            >
              Encabezado
            </label>
            <br>
            <label
              for="textoMenuEncabezado"
              class="text-preview mb-1"
              :style="`--texto-encabezado-barra: ${colores.textoEncabezadoBarra};`"
            >
              System
            </label>
          </div>
          <div class="container-preview menu">
            <label
              for="fondoMenu"
              class="bg-preview"
              :style="`--fondo-barra: ${colores.fondoBarra};`"
            />
            <label
              for="textoMenu"
              class="text-preview"
              title="Enlace del menú"
              :style="`--texto-barra: ${colores.textoBarra};`"
            >
              <font-awesome-icon icon="boxes" />
              Paquetes
            </label>
            <br>
            <label
              for="textoMenu"
              class="text-preview"
              title="Enlace del menú"
              :style="`--texto-barra: ${colores.textoBarra};`"
            >
              <font-awesome-icon :icon="['fab', 'trello']" />
              Órdenes
            </label>
            <br>
            <label
              for="btnPrimarioFondo"
              class="text-preview activo"
              title="Enlace activo del menú se resalta con el color primario"
              :style="`--primario: ${colores.primario};`"
            >
              <font-awesome-icon icon="file-invoice-dollar" />
              Pagos
            </label>
          </div>
          <div class="container-preview informativo">
            <label
              for="fondoMenu"
              class="bg-preview"
              :style="`--fondo-barra: ${colores.fondoBarra};`"
            />
            <label
              for="textoMenuInformativo"
              class="text-preview mb-1 mt-3"
              title="Información sobre la versión del sistema"
              :style="`--texto-informativo: ${colores.textoInformativo};`"
            >
              <i class="fas fa-user-circle"></i>
              Usuario
              <br>
              usuario@mail.com
            </label>
            <br>
            <label
              for="textoMenuInformativo"
              class="text-preview mb-2"
              title="Información sobre la versión del sistema"
              :style="`--texto-informativo: ${colores.textoInformativo};`"
            >
              ©
              <span class="font-weight-bold fs-inherit letter-spacing-1">
                Global Trackit System
              </span>
              <br>
              Versión 03/05/2022 15:23
            </label>
          </div>
        </div>
        <div class="col-12 col-lg px-0">
          <header class="h5">Botones</header>
          <header class="h6 mb-sm-n3">Primario</header>
          <div class="row align-items-end">
            <div class="col-6 col-sm pr-2">
              <mdb-input
                v-model="colores.primario"
                id="btnPrimarioFondo"
                type="color"
                class="my-2 outline-color"
                title="Color de fondo "
                outline
              >
                <label for="btnPrimarioFondo" class="active mr-5">Fondo</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm pr-2">
              <mdb-input
                v-model="colores.primarioTinte"
                id="btnPrimarioVariante"
                type="color"
                class="my-2 outline-color"
                title="Variante del color de fondo cuando el cursor está sobre el botón, suele ser un tono más claro o oscuro que el fondo"
                outline
              >
                <label for="btnPrimarioVariante" class="active mr-5">Variante</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm px-2">
              <mdb-input
                v-model="colores.primarioBtnTexto"
                id="btnPrimarioTexto"
                type="color"
                class="my-2 outline-color"
                title="Color de texto"
                outline
              >
                <label for="btnPrimarioTexto" class="active mr-5">Texto</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm-4 col-lg-3">
              <small class="text-muted user-select-none">
                Vista previa
              </small>
              <div class="btn-preview primario">
                <label
                  for="btnPrimarioFondo"
                  class="bg-preview"
                  :style="`
                    --primario: ${colores.primario};
                    --primario-tinte: ${colores.primarioTinte};
                  `"
                />
                <label
                  for="btnPrimarioTexto"
                  class="text-preview"
                  :style="`--primario-btn-texto: ${colores.primarioBtnTexto};`"
                >
                  Texto
                </label>
              </div>
            </div>
          </div>
          <hr>
          <header class="h6 mb-sm-n3">Secundario</header>
          <div class="row align-items-end">
            <div class="col-6 col-sm pr-2">
              <mdb-input
                v-model="colores.terciario"
                id="btnTerciarioFondo"
                type="color"
                class="my-2 outline-color"
                title="Color de fondo "
                outline
              >
                <label for="btnTerciarioFondo" class="active mr-5">Fondo</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm pr-2">
              <mdb-input
                v-model="colores.terciarioTinte"
                id="btnTerciarioVariante"
                type="color"
                class="my-2 outline-color"
                title="Variante del color de fondo cuando el cursor está sobre el botón, suele ser un tono más claro o oscuro que el fondo"
                outline
              >
                <label for="btnTerciarioVariante" class="active mr-5">Variante</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm px-2">
              <mdb-input
                v-model="colores.terciarioBtnTexto"
                id="btnTerciarioTexto"
                type="color"
                class="my-2 outline-color"
                title="Color de texto"
                outline
              >
                <label for="btnTerciarioTexto" class="active mr-5">Texto</label>
              </mdb-input>
            </div>
            <div class="col-6 col-sm-4 col-lg-3">
              <small class="text-muted user-select-none">
                Vista previa
              </small>
              <div class="btn-preview terciario">
                <label
                  for="btnTerciarioFondo"
                  class="bg-preview"
                  :style="`
                    --terciario: ${colores.terciario};
                    --terciario-tinte: ${colores.terciarioTinte};
                  `"
                />
                <label
                  for="btnTerciarioTexto"
                  class="text-preview"
                  :style="`--terciario-btn-texto: ${colores.terciarioBtnTexto};`"
                >
                  Texto
                </label>
              </div>
            </div>
          </div>
          <header class="h5">Menú</header>
          <div class="row align-items-start">
            <div class="col-12 col-sm-3">
              <header class="h6">Encabezado</header>
              <mdb-input
                v-model="colores.textoEncabezadoBarra"
                id="textoMenuEncabezado"
                type="color"
                class="mb-2 mt-3 outline-color"
                title="Color de fondo "
                outline
              >
                <label for="textoMenuEncabezado" class="active mr-5">Texto</label>
              </mdb-input>
              <div class="d-lg-none">
                <small class="text-muted user-select-none">
                  Vista previa
                </small>
                <div
                  class="container-preview encabezado"
                  :style="`--fondo-barra: ${colores.fondoBarra};`"
                >
                  <label
                    for="btnTerciarioFondo"
                    class="bg-preview"
                    :style="`--terciario: ${colores.terciario};`"
                  />
                  <label
                    for="textoMenuEncabezado"
                    class="text-preview mt-3"
                    :style="`--texto-encabezado-barra: ${colores.textoEncabezadoBarra};`"
                  >
                    Encabezado
                  </label>
                  <br>
                  <label
                    for="textoMenuEncabezado"
                    class="text-preview mb-1"
                    :style="`--texto-encabezado-barra: ${colores.textoEncabezadoBarra};`"
                  >
                    System
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm">
              <header class="h6 mt-4 mt-sm-0">Enlaces</header>
              <div class="row">
                <div class="col">
                  <mdb-input
                    v-model="colores.fondoBarra"
                    id="fondoMenu"
                    type="color"
                    class="my-2 outline-color"
                    title="Color de fondo "
                    outline
                  >
                    <label for="fondoMenu" class="active mr-5">Fondo</label>
                  </mdb-input>
                </div>
                <div class="col">
                  <mdb-input
                    v-model="colores.textoBarra"
                    id="textoMenu"
                    type="color"
                    class="my-2 outline-color"
                    title="Color de fondo "
                    outline
                  >
                    <label for="textoMenu" class="active mr-5">Texto</label>
                  </mdb-input>
                </div>
              </div>
              <div class="d-lg-none">
                <small class="text-muted user-select-none">
                  Vista previa
                </small>
                <div class="container-preview menu">
                  <label
                    for="fondoMenu"
                    class="bg-preview"
                    :style="`--fondo-barra: ${colores.fondoBarra};`"
                  />
                  <label
                    for="textoMenu"
                    class="text-preview"
                    title="Enlace del menú"
                    :style="`--texto-barra: ${colores.textoBarra};`"
                  >
                    <font-awesome-icon icon="boxes" />
                    Paquetes
                  </label>
                  <br>
                  <label
                    for="textoMenu"
                    class="text-preview"
                    title="Enlace del menú"
                    :style="`--texto-barra: ${colores.textoBarra};`"
                  >
                    <font-awesome-icon :icon="['fab', 'trello']" />
                    Órdenes
                  </label>
                  <br>
                  <label
                    for="btnPrimarioFondo"
                    class="text-preview activo"
                    title="Enlace activo del menú se resalta con el color primario"
                    :style="`--primario: ${colores.primario};`"
                  >
                    <font-awesome-icon icon="file-invoice-dollar" />
                    Pagos
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm">
              <header class="h6 mt-4 mt-sm-0">Texto informativo</header>
              <mdb-input
                v-model="colores.textoInformativo"
                id="textoMenuInformativo"
                type="color"
                class="mb-2 mt-3 outline-color"
                title="Color de fondo "
                outline
              >
                <label for="textoMenuInformativo" class="active mr-5">Texto</label>
              </mdb-input>
              <div class="d-lg-none">
                <small class="text-muted user-select-none">
                  Vista previa
                </small>
                <div class="container-preview informativo">
                  <label
                    for="fondoMenu"
                    class="bg-preview"
                    :style="`--fondo-barra: ${colores.fondoBarra};`"
                  />
                  <label
                    for="textoMenuInformativo"
                    class="text-preview mb-1 mt-3"
                    title="Información sobre la versión del sistema"
                    :style="`--texto-informativo: ${colores.textoInformativo};`"
                  >
                    <i class="fas fa-user-circle"></i>
                    Usuario
                    <br>
                    usuario@mail.com
                  </label>
                  <br>
                  <label
                    for="textoMenuInformativo"
                    class="text-preview mb-2"
                    title="Información sobre la versión del sistema"
                    :style="`--texto-informativo: ${colores.textoInformativo};`"
                  >
                    ©
                    <span class="font-weight-bold fs-inherit letter-spacing-1">
                      Global Trackit System
                    </span>
                    <br>
                    Versión 03/05/2022 15:23
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-center">
      <mdb-btn
        color="primario"
        :disabled="botonDeshabilitado"
        :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        @click="asignarColores()"
      >
        Establecer
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbInput,
} from 'mdbvue';
import gql from "graphql-tag";
import agenciaAsignarColoresEmpresariales from "@/graphql/agenciaAsignarColoresEmpresariales.gql";

export default {
  name: "ModalColoresEmpresariales",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    agenciaId: {
      type: String,
      required: true,
    },
    coloresEmpresariales: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      botonDeshabilitado: false,
      colores: {}
    };
  },
  watch: {
    mostrarModal: function () {
      if(this.mostrarModal) {
        if(Object.keys(this.coloresEmpresariales).length) {
          this.colores = JSON.parse(JSON.stringify(this.coloresEmpresariales));
        } else {
          // Colores iniciales de la agencia
          this.colores = {
            primario: '#FCC626',
            primarioTinte: '#FDD45C',
            primarioBtnTexto: '#071A6A',
            terciario: '#071A6A',
            terciarioTinte: '#26377D',
            terciarioBtnTexto: '#FFFFFF',
            textoEncabezadoBarra: '#FFFFFF',
            textoBarra: '#212427',
            fondoBarra: '#F6F6F9',
            textoInformativo: '#6c757d'
          }
        }
      } else {
        this.limpiarCampos();
      }
    },
  },
  methods: {
    asignarColores () {
      // Se elimina la reactividad y el key __typename
      const coloresVariables = JSON.parse(JSON.stringify(this.colores));
      delete coloresVariables.__typename;


      // Se validan los campos del form
      const valoresColores = Object.values(coloresVariables)
      const coloresInvalidos = valoresColores.map((color) => (color.charAt(0) === '#' && color.length === 7))

      // Verifica que todos los campos tengan valor
      if (valoresColores.includes('')) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Todos los colores son requeridos',
          tipo: 'advertencia'
        })
      }

      // Verifica que los colores sean válidos
      if (coloresInvalidos.includes(false)) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Has seleccionado colores no permitidos',
          tipo: 'error'
        })
      }

      this.botonDeshabilitado = true;
      // se declara la mutate
      this.$apollo.mutate({
        mutation: gql`
          ${agenciaAsignarColoresEmpresariales}
        `,
        variables: {
          agenciaId: this.agenciaId,
          colores:coloresVariables
        },
      }).then(({ data: { asignarColoresEmpresariales } }) => {
        switch (asignarColoresEmpresariales.codigo) {
          case "Correcto":
            this.$emit("alerta-mensaje", {
              contenido: 'Los colores de la agencia se establecieron exitosamente',
              tipo: 'correcto'
            })
            this.$emit('actualizarColores')
            break;
          case "Fallido":
            this.$emit("alerta-mensaje", {
              contenido: 'Ocurrió un error estableciendo los colores de la agencia',
              tipo: 'error'
            })
            break;
          default:
            this.$emit("alerta-mensaje", {
              contenido: 'Ha ocurrido un error inesperado',
              tipo: 'error'
            })
            break;
        }
        this.botonDeshabilitado = false;
        this.limpiarCampos();
        this.$emit('cerrar');
      })
      .catch((e) => {
        this.botonDeshabilitado = false;
        this.$emit("alerta-mensaje", {
          contenido: `Ha ocurrido un error: ${e}`,
          tipo: 'error'
        })
      })
    },
    limpiarCampos() {
      this.colores = {
        primario: '',
        primarioTinte: '',
        primarioBtnTexto: '',

        terciario: '',
        terciarioTinte: '',
        terciarioBtnTexto: '',

        fondoBarra: '',
        textoEncabezadoBarra: '',
        textoBarra: '',
        textoInformativo: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-preview,
  .container-preview {
    /* Primario */
    --primario: #FCC626;
    --primario-tinte: #FDD45C;
    --primario-btn-texto: #071A6A;

    /* Terciario */
    --terciario: #071A6A;
    --terciario-tinte: #26377D;
    --terciario-btn-texto: #FFFFFF;

    /* Barra lateral */
    --fondo-barra: #F6F6F9;
    --texto-encabezado-barra: #FFFFFF;
    --texto-barra: #212427;
    --texto-informativo: #6c757d;

    position: relative;

    .bg-preview {
      cursor: pointer;
      margin-bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 0;
    }
    .text-preview {
      cursor: pointer;
      margin-bottom: 0;
      position: relative;
    }
  }

  .btn-preview {
    padding: .15rem .25rem .5rem;
    text-align: center;

    .bg-preview {
      border-radius: .125rem;
      height: 40px;
    }
    .text-preview {
      font-size: .81rem;
      font-weight: 400;
      line-height: 40px;
      text-transform: uppercase;
    }


    &.primario {
      .bg-preview {
        background-color: var(--primario);

        &:hover {
          background-color: var(--primario-tinte);
        }
      }
      .text-preview {
        color: var(--primario-btn-texto);
      }
    }
    &.terciario {
      .bg-preview {
        background-color: var(--terciario);

        &:hover {
          background-color: var(--terciario-tinte);
        }
      }
      .text-preview {
        color: var(--terciario-btn-texto);
      }
    }
  }

  .container-preview {
    max-width: 220px;

    .bg-preview {
      height: 100%;
    }
    .text-preview {
      font-size: .8rem;

      &.activo {
        font-weight: 700;
      }
    }

    &.encabezado,
    &.menu .bg-preview,
    &.informativo .bg-preview {
      background-color: var(--fondo-barra);
    }


    &.encabezado,
    &.informativo {
      text-align: center;
    }

    &.encabezado {
      padding-bottom: 2rem;

      .bg-preview {
        background-color: var(--terciario);
        height: 74%;
        border-radius: 0 0 6px 0;

        &::before {
          background: var(--terciario);
          border-radius: 0 0 0 30px;
          bottom: -15px;
          content: "";
          height: 34px;
          left: 1px;
          position: absolute;
          transform: rotate(-8deg);
          width: 97%;
          z-index: 0;

          @media (min-width: 576px) and (max-width: 991px) {
            bottom: -17px;
            left: 3px;
            transform: rotate(-22deg);
            width: 88%;
          }

          @media (min-width: 992px) {
            transform: rotate(-10deg);
          }
        }
      }
      .text-preview {
        color: var(--texto-encabezado-barra);
      }
    }

    &.menu .text-preview {
      color: var(--texto-barra);
      margin: 1rem 0 1rem 1rem;

      @media (min-width: 992px) {
        margin: .5rem 0 .5rem 1rem;
      }

      &.activo {
        color: var(--primario);
        font-weight: 700;
      }
    }


    &.informativo .text-preview {
      color: var(--texto-informativo);
    }
  }
</style>
<template>
  <section>
    <!-- Modal Entregar paquete -->
    <mdb-modal
      :show="mostrarModal"
      centered
      elegant
      scrollable
      @close="limpiarCampos(); $emit('close', false);"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          {{ !esFoto
            ? 'Entregar paquete'+`${paquetesId.length > 1 ? 's' : ''}`
            : 'Subir comprobante'
          }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-input
          v-if="!esFoto"
          v-model.trim="paquete.observacion"
          type="textarea"
          :rows="3"
          label="Observación (opcional)"
          class="mb-2 mt-n2"
          outline
        />
        <!-- En caso que se entregue solo un paquete se puede subir la imagen al mísmo tiempo -->
        <div
          v-if="paquetesId.length === 1 || esFoto"
          :class="{'mt-n4': esFoto}"
        >
          <header class="font-weight-bold mt-3 mb-1">Comprobante</header>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="outline-input-file" data-size="sm">
                <label for="input-file-images" class="label-file">
                  <font-awesome-icon icon="upload" class="mr-1" />
                  Elegir fotos
                </label>
                <span class="contenido">
                  {{
                    fotos && fotos.length
                    ? fotos.length > 1
                      ?  fotos.length + ' archivos'
                      : fotos.length + ' archivo'
                    : 'No se eligió archivo'
                  }}
                </span>
                <input
                  id="input-file-images"
                  type="file"
                  multiple
                  accept="image/*"
                  class="custom-file-input"
                  @change="handleInputChange"
                />
                <mdb-btn
                  v-if="fotos.length > 0"
                  flat
                  dark-waves
                  icon="times"
                  class="file-button m-0 p-2"
                  title="Eliminar fotos seleccionadas"
                  @click="fotos=[]; size=0;"
                />
              </div>
            </div>
            <span
              v-if="size"
              class="text-center text-muted"
            >
              Archivos adjuntados: {{ size.toFixed(2) }} / {{ maxSize }}Mb
            </span>
          </div>
        </div>
        <div v-if="!esFoto">
          <h5 class="text-center mt-3">
            Requisitos para la entrega
            {{ paquetes.length === 1
              ? "del paquete"
              : "de los paquetes"
            }}
          </h5>
          <!-- Resultado de computed que evalua los paquetes para asignar un consolidado -->
          <p class="mb-2">
            <font-awesome-icon
              :icon="textoValidadorDePaquetesAEntregar.cliente ? 'check-circle' : 'times-circle'"
              :class="textoValidadorDePaquetesAEntregar.cliente ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ textoValidadorDePaquetesAEntregar.cliente ? "Si" : "No" }}
            tiene cliente asignado
          </p>
          <p class="mb-2">
            <font-awesome-icon
              :icon="textoValidadorDePaquetesAEntregar.pagado ? 'check-circle' : 'times-circle'"
              :class="textoValidadorDePaquetesAEntregar.pagado ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ textoValidadorDePaquetesAEntregar.pagado ? "Si" : "No" }}
            se encuentra pagado
          </p>
          <p class="mb-2">
            <font-awesome-icon
              :icon="textoValidadorDePaquetesAEntregar.estatus ? 'check-circle' : 'times-circle'"
              :class="textoValidadorDePaquetesAEntregar.estatus ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ textoValidadorDePaquetesAEntregar.estatus ? "Si" : "No" }}
            es entregable por su estatus.
          </p>
          <p class="mb-2">
            <font-awesome-icon
              :icon="textoValidadorDePaquetesAEntregar.direccionDestino ? 'check-circle' : 'times-circle'"
              :class="textoValidadorDePaquetesAEntregar.direccionDestino ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ textoValidadorDePaquetesAEntregar.direccionDestino ? "Si" : "No" }}
            cuenta con dirección destino
          </p>
        </div>
        <div v-else>
          <div class="text-center mt-2">
            <mdb-btn
              flat
              dark-waves
              icon="images"
              class="col-auto btn-bordeado"
              @click="mostrarFotos = !mostrarFotos; obtenerFotosPaquete();"
            >
              Ver fotos
            </mdb-btn>
          </div>
          <div v-if="mostrarFotos">
            <div v-if="fotosObtenidas && fotosObtenidas.length">
                 <CargandoVista
                  v-if="esEliminarFotos"
                  clase-adicional="vc-50vh"
                />
              <ul v-else class="listado-fotos mt-3">
                <li
                  v-for="(foto, iFoto) in fotosObtenidas"
                  :key="iFoto"
                  class="foto-contenedor"
                >
                  <div class="foto">
                    <img
                      v-lazy="`${endpointPublicBackend}${foto.url}`"
                      :alt="`Imagen nro ${iFoto + 1} del paquete`"
                      style="height: 100px"
                      @click="abrirGaleriaEn(iFoto)"
                      >
                  </div>
                  <label
                    v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
                    class="boton-eliminar checkbox-grande"
                    title="Seleccionar foto para eliminar del paquete"
                  >
                    <input
                      v-model="fotosEliminar"
                      :value="foto.url"
                      type="checkbox"
                      name="fotos-eliminar">
                    <span class="checkbox" />
                  </label>
                </li>
              </ul>
              <div
                v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
                class="row justify-content-center mt-3">
                <mdb-btn
                  flat
                  dark-waves
                  :icon="botonDeshabilitado ? 'circle-notch' : 'check-square'"
                  :icon-class="[{'texto-terciario': fotosEliminar.length},
                    {'fa-spin': botonDeshabilitado}]"
                  :disabled="botonDeshabilitado"
                  class="btn-bordeado col-auto px-3"
                  @click="seleccionarTodo"
                >
                  {{textoBotonSeleccionar}}
                </mdb-btn>
                <mdb-btn
                  flat
                  dark-waves
                  :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
                  :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
                  :disabled="botonDeshabilitado || !fotosEliminar.length"
                  class="btn-bordeado col-auto px-3"
                  @click="eliminarFotos"
                >
                  Eliminar fotos
                </mdb-btn>
              </div>
            </div>
            <div
              v-else
              class="vertical-centered vc-160px user-select-none"
            >
              <font-awesome-icon
                :icon="['fas', 'images']"
                class="fa-5x my-4"
              />
              <p class="h4-responsive text-center mb-4">
                No hay comprobantes del paquete
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <!-- Boton cuando el paquete ha sido entregado y solo se suben fotos -->
          <div
            v-if="esFoto"
            :class="['col-auto mt-4 px-0',
            {'cursor-not-allowed': botonDeshabilitado || !fotos.length}
            ]"
            :title="!fotos.length
              ? 'Debes elegir al menos 1 foto para agregar'
              : 'Haz clic para agregar'"
          >
            <mdb-btn
              color="primario"
              :icon="botonDeshabilitado
                ? 'circle-notch' : 'check'"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado || !fotos.length"
              @click="agregarFotos"
            >
              Agregar
            </mdb-btn>
          </div>
          <!-- Caso contrario -->
          <div
            v-else
            :class="['col-auto mt-4 px-0',
              {'cursor-not-allowed': botonDeshabilitado
                || !textoValidadorDePaquetesAEntregar.activarBoton}
            ]"
            :title="!textoValidadorDePaquetesAEntregar.activarBoton
              ? 'El paquete debe cumplir con todos los requisitos solicitados'
              : 'Haz clic para confirmar la entrega'"
          >
            <mdb-btn
              color="primario"
              :icon="botonDeshabilitado
                ? 'circle-notch' : 'check'"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado
                || !textoValidadorDePaquetesAEntregar.activarBoton"
              @click="entregarPaquetes"
            >
              Entregar
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <LightBox
      v-if="mostrarFotos"
      ref="lightbox"
      :media="fotosDelPaquete"
      :show-light-box="false"
    />
     <ModalEditarImagen 
      titulo="Editar foto de entrega del paquete"
      :mostrar-modal="mostrarModalEditarFoto"
      :imagen="imagenSeleccionada"
      :imgSrc="imgSrc"
      @alerta="alertaMensaje($event.contenido, $event.tipo)"
      @imagenModificada="imagenAgregar($event)"
      @cerrar="mostrarModalEditarFoto = $event; imagenSeleccionada=null; imgSrc=''"
    />
    <ConfirmacionEliminar 
      :mostrar-modal="mostrarModalConfirmarEditarImagen"
      :cancelarMensaje="true"
      mensaje="¿Quieres editar la imagen seleccionada?"
      @cerrar="mostrarModalConfirmarEditarImagen = $event;imagenAgregar(imagenSeleccionada)"
      @eliminar="leerImagenEditar(imagenSeleccionada);
        mostrarModalConfirmarEditarImagen=false"
    />
  </section>
</template>

<script>
import agenciaGql from "@/graphql/agencia.gql";
import fotoCrearGql from "@/graphql/fotoCrear.gql";
import gql from "graphql-tag";
import paqueteEntregarDestinoGql from "@/graphql/paqueteEntregarDestino.gql";
import fotoEliminarGql from "@/graphql/fotoEliminar.gql";
import paqueteObtenerComprobanteGql from "@/graphql/paqueteObtenerComprobante.gql";
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import LightBox from 'vue-image-lightbox';
import {endpointPublicBackend} from "@/constantes/paquetes.js"
import CargandoVista from "@/components/CargandoVista.vue";
import ModalEditarImagen from "@/components/ModalEditarImagen.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import { leerRoles } from "@/utils/datosToken.js";
import { regExpFoto } from "@/constantes/paquetes.js";
import {
  mdbBtn,
  mdbModal,
  mdbInput,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: 'ModalEntregarPaquete',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    CargandoVista,
    LightBox,
    ModalEditarImagen,
    ConfirmacionEliminar
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esFoto: {
      type: Boolean,
      required: false,
    },
    paquetesId: {
      type: Array,
      required: true,
    },
    paquetes: {
      type: Array,
      required: true,
    },
    variablesQueryAgencia: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      rol: leerRoles(),
      botonDeshabilitado: false,
      // fotos
      mostrarFotos: false,
      esEliminarFotos:false,
      fotos: [],
      fotosObtenidas: [],
      fotosEliminar: [],
      // otros
      paquete:{
        observacion: '',
      },
      endpointPublicBackend,
      mostrarModalEditarFoto:false,
      imagenSeleccionada:null,
      mostrarModalConfirmarEditarImagen:false,
      imgSrc:'',
      maxSize:18,
      size:0
    };
  },
  watch: {
    mostrarModal: function() {
      if(!this.mostrarModal) {
        this.limpiarCampos();
      }
    },
  },
  computed: {
    textoBotonSeleccionar(){
       const fotosDisponibles = this.fotosObtenidas.length
      const fotosSeleccionadas = this.fotosEliminar.length
      return fotosDisponibles === fotosSeleccionadas
        ? "Deseleccionar todo"
        : "Seleccionar todo";

    },
    fotosDelPaquete() {
      if (this.fotosObtenidas.length > 0) {
        const endpoint = this.endpointPublicBackend;
        return this.fotosObtenidas.map((foto)=> 
            ({
            thumb: `${endpoint}${foto.url}`,
            src: `${endpoint}${foto.url}`,
          })
        );
      }
      return [""]
    },
    textoValidadorDePaquetesAEntregar() {
      if(this.mostrarModal){
        const validarValores = {
          cliente: true,
          pagado: true,
          direccionDestino: true,
          estatus: true,
          activarBoton: true
        }
        this.paquetes.forEach((p) => {
          if(!p.cliente){
            validarValores.cliente = false
          }
          if(!p.pagado){
            validarValores.pagado = false
          }
          if(!p.direccionDestino){
            validarValores.direccionDestino = false
            validarValores.estatus = false
          }
          // Validar si no se encuentra en estatus. (ListoDespacho o TransitoNacional)
          if(p.estatus != 'ListoDespacho' && p.estatus != 'TransitoNacional'){
            validarValores.estatus = false
          }
        })
        // Se evalua si se activará el botón de asignar consolidado
        if (Object.values(validarValores).includes(false)) {
            validarValores.activarBoton = false;
        }
        return validarValores
      } else { return  ({}) }
    },
  },
  methods: {
    seleccionarTodo(){
      const fotosDisponibles = this.fotosObtenidas.length
      const fotosSeleccionadas = this.fotosEliminar.length
      const estanSeleccionasTodas = fotosDisponibles === fotosSeleccionadas
      if(estanSeleccionasTodas){
        this.fotosEliminar = []
        return
      }
      this.fotosEliminar = this.fotosObtenidas.map(foto => foto.url)
    },
    leerImagenEditar(imagenSeleccionada){
       if (typeof FileReader === "function") {
         const reader = new FileReader();
         reader.onload = (event) => {
           this.imgSrc = event.target.result;
           this.mostrarModalEditarFoto = true;
         };
         reader.readAsDataURL(imagenSeleccionada);
       } else {
          this.alertaMensaje(
           "No se puede editar la imagen, actualiza tu navegador",
           "advertencia",
        );
       }
    },
    imagenAgregar(archivo){
      const sizeFile = Number((archivo.size / 1024 / 1024).toFixed(2));
       if (sizeFile > this.maxSize) {
          return this.alertaMensaje(
           `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
           "error"
       )}
      if ((this.size + sizeFile) > this.maxSize) {
          return this.alertaMensaje(
            `La imagen modificada supera el límite de 18MB, no se puede agregar. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
           "error"
          );
        }
        this.size += Number(sizeFile);
        this.fotos.push(archivo)
    },
    handleInputChange({ target }) {
      if (!target.files.length) return;
       if (target.files.length === 1 && regExpFoto.test(target.files[0].name)) {
        this.imagenSeleccionada = target.files[0];
        this.mostrarModalConfirmarEditarImagen = true;
        return;
      }
      target.files.forEach((file) => {
        const sizeFile = Number((file.size / 1024 / 1024).toFixed(2));
        if (sizeFile > this.maxSize) {
          return this.alertaMensaje(
          `El archivo que intenta subir es demasiado grande. Prueba con un archivo con peso inferior a 18MB`,
            "error"
          );
        }
        if ((this.size + sizeFile) > this.maxSize) {
          return this.alertaMensaje(
          `No se puede agregar el archivo ya que superaría el límite establecido de 18MB. Actualmente tienes: ${this.size.toFixed(
              2
            )} MB`,
           "error"
          );
        }
        if(!regExpFoto.test(file.name)){
          return this.alertaMensaje("El archivo que intenta subir no es una imagen", "error");
        }
         this.size += Number(sizeFile);
         this.fotos.push(file)
      });
    },
    entregarPaquetes(){
      this.botonDeshabilitado = true;
      const paquete = {
        estatus:'ListoDespacho',
      }
      if(this.paquete.observacion){
        Object.assign(paquete, {
          observacionEnvio: this.paquete.observacion
        })
      }
      const variableParaMutate = {
        paquete,
        paquetesId: this.paquetesId
      };
      // Asignar fotos
      if(this.fotos.length){
        Object.assign(variableParaMutate, {
          comprobante: {
            foto: this.fotos
          }
        })
      }
      this.$apollo.mutate({
        mutation: paqueteEntregarDestinoGql,
        variables: variableParaMutate,
        update: (store, {data: {paqueteEntregarDestino}}) => {
          const data = store.readQuery({
              query: agenciaGql,
              variables: this.variablesQueryAgencia,
          });
          const msg = paqueteEntregarDestino.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje('Paquete'+`${this.paquetesId.length > 1 ? 's entregados' : ' entregado'}`+' exitosamente',
                'correcto')
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error entregando el paquete. Por favor intenta de nuevo',
                'error')
            default:
              this.botonDeshabilitado = false;
              return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
                'error')
          }
          // Asignar el estatus a cada paquete entregado
          this.paquetesId.forEach(id => {
            data.Agencia[0].paquetes.map((p) => {
              const paquete = p
              if(p.id === id){
                paquete.estatus = 'Entregado'
                if(this.paquete.observacion){
                  paquete.observacionEnvio = this.paquete.observacion;
                }
              }
              return paquete
            })
          });
          store.writeQuery({
            query: agenciaGql,
            variables: this.variablesQueryAgencia,
            data,
          });
          if(!this.botonDeshabilitado){
            this.limpiarCampos();
            this.$emit('close', false);
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            paqueteEntregarDestino: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.botonDeshabilitado = false
        return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
          'error')
      })
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
     obtenerFotosPaquete(){
      if(!this.mostrarFotos)return
      this.$apollo.query({
        query: gql`${paqueteObtenerComprobanteGql}`,
        variables: {
          id: this.paquetesId[0]
        },
        update: data => data.Paquete[0].comprobante,
        fetchPolicy: "network-only"
      }).then(res => {
        this.fotosObtenidas = res.data.Paquete[0].comprobante;
      }).catch(()=>{
        this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
          'error')
      })
    },
    agregarFotos() {
      if(!this.fotos.length) {
        return this.alertaMensaje("Selecciona una imagen antes de continuar", "advertencia")
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: fotoCrearGql,
          variables: {
            paqueteId: this.paquetesId[0],
            fotos: {
              foto: this.fotos,
            },
            entidadFoto: 'Paquete'
          },
        })
        .then(() => {
          this.obtenerFotosPaquete()
          this.fotos = [];
          this.size = 0
          this.botonDeshabilitado = false;
          this.alertaMensaje("Imágenes agregadas correctamente al paquete", "correcto")
        })
        .catch(() => {
          this.alertaMensaje("Ha ocurrido un error editando las fotos del paquete"
            +" Por favor intenta de nuevo", "error")
        });
    },
    eliminarFotos(){
      if(!this.fotosEliminar.length) {
        return this.alertaMensaje("Debes seleccionar al menos una foto","error")
      }
      this.esEliminarFotos = true
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation:gql`${fotoEliminarGql}`,
        variables:{
          grafoId: this.paquetesId[0],
          url:this.fotosEliminar,
          entidadEliminar: "Paquete"
        }
      }).then(({data:{fotoEliminar}})=>{
        switch (fotoEliminar.codigo) {
          case "Correcto":
            this.alertaMensaje("Fotos eliminadas del paquete",
              "correcto")
            break;
          case "Fallido":
            this.botonDeshabilitado = false
            this.alertaMensaje("Ha ocurrido un error eliminando las fotos del paquete."
              +" Por favor intenta de nuevo", "error")
            return;
          default:
            this.botonDeshabilitado = false
            this.alertaMensaje('Ha ocurrido un error inesperado eliminando las fotos, intente nuevamente',
              'error')
            return;
        }
        this.obtenerFotosPaquete()
        this.fotosEliminar = [];
        this.botonDeshabilitado = false;
        this.esEliminarFotos = false
      })
      .catch(()=>{
        this.alertaMensaje('Ha ocurrido un error inesperado eliminando las fotos, intente nuevamente',
          'error')
        this.esEliminarFotos = false
        this.limpiarCampos();
      })
    },
    abrirGaleriaEn(foto) {
      this.$refs.lightbox.showImage(foto)
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.mostrarFotos = false;
      this.fotosObtenidas = [];
      this.fotosEliminar = [];
      this.paquete = {
        observacion: '',
      };
      this.fotos = [];
      this.size = 0;
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="vista-detalles cliente">
    <div class="container-xl px-0">
      <!-- Encabezado -->
      <div
        v-if="!$apolloData.queries.cliente.loading"
        :class="['row align-items-center justify-content-center mt-3',
          {'justify-content-lg-between': comprobarClienteUsuarioActual}
        ]"
      >
        <router-link
          to="/clientes"
          :class="['col-auto text-reset',
            comprobarClienteUsuarioActual ? 'order-xl-1' : 'order-md-1'
          ]"
        >
          <mdb-btn
            flat
            dark-waves
            icon="angle-left"
            icon-class="fa-lg"
            class="btn-bordeado px-lg-3"
          >
            Volver atrás
          </mdb-btn>
        </router-link>
        <div
          :class="['col-auto',
            comprobarClienteUsuarioActual ? 'text-center order-xl-3' : 'order-md-3',
            {'d-none d-md-block': !cliente}
          ]"
        >
          <template v-if="esAdmin && cliente">
            <mdb-btn
              flat
              dark-waves
              icon="user-lock"
              :disabled="botonDeshabilitado"
              class="btn-bordeado my-md-0 px-3 text-nowrap"
              title="Cambio de clave del cliente"
              @click="mostrarModalCambiarClave = true"
            >
              Cambio de clave
            </mdb-btn>
            <mdb-btn
              color="primario"
              icon="pen"
              :disabled="botonDeshabilitado"
              class="my-0 mx-4 mx-md-1 px-3 text-nowrap botonFlotante"
              title="Editar cliente"
              @click="mostrarModalFormularioCliente = true; esEditar=true"
            >
              Editar
            </mdb-btn>
            <mdb-btn
              v-if="comprobarClienteUsuarioActual"
              icon="trash"
              color="peligroso"
              :disabled="botonDeshabilitado"
              class="btn-bordeado my-md-0 px-3 text-nowrap "
              title="Eliminar cliente"
              @click="mostrarModalConfirmarPermiso = !mostrarModalConfirmarPermiso;"
            >
              Eliminar
            </mdb-btn>
          </template>
        </div>
        <h3
          v-if="cliente"
          :class="['col-12 mt-2 text-center user-select-none',
            comprobarClienteUsuarioActual ? 'col-xl order-xl-2' : 'col-md order-md-2',
          ]"
        >
          Cliente
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.cliente.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.cliente.loading && !cliente"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          El cliente solicitado no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row mt-md-2">
          <p class="col-12 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Cliente asignado
            </small>
            {{ cliente.nombre }}
          </p>
          <p class="col-12 col-md-4 col-xl-3 mb-2 text-break">
            <small class="text-muted user-select-none d-block">
              Usuario
            </small>
            <span :class="{'text-muted user-select-none': !cliente.usuario}">
              {{ cliente.usuario ? cliente.usuario : 'Sin especificar' }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Identificación
            </small>
            <span :class="{'text-muted user-select-none': !cliente.identificacion}">
              {{ cliente.identificacion ? cliente.identificacion : 'Sin especificar'}}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Género
            </small>
            <span :class="{'text-muted user-select-none': !cliente.genero}">
              {{ cliente.genero ? cliente.genero : 'Sin especificar' }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Desde
            </small>
            <span :class="{'text-muted user-select-none': !(cliente.creadoEl && cliente.creadoEl.length)}">
              {{ cliente.creadoEl && cliente.creadoEl.length ? cliente.creadoEl.formatted.substring(0, 10) : 'Sin especificar'}}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Envíos realizados
            </small>
            {{ cliente.enviosRealizados ? cliente.enviosRealizados : '0' }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Crédito
            </small>
            <font-awesome-icon
              :icon="cliente.credito ? 'check-circle' : 'times-circle'"
              :class="cliente.credito ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ cliente.credito ? 'Sí' : 'No' }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Casillero
            </small>
            <span :class="{'text-muted user-select-none': !cliente.casillero}">
              {{ cliente.casillero ? cliente.casillero : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Saldo disponible
            </small>
            <span
              :class="['font-weight-bold',
                {'text-muted user-select-none': !cliente.saldo},
                {'texto-exitoso': cliente.saldo > 0},
                {'texto-peligro': cliente.saldo < 0},
              ]"
            >
              ${{ cliente.saldo ? cliente.saldo.toFixed(2) : "0.00" }}
            </span>
          </p>
          <p class="col-12 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Empresa
            </small>
            <span :class="{'text-muted user-select-none': !cliente.agencia}">
              {{ cliente.agencia ? cliente.agencia.nombre : 'Sin asignar' }}
            </span>
          </p>
        </div>
        <h4 class="mt-2 user-select-none">Información de contacto</h4>
        <div class="row mb-3">
          <div class="col-12 col-sm-6">
            <header
              class="user-select-none cursor-pointer"
              title="Agregar nuevo teléfono"
              @click="esPermitido
                ? mostrarNuevoTelefono = !mostrarNuevoTelefono
                : ''"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Teléfonos
            </header>
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-md pr-md-0">
                <mdb-input
                  v-if="mostrarNuevoTelefono"
                  v-model.number="nuevoTelefono"
                  type="tel"
                  label="Teléfono"
                  placeholder="Ej: 58426573828"
                  minlength="7"
                  maxlength="13"
                  class="my-2 plus-addon"
                  outline
                  @keyup.enter.native="validarTelefono(nuevoTelefono, true)"
                >
                  <span class="input-group-text md-addon" slot="prepend">+</span>
                </mdb-input>
              </div>
              <div class="col-auto">
                <mdb-btn
                  v-if="mostrarNuevoTelefono"
                  color="exitoso"
                  icon="check"
                  title="Agregar teléfono"
                  class="m-1 py-2 px-3"
                  @click="validarTelefono(nuevoTelefono, true)"
                >
                  Agregar
                </mdb-btn>
              </div>
            </div>
            <!-- Listado de teléfonos -->
            <div v-if="cliente.telefonos && cliente.telefonos.length">
              <ul class="list-unstyled mb-2">
                <li v-for="(tel, i) in cliente.telefonos" :key="`tel${i}`">
                  <!-- Si se selecciona un teléfono para editar, se sustituirá el número teléfono por un
                  input que permite editarlo  -->
                  <div v-if="editarTelefono">
                    <div
                      v-if="telefonoEditarSeleccionado
                        && telefonoAEditar.telefonoIndice===i"
                    >
                      <mdb-input
                        v-model.number="telefonoAEditar.telefonoNuevo"
                        type="tel"
                        label="Teléfono"
                        minlength="7"
                        maxlength="13"
                        class="my-2 plus-addon"
                        outline
                        @keyup.enter.native="validarTelefono(telefonoAEditar.telefonoNuevo)"
                      >
                        <span class="input-group-text md-addon" slot="prepend">+</span>
                      </mdb-input>
                      <div class="mb-2 text-center">
                        <mdb-btn
                          color="exitoso"
                          icon="sync"
                          title="Actualizar teléfono"
                          class="m-1 py-2 px-3"
                          @click="validarTelefono(telefonoAEditar.telefonoNuevo)"
                        >
                          Actualizar
                        </mdb-btn>
                        <mdb-btn
                          flat
                          dark-waves
                          icon="times"
                          title="Cancelar edición del teléfono"
                          class="m-1 p-2 px-md-3"
                          @click="telefonoEditarSeleccionado = false;"
                        >
                          Cancelar
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else class="mb-2">
                      <span  class="d-block">
                        +{{tel.numero}}
                      </span>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoEditarSeleccionado = true;
                          telefonoAEditar = {
                            telefonoIndice: i,
                            telefonoNuevo: tel.numero,
                            telefonoAnterior: tel.numero
                          };"
                      >
                        Editar
                      </mdb-btn>
                      <mdb-btn
                        v-if="esAdmin"
                        flat
                        dark-waves
                        icon="trash"
                        title="Eliminar teléfono"
                        class="m-1 py-1 px-3"
                        @click="telefonoRemover(tel.numero)"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                  <div v-else>
                    <a
                      :href="`tel:+${tel.numero}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +{{tel.numero}}
                    </a>
                    <div class="d-inline-block">
                      <a
                        :href="`https://wa.me/${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon :icon="['fab', 'whatsapp']" />
                        WA
                      </a>
                      <a
                        :href="`sms:+${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                      >
                        <font-awesome-icon icon="sms" />
                        Sms
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- Botón para editar teléfonos -->
              <mdb-btn
                v-if="esAdmin"
                block
                flat
                dark-waves
                :icon="botonEditarTelefono.icono"
                :title="botonEditarTelefono.title"
                class="m-0 mr-2 px-3 py-2"
                @click="editarTelefono = !editarTelefono"
              >
                {{ botonEditarTelefono.title }}
              </mdb-btn>
            </div>
            <div
              v-else
              class="text-muted user-select-none"
            >
              Sin teléfonos registrados
            </div>
          </div>
          <div class="col-12 col-sm-6 mt-3 mt-sm-0">
            <header
              class="user-select-none cursor-pointer"
              title="Agregar nuevo email"
              @click="esPermitido
                ? mostrarNuevoEmail = !mostrarNuevoEmail
                : ''"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Emails
            </header>
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-md pr-md-0">
                <mdb-input
                  v-if="mostrarNuevoEmail"
                  v-model.trim="nuevoEmail"
                  type="email"
                  label="Email"
                  class="my-2"
                  outline
                  @keyup.enter.native="validarEmail(nuevoEmail, true)"
                />
              </div>
              <div class="col-auto">
                <mdb-btn
                  v-if="mostrarNuevoEmail"
                  color="exitoso"
                  icon="check"
                  title="Agregar email"
                  class="m-1 py-2 px-3"
                  @click="validarEmail(nuevoEmail, true)"
                >
                  Agregar
                </mdb-btn>
              </div>
            </div>
            <!-- Listado de emails -->
            <div v-if="cliente.emails && cliente.emails.length">
              <ul class="list-unstyled mb-2">
                <li v-for="(email, i) in cliente.emails" :key="`email${i}`">
                  <div v-if="editarEmail">
                    <div
                      v-if="emailEditarSeleccionado
                        && emailAEditar.emailIndice===i"
                    >
                      <mdb-input
                        v-model.trim="emailAEditar.emailNuevo"
                        type="email"
                        label="Email"
                        class="my-2"
                        outline
                        @keyup.enter.native="validarEmail(emailAEditar.emailNuevo)"
                      />
                      <div class="mb-2 text-center">
                        <mdb-btn
                          color="exitoso"
                          icon="sync"
                          title="Actualizar email"
                          class="m-1 py-2 px-3"
                          @click="validarEmail(emailAEditar.emailNuevo)"
                        >
                          Actualizar
                        </mdb-btn>
                        <mdb-btn
                          flat
                          dark-waves
                          icon="times"
                          title="Cancelar edición del email"
                          class="m-1 p-2 px-md-3"
                          @click="emailEditarSeleccionado = false;"
                        >
                          Cancelar
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else class="mb-2">
                      <span class="d-block">
                        {{ email.email }}
                      </span>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar email"
                        class="m-1 py-1 px-3"
                        @click="emailEditarSeleccionado = true;
                          emailAEditar = {
                            emailIndice: i,
                            emailNuevo: email.email,
                            emailAnterior: email.email
                          };"
                      >
                        Editar
                      </mdb-btn>
                      <mdb-btn
                        v-if="email.email !== cliente.usuario && esAdmin"
                        flat
                        dark-waves
                        icon="trash"
                        title="Eliminar email"
                        class="m-1 py-1 px-3"
                        @click="emailRemover(email.email)"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                  <a
                    v-else
                    target="_blank"
                    :href="`mailto:${email.email}`"
                    class="d-block text-break mt-1 py-1"
                  >
                    {{ email.email}}
                  </a>
                </li>
              </ul>
              <!-- Botón para editar emails -->
              <mdb-btn
                v-if="esAdmin"
                block
                flat
                dark-waves
                :icon="botonEditarEmails.icono"
                :title="botonEditarEmails.title"
                class="m-0 mr-2 px-3 py-2"
              @click="editarEmail = !editarEmail"
              >
                {{ botonEditarEmails.title }}
              </mdb-btn>
            </div>
            <div
              v-else
              class="text-muted user-select-none"
            >
              Sin emails registrados
            </div>
          </div>
        </div>
        <h4
          class="mt-2 user-select-none cursor-pointer"
          title="Agregar nueva dirección"
          @click="esPermitido
            ? mostrarModalEditarEnvio=!mostrarModalEditarEnvio
            : ''"
        >
          <font-awesome-icon
            icon="plus-circle"
            size="sm"
            class="texto-exitoso"
          />
          Direcciones de envío
        </h4>
        <ul
          v-if="cliente.direcciones && cliente.direcciones.length"
          class="list-unstyled"
        >
          <li
            v-for="(direccion, iD) in cliente.direcciones"
            :key="`direccion${iD}`"
          >
            <div class="row justify-content-around align-center-center my-2">
              <h5
                class="col-12 col-sm mb-0 user-select-none cursor-pointer"
                title="Editar esta dirección"
                @click="validarAsignarDireccion(direccion)"
              >
                <font-awesome-icon
                  icon="edit"
                  size="sm"
                />
                Dirección {{iD + 1}}
              </h5>
              <mdb-btn
                flat
                icon="handshake"
                size="sm"
                class="col-auto my-sm-0 px-2"
                @click="validarAsignarAliado(direccion)"
              >
                {{ direccion.aliado ? 'Cambiar' : 'Asignar' }} aliado
              </mdb-btn>
              <mdb-btn
                v-if="roles.includes('Admin') || roles.includes('Cliente')"
                flat
                dark-waves
                icon="trash"
                size="sm"
                class="col-auto my-sm-0 px-2"
                @click="IdDireccionAEliminar = direccion.id;
                  mostrarModalConfirmarEliminar = true;
                  sustantivoFemenino = true"
              >
                Remover Dirección
              </mdb-btn>
            </div>
            <div class="row">
              <p class="col-12 col-md-4 col-xl-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  País
                </small>
                {{ direccion.ciudad.estado.pais.nombre }}
              </p>
              <p class="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  Estado
                </small>
                {{ direccion.ciudad.estado.nombre }}
              </p>
              <p class="col-12 col-sm-6 col-md-4 col-xl-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Ciudad
                </small>
                {{ direccion.ciudad.nombre }}
              </p>
              <p class="col-12 col-md-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Dirección de envío
                </small>
                {{ direccion.direccion }}
              </p>
              <p class="col-12 col-md-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Observación
                </small>
                <span :class="{'text-muted user-select-none': !direccion.observacion}">
                  {{ direccion.observacion ? direccion.observacion : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-6 col-md-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa aérea
                </small>
                <span :class="{'text-muted user-select-none': !direccion.tasaAerea}">
                  {{ direccion.tasaAerea ? direccion.tasaAerea : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-6 col-md-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa marítima
                </small>
                <span :class="{'text-muted user-select-none': !direccion.tasaMaritima}">
                  {{ direccion.tasaMaritima ? direccion.tasaMaritima : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-6 col-md-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa mín. aérea
                </small>
                <span :class="{'text-muted user-select-none': !direccion.minAerea}">
                  {{ direccion.minAerea ? direccion.minAerea : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-6 col-md-3 mb-2">
                <small class="text-muted user-select-none d-block">
                  Tasa mín. marítima
                </small>
                <span :class="{'text-muted user-select-none': !direccion.minMaritima}">
                  {{ direccion.minMaritima ? direccion.minMaritima : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Aliado
                </small>
                <span :class="{'text-muted user-select-none': !direccion.aliado}">
                  {{ direccion.aliado ? direccion.aliado.nombre : 'Sin especificar' }}
                </span>
              </p>
              <div class="col-12 col-lg-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Persona que recibe
                </small>
                <span :class="{'text-muted user-select-none': !(direccion.recibe && direccion.recibe.nombre)}">
                  {{ direccion.recibe && direccion.recibe.nombre ?  direccion.recibe.nombre : 'Sin especificar'}}
                </span>
                <!-- Si no existe un receptor se podrá crear -->
                <mdb-btn
                  v-if="!direccion.recibe && esPermitido"
                  flat
                  icon="user-plus"
                  dark-waves
                  size="sm"
                  class="btn-bordeado py-2 px-3"
                  title="Haz click para asignar un receptor a la dirección"
                  @click="esCrearReceptor=true; direccionSeleccionada = direccion;
                    mostrarModalEditarEnvio=!mostrarModalEditarEnvio;"
                >
                  Asignar receptor
                </mdb-btn>
                <!-- Si existe un receptor se podrá crear, editar o eliminar sus teléfonos -->
                <div v-if="direccion.recibe">
                <!-- Listado de teléfonos -->
                  <header
                    class="user-select-none cursor-pointer"
                    title="Agregar nuevo teléfono"
                    @click="esPermitido
                      ? asignarTelefonoReceptor(iD)
                      : ''"
                  >
                    <font-awesome-icon
                      icon="plus-circle"
                      size="sm"
                      class="texto-exitoso"
                    />
                    Teléfonos
                  </header>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-12 col-sm">
                      <mdb-input
                        v-if="mostrarNuevoRTelefono && indiceDireccionSeleccionada===iD"
                        v-model.number="nuevoRTelefono"
                        type="tel"
                        label="Teléfono"
                        placeholder="Ej: 58426573828"
                        minlength="7"
                        maxlength="13"
                        class="my-2 plus-addon"
                        outline
                        @keyup.enter.native="validarTelefono(nuevoRTelefono, true, direccion.recibe.id)"
                      >
                        <span class="input-group-text md-addon" slot="prepend">+</span>
                      </mdb-input>
                    </div>
                    <div class="col-auto">
                      <mdb-btn
                        v-if="mostrarNuevoRTelefono && indiceDireccionSeleccionada===iD"
                        color="exitoso"
                        icon="sync"
                        title="Actualizar teléfono"
                        class="m-1 py-2 px-3"
                        @click="validarTelefono(nuevoRTelefono, true, direccion.recibe.id)"
                      >
                        Agregar
                      </mdb-btn>
                    </div>
                  </div>
                  <div v-if="direccion.recibe.telefonos && direccion.recibe.telefonos.length">
                    <ul class="list-unstyled row mb-2">
                      <li
                        v-for="(tel, i) in direccion.recibe.telefonos"
                        :key="`tel${i}`"
                        :class="['col-12', {'col-sm-6 col-xl-4': i > 1}]"
                      >
                        <!-- Si se selecciona un teléfono para editar, se sustituirá el número teléfono por un
                        input que permite editarlo  -->
                        <div v-if="editarRTelefono && indiceDireccionSeleccionada===iD">
                          <div
                            v-if="rTelefonoEditarSeleccionado
                              && rTelefonoAEditar.telefonoIndice===i"
                          >
                            <mdb-input
                              v-model.number="rTelefonoAEditar.telefonoNuevo"
                              type="tel"
                              label="Teléfono"
                              minlength="7"
                              maxlength="13"
                              class="my-2 plus-addon"
                              outline
                              @keyup.enter.native="
                                validarTelefono(rTelefonoAEditar.telefonoNuevo, false, direccion.recibe.id)
                              "
                            >
                              <span class="input-group-text md-addon" slot="prepend">+</span>
                            </mdb-input>
                            <div class="mb-2 text-center">
                              <mdb-btn
                                color="exitoso"
                                icon="sync"
                                title="Actualizar teléfono"
                                class="m-1 py-2 px-3"
                                @click="validarTelefono(rTelefonoAEditar.telefonoNuevo, false, direccion.recibe.id)"
                              >
                                Actualizar
                              </mdb-btn>
                              <mdb-btn
                                flat
                                dark-waves
                                icon="times"
                                title="Cancelar edición del teléfono"
                                class="m-1 p-2 px-md-3"
                                @click="rTelefonoEditarSeleccionado = false;"
                              >
                                Cancelar
                              </mdb-btn>
                            </div>
                          </div>
                          <div v-else class="mb-2">
                            <span  class="d-block">
                              +{{tel.numero}}
                            </span>
                            <mdb-btn
                              flat
                              dark-waves
                              icon="pen"
                              title="Editar teléfono"
                              class="m-1 py-1 px-3"
                              @click="rTelefonoEditarSeleccionado = true;
                                rTelefonoAEditar = {
                                  telefonoIndice: i,
                                  telefonoNuevo: tel.numero,
                                  telefonoAnterior: tel.numero
                                };"
                            >
                              Editar
                            </mdb-btn>
                            <mdb-btn
                              v-if="esAdmin"
                              flat
                              dark-waves
                              icon="trash"
                              title="Eliminar teléfono"
                              class="m-1 py-1 px-3"
                              @click="telefonoRemover(tel.numero, direccion.recibe.id)"
                            >
                              Eliminar
                            </mdb-btn>
                          </div>
                        </div>
                        <div v-else>
                          <a
                            :href="`tel:+${tel.numero}`"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +{{tel.numero}}
                          </a>
                          <div class="d-inline-block">
                            <a
                              :href="`https://wa.me/${tel.numero}`"
                              target="_blank"
                              rel="noopener noreferrer"
                              class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                            >
                              <font-awesome-icon :icon="['fab', 'whatsapp']" />
                              WA
                            </a>
                            <a
                              :href="`sms:+${tel.numero}`"
                              target="_blank"
                              rel="noopener noreferrer"
                              class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                            >
                              <font-awesome-icon icon="sms" />
                              Sms
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <!-- Botón para editar teléfonos -->
                    <mdb-btn
                      v-if="esAdmin"
                      block
                      flat
                      dark-waves
                      :icon="botonEditarTelefono.icono"
                      :title="botonEditarTelefono.title"
                      class="m-0 mr-2 px-3 py-2"
                      @click="editarRTelefono = !editarRTelefono; indiceDireccionSeleccionada = iD;"
                    >
                      {{ botonEditarTelefono.title }}
                    </mdb-btn>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="text-muted user-select-none">
          No hay direcciones registradas
        </div>
        <section v-if="!!Object.keys(PaquetesClienteConEstatus).length">
          <h4 class="mt-2 user-select-none cursor-pointer">
            Resumen de paquetes por estatus
          </h4>
          <div class="row">
            <div
              v-for="(value, name) in PaquetesClienteConEstatus"
              :key="name"
              class="col-12 col-sm-6 col-md-4 col-xl-3"
            >
              <div class="tarjeta-estatus">
                <header>{{ PaqueteEstatus[name] }}</header>
                <div class="valor">{{ value }}</div>
              </div>
            </div>
          </div>
        </section>
        <!-- Tabla de paquetes -->
        <ul
          data-table="sticky"
          class="table mb-3"
        >
          <li class="encabezado doble-fila row">
            <div class="fila-titulo col-12">
              <div class="row align-items-center my-1">
                <h4
                  class="col ml-sm-5 ml-lg-0 px-2 text-center text-sm-left
                    user-select-none"
                >
                  Paquetes
                </h4>
                <div
                  v-if="paquetesId.length && esAdmin"
                >
                  <mdb-btn
                    color="peligroso"
                    icon="trash"
                    size="sm"
                    class="my-sm-0 px-3"
                    @click="paqueteEliminar"
                  >
                    Remover Paquete{{
                      paquetesId.length > 1 ? 's' : ''
                    }}
                  </mdb-btn>
                  <div
                    :class="['d-inline-block',
                      {'cursor-not-allowed': botonDeshabilitado || !paquetesTipoIndividual}]"
                    title="Solo se permite en paquetes de tipo individual"
                  >
                    <mdb-btn
                      color="terciario"
                      icon="box-open"
                      size="sm"
                      :disabled="botonDeshabilitado || !paquetesTipoIndividual"
                      title="Solo se permite en paquetes de tipo individual"
                      class="my-0 px-3"
                      @click="paqueteReempacar"
                    >
                      Reempacar
                    </mdb-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col col-sm-3 pl-4 pl-lg-2 d-none d-sm-block">Fecha</div>
                <div class="col pl-4 pl-sm-2">Detalles</div>
                <div class="col">
                  Estatus
                </div>
              </div>
            </div>
          </li>
          <li
            v-for="paquete in paquetesClienteAscendente"
            :key="`paquete-${paquete.id}`"
            class="contenido row align-items-center"
            @click="esPermitidoVerDetallesPaquete && $router.push({ name: 'ClientePaquete', params: { paqueteId: paquete.id } })"
          >
            <div class="col col-sm-3 d-none d-sm-block">
              <label
                v-if="esAdmin"
                class="opciones-checkbox py-2"
                title="Seleccionar paquete"
                @click.stop
              >
                <input
                  v-model="paquetesId"
                  type="checkbox"
                  name="selecionar-paquete-checkbox"
                  :value="paquete"
                />
                <span class="icono-checkbox" />
                {{ paquete.fecha && paquete.fecha.formatted
                  ? paquete.fecha.formatted : 'Sin especificar'}}
              </label>
              <span v-else>
                {{ paquete.fecha && paquete.fecha.formatted
                  ? paquete.fecha.formatted : 'Sin especificar'}}
              </span>
            </div>
            <div class="col">
              <label
                v-if="esAdmin"
                class="opciones-checkbox py-2 d-block d-sm-none"
                title="Seleccionar paquete"
                @click.stop
              >
                <input
                  v-model="paquetesId"
                  type="checkbox"
                  name="selecionar-paquete-checkbox"
                  :value="paquete"
                />
                <span class="icono-checkbox" />
                {{ paquete.fecha && paquete.fecha.formatted
                  ? paquete.fecha.formatted : 'Sin especificar'}}
              </label>
              <span v-else>
                {{ paquete.fecha && paquete.fecha.formatted
                  ? paquete.fecha.formatted : 'Sin especificar'}}
              </span>
              Paquete ID: {{ paquete.numeroHR ? paquete.numeroHR : 'Sin especificar'}}
              <p class="mb-0">
                Pies cúbicos: {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                }} ft<sup>3</sup>
              </p>
              Peso: {{ mostrarSoloDosDecimales(paquete.peso) }} lbs
              <p class="mb-0">
                Peso por volúmen: {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                }} lbs
              </p>
              Tipo: {{paquete.tipo}}
              <p v-if="paquete.observacion" class="mb-0">
                Observación: {{ paquete.observacion }}
              </p>
            </div>
            <div class="col">
                {{ paquete.estatus ? PaqueteEstatus[paquete.estatus] : 'Sin especificar'}}
            </div>
          </li>
          <li
            v-if="!(cliente.paquetes && cliente.paquetes.length)"
            class="no-items"
          >
            No hay paquetes
          </li>
        </ul>
        <!-- Tabla de órdenes -->
        <ul
          data-table="sticky"
          class="table mb-3"
        >
          <li class="encabezado  doble-fila row">
            <h4
              class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none cursor-pointer"
              title="Agregar nueva orden de compra"
              @click="esPermitido
                ? mostrarFormularioOrden = true
                : ''"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Órdenes de compra
            </h4>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col col-sm-3 col-md-2 pl-4 pl-lg-2 d-none d-sm-block">Fecha</div>
                <div class="col col-sm-3 col-md-4 pl-4 pl-sm-2">Detalles</div>
                <div class="col col-sm-3 col-md-4">Estatus</div>
                <div class="col col-sm-3 col-md-2">Acción</div>
              </div>
            </div>
          </li>
          <li
            v-for="orden in cliente.ordenes"
            :key="`orden-${orden.id}`"
            class="contenido normal row align-items-center"
          >
            <div class="col col-sm-3 col-md-2 d-none d-sm-block">
              {{ orden.fecha && orden.fecha.formatted
                ? orden.fecha.formatted : 'Sin especificar'}}
            </div>
            <div class="col col-sm-3 col-md-4">
              <span v-if="orden.fecha && orden.fecha.formatted" class="d-sm-none">
                {{ orden.fecha.formatted }}
              </span>
            <div class="font-weight-bold" title="Monto">${{ orden.monto }}</div>
             <div class="d-block">
                Compra ID:
                <span
                  v-if="orden.compraId && orden.compraId.length"
                >
                  <router-link
                    :to="'/compras/'+orden.id"
                  >
                    {{ orden.compraId}}
                  </router-link>
                </span>
                <span
                  v-else
                  class="text-muted user-select-none"
                >
                  Sin especificar
                </span>
             </div>
            </div>
            <div class="col col-sm-3 col-md-4">
                {{ OrdenEstatus[orden.estatus] }}
            </div>
            <div class="col col-sm-3 col-md-2">
              <mdb-btn
                v-if="esAdmin"
                flat
                dark-waves
                icon="trash"
                icon-class="texto-peligro"
                class="m-0 ml-2 ml-sm-0 py-3 px-2 btn-bordeadoOnHover"
                title="Eliminar orden"
                @click="IdOrdenAEliminar = orden.id;
                  mostrarModalConfirmarEliminar = true;
                  sustantivoFemenino = true;"
              />
              <mdb-btn
                v-if="roles.includes('Cliente') || roles.includes('Admin') || roles.includes('Agencia')"
                flat
                dark-waves
                icon="pen"
                class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
                title="Editar orden"
                @click="abrirModalEditar(orden)"
              />
            </div>
          </li>
          <li
            v-if="!(cliente.ordenes && cliente.ordenes.length)"
            class="no-items"
          >
            No hay órdenes
          </li>
        </ul>
        <!-- Tabla de pagos con saldo disponible -->
        <ul
          v-if="cliente.pagosConSaldo.length"
          data-table="sticky"
          class="table"
        >
          <li class="encabezado doble-fila row">
            <h4
              class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none"
            >
              Pagos con saldo disponible
            </h4>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col-2 d-none d-md-block">Fecha</div>
                <div class="col pl-4 pl-sm-2">
                  <span class="d-md-none">Detalles</span>
                  <span class="d-none d-md-block">Medio / Referencia / Monto</span>
                </div>
                <div class="col-auto acciones text-right pl-0">Restante</div>
                <div class="col d-none d-sm-block">Observación</div>
              </div>
            </div>
          </li>
          <li
            v-for="(pago, pcsI) in cliente.pagosConSaldo"
            :key="`${cliente.nombre}-conSaldo-`+pcsI"
            class="contenido normal row align-items-center"
          >
            <div class="col-2 d-none d-md-block">
              <span
                :class="{'text-muted': !(pago.fecha && pago.fecha.formatted.length)}"
              >
                {{ pago.fecha && pago.fecha.formatted
                  ? pago.fecha.formatted : 'Sin especificar'}}
              </span>
            </div>
            <div class="col">
              <div
                v-if="pago.fecha && pago.fecha.formatted.length"
                class="py-2 d-md-none"
              >
                {{ pago.fecha.formatted }}
              </div>
              Medio de pago:
              <span :class="{'text-muted': !(pago.medio && pago.medio.nombre.length)}">
                {{
                  pago.medio && pago.medio.nombre.length
                    ? pago.medio.nombre
                    : "Sin especificar"
                }}
              </span>
              <div title="Referencia">Ref. {{ pago.referencia }}</div>
              <div class="font-weight-bold" title="Monto">${{ pago.monto }}</div>
              <div class="py-2 d-sm-none">
                Observación:
                <span
                  :class="{'text-muted': !(pago.observacion
                    && pago.observacion.length)}"
                >
                  {{ pago.observacion && pago.observacion.length
                    ? pago.observacion : 'Sin especificar'
                  }}
                </span>
              </div>
            </div>
            <div class="col-auto acciones text-right pl-0">
              <span
                :class="['font-weight-bold',
                  {'text-muted user-select-none': !pago.restante},
                  {'texto-exitoso': pago.restante > 0},
                  {'texto-peligro': pago.restante < 0},
                ]"
              >
                ${{ pago.restante ? pago.restante.toFixed(2) : "0.00" }}
              </span>
            </div>
            <div :class="['col d-none d-sm-block', {'text-muted user-select-none': !(pago.observacion && pago.observacion.length)}]">
              {{  pago.observacion && pago.observacion.length
                ? pago.observacion : 'Sin especificar' }}
            </div>
          </li>
          <li
            v-if="!(cliente.pagos && cliente.pagos.length)"
            class="no-items"
          >
            No hay pagos
          </li>
        </ul>
        <!-- Tabla de pagos -->
        <ul
          data-table="sticky"
          class="table"
        >
          <li class="encabezado doble-fila row">
            <h4
              class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none cursor-pointer"
              title="Agregar nuevo pago"
              @click="esPermitido
                ? mostrarModalPago = true
                : ''"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Pagos
            </h4>
            <div class="fila-encabezado-tabla col-12 user-select-none">
              <div class="row">
                <div class="col-2 d-none d-md-block">Fecha</div>
                <div class="col pl-4 pl-sm-2">
                  <span class="d-md-none">Detalles</span>
                  <span class="d-none d-md-block">Medio / Referencia / Monto</span>
                </div>
                <div class="col d-none d-sm-block">Observación</div>
                <div class="col-auto acciones pr-0">Acción</div>
              </div>
            </div>
          </li>
          <li
            v-for="(pago, i) in cliente.pagos"
            :key="`${cliente.nombre}`+i"
            class="contenido normal row align-items-center"
          >
            <div class="col-2 d-none d-md-block">
              <span
                :class="{'text-muted': !(pago.fecha && pago.fecha.formatted.length)}"
              >
                {{ pago.fecha && pago.fecha.formatted
                  ? pago.fecha.formatted : 'Sin especificar'}}
              </span>
            </div>
            <div class="col">
              <div
                v-if="pago.fecha && pago.fecha.formatted.length"
                class="py-2 d-md-none"
              >
                {{ pago.fecha.formatted }}
              </div>
              Medio de pago:
              <span :class="{'text-muted': !(pago.medio && pago.medio.nombre.length)}">
                {{
                  pago.medio && pago.medio.nombre.length
                    ? pago.medio.nombre
                    : "Sin especificar"
                }}
              </span>
              <div title="Referencia">Ref. {{ pago.referencia }}</div>
              <div class="font-weight-bold" title="Monto">${{ pago.monto }}</div>
              <div class="py-2 d-sm-none">
                Observación:
                <span
                  :class="{'text-muted': !(pago.observacion
                    && pago.observacion.length)}"
                >
                  {{ pago.observacion && pago.observacion.length
                    ? pago.observacion : 'Sin especificar'
                  }}
                </span>
              </div>
            </div>
            <div :class="['col d-none d-sm-block', {'text-muted user-select-none': !(pago.observacion && pago.observacion.length)}]">
              {{  pago.observacion && pago.observacion.length
                ? pago.observacion : 'Sin especificar' }}
            </div>
            <div class="col-auto acciones pr-0">
              <mdb-btn
                v-if="roles.includes('Cliente') || roles.includes('Admin')"
                flat
                dark-waves
                icon="trash"
                icon-class="texto-peligro"
                class="m-0 ml-2 ml-md-0 py-3 px-2 btn-bordeadoOnHover"
                title="Eliminar pago"
                @click="IdPagoAEliminar = pago.id;
                mostrarModalConfirmarEliminar = true;
                obtenerComprobantesEliminar();
                "
              />
              <mdb-btn
                v-if="roles.includes('Cliente') || roles.includes('Admin') || roles.includes('Agencia')"
                flat
                dark-waves
                icon="pen"
                class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
                title="Editar pago"
                @click.native.stop="pagoAEditar(pago)"
              />
              <mdb-btn
                flat
                dark-waves
                icon="file-invoice-dollar"
                :disabled="botonDeshabilitado"
                class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
                title="Ver comprobantes"
                @click="obtenerComprobantes(pago.id)"
              />
            </div>
          </li>
          <li
            v-if="!(cliente.pagos && cliente.pagos.length)"
            class="no-items"
          >
            No hay pagos
          </li>
        </ul>
      </div>
    </div>
    <!--
        <h5>Notificaciones</h5>
        TABLA
        Agregar tabla con las notificaciones.
      -->
    <!-- Modal Editar Cliente -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalFormularioCliente"
      @close="mostrarModalFormularioCliente = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Datos del cliente a editar</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <NuevoCliente
          v-if="mostrarModalFormularioCliente"
          :mostrarFormulario="mostrarModalFormularioCliente"
          :datosCliente="cliente ? cliente : {}"
          :esEditar="esEditar"
          @cerrar="mostrarModalFormularioCliente = false"
          @editado="Object.assign(cliente, $event); mostrarModalFormularioCliente = false;"
          @alertaMensaje="alertarMensaje($event)"
        />
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal Cambiar Clave -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalCambiarClave"
      @close="cerrarModalClave"
    >
      <mdb-modal-header>
        <mdb-modal-title>Cambio de clave</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <section class="row justify-content-center">
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.clave
                && validarValores.clave.length},
              {'valido': validarValores.clave == 'valido'},
              {'invalido': validarValores.clave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="clave"
              :type="mostrarCamposClave.clave ? 'text' : 'password'"
              minlength="8"
              label="Clave"
              class="my-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
                @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
              />
            </mdb-input>
            <p
              v-if="validarValores.clave == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la clave
            </p>
          </div>
          <div
            :class="['col-12 col-md-6',
              {'campo': validarValores.confirmacionClave
                && validarValores.confirmacionClave.length},
              {'valido': validarValores.confirmacionClave == 'valido'},
              {'invalido': validarValores.confirmacionClave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="confirmacionClave"
              :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
              minlength="8"
              label="Confirmar clave"
              class="my-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.confirmacionClave
                  ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                @click="mostrarCamposClave.confirmacionClave
                  = !mostrarCamposClave.confirmacionClave"
              />
            </mdb-input>
            <p
              v-if="validarValores.confirmacionClave == 'invalido'"
              class="mensaje-invalido"
            >
              Confirma la clave
            </p>
          </div>
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'sync'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="mt-3 px-3"
            :disabled="botonDeshabilitado"
            @click="cambiarClave"
          >
            Cambiar clave
          </mdb-btn>
        </section>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal comprobantes de pagos -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalComprobante"
      @close="cerrarModalComprobante"
    >
      <mdb-modal-header>
        <mdb-modal-title> Comprobantes de pago </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <h5 class="mb-3">Fotos del comprobante</h5>
        <div v-if="cargandoComprobantes">
          <CargandoVista
            v-if="cargandoComprobantes"
            clase-adicional="vc-50vh"
          />
        </div>
        <div v-else>
          <ul
            v-if="comprobante.fotos && comprobante.fotos.length"
            class="listado-fotos"
          >
            <li
              v-for="(foto, iFoto) in comprobante.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto}`"
                  :alt="`Comprobante del pago: Imagen nro ${iFoto + 1}`"
                  @click="abrirGaleriaEn(iFoto)"
                />
              </div>
              <label
                v-if="roles.includes('Admin')"
                class="boton-eliminar checkbox-grande"
                title="Seleccionar las imágenes del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="fotos-eliminar"
                  v-model="comprobantesEliminar"
                  :value="foto"
                />
                <span class="checkbox" />
              </label>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay fotos para mostrar
          </span>
          <h5 class="my-3">Documentos PDF</h5>
          <ul
            v-if="comprobante.pdf && comprobante.pdf.length"
            class="lista-archivos"
          >
            <li
              v-for="(urlArchivo, pdfIndex) in comprobante.pdf"
              :key="pdfIndex"
              class="archivo-contenedor"
            >
              <a
                :href="`${endpointPublicBackend}${urlArchivo}`"
                target="_blank"
                rel="noopener noreferrer"
                class="archivo"
              >
                <pdf :src="`${endpointPublicBackend}${urlArchivo}`"> </pdf>
              </a>
              <label
                v-if="roles.includes('Admin')"
                class="boton-eliminar checkbox-grande"
                title="Seleccionar archivos del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="pdf-checkboxes"
                  v-model="comprobantesEliminar"
                  :value="urlArchivo"
                />
                <span class="checkbox" />
              </label>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay archivos en fomato pdf para mostrar
          </span>
          <div
            class="row justify-content-center align-items-center my-3"
            v-if="comprobante.pdf.length || comprobante.fotos.length && (roles.includes('Admin'))"
          >
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'check-square'"
              :icon-class="[{'texto-terciario': comprobantesEliminar.length},
                {'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="seleccionarTodo"
            >
              {{textoBotonSeleccionar}}
            </mdb-btn>
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
              :icon-class="[{'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado || !comprobantesEliminar.length"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="
                mostrarModalConfirmarEliminar = true;
                esEliminarComprobantes = true;
              "
            >
              Eliminar comprobantes
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      :mensaje="mensajeAlConfirmarEliminar"
      :sustantivoFemenino="sustantivoFemenino"
      @cerrar="mostrarModalConfirmarEliminar = $event; limpiarId(); tieneComprobantes = false;
        sustantivoFemenino = false; eliminarClienteDobleVerificacion = false"
      @eliminar="metodoAlConfirmarEliminar();"
    />
    <FormularioOrden
      :mostrarFormularioOrden="mostrarFormularioOrden"
      :es-editar="esEditarOrden"
      :clienteEspecificado="cliente ? cliente : {}"
      :titulo="
        esEditarOrden ? 'Datos de la orden a editar' : 'Datos de la nueva orden'
      "
      :ordenAsignar="ordenAsignar ? ordenAsignar : {}"
      @cerrar="mostrarFormularioOrden = $event"
      @orden="ordenCrear($event)"
      @cliente="clienteOrden = $event"
      @editar="esEditarOrden = $event"
      @datosEditar="ordenEditar($event)"
    />
    <FormularioPago
      :mostrarModalPago="mostrarModalPago"
      :es-editar="esEditarPago"
      :titulo="esEditarPago ? 'Datos del pago a editar' : 'Datos del nuevo pago'"
      :pagoAsignar="pagoAsignar && pagoAsignar"
      :clienteEspecificado="cliente ? cliente : {}"
      @crear="pagoCrear($event)"
      @cerrar="
        mostrarModalPago = false;
        botonDeshabilitado = false;
      "
      @editar="esEditarPago = $event"
      @editarPago="pagoEditar($event)"
      @clienteError="alertaMensaje = {
        contenido: 'El cliente es requerido para crear el pago',
        tipo: 'error',
      }"
      @alerta="mensajeAlerta($event)"
    />
    <!-- Modal Editar datos de envio -->
    <ModalDatosEnvio
      :mostrar-modal="mostrarModalEditarEnvio"
      :clienteEspecificado="cliente ? cliente : {}"
      :es-editar="esEditarDirección"
      :esCrearReceptor="esCrearReceptor"
      :datosDireccion="direccionSeleccionada ? direccionSeleccionada : ''"
      @alertaMensaje="alertaMensaje=$event"
      @creado="cliente.direcciones.push($event); mostrarModalEditarEnvio = false;"
      @cerrar="mostrarModalEditarEnvio = $event; esEditarDirección=false; esCrearReceptor=false;"
    />
    <!-- Modal Nuevo paquete  / reempaque-->
    <FormularioPaquete
      :titulo="modalTitulo"
      :esReempaque="reempaque"
      :paquetes="paquetesSeleccionados"
      :paquetesId="paquetesIdFormatted"
      :mostrar-modal="mostrarFormularioPaquete"
      :personaIdReempacar="cliente ? cliente.id : ''"
      :esCliente="true"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="
        mostrarFormularioPaquete = $event;
        reempaque = false;
        paquetesId = [];
      "
    />
    <!-- Modal seleccionar aliado-->
    <ModalSeleccionarAliado
      :mostrar-modal="mostrarModalSeleccionarAliado"
      :aliadoId="direccionSeleccionada && direccionSeleccionada.aliado ? direccionSeleccionada.aliado.id : ''"
      :direccionEspecificada="direccionSeleccionada ? direccionSeleccionada : {}"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalSeleccionarAliado = $event;"
    />
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="idClienteEliminar=$route.params.id; idClienteEliminar.length && eliminarClienteDobleVerificacion ? eliminarCliente() : mostrarModalConfirmarEliminar=true"
      @cerrar="mostrarModalConfirmarPermiso = $event; eliminarClienteDobleVerificacion = false;"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <LightBox
      v-if="galeriaFotosComprobante && galeriaFotosComprobante.length"
      ref="lightbox"
      :media="galeriaFotosComprobante"
      :show-light-box="false"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>
<script>
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import FormularioPago from "@/components/FormularioPago.vue";
import FormularioPaquete from "@/components/FormularioPaquete.vue";
import FormularioOrden from "@/components/FormularioOrden.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import ordenCrearGql from "@/graphql/ordenCrear.gql";
import ordenEditarGql from "@/graphql/ordenEditar.gql";
import ordenEliminarGql from "@/graphql/ordenEliminar.gql";
import pagoEliminarGql from "@/graphql/pagoEliminar.gql";
import pagoCrearGql from "@/graphql/pagoCrear.gql";
import pagoEditarGql from "@/graphql/pagoEditar.gql";
import CargandoVista from "@/components/CargandoVista.vue";
import clienteGql from "@/graphql/cliente.gql";
import clientesGql from "@/graphql/clientes.gql";
import clienteEliminarGql from '@/graphql/clienteEliminar.gql'
import clienteEditarEmailGql from "@/graphql/clienteEditarEmail.gql";
import clienteCrearEmailGql from "@/graphql/clienteCrearEmail.gql";
import clienteEditarTelefonoGql from "@/graphql/clienteEditarTelefono.gql";
import clienteCrearTelefonoGql from "@/graphql/clienteCrearTelefono.gql";
import clienteEliminarTelefonoGql from "@/graphql/clienteEliminarTelefono.gql";
import clienteEliminarEmailGql from "@/graphql/clienteEliminarEmail.gql";
import paqueteEliminarGql from "@/graphql/paqueteEliminar.gql";
import direccionEliminarGql from "@/graphql/direccionEliminar.gql";
import obtenerFotosGql from "@/graphql/obtenerFotos.gql";
import fotoEliminarGql from "@/graphql/fotoEliminar.gql";
import cambiarClaveCliente from "@/graphql/cambiarClaveCliente.gql";
import paquetesClienteEstatusGql from "@/graphql/paquetesClienteEstatus.gql";
import { endpointPublicBackend } from "@/constantes/paquetes.js";
import NuevoCliente from "@/components/NuevoCliente.vue";
import pdf from 'vue-pdf'
import LightBox from "vue-image-lightbox";
import existeUsuarioGql from "@/graphql/existeUsuario.gql"
import ModalDatosEnvio from "../components/ModalDatosEnvio.vue";
import ModalSeleccionarAliado from "../components/ModalSeleccionarAliado.vue";
import gql from "graphql-tag";
import {  regExpPdf } from "@/constantes/pagos.js";
import { PaqueteEstatus } from "@/constantes/paquetes.js"
import { OrdenEstatus } from "@/constantes/ordenes.js";
import {
  validarMail,
  validarTel,
  evitarTelefonosRepetidos,
  mostrarSoloDosDecimales
} from "@/funciones/funciones.js"
import { leerRoles, leerPersonaId } from "@/utils/datosToken.js";
import {obtenerMarcaTemporal, enumRespuestaCodigo} from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput,
} from "mdbvue";
// Tareas a realizar
// Agregar notificaciones [] (falta implementación del back-end)
// Funcionalidad de autorizar envio de paquete [] (falta implementación del back-end)

export default {
  name: "cliente",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    AlertaMensaje,
    CargandoVista,
    NuevoCliente,
    ModalDatosEnvio,
    ConfirmacionEliminar,
    FormularioOrden,
    FormularioPaquete,
    FormularioPago,
    pdf,
    LightBox,
    ModalSeleccionarAliado,
    ModalConfirmarPermiso
  },
  data() {
    return {
      // aliados
      idUsuarioActual:leerPersonaId(),
      mostrarModalSeleccionarAliado: false,
      roles : leerRoles(),
      mostrarSoloDosDecimales,
      // Crear receptor
      esCrearReceptor: false,
      // direcciones
      mostrarModalEditarEnvio: false,
      mostrarModalCambiarClave: false,
      mostrarCamposClave: {
        clave: false,
        confirmacionClave: false,
      },
      clave:'',
      confirmacionClave:'',
      validarValores:{},
      esEditarDirección: false,
      direccionSeleccionada: {},
      IdDireccionAEliminar: '',
      mostrarNuevoRTelefono: false,
      nuevoRTelefono: '',
      editarRTelefono: false,
      rTelefonoEditarSeleccionado: false,
      indiceDireccionSeleccionada: 0,
      rTelefonoAEditar: {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: '',
      },
      // Paquetes
      paquetesId: [],
      mostrarFormularioPaquete: false,
      PaqueteEstatus,
      reempaque: false,
      // Emails
      mostrarNuevoEmail: false,
      editarEmail: false,
      emailEditarSeleccionado: false,
      nuevoEmail: '',
      emailAEditar: {
        emailIndice: 0,
        emailAnterior: '',
        emailNuevo: '',
      },
      // Teléfonos
      mostrarNuevoTelefono: false,
      editarTelefono: false,
      telefonoEditarSeleccionado: false,
      nuevoTelefono: '',
      telefonoAEditar: {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: '',
      },
      // Ordenes
      clienteOrden: {},
      orden: {
        miniatura: '',
        estatus: '',
        fecha: {},
      },
      IdOrdenAEliminar: '',
      ordenAsignar: {
        miniatura: '',
        estatus: '',
        fecha: {},
      },
      // Pagos
      esEditarPago: false,
      mostrarModalPago: false,
      cargandoComprobantes:false,
      mostrarModalComprobante:false,
      esEliminarComprobantes:false,
      pagoIdComprobante:"",
      tieneComprobantes:false,
      comprobante:{
        fotos:[],
        pdf:[]
      },
      comprobantesEliminar:[],
      galeriaFotosComprobante:[],
      endpointPublicBackend,
      OrdenEstatus,
      pago: {
        referencia:"",
        observacion:"",
        medio: "",
        fecha: {},
        cliente: {},
      },
      IdPagoAEliminar: '',
      pagoAsignar: {
        medio: "",
        fecha: {},
        monto: "",
        referencia: "",
        observacion:"",
      },
      botonDeshabilitado: false,
      mostrarFormularioOrden: false,
      alertaMensaje: {contenido: ''},
      clienteId: '',
      modalTitulo: '',
      consolidadoId: '',
      sustantivoFemenino: false,
      mostrarModalFormularioCliente: false,
      mostrarModalConfirmarEliminar: false,
      esEditar: false,
      esEditarOrden: false,
      cliente: {},
      mostrarModalConfirmarPermiso:false,
      eliminarClienteDobleVerificacion: false,
      idClienteEliminar: ''
    };
  },
  computed: {
    // Comprueba si el cliente actual es el mismo usuario
    comprobarClienteUsuarioActual() {
      return this.idUsuarioActual !== this.$route.params.id
    },
    // Ordenar por fecha descendente los paquetes del cliente
    paquetesClienteAscendente: function() {
      if(this.cliente){
        const paquetesDelCliente = JSON.parse(JSON.stringify(this.cliente.paquetes))
        return paquetesDelCliente.sort((a, b) => new Date(b.fecha.formatted) - new Date(a.fecha.formatted))
      } return []
    },
    textoBotonSeleccionar() {
      const pdfDisponibles = this.comprobante.pdf.length;
      const fotosDisponibles = this.comprobante.fotos.length;
      const totalComprobantes = pdfDisponibles + fotosDisponibles;
      return this.comprobantesEliminar.length === totalComprobantes
        ? "Deseleccionar todo"
        : "Seleccionar todo";
    },
    botonEditarEmails() {
      return this.editarEmail
      ? {icono:'times', title:'Cancelar edición'}
      : {icono:'pen', title:'Editar emails'};
    },
    botonEditarTelefono() {
      return this.editarTelefono
      ? {icono:'times', title:'Cancelar edición'}
      : {icono:'pen', title:'Editar teléfonos'};
    },
    paquetesEsperaMiami() {
      if (!this.paquetesId.length) return false;
      let paqueteEnMiami = true;
      this.paquetesId.forEach((paquete) => {
        if(paquete.estatus !== "EsperaMiami"){
          paqueteEnMiami = false
        }
      });
      if(paqueteEnMiami){
      // retornará verdadero si todos los paquetes seleccionados tienen estatus "EsperaMiami"
        return true
      }
      return false;
    },
    paquetesTipoIndividual() {
      if (!this.paquetesId.length) return false;
      const tipos = [];
      this.paquetesId.forEach((paquete) => {
        tipos.push(paquete.tipo);
      });
      if (Object.values(tipos).includes("Reempaque")) return false;
      // retornará verdadero si todos los paquetes seleccionados son de tipo individual
      return true;
    },
    paquetesIdFormatted() {
      if (!this.paquetesId) return false;
      const ids = [];
      this.paquetesId.forEach((paquete) => {
        ids.push(paquete.id);
      });
      // retornará todos los id de los paquetes seleccionados
      return ids;
    },
    paquetesSeleccionados() {
      if (!this.paquetesId) return false;
      const paquetes = [];
      this.paquetesId.forEach((paquete) => {
        paquetes.push(paquete);
      });
      // retornará todos los paquetes seleccionados
      return paquetes;
    },
    mensajeAlConfirmarEliminar(){
      if (this.IdOrdenAEliminar)return 'Se eliminará la orden en su totalidad'
      if (this.IdPagoAEliminar)return 'Se eliminará el pago en su totalidad'
      if (this.IdDireccionAEliminar)return 'Se eliminará la dirección en su totalidad'
      if (this.esEliminarComprobantes)return `Se eliminará ${this.comprobantesEliminar.length > 1 ? 'los comprobantes seleccionados' : 'el comprobante seleccionado'}`
      if (this.idClienteEliminar)return 'Se eliminará el cliente actual'
      return 'Se eliminará el paquete en su totalidad'
    },
    // Filtrar el objeto con paquetes del cliente en cada estatus
    PaquetesClienteConEstatus() {
      let estatusConPaquetes = {};
      for (let estatus in this.paquetesClienteEstatus) {
        let valor = this.paquetesClienteEstatus[estatus];
        if(valor !== 0) estatusConPaquetes[estatus] = valor;
      }
      return estatusConPaquetes;
    },
    esPermitido(){
      if(this.roles.includes('Admin')){
        return true
      } else if(this.roles.includes('Agencia')){
        return true
      } else return false
    },
    esAdmin(){
      if(this.roles.includes('Admin')){
        return true
      } else return false
    },
    esPermitidoVerDetallesPaquete(){
      if(this.esPermitido || this.roles.includes('OpAlmacen')){
        return true
      } else return false
    }
  },
  methods: {
    // Validaciones
    asignarTelefonoReceptor(iDireccion){
      this.mostrarNuevoRTelefono = !this.mostrarNuevoRTelefono
      this.indiceDireccionSeleccionada = iDireccion
    },
    validarAsignarDireccion(direccion){
      if(this.esPermitido){
        this.esEditarDirección = true;
        this.direccionSeleccionada = direccion;
        this.mostrarModalEditarEnvio=  !this.mostrarModalEditarEnvio;
      }
    },
    validarAsignarAliado(direccion){
      if(this.esPermitido){
        this.mostrarModalSeleccionarAliado = !this.mostrarModalSeleccionarAliado;
        this.direccionSeleccionada = direccion;
      }
    },
    paqueteReempacar(){
      if(!this.paquetesEsperaMiami) {
        return this.alertaMensaje = {
          contenido:  'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia',
        }
      }
      this.mostrarFormularioPaquete = true;
      this.reempaque = true;
      this.modalTitulo = 'Datos del nuevo paquete tipo reempaque';
    },
    metodoAlConfirmarEliminar(){
      if (this.IdOrdenAEliminar)return this.ordenEliminar()
      if (this.IdPagoAEliminar)return this.pagoEliminar()
      if (this.IdDireccionAEliminar)return this.direccionRemover()
      if (this.esEliminarComprobantes)return this.eliminarComprobantes()
      if (this.idClienteEliminar) {
        if(!this.eliminarClienteDobleVerificacion){
          this.eliminarClienteDobleVerificacion =  !this.eliminarClienteDobleVerificacion;
          this.mostrarModalConfirmarPermiso = true
          return
        }
      }
      return this.paqueteEliminar()
    },
    async eliminarCliente() {
      this.botonDeshabilitado = true;
      try {
        await this.$apollo.mutate({
          mutation: clienteEliminarGql,
          variables: {
            personaId: this.idClienteEliminar,
          },
          update: (
            store,
            {
              data: {
                clienteEliminar: { codigo },
              },
            }
          ) => {
            if (codigo === enumRespuestaCodigo.Correcto) {
              this.alertaMensaje = {
                contenido: "Cliente eliminado correctamente",
                tipo: "correcto",
              };
              this.mostrarModalConfirmarEliminar = false;
              this.eliminarClienteDobleVerificacion = false;
              this.mostrarModalConfirmarPermiso = false;
              const data = store.readQuery({
                query: clientesGql,
              });
              const clientesActualizado = data.Cliente.filter(
                (cliente) => cliente.id !== this.idClienteEliminar
              );
              store.writeQuery({
                query: clientesGql,
                data: {
                  Cliente: clientesActualizado,
                },
              });
              this.limpiarId();
              setTimeout(() => {
                this.$router.push("/clientes");
              }, 2300);
            } else {
              this.alertaMensaje = {
                contenido: "El cliente no pudo ser eliminado. Verifica que el cliente que intentas eliminar no sea un usuario del sistema",
                tipo: "advertencia",
              };
            }
          },
        });
      } catch (error) {
        this.alertaMensaje = {
          contenido: `Ocurrió un error al eliminar el cliente: ${error.message}`,
          tipo: "error",
        };
      } finally {
        this.botonDeshabilitado = false;
      }
    },
    // Funcionalidad AlertaMensaje
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
     abrirGaleriaEn(foto) {
      this.$refs.lightbox.showImage(foto);
    },
    cerrarModalClave(){
      this.mostrarModalCambiarClave = false; 
      this.clave=''; 
      this.confirmacionClave='';
      this.botonDeshabilitado = false;
      this.validarValores = {}
      this.mostrarCamposClave = {
         clave: false,
        confirmacionClave: false,
      }
    },
    cambiarClave(){
      this.validarValores = {
        clave: this.clave.trim() ? "valido" : "invalido",
        confirmacionClave: this.confirmacionClave.trim() ? "valido" : "invalido",
      };
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes("invalido")) {
        this.alertaMensaje = {contenido:'Todos los campos son requeridos', tipo:'error'},
        this.botonDeshabilitado = false;
        return;
      }
      if(this.clave.length < 6){
        return this.alertaMensaje = {
          contenido:'La clave debe contener al menos 6 caracteres',
          tipo:'advertencia'
          }
      }
      if(this.clave !== this.confirmacionClave){
        this.validarValores.confirmacionClave = "invalido";
        return this.alertaMensaje={
          contenido:'La confirmación de la clave no coincide. Por favor verifica tus datos',
          tipo:'advertencia'
          }
      }
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
         mutation: gql`
            ${cambiarClaveCliente}
          `,
          variables: {
            clienteId: this.$route.params.id,
            clave: this.clave,
          },
      }).then(({ data: { clienteCambiarClave } }) => {
          switch (clienteCambiarClave.codigo) {
            case "Correcto":
              this.alertaMensaje =  {
                contenido: 'La clave del cliente ha sido modificada correctamente. Se le ha enviando un correo de notificación con la nueva clave',
                tipo: 'correcto'
              };
              break;
            case "Fallido":
              this.alertaMensaje =  {
                contenido: "Ocurrió un error cambiando la clave del cliente",
                tipo: "error",
              };
              break;
            default:
              this.alertaMensaje =  {
                contenido: "Ha ocurrido un error inesperado",
                tipo: "error",
              };
              break;
          }
         this.botonDeshabilitado = false;
         this.clave = '',
         this.confirmacionClave = ''
         this.validarValores = {}
         this.mostrarModalCambiarClave = false;
          this.mostrarCamposClave = {
         clave: false,
         confirmacionClave: false,
           }
        })
        .catch((e) => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido:`Ha ocurrido un error: ${e}`,
            tipo:'error'
          }
        })
    },
    eliminarComprobantes(){
        if (!this.comprobantesEliminar.length || !this.pagoIdComprobante) {
        return (this.alertaMensaje =  {
          contenido: "Debes seleccionar al menos un comprobante",
          tipo: "advertencia",
        });
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: gql`
            ${fotoEliminarGql}
          `,
          variables: {
            grafoId: this.pagoIdComprobante,
            url: this.comprobantesEliminar,
            entidadEliminar: "Pago"
          },
        })
        .then(({ data: { fotoEliminar } }) => {
          switch (fotoEliminar.codigo) {
            case "Correcto":
              this.alertaMensaje =  {
                contenido: `${
                  this.comprobantesEliminar.length > 1
                    ? 'Comprobantes eliminados'
                    : 'Comprobante eliminado'
                } correctamente`,
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje =  {
                contenido: "No se pudo eliminar los comprobantes",
                tipo: "error",
              };
              break;
            default:
              this.alertaMensaje =  {
                contenido: "Ha ocurrido un error eliminando los comprobantes",
                tipo: "error",
              };
              break;
          }
          this.establecerValoresEliminar();
        })
        .catch(() => {
          this.alertaMensaje =  {
            contenido:
              "Ha ocurrido un error inesperado eliminando los comprobantes",
            tipo: "error",
          };
          this.establecerValoresEliminar();
        });
    },
    alertarMensaje(mensaje){
      if(mensaje && mensaje.contenido && mensaje.tipo){
        return this.alertaMensaje = {
          contenido: mensaje.contenido,
          tipo: mensaje.tipo,
        };
      }
    },
    // Funcionalidad emails
    validarEmail(email, nuevo){
      this.botonDeshabilitado = true;
      // Retorna true si se encuentra ya registrado anteriormente el email
      this.$apollo
        .query({
            query: gql`
              ${existeUsuarioGql}
            `,
            variables: {
              usuario: email
            },
            update: (data) => data.existeUsuario,
            fetchPolicy: "no-cache",
          })
          .then(({data: {existeUsuario}}) => {
              const codigo = existeUsuario.codigo;
              this.botonDeshabilitado = false;
              switch (codigo) {
                case 'Correcto':
                  this.alertaMensaje = {
                    contenido: 'El email especificado ya se encuentra asignado a otro usuario.'+
                    ' Por favor comprueba tus datos',
                    tipo: 'advertencia'
                  };
                  return false
                case 'Fallido':
                  this.validarEmailFormat(email, nuevo)
                  return true
                default:
                  this.alertaMensaje = {
                    contenido: 'Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
                    tipo: 'error'
                  }
                  return false
              }
            },
          )
          .catch(() => {
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error validando el correo electrónico. Por favor intenta de nuevo',
              tipo: 'error'
            }
            return false
          })
    },
    validarEmailFormat(email, nuevo){
      if(!nuevo && email === this.emailAEditar.emailAnterior){
        return this.alertaMensaje = {
          contenido:'El email no puede ser identico al anterior',
          tipo: 'error'
        };
      }
      if(!email || !validarMail(email)){
        return this.alertaMensaje= {
          contenido: 'Email no válido',
          tipo: 'error',
        }
      }
      if(nuevo) return this.emailCrear(email)
      this.emailEditar(email)
    },
    limpiarId(){
      this.idClienteEliminar = ''
      this.IdOrdenAEliminar = ""
      this.IdPagoAEliminar = ""
      this.IdDireccionAEliminar = ""
    },
    mensajeAlerta({contenido,tipo}){
      this.alertaMensaje={contenido, tipo}
    },
    emailRemover(email){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEliminarEmailGql,
        variables: {
          email,
          personaId: this.$route.params.id,
        },
        update: (store, {data: {emailEliminar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = emailEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Cliente[0].emails = data.Cliente[0].emails.filter((e) => {
            return !email.includes(e.email);
          });
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarEmail = false;
            this.emailEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    emailEditar(email){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEditarEmailGql,
        variables: {
          emailAnterior: this.emailAEditar.emailAnterior,
          personaId: this.$route.params.id,
          emailNuevo: email,
        },
        update: (store, {data: {emailEditar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = emailEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Cliente[0].emails.forEach(mail => {
            if(this.emailAEditar.emailAnterior === mail.email){
              mail.email = this.emailAEditar.emailNuevo
            }
          });
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarEmail = false;
            this.emailEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    emailCrear(email){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteCrearEmailGql,
        variables: {
          personaId: this.$route.params.id,
          email
        },
        update: (store, {data: {emailCrear}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = emailCrear.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha creado el correo electrónico exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error creando el email. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Cliente[0].emails.push({email, __typename: "Email"})
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.mostrarNuevoEmail = false;
            this.nuevoEmail = '';
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            emailCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    // Funcionalidad teléfonos
    validarTelefono(telefono, nuevo, receptorId){
      if(!nuevo && telefono === this.telefonoAEditar.telefonoAnterior
        || telefono === this.rTelefonoAEditar.telefonoAnterior){
        return this.alertaMensaje = {
          contenido:'El teléfono no puede ser idéntico al anterior',
          tipo: 'error'
        };
      }
       if(!telefono || !validarTel(telefono)){
        return this.alertaMensaje= {
          contenido: 'Teléfono no válido',
          tipo: 'error',
        }
       }
      //  Validar teléfono repetido en caso sea del cliente
       if(!receptorId && evitarTelefonosRepetidos(this.cliente.telefonos, telefono)){
          return (this.alertaMensaje = {
            contenido: 'El teléfono que ingresaste ya se encuentra agregado',
            tipo: 'error'
          })
       }
       if(receptorId){
         let repetido = false
         this.cliente.direcciones.forEach(direccion => {
          //  Validar teléfono repetido del receptor
          if(direccion.recibe && direccion.recibe.telefonos
            && evitarTelefonosRepetidos(direccion.recibe.telefonos, telefono)){
            return repetido = true;
          }
         });
          if(repetido){
            return (this.alertaMensaje = {
              contenido: 'El teléfono que ingresaste ya se encuentra agregado',
              tipo: 'error'
            })
          }
       }
       if(nuevo && receptorId) return this.telefonoCrear(telefono, receptorId)
       if(receptorId) return this.telefonoEditar(telefono, receptorId)
       if(nuevo) return this.telefonoCrear(telefono)
       this.telefonoEditar(telefono)
    },
    telefonoCrear(tel, id){
      const telefono = String(tel)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteCrearTelefonoGql,
        variables: {
          personaId: id ? id : this.$route.params.id,
          numero: telefono
        },
        update: (store, {data: {telefonoCrear}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = telefonoCrear.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha creado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error creando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          if(id) {
            const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
            const direccionesActualizadas = []
            direcciones.forEach((d) => {
              if(d.recibe && d.recibe.id === id) {
                d.recibe.telefonos.push({numero: telefono, __typename: "Telefono"})
              }
              direccionesActualizadas.push(d)
            });
            data.Cliente[0].direcciones = direccionesActualizadas
          }else {
            data.Cliente[0].telefonos.push({numero: telefono, __typename: "Telefono"})
          }
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            if(id) {
              this.mostrarNuevoRTelefono = false;
              this.nuevoRTelefono = '';
            } else {
              this.mostrarNuevoTelefono = false;
              this.nuevoTelefono = '';
            }
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoRemover(num, id){
      const numero = String(num)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEliminarTelefonoGql,
        variables: {
          numero,
          personaId: id ? id : this.$route.params.id,
        },
        update: (store, {data: {telefonoEliminar}}) => {
          const msg = telefonoEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
           if(id) {
            const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
            const direccionesActualizadas = []
            direcciones.forEach((d) => {
              if(d.recibe && d.recibe.id === id) {
                d.recibe.telefonos = d.recibe.telefonos.filter((e) => {
                  return e.numero !== numero;
                });
              }
              direccionesActualizadas.push(d)
            });
            data.Cliente[0].direcciones = direccionesActualizadas
          }else {
            data.Cliente[0].telefonos = data.Cliente[0].telefonos.filter((e) => {
              return e.numero !== numero;
            });
          }
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.editarTelefono = false;
            this.telefonoEditarSeleccionado = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    telefonoEditar(tel, id){
      const telefono = JSON.stringify(tel)
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: clienteEditarTelefonoGql,
        variables: {
          numeroAnterior: id 
            ? String(this.rTelefonoAEditar.telefonoAnterior)
            : String(this.telefonoAEditar.telefonoAnterior),
          personaId: id ? id : this.$route.params.id,
          numeroNuevo: telefono,
        },
        update: (store, {data: {telefonoEditar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = telefonoEditar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha actualizado el teléfono exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error actualizando el teléfono. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          if(id) {
            const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
            const direccionesActualizadas = []
            direcciones.forEach((d) => {
              if(d.recibe && d.recibe.id === id) {
                d.recibe.telefonos.forEach(tel => {
                  if(this.rTelefonoAEditar.telefonoAnterior === tel.numero){
                    tel.numero = this.rTelefonoAEditar.telefonoNuevo
                  }
                });
              }
              direccionesActualizadas.push(d)
            });
            data.Cliente[0].direcciones = direccionesActualizadas
          }else {
            data.Cliente[0].telefonos.forEach(tel => {
              if(this.telefonoAEditar.telefonoAnterior === tel.numero){
                tel.numero = this.telefonoAEditar.telefonoNuevo
              }
            });
          }
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            if(id){
              this.editarRTelefono = false;
              this.rTelefonoEditarSeleccionado = false;
            }else {
              this.editarTelefono = false;
              this.telefonoEditarSeleccionado = false;
            }
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            telefonoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    // Funcionalidad paquetes
    paqueteEliminar() {
      if(this.paquetesIdFormatted.length > 1){
        return this.alertaMensaje = {
                contenido: 'Por favor selecciona un paquete a la vez',
                tipo: 'advertencia',
              };
      }
      if(!this.mostrarModalConfirmarEliminar){
        return this.mostrarModalConfirmarEliminar = true;
      }
      this.$apollo
        .mutate({
          mutation: gql`
          ${paqueteEliminarGql}
        `,
          variables: {
            id: this.paquetesIdFormatted[0]
          },
           update: (store, {data: {paqueteEliminar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = paqueteEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha eliminado el paquete correctamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando el paquete. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Cliente[0].paquetes = data.Cliente[0].paquetes.filter((e) => {
            return !this.paquetesIdFormatted[0].includes(e.id);
          });
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          // Condicional para prevenir error con optimisticResponse
          if(!this.mostrarModalConfirmarEliminar){
            this.paquetesId = [];
          }
          this.mostrarModalConfirmarEliminar = false;
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            paqueteEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
      })
    },
    // Funcionalidad órdenes
     ordenCrear(orden) {
      delete orden.__typename;
      delete orden.fecha.__typename;
      this.botonDeshabilitado = true;
      if(!this.cliente.casillero){
      this.botonDeshabilitado = false;
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Asigna una agencia para continuar",
          tipo: 'advertencia',
        };
      }
      this.$apollo
        .mutate({
          mutation: ordenCrearGql,
          variables: {
            orden: orden,
            personaId: this.cliente.id,
          },
          update: (store, { data: { ordenCrear } }) => {
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            const ordenAgregar = {
              ...orden,
              ...ordenCrear,
              cliente: {},
            };
            const { nombre } = JSON.parse(JSON.stringify(this.clienteOrden));
            ordenAgregar.cliente.nombre = nombre;
            ordenAgregar.compraId = this.cliente?.casillero ? this.cliente?.casillero+ 'C'+obtenerMarcaTemporal() : 'Sin especificar'
            ordenAgregar.cliente.__typename = "Cliente";
            ordenAgregar.fecha.__typename = "_Neo4jDateTime";
            data.Cliente[0].ordenes.push(ordenAgregar);
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenCrear: {
              __typename: "Orden",
              id: -1,
              miniatura: orden.miniatura,
              compraId:this.cliente?.casillero ? this.cliente?.casillero+ 'C'+obtenerMarcaTemporal() : 'Sin especificar',
              estatus: orden.estatus,
              cliente: {
                nombre: this.cliente.nombre,
                __typename: "Cliente",
              },
              fecha: {
                formatted: orden.fecha.formatted,
                __typename: "_Neo4jDateTime",
              },
            },
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.mostrarFormularioOrden = false;
          this.limpiarCamposForm();
          this.alertaMensaje = {
            contenido: "Agregada orden correctamente",
            tipo: "correcto",
          };
        })
         .catch((e) => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: `Ha ocurrido un error agregando la orden. ${e.message.length ? e.message.replace('GraphQL error: ', '') : ''} `,
            tipo: "error",
          };
        });
    },
    abrirModalEditar(ordenSeleccionada) {
      this.orden = ordenSeleccionada;
      this.ordenAsignar = { ...this.orden };
      this.esEditarOrden = true;
      this.mostrarFormularioOrden = true;
    },
    ordenEditar(orden) {
      const ordenAEditar = JSON.parse(JSON.stringify(orden));
      delete ordenAEditar.compraId
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: ordenEditarGql,
          variables: {
            orden: ordenAEditar,
          },
          update: (store, {data: { ordenEditar }}) => {
            switch (ordenEditar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Orden actualizada correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo actualizar la orden",
                tipo: "error",
              };
              return;
            default:
              this.alertaMensaje = {
                contenido: "Ocurrió un error actualizando la orden",
                tipo: "error",
              };
              return;
          }
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            data.Cliente[0].ordenes.filter((ordenStore) => ordenStore.id === orden.id).map(
              (ordenActual) => {
                ordenActual.estatus = ordenAEditar.estatus;
                ordenActual.miniatura = ordenAEditar.miniatura;
                ordenActual.monto = ordenAEditar.monto;
                ordenActual.fecha.formatted = ordenAEditar.fecha.formatted;
                ordenActual.compraId = ordenActual.compraId 
                  ? ordenActual.compraId
                  : this.cliente?.casillero
                    ? this.cliente?.casillero+ 'C'+obtenerMarcaTemporal()
                    : 'Sin especificar';
              }
            );
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data,
            });
            // Condicional para prevenir error con optimisticResponse
            if(this.mostrarFormularioOrden){
              this.limpiarCamposForm();
            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: "Ocurrió un error actualizando la orden",
            tipo: "error",
          };
        });
    },
    ordenEliminar() {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: ordenEliminarGql,
          variables: {
            ordenId: this.IdOrdenAEliminar,
          },
          update: (store, {data: { ordenEliminar }}) => {
            switch (ordenEliminar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Se ha removido la orden correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo eliminar la orden",
                tipo: "error",
              };
              return;
            default:
              this.alertaMensaje = {
                contenido: "Ocurrió un error inesperado",
                tipo: "error",
              };
              return;
          }
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            data.Cliente[0].ordenes = data.Cliente[0].ordenes
              .filter((orden) => orden.id !== this.IdOrdenAEliminar);
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data,
            });
            if(!this.botonDeshabilitado){
              this.IdOrdenAEliminar = '';
              this.mostrarModalConfirmarEliminar = false;
            }
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault();
          this.botonDeshabilitado = false;
        });
    },
    // Funcionalidad pagos
    pagoAEditar(pagoSeleccionado) {
      this.botonDeshabilitado = true;
      this.pagoAsignar = JSON.parse(JSON.stringify(pagoSeleccionado));
      this.esEditarPago = true;
      this.mostrarModalPago = true;
    },
    seleccionarTodo() {
     const pdfCantidad = this.comprobante.pdf.length;
      const fotosCantidad = this.comprobante.fotos.length;
      const todosLosElementosSeleccionados =
        this.comprobantesEliminar.length === pdfCantidad + fotosCantidad;
      if (todosLosElementosSeleccionados) {
        this.comprobantesEliminar = [];
        return;
      }
      const pdfAEliminar = this.comprobante.pdf.map((pdf) => pdf);
      const fotosAEliminar = this.comprobante.fotos.map((foto) => foto);
      this.comprobantesEliminar = [...pdfAEliminar, ...fotosAEliminar];
     },
     obtenerComprobantesEliminar() {
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId: this.IdPagoAEliminar,
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: "network-only",
        })
        .then(({ data: { obtenerFotos } }) => {
          this.tieneComprobantes = obtenerFotos.length > 0 ? true : false;
        })
        .catch(() => {
          this.alertaMensaje =  {
            contenido: "Ha ocurrido un error consultando los comprobantes",
            tipo: "error",
          };
        });
    },
     pagoCrear({pago, comprobante, medioId}) {
      this.botonDeshabilitado = true;
      const { cliente, ...restPago } = pago;
      delete restPago.__typename;
      delete restPago.fecha.__typename;
      delete cliente.__typename;
      const {id:idMedio, nombre:nombreDelMedio,descripcion} = medioId;
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoCrearGql}
          `,
          variables: {
            pago: restPago,
            personaId: cliente.id,
            medioId:idMedio,
            comprobante: { foto: comprobante },
          },
          update: (store, { data: { pagoCrear } }) => {
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            const pagoAgregar = {
              ...restPago,
              ...pagoCrear,
              medio:{},
              cliente: {},
            };
            const { nombre } = cliente;
            pagoAgregar.__typename = "Pago";
            pagoAgregar.cliente.__typename = "Cliente";
            pagoAgregar.cliente.nombre = nombre;
            pagoAgregar.cliente.telefonos = [];
            pagoAgregar.medio.nombre=nombreDelMedio
            pagoAgregar.medio.id = idMedio
            pagoAgregar.medio.descripcion = descripcion
            pagoAgregar.medio.__typename="Medio"
            pagoAgregar.fecha.__typename = "_Neo4jDateTime";
            data.Cliente[0].__typename = "Cliente";
            data.Cliente[0].pagos.push(pagoAgregar);
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoCrear: {
              __typename: "Pago",
              id: -1,
              monto: restPago.monto,
              medio: {
                nombre:nombreDelMedio,
                __typename:"Medio"
              },
              referencia: restPago.referencia,
              observacion: restPago.observacion,
              fecha: {
                formatted: restPago.fecha.formatted,
                __typename: "_Neo4jDateTime",
              },
              cliente: {
                nombre: cliente.nombre,
                telefonos: [],
                __typename: "Cliente",
              },
            },
          },
        })
        .then(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Pago agregado correctamente",
            tipo: "correcto",
          };
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error agregando el pago",
            tipo: "error",
          };
        });
    },
     obtenerComprobantes(grafoId) {
      this.cargandoComprobantes = true;
      this.mostrarModalComprobante = true;
      this.pagoIdComprobante = grafoId;
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId,
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: "network-only",
        })
        .then(({ data: { obtenerFotos } }) => {
          const archivosUrl = obtenerFotos.map((archivo) => archivo.url);
          this.comprobante.pdf = archivosUrl.filter((url) =>
            regExpPdf.test(url)
          );
          this.comprobante.fotos = archivosUrl.filter(
            (url) => !regExpPdf.test(url)
          );
          if (this.comprobante.fotos.length) {
            const endpoint = this.endpointPublicBackend;
            this.galeriaFotosComprobante = this.comprobante.fotos.map(
              (foto) => ({
                thumb: `${endpoint}${foto}`,
                src: `${endpoint}${foto}`,
              })
            );
          } else this.galeriaFotosComprobante = [];
          this.cargandoComprobantes = false;
        })
        .catch(() => {
          this.cargandoComprobantes = false;
          this.mostrarModalComprobante = false;
        });
    },
     cerrarModalComprobante() {
      this.mostrarModalComprobante = false;
      this.comprobantesEliminar  = [];
      this.comprobante.pdf = [];
      this.comprobante.fotos = [];
      this.galeriaFotosComprobante = [];
      this.pagoIdComprobante = "";
    },
     establecerValoresEliminar() {
      this.botonDeshabilitado = false;
      this.mostrarModalConfirmarEliminar = false;
      this.comprobante.pdf = [];
      this.comprobante.fotos = [];
      this.comprobantesEliminar = [];
      this.mostrarModalComprobante = false;
      this.galeriaFotosComprobante = [];
      this.pagoIdComprobante = "";
      this.esEliminarComprobantes = false;
      this.tieneComprobantes = false
    },
    pagoEditar({pago, medioId}) {
      this.botonDeshabilitado = true;
      pago.monto = Number(pago.monto);
      const pagoVariable = JSON.parse(JSON.stringify(pago))
      const {id:idMedio, nombre:nombreDelMedio, descripcion} = medioId;
       this.$apollo
        .mutate({
          mutation: gql`
            ${pagoEditarGql}
          `,
          variables: {
            pago:pagoVariable,
            medioId:idMedio,
          },
          update: (store) => {
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            data.Cliente[0].__typename = "Cliente";
            data.Cliente[0].pagos.filter(({ id }) => id === pagoVariable.id).map((pagoEditar) => {
              if(!pagoEditar.medio){
                pagoEditar.medio = {}
                pagoEditar.medio.__typename= "Medio"
              }
              pagoEditar.monto = pagoVariable.monto;
              pagoEditar.medio.nombre = nombreDelMedio;
              pagoEditar.medio.id = idMedio;
              pagoEditar.medio.descripcion= descripcion;
              pagoEditar.referencia = pagoVariable.referencia;
              pagoEditar.observacion = pagoVariable.observacion;
              pagoEditar.fecha.formatted = pagoVariable.fecha.formatted;
            });
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoEditar: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({data:{pagoEditar:{codigo}}}) => {
            switch (codigo) {
              case "Correcto":
                this.alertaMensaje = {
                  contenido: "Pago actualizado correctamente",
                  tipo: "correcto",
                };
                break;
              case "Fallido":
                this.alertaMensaje = {
                  contenido: "No se pudo actualizar el pago",
                  tipo: "error",
                };
                break;
              default:
                this.alertaMensaje = {
                  contenido: "Ocurrió un error inesperado",
                  tipo: "error",
                };
                break;
            }
            this.botonDeshabilitado = false;
            this.limpiarCamposForm();
            return;
          }
        )
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje = {
            contenido: "Ha ocurrido un error editando el pago",
            tipo: "error",
          };
        });
    },
    pagoEliminar() {
      if(this.tieneComprobantes){
        return this.alertaMensaje =  {
          contenido:"Para eliminar el pago debes remover los comprobantes adjuntos",
          tipo:"Advertencia"
        }
      }
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: pagoEliminarGql,
          variables: {
            pagoId: this.IdPagoAEliminar,
          },
          update: (store, {data: { pagoEliminar }}) => {
            switch (pagoEliminar.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Se ha removido el pago correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo eliminar la pago",
                tipo: "error",
              };
              return;
            default:
              this.alertaMensaje = {
                contenido: "Ocurrió un error inesperado",
                tipo: "error",
              };
              return;
          }
            const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            });
            data.Cliente[0].pagos = data.Cliente[0].pagos
              .filter((pago) => pago.id !== this.IdPagoAEliminar);
            store.writeQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
              data,
            });
            if(!this.botonDeshabilitado){
              this.IdPagoAEliminar = '';
              this.mostrarModalConfirmarEliminar = false;
            }
            this.botonDeshabilitado = false;
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoEliminar: {
              codigo: "Correcto",
              __typename: "Resultado",
            },
          },
        })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault();
          this.botonDeshabilitado = false;
        });
    },
    // Direcciones
    direccionRemover(){
      this.botonDeshabilitado = true;
      this.$apollo.mutate({
        mutation: direccionEliminarGql,
        variables: {
          direccionId: this.IdDireccionAEliminar,
        },
        update: (store, {data: {direccionEliminar}}) => {
          const data = store.readQuery({
              query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
          });
          const msg = direccionEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido:  'Se ha removido la dirección exitosamente',
                tipo: 'correcto',
              };
              break;
            case 'Fallido':
              this.botonDeshabilitado = false;
              return this.alertaMensaje = {
                contenido:  'Ha ocurrido un error eliminando la dirección. Por favor intenta de nuevo',
                tipo: 'error',
              };
            default:
              this.botonDeshabilitado = false;
              return this.mostrarMensajeEnCasoDefault();
          }
          data.Cliente[0].direcciones = data.Cliente[0].direcciones.filter((e) => {
            return !this.IdDireccionAEliminar.includes(e.id);
          });
          store.writeQuery({
            query: clienteGql,
              variables: {
                filter:{
                  id: this.$route.params.id
                }
              },
            data,
          });
          if(!this.botonDeshabilitado){
            this.sustantivoFemenino = false;
            this.mostrarModalConfirmarEliminar = false;
          }
          this.botonDeshabilitado = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
            direccionEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto',
          },
        },
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false
      })
    },
    limpiarCamposForm() {
      this.orden = {
        miniatura: "",
        estatus: "",
        fecha: {},
      };
      this.ordenAsignar = {
        miniatura: "",
        estatus: "",
        fecha: {},
      };
      this.pago = {
        medio: '',
        fecha: {},
        monto: '',
      },
      this.pagoAsignar = {
        medio: "",
        fecha: {},
        monto: "",
        referencia: "",
      },
      this.mostrarModalPago = false;
      this.mostrarFormularioOrden = false;
      this.botonDeshabilitado = false;
    },
  },
  apollo: {
    cliente() {
      return {
        query: clienteGql,
        variables: {
          filter:{
            id: this.$route.params.id,
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: "cache-and-network",
      };
    },
    paquetesClienteEstatus(){
      return {
        query:paquetesClienteEstatusGql,
        variables:{
          clienteId:this.$route.params.id,
        },
        fetchPolicy: "cache-and-network",
        update: (data) => data,
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.col-auto {
  &.acciones {
    width: 64px;
    @media screen and (min-width: 768px){width: 136px;}
  }
}

.tarjeta-estatus {
  border: 1px solid $gris-tr-borde;
  border-radius: 8px;
  cursor: default;
  margin: .5rem 0;
  padding: .75rem 0 0;
  overflow: hidden;

  & + &:not(:first-of-type) {
    border-left-color: $gris-tr-borde;
  }

  header,
  .valor {
    padding: 0 1rem;
  }

  header {
    font-size: .87rem;
    margin-bottom: .5rem;
    @media screen and (min-width: 768px) {
      margin-bottom: .25rem;
      min-height: 42px;
    }
  }
  .valor {
    background-color: lighten($terciario, 70%);
    color: $terciario-sombra;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: right;
  }
}
</style>

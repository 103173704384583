<template>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="
      $emit('cerrar', false);
      limpiarCampos();
    "
  >
    <mdb-modal-header>
      <mdb-modal-title>
        {{ tituloModal }}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <!-- Listado de paquetes en caso sea especificado el consolidado -->
      <section
        v-if="idConsolidado && consolidados.length"
        class="animated fadeIn"
      >
        <div class="row">
          <p v-if="clienteEspecificado && clienteEspecificado.nombre" class="col-12 col-sm-6 col-md-4 mb-1">
            <small class="d-block text-muted user-select-none">Cliente</small>
            {{ clienteEspecificado.nombre }}
          </p>
          <p v-if="clienteEspecificado && clienteEspecificado.casillero" class="col-12 col-sm-6 col-md-4 mb-1">
            <small class="d-block text-muted user-select-none">Casillero</small>
            {{ clienteEspecificado.casillero }}
          </p>
          <p v-if="idConsolidado && consolidados.length && consolidados[0].master" class="col-12 col-sm-6 col-md-4 mb-1">
            <small class="d-block text-muted user-select-none">Consolidado máster</small
            >
            {{ consolidados[0].master }}
          </p>
        </div>
        <ul class="table" data-table="sticky">
          <li class="encabezado row">
            <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
            <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
            <div class="col pl-0">Detalles</div>
            <div class="col-4 col-md d-none d-sm-block">Receptor</div>
          </li>
          <li
            v-for="paquete in consolidados[0].paquetes"
            :key="`paquete-${paquete.id}`"
            class="contenido normal row align-items-center"
          >
            <!-- Fecha recibido -->
            <div class="col-3 col-sm-2 col-lg-1">
              <span class="d-block">
                {{ formatearFecha(paquete.fecha.formatted).ddmm }}
              </span>
              {{ formatearFecha(paquete.fecha.formatted).yyyy }}
            </div>
            <!-- Estatus -->
            <div class="col-4 col-md-3 d-none d-lg-block">
              {{ PaqueteEstatus[paquete.estatus] }}
              <p class="mb-0">
                Envío:
                <span :class="{ 'text-muted font-italic': !paquete.envio }">
                  {{
                    paquete.envio
                      ? ConsolidadoTipoEnvio[paquete.envio]
                      : "Sin especificar"
                  }}
                </span>
              </p>
              <p class="mb-0">
                Tipo:
                <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                  {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                </span>
              </p>
            </div>
            <!-- Detalles -->
            <div class="col pl-0">
              <!-- Columna estatus -->
              <div class="d-lg-none mb-1">
                {{ PaqueteEstatus[paquete.estatus] }}
                <p class="mb-0">
                  Envío:
                  <span :class="{ 'text-muted font-italic': !paquete.envio }">
                    {{
                      paquete.envio
                        ? ConsolidadoTipoEnvio[paquete.envio]
                        : "Sin especificar"
                    }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <span :class="{ 'text-muted font-italic': !paquete.tipo }">
                    {{ paquete.tipo ? paquete.tipo : "Sin especificar" }}
                  </span>
                </p>
              </div>
              Tracking:
              <span :class="{ 'text-muted font-italic': !paquete.tracking }">
                {{ paquete.tracking ? paquete.tracking : "Sin especificar" }}
              </span>
              <p class="mb-0">
                <span title="Peso por volumen">
                  vlbs:
                  {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166) }}
                  |
                </span>
                <span title="Peso">lbs: {{ mostrarSoloDosDecimales(paquete.peso) }} |</span
                >
                <span title="Pies cúbicos">
                  ft<sup>3</sup>:
                  {{ mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728) }}
                </span>
              </p>
              <!-- Columna receptor -->
              <p class="mb-0 mt-1 d-sm-none">
                Receptor:
                <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                  {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
                </span>
              </p>
              <p class="mb-0 d-sm-none">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span
                  v-if="
                    paquete.direccionDestino &&
                    paquete.direccionDestino.ciudad &&
                    paquete.direccionDestino.direccion
                  "
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic"
                  >Sin especificar</span
                >
              </p>
              <span v-if="paquete.observacion">
                Observación: {{ paquete.observacion }}
              </span>
            </div>
            <!-- Receptor -->
            <div class="col-4 col-md d-none d-sm-block">
              Receptor:
              <span :class="{ 'text-muted font-italic': !paquete.receptor }">
                {{ paquete.receptor ? paquete.receptor : "Sin especificar" }}
              </span>
              <p class="mb-0">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span
                  v-if="
                    paquete.direccionDestino &&
                    paquete.direccionDestino.ciudad &&
                    paquete.direccionDestino.direccion
                  "
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic"
                  >Sin especificar</span
                >
              </p>
            </div>
          </li>
        </ul>
      </section>
      <!-- Caso: No se encontraron paquetes \\ no cuenta con consolidado el paquete-->
      <div
        v-else
        class="text-center text-muted user-select-none mb-4"
      >
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="fa-5x mb-4"
        />
        <p class="h3-responsive text-center mb-0 mx-lg-5 px-lg-5">
          Es necesario que el paquete cuente con un consolidado asignado para continuar
        </p>
      </div>
    </mdb-modal-body>
    <!-- Funcionalidad crear PDF -->
    <mdb-modal-footer
      v-if="idConsolidado && consolidados.length"
      class="justify-content-around"
    >
      <mdb-btn
        :color="esAgencia ? 'terciario' : 'primario'"
        :icon="botonDeshabilitado ? 'circle-notch' : 'print'"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        class="px-3"
        :disabled="botonDeshabilitado"
        @click="generarRecibo"
      >
        Obtener recibo
      </mdb-btn>
      <mdb-btn
        v-if="esAgencia"
        color="primario"
        :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
        :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
        class="px-3"
        :disabled="botonDeshabilitado"
        @click="entregarPaquetes"
      >
        Entregar
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import gql from "graphql-tag";
import facturasGql from "@/graphql/facturas.gql";
import { PaqueteEstatus, PaqueteTipos } from "@/constantes/paquetes.js";
import { ConsolidadoTipoEnvio } from "@/constantes/consolidados.js";
import { imagen } from "@/logos/logos.js";
import {
  mostrarSoloDosDecimales,
  formatearFecha,
  getBase64ImageFromURL
} from "@/funciones/funciones.js";
import { reciboPaquetesConsolidadoCliente } from "@/funciones/formatosImpresion.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalFooter,
} from "mdbvue";

// PDFmake
const pdfMake = require("pdfmake/build/pdfmake.js");
const pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "ModalRecibo",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalFooter,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esAgencia: {
      type: Boolean,
      required: false,
    },
    esPaquete: {
      type: Boolean,
      required: false,
    },
    agenciaLogo: {
      type: String,
      required: false,
    },
    agenciaNombre: {
      type: String,
      required: false,
    },
    clienteEspecificado: {
      type: Object,
      required: true,
    },
    idConsolidado: {
      type: String,
      required: false,
    },
    idDireccion: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      PaqueteTipos,
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      mostrarSoloDosDecimales,
      formatearFecha,
      botonDeshabilitado: false,
      consolidados: [],
      imagen,
    };
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos();
        this.botonDeshabilitado = false;
      } else {
        this.obtenerFacturas();
      }
    },
  },
  computed: {
    tituloModal() {
      return this.esPaquete
        ? "Paquetes a ser facturados"
        : "Listado paquetes a entregar";
    },
  },
  methods: {
    obtenerFacturas() {
      const idCliente = this.clienteEspecificado.id;
      const idConsolidado = this.idConsolidado;
      const idDireccion = this.idDireccion
      if (!idConsolidado) {
        return (this.consolidados = []);
      }
      const filter = {
        id: idConsolidado,
      };
      const filters = {
        cliente: { id: idCliente }
      }
      // Obtener solo paquetes con dirección igual al paquete seleccionado 
      if(idDireccion){
        Object.assign(filters, {
          direccionDestino: {
            id: idDireccion
          }
        })
      } else {
        Object.assign(filters, {
          direccionDestino: null
        })
      }
      const variablesQuery = {
        filter,
        filters,
      };
      this.$apollo
        .query({
          query: gql`
            ${facturasGql}
          `,
          variables: variablesQuery,
          update: (data) => data.Consolidado,
          fetchPolicy: "no-cache",
        })
        .then(({ data }) => {
          if (data && data.Consolidado) {
            this.consolidados = data.Consolidado;
          }
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.botonDeshabilitado = false;
          this.alertaMensaje(
            "Ha ocurrido un error obteniendo las facturas",
            "error"
          );
        });
    },
    entregarPaquetes(){
      const ids = this.consolidados[0].paquetes.map(p => (p.id))
      const paquetes = this.consolidados[0].paquetes.map(p => {
        const paquete = p
        paquete.cliente = this.clienteEspecificado
        return paquete
        })
      this.$emit('paquetes', JSON.parse(JSON.stringify(paquetes)))
      this.$emit('ids', ids)
      this.$emit('entregar', true)
      this.$emit('cerrar', false);
      this.limpiarCampos();
    },
    // Genera la estructura para el PDF
    async generarRecibo() {
      // Paquetes del cliente en el consolidado
      const paquetes = JSON.parse(
        JSON.stringify(this.consolidados[0].paquetes)
      );

      // Calcular # de paquetes asegurados
      let paquetesAsegurados = 0;
      paquetes.map((p) => {
        p.asegurado ? paquetesAsegurados++ : ''
      });

      // Da formato a los teléfonos del cliente
      let telefonosFormat = "";
      this.clienteEspecificado.telefonos.map((t, i) => {
        telefonosFormat += String(t.numero);
        telefonosFormat += i + 1 === this.clienteEspecificado.telefonos.length ? "" : ", "
      });

      // Remitente | Cliente: nombre, identificacion, casillero, teléfono, direccionDestino
      const remitente = {
        nombre: this.clienteEspecificado.nombre,
        identificacion: this.clienteEspecificado.identificacion,
        casillero: this.clienteEspecificado.casillero,
        telefono: telefonosFormat,
        direccionDestino: `${paquetes[0].direccionDestino?.direccion}. ` +
        `${paquetes[0].direccionDestino?.ciudad?.nombre}, ` +
        `${paquetes[0].direccionDestino?.ciudad?.estado?.nombre}, ` +
        `${paquetes[0].direccionDestino?.ciudad?.estado?.pais?.nombre}.`
      }

      // Destinatario | Receptor
      let destinatario = { nombre: '', identificacion: '' };

      if (paquetes[0].direccionDestino
        && paquetes[0].direccionDestino?.recibe
        && paquetes[0].direccionDestino?.recibe?.nombre
        && paquetes[0].direccionDestino?.recibe.nombre.length) {
        const identificacion = paquetes[0].direccionDestino?.recibe?.identificacion
          ? paquetes[0].direccionDestino.recibe.identificacion : '';
        destinatario = Object.assign(destinatario, {
          nombre: paquetes[0].direccionDestino?.recibe?.nombre,
          identificacion: identificacion,
        })
      } else {
        let existeRecibe = false;
        paquetes.forEach(paquete => {
          if (paquete.direccionDestino
            && paquete.direccionDestino?.recibe
            && paquete.direccionDestino?.recibe?.nombre
            && paquete.direccionDestino.recibe.nombre.length
            && paquete.direccionDestino?.recibe?.nombre !== destinatario.nombre) {
            const identificacion = paquete.direccionDestino?.recibe?.identificacion
              ? paquete.direccionDestino.recibe.identificacion : '';
            destinatario = Object.assign(destinatario, {
              nombre: paquete.direccionDestino?.recibe?.nombre,
              identificacion: identificacion,
            })
            existeRecibe = true;
          }
        })
        if (!existeRecibe) {
          if (paquetes[0].receptor && paquetes[0].receptor.length) {
            destinatario.nombre = paquetes[0].receptor;
          } else {
            paquetes.forEach(paquete => {
              if (paquete.receptor
                && paquete.receptor.length
                && paquete.receptor !== destinatario.nombre) {
                destinatario.nombre = paquete.receptor;
              }
            })
          }
        }
      }

      // Consolidado: máster y tipo de envío
      const consolidado = {
        guiaMaster: this.consolidados[0].master,
      };

      // Paquetes: en formato Array para tabla
      let bodyPaquetes = [];
      bodyPaquetes.push([ // Encabezado de la tabla
        {text: 'N° GUÍA', style: 'tableHeader'},
        {text: 'ASEGURADO', style: 'tableHeader'},
      ]);
      paquetes.forEach(paquete => {
        bodyPaquetes.push([ // Información del paquete con formato
          {text: paquete.numeroHR},
          {text: paquete.asegurado ? 'Sí' : 'No', alignment: 'center'}
        ])
      });
      const tablaPaquetes = {
        headerRows: 1,
        widths: [ '*', '*' ],
        body: bodyPaquetes,
      }
      // Información de los Paquetes:
      const informacionPaquetes = {
        cantidadPaquetes: paquetes.length,
        asegurados: paquetesAsegurados,
        aliado: paquetes[0].direccionDestino && paquetes[0].direccionDestino.aliado
          ? paquetes[0].direccionDestino.aliado.nombre : ''
      }

      let nombreHR = (this.agenciaNombre && this.agenciaNombre.length) ? this.agenciaNombre : 'GLOBAL TRACKIT'; // Nombre de la agecia
      let logoHR = (this.agenciaLogo && this.agenciaLogo.length) ? await getBase64ImageFromURL(this.agenciaLogo) : this.imagen;

      let contenido = reciboPaquetesConsolidadoCliente(remitente, destinatario, consolidado, tablaPaquetes, informacionPaquetes, logoHR, nombreHR);

      // Definiendo la información básica del documento
      const informacionDocumento = {
        title: `Recibo - Cliente ${remitente.nombre} ${remitente.casillero} - Guía ${consolidado.guiaMaster}`,
        author: 'GLOBAL TRACKIT',
        keywords: 'Recibo PDF de paquetes',
      };
      this.vistaImpresion(informacionDocumento, contenido);
    },
    // Genera el PDF
    vistaImpresion(info, contenido) {
      const documento = {
        info: info,
        content: contenido,
        defaultStyle: {
          columnGap: 10,
          fontSize: 11,
        },
        styles: {
          header1: {
            fontSize: 24,
            bold: true,
            characterSpacing: 2,
          },
          header2: {
            fontSize: 18,
            bold: true,
          },
          header3: {
            fontSize: 16,
          },
          header4: {
            fontSize: 14,
          },
          header5: {
            fontSize: 12,
            bold: true,
          },
          header6: {
            fontSize: 11,
            bold: true,
          },
          tableHeader: {
            fontSize: 11,
            alignment: 'center',
            bold: true,
          },
          small: {
            fontSize: 8,
          },
        },
        pageSize: "A4",
        pageOrientation: "portrait",
      };
      pdfMake.createPdf(documento).open();
    },
    alertaMensaje(contenido, tipo) {
      this.$emit("alertaMensaje", {
        contenido,
        tipo,
      });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.consolidados = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
}
</style>

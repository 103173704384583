
import { mostrarSoloDosDecimales } from '@/funciones/funciones';

/**
 * Calcula el consto del paquete
 * @param {*} paquete 
 */
function paquteCalcularCosto(paquete) {
  let costoTotal = NaN;
  const tiposEnvio = [ 'Aereo', 'Maritimo'];
  // Requisitos para calcular monto
  if (
    !paquete.direccionDestino ||
    !tiposEnvio.includes(paquete.envio) ||
    !paquete.ancho ||
    !paquete.alto ||
    !paquete.largo ||
    !paquete.peso
  ) {
    return costoTotal;
  }
  if (paquete.envio === 'Aereo') {
    if (
      !paquete.direccionDestino.tasaAerea ||
      !paquete.direccionDestino.minAerea
    ) {
      return costoTotal;
    }
  }
  if (paquete.envio === 'Maritimo') {
    if (
      !paquete.direccionDestino.tasaMaritima ||
      !paquete.direccionDestino.minMaritima
    ) {
      return costoTotal;
    }
  }

  const direccion = paquete.direccionDestino
  const costo = Number(paquete.costo)
  const valor = Number(paquete.valorDeclarado)
  let seguro = 0;

  if(valor){
    seguro = valor * 0.05
    costoTotal = costo + seguro
  } else {
    // Si se establece manualmente en "0" el valor, se reinician los valores
    seguro = 0
    costoTotal = costo
  }
  // Calculo de pesos
  const pesoVolumetrico = (paquete.ancho * paquete.alto * paquete.largo) / 166
  const peso = paquete.peso > pesoVolumetrico
    ? paquete.peso
    : pesoVolumetrico
  const piesCubicos = (paquete.ancho * paquete.alto * paquete.largo) / 1728
  // Calcular según tipo de envío
  switch (paquete.envio) {
    case "Aereo":
      if(peso < direccion.minAerea){
        // Si el peso es menor que la tasa minima aerea se usa la tasa minima aerea
        costoTotal += (direccion.tasaAerea * direccion.minAerea)
      } else {
        // En caso contrario se usa el peso * tasa aerea
        costoTotal += (direccion.tasaAerea * peso)
      }
      break;
    case "Maritimo":
      if(piesCubicos < direccion.minMaritima){
        // Si los piesCubicos es menor que la tasa minima Maritima se usa la tasa minima Maritima
        costoTotal += (direccion.tasaMaritima * direccion.minMaritima)
      } else {
        // En caso contrario se usan los piesCubicos * tasa Maritima
        costoTotal += (direccion.tasaMaritima * piesCubicos)
      }
      break;
  }
  return mostrarSoloDosDecimales(costoTotal);
}

/**
 * Calcula el costo de los paquetes de un cliente en un consolidado
 * @param {Array} paquetes
 * @return {Number} costo
 */
function paquetesCalcularCosto(paquetes) {
  let costoTotal = NaN;
  let minimo = 0;
  let alerta = "";
  const tiposEnvio = [ 'Aereo', 'Maritimo'];
  // Requisitos para calcular monto
  paquetes.forEach((paquete)=>{
    if (!paquete.direccionDestino) alerta += "Falta dirección de "+paquete.numeroHR+". "
    if (!tiposEnvio.includes(paquete.envio)) alerta += "Falta tipo de envío de "+paquete.numeroHR+". "
    if (
      !paquete.ancho ||
      !paquete.alto ||
      !paquete.largo ||
      !paquete.peso
    ) alerta += "Faltan dimensiones de "+paquete.numeroHR+". "
  })
  paquetes.forEach((paquete)=>{
    if (paquete.envio === 'Aereo') {
      if (
        !paquete.direccionDestino.tasaAerea ||
        !paquete.direccionDestino.minAerea
      ) {
        alerta += "Faltan tasas aéreas de "+paquete.numeroHR+". "
      }
    }
    if (paquete.envio === 'Maritimo') {
      if (
        !paquete.direccionDestino.tasaMaritima ||
        !paquete.direccionDestino.minMaritima
      ) {
        alerta += "Faltan tasas marítimas de "+paquete.numeroHR+". "
      }
    }
  })

  if (alerta.length) {
    return {
      monto: costoTotal,
      minimo,
      alerta,
    };
  }
  costoTotal = 0;
  let paquetesPorDireccion = {}
  
  // Calculo de pesos
  paquetes.forEach((paquete)=>{
    // Condiciones iniciales para calculo por dirección
    const direccion = paquete.direccionDestino
    if (!paquetesPorDireccion[direccion.id]) {
      paquetesPorDireccion[direccion.id] = {
        direccion,
        tipoEnvio: paquete.envio,
        pesoTotal: 0,
        pesoVolumetricoTotal: 0,
        volumenTotal: 0,
        seguro: 0,
        costoTotal: 0,
      }
    }

    const costo = Number(paquete.costo)
    const valor = Number(paquete.valorDeclarado)
    let seguro = 0;
  
    if(valor){
      seguro = valor * 0.05
      paquetesPorDireccion[direccion.id].costoTotal += costo + seguro
    } else {
      // Si se establece manualmente en "0" el valor, se reinician los valores
      seguro = 0
      paquetesPorDireccion[direccion.id].costoTotal += costo
    }
    const pesoVolumetrico = (paquete.ancho * paquete.alto * paquete.largo) / 166
    paquetesPorDireccion[direccion.id].pesoTotal += paquete.peso;
    paquetesPorDireccion[direccion.id].pesoVolumetricoTotal += pesoVolumetrico;
    paquetesPorDireccion[direccion.id].volumenTotal += (paquete.ancho * paquete.alto * paquete.largo) / 1728
  })
  
  // Calcular según tipo de envío
  switch (paquetes[0].envio) {
    case "Aereo":
      for (const direccionId in paquetesPorDireccion) {
        if (Object.prototype.hasOwnProperty.call(paquetesPorDireccion, direccionId)) {
          const direccion = paquetesPorDireccion[direccionId];
          costoTotal += paquetesPorDireccion[direccionId].costoTotal;

          const montoMinimo = (direccion.direccion.tasaAerea * direccion.direccion.minAerea)
          const pesoUsar = direccion.pesoTotal > direccion.pesoVolumetricoTotal
            ? direccion.pesoTotal
            : direccion.pesoVolumetricoTotal
          const montoDimension = (direccion.direccion.tasaAerea * pesoUsar)
          if(pesoUsar < direccion.direccion.minAerea){
            // Si el peso es menor que la tasa minima aerea se usa la tasa minima aerea
            costoTotal += montoMinimo
            minimo += montoMinimo - montoDimension;
          } else {
            // En caso contrario se usa el peso * tasa aerea
            costoTotal += montoDimension
          }
        }
      }
      break;
    case "Maritimo":
      for (const direccionId in paquetesPorDireccion) {
        if (Object.prototype.hasOwnProperty.call(paquetesPorDireccion, direccionId)) {
          const direccion = paquetesPorDireccion[direccionId];
          costoTotal += paquetesPorDireccion[direccionId].costoTotal;

          const montoMinimo = (direccion.direccion.tasaMaritima * direccion.direccion.minMaritima)
          const montoDimension = (direccion.direccion.tasaMaritima * direccion.volumenTotal)
          if(direccion.volumenTotal < direccion.direccion.minMaritima){
            // Si los piesCubicos es menor que la tasa minima Maritima se usa la tasa minima Maritima
            costoTotal += montoMinimo
            minimo += montoMinimo - montoDimension;
          } else {
            // En caso contrario se usan los piesCubicos * tasa Maritima
            costoTotal += montoDimension
          }
        }
      }
      break;
  }
  return {
    monto: mostrarSoloDosDecimales(costoTotal),
    minimo,
    alerta,
  };
}

export {
  paquteCalcularCosto,
  paquetesCalcularCosto,
};
